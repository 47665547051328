import React from "react";
import { cloneDeep } from "lodash";
import { ICommonData } from "../../../context/CommonDataContext";
import { IAbility } from "../../../Interfaces";

export const getNewCapabilitiesForContext = (commonData: ICommonData, newData: any, updatedId?: string) => {
    return cloneDeep(
        commonData.ehrCapabilities.map((ehr: IAbility) => {
            if (ehr.id === newData.id) {
                if (!!updatedId) {
                    return { ...newData, id: updatedId };
                }
                return newData;
            } else {
                return ehr;
            }
        })
    );
};

export const getAccountConfigCapabilitiesForContext = (commonData: ICommonData, newData: any, updatedId?: string) => {
    return cloneDeep(
      commonData.accountConfigCapabilities?.map((ehr: IAbility) => {
        if (ehr.id === newData.id) {
          if (!!updatedId) {
            return {...newData, id: updatedId};
          }
          return newData;
        } else {
          return ehr;
        }
      })
    );
};