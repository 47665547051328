import React, {useCallback, useEffect} from 'react';
import {IConversationFilter, IConversationListState} from '../interface';
import {IMentionObject, IUnreadMentionObject} from '../../../../../../../utils/interfaces';
import {EventBus} from '../../../../../../../utils/EventBus';
import {MessageBus} from '../../../../../../../utils/MessageBus';
import {CONVERSATION_LOCAL_EVENT_CODES, SUPPORTED_EVENT_CODE, WEB_SOCKET_EVENT_CODE} from '../../../../../../../constants/WebSocketConst';
import {IContact, IConversationData, IConversationResponse, INewConversationResponse, MESSAGE_TYPE} from '../../../interfaces';
import {Platform} from 'react-native';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {isSearchStringMatchedWithReceivedConversation} from '../ConversationSearchUtils';
import {formatConversationMentionData, getFormattedConversationDataComingFromWS, isConversationPresent, getUniqueConversationList, isConversationPresentByConversationUuid, getAssigneeIdsFromFilter, getInboxIdFromFilter, getUniqueConversationListByUuid, isConversationMentionPresentByConversationUuid, isConversationFilterAppliedV2} from '../ConversationListUtils';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, CONVERSATION_SUBTAB_CODES, GROUP_CONVERSATION_CHANNEL} from '../../../ConversationConst';
import {StrCaseConverter} from '../../../../../../../utils/CaseConverter';
import {ICommonData} from '../../../../../../../context/CommonDataContext';
import {getMlovCodeFromId, getMlovCodeIdObj, getMlovIdCodeObj, getMlovListFromCategory} from '../../../../../../../utils/mlovUtils';
import {CONVERSATION_STATUS, CONVERSATION_STATUS_STRING, MLOV_CATEGORY, ONLINE_STATUS, SENDER_TYPE} from '../../../../../../../constants';
import {IGroupConversationCreatedData} from '../../../../../../../Interfaces/GroupInterfaces';
import {IConversationCreated, IMessageCreated, IMessageCreatedFormattedData} from '../../../../../../../Interfaces/WebSocketEventInterfaces';
import ConversationsQueries from '../../../../../../../services/Conversations/ConversationsQueries';
import {useLazyQuery} from '@apollo/client';
import {getInboxTypeCode, getUserId, getUserUUID, isEFaxChannelInbox, isEmailChannelInboxType} from '../../../../../../../utils/commonUtils';
import {ILoginUserData} from '../../../../../../../Interfaces';
import {IOnMessageReceivedDataAction, MESSAGE_RECEIVED_DATA_ACTION, getFormattedReceivedMessageData, getPatientName, getSelectedMentionConversationFromList, isAllCallsMessageType, isAssignedToOtherFilterApplied, isMissCallMessageType, updateConversationAssigneeDataOnLocalEvent, updateConversationDataInMatchedConversation, updateUnixTimeStampOnIOMessage} from './ConversationListEventHookUtils';
import {ALLOWED_GROUP_TYPE} from './ConversationListEventHookConst';
import {IArchivedConversationBusEventData, IBulKArchiveLocalEventData, IArchivedLocalEventData, IAssigneeLocalEventData, IConversationAssigneeLocalEventData, ILocalEventFormattedData} from './interface';
import {isWeb} from '../../../../../../../utils/platformCheckUtils';
import {CALL_ONLY_MESSAGE_TYPE_NUM, CONVERSATION_DISPLAY_MESSAGE_TYPE, GROUP_MEMBER_ADD_REMOVE_CONVERSATION_DISPLAY_REALTIME, NEW_GROUP_CONVERSATION_DISPLAY_REALTIME, NEW_PATIENT_GROUP_CONVERSATION_DISPLAY_REALTIME} from '../../../MessagingWindow/MessagingCustomCard/CustomCardConst';
import {getContactDataAndType, isInternalChat, isPrivateGroupConversation} from '../../../MessagingWindow/MessagingUtils';
import { ToastType } from '../../../../../../../utils/commonViewUtils';
import {GROUP_TYPE_CODES} from '../../../../../../../constants/MlovConst';
import { setSearchParamsForSelectedConversation } from '../../../ConversationChannelNew/ConversationSidebarUtils';
import { localBroadcastEvent } from '../../../../../../../utils/CustomEventHandler';

export const useConversationMessageReceivedEffect = (
  conversationListState: IConversationListState,
  setConversationListState: React.Dispatch<React.SetStateAction<IConversationListState>>,
  conversationListPropsData: {
    searchString: string;
    conversationFilter: IConversationFilter;
    selectedInboxTypeCode: string;
    onConversationActionPerformed?: (
      actionCode: string,
      actionData?: any
    ) => void;
    selectedSubtabCode?: string;
  },
  commonData: ICommonData,
  toast: any,
) => {

  const {
    conversationFilter,
    searchString,
    selectedInboxTypeCode
  } = conversationListPropsData;
  const userId = getUserId();
  const userUuid = getUserUUID();
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeIdCodeObj = getMlovIdCodeObj(groupTypeList);

  const [getConversationByConversationUuid] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByConversationUuid,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getConversationByConversationUuidBySubtab] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByConversationUuidForSubtab,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getUnreadMessagesDataById] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetUnreadMessagesById,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GetMentionsConversationsByConversationUuId] = useLazyQuery<IConversationResponse>(
    ConversationsQueries.GetMentionsConversationsByConversationUuId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const updateConversationList = (
    conversationRespData: INewConversationResponse,
    selectConversation: boolean
  ) => {
    let latestData = {} as IConversationData;
    if (conversationListPropsData?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      latestData = {
        ...formatConversationMentionData(conversationRespData?.conversationMentions || [])?.[0]
      }
    } else {
      latestData = {
        ...conversationRespData?.conversations?.[0],
      }
    }
    const isSearchStringMatched = isSearchStringMatchedWithReceivedConversation(searchString || '', conversationRespData);
    if (latestData && latestData.id && isSearchStringMatched) {
      latestData = {
        ...latestData,
        conversationContact: {
          ...latestData.conversationContact,
          isOnline: false,
        },
      };
      if (selectConversation) {
        setConversationListState((prev: any) => {
          return {
            ...prev,
            newAddedConversation: latestData,
            conversationList: [latestData, ...prev.conversationList],
            isLoadingConversations: false,
            selectedConversation: latestData,
          };
        });
        if (isWeb()) {
          conversationListPropsData?.onConversationActionPerformed?.(
            COMMON_ACTION_CODES.ITEM_CLICKED,
            {
              selectedConversation: latestData,
            }
          );
        }
      } else {
        setConversationListState((prev: any) => {
          return {
            ...prev,
            newAddedConversation: latestData,
            conversationList: [latestData, ...prev.conversationList],
            isLoadingConversations: false,
          };
        });
      }
    }
  };

  const onGroupConversationCreated = (data: IGroupConversationCreatedData) => {
    const groupConversation = data?.groupConversations?.[0] || {};
    if (groupConversation?.id) {
      const groupTypeList = commonData.MLOV[MLOV_CATEGORY.GroupType];
      //TODO Fetch Conversation on proper conditions
    }
  };

  const onConversationCreatedListenerFn = useCallback(
    (data: any) => {
      let channelTypeStr = CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET;
      if (data?.channel) {
        channelTypeStr = StrCaseConverter.toConstantCase(data?.channel) as any;
      }
      if (
        channelTypeStr === selectedInboxTypeCode ||
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ALL
      ) {
        if (
          data?.groupConversations &&
          Object.keys(data?.groupConversations).length
        ) {
          onGroupConversationCreated(data);
        } else {
          const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
          onConversationCreated(
            data as IConversationCreated,
            finalConversationList
          );
        }
      }
    },
    [conversationListState]
  );

  const getEmailConversation = (
    conversationUuid: string,
    selectConversation: boolean
  ) => {
    let finalVariables: any = {
      conversationUuid: conversationUuid,
      loginUserId: userId,
      loginUserIntId: userId,
      loginUserUuid: userUuid,
    }
    if (
      conversationListPropsData?.selectedSubtabCode ===
      CONVERSATION_SUBTAB_CODES.INBOX
    ) {
      finalVariables = {
        ...finalVariables,
        conversationMessagesWhere: {
          _or: [
            {
              messageType: {_eq: 0},
              senderType: {_eq: SENDER_TYPE.CONTACT},
            },
            {
              messageType: {_eq: 1},
              senderType: {_eq: SENDER_TYPE.USER},
              private: {_eq: true},
            },
          ],
        },
      }
    } else if (
      conversationListPropsData?.selectedSubtabCode ===
      CONVERSATION_SUBTAB_CODES.SENT
    ) {
      finalVariables = {
        ...finalVariables,
        conversationMessagesWhere: {
          messageType: {
            _eq: 1,
          },
          senderType: {
            _eq: SENDER_TYPE.USER,
          },
        },
      }
    }
    getConversationByConversationUuidBySubtab({
      variables: finalVariables,
    }).then((conversationRespData: any) => {
      if (conversationRespData && conversationRespData.data) {
        updateConversationList(conversationRespData.data, selectConversation);
      }
    });
  };

  const getEFaxConversation = (
    conversationUuid: string,
    selectConversation: boolean
  ) => {
    let finalVariables: any = {
      conversationUuid: conversationUuid,
      loginUserId: userId,
      loginUserIntId: userId,
      loginUserUuid: userUuid,
    }
    if (
      conversationListPropsData?.selectedSubtabCode ===
      CONVERSATION_SUBTAB_CODES.INBOX
    ) {
      finalVariables = {
        ...finalVariables,
        conversationMessagesWhere: {
          _or: [
            {
              messageType: {_eq: 0},
              senderType: {_eq: SENDER_TYPE.CONTACT},
            },
            {
              messageType: {_eq: 1},
              senderType: {_eq: SENDER_TYPE.USER},
              private: {_eq: true},
            },
          ],
        },
      }
    } else if (
      conversationListPropsData?.selectedSubtabCode ===
      CONVERSATION_SUBTAB_CODES.SENT
    ) {
      finalVariables = {
        ...finalVariables,
        conversationMessagesWhere: {
          messageType: {
            _eq: 1,
          },
          senderType: {
            _eq: SENDER_TYPE.USER,
          },
        },
      }
    }
    getConversationByConversationUuidBySubtab({
      variables: finalVariables,
    }).then((conversationRespData: any) => {
      if (conversationRespData && conversationRespData.data) {
        updateConversationList(conversationRespData.data, selectConversation);
      }
    });
  };

  const getNewConversationAndUpdateInConversationList = (newConversationData: IMessageCreatedFormattedData, selectConversation: boolean) => {
    // IMP : Please do not use other fields from newConversationData.
    const conversationUuid = newConversationData?.conversationUuid;
    if (conversationUuid) {
      if (isConversationFilterAppliedV2(conversationFilter)) {
        let canConversationAddOnRealTimeInList = false;
        if (conversationFilter?.locationUuids?.length && newConversationData?.conversation?.practiceLocationUuid && conversationFilter?.locationUuids.includes(newConversationData?.conversation?.practiceLocationUuid)) {
          canConversationAddOnRealTimeInList = true;
        } else if (conversationFilter?.inboxId && conversationFilter?.inboxId > 0 && newConversationData?.inboxId > 0 && conversationFilter?.inboxId === newConversationData?.inboxId) {
          canConversationAddOnRealTimeInList = true;
        }
        if (canConversationAddOnRealTimeInList) {
          getNewConversationDataFromAPI(newConversationData, selectConversation);
        }
      } else if (isEFaxChannelInbox(conversationListPropsData?.selectedInboxTypeCode)) {
        getEFaxConversation(conversationUuid, selectConversation)
      } else {
        getNewConversationDataFromAPI(newConversationData, selectConversation);
      }
    }
  };

  const getNewConversationDataFromAPI = (newConversationData: IMessageCreatedFormattedData, selectConversation: boolean) => {
    const conversationUuid = newConversationData?.conversationUuid;
    if (isEmailChannelInboxType(conversationListPropsData?.selectedInboxTypeCode)) {
      getEmailConversation(conversationUuid, selectConversation);
    } else {
      getConversationByConversationUuid({
        variables: {
          conversationUuid: conversationUuid,
          loginUserId: userId,
          loginUserIntId: userId,
          loginUserUuid: userUuid,
        },
      }).then((conversationRespData: any) => {
        if (conversationRespData && conversationRespData.data) {
          updateConversationList(
            conversationRespData.data,
            selectConversation
          );
        }
      });
    }
  }

  const onConversationCreated = (
    socketEventData: IConversationCreated,
    prevConversationList: IConversationData[]
  ) => {
    const msgData = socketEventData;
    if (msgData) {
      const statusMlovList = commonData.MLOV[MLOV_CATEGORY.CONVERSATION_STATUS];
      const newConversationData = getFormattedConversationDataComingFromWS(
        msgData,
        statusMlovList,
        conversationFilter,
        selectedInboxTypeCode
      );
      if (msgData?.contact_inbox?.contact_id) {
        newConversationData.contactId = msgData?.contact_inbox?.contact_id;
      }
      if (
        newConversationData?.contactId &&
        !isConversationPresent(
          prevConversationList,
          newConversationData.contactId
        )
      ) {
        // TODO Fetch Conversation with proper condition
      }
    }
  };

  const updateConversationDataOnMessageOrActivityReceived = (receivedMessageData: IMessageCreated) => {
    if (receivedMessageData?.data) {
      receivedMessageData = receivedMessageData?.data;
    }
    const formattedMessageData = getFormattedReceivedMessageData(receivedMessageData, groupTypeIdCodeObj);
    switch (conversationListPropsData.selectedInboxTypeCode) {
      case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME: {
        updateConversationInChannelAssignedMe(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER: {
        updateConversationInChannelAssignedOther(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED: {
        updateConversationInChannelUnAssigned(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_MENTION: {
        updateConversationInChannelMention(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS: {
        updateConversationInChannelMissCall(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED: {
        updateConversationInChannelArchived(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_ALL: {
        updateConversationInChannelAll(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET: {
        updateConversationInChannelChat(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS: {
        updateConversationInChannelSMS(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_EMAIL: {
        updateConversationInChannelEmail(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_INTERNAL: {
        updateConversationInChannelInternal(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_E_FAX: {
        updateConversationInChannelEfax(formattedMessageData);
        break;
      }
      case CHANNEL_TYPE_CODE.ALL_CALLS: {
        updateConversationInChannelAllCall(formattedMessageData);
        break;
      }
    }
  }

  const showToastMessageOnAssigneeChangesOrArchive = useCallback(
    (formattedMessageData: IMessageCreatedFormattedData) => {
      if (formattedMessageData?.messageType !== 2) {
        return;
      }
      const patientName = getPatientName(conversationListState, formattedMessageData);
      if (!patientName) {
        return;
      }
      let toastStrOnAssigneeChangeOrArchive = '';

      if (!formattedMessageData?.assigneeId) {
        if (formattedMessageData?.conversationStatus === CONVERSATION_STATUS.RESOLVED) {
          if (formattedMessageData?.contentAttributes?.actionByUserData?.name) {
            toastStrOnAssigneeChangeOrArchive = "Archived -> " + patientName;
          }
        } else if (formattedMessageData?.conversationStatus === CONVERSATION_STATUS.OPEN) {
          if (formattedMessageData?.contentAttributes?.assigneeToUserData?.id && formattedMessageData?.contentAttributes?.assigneeToUserData?.name) {
            toastStrOnAssigneeChangeOrArchive = patientName + " -> " + formattedMessageData?.contentAttributes?.assigneeToUserData?.name;
          } else if (formattedMessageData?.contentAttributes?.previousAssigneeId) {
            toastStrOnAssigneeChangeOrArchive = "Unassigned -> " + patientName;
          } else if (!formattedMessageData?.contentAttributes?.previousAssigneeId && formattedMessageData?.messageType === 2) {
            toastStrOnAssigneeChangeOrArchive = "Unarchived -> " + patientName;
          } else if (formattedMessageData?.assigneeId && formattedMessageData?.contentAttributes?.assigneeToUserData?.name) {
            toastStrOnAssigneeChangeOrArchive = patientName + " -> " + formattedMessageData?.contentAttributes?.assigneeToUserData?.name;
          }
        }
      } else if (formattedMessageData?.contentAttributes?.assigneeToUserData?.name) {
        toastStrOnAssigneeChangeOrArchive = patientName + " -> " + formattedMessageData?.contentAttributes?.assigneeToUserData?.name;
      }
      if (!toastStrOnAssigneeChangeOrArchive.length) {
        return;
      }
      toast({
        message: toastStrOnAssigneeChangeOrArchive,
        toastType: ToastType.success,
        duration: 2000,
        closeAllPrevToast: true,
      });
    },
    [conversationListState]
  );

  const addOrUpdateConversationInConversationList = (receivedMessageData: IMessageCreatedFormattedData, messageReceivedDataAction: IOnMessageReceivedDataAction[]) => {
    const conversationUuid = receivedMessageData?.conversationUuid;
    if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (isConversationPresentByConversationUuid(finalConversationList, conversationUuid) && messageReceivedDataAction?.length) {
        const finalUpdatedConversationsList = updateConversationDataInMatchedConversation(
          finalConversationList,
          conversationListState.selectedConversation,
          receivedMessageData,
          userId as any,
          selectedInboxTypeCode,
          messageReceivedDataAction,
          conversationListPropsData?.selectedSubtabCode,
        );
        setConversationListState((prev) => {
          return {
            ...prev,
            conversationList: [...finalUpdatedConversationsList],
          };
        });
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED && !receivedMessageData?.assigneeId) {
        getNewConversationAndUpdateInConversationList(receivedMessageData, false);
      }
       else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
        if (receivedMessageData?.assigneeId) {
          getNewConversationAndUpdateInConversationList(receivedMessageData, false);
        }
      } else if (
        (receivedMessageData?.conversationStatus === CONVERSATION_STATUS.OPEN) && selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED) {
        getNewConversationAndUpdateInConversationList(receivedMessageData, false);
      } else if (receivedMessageData?.conversationStatus === CONVERSATION_STATUS.RESOLVED && selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED) {
        getNewConversationAndUpdateInConversationList(receivedMessageData, false);
      }
    }
  }


  function updateMessageInConversationList(formattedReceivedMessageData: IMessageCreatedFormattedData) {
    const messageReceivedDataAction: any = [
      MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
    ];
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    if (formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
      formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED) {
      messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE);
    }
    if (formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
      (
        formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
        formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED
      )) {
      messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE);
    }
    const finalConversationList = getUniqueConversationListByUuid(
      conversationListState.conversationList
    );
    if (
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED &&
      isConversationPresentByConversationUuid(
        finalConversationList,
        conversationUuid
      ) &&
      formattedReceivedMessageData?.eventCode ===
        WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED
    ) {
      messageReceivedDataAction.push(
        MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE
      );
    }

    addOrUpdateConversationInConversationList(formattedReceivedMessageData, [
      ...messageReceivedDataAction
    ]);
  }

  const updateConversationInChannelAssignedMe = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (conversationUuid && formattedReceivedMessageData?.assigneeId === userId && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (conversationUuid && !isConversationPresentByConversationUuid(finalConversationList, conversationUuid) && formattedReceivedMessageData?.assigneeId === userId) {
      getNewConversationAndUpdateInConversationList(formattedReceivedMessageData, false);
    } else if (
      conversationUuid &&
      formattedReceivedMessageData?.messageType === 2 &&
      isConversationPresentByConversationUuid(
        finalConversationList,
        conversationUuid
      )
    ) {
      updateMessageInConversationList(formattedReceivedMessageData);
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.assigneeId !== userId && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelAssignedOther = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const assigneeIds = getAssigneeIdsFromFilter(conversationFilter);
    if (conversationUuid && isAssignedToOtherFilterApplied(assigneeIds, formattedReceivedMessageData, userId)) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
        updateMessageInConversationList(formattedReceivedMessageData);
      }
    }
  }

  const updateConversationInChannelUnAssigned = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (conversationUuid && !formattedReceivedMessageData?.assigneeId && formattedReceivedMessageData?.conversationStatus === CONVERSATION_STATUS.OPEN && (formattedReceivedMessageData?.channel ||  formattedReceivedMessageData?.groupTypeCode === GROUP_TYPE_CODES.PRIVATE)) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (
      conversationUuid &&
      formattedReceivedMessageData?.contentAttributes?.previousAssigneeData
        ?.id !==
        formattedReceivedMessageData?.contentAttributes?.assignToUserData?.id &&
      formattedReceivedMessageData?.messageType === 2 &&
      isConversationPresentByConversationUuid(
        finalConversationList,
        conversationUuid
      )
    ) {
      updateMessageInConversationList(formattedReceivedMessageData);
    }
    // toast for assignee change for future use
    // if (conversationUuid && (formattedReceivedMessageData?.assigneeId || formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED) && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelArchived = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (conversationUuid && formattedReceivedMessageData?.conversationStatus === CONVERSATION_STATUS.RESOLVED) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
        updateMessageInConversationList(formattedReceivedMessageData);
      }
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status ===  CONVERSATION_STATUS.OPEN && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelMention = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    if (conversationUuid && formattedReceivedMessageData?.contentAttributes?.user_mention_ids?.includes(userUuid)) {
      GetMentionsConversationsByConversationUuId({
        variables: {
          conversationUuid: conversationUuid,
        },
      }).then((conversationRespData) => {
        if (conversationRespData?.data?.conversationMentions) {
          updateConversationList(conversationRespData?.data, false);
        }
      });
    } else if (formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity && formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED) {
      const messageReceivedDataAction: any = [MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE];
      if (isConversationPresentByConversationUuid(conversationListState?.conversationList as any, conversationUuid)) {
        const finalUpdatedConversationsList = updateConversationDataInMatchedConversation(
          conversationListState?.conversationList,
          conversationListState.selectedConversation,
          formattedReceivedMessageData,
          userId as any,
          selectedInboxTypeCode,
          messageReceivedDataAction
        );
        setConversationListState((prev) => {
          return {
            ...prev,
            conversationList: [...finalUpdatedConversationsList],
          };
        });
        if (isWeb()) {
          const selectedMentionConversation = getSelectedMentionConversationFromList(conversationListState?.selectedConversation, finalUpdatedConversationsList);
          if (selectedMentionConversation?.uuid) {
            conversationListPropsData?.onConversationActionPerformed?.(
              COMMON_ACTION_CODES.ITEM_CLICKED,
              {
                selectedConversation: selectedMentionConversation,
              }
            );
          }
        }
      }
    }
  }

  const updateConversationInChannelMissCall = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    const filterInboxId = getInboxIdFromFilter(conversationFilter);
    if (conversationUuid &&
      isConversationPresentByConversationUuid(finalConversationList, conversationUuid) &&
      formattedReceivedMessageData?.inboxId >= 1 &&
      (filterInboxId === -1 || filterInboxId === formattedReceivedMessageData?.inboxId)) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (conversationUuid &&
        isMissCallMessageType(formattedReceivedMessageData?.messageType) &&
      !isConversationPresentByConversationUuid(finalConversationList, conversationUuid) &&
      formattedReceivedMessageData?.inboxId >= 1 &&
      (filterInboxId === -1 || filterInboxId === formattedReceivedMessageData?.inboxId)) {
      getNewConversationAndUpdateInConversationList(formattedReceivedMessageData, false);
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelAllCall = (
    formattedReceivedMessageData: IMessageCreatedFormattedData
  ) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const filterInboxId = getInboxIdFromFilter(conversationFilter);
    if (
      CONVERSATION_DISPLAY_MESSAGE_TYPE[selectedInboxTypeCode]?.includes(
        formattedReceivedMessageData?.messageType
      )
    ) {
      if (
        conversationUuid &&
        formattedReceivedMessageData?.channel ===
          CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        formattedReceivedMessageData?.inboxId >= 1 &&
        (filterInboxId === -1 ||
          filterInboxId === formattedReceivedMessageData?.inboxId)
      ) {
        const messageReceivedDataAction: any = [
          MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE,
        ];

        if (isAllCallsMessageType(formattedReceivedMessageData?.messageType)) {
          messageReceivedDataAction.push(
            MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
          );
        }

        addOrUpdateConversationInConversationList(
          formattedReceivedMessageData,
          [...messageReceivedDataAction]
        );
      } else if (
        conversationUuid
      ) {
        const finalConversationList = getUniqueConversationListByUuid(
          conversationListState.conversationList
        );
        if (
          isConversationPresentByConversationUuid(
            finalConversationList,
            conversationUuid
          )
        ) {
          const messageReceivedDataAction: any = [
            MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE,
          ];
          if (isAllCallsMessageType(formattedReceivedMessageData?.messageType)) {
            messageReceivedDataAction.push(
              MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
            );
          }
          addOrUpdateConversationInConversationList(
            formattedReceivedMessageData,
            [...messageReceivedDataAction]
          );
        }
      }
    }
  };

  const updateConversationInChannelAll = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const allowedGroupType = ALLOWED_GROUP_TYPE[CHANNEL_TYPE_CODE.CHANNEL_ALL];
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (conversationUuid &&
      (
        allowedGroupType?.includes(formattedReceivedMessageData?.groupTypeCode) ||
        formattedReceivedMessageData?.inboxId >= 1
      )
    ) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
        updateMessageInConversationList(formattedReceivedMessageData);
      }
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelChat = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const allowedGroupType = ALLOWED_GROUP_TYPE[CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET];
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (conversationUuid &&
      allowedGroupType?.includes(formattedReceivedMessageData?.groupTypeCode)
    ) {
      updateMessageInConversationList(formattedReceivedMessageData);
    } else if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
        updateMessageInConversationList(formattedReceivedMessageData);
      }
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelSMS = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const filterInboxId = getInboxIdFromFilter(conversationFilter);
    if (
      CONVERSATION_DISPLAY_MESSAGE_TYPE[selectedInboxTypeCode]?.includes(
        formattedReceivedMessageData?.messageType
      )
    ) {
      if (
        conversationUuid &&
        formattedReceivedMessageData?.channel ===
          CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        formattedReceivedMessageData?.inboxId >= 1 &&
        (filterInboxId === -1 ||
          filterInboxId === formattedReceivedMessageData?.inboxId)
      ) {
        const messageReceivedDataAction: any = [
          MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE,
        ];
        if (
          formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
          formattedReceivedMessageData?.eventCode ===
            WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED
        ) {
          messageReceivedDataAction.push(
            MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE
          );
        }
        if (
          formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
          (formattedReceivedMessageData?.eventCode ===
            WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
            formattedReceivedMessageData?.eventCode ===
              WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED)
        ) {
          messageReceivedDataAction.push(
            MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE
          );
        }

        addOrUpdateConversationInConversationList(
          formattedReceivedMessageData,
          [...messageReceivedDataAction]
        );
      } else if (conversationUuid) {
        const finalConversationList = getUniqueConversationListByUuid(
          conversationListState.conversationList
        );
        if (
          isConversationPresentByConversationUuid(
            finalConversationList,
            conversationUuid
          )
        ) {
          const messageReceivedDataAction: any = [
            MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE,
          ];
          if (
            formattedReceivedMessageData?.messageType ===
              MESSAGE_TYPE.activity &&
            formattedReceivedMessageData?.eventCode ===
              WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED
          ) {
            messageReceivedDataAction.push(
              MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE
            );
          }
          if (
            formattedReceivedMessageData?.messageType ===
              MESSAGE_TYPE.activity &&
            (formattedReceivedMessageData?.eventCode ===
              WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
              formattedReceivedMessageData?.eventCode ===
                WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED)
          ) {
            messageReceivedDataAction.push(
              MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE
            );
          }
          addOrUpdateConversationInConversationList(
            formattedReceivedMessageData,
            [...messageReceivedDataAction]
          );
        }
      }
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelEmail = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const filterInboxId = getInboxIdFromFilter(conversationFilter);
    if (conversationUuid &&
      formattedReceivedMessageData?.channel === CHANNEL_TYPE.CHANNEL_EMAIL &&
      formattedReceivedMessageData?.inboxId >= 1 &&
      (filterInboxId === -1 || filterInboxId === formattedReceivedMessageData?.inboxId)
    ) {
      const messageReceivedDataAction: any = [
        MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
      ];
      if (
        formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
        formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED
      ) {
        messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE);
      }
      if (
        formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
        (
          formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
          formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED
        )
      ) {
        messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE);
      }
      addOrUpdateConversationInConversationList(formattedReceivedMessageData, [
        ...messageReceivedDataAction
      ]);
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelEfax = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const filterInboxId = getInboxIdFromFilter(conversationFilter);
    if (conversationUuid &&
      formattedReceivedMessageData?.channel === CHANNEL_TYPE.CHANNEL_EFAX &&
      formattedReceivedMessageData?.inboxId >= 1 &&
      (filterInboxId === -1 || filterInboxId === formattedReceivedMessageData?.inboxId)
    ) {
      const messageReceivedDataAction: any = [
        MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
      ];
      if (
        formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
        formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED
      ) {
        messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE);
      }
      if (
        formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
        (
          formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
          formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED
        )
      ) {
        messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE);
      }
      addOrUpdateConversationInConversationList(formattedReceivedMessageData, [
        ...messageReceivedDataAction
      ]);
    } else if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
        const messageReceivedDataAction: any = [
          MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
        ];
        if (
          formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
          formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED
        ) {
          messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE);
        }
        if (
          formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
          (
            formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
            formattedReceivedMessageData?.eventCode === WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED
          )
        ) {
          messageReceivedDataAction.push(MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE);
        }
        addOrUpdateConversationInConversationList(formattedReceivedMessageData, [
          ...messageReceivedDataAction
        ]);
      }
    }
    // toast for assignee change for future use
    // if (conversationUuid && formattedReceivedMessageData?.conversation?.status === CONVERSATION_STATUS.RESOLVED && isConversationPresentByConversationUuid(finalConversationList, conversationUuid)) {
    //   showToastMessageOnAssigneeChangesOrArchive(formattedReceivedMessageData);
    // }
  }

  const updateConversationInChannelInternal = (formattedReceivedMessageData: IMessageCreatedFormattedData) => {
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const allowedGroupType = ALLOWED_GROUP_TYPE[CHANNEL_TYPE_CODE.CHANNEL_INTERNAL];
    if (conversationUuid &&
      allowedGroupType?.includes(formattedReceivedMessageData?.groupTypeCode)
    ) {
      const messageReceivedDataAction: any = [
        MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE
      ];
      addOrUpdateConversationInConversationList(formattedReceivedMessageData, [
        ...messageReceivedDataAction
      ]);
    }
  }

  const onMsgAssigneeChangedListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      updateConversationDataOnMessageOrActivityReceived(receivedMessageData);
    },
    [conversationListState]
  );


  const onMsgReceivedListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      // This function call because on message received we are getting unix time stamp on websocket.
      updateUnixTimeStampOnIOMessage(receivedMessageData);
      if (!receivedMessageData?.eventCode) {
        receivedMessageData.eventCode = WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED;
      }
      updateConversationDataOnMessageOrActivityReceived(receivedMessageData);
    },
    [conversationListState]
  );

  const onGroupMemberAddedListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      if (GROUP_MEMBER_ADD_REMOVE_CONVERSATION_DISPLAY_REALTIME?.includes(conversationListPropsData?.selectedInboxTypeCode)) {
        updateConversationDataOnMessageOrActivityReceived(receivedMessageData);
      }
    },
    [conversationListState]
  );

  const oGroupMemberRemovedListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      if (GROUP_MEMBER_ADD_REMOVE_CONVERSATION_DISPLAY_REALTIME?.includes(conversationListPropsData?.selectedInboxTypeCode)) {
        updateConversationDataOnMessageOrActivityReceived(receivedMessageData);
      }
    },
    [conversationListState]
  );




  const onGroupMsgReceivedListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      let channelTypeStr = GROUP_CONVERSATION_CHANNEL;
      if (receivedMessageData?.data && Object.keys(receivedMessageData)?.length) {
        receivedMessageData = receivedMessageData?.data;
      }
      if (receivedMessageData?.channel) {
        channelTypeStr = getInboxTypeCode(receivedMessageData?.channel || '');
      }


    },
    [conversationListState]
  );




  const onConversationArchivedListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      // TODO Fetch Conversation or update with proper condition
      updateConversationDataOnMessageOrActivityReceived(receivedMessageData);
    },
    [conversationListState]
  );

  const onConversationOpenListenerFn = useCallback(
    (receivedMessageData: IMessageCreated) => {
      // TODO Fetch Conversation or update with proper condition
      updateConversationDataOnMessageOrActivityReceived(receivedMessageData);
    },
    [conversationListState]
  );


  const onMsgMarkAsUnreadUpdate = async (
    receivedMsgData: any,
    previousConversationList: IConversationData[]
  ) => {
    const conversationUuid =
      receivedMsgData?.conversationUuid || receivedMsgData?.conversation_uuid;
    const conversationId = receivedMsgData?.displayId || receivedMsgData?.id;

    const conversationRespData = await getUnreadMessagesDataById({
      variables: {
        conversationId: conversationId,
        loginUserId: userId,
        loginUserIdInt: userId,
        loginUserUuid: userUuid,
      },
    });
    if (conversationRespData && conversationRespData.data?.conversations &&
      conversationRespData?.data?.conversations?.length > 0) {
      const finalConversationList = [] as IConversationData[];
      previousConversationList.forEach((singleConversationData) => {
        let finalSingleConversationData = {...singleConversationData};
        if (
          singleConversationData?.uuid === conversationUuid ||
          conversationListState?.selectedConversation?.uuid ===
          singleConversationData?.uuid
        ) {
          finalSingleConversationData = {
            ...singleConversationData,
            unreadMessages:
              conversationRespData.data?.conversations?.[0]?.unreadMessages ||
              [],
            unreadMsgCount:
              conversationRespData.data?.conversations?.[0]?.unreadMsgCount ||
              0,
          };
        }
        finalConversationList.push(finalSingleConversationData);
      });
      if (finalConversationList.length) {
        setConversationListState((prev) => {
          return {
            ...prev,
            conversationList: [...finalConversationList],
          };
        });
      }
    }
  };

  const onLocalConversationLastSeenUpdateListenerFn = useCallback(
    (data: any) => {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      onMsgMarkAsUnreadUpdate(data, finalConversationList);
    },
    [conversationListState]
  );

  const onLocalMarkAsUnReadMentionStatusChangedListenerFn = useCallback(
    (mentionObject: IUnreadMentionObject) => {
      setConversationListState((prev) => {
      const finalConversationList = [...prev.conversationList];
      finalConversationList.forEach((item) => {
        if (mentionObject.mentionUuid === item?.conversationMentionId) {
          item.isRead = mentionObject.isRead;
        }
      });
        return {
          ...prev,
          conversationList: finalConversationList,
        };
      });
    },
    [conversationListState]
  );

  const onMentionStatusChangedForList = useCallback(
    (mentionObjects: IMentionObject[]) => {
      setConversationListState((prev) => {
        const conversationList = [...prev.conversationList];
        conversationList.forEach((item) => {
          mentionObjects.forEach((obj) => {
            const messageMentions =
              item?.conversationMessages?.[0]?.messageMentions &&
              item?.conversationMessages?.[0]?.messageMentions[0];
            if (
              item.conversationMentionId === obj.mentionUuid ||
              messageMentions?.id === obj.mentionUuid
            ) {
              item.isRead = true;
            }
          });
        });
        return {
          ...prev,
          conversationList: conversationList,
        };
      });
    },
    [conversationListState]
  );

  const onLocalContactDetailUpdatedListenerFn = useCallback(async (actionData?: {
    conversationData: IConversationData
  }) => {
    if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      const selectedConversation = conversationListState?.selectedConversation;
      const allConversationList: IConversationData[] = [];
      let tempSelectedConversation = {} as any;
      if (selectedConversation?.uuid) {
        let whereCondition;
        if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS) {
          whereCondition = {messageType: {_in: CALL_ONLY_MESSAGE_TYPE_NUM}}
        } else {
          whereCondition = {messageType: {_is_null: false}}
        }
        const selectedConversationResp = await getConversationByConversationUuid({
          variables: {
            conversationUuid: actionData?.conversationData?.uuid || selectedConversation?.uuid,
            loginUserId: userId,
            loginUserIntId: userId,
            loginUserUuid: userUuid,
            messageWhereCondition: whereCondition,
          },
        });
        tempSelectedConversation = selectedConversationResp?.data?.conversations?.[0];
      }
      if (tempSelectedConversation?.uuid) {
        const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
        for (let index = 0; index < finalConversationList.length; index++) {
          const singleConversation = finalConversationList[index];
          if (singleConversation?.uuid === tempSelectedConversation?.uuid) {
            allConversationList.push(tempSelectedConversation);
          } else {
            allConversationList.push(singleConversation);
          }
        }
        const uniqueConversationList = getUniqueConversationListByUuid(allConversationList);
        // TODO need to check if another patient quick view open and the selected conversation is different
        const isSelectedConversation =
          selectedConversation?.uuid === tempSelectedConversation?.uuid;
        setConversationListState((prev) => {
          return {
            ...prev,
            conversationList: [...allConversationList],
            uniqueConversationList: [...uniqueConversationList],
            selectedConversation: isSelectedConversation ? tempSelectedConversation : prev?.selectedConversation,
          };
        });
        if (isSelectedConversation) {
          conversationListPropsData?.onConversationActionPerformed?.(
            COMMON_ACTION_CODES.ITEM_CLICKED,
            {
              selectedConversation: tempSelectedConversation,
            }
          );
        }
      }
    }
  },
    [conversationListState]
  );


  const onLocalNewMessageReceivedListenerFn = useCallback(
    (data: {
      conversationData: IConversationData
    }) => {
      if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        const conversationData = data?.conversationData;
        const conversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
        conversationList.forEach((item) => {
          if (conversationData.uuid === item?.uuid) {
            if (conversationData?.status === 1) {
              item.status = 0;
            }
          }
        });
        if (conversationData?.id && conversationData?.status === 1) {
          setConversationListState((prev) => {
            return {
              ...prev,
              conversationList: conversationList,
            };
          });
        }
      }
    },
    [conversationListState]
  );

  const onLocalMessageReadFromNotification = useCallback(
    (actionData: any) => {
      setConversationListState(prevState => {
        if (!(conversationListState?.selectedConversation?.uuid === actionData?.conversation)) {
          const conversationIndex = prevState.conversationList.findIndex(conversation => conversation.uuid === actionData?.conversation);
          if (conversationIndex !== -1) {
            const updatedConversationList = [...prevState.conversationList];
            const updatedConversation = {
              ...updatedConversationList[conversationIndex],
              unreadMessages: [],
              unreadMsgCount: 0,
            };
            updatedConversationList[conversationIndex] = updatedConversation;
            return {
              ...prevState,
              conversationList: updatedConversationList
            };
          }
        }
        return prevState;
      });
    },
    [conversationListState]
  );

  const onLocalGroupConversationCreatedEvent = useCallback(async (localEventData: ILocalEventFormattedData) => {
    if (NEW_GROUP_CONVERSATION_DISPLAY_REALTIME?.includes(conversationListPropsData?.selectedInboxTypeCode)) {
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      if (!isConversationPresentByConversationUuid(finalConversationList, localEventData?.conversation?.uuid)) {
        if (CHANNEL_TYPE_CODE.CHANNEL_INTERNAL === selectedInboxTypeCode && isInternalChat(localEventData?.conversation)) {
          const conversationData = {
            conversationUuid: localEventData?.conversation?.uuid,
          };
          getNewConversationAndUpdateInConversationList(conversationData as any, false);
        } else if (NEW_PATIENT_GROUP_CONVERSATION_DISPLAY_REALTIME?.includes(selectedInboxTypeCode) && isPrivateGroupConversation(localEventData?.conversation)) {
          const conversationData = {
            conversationUuid: localEventData?.conversation?.uuid,
          };
          getNewConversationAndUpdateInConversationList(conversationData as any, false);
        }
      }
    }
  }, [conversationListState]);

  const onLocalConversationArchivedEvent = useCallback(async (localEventData: IArchivedLocalEventData) => {
    // need to check it may required for future use case
    // const formattedMessageData = getFormattedReceivedMessageData(localEventData?.messageData, groupTypeIdCodeObj);
    const archivedConversation = localEventData?.conversation;
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    const conversationDataList = [...finalConversationList];
    const updatedConversationList = conversationDataList.filter((singleConversation) => singleConversation.uuid !== archivedConversation?.uuid);
    if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      const selectedConversation = updatedConversationList[0];
      setSelectedConversationAfterListUpdate(selectedConversation);
      setConversationListState((prev: any) => {
        return {
          ...prev,
          conversationList: [...updatedConversationList],
          selectedConversation: selectedConversation,
          alwaysShowFetchMore: conversationListState.showFetchMore
        };
      });
      if (isWeb()) {
        conversationListPropsData?.onConversationActionPerformed?.(
          COMMON_ACTION_CODES.ITEM_CLICKED,
          {
            selectedConversation: selectedConversation,
          }
        );
      }
    }
  }, [conversationListState]);

  const onLocalConversationBulkArchivedEvent = useCallback(async (localEventData: IBulKArchiveLocalEventData) => {
    const archivedConversationUuids = localEventData?.conversationUuids;
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    const conversationDataList = [...finalConversationList];
    const updatedConversationList = conversationDataList.filter((singleConversation) => !archivedConversationUuids?.includes(singleConversation?.uuid))
    if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      const selectedConversation = updatedConversationList[0];
      setSelectedConversationAfterListUpdate(selectedConversation);
      setConversationListState((prev: any) => {
        return {
          ...prev,
          conversationList: [...updatedConversationList],
          selectedConversation: selectedConversation,
          alwaysShowFetchMore: conversationListState.showFetchMore
        };
      });
      if (isWeb()) {
        conversationListPropsData?.onConversationActionPerformed?.(
          COMMON_ACTION_CODES.ITEM_CLICKED,
          {
            selectedConversation: selectedConversation,
          }
        );
      }
    }
  }, [conversationListState]);

  const onLocalConversationArchivedOnConversationBusEvent = useCallback(async (localEventData: IArchivedConversationBusEventData) => {
    if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED) {
      const archivedConversation = localEventData?.conversation;
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      const conversationDataList = [...finalConversationList];
      const updatedConversationList = conversationDataList.filter((singleConversation) => singleConversation.uuid !== archivedConversation?.uuid);
      const selectedConversation = updatedConversationList[0];
      setConversationListState((prev: any) => {
        return {
          ...prev,
          conversationList: [...updatedConversationList],
          selectedConversation: selectedConversation,
          alwaysShowFetchMore: conversationListState.showFetchMore
        };
      });
      if (isWeb()) {
        conversationListPropsData?.onConversationActionPerformed?.(
          COMMON_ACTION_CODES.ITEM_CLICKED,
          {
            selectedConversation: selectedConversation,
          }
        );
      }
    }

  }, [conversationListState]);

  const onLocalConversationUnArchivedOnConversationBusEvent = useCallback(async (localEventData: IArchivedConversationBusEventData) => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED) {
      const unArchivedConversation = localEventData?.conversation;
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      const conversationDataList = [...finalConversationList];
      const updatedConversationList = conversationDataList.filter((singleConversation) => singleConversation.uuid !== unArchivedConversation?.uuid);
      const selectedConversation = updatedConversationList[0];
      setConversationListState((prev: any) => {
        return {
          ...prev,
          conversationList: [...updatedConversationList],
          selectedConversation: selectedConversation,
          alwaysShowFetchMore: conversationListState.showFetchMore
        };
      });
      if (isWeb()) {
        conversationListPropsData?.onConversationActionPerformed?.(
          COMMON_ACTION_CODES.ITEM_CLICKED,
          {
            selectedConversation: selectedConversation,
          }
        );
      }
    }

  }, [conversationListState]);

  const setSelectedConversationAfterListUpdate = (selectedConversation: IConversationData) => {
    if (isWeb()) {
      conversationListPropsData?.onConversationActionPerformed?.(
        CONVERSATION_ACTION_CODES.GROUP_CONVERSATION_DATA_LOADING,
        {
          selectedConversation: selectedConversation,
          isGroupConversationLoading: true,
        }
      );
    }
    if (selectedConversation?.uuid) {
      getConversationByConversationUuid({
        variables: {
          conversationUuid: selectedConversation?.uuid,
          loginUserId: userId,
          loginUserIntId: userId,
          loginUserUuid: userUuid,
        },
      }).then((selectedConversationResp) => {
        if (selectedConversationResp?.data?.conversations?.[0]?.uuid) {
          selectedConversation = selectedConversationResp?.data?.conversations?.[0];
        }
        setConversationListState((prev) => {
          const finalConversationList = [...prev.conversationList];
          const conIndex = finalConversationList.findIndex((singleCon) => {
            if(selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION){
              return singleCon.conversationMentionId === selectedConversation.conversationMentionId
            }
            return (singleCon.uuid === selectedConversation?.uuid);
          });
          if (conIndex !== -1) {
            finalConversationList[conIndex] = {...selectedConversation};
          }
          return {
            ...prev,
            conversationList: finalConversationList,
            selectedConversation: selectedConversation,
            alwaysShowFetchMore: conversationListState.showFetchMore
          };
        });
        if (isWeb()) {
          conversationListPropsData?.onConversationActionPerformed?.(
            COMMON_ACTION_CODES.ITEM_CLICKED,
            {
              selectedConversation: selectedConversation,
              isGroupConversationLoading: false,
            }
          );
        }
      });
    }
  }


  const onLocalConversationUnArchivedEvent = useCallback(async (localEventData: IArchivedLocalEventData) => {
    // need to check it may required for future use case
    const formattedMessageData = getFormattedReceivedMessageData(localEventData?.messageData, groupTypeIdCodeObj);
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED) {
      const unArchivedConversation = localEventData?.conversation;
      const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
      const conversationDataList = [...finalConversationList];
      const updatedConversationList = conversationDataList.filter((singleConversation) => singleConversation.uuid !== unArchivedConversation?.uuid);
      const selectedConversation = updatedConversationList[0];
      setSelectedConversationAfterListUpdate(selectedConversation);
      setConversationListState((prev: any) => {
        return {
          ...prev,
          conversationList: [...updatedConversationList],
          selectedConversation: selectedConversation,
          alwaysShowFetchMore: conversationListState.showFetchMore
        };
      });
      if (isWeb()) {
        conversationListPropsData?.onConversationActionPerformed?.(
          COMMON_ACTION_CODES.ITEM_CLICKED,
          {
            selectedConversation: selectedConversation,
          }
        );
      }
    }

  }, [conversationListState]);

  const removeConversationFromListOnLocalEvent = async (localEventFormattedData: ILocalEventFormattedData) => {
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    let updatedConversationList = updateConversationAssigneeDataOnLocalEvent(
      finalConversationList,
      localEventFormattedData?.conversation,
      localEventFormattedData?.assignUser,
      localEventFormattedData?.messageData,
      selectedInboxTypeCode
    );
    updatedConversationList = updatedConversationList.filter((singleConversation) => singleConversation.uuid !== localEventFormattedData?.conversation?.uuid);
    const selectedConversation = updatedConversationList[0];
    setSelectedConversationAfterListUpdate(selectedConversation);
    setConversationListState((prev: any) => {
      return {
        ...prev,
        conversationList: [...updatedConversationList],
        selectedConversation: selectedConversation,
        alwaysShowFetchMore: conversationListState.showFetchMore
      };
    });
    if (isWeb()) {
      conversationListPropsData?.onConversationActionPerformed?.(
        COMMON_ACTION_CODES.ITEM_CLICKED,
        {
          selectedConversation: selectedConversation,
        }
      );
    }
  }



  const updateConversationDataOnLocalEvent = (localEventFormattedData: ILocalEventFormattedData) => {
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    const updatedConversationList = updateConversationAssigneeDataOnLocalEvent(
      finalConversationList,
      localEventFormattedData?.conversation,
      localEventFormattedData?.assignUser,
      localEventFormattedData?.messageData,
      selectedInboxTypeCode
    );
    setConversationListState((prev: any) => {
      let updateSelectedConversation = prev?.selectedConversation;
      if (updateSelectedConversation?.uuid === localEventFormattedData?.conversation?.uuid) {
        updateSelectedConversation = {
          ...updateSelectedConversation,
          assignUser: localEventFormattedData?.assignUser
        }
      }
      if (prev?.selectedConversation === localEventFormattedData) {}
      return {
        ...prev,
        conversationList: [...updatedConversationList],
        alwaysShowFetchMore: conversationListState.showFetchMore,
        selectedConversation: updateSelectedConversation
      };
    });
  }



  const addOrUpdateConversationOnLocalEventAssignedMe = (localEventFormattedData: ILocalEventFormattedData) => {
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (isConversationPresentByConversationUuid(finalConversationList, localEventFormattedData?.conversation?.uuid)) {
      if (!localEventFormattedData?.messageData?.conversation?.assigneeId || localEventFormattedData?.messageData?.conversation?.assigneeId !== userId) {
        removeConversationFromListOnLocalEvent(localEventFormattedData);
      } else if (localEventFormattedData?.messageData?.conversation?.assigneeId === userId) {
        updateConversationDataOnLocalEvent(localEventFormattedData);
      }
    } else if (localEventFormattedData?.messageData?.conversation?.assigneeId === userId && localEventFormattedData?.messageData?.conversationStatus === CONVERSATION_STATUS.OPEN) {
      getNewConversationAndUpdateInConversationList(localEventFormattedData?.messageData, false);
    }
  }

  const addOrUpdateConversationOnLocalEventAssignedOther = (localEventFormattedData: ILocalEventFormattedData) => {
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    const assigneeIds = getAssigneeIdsFromFilter(conversationFilter);
    if (isConversationPresentByConversationUuid(finalConversationList, localEventFormattedData?.conversation?.uuid)) {
      if (isAssignedToOtherFilterApplied(assigneeIds, localEventFormattedData?.messageData, userId)) {
        if (localEventFormattedData?.messageData?.assigneeId) {
          updateConversationDataOnLocalEvent(localEventFormattedData);
        } else if (!localEventFormattedData?.messageData?.assigneeId) {
          removeConversationFromListOnLocalEvent(localEventFormattedData);
        }
      } else {
        removeConversationFromListOnLocalEvent(localEventFormattedData);
      }
    } else if (isAssignedToOtherFilterApplied(assigneeIds, localEventFormattedData?.messageData, userId) && localEventFormattedData?.messageData?.conversationStatus === CONVERSATION_STATUS.OPEN) {
      getNewConversationAndUpdateInConversationList(localEventFormattedData?.messageData, false);
    }
  }

  const addOrUpdateConversationOnLocalEventUnassigned = (localEventFormattedData: ILocalEventFormattedData) => {
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (isConversationPresentByConversationUuid(finalConversationList, localEventFormattedData?.conversation?.uuid)) {
      if (localEventFormattedData?.assignUser?.assigneeId) {
        removeConversationFromListOnLocalEvent(localEventFormattedData);
      } else {
        updateConversationDataOnLocalEvent(localEventFormattedData);
      }
    } else if (!localEventFormattedData?.assignUser?.assigneeId && localEventFormattedData?.messageData?.conversationStatus === CONVERSATION_STATUS.OPEN) {
      getNewConversationAndUpdateInConversationList(localEventFormattedData?.messageData, false);
    }
  }

  const updateConversationOnLocalEventIfMatched = (localEventFormattedData: ILocalEventFormattedData) => {
    const finalConversationList = getUniqueConversationListByUuid(conversationListState.conversationList);
    if (isConversationPresentByConversationUuid(finalConversationList, localEventFormattedData?.conversation?.uuid)) {
      updateConversationDataOnLocalEvent(localEventFormattedData);
    }
  }



  const onLocalConversationAssignedOrUnassignedEvent = useCallback((localEventData: IAssigneeLocalEventData) => {
    const formattedMessageData = getFormattedReceivedMessageData(localEventData?.messageData, groupTypeIdCodeObj);
    const localEventFormattedData = {
      conversation: localEventData?.conversation,
      assignUser: localEventData?.assignUser,
      messageData: formattedMessageData,
      selectedInboxTypeCode,
    }
    switch (conversationListPropsData.selectedInboxTypeCode) {
      case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME: {
        addOrUpdateConversationOnLocalEventAssignedMe(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER: {
        addOrUpdateConversationOnLocalEventAssignedOther(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED: {
        addOrUpdateConversationOnLocalEventUnassigned(localEventFormattedData)
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_ALL: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_EMAIL: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_E_FAX: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }
      case CHANNEL_TYPE_CODE.CHANNEL_INTERNAL: {
        updateConversationOnLocalEventIfMatched(localEventFormattedData);
        break;
      }

    }
  }, [conversationListState]);

  const onReadSelectedConversation = (data: any) => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      setConversationListState((prev) => {
        const updatedList = [...prev.conversationList]
        updatedList.forEach((item) => {
          if (item?.conversationMentionId && data?.conversationUuids && data?.conversationUuids?.includes(item?.conversationMentionId)) {
            item.unreadMsgCount = 0;
            item.unreadMessages = [];
            item.isRead = true;
          }
        })
        return {
          ...prev,
          conversationList: updatedList,
        }
      })
    } else {
      setConversationListState((prev) => {
        const updatedList = [...prev.conversationList]
        updatedList.forEach((item) => {
          if (data?.conversationUuids && data?.conversationUuids?.includes(item?.uuid)) {
            item.unreadMsgCount = 0;
            item.unreadMessages = [];
          }
        })
        return {
          ...prev,
          conversationList: updatedList,
        }
      })
    }
  }

  const updateUserLastSeenAtMessageAndConversation = (data: any) => {
    const {selectedConversation} = data;
    conversationListPropsData?.onConversationActionPerformed?.(
      CONVERSATION_ACTION_CODES.MESSAGE_READ,
      selectedConversation
    );
    localBroadcastEvent(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ_ACTION_BUS,
      {
        selectedConversation: selectedConversation,
        selectedInboxTypeCode: selectedInboxTypeCode,
      }
    );
    const eventBus = EventBus.getEventBusInstance();
    setSearchParamsForSelectedConversation({
      selectedConversation,
    }).then(() => {
      eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.CONVERSATION_CHANGED, {});
    });
    setConversationListState((prev) => {
      const updatedConversationList = [...prev.conversationList];
      const selectedConversationIndex = updatedConversationList?.findIndex(
        (item) => item?.uuid == selectedConversation?.uuid
      );
      if (selectedConversationIndex !== -1) {
        const updatedConversation = {
          ...updatedConversationList[selectedConversationIndex],
          unreadMessages: [],
          unreadMsgCount: 0,
        };
        updatedConversationList[selectedConversationIndex] =
          updatedConversation;
      }
      return {
        ...prev,
        conversationList: updatedConversationList,
      };
    });
  };




  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const messageBus = MessageBus.getMessageBusInstance();
    // Websocket Component Events Start
    eventBus.removeEventListener(onConversationCreatedListenerFn);
    eventBus.removeEventListener(onConversationCreatedListenerFn);
    eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
    eventBus.removeEventListener(onConversationArchivedListenerFn);
    eventBus.removeEventListener(onConversationOpenListenerFn);
    eventBus.removeEventListener(onMsgReceivedListenerFn);
    eventBus.removeEventListener(onGroupMsgReceivedListenerFn);
    eventBus.removeEventListener(onReadSelectedConversation);
    // Websocket Component Events End

    // Local Component Events Start
    eventBus.removeEventListener(onLocalConversationLastSeenUpdateListenerFn);
    eventBus.removeEventListener(onLocalMarkAsUnReadMentionStatusChangedListenerFn);
    eventBus.removeEventListener(onLocalContactDetailUpdatedListenerFn);
    eventBus.removeEventListener(onLocalNewMessageReceivedListenerFn);
    eventBus.removeEventListener(onLocalMessageReadFromNotification);

    eventBus.removeEventListener(onLocalConversationArchivedEvent);
    eventBus.removeEventListener(onLocalConversationUnArchivedEvent);
    eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent);
    eventBus.removeEventListener(updateUserLastSeenAtMessageAndConversation);

    //Conversation ActionBus Event start
    eventBus.removeEventListener(onLocalConversationArchivedOnConversationBusEvent);
    eventBus.removeEventListener(onLocalConversationUnArchivedOnConversationBusEvent);
    eventBus.removeEventListener(onLocalConversationBulkArchivedEvent);
    //Conversation ActionBus Event end

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_CREATED,
      onConversationCreatedListenerFn
    );
    // Group conversation events start
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.GROUP_CONVERSATION_CREATED,
      onConversationCreatedListenerFn
    );
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_ADDED,
      onGroupMemberAddedListenerFn
    );
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_REMOVED,
      oGroupMemberRemovedListenerFn
    );
    // Group conversation events end

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      onMsgAssigneeChangedListenerFn
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      onConversationArchivedListenerFn
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      onConversationOpenListenerFn
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.GROUP_MESSAGE_CREATED,
      onGroupMsgReceivedListenerFn
    );


    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_LAST_SEEN_UPDATE,
      onLocalConversationLastSeenUpdateListenerFn
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.MARK_US_UNREAD_MENTION,
      onLocalMarkAsUnReadMentionStatusChangedListenerFn
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONTACT_DETAIL_UPDATE,
      onLocalContactDetailUpdatedListenerFn
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      onLocalNewMessageReceivedListenerFn
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.MESSAGE_READ_FROM_NOTIFICATION,
      onLocalMessageReadFromNotification
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_GROUP_CONVERSATION_CREATED,
      onLocalGroupConversationCreatedEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ARCHIVED,
      onLocalConversationArchivedEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED,
      onLocalConversationUnArchivedEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNED,
      onLocalConversationAssignedOrUnassignedEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED,
      onLocalConversationAssignedOrUnassignedEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_MARK_AS_READ_CONVERSATION,
      onReadSelectedConversation
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_BULK_ARCHIVE_CONVERSATION,
      onLocalConversationBulkArchivedEvent
    );

    //Conversation ActionBus Event start
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_ARCHIVED,
      onLocalConversationArchivedOnConversationBusEvent
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_UN_ARCHIVED,
      onLocalConversationUnArchivedOnConversationBusEvent
    );
    //Conversation ActionBus Event end

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ,
      updateUserLastSeenAtMessageAndConversation
    )


    messageBus.registerCallbacks({
      onMentionStatusChangedCallbackForList: onMentionStatusChangedForList,
    });
    return () => {
      // Websocket Component Events Start
      eventBus.removeEventListener(onConversationCreatedListenerFn);
      eventBus.removeEventListener(onConversationCreatedListenerFn);
      eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
      eventBus.removeEventListener(onConversationArchivedListenerFn);
      eventBus.removeEventListener(onConversationOpenListenerFn);
      eventBus.removeEventListener(onMsgReceivedListenerFn);
      eventBus.removeEventListener(onGroupMsgReceivedListenerFn);

      eventBus.removeEventListener(onGroupMemberAddedListenerFn);
      eventBus.removeEventListener(oGroupMemberRemovedListenerFn);
      eventBus.removeEventListener(onReadSelectedConversation);
      // Websocket Component Events End

      // Local Component Events Start
      eventBus.removeEventListener(onLocalConversationLastSeenUpdateListenerFn);
      eventBus.removeEventListener(onLocalMarkAsUnReadMentionStatusChangedListenerFn);
      eventBus.removeEventListener(onLocalContactDetailUpdatedListenerFn);
      eventBus.removeEventListener(onLocalNewMessageReceivedListenerFn);
      eventBus.removeEventListener(onLocalMessageReadFromNotification);

      eventBus.removeEventListener(onLocalConversationArchivedEvent);
      eventBus.removeEventListener(onLocalConversationUnArchivedEvent);
      eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent);
      eventBus.removeEventListener(onLocalGroupConversationCreatedEvent);
      eventBus.removeEventListener(updateUserLastSeenAtMessageAndConversation);
      eventBus.removeEventListener(onLocalConversationBulkArchivedEvent);

      // Local Component Events End

      //Conversation ActionBus Event start
      eventBus.removeEventListener(onLocalConversationArchivedOnConversationBusEvent);
      eventBus.removeEventListener(onLocalConversationUnArchivedOnConversationBusEvent);
      //Conversation ActionBus Event end

      messageBus.removeCallback({
        onMentionStatusChangedCallbackForList: onMentionStatusChangedForList,
      });

    };

  }, [conversationListState]);
};


