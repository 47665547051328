const LocationSvg = (props: {
  customColor?: string;
  width?: string;
  height?: string;
}) => {
  const {customColor} = props;
  const strokeColor = customColor ? customColor : '#6F7A90';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 24}
      height={props?.height || 24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={strokeColor}
        d="M13.4629 21.6744L13.2512 21.2215L13.4629 21.6744ZM10.5371 21.6744L10.7488 21.2215L10.5371 21.6744ZM13.2512 21.2215C12.4567 21.5928 11.5433 21.5928 10.7488 21.2215L10.3253 22.1274C11.3882 22.6242 12.6118 22.6242 13.6747 22.1274L13.2512 21.2215ZM3.5 10.1433C3.5 12.4637 4.16213 14.9625 5.33525 17.1225C6.50739 19.2808 8.21131 21.1393 10.3253 22.1274L10.7488 21.2215C8.87908 20.3476 7.31447 18.6716 6.21401 16.6453C5.11453 14.6208 4.5 12.285 4.5 10.1433H3.5ZM19.5 10.1433C19.5 12.285 18.8855 14.6208 17.786 16.6453C16.6855 18.6716 15.1209 20.3476 13.2512 21.2215L13.6747 22.1274C15.7887 21.1393 17.4926 19.2808 18.6648 17.1225C19.8379 14.9625 20.5 12.4637 20.5 10.1433H19.5ZM12 2.5C16.1338 2.5 19.5 5.91361 19.5 10.1433H20.5C20.5 5.37814 16.7028 1.5 12 1.5V2.5ZM12 1.5C7.29725 1.5 3.5 5.37814 3.5 10.1433H4.5C4.5 5.91361 7.8662 2.5 12 2.5V1.5ZM14.5 10C14.5 11.3807 13.3807 12.5 12 12.5V13.5C13.933 13.5 15.5 11.933 15.5 10H14.5ZM12 12.5C10.6193 12.5 9.5 11.3807 9.5 10H8.5C8.5 11.933 10.067 13.5 12 13.5V12.5ZM9.5 10C9.5 8.61929 10.6193 7.5 12 7.5V6.5C10.067 6.5 8.5 8.067 8.5 10H9.5ZM12 7.5C13.3807 7.5 14.5 8.61929 14.5 10H15.5C15.5 8.067 13.933 6.5 12 6.5V7.5Z"
      ></path>
    </svg>
  );
};

export default LocationSvg;
