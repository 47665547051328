import {Path, Svg} from 'react-native-svg';

const NewSearchIconSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M12.875 12.875L15.5 15.5m-.75-7.875a7.125 7.125 0 11-14.25 0 7.125 7.125 0 0114.25 0z"
      ></Path>
    </Svg>
  );
};

export default NewSearchIconSvg;
