import { Calendar, Popover, Checkbox } from 'antd';
import {
  Button,
  Divider,
  HStack,
  Icon, Pressable, Spacer,
  Stack,
  Switch,
  Text,
  useMediaQuery,
  View,
  VStack,
  Select,
  Tooltip,
  Input,
  Box
} from 'native-base';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Feather from 'react-native-vector-icons/Feather';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../constants';
import { FlowType } from '../../../../context/WorkflowContext';
import { ILocation, IUser } from '../../../../Interfaces';
import { Colors } from '../../../../styles';
import { getBooleanFeatureFlag, isEmployerRole} from '../../../../utils/commonUtils';
import {
  getCalendarDateTitle,
  getMomentObj,
  getNextCalendarDate,
  getPreviousCalendarDate
} from '../../../../utils/DateUtils';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { AutomationSettingBtn } from '../../AutomationSettingBtn';
import CustomAppointmentScheduleButton from '../../Buttons/CustomAppointmentScheduleButton';
import { capitalizeText } from '../../ContactRelationView/ContactRelationUtils';
import {
  DayCalender,
  MonthCalender,
  WeekCalender
} from '../../Svg/DateCalendersSvg';
import LocationAutoComplete, {
  ILocationSearch, autoCompleteTypeCodes
} from '../LocationAutoComplete/LocationAutoComplete';
import {
  IUserSearch,
  UserAutoComplete,
  userAutoCompleteTypeCodes
} from '../UserAutoComplete/UserAutoComplete';
import { getTitle } from './CalendarTopBarHelper';
import { DisplayText } from '../../DisplayText/DisplayText';
import "./CalendarTopBarStyles.css";
import { ModalActionAntSelect } from '../../ModalActionCommonComponent/ModalActionAntSelect';
import ScheduleSuggestor from '../BookingWorkflows/ScheduleSuggestor/ScheduleSuggestor';
import { FilterWrapperComponent } from '../../CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import { IAppointmentType } from '../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import { APPOINTMENT_COLOR_INDICATOR_SIZE, DefaultFallbackAppointmentTypeColor } from '../../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentTypeConst';
import { IMlov } from '../../../RightSideContainer/Journeys/JourneysOfCare/Table/Interfaces';
import { isEqual } from 'lodash';
import {usePermissions} from '../../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
export enum CalendarTopBarActions {
  bookAppointment = 'BookAppointment',
  blockTime = 'BlockTime',
  groupAppointment = 'GroupAppointment',
  groupSessionAppointment = 'GroupSessionAppointment',
  service = 'Service',
}
const CalendarTopBar = (props: {
  currentView: string;
  currentDate: Date;
  onViewChange: (view: string) => void;
  onDateChange: (date: Date) => void;
  onAction: (action: CalendarTopBarActions) => void;
  onParticipantSelect: (participant: string[]) => void;
  onLocationSelect: (location: string[]) => void;
  selectedUserUUIDList: IUser[];
  selectedLocationUUIDList: ILocation[];
  accountUsers: IUser[];
  userLocations: ILocation[];
  selectedLocationName?: string;
  enabledUserDropdown: boolean;
  enabledLocationDropdown: boolean;
  isPracticeAvailabilityView?: boolean;
  onPracticeAvailabilityHandler?: () => void;
  refetchAppointments: () => void;
  appointmentTypes?: IAppointmentType[];
  onAppointmentTypeSelect?: (appointmentTypes: string[]) => void;
  selectedAppointmentTypes?: string[];
  appointmentStatusList?: IMlov[];
  onAppointmentStatusSelect?: (appointmentStatus: string[]) => void;
  selectedAppointmentStatus?: string[];
}) => {
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<{
    selectedUsers: string[],
    selectedLocations: string[],
    selectedAppointmentTypes: string[],
    selectedAppointmentStatus: string[],
  }>({
    selectedUsers: props.selectedUserUUIDList.map((item) => item.uuid),
    selectedLocations: props.selectedLocationUUIDList.map((item) => item.uuid),
    selectedAppointmentTypes: [...(props.selectedAppointmentTypes || [])],
    selectedAppointmentStatus: [...(props.selectedAppointmentStatus || [])],
  });

  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.SCHEDULE);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];
  const contextData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(contextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);

  useEffect(() => {
    setFilters({
      selectedUsers: props.selectedUserUUIDList.map((item) => item.uuid),
      selectedLocations: props.selectedLocationUUIDList.map((item) => item.uuid),
      selectedAppointmentTypes: [...(props.selectedAppointmentTypes || [])],
      selectedAppointmentStatus: [...(props.selectedAppointmentStatus || [])],
    });
  }, [
    props.selectedUserUUIDList,
    props.selectedLocationUUIDList,
    props.selectedAppointmentTypes,
    props.selectedAppointmentStatus
  ])

  // const [participant, setParticipant] = useState<IUserSearch>();
  const showDatePicker =
    props.currentView === 'day' || props.currentView === 'week';
  const goBack = () => {
    props.onDateChange(
      getPreviousCalendarDate(props.currentView, props.currentDate)
    );
  };

  const goNext = () => {
    props.onDateChange(
      getNextCalendarDate(props.currentView, props.currentDate)
    );
  };

  const getCalenderIcon = (type: string, isSelcted: boolean) => {
    let ele = <></>;
    if (type == 'day') {
      ele = <DayCalender isSelected={isSelcted} />;
    }
    if (type == 'week') {
      ele = <WeekCalender isSelected={isSelcted} />;
    }
    if (type == 'month') {
      ele = <MonthCalender isSelected={isSelcted} />;
    }
    return ele;
  };

  const renderAppointmentTypes = (): JSX.Element => {
    return (
      <View style={{
        width: 350
      }}>

        {/* <Checkbox
          value={"select all"}
          checked={filters.selectedAppointmentTypes?.length == props?.appointmentTypes?.length}
          onChange={(event) => {
            if (event.target.checked) {
              setFilters((prev) => ({...prev, selectedAppointmentTypes: props?.appointmentTypes ? props.appointmentTypes?.map((item) => item.id || "") : []}));
            } else {
              setFilters((prev) => ({...prev, selectedAppointmentTypes: []}));
            }
          }}
        >
          <HStack style={{
            padding: 12,
          }} alignItems='center' flex={1}>
            <VStack flex={1}>
              <HStack alignItems='center' >
                <Text fontSize={16}>Select All</Text>
              </HStack>
            </VStack>
          </HStack>
        </Checkbox>
        <Divider marginX={-2} /> */}

        <View overflowY={'scroll'} style={{
          maxHeight: 400,
        }}>
          {props?.appointmentTypes?.length ?
            props?.appointmentTypes?.map((item: IAppointmentType, index: number) => {
              if (!item.id) {
                return null;
              }
              const apppointmentColor = item?.appointmentCardProperties?.bgColorPrimary || DefaultFallbackAppointmentTypeColor;

              return (<>
                <Checkbox
                  key={item.id}
                  value={item.id || ""}
                  style={{ marginLeft: 0, alignItems: 'center' }}
                  checked={filters.selectedAppointmentTypes?.includes(item.id)}
                  onChange={(event) => {
                    const selected = filters.selectedAppointmentTypes || [];
                    if (!item.id) {
                      return;
                    }
                    if (event.target.checked) {
                      selected.push(item.id)
                    } else {
                      const index = selected.indexOf(item.id);
                      if (index != undefined && index > -1) {
                        selected.splice(index, 1);
                      }
                    }
                    setFilters((prev) => ({...prev, selectedAppointmentTypes: selected || []}));
                  }}
                >
                  <HStack style={{
                    padding: 12,
                  }} alignItems='center' flex={1}>
                    <Box
                      mr={2}
                      bg={apppointmentColor}
                      rounded="full"
                      h={APPOINTMENT_COLOR_INDICATOR_SIZE}
                      w={APPOINTMENT_COLOR_INDICATOR_SIZE}
                    />
                    <VStack flex={1}>
                      <HStack alignItems='center' >
                        <Text fontSize={16}>{item.eventName}</Text>
                      </HStack>
                      <Text fontWeight={200} fontSize={12} color="gray.500" numberOfLines={2} textOverflow={"ellipsis"}>
                        {item.description}
                      </Text>
                    </VStack>
                  </HStack>
                </Checkbox>
                {index !== ((props?.appointmentTypes?.length || 0) - 1) && <Divider marginX={-2} />}
                </>
              )
            }) :
            <Text style={{
              marginTop: 8
            }} size={'smMedium'}
              color={Colors.Custom.Gray700}>
              {intl.formatMessage({
                id: 'noDataFound',
              })}
            </Text>
          }
        </View>
      </View>
    )
  }

  const renderAppointmentStatus = (): JSX.Element => {
    return (
      <View style={{
        width: 180
      }}>
        {/* <Checkbox
          value={"selectAll AppoinmentStatus"}
          checked={filters.selectedAppointmentStatus?.length == props?.appointmentStatusList?.length}
          onChange={(event) => {
            if (event.target.checked) {
              setFilters((prev) => ({...prev, selectedAppointmentStatus: props?.appointmentStatusList?.map((item) => item.id) || []}));
            } else {
              setFilters((prev) => ({...prev, selectedAppointmentStatus: []}));
            }
          }}
        >
          <HStack style={{
            padding: 12,
          }} alignItems='center' flex={1}>
            <Text fontSize={16}>
              Select all
            </Text>
          </HStack>
        </Checkbox>
        <Divider marginX={-2} /> */}
        <View overflowY={'scroll'} style={{
          maxHeight: 400,
        }}>
          {props?.appointmentStatusList?.length ?
            props?.appointmentStatusList?.map((item: IMlov, index: number) => {
              if (!item.id) {
                return null;
              }

              return (
                <>
                <Checkbox
                  key={item.id}
                  value={item.id || ""}
                  style={{ marginLeft: 0 }}
                  checked={filters.selectedAppointmentStatus?.includes(item.id)}
                  onChange={(event) => {
                    const selected = filters.selectedAppointmentStatus || [];
                    if (event.target.checked) {
                      selected?.push(item.id)
                    } else {
                      const index = selected?.indexOf(item.id);
                      if (index != undefined && index > -1) {
                        selected?.splice(index, 1);
                      }
                    }
                    setFilters((prev) => ({...prev, selectedAppointmentStatus: selected || []}));
                  }}
                >
                  <HStack style={{
                    padding: 12,
                  }} alignItems='center' flex={1}>
                    <Text fontSize={16}>
                      {item.value}
                    </Text>
                  </HStack>
                </Checkbox>
                {index !== ((props?.appointmentStatusList?.length || 0) - 1) && <Divider marginX={-2} />}
                </>
              )
            }) :
            <Text style={{
              marginTop: 8
            }} size={'smMedium'}
              color={Colors.Custom.Gray700}>
              {intl.formatMessage({
                id: 'noDataFound',
              })}
            </Text>
          }
        </View>
      </View>
    )
  }

  const getPracticeAvailabilityFilter = (): JSX.Element => {
    return (
      <HStack marginRight={4}>
        <VStack style={{ marginHorizontal: 6 }}>
          <HStack space={1}>
            <Tooltip placement='top' label={capitalizeText('Availability')}>
              <Switch
                offThumbColor={Colors.Custom.FontColorWhite}
                onThumbColor={Colors.Custom.FontColorWhite}
                onValueChange={(value: boolean) => {
                  if (props?.onPracticeAvailabilityHandler && typeof props?.onPracticeAvailabilityHandler == 'function') {
                    props.onPracticeAvailabilityHandler();
                  }
                }}
                isChecked={props?.isPracticeAvailabilityView}
              />
            </Tooltip>
          </HStack>
          {/* <Button
                style={{
                  backgroundColor: props?.isPracticeAvailabilityView ? Colors.primary['300'] : '#fff',
                  borderRadius: 20,
                }}
                onPress={() => {
                  if (props?.onPracticeAvailabilityHandler && typeof props?.onPracticeAvailabilityHandler == 'function') {
                    props.onPracticeAvailabilityHandler();
                  }
                }}
                leftIcon={
                  <AntIcon
                    name="eyeo"
                    size={17}
                    color={props?.isPracticeAvailabilityView ? '#fff' : Colors.Custom.Gray500}
                  />
                }
              >
                <Text
                  size={'smSemibold'}
                  color={ props?.isPracticeAvailabilityView ? '#fff' : Colors.Custom.Gray500}
                >
                  {capitalizeText('Availability')}
                </Text>
              </Button> */}
        </VStack>
      </HStack>
    );
  };

  const getRefreshCalenderElement = (): JSX.Element => {
    return (
      <Tooltip
        label={intl.formatMessage({ id: 'dashboardCalendarRefreshButtonInfo' })}
        placement={'bottom right'}
      >
        <View alignItems={'center'}>
          <Pressable
            onPress={() => {
              props.onDateChange(props.currentDate);
            }}
            style={{ justifyContent: 'center' }}
          >
            <Icon
              as={MaterialCommunityIcon}
              name={'reload'}
              size={'6'}
              color={Colors.Custom.Gray500}
            />
          </Pressable>
        </View>
      </Tooltip>
    );
  }

  const calenderFilter = (calendarFilters: string[]) => {
    const filterData = (calendarFilters || ['day', 'week', 'month']).map((type, index) => {
      return {
        value: type,
        key: type,
        label: capitalizeText(type),
      };
    });
    return (
      <HStack style={{ height: 40 }}>
        <ModalActionAntSelect
          allowClear={false}
          showSearch={false}
          value={props.currentView}
          onChange={(type: any) => {
            props.onViewChange(type);
          }}
          data={filterData}
          optionProps={{ key: 'key', value: 'value', label: 'label' }}
          extraStyle={{ flex: 1 }}
          customStyle={{
            flex: 1,
            fontSize: 15,
            fontWeight: 400,
            width: 100,
            borderColor: Colors.Custom.Gray300,
            fontFamily:'Manrope-Medium'
          }}
        />
        {/* {(calendarFilters || ['day', 'week', 'month']).map((type) => {
        return (
          <VStack style={{marginHorizontal: 3}}>
            <Button
              style={{
                backgroundColor:
                  getTitle(intl, props.currentView) == getTitle(intl, type)
                    ? Colors.primary['300']
                    : '#fff',
                borderRadius: 20,
              }}
              onPress={() => {
                props.onViewChange(type);
              }}
              leftIcon={getCalenderIcon(
                type,
                getTitle(intl, props.currentView) == getTitle(intl, type)
              )}
            >
              <Text
                size={'smSemibold'}
                color={
                  getTitle(intl, props.currentView) == getTitle(intl, type)
                    ? '#fff'
                    : Colors.Custom.Gray500
                }
              >
                {capitalizeText(type)}
              </Text>
            </Button>
          </VStack>
        );
      })} */}
      </HStack>
    );
  };

  const calenderFilterPopover = () => {
    return (
      <VStack style={{ padding: 0, borderRadius: 16 }}>
        {['day', 'week', 'month'].map((type) => {
          return (
            <VStack style={{ marginHorizontal: 3 }}>
              <View style={{}}>
                <Button
                  style={{
                    flex: 1,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor:
                      getTitle(intl, props.currentView) == getTitle(intl, type)
                        ? Colors.primary['300']
                        : '#fff',
                  }}
                  onPress={() => {
                    props.onViewChange(type);
                  }}
                  leftIcon={getCalenderIcon(
                    type,
                    getTitle(intl, props.currentView) == getTitle(intl, type)
                  )}
                >
                  <Text
                    size={'smSemibold'}
                    color={
                      getTitle(intl, props.currentView) == getTitle(intl, type)
                        ? '#fff'
                        : Colors.Custom.Gray500
                    }
                  >
                    {capitalizeText(type)}
                  </Text>
                </Button>
              </View>
            </VStack>
          );
        })}
      </VStack>
    );
  };

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const getElement = () => {
    return (
      <View
        style={{
          padding: 5,
          borderRadius: 35,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button.Group isAttached>
          <Pressable
            onPress={() => {
              goBack();
            }}
            style={{ padding: 8, justifyContent: 'center' }}
          >
            <Icon
              as={AntIcon}
              name={'left'}
              size="4"
              color={Colors.Custom.mainPrimaryPurple}
            />
          </Pressable>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                !showDatePicker && props.onDateChange(new Date());
              },
            }}
            customProps={{
              btnText: showDatePicker ? (
                <Popover
                  content={
                    <VStack style={{ width: 300 }}>
                      <Calendar
                        className="custom-calendar"
                        value={getMomentObj(props.currentDate)}
                        fullscreen={false}
                        onSelect={(date) => props.onDateChange(date.toDate())}
                      />
                      <Divider />
                      <Button
                        variant={BUTTON_TYPE.PRIMARY}
                        onPress={() => props.onDateChange(new Date())}
                      >
                        <Text color={Colors.primary['300']}>Today</Text>
                      </Button>
                    </VStack>
                  }
                >
                  <HStack alignItems="center">
                    <Text
                      color={'#825AC7'}
                      size={'xsBold'}
                      style={{ fontSize: 14 }}
                      textAlign="center"
                      mr={2}
                    >
                      {getCalendarDateTitle(
                        props.currentView,
                        props.currentDate
                      )}
                    </Text>
                    <Feather name="calendar" size={20} color={'#825AC7'} />
                  </HStack>
                </Popover>
              ) : (
                getCalendarDateTitle(props.currentView, props.currentDate)
              ),
            }}
          />
          <Pressable
            onPress={() => {
              goNext();
            }}
            style={{ padding: 8, justifyContent: 'center' }}
          >
            <Icon
              as={AntIcon}
              name={'right'}
              size="4"
              color={Colors.Custom.mainPrimaryPurple}
            />
          </Pressable>
        </Button.Group>
      </View>
    );
  };

  // If no multi tenancy is enabled, return all locations & if multi tenancy is enabled, only return locations that the current user has access to
  const locationList = props.userLocations.filter((userLocationItem) =>
    isMultiTenancyEnabled
      ? currentUserAllowedLocations.includes(userLocationItem.uuid)
      : true
  );
  
  return (
    <View
      style={{
        backgroundColor: '#fff',
        paddingVertical: 4,
      }}
    >
      <View paddingX={4}>
        <HStack
          flex={1}
          space={2}
          alignItems={isSmallScreen ? 'flex-start' : 'center'}
          justifyContent="space-between"
          flexDirection={isSmallScreen ? 'column' : 'row'}
        >
          <Stack
            flex={2.5}
            space="2"
            flexDirection={'row'}
            style={{marginBottom: isSmallScreen ? 10 : 0}}
          >
            {props.enabledUserDropdown && (
              <View>
                <FilterWrapperComponent
                  textWeight={400}
                  label={
                    !filters.selectedUsers?.length
                      ? 'All Providers'
                      : undefined
                  }
                  value={filters.selectedUsers.length ? props.accountUsers.filter((item) => filters.selectedUsers.includes(item.uuid)).map(item => item.name) : []}
                  onClose={() => {
                    const itemList = props.selectedUserUUIDList.map((item) => item.uuid);
                    if (!isEqual(filters.selectedUsers, itemList)) {
                      props.onParticipantSelect(filters.selectedUsers);
                    }
                  }}
                >
                  <UserAutoComplete
                    minWidth={isSmallScreen ? 180 : 165}
                    selectedUserUUIDList={filters.selectedUsers}
                    userAutoCompleteType={
                      userAutoCompleteTypeCodes.INPUT_CHECKBOX_OPTIONS
                    }
                    allowClear={false}
                    onChange={(users: any) => {
                      setFilters((prev) => ({...prev, selectedUsers: users}));
                    }}
                    usersData={props?.accountUsers?.sort((a, b) => a.name.localeCompare(b.name))}
                    backgroundColor={'white'}
                  />
                </FilterWrapperComponent>
              </View>
            )}
            {props.enabledLocationDropdown &&
              props.userLocations.length > 0 && (
                <FilterWrapperComponent
                  label={
                    !filters.selectedLocations?.length
                      ? 'All Locations'
                      : undefined
                  }
                  textWeight={400}
                  value={filters.selectedLocations.length ? props.userLocations.filter((item) => filters.selectedLocations.includes(item.uuid)).map(item => item.name) : []}
                  onClose={() => {
                    const itemList = props.selectedLocationUUIDList.map((item) => item.uuid);
                    if (!isEqual(filters.selectedLocations, itemList)) {
                      props.onLocationSelect(filters.selectedLocations);
                    }
                  }}
                >
                  <LocationAutoComplete
                    hideSelectAll
                    minWidth={isSmallScreen ? 180 : 165}
                    selectedLocationName={props.selectedLocationName}
                    selectedLocationUUIDList={filters.selectedLocations}
                    allowClear={false}
                    onChange={(location: any) => {
                      setFilters((prev) => ({...prev, selectedLocations: location}));
                    }}
                    accountLocations={locationList}
                    autoCompleteType={
                      autoCompleteTypeCodes.INPUT_CHECKBOX_OPTIONS
                    }
                  />
                </FilterWrapperComponent>
              )}
            <View marginLeft={2}>
              {calenderFilter(
                props?.isPracticeAvailabilityView
                  ? ['day', 'week']
                  : ['day', 'week', 'month']
              )}
            </View>
          </Stack>
          <HStack flex={2} justifyContent={'space-evenly'}>
            {isSmallScreen ? <></> : getElement()}
          </HStack>

          <HStack
            flex={4.5}
            // flex={isSmallScreen ? 0.2 : 0.5}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              height: 44,
            }}
          >
            <View>{isSmallScreen ? getElement() : <></>}</View>
            {!isSmallScreen && <Spacer></Spacer>}

            {!props.isPracticeAvailabilityView ? (
              <FilterWrapperComponent
                onClose={() => {
                  const itemList = props.selectedAppointmentTypes || [];
                  if (!isEqual(filters.selectedAppointmentTypes, itemList)) {
                    props.onAppointmentTypeSelect?.(filters.selectedAppointmentTypes);
                  }
                }}
                textWeight={400}
                value={
                  props.selectedAppointmentTypes?.length
                    ? props.appointmentTypes
                        ?.filter((item) =>
                          item.id
                            ? props.selectedAppointmentTypes?.includes(item.id)
                            : false
                        )
                        ?.map((item) => item.eventName)
                    : ['All Appointment Types']
                }
                maxWidth={152}
              >
                {renderAppointmentTypes()}
              </FilterWrapperComponent>
            ) : null}
            {!props.isPracticeAvailabilityView ? (
              <FilterWrapperComponent
                onClose={() => {
                  const itemList = props.selectedAppointmentStatus || [];
                  if (!isEqual(filters.selectedAppointmentStatus, itemList)) {
                    props.onAppointmentStatusSelect?.(filters.selectedAppointmentStatus);
                  }
                }}
                textWeight={400}
                value={
                  props.selectedAppointmentStatus?.length
                    ? props.appointmentStatusList
                        ?.filter((item) =>
                          item.id
                            ? props.selectedAppointmentStatus?.includes(item.id)
                            : false
                        )
                        ?.map((item) => item.value)
                    : ['All Status']
                }
              >
                {renderAppointmentStatus()}
              </FilterWrapperComponent>
            ) : null}

            <>{getPracticeAvailabilityFilter()}</>

            {isSmallScreen && !props?.isPracticeAvailabilityView && false && (
              <Popover
                overlayInnerStyle={{padding: 0, borderRadius: 16}}
                overlayStyle={{padding: 0}}
                overlay={{padding: 0}}
                style={{padding: 0, borderRadius: 16}}
                placement={'left'}
                trigger="click"
                content={calenderFilterPopover}
                visible={isOpen}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 18,
                    borderWidth: 1,
                    borderColor: Colors.secondary['200'],
                    backgroundColor: Colors.secondary['100'],
                  }}
                  onPress={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <Feather
                    name="more-vertical"
                    style={[
                      {
                        marginLeft: 2,
                        marginRight: 2,
                        fontSize: 14.29,
                        color: Colors.Custom.Gray600,
                      },
                    ]}
                  />
                </Button>
              </Popover>
            )}

            <CustomAppointmentScheduleButton
              title={intl.formatMessage({id: 'schedule'})}
              onClick={(action: CalendarTopBarActions) => {
                props.onAction(action);
              }}
            />

            <View
              style={{
                width: 1,
                height: 20,
                backgroundColor: Colors.Custom.Gray200,
                marginLeft: 15,
                marginRight: 15,
              }}
            ></View>

            <ScheduleSuggestor
              refetchAppointments={props.refetchAppointments}
            />

            <View
              style={{
                width: 1,
                height: 20,
                backgroundColor: Colors.Custom.Gray200,
                marginLeft: 15,
                marginRight: 3,
              }}
            ></View>

            <View marginLeft={3}>{getRefreshCalenderElement()}</View>

            {!isEmployerRole() && (
              <AutomationSettingBtn
                flowType={FlowType.scheduling}
                settingsPath={'/admin/schedule/availability'}
              />
            )}
          </HStack>
        </HStack>
      </View>
      {/* <Divider /> */}
      {/* <View>
        <HStack marginTop={2}>
          <Spacer />
          <Text fontSize={20}>
            {getCalendarDateTitle(props.currentView, props.currentDate)}
          </Text>
          <Spacer />
        </HStack>
      </View> */}
    </View>
  );
};

export default CalendarTopBar;
