import {useLazyQuery, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {GET_LOCATION_GROUPS} from '../../../../services/Appointment/AppointmentQueries';

export const useLocationGroup = () => {
  const [getLocationGroups, {data, loading, error}] = useLazyQuery(
    GET_LOCATION_GROUPS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    getLocationGroups();
  }, []);

  const transformedLocationGroups =
    data?.locationGroups?.map((group: any) => ({
      code: group.id,
      display: group.groupName,
    })) || [];

  return {
    locationGroups: transformedLocationGroups || [],
    loading,
    error,
  };
};

export default useLocationGroup;
