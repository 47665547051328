import React, { useEffect, useState } from "react";
import { getPatientCoverage } from "../../../services/CommonService/AidBoxService";
import { getEhrConfig } from "../../../utils/capabilityUtils";
import { Coverage, Extension, Period } from 'fhir/r4';
import { Skeleton, Stack, Text } from "native-base";
import { getExtensionByUrl } from "../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils";
import { EXTENSION_URLS } from "../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant";
import { ATHENA_COVERAGE_TYPE, ELATION_COVERAGE_TYPE } from "./constant";
import { Colors } from "../../../styles/Colors";

interface IHealthPlan {
  productType: string,
  period?: Period
};

export const MemberHealthPlanView = (props: { patientId: string, patientLocationUuid: string }) => {
  const ehrConfig = getEhrConfig(props?.patientLocationUuid, '');
  const [loading, setLoading] = useState<boolean>(false);
  const [healthPlans, setHealthPlans] = useState<IHealthPlan[]>([]);

  const isAthenaSpecificHealthPlan = (coverageProductType: Extension) => {
    const productType: string = coverageProductType?.valueString || '';
    const athenaCoverageTypeArray = Object.values(ATHENA_COVERAGE_TYPE || {}) || [];
    return athenaCoverageTypeArray.includes(productType);
  }

  const isElationSpecificHealthPlan = (coverageProductType: Extension) => {
    const productType: string = coverageProductType?.valueString || '';
    const athenaCoverageTypeArray = Object.values(ELATION_COVERAGE_TYPE || {}) || [];
    return athenaCoverageTypeArray.includes(productType);
  }

  const setHealthPlanData = async () => {
    setLoading(true);
    try {
      if (!ehrConfig?.isAthena && !ehrConfig?.isElation) {
        setHealthPlans([]);
        setLoading(false);
      }
      const coverageResponse = await getPatientCoverage(props?.patientId, props?.patientLocationUuid);
      if (coverageResponse?.data?.entry?.length) {
        const  coveragePlans: IHealthPlan[] = [];
        const coverageEntries: any[] = coverageResponse?.data?.entry;
        for (const coverageEntry of coverageEntries) {
          const resource: Coverage = coverageEntry?.resource || {};
          const coverageProductType: Extension = getExtensionByUrl(resource.extension || [] || [], EXTENSION_URLS.coverageProductType);
          if (coverageProductType && ehrConfig?.isAthena && isAthenaSpecificHealthPlan(coverageProductType)) {
            coveragePlans.push({
              productType: coverageProductType?.valueString || '',
              period: resource?.period,
            });
          }
          if (coverageProductType && ehrConfig?.isElation && isElationSpecificHealthPlan(coverageProductType)) {
            coveragePlans.push({
              productType: coverageProductType?.valueString || '',
              period: resource?.period,
            });
          }
        }
        setHealthPlans(coveragePlans)
      }
      setLoading(false);
    } catch(error) {

      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      setHealthPlanData();
    }
  }, []);

  return (
    <>
      {loading && (
        <Skeleton.Text lines={1}></Skeleton.Text>
      )}
      {!loading && !!healthPlans?.length && (
        (healthPlans || []).map(healthPlan => {
          return (
            <Stack direction={'column'} key={`${healthPlan?.productType}`}>
              <Text wordBreak={'break-word'} color={Colors.Custom.Gray500} size={'smLight'}>{healthPlan?.productType}</Text>
              {(healthPlan?.period?.start && healthPlan?.period?.end) && (
                <Text wordBreak={'break-word'} color={Colors.Custom.Gray400} size={'smLight'}>
                  {`(${healthPlan?.period?.start} - ${healthPlan?.period?.end})`}
                </Text>
              )}
            </Stack>
          )
        })
      )}
    </>
  );
}
