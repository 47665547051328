import { Colors } from "../../../styles/Colors";
import {Content} from 'antd/lib/layout/layout';
import { Collapse, Tooltip } from 'antd';
import {Box, Divider, HStack, Image, Text, useToast, View, VStack} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {PERSON_TYPES} from '../../../constants';
import {CommonDataContext} from '../../../context/CommonDataContext';
import { getEnabledFormComponents, getPatientEHRUrl } from '../../../utils/capabilityUtils';
import {getFoldAccessToken, getAccountUUID, isEnableCareProgram, isWearableIntegrationEnabled} from '../../../utils/commonUtils';
import PamiIconSvg from '../../common/Svg/PamiIconSvg';
import {FHForm} from '../../RightSideContainer/Forms/FHFormio';
import {
  componentKeys,
  HISTORY_COMPONENT_LIST,
  INTAKE_COMPONENT_LIST,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  VITAL_INTAKE_COMPONENT,
} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {FormViewType} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
// import {PersonDetail} from './PersonDetail/PersonDetail.web';
import {ILeftContainerProps, IPatientRelevantData} from './interfaces';
import './LeftContainer.css';
// import AllergyListView from './PamiDetail/AllergyListView';
import EntityEventsListView from './PamiDetail/EntityEventsListView';
import { RightOutlined } from "@ant-design/icons";
import {useIntl} from 'react-intl';
import FormCarePlanTemplates from "../../common/FormCarePlanTemplates/FormCarePlanTemplates";
import ActivityPanelView from "../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/ActivityPanelView";
import Stack from "../../common/LayoutComponents/Stack";
import LabReports from "../../RightSideContainer/Forms/FHFormio/CustomComponents/LabReports/LabReports";
import ClinicalAssessmentPanelView from "../../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/ClinicalAssessmentPanelView";
import { Vital } from "../../../utils/VitalUtils";
import { GRAPH_TYPES } from "../MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities";
import { getRelevantClinicalCodes } from "../../../services/CommonService/OrderService";
import useLatestCareProgramEnrollmentDate from "./PamiDetail/hooks/useCareProgramEnrollmentDate";
import { CARE_PROGRAM_TYPES } from "../../../constants/MlovConst";
import { RELEVANT_DATA_CONSTANTS, RESOURCE_CONFIGURATION_TYPES } from "./PAMIConstants";
import { showToast, ToastType } from "../../../utils/commonViewUtils";
import FormComponentLoader from "../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/FormComponentLoader/FormComponentLoader";
import HomeMonitoring from "./HomeMonitoring";
import useEHRCapabilities from "../../../screens/BusinessStudio/useEHRCapabilities";

// import ImmunizationListView from './PamiDetail/ImmunizationListView';
// import MedicationListView from './PamiDetail/MedicationListView';
// import ProblemListView from './PamiDetail/ProblemListView.web';
export const PAMIView = (props: ILeftContainerProps) => {
  const {height} = Dimensions.get('window');
  let finalHeight = height - 212;
  if (props.personType === PERSON_TYPES.LEAD) {
    finalHeight = height - 250;
  }
  const {personData} = props;
  const contextData = useContext(CommonDataContext);
  const intl = useIntl();
  const foldAccessToken = getFoldAccessToken();
  const accountUUID = getAccountUUID();
  const contactUUID = personData?.contactUUID;
  const userSettings = contextData.userSettings;

  const patientId =
    props.personData?.patientId || props.personData?.patientUuid;
  const accountLocationUuid = props.personData?.accountLocationUuid || '';
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const [enabledVitalComponents] = useState(getEnabledFormComponents(VITAL_INTAKE_COMPONENT, ehrCapabilities));
  const [enabledIntakeComponents] = useState(getEnabledFormComponents(INTAKE_COMPONENT_LIST, ehrCapabilities));
  const [enabledHistoryComponents] = useState(getEnabledFormComponents(HISTORY_COMPONENT_LIST, ehrCapabilities));
  const openInEhrUrl = getPatientEHRUrl(patientId || '', accountLocationUuid, '');
  const { Panel } = Collapse;
  const PANEL_KEYS = {
    VITAL: 'VITAL',
    PAMI: 'PAMI',
    HISTORY: 'HISTORY',
  }

  const toast = useToast();

  const [componentState, setComponentState] = useState<{
    dataAvailability: {[index: string]: boolean};
    dataLoading: {[index: string]: boolean};
    pamiSectionActiveKeys: string[];
    historySectionActiveKeys: string[];
    patientRelevantData: IPatientRelevantData | null;
    patientRelevantDataLoading: boolean
  }>({
    dataAvailability: {},
    dataLoading: {},
    pamiSectionActiveKeys: [],
    historySectionActiveKeys: [],
    patientRelevantData: null,
    patientRelevantDataLoading: false
  });

  const updateLoadingStatus = useCallback((componentKey: string, isLoading: boolean, isDataAvailable: boolean) => {
    if (componentKey) {
      setComponentState((prev) => {
        const dataAvailability = {
          ...prev.dataAvailability,
          [componentKey]: isDataAvailable,
        };
        const dataLoading = {
          ...prev.dataLoading,
          [componentKey]: isLoading,
        }
        const pamiDataAvailable = dataAvailability[componentKeys.CONDITIONS] ||
          dataAvailability[componentKeys.ALLERGIES] ||
          dataAvailability[componentKeys.MEDICATIONS] ||
          dataAvailability[componentKeys.IMMUNIZATIONS];
        const pamiDataLoading = dataLoading[componentKeys.CONDITIONS] ||
          dataLoading[componentKeys.ALLERGIES] ||
          dataLoading[componentKeys.MEDICATIONS] ||
          dataLoading[componentKeys.IMMUNIZATIONS];

        const historyDataAvailable = dataAvailability[componentKeys.SURGICAL_HISTORY] ||
          dataAvailability[componentKeys.FAMILY_HISTORY] ||
          dataAvailability[componentKeys.SOCIAL_HISTORY];
        const historyDataLoading= dataLoading[componentKeys.SURGICAL_HISTORY] ||
          dataLoading[componentKeys.FAMILY_HISTORY] ||
          dataLoading[componentKeys.SOCIAL_HISTORY];

        return {
          ...prev,
          pamiSectionActiveKeys: pamiDataAvailable && !pamiDataLoading ? [PANEL_KEYS.PAMI] : [],
          historySectionActiveKeys: historyDataAvailable && !historyDataLoading ? [PANEL_KEYS.HISTORY] : [],
          dataAvailability: dataAvailability,
          dataLoading: dataLoading,
        };
      });
    }
  }, []);

  const getPatientRelevantDataByKey = (key: string, clinicalRelevantData?: IPatientRelevantData) => {
    if(clinicalRelevantData){
      return clinicalRelevantData?.[key]?.codes || [];
    }
    return componentState?.patientRelevantData?.[key]?.codes || [];
  }

  const [intakeOptions, setIntakeOptions] = useState({
    foldAccessToken,
    patientId,
    accountUUID,
    contactId: contactUUID,
    backgroundSaveEnabled: true,
    componentView: FormViewType.compact,
    ehrCapabilities,
    accountLocationUuid,
    openInEhrUrl,
    updateLoadingStatus,
    isCareProgramEnabled: false,
    relevantVitals: getPatientRelevantDataByKey(RELEVANT_DATA_CONSTANTS.VITAL),
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
  });

  const ccmDate = undefined;
  const ccmDateLoading = false;
  const isLoading = ccmDateLoading;
  const isWearableEnabled = isWearableIntegrationEnabled(userSettings, personData?.contactUUID as string);

  return (
    <>
    {isLoading ? <FormComponentLoader/> :
    <Content
      className="common-scroll common-collapse pami-left-view"
      style={{overflow: 'scroll'}}
    >
      {/* <FormCarePlanTemplates contactId={contactUUID || ''} locationId={accountUUID} /> */}

      <VStack space={0}>
          {enabledVitalComponents.length > 0 && (
            <FHForm
              optionData={intakeOptions}
              components={enabledVitalComponents}
            />
          )}
          {/* NOTE: CODE IS KEPT FOR FUTURE REFERENCE
          {isCareProgramEnabled && !isLoading && (
            <ActivityPanelView
              contactId={contactUUID}
              accountLocationId={accountLocationUuid}
              patientId={patientId}
              ccmDate={ccmDate}
              relevantCodes={getPatientRelevantDataByKey(RELEVANT_DATA_CONSTANTS.ACTIVITY)}
            />
          )} */}

          <ClinicalAssessmentPanelView
            contactId={contactUUID || ''}
            ccmDate={ccmDate}
          />

          {/* NOTE: CODE IS KEPT FOR FUTURE REFERENCE
          {/* {isCareBoardEnabled && !isLoading && (
            <LabReports
              contactId={contactUUID}
              accountLocationId={accountLocationUuid}
              patientId={patientId}
              ccmDate={ccmDate}
              relevantCodes={getPatientRelevantDataByKey(RELEVANT_DATA_CONSTANTS.LAB_RESULT)}
            />
          )} */}

          {
            isWearableEnabled && (
              <HomeMonitoring
                personType={props.personType}
                personData={props.personData}
              />
            )
          }

          {enabledIntakeComponents.length > 0 && !isLoading && (
            <>
              <HStack
                alignItems={'center'}
                space={2}
                backgroundColor={Colors.Custom.Gray100}
                margin={2}
              >
              </HStack>
              <Collapse
                activeKey={componentState.pamiSectionActiveKeys}
                onChange={(activeKeys) => {
                  setComponentState((prev) => ({...prev, pamiSectionActiveKeys: activeKeys as string[]}));
                }}
                expandIconPosition="end"
                expandIcon={({ isActive }) => {
                  const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
                  return (
                    <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                      <RightOutlined rotate={isActive ? 90 : 0} />
                    </Tooltip>
                    )}}
                style={{
                  borderRadius: 0,
                  borderWidth: 0,
                  padding: 0,
                  background: "Colors.Custom.Gray100",
                }}>
                <Panel forceRender key={PANEL_KEYS.PAMI} header={<><PamiIconSvg />
                  <Text
                    color={'#0e0333'}
                    fontSize={20}
                    fontWeight={600}
                    fontFamily={'Manrope-Bold'}
                    marginLeft={2}
                  >
                    PAMI
                  </Text>
                </>}>
                  <div>
                    <FHForm optionData={intakeOptions} components={enabledIntakeComponents} />
                  </div>
                </Panel>
              </Collapse>
            </>
          )}
        {/* <EntityEventsListView patient={personData} /> */}
          {enabledHistoryComponents.length > 0 && !isLoading && (
            <>
              <HStack
                alignItems={'center'}
                space={2}
                backgroundColor={Colors.Custom.Gray100}
                margin={2.5}
              >
              </HStack>

              <Collapse
                activeKey={componentState.historySectionActiveKeys}
                onChange={(activeKeys) => {
                  setComponentState((prev) => ({...prev, historySectionActiveKeys: activeKeys as string[]}));
                }}
                expandIconPosition="end"
                expandIcon={({ isActive }) => {
                  const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
                  return (
                    <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                      <RightOutlined rotate={isActive ? 90 : 0} />
                    </Tooltip>
                  )
                }}
                style={{
                  borderRadius: 0,
                  borderWidth: 0,
                  padding: 0,
                  background:"Colors.Custom.Gray100",
                }}
              >
                <Panel forceRender key={PANEL_KEYS.HISTORY} header={<>
                  <PamiIconSvg />
                  <Text
                    color={'#0e0333'}
                    fontSize={20}
                    fontWeight={600}
                    fontFamily={'Manrope-Bold'}
                    marginLeft={2}
                  >
                    History
                  </Text></>}>
                  <FHForm
                    optionData={intakeOptions}
                    components={enabledHistoryComponents}
                  />
                </Panel>
              </Collapse>
            </>
        )}
      </VStack>
    </Content>
    }
    </>

  );
};
