import {AxiosInstance, AxiosResponse} from "axios";
import BaseService from "../../../../../services/CommonService/BaseService";
import {
  IAssigneeUserResInterface,
  ICreateUserConversationFilterBody,
  IUpdateConversationLastSeensParams,
  IUpdateUserConversationFilterBody,
  IResetUserConversationFilterBody,
  IUserConversationFilterResp,
  IConversationStatusChangedData,
  IConversationStatusChangedRes
} from "./Interfaces";
import {ITranscriptionParams, ITwilioSidData} from "../../CallLogs/interface";

export const assignConversationAPI = (args: {
  body: {
    conversationUuid: string;
    assigneeUuid: string;
  };
}) => {
  const {body} = args;
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/conversation/assignment';
  return baseService.post<IAssigneeUserResInterface>(url, body);
};
export const unAssignConversationAPI = (conversation: {
  body: {
    conversationUuid: string;
  };
}) => {
  const {body} = conversation;
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/conversation/assignment';
  return baseService.post<IAssigneeUserResInterface>(url, body);
};

export const updateConversationStatusAPI = (statusCode: string, conversationUUID: string, isNotSendMessage?: boolean) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;

  const url = `crm-nest/conversation/status`
  return axiosService.post<IConversationStatusChangedRes>(url,
    {
      conversationStatus: statusCode,
      conversationUuid: conversationUUID,
      isNotSendMessage: isNotSendMessage
    },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};
export const changePrimaryContactAPI = (fromContactUuid: string, toContactUuid: string, conversationUuid: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  const body = {
    fromContactUuid,
    toContactUuid
  };

  const url = `crm-nest/conversation/${conversationUuid}/contact`
  return axiosService.put(url,
    body,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};


export const changePrimaryContactForAllConversationForSelectedContact = (fromContactUuid: string, toContactUuid: string, channelType: string, currentConversationUuid: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  const body = {
    fromContactUuid,
    toContactUuid,
    channelType,
    currentConversationUuid
  };
  const url = `crm-nest/conversation/contact`
  return axiosService.put(url,
    body,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};

export const getTranscriptionDataApi = (callId: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.get(`cloud-telephony/api/v1/call-log/${callId}/transcription`);
};

export const getTranscriptionDataFromAwsApi = (transcriptionParams: ITranscriptionParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;

  const url = `cloud-telephony/api/v1/call-log/twilio/transcription`
  return axiosService.post(url,
    transcriptionParams,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};

export const getSignedRecordingApi = (recordingUrlParams: ITwilioSidData) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;

  const accountSid = recordingUrlParams.accountSid;
  const recordingSid = recordingUrlParams.recordingSid;
  return axiosService.get(`cloud-telephony/api/v1/call-log/${accountSid}/${recordingSid}/recordingFile`);
};

export const getAllUsersWithUnreadMsgCountAPI = (searchString: any) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;

  const url = `crm-nest/user/getAllUsers`
  return axiosService.post(url,
    {
      searchString: searchString,
    },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
}
export const getAllAccountUsersWithUnreadMsgCountAPI = () => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;

  const url = `crm-nest/user/getAllAccountUsers`
  return axiosService.post(url,
    {
    },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
}
export const updateConversationLastseenAt = async (body: IUpdateConversationLastSeensParams) => {
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.post(
      'crm-nest/conversation/conversation-lastseen',
      body,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    return response;
  } catch (error) {

    return error;
  }
};


export const getUserConversationFilter = async (body: {
  filterCodes: string[];
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get<{filterList: IUserConversationFilterResp[]}>(
    'crm-nest/conversation/user-filter',
    {
      params: body,
      headers: {
        'content-type': 'application/json',
      },
    }
  );

};



export const createUserConversationFilter = async (body: {
  filterCode: string;
  filterValue: any;
  defaultFilterValue?: any;
  additionalData?: any;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post<IUserConversationFilterResp>(
    'crm-nest/conversation/user-filter',
    body,
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );
};


export const updateUserConversationFilter = async (body: {
  id: string;
  filterCode: string;
  filterValue: any;
  additionalData?: any;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.put(
    'crm-nest/conversation/user-filter',
    body,
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );
};


export const resetUserConversationFilter = async (body: {
  id: string;
  filterCode: string;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.put(
    'crm-nest/conversation/reset-user-filter',
    body,
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );
};






