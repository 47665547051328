import {IInbox, IInboxData} from '../../../../../services/Inbox/interfaces';
import {CHANNEL_TYPE} from '../../Conversations/ConversationConst';
import { EMAIL_PLATFORMS } from '../IntegrationCreate/EmailInboxCreate/EmailInboxConst';
import { isOutlookEmailInbox } from '../IntegrationCreate/EmailInboxCreate/EmailInboxUtils';
import {INBOX_TYPE, INTEGRATION_CONSTANTS} from '../constants';

export const formatIntegrationsData = (dataList: IInboxData) => {
  const inboxList: any = [];
  dataList.inboxes?.forEach((singleInboxData: IInbox) => {
    const assignedAgents: any = [];
    const key = singleInboxData.id;
    const title = singleInboxData.name;
    const channel_id = singleInboxData.channelId;
    const inbox_type = getInboxType(singleInboxData);
    const actions = '';
    let phoneNumber = '';
    singleInboxData.inboxMembers.forEach((agent: any) => {
      assignedAgents.push(agent?.user?.name);
    });
    if (singleInboxData.channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      phoneNumber = singleInboxData?.channelTwilioSms?.phoneNumber;
    }
    inboxList.push({
      agents: assignedAgents,
      key,
      title,
      phoneNumber,
      inbox_type,
      actions,
      channel_id,
      isDeleted: singleInboxData?.isDeleted,
      singleInboxData: singleInboxData,
    });
  });
  return inboxList;
};

export const formatCreateInboxData = (dataList: any) => {
  const inboxData: any = {};
  inboxData.channelType = getChannelType(dataList.channel);
  inboxData.name = dataList.channelName
    ? dataList.channelName
    : dataList.websiteName;
  inboxData.forwardToEmail = (Math.random() + 1).toString(36).substring(7);
  inboxData.websiteToken = uuid();
  dataList.greetingStatus
    ? (inboxData.greetingEnabled = dataList.greetingStatus)
    : (inboxData.greetingEnabled = false);
  dataList.greetingMessage
    ? (inboxData.greetingMessage = dataList.greetingMessage)
    : (inboxData.greetingMessage = '');
  inboxData.websiteUrl = dataList.websiteDomain;
  inboxData.welcomeTagline = dataList.welcomeTagline;
  inboxData.welcomeTitle = dataList.welcomeHeading;
  inboxData.widgetColor = dataList.widgetColor;
  dataList.enablePreChat == 'Yes'
    ? (inboxData.preChatFormEnabled = true)
    : (inboxData.preChatFormEnabled = false);
  if (dataList.enablePreChat) {
    inboxData.preChatFormOptions = {
      require_email: dataList.visitorsProvideDetails,
      pre_chat_message: dataList.preChatMessage,
    };
  } else {
    inboxData.preChatFormOptions = {};
  }
  return inboxData;
};

export const getCreateInboxAPIData = (inboxData: any) => {
  const formattedData: any = {};
  const channel: any = {};

  formattedData.name = inboxData.name;
  formattedData.greeting_enabled = inboxData.greetingEnabled;
  formattedData.greeting_message = inboxData.greetingMessage;
  channel.type = INTEGRATION_CONSTANTS.WEB_WIDGET;
  channel.website_url = inboxData.websiteUrl;
  channel.welcome_title = inboxData.welcomeTitle;
  channel.welcome_tagline = inboxData.welcomeTagline;
  channel.agent_away_message = 'Agent is currently away';
  channel.widget_color = inboxData.widgetColor;
  formattedData.channel = channel;
  return formattedData;
};

export const formatEditWidgetSettingsOld = (
  channelData: any,
  inboxData: any
): any => {
  const currentData: any = {};
  currentData.websiteUrl = channelData?.websiteUrl;
  currentData.welcomeTagline = channelData?.welcomeTagline;
  currentData.welcomeTitle = channelData?.welcomeTitle;
  currentData.widgetColor = channelData?.widgetColor;
  currentData.replyTime = getReplyTime(channelData?.replyTime);
  if (channelData?.featureFlags == 1 || channelData?.featureFlags == 3) {
    currentData.filePicker = true;
  } else {
    currentData.filePicker = false;
  }

  if (channelData?.featureFlags == 2 || channelData?.featureFlags == 3) {
    currentData.emojiPicker = true;
  } else {
    currentData.emojiPicker = false;
  }

  currentData.greetingEnabled = toEnableDisable(inboxData?.greetingEnabled);
  currentData.greetingMessage = inboxData?.greetingMessage;

  currentData.allowMessagesAfterResolved = toEnableDisable(
    inboxData?.allowMessagesAfterResolved
  );
  currentData.csatSurveyEnabled = toEnableDisable(inboxData?.csatSurveyEnabled);
  currentData.enableAutoAssignment = toEnableDisable(
    inboxData?.enableAutoAssignment
  );
  currentData.enableEmailCollect = toEnableDisable(
    inboxData?.enableEmailCollect
  );

  currentData.name = inboxData?.name;

  return currentData;
};

export const formatEditWidgetSettingsNew = (dataList: any) => {
  const currentData: any = {};
  currentData.allowMessagesAfterResolved = toBoolean(
    dataList.allowAfterResolve
  );
  currentData.csatSurveyEnabled = toBoolean(dataList.enableCSAT);
  currentData.enableEmailCollect = toBoolean(dataList.enableEmailCollect);
  currentData.enableAutoAssignment = toBoolean(dataList.enableAutoAssignment);
  currentData.greetingEnabled = toBoolean(dataList.greeting);
  currentData.greetingEnabled == true
    ? (currentData.greetingMessage = dataList.greetingMessage)
    : (currentData.greetingMessage = null);
  currentData.websiteUrl = dataList.websiteUrl;
  currentData.welcomeTagline = dataList.welcomeTagline;
  currentData.welcomeTitle = dataList.welcomeTitle;
  currentData.widgetColor = dataList.color;
  currentData.name = dataList.name;
  currentData.widgetColor = dataList.color;
  currentData.replyTime = getReplyTimeNumeric(dataList.replyTime);
  currentData.featureFlags = getFeatureFlags(
    dataList.filePicker,
    dataList.emojiPicker
  );
  currentData.id = dataList.id;
  currentData.channelId = dataList.channel_id;
  return currentData;
};

export const formatEditWidgetConfigOld = (channelData: any): any => {
  const currentData: any = {};
  currentData.hmacToken = channelData?.hmacToken;
  currentData.websiteToken = channelData?.websiteToken;
  currentData.hmacMandatory = channelData?.hmacMandatory;
  return currentData;
};

export const formatEditCommonBusinessHoursOld = (inboxData: any): any => {
  const currentData: any = {};
  currentData.outOfOfficeMessage = inboxData?.outOfOfficeMessage;
  currentData.timezone = inboxData?.timezone;
  currentData.workingHoursEnabled = inboxData?.workingHoursEnabled;
  currentData.id = inboxData?.id;
  return currentData;
};

export const formatEditCommonBusinessHoursNew = (inboxData: any): any => {
  const currentData: any = {};
  currentData.id = inboxData?.id;
  currentData.timezone = inboxData?.timezone;
  currentData.workingHoursEnabled = inboxData?.businessAvailability;
  currentData.workingHoursEnabled
    ? (currentData.outOfOfficeMessage = inboxData?.unavailableMessage)
    : (currentData.outOfOfficeMessage = null);
  return currentData;
};

export const formatEditWidgetPreChatOld = (channelData: any): any => {
  const currentData: any = {};
  channelData?.preChatFormEnabled
    ? (currentData.preChatFormEnabled = 'Yes')
    : (currentData.preChatFormEnabled = 'No');

  currentData.preChatMessage =
    channelData?.preChatFormOptions?.pre_chat_message;

  currentData.visitorsProvideDetails =
    channelData?.preChatFormOptions?.require_email;

  currentData.id = channelData?.id;

  return currentData;
};

export const formatEditWidgetPreChatNew = (channelData: any): any => {
  const currentData: any = {};

  channelData?.enablePreChat == 'Yes'
    ? (currentData.preChatFormEnabled = true)
    : (currentData.preChatFormEnabled = false);

  currentData.id = channelData?.id;
  currentData.preChatFormOptions = {
    require_email: channelData?.visitorsProvideDetails,
    pre_chat_message: channelData?.preChatMessage,
  };
  return currentData;
};

export const getAgentRecord = (agentsList: any) => {
  const agentsRecord: any = [];
  agentsList?.accountUsers?.forEach((item: any) => {
    agentsRecord.push({
      agentName: item?.user?.name,
      agentId: item?.user?.id,
    });
  });

  return agentsRecord;
};

export const getAgentBotRecord = (agentBots: any) => {
  const agentBotsRecord: any = [];
  agentBots?.forEach((item: any) => {
    agentBotsRecord.push({
      agentBotName: item.name,
      agentBotId: item.id,
    });
  });
  agentBotsRecord.push({
    agentBotName: 'None',
    agentBotId: -1,
  });

  return agentBotsRecord;
};

export const getAssignedAgents = (agentsList: any, assignedAgentsData: any) => {
  const assignedAgents: any = [];
  const allAgentsHash: any = {};
  agentsList.forEach((item: any) => {
    allAgentsHash[item.agentId] = item.agentName;
  });
  assignedAgentsData?.inboxMemberAggregate?.nodes?.forEach((item: any) => {
    assignedAgents.push({
      agentName: allAgentsHash[item.userId],
      agentId: item.userId,
    });
  });
  return assignedAgents;
};

export const getUpdatedAgents = (
  finalAgents: any,
  initialAgents: any,
  allAgentList: any
) => {
  const agentIdToAdd: any = [];
  const agentIdToDelete: any = [];

  initialAgents.forEach((initialAgent: any) => {
    if (
      !finalAgents.some(
        (finalAgent: any) => initialAgent.agentName == finalAgent
      )
    ) {
      agentIdToDelete.push(initialAgent.agentId);
    }
  });

  finalAgents.forEach((finalAgent: any) => {
    if (
      !initialAgents.some(
        (initialAgent: any) => initialAgent.agentName == finalAgent
      )
    ) {
      const data = allAgentList.find(
        (agent: any) => agent.agentName == finalAgent
      );
      agentIdToAdd.push(data.agentId);
    }
  });

  return {agentIdToAdd, agentIdToDelete};
};

export const getAgentObject = (agentList: any, inboxId: any) => {
  const agentsToAdd: any = [];
  agentList.forEach((agent: any) => {
    agentsToAdd.push({
      userId: agent,
      inboxId,
    });
  });
  return agentsToAdd;
};

const getInboxType = (singleInboxData: IInbox) => {
  const inboxType = singleInboxData?.channelType.split('::')[1];
  if (inboxType === 'WebWidget') {
    return INBOX_TYPE.WEBSITE;
  } else if (singleInboxData?.channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
    return INBOX_TYPE.SMS;
  } else if (singleInboxData?.inboxType === EMAIL_PLATFORMS.OUTLOOK) {
    return INBOX_TYPE.OUTLOOK;
  } else if (singleInboxData?.inboxType === EMAIL_PLATFORMS.MAILGUN) {
    return INBOX_TYPE.DEFAULT;
  } else if (singleInboxData?.inboxType === EMAIL_PLATFORMS.GMAIL) {
    return INBOX_TYPE.GMAIL;
  } else {
    return inboxType;
  }
};

const getChannelType = (channel: string) => {
  if (channel === 'Website') {
    return 'Channel::WebWidget';
  } else {
    return `Channel::${channel}`;
  }
};

const getReplyTime = (value: number) => {
  switch (value) {
    case 0:
      return INTEGRATION_CONSTANTS.REPLY_MINUTES;

    case 1:
      return INTEGRATION_CONSTANTS.REPLY_HOURS;

    case 2:
      return INTEGRATION_CONSTANTS.REPLY_DAYS;

    default:
      return INTEGRATION_CONSTANTS.REPLY_MINUTES;
  }
};

const getReplyTimeNumeric = (value: string) => {
  switch (value) {
    case INTEGRATION_CONSTANTS.REPLY_MINUTES:
      return 0;

    case INTEGRATION_CONSTANTS.REPLY_HOURS:
      return 1;

    case INTEGRATION_CONSTANTS.REPLY_DAYS:
      return 2;

    default:
      return INTEGRATION_CONSTANTS.REPLY_MINUTES;
  }
};

export const getFeatureFlags = (filePicker: boolean, emojiPicker: boolean) => {
  if (filePicker && emojiPicker) {
    return 3;
  } else if (filePicker) {
    return 1;
  } else if (emojiPicker) {
    return 2;
  } else {
    return 0;
  }
};

export const getHours = (timeString: string) => {
  const hour = parseInt(timeString.split(':')[0]);
  const amOrPm = timeString.split(':')[2];
  if (amOrPm == 'PM' && hour != 12) {
    return hour + 12;
  } else if (amOrPm == 'AM' && hour == 12) {
    return 0;
  } else {
    return hour;
  }
};

export const toBoolean = (value: string) => {
  return value == 'Enabled' ? true : false;
};

const toEnableDisable = (value: boolean) => {
  return value ? 'Enabled' : 'Disabled';
};

const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
