import { DatePicker, Radio, RadioChangeEvent, Select as SelectAntd, Tooltip } from 'antd';
import { debounce } from 'lodash';
import {
  Box,
  Button,
  FormControl,
  HStack,
  Icon,
  Image,
  Input,
  Pressable,
  Select,
  Spinner,
  Text,
  TextArea,
  Toast,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BUTTON_TYPE,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  MLOV_CATEGORY,
  TRACKING_RESOURCE_TYPE
} from '../../../../../../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../../../../../../constants/ActionConst';
import { MLOV_CODES } from '../../../../../../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../../../../../../context/CommonDataContext';
import {
  addOrUpdateProduct
} from '../../../../../../../../../../services/ProductAndServices/ProductAndServicesAPI';
import { Colors } from '../../../../../../../../../../styles';
//import {getValueFromList} from '../../../../../../../../../../utils/commonUtils';
import {
  addDaysInDate,
  getDateStrFromFormat,
  getJourneyStartDateWithOffset,
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
  getStartOfDay,
  isPastDay,
} from '../../../../../../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../../../../../utils/mlovUtils';
import { DisplayText } from '../../../../../../../../../common/DisplayText/DisplayText';
import { JourneyDurationInput } from '../../../../../../../../Journeys/AddOrUpdateJourney/DetailsAndMembers/DetailsAndMembers';
import {
  JOURNEY_PURCHASE_TRIGGER_EVENT,
  JOURNEY_TRIGGER_EVENTS,
} from '../../../../../../../../Journeys/AddOrUpdateJourney/JourneyTabs';
import { IMultiSelectTagObj } from '../../../../../ProductTableView/ProductUtils/interfaces';
import {
  getOptionTypesObjList,
  getTaxonObjList,
} from '../../../../../ProductTableView/ProductUtils/ProductTableUtils';
import { IMemberShipProduct } from '../../../../../ProductTableView/ProductUtils/stripeRespInterface';
import { ISingleProductApiRespData } from '../../../../interface';
//import TaxonsMultiSelect from '../SearchComponents/TaxonsMultiSelect';
import { useLazyQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../../../../constants/Configs';
import { GET_JOURNEY_COUNT_FOR_NAME } from '../../../../../../../../../../services/CareJourney/CareJourneyQueries';
import { GetPackages } from '../../../../../../../../../../services/Stripe/StripeServices';
import { getEnabledVitalsFromCapability, getVitalListFromCapability } from '../../../../../../../../../../utils/capabilityUtils';
import { getAccountUUID, isJourneyPackagesEnabled } from '../../../../../../../../../../utils/commonUtils';
import { ModalActionAntSelect } from '../../../../../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { FoldButton } from '../../../../../../../../../CommonComponents/FoldButton/FoldButton';
import { UploadPatientDocument } from '../../../../../../../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { CareJourneyDocumentsList } from '../../../../../../../../Journeys/AddOrUpdateJourney/UploadDocuments/CareJourneyDocumentsList';
import { getCareJourneyStatusIds } from '../../../../../../../../Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
import { styles } from './CommonStyles';
import { JOURNEY_START_TYPES, PACKAGE_TYPES } from './FormConst';
import { IDetailViewFormProps, IProductDetailForm } from './interfaces';
import { validateProductFormData } from './ProductFormUtils';
import { showToast, ToastType } from '../../../../../../../../../../utils/commonViewUtils';
import LabSearch from '../../../../../../../../../common/LabSearch/LabSearch';
import MultipleLabAnalyteSearch from '../../../../../../../../../common/LabSearch/MultipleLabAnalyteSearch';
import { IPackage, IRecommendedCareJourney } from '../SearchComponents/interfaces';
import { isExpiredDate } from '../../../../../../JourneyPackages/PackagesUtils';
import { getEnabledVitalListFromCapability } from '../../../../../../../../../../utils/VitalUtils';
import { isAccountConfigEnabled } from '../../../../../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../../../../../constants/AccountConfigConst';
import WorkflowLocationSelect from '../../../../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowTriggerView/WorkflowLocationSelect';
import { FlowType } from '../../../../../../../../../../context/WorkflowContext';

export const DetailViewForm = (props: IDetailViewFormProps) => {
  const {
    handleTabsDataLoading,
    productData,
    tabType,
    customErrors,
    setCustomErrors,
    isTabOrBtnClicked,
    productDetailForm,
    selectedPackages,
    onPackageSelect,
    journeyData,
    onUpdateData
      } = props;
  const [errors, setErrors]: any = useState({});
  const [productDetail, setProductDetails] = useState(
    {} as ISingleProductApiRespData
  );
  const intl = useIntl()
  const [memberShipProduct, setMemberShipProduct] = useState(
    {} as IMemberShipProduct
  );
  const isPackagesEnabled = isJourneyPackagesEnabled();
  const navigate = useNavigate();
  const urlQueryParams = useParams();
  const accountUUID = getAccountUUID();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);

  const mlovData = useContext(CommonDataContext);
  const productDetailFormToast = useToast();

  const defaultDate = getDateStrFromFormat(
    new Date(),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );

  const journeyDuration =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION
    ) || [];

  const monthsId = getMlovIdFromCode(journeyDuration, MLOV_CODES.MONTH);

  const defaultDuration = {
    value: 6,
    unit: monthsId,
    unitList: journeyDuration,
  };

  const [detailViewFormState, setDetailViewFormState] = useState<{ //here
    isJourneyNameTaken: boolean;
    taxonObjList: IMultiSelectTagObj[];
    optionTypeObjList: IMultiSelectTagObj[];
    productDetailForm: IProductDetailForm;
    isLoading: boolean;
    productId: number;
    isError: boolean
      }>({
    isJourneyNameTaken:false,
    taxonObjList: [],
    optionTypeObjList: [],
    productDetailForm: props.productDetailForm || ({
      startType: JOURNEY_START_TYPES.ROLLING
    } as IProductDetailForm),
    isLoading: false,
    productId: -1,
    isError: false,
  });

  const vitalList = getEnabledVitalListFromCapability(
    detailViewFormState?.productDetailForm?.careJourneyLocations?.locations?.[0]
  );

  const [stateData,setStateData] = useState({
    accountLocation: [] as any,
    accountLocationData: [] as any,
    careJourneys: [] as any,
    careJourneysData: [] as any,
    packages: selectedPackages || [],
    isAddDocumentModalOpen: false,
    packageSearchString: '',
    locationSearchString: ''
  })

  const careJourneyStatusIds = getCareJourneyStatusIds(mlovData)

  const isPublished = detailViewFormState.productDetailForm?.statusId === careJourneyStatusIds.published && !props.isClone

  const onCancel = () => {
    navigate(-1);
  };

  const isPrescribedTagSelected =
    detailViewFormState?.productDetailForm?.triggerEvents?.length === 1 &&
    detailViewFormState?.productDetailForm?.triggerEvents?.includes(
      'prescribed'
    );

  const getErrorObject = () => {
    return props.journeyData.isCareJourney ? customErrors : errors;
  };

  const setErrorObject = (data: any) => {
    return props.journeyData.isCareJourney
      ? setCustomErrors(data)
      : setErrors(data);
  };

  const showToastMessage = (msg: string, isError: boolean, isI18N = true) => {
    showToast(productDetailFormToast, isI18N ? intl.formatMessage({id: msg}) : msg, isError ? ToastType.error : ToastType.success);
  };

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      const filteredData = stateData?.accountLocationData?.filter((item: any) => {
        return item.label.toLowerCase().includes(searchString.toLowerCase());
      });
      setStateData((prev)=>{
        return {
          ...prev,
          accountLocation: filteredData,
          locationSearchString: searchString
        }
      })
    } else {
      setStateData((prev)=>{
        return {
          ...prev,
          accountLocation: prev.accountLocationData,
          locationSearchString: searchString
        }
      })
    }
  };

  const onPackageSearch = async (searchString: string)  => {
    try{
      const packagesResponse = await GetPackages({
        searchString: searchString.trim(),
        limit: 20,
        offset: 0,
        status: PACKAGE_TYPES.PUBLISHED
      });
      const unExpiredPackages = packagesResponse?.data?.products?.filter(
        (packageData: IPackage) =>
          !isExpiredDate(getMomentObj(packageData.expiryDate).toDate())
      );
      setStateData((prev)=>{
        return {
          ...prev,
          packages: unExpiredPackages,
        }
      })
    }catch(error){
      showToastMessage('apiErrorMsg', true);
    }
    setStateData((prev)=>{
      return {
        ...prev,
        packageSearchString: searchString
      }
    })
  }

  const [getJourneyCountForName] = useLazyQuery(GET_JOURNEY_COUNT_FOR_NAME, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (data: any) => {
      setDetailViewFormState((prevState) => ({
        ...prevState,
        isJourneyNameTaken: data?.aggregateCareJourneys?.aggregate?.count > 0,
        isLoading: false,
      }));
      props?.handleTabsDataLoading?.({
        'duplicateName': false
      });
    },
    onError: (error) => {
      setDetailViewFormState((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      props?.handleTabsDataLoading?.({
        'duplicateName': false
      });
      showToast(
        productDetailFormToast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
      );
    },
  });

  const isJourneyNameAlreadyTaken = (JourneyName: string) => {
    setDetailViewFormState((prevState) => ({
      ...prevState,
      isLoading: true,
      isError: false,
    }));
    props?.handleTabsDataLoading?.({
      'duplicateName': true
    });
    const timerId = setTimeout(() => {
      getJourneyCountForName({
        variables: {
          searchString: JourneyName,
        },
      });
    }, 500);
  };


  useEffect(() => {
    const productId = urlQueryParams.id;
    // if (productData !== null && productId) {
    //   getProductDetails(
    //     productId,
    //     (productResp: any) => {
    //       if (productResp) {
    //         setProductDetails(productResp);
    //         props?.onActionPerformed?.(
    //           COMMON_ACTION_CODES.ON_REFRESH_ACTION,
    //           productResp
    //         );
    //       }
    //     },
    //     () => {
    //     },
    //     tabType
    //   );
    // } else if (productData) {
    //   setProductDetails(productData);
    // }
    if (
      props.journeyData.isCareJourney &&
      !detailViewFormState.productDetailForm?.triggerEvents?.length
    ) {
      setDetailViewFormState((prev) => {
        return {
          ...prev,
          productDetailForm: {
            ...prev.productDetailForm,
            triggerEvents: [JOURNEY_TRIGGER_EVENTS?.[0].value],
          },
        };
      });
    }
    if(!productDetailForm?.recommendedCareJourney?.recommendedCareJourney?.length){
     onPackageSearch('')
    }
  }, []);

  useEffect(() => {
    if (
      detailViewFormState.taxonObjList &&
      detailViewFormState.taxonObjList.length
    ) {
      setDetailViewFormState((prev) => {
        return {
          ...prev,
          productDetailForm: {
            ...prev.productDetailForm,
            taxon_ids:
              detailViewFormState?.taxonObjList[0].key ||
              detailViewFormState?.taxonObjList[0].id ||
              '',
          },
        };
      });
    }
  }, [detailViewFormState.taxonObjList]);

  const updateProductDetails = () => {
    const productFormData = {...detailViewFormState.productDetailForm};

    if (!productDetail?.data?.id) {
      productFormData.shipping_category_id = '1';
    }

    if (detailViewFormState?.taxonObjList?.length) {
      productFormData.taxon_ids =
        detailViewFormState?.taxonObjList[0].key ||
        detailViewFormState?.taxonObjList[0].id ||
        '';
    }

    if (detailViewFormState?.optionTypeObjList?.length) {
      productFormData.option_type_ids =
        detailViewFormState?.optionTypeObjList[0].id;
    }
    //Updating on price is not working for changing price, so changing available_on as well at the same movement to update price correct
    productFormData.available_on = getDateStrFromFormat(
      addDaysInDate(new Date(), -1),
      DATE_FORMATS.API_DATE_FORMAT
    );

    // return;
    setDetailViewFormState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    addOrUpdateProduct(
      {
        product: productFormData,
      },
      (responseMsg: ISingleProductApiRespData) => {
        if (responseMsg?.data && responseMsg?.data.id) {
          setDetailViewFormState((prev) => {
            return {
              ...prev,
              isLoading: false,
              productId: responseMsg?.data.id,
            };
          });
        }

        showToastMessage('productDetailFormSuccess', false);
        props?.onActionPerformed?.(
          COMMON_ACTION_CODES.ON_REFRESH_ACTION,
          responseMsg
        );

        navigate(
          `/admin/commerce/${urlQueryParams.type}/product/` +
            responseMsg?.data.id,
          {
            replace: true,
          }
        );
      },
      () => {
        setDetailViewFormState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
        showToastMessage('productDetailFormError', true);
      },
      productDetail?.data?.id
    );
  };

  const onProductDetailFromSubmit = () => {
    setErrorObject(
      validateProductFormData(
        detailViewFormState.productDetailForm,
        props.journeyData,
        props.tabType
      ).errors
    );

    if (
      validateProductFormData(
        detailViewFormState.productDetailForm,
        props.journeyData,
        props.tabType
      ).validate
    ) {
      updateProductDetails();
    } else {
      validateProductFormData(
        detailViewFormState.productDetailForm,
        props.journeyData,
        tabType
      ).errors
    }
  };

  useEffect(() => {
    if (productDetail?.data?.id) {
      const taxonObjList = getTaxonObjList(
        productDetail.data,
        productDetail.included
      );

      const optionTypeObjList = getOptionTypesObjList(
        productDetail.data,
        productDetail.included
      );

      setDetailViewFormState((prev) => {
        return {
          ...prev,
          taxonObjList,
          optionTypeObjList,
          productId: productDetail.data.id,
          productDetailForm: {
            ...prev.productDetailForm,
            name: productDetail?.data?.attributes?.name || '',
            slug: productDetail?.data?.attributes?.slug || '',
            description: productDetail?.data?.attributes?.description || '',
            shipping_category_id: '1',
            available_on: productDetail?.data?.attributes?.available_on || '',
            price: productDetail?.data?.attributes?.price || '',
            cost_currency: 'USD',
            compare_at_price:
              productDetail?.data?.attributes?.compare_at_price || '',
          },
        };
      });
    } else if (props.selectedTaxon && props.selectedTaxon?.id) {
      const selectedTaxonArray: IMultiSelectTagObj[] = [
        {
          id: props.selectedTaxon.id,
          value: props.selectedTaxon.attributes.name,
          key: props.selectedTaxon.id,
        },
      ];

      setDetailViewFormState((prev) => {
        return {
          ...prev,
          taxonObjList: selectedTaxonArray,
        };
      });
    }
  }, [productDetail?.data?.id]);

  const getPriceError = () => {
    if (isPrescribedTagSelected) {
      return '';
    }

    let offerPrice: any = undefined;
    let price: any = undefined;

    try {
      offerPrice = detailViewFormState.productDetailForm.price;
      price = detailViewFormState.productDetailForm.compare_at_price;
      if (offerPrice) {
        offerPrice = parseFloat(offerPrice);
      }
      if (price) {
        price = parseFloat(price);
      }
    } catch (err) {
    }

    if (!offerPrice) {
      return 'Offer Price is required';
    }

    if (offerPrice && price && offerPrice > price) {
      return 'Offer Price should be less then Product Price';
    }

    return '';
  };

  const getMrpPriceError = () => {
    if (isPrescribedTagSelected) {
      return '';
    }

    let mrpPrice;

    try {
      mrpPrice = detailViewFormState.productDetailForm?.compare_at_price;
      if (mrpPrice) {
        mrpPrice = parseFloat(mrpPrice);
      }
    } catch (err) {
    }

    if (!mrpPrice) {
      return 'Price is required';
    }
    return '';
  };

  const showDocumentList = ()=> {
    const isDocumentData = detailViewFormState?.productDetailForm?.careJourneyDocuments?.filter((document)=>{
      return !document.isDeleted
    }) || [];
    return isDocumentData.length ? true : false;
  }

  useEffect(() => {
    if (props.onChange) {
      const currentErrors = getErrorObject();
      setErrorObject({
        ...currentErrors,
        priceIssue: getPriceError(),
        mrpPrice: getMrpPriceError(),
        duplicate: detailViewFormState?.isJourneyNameTaken,
        isLoading: detailViewFormState?.isLoading
      });
      errors.priceIssue = getPriceError();
      errors.mrpPrice = getMrpPriceError();
      props.onChange(detailViewFormState);
    }
  }, [detailViewFormState]);

  useEffect(() => {
    if (isTabOrBtnClicked) {
      setErrorObject(
        validateProductFormData(detailViewFormState.productDetailForm, {
          isAssignWorkflow: false,
          isCareJourney: true,
        }).errors
      );
    }
  }, [detailViewFormState, isTabOrBtnClicked]);

  useEffect(()=> {
    if(!stateData.packages.length){
      setStateData((prev)=> ({
        ...prev,
         packages: props.selectedPackages || []
        }
      ))
    }
  }, [props.selectedPackages])

  useEffect(()=> {
    setStateData((prev)=>{
      return {
        ...prev,
        accountLocation: props?.accountLocation || [],
        accountLocationData: props?.accountLocation || [],
        careJourneys: props?.stateData?.careJourneys || [],
        careJourneysData: props?.stateData?.careJourneysData || []
      }
    })
  },[props])

  const getSkuDetails = () => {
    if (detailViewFormState?.productDetailForm?.sku) {
      return detailViewFormState?.productDetailForm?.sku;
    } else {
      const filteredInfo = productDetail?.included?.filter((obj) => {
        return obj.type == 'variant' && obj.attributes?.is_master == true;
      });
      if (filteredInfo && filteredInfo.length) {
        return filteredInfo[0].attributes?.sku || '';
      }
    }
    return '';
  };
  const {height} = Dimensions.get('window')
  return (
    <>
      <View minH={height - 145} style={{paddingHorizontal: 16}}>
        <HStack flex={1}>
          <View
            flex={0.7}
            style={{
              backgroundColor: '#fff',
            }}
          >
            {/* <TableTopBar title="details" hideSearchBar={true} /> */}
            <View
              justifyContent={'space-between'}
              style={{paddingHorizontal: 12}}
            >
              <HStack justifyContent={'space-between'}>
                <FormControl
                  style={styles.formElement}
                  flex={0.49}
                  isRequired
                  isInvalid={
                    props.journeyData.isCareJourney
                      ? getErrorObject()?.journeyTitle
                      : getErrorObject()?.name
                  }
                >
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      textLocalId={
                        props.journeyData.isCareJourney ? 'journeyName' : 'name'
                      }
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <Input
                    _focus={{borderColor: '#D0D5DD'}}
                    placeholder="Journey Name"
                    isReadOnly={props.isReadOnly}
                    defaultValue={
                      detailViewFormState.productDetailForm.name ||
                      productDetail?.data?.attributes?.name ||
                      ''
                    }
                    onChangeText={debounce((value: string) => {
                      const JourneyName = value.trim();
                      if (JourneyName) {
                        isJourneyNameAlreadyTaken(JourneyName);
                      }
                      setDetailViewFormState((prev) => {
                        return {
                          ...prev,
                          productDetailForm: {
                            ...prev.productDetailForm,
                            name: value,
                          },
                        };
                      });
                    }, 400)}
                  />
                  {detailViewFormState.isJourneyNameTaken && (
                    <HStack space={2}>
                      <Text fontSize="xs" color={Colors.danger[500]}>
                        {intl.formatMessage({id: 'duplicateJourneyNameError'})}
                      </Text>
                    </HStack>
                  )}
                   {
                    <HStack marginTop={2} height={3}>
                      {detailViewFormState.isLoading ? (
                        <>
                          <Spinner
                            size="sm"
                            accessibilityLabel={`Checking journey name availability`}
                          />
                          <Text marginLeft={1} fontSize="xs">
                            {intl.formatMessage({
                              id: 'checkingJourneyNameAvailability',
                            })}
                          </Text>
                        </>
                      ) : (
                        <Text>{' '}</Text>
                      )}
                    </HStack>
                  }
                  {getErrorObject()?.journeyTitle || getErrorObject()?.name ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {getErrorObject()?.journeyTitle || getErrorObject()?.name}
                    </FormControl.ErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
                {props.journeyData.isAssignWorkflow && (
                  <FormControl style={styles.formElement} flex={0.24}>
                    <FormControl.Label style={styles.formLabel} isRequired>
                      <DisplayText
                        textLocalId={'journeyStartDate'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <View maxWidth={300}>
                      <DatePicker
                        value={getMomentObjectWithDateStringAndFormat(
                          detailViewFormState.productDetailForm.startDate ||
                            defaultDate,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )}
                        format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                        onChange={(value, dateString) => {
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                startDate: dateString,
                              },
                            };
                          });
                        }}
                        disabledDate={(current: any) => {
                          return current && isPastDay(current);
                        }}
                        className={
                          getErrorObject()?.startDate ? 'field-error' : ''
                        }
                      />
                    </View>
                  </FormControl>
                )}
                {false &&
                  props.journeyData.isCareJourney &&
                  !props.journeyData.isAssignWorkflow && (
                    <FormControl
                      style={styles.formElement}
                      flex={0.24}
                      isRequired
                      isInvalid={getErrorObject()?.triggerEvents}
                    >
                      <FormControl.Label style={styles.formLabel}>
                        <DisplayText
                          textLocalId={'journeyWillStartOn'}
                          size={'smMedium'}
                          extraStyles={{color: Colors.Custom.Gray700}}
                        />
                      </FormControl.Label>
                      <Select
                        isDisabled={props.isReadOnly}
                        selectedValue={
                          detailViewFormState.productDetailForm.triggerEvents
                            .length > 0
                            ? detailViewFormState.productDetailForm
                                .triggerEvents[0]
                            : JOURNEY_TRIGGER_EVENTS?.[0]?.value
                        }
                        onValueChange={(value: string) => {
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                triggerEvents: [value],
                              },
                            };
                          });
                        }}
                      >
                        {JOURNEY_TRIGGER_EVENTS.map((option, index) => {
                          return (
                            <Select.Item
                              key={`${option.value}_${index}`}
                              value={option.value}
                              label={option.label}
                            >
                              {option.label}
                            </Select.Item>
                          );
                        })}
                      </Select>
                      {getErrorObject()?.triggerEvents && (
                        <FormControl.ErrorMessage
                          _text={{
                            fontSize: 'xs',
                            color: 'error.500',
                            fontWeight: 500,
                          }}
                        >
                          {getErrorObject()?.triggerEvents}
                        </FormControl.ErrorMessage>
                      )}
                      {detailViewFormState?.productDetailForm
                        ?.triggerEvents?.[0] ===
                        JOURNEY_PURCHASE_TRIGGER_EVENT && (
                        <DisplayText
                          textLocalId={'allowJourneyInfo'}
                          size={'xsMedium'}
                          extraStyles={{
                            color: Colors.Custom.Gray500,
                            marginLeft: 4,
                          }}
                        />
                      )}
                    </FormControl>
                  )}
                {props.journeyData.isCareJourney && (
                  <FormControl style={styles.formElement} flex={0.49}>
                    <FormControl.Label
                      style={styles.formLabel}
                      isRequired
                      isInvalid={getErrorObject()?.duration}
                    >
                      <DisplayText
                        textLocalId={'journeyDuration'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <JourneyDurationInput
                      isReadOnly={props.isReadOnly}
                      labelId={''}
                      isShowError={getErrorObject()?.duration}
                      value={
                        detailViewFormState.productDetailForm.duration ||
                        defaultDuration
                      }
                      onChange={(value) => {
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              duration: value,
                            },
                          };
                        });
                      }}
                    />
                    <DisplayText
                      textLocalId={'journeyDurationInfo'}
                      size={'xsMedium'}
                      extraStyles={{
                        color: Colors.Custom.Gray500,
                        marginLeft: 4,
                        marginTop: 1,
                      }}
                    />
                    {getErrorObject()?.duration && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                      >
                        {getErrorObject()?.duration}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
                {!props.journeyData.isCareJourney && (
                  <FormControl style={styles.formElement} flex={0.49}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'sku'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <Input
                      _focus={{borderColor: '#D0D5DD'}}
                      placeholder="Optional"
                      isReadOnly={props.isReadOnly}
                      defaultValue={getSkuDetails()}
                      onChangeText={(sku) => {
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              sku: sku,
                              master_sku: sku,
                            },
                          };
                        });
                      }}
                    />
                  </FormControl>
                )}
              </HStack>
              {props.journeyData.isCareJourney && (
                <HStack flex={1}>
                  <FormControl
                    style={[styles.formElement]}
                    isRequired
                    isInvalid={getErrorObject()?.description}
                  >
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'description'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <TextArea
                      totalLines={10}
                      isReadOnly={props.isReadOnly}
                      defaultValue={
                        detailViewFormState.productDetailForm.description ||
                        productDetail?.data?.attributes?.description ||
                        ''
                      }
                      placeholder={'Description'}
                      onChangeText={(description) => {
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              description:
                                description.trim().length > 0
                                  ? description
                                  : '',
                            },
                          };
                        });
                      }}
                    />
                    {getErrorObject()?.description && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                      >
                        {getErrorObject()?.description}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                </HStack>
              )}
              <HStack>
                {
                  <FormControl style={{marginBottom: 20}} flex={1}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'vitalsTracking'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <HStack alignItems={'center'} width={'full'}>
                      <SelectAntd
                        showArrow={true}
                        value={(
                          detailViewFormState.productDetailForm
                            .careJourneyTrackingResources || []
                        )
                          .filter((careJourneyTrackingResource) => {
                            return (
                              careJourneyTrackingResource?.trackingResourceType ==
                                TRACKING_RESOURCE_TYPE?.VITALS &&
                              !careJourneyTrackingResource?.isDeleted
                            );
                          })
                          .map((careJourneyTrackingResource) => {
                            return careJourneyTrackingResource.trackingResourceCode;
                          })}
                        allowClear
                        style={{width: '97%'}}
                        options={vitalList.map((vital) => {
                          return {
                            label: vital?.foldDisplay || '',
                            value: vital?.loinc || '',
                          };
                        })}
                        filterOption={(input, option: any) => {
                          return (
                            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        onChange={(v1) => {
                          let careJourneyTrackingResources = JSON.parse(
                            JSON.stringify(
                              detailViewFormState.productDetailForm
                                .careJourneyTrackingResources || []
                            )
                          );

                          careJourneyTrackingResources =
                            careJourneyTrackingResources?.map(
                              (careJourneyTrackingResource: any) => {
                                if (
                                  careJourneyTrackingResource.trackingResourceType ==
                                    TRACKING_RESOURCE_TYPE?.VITALS &&
                                  v1.indexOf(
                                    careJourneyTrackingResource?.trackingResourceCode
                                  ) == -1
                                ) {
                                  careJourneyTrackingResource.isDeleted = true;
                                }
                                return careJourneyTrackingResource;
                              }
                            );
                          v1.forEach((trackingResourceCode) => {
                            const isPresent = careJourneyTrackingResources.some(
                              (careJourneyTrackingResource: any) => {
                                if (
                                  careJourneyTrackingResource?.trackingResourceCode ==
                                  trackingResourceCode
                                ) {
                                  careJourneyTrackingResource.isDeleted = false;
                                  return true;
                                }
                              }
                            );
                            if (!isPresent) {
                              careJourneyTrackingResources.push({
                                trackingResourceCode: trackingResourceCode,
                                trackingResourceType:
                                  TRACKING_RESOURCE_TYPE?.VITALS,
                                isDeleted: false,
                              });
                            }
                          });
                          careJourneyTrackingResources =
                            careJourneyTrackingResources.filter(
                              (careJourneyTrackingResource: any) => {
                                return !(
                                  careJourneyTrackingResource.isDeleted &&
                                  !careJourneyTrackingResource?.id
                                );
                              }
                            );
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                careJourneyTrackingResources:
                                  careJourneyTrackingResources,
                              },
                            };
                          });
                        }}
                        placeholder="Please select"
                        mode="multiple"
                      ></SelectAntd>
                    </HStack>
                  </FormControl>
                }

                {
                  <FormControl style={{marginBottom: 20}} flex={1}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'labTestTracking'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <HStack alignItems={'center'} width={'full'}>
                      <MultipleLabAnalyteSearch
                        isShowError={false}
                        value={(
                          detailViewFormState.productDetailForm
                            .careJourneyTrackingResources || []
                        )
                          .filter((careJourneyTrackingResource) => {
                            return (
                              careJourneyTrackingResource?.trackingResourceType ==
                                TRACKING_RESOURCE_TYPE?.LAB_TEST &&
                              !careJourneyTrackingResource?.isDeleted
                            );
                          })
                          .map((careJourneyTrackingResource) => {
                            return careJourneyTrackingResource.trackingResourceCode;
                          })}
                        onChange={(v1) => {
                          let careJourneyTrackingResources = JSON.parse(
                            JSON.stringify(
                              detailViewFormState.productDetailForm
                                .careJourneyTrackingResources || []
                            )
                          );

                          careJourneyTrackingResources =
                            careJourneyTrackingResources?.map(
                              (careJourneyTrackingResource: any) => {
                                if (
                                  careJourneyTrackingResource.trackingResourceType ==
                                    TRACKING_RESOURCE_TYPE?.LAB_TEST &&
                                  v1.indexOf(
                                    careJourneyTrackingResource?.trackingResourceCode
                                  ) == -1
                                ) {
                                  careJourneyTrackingResource.isDeleted = true;
                                }
                                return careJourneyTrackingResource;
                              }
                            );
                          v1.forEach((trackingResourceCode: string) => {
                            const isPresent = careJourneyTrackingResources.some(
                              (careJourneyTrackingResource: any) => {
                                if (
                                  careJourneyTrackingResource?.trackingResourceCode ==
                                  trackingResourceCode
                                ) {
                                  careJourneyTrackingResource.isDeleted = false;
                                  return true;
                                }
                              }
                            );
                            if (!isPresent) {
                              careJourneyTrackingResources.push({
                                trackingResourceCode: trackingResourceCode,
                                trackingResourceType:
                                  TRACKING_RESOURCE_TYPE?.LAB_TEST,
                                isDeleted: false,
                              });
                            }
                          });
                          careJourneyTrackingResources =
                            careJourneyTrackingResources.filter(
                              (careJourneyTrackingResource: any) => {
                                return !(
                                  careJourneyTrackingResource.isDeleted &&
                                  !careJourneyTrackingResource?.id
                                );
                              }
                            );
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                careJourneyTrackingResources:
                                  careJourneyTrackingResources,
                              },
                            };
                          });
                        }}
                      />
                    </HStack>
                  </FormControl>
                }
              </HStack>
              {/* {props.journeyData.isCareJourney && (
                <HStack flex={1} justifyContent={'space-between'}>
                  <FormControl flex={0.49} style={styles.formElement}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'memberOutcome'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <TextArea
                      placeholder="Optional"
                      totalLines={3}
                      isReadOnly={props.isReadOnly}
                      defaultValue={
                        detailViewFormState.productDetailForm.memberOutcome || ''
                      }
                      onChangeText={(value) => {
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              memberOutcome: value,
                            },
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl flex={0.49} style={styles.formElement}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'businessOutcome'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <TextArea
                      placeholder="Optional"
                      totalLines={3}
                      isReadOnly={props.isReadOnly}
                      defaultValue={
                        detailViewFormState.productDetailForm.businessOutcome ||
                        ''
                      }
                      onChangeText={(value) => {
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              businessOutcome: value,
                            },
                          };
                        });
                      }}
                    />
                  </FormControl>
                </HStack>
              )} */}
              {/* {!isPrescribedTagSelected && (
                <HStack flex={1} justifyContent={'space-between'}>
                  <FormControl
                    isRequired
                    flex={0.49}
                    style={styles.formElement}
                    isInvalid={getErrorObject()?.mrpPrice}
                  >
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'price'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <InputNumber
                      style={{width: '100%'}}
                      readOnly={props.isReadOnly}
                      type={'number'}
                      placeholder={'199'}
                      value={
                        detailViewFormState.productDetailForm.compare_at_price
                      }
                      onChange={(compare_at_price) => {
                        if (compare_at_price < 0) {
                          return;
                        }
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              compare_at_price: compare_at_price,
                            },
                          };
                        });
                        // setErrors({});
                      }}
                    />
                    {getErrorObject()?.mrpPrice && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                      >
                        {getErrorObject()?.mrpPrice}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    flex={0.49}
                    style={styles.formElement}
                    isInvalid={getErrorObject()?.priceIssue}
                  >
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        // textLocalId={'price'}
                        textLocalId={'sellingPrice'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <InputNumber
                      placeholder="99"
                      style={{width: '100%'}}
                      readOnly={props.isReadOnly}
                      type={'number'}
                      value={detailViewFormState.productDetailForm.price}
                      onChange={(price) => {
                        if (price < 0) {
                          return;
                        }
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              price: price,
                              // prices: [] as IPrices,
                            },
                          };
                        });
                        // setDetailViewFormState((prev) => {
                        //   return {
                        //     ...prev,
                        //     productDetailForm: {
                        //       ...prev.productDetailForm,
                        //       price: price,
                        //       prices: {
                        //         ...prev.productDetailForm.prices,
                        //         one_time: price,
                        //       },
                        //     },
                        //   };
                        // });
                      }}
                    />
                    {getErrorObject()?.priceIssue && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                      >
                        {getErrorObject()?.priceIssue}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                </HStack>
              )} */}
              {props.journeyData.isCareJourney && (
                <FormControl style={{marginBottom: 20}}>
                  <FormControl.Label style={styles.formLabel} isRequired>
                    <DisplayText
                      textLocalId={'journeyStartDate'}
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <HStack alignItems={'center'}>
                    <Radio.Group
                      disabled={isPublished}
                      style={{paddingLeft: 0, alignItems: 'center'}}
                      name="journeyRolling"
                      value={
                        detailViewFormState.productDetailForm?.startType ||
                        JOURNEY_START_TYPES.ROLLING
                      }
                      onChange={(e: RadioChangeEvent) => {
                        setDetailViewFormState((prev) => {
                          return {
                            ...prev,
                            productDetailForm: {
                              ...prev.productDetailForm,
                              startType: e.target.value,
                              ...(e.target.value ===
                                JOURNEY_START_TYPES.ROLLING && {
                                startDateTime: null,
                              }),
                            },
                          };
                        });
                      }}
                    >
                      <HStack alignItems={'center'} style={{height: 40}}>
                        <Radio
                          value={JOURNEY_START_TYPES.ROLLING}
                          style={{marginRight: 0}}
                        >
                          <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                            {intl.formatMessage({id: 'rolling'})}
                          </Text>
                        </Radio>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'rollingTollTipMessage',
                          })}
                          placement={'top'}
                          destroyTooltipOnHide={true}
                        >
                          <Icon
                            as={AntIcon}
                            color={Colors.Custom.Gray500}
                            name="infocirlceo"
                            size="smMedium"
                          />
                        </Tooltip>
                        <Radio
                          style={{marginLeft: 16, marginRight: 0}}
                          value={JOURNEY_START_TYPES.FIXED}
                        >
                          <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                            {intl.formatMessage({id: 'fixedDate'})}
                          </Text>
                        </Radio>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'fixedTooltipMessage',
                          })}
                          placement={'top'}
                          destroyTooltipOnHide={true}
                        >
                          <Icon
                            as={AntIcon}
                            color={Colors.Custom.Gray500}
                            name="infocirlceo"
                            size="smMedium"
                          />
                        </Tooltip>
                      </HStack>
                    </Radio.Group>

                 { detailViewFormState.productDetailForm?.startType === JOURNEY_START_TYPES.FIXED &&
                 <DatePicker
                  style={{width: 250, height:40, marginLeft: 16, borderColor: getErrorObject().startDateTime? Colors.Custom.ErrorColor: ''}}
                  value={detailViewFormState.productDetailForm?.startDateTime ? getMomentObj(detailViewFormState.productDetailForm?.startDateTime): undefined}
                  format={DISPLAY_DATE_FORMAT}
                  disabled={isPublished}
                  onChange={(value, dateString) => {
                    const startDateTime = getJourneyStartDateWithOffset(value)?.toISOString();
                    setDetailViewFormState((prev) => {
                      return {
                        ...prev,
                        productDetailForm: {
                          ...prev.productDetailForm,
                          startDateTime: startDateTime,
                        }
                            };
                          });
                        }}
                        disabledDate={(current) => {
                          return isPastDay(current);
                        }}
                      />
                    }
                    {getErrorObject()?.startDateTime && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                      >
                        {getErrorObject()?.startDateTime}
                      </FormControl.ErrorMessage>
                    )}
                  </HStack>
                </FormControl>
              )}
              {(isPackagesEnabled || isMsoEnabled) && (
                <HStack flex={1} justifyContent={'space-between'}>
                  <FormControl flex={0.49} style={styles.formElement} isRequired={isMsoEnabled}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'locations'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <WorkflowLocationSelect
                      onChangeLocationIds={(values: string[]) => {
                        if (values.length) {
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                careJourneyLocations: {
                                  previousLocations:
                                    prev?.productDetailForm
                                      ?.careJourneyLocations
                                      ?.previousLocations || [],
                                  locations: values,
                                },
                              },
                            };
                          });
                          onUpdateData && onUpdateData(values);
                        }
                      }}
                      locationIds={detailViewFormState?.productDetailForm?.careJourneyLocations?.locations}
                      flowType={FlowType.careJourney}
                       notFoundContent={intl.formatMessage({
                        id: stateData.locationSearchString
                          ? 'noData'
                          : 'NoData',
                      })}
                    />
                    {/* <ModalActionAntSelect
                      mode={'multiple'}
                      isRequired={isMsoEnabled ? true : false}
                      allowClear={true}
                      showSearch={true}
                      leftMargin={'0'}
                      onSearch={debounce(onSearch, 500)}
                      labelInValue={true}
                      filterOption={false}
                      value={
                        detailViewFormState?.productDetailForm
                          ?.careJourneyLocations?.locations
                      }
                      notFoundContent={intl.formatMessage({
                        id: stateData.locationSearchString
                          ? 'noData'
                          : 'NoData',
                      })}
                      placeholder={intl.formatMessage({id: 'selectLocations'})}
                      onChange={(values: any[], data: any) => {
                        const selectedLocations =
                          values?.map((location) => location.value) || [];
                        if (values) {
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                careJourneyLocations: {
                                  previousLocations:
                                    prev?.productDetailForm
                                      ?.careJourneyLocations
                                      ?.previousLocations || [],
                                  locations: selectedLocations,
                                },
                              },
                            };
                          });
                        }
                      }}
                      data={stateData?.accountLocation}
                      optionProps={{key: 'key', value: 'value', label: 'label'}}
                      extraStyle={{flex: 1}}
                      customStyle={{
                        flex: 1,
                        fontSize: 15,
                        fontWeight: 400,
                        borderColor: Colors.Custom.Gray300,
                      }}
                    /> */}
                  </FormControl>
                  <FormControl flex={0.49} style={styles.formElement}>
                    <FormControl.Label style={styles.formLabel}>
                      <DisplayText
                        textLocalId={'recommendedPackages'}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <ModalActionAntSelect
                      isRequired={false}
                      allowClear={true}
                      showSearch={true}
                      leftMargin={'0'}
                      onSearch={debounce(onPackageSearch, 500)}
                      labelInValue={true}
                      filterOption={false}
                      value={
                        detailViewFormState?.productDetailForm
                          ?.recommendedCareJourney?.recommendedCareJourney
                      }
                      notFoundContent={intl.formatMessage({
                        id: stateData.packageSearchString ? 'noData' : 'NoData',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'selectRecommendedPackage',
                      })}
                      onChange={(value: any) => {
                        if (!!value?.value) {
                          const selectedPackage = stateData.packages.find(
                            (packageObj) => packageObj.id === value.value
                          );
                          if (selectedPackage) {
                            onPackageSelect && onPackageSelect(selectedPackage);
                          }
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                recommendedCareJourney: {
                                  previousRecommendedCareJourney:
                                    prev?.productDetailForm
                                      ?.recommendedCareJourney
                                      ?.previousRecommendedCareJourney || [],
                                  recommendedCareJourney: value
                                    ? [value.value]
                                    : [],
                                },
                              },
                            };
                          });
                        } else {
                          onPackageSelect && onPackageSelect(undefined);
                          setDetailViewFormState((prev) => {
                            const recommendedCareJourney =
                              prev?.productDetailForm?.recommendedCareJourney ||
                              ({} as IRecommendedCareJourney);
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                recommendedCareJourney: {
                                  ...recommendedCareJourney,
                                  recommendedCareJourney: [],
                                },
                              },
                            };
                          });
                        }
                      }}
                      data={stateData?.packages}
                      optionProps={{key: 'id', value: 'id', label: 'name'}}
                      extraStyle={{flex: 1}}
                      customStyle={{
                        flex: 1,
                        fontSize: 15,
                        fontWeight: 400,
                        borderColor: Colors.Custom.Gray300,
                      }}
                    />
                  </FormControl>
                </HStack>
              )}
            </View>
            {!props.journeyData.isCareJourney && (
              <FormControl
                style={[styles.formElement, {paddingHorizontal: 12}]}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    textLocalId={'description'}
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                  />
                </FormControl.Label>
                <TextArea
                  isReadOnly={props.isReadOnly}
                  defaultValue={
                    detailViewFormState.productDetailForm.description ||
                    productDetail?.data?.attributes?.description ||
                    ''
                  }
                  onChangeText={(description) => {
                    setDetailViewFormState((prev) => {
                      return {
                        ...prev,
                        productDetailForm: {
                          ...prev.productDetailForm,
                          description: description,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            )}
            {!props.journeyData.isCareJourney && (
              <Button.Group
                style={{paddingHorizontal: 12, paddingVertical: 12}}
                mx={{
                  base: 'auto',
                  md: 0,
                }}
                justifyContent={'flex-end'}
              >
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.SECONDARY,
                    onPress: () => {
                      onCancel();
                    },
                    style: {marginRight: 20},
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: 'cancel'}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      onProductDetailFromSubmit();
                    },
                    style: {marginRight: 20},
                    isDisabled: detailViewFormState?.isLoading,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({
                      id: productDetail?.data?.id ? 'update' : 'add',
                    }),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              </Button.Group>
            )}
          </View>
          <View borderLeftWidth={1} borderColor={'gray.300'} flex={0.3} mt={1}>
            <FormControl style={styles.formElement}>
              <View
                pl={3}
                borderBottomWidth={1}
                pb={1}
                borderColor={'gray.300'}
              >
                <FormControl.Label>
                  <HStack
                    alignItems={'center'}
                    flex={1}
                    justifyContent={'space-between'}
                  >
                    <DisplayText
                      textLocalId={'supportingDocuments'}
                      size={'smMedium'}
                      extraStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                        fontFamily: 'Manrope',
                        marginTop: 1,
                      }}
                    />
                    {showDocumentList() && (
                      <Pressable
                        onPress={() => {
                          setStateData((prev) => ({
                            ...prev,
                            isAddDocumentModalOpen: true,
                            // selectedDocument: undefined,
                          }));
                        }}
                      >
                        <Feather
                          name="plus"
                          size={18}
                          color={Colors.Custom.Gray500}
                        />
                      </Pressable>
                    )}
                  </HStack>
                </FormControl.Label>
              </View>
              {showDocumentList() ? (
                <View ml={2} marginTop={2}>
                  <CareJourneyDocumentsList
                    onDelete={(id: string) => {
                      const updatedDocumentAfterDelete =
                        detailViewFormState?.productDetailForm?.careJourneyDocuments
                          ?.map((document) => {
                            if (document?.id === id || document?.uid === id) {
                              return {
                                ...document,
                                show: false,
                                isDeleted: true,
                              };
                            }
                            return document;
                          })
                          .filter((document) => {
                            if (document?.isDeleted) {
                              if (document?.id) {
                                return document;
                              }
                            } else {
                              return document;
                            }
                          }) || [];
                      setDetailViewFormState((prev) => {
                        return {
                          ...prev,
                          productDetailForm: {
                            ...prev.productDetailForm,
                            careJourneyDocuments:
                              updatedDocumentAfterDelete || [],
                          },
                        };
                      });
                    }}
                    documents={
                      detailViewFormState?.productDetailForm
                        ?.careJourneyDocuments
                    }
                  />
                </View>
              ) : (
                <Pressable
                  onPress={() => {
                    setStateData((prev) => ({
                      ...prev,
                      isAddDocumentModalOpen: true,
                      // selectedDocument: undefined,
                    }));
                  }}
                >
                  <VStack mt={2} alignItems={'center'} space={2}>
                    <Image
                      resizeMode="contain"
                      // resizeMethod="resize"
                      size={'sm'}
                      source={require('../../../../../../../../../../assets/images/EmptyStatePng/noDocument.png')}
                      alt="image"
                    ></Image>
                    <Text fontSize={'10px'} fontWeight={600} color={'gray.400'}>
                      {'No Files Added'}
                    </Text>
                    <Text
                      borderRadius={4}
                      borderWidth={1}
                      color={Colors.Custom.mainPrimaryPurple}
                      borderColor={Colors.Custom.mainPrimaryPurple}
                      px={4}
                      py={2}
                    >
                      {'Add Files'}
                    </Text>
                  </VStack>
                </Pressable>
              )}
            </FormControl>
          </View>
          {stateData?.isAddDocumentModalOpen && (
            <View>
              <UploadPatientDocument
                contactId={''}
                isCareJourneyDocument={true}
                isVisible={true}
                onCancel={() => {
                  setStateData((prev) => ({
                    ...prev,
                    isAddDocumentModalOpen: false,
                    // selectedDocument: undefined,
                  }));
                }}
                careJourneyDocuments={detailViewFormState?.productDetailForm?.careJourneyDocuments}
                onUpload={(files) => {
                  const previousDocuments =
                    detailViewFormState?.productDetailForm
                      ?.careJourneyDocuments || [];
                  setDetailViewFormState((prev) => {
                    return {
                      ...prev,
                      productDetailForm: {
                        ...prev.productDetailForm,
                        careJourneyDocuments: [...previousDocuments, ...files],
                      },
                    };
                  });
                  setStateData((prev) => ({
                    ...prev,
                    isAddDocumentModalOpen: false,
                    // selectedDocument: undefined,
                  }));
                }}
              />
            </View>
          )}
        </HStack>
      </View>
      {/* </View> */}
    </>
  );
};
