import React from 'react';
import {Path, Svg} from 'react-native-svg';

const DataNotFoundSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="102"
      height="102"
      fill="none"
      viewBox="0 0 102 102"
    >
      <circle
        cx="51"
        cy="51"
        r="50"
        stroke="url(#paint0_radial_1643_31595)"
        strokeDasharray="1.46 1.46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      ></circle>
      <circle
        cx="51"
        cy="51"
        r="42.667"
        stroke="url(#paint1_radial_1643_31595)"
        strokeDasharray="1.46 1.46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      ></circle>
      <Path
        stroke="#D0D6E1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M51 40.646v11.666m3.634-26.224l3.561 3.035a5.598 5.598 0 003.187 1.32l4.664.372a5.601 5.601 0 015.138 5.138l.371 4.664a5.605 5.605 0 001.322 3.188l3.034 3.56a5.602 5.602 0 010 7.267l-3.033 3.561a5.605 5.605 0 00-1.322 3.188l-.373 4.664a5.6 5.6 0 01-5.136 5.139l-4.665.372a5.6 5.6 0 00-3.187 1.32l-3.56 3.034a5.605 5.605 0 01-7.27 0l-3.56-3.034a5.6 5.6 0 00-3.187-1.32l-4.666-.372a5.599 5.599 0 01-5.135-5.138l-.373-4.665a5.6 5.6 0 00-1.322-3.187l-3.033-3.562a5.6 5.6 0 01-.001-7.266l3.036-3.561a5.596 5.596 0 001.317-3.188l.373-4.664a5.605 5.605 0 015.142-5.138l4.66-.372a5.601 5.601 0 003.188-1.32l3.562-3.035a5.605 5.605 0 017.268 0zm-3.489 34.975v.291l-.29-.001v-.29h.29z"
      ></Path>
      <defs>
        <radialGradient
          id="paint0_radial_1643_31595"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 -72.5485 72.5485 0 51 73.608)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9"></stop>
          <stop offset="1" stopColor="#E2E2E2" stopOpacity="0.35"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_1643_31595"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 60.256 9.93) scale(66.9587)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6C6C6"></stop>
          <stop offset="1" stopColor="#E4E7EC" stopOpacity="0.45"></stop>
        </radialGradient>
      </defs>
    </Svg>
  );
}

export default DataNotFoundSvg;
