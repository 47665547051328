import React from 'react';
import {Path, Svg} from 'react-native-svg';

const AutomationAnalyticsSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M14.268 7.645c1.302-2.536 1.953-3.804 1.853-4.252a1.126 1.126 0 00-1.211-.889c-.457.04-1.47 1.042-3.496 3.047L6.77 10.142c-1.24 1.226-1.86 1.84-1.76 2.494.097.655.855.96 2.37 1.569l1.504.605c.56.225.839.337 1.01.524.216.237.324.56.298.897-.02.265-.168.55-.461 1.123-1.302 2.537-1.953 3.805-1.853 4.253.126.567.632.939 1.211.888.457-.04 1.47-1.042 3.496-3.046l4.643-4.591c1.24-1.226 1.86-1.84 1.76-2.494-.097-.655-.855-.96-2.37-1.57l-1.504-.604c-.56-.225-.839-.338-1.01-.524a1.188 1.188 0 01-.298-.897c.02-.265.168-.551.461-1.124z"
      ></Path>
    </Svg>
  );
};

export default AutomationAnalyticsSvg;
