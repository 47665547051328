import { useLazyQuery } from '@apollo/client';
import {Pressable, Text, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {Colors} from '../../../../styles';
import {getFeatureFlag, isEmployerRole, isMasterAccount} from '../../../../utils/commonUtils';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../common/CustomTabsView/interfaces';
import {TableTopBar} from '../../../common/TableTopBar';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import DateRangeFilter from '../../Analytics/EmployerAnalytics/CommonView/DateRangeFilter';
import {EMPLOYER_CONST} from '../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import Analytics from '../../BrazilCodeMR/pages/analytics';
import { Employer } from '../../BrazilCodeMR/pages/analytics/AnalyticsController';
import EmpDashboard from '../../BrazilCodeMR/pages/analytics/EmpDashboard';
import {getPathFromHash} from '../../ContentManagement/ContentManagementUtils';
import {MembersManagement} from '../../MembersManagement';
import {styles} from './EmployerMainViewStyle';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import SettingSvg from '../../../common/Svg/SideMenu/SettingSvg';

import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
const EmployerMainView = ()=> {
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const intl = useIntl();
  const {id} = useParams();
  const commonData = useContext(CommonDataContext);
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, commonData);
  const isMasterAccountFlag = isMasterAccount();
  const [stateData, setStateData] = useState({
    memberTopBar: <></>,
    dashboardAction: false,
  });
  const disableAnalytics = isAccountConfigEnabled(CONFIG_CODES.DISABLE_ANALYTICS)
  const [dateRange, setDateRange] = useState({
    from: '' as any,
    to: '' as any,
    uiFromDate: '' as any,
    uiToDate: '' as any,
  });
  const [state, setState] = useState({
    dateRangeType: EMPLOYER_CONST.QUARTER,
    employer: {
      id: '',
      name: ''
    } as Employer,
  });

  const [getEmployerNameById] = useLazyQuery(EmployerQueries.getEmployerNameById, {
    fetchPolicy: 'no-cache',
    variables: {
      id: id,
    },
  });

  const getEmployerName = async () => {
     const employerName = await getEmployerNameById();
     setState((prev) => {
      return {
        ...prev,
          employer: {
            id: id ?? '',
            name: employerName?.data?.employer?.name ?? ''
          },
      };
    });
  }


  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'members',
        title: isVbcTenant? 'Members': 'Employees',
        path: 'members',
        tabContainerElem: () => {
          return (
            <MembersManagement
              isHideMainTitleBar={true}
              callBackToParent={(data: any) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    memberTopBar: data?.tableTopBar,
                  };
                });
              }}
            />
          );
        },
      },
      ...(!disableAnalytics && !isMasterAccountFlag
        ? [
            {
              key: 'dashboard',
              title: `${state?.employer?.name} Dashboard`,
              path: 'dashboard',
              resourceCode: MAIN_MENU_CODES.ANALYTICS,
              tabContainerElem: () => {
                return (
                  <Analytics
                    state={state}
                    dateRange={dateRange}
                    dashboardAction={stateData.dashboardAction}
                    isHideMainTitleBar={true}
                    isFromEmployerListing={true}
                    dashboardCallback={() => {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          dashboardAction: false,
                        };
                      });
                    }}
                  />
                );
              },
            },
          ]
        : []),
    ];
    return array;
  };
  const DashboardTopBar = (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <DateRangeFilter
        dateRange={dateRange}
        setDateRange={setDateRange}
        setState={setState}
        state={state}
      />

      <Pressable
        style={{width: 24, height: 24, marginLeft: 14}}
        onPress={() => {
          setStateData((prev) => {
            return {
              ...prev,
              dashboardAction: true,
            };
          });
        }}
      >
        <SettingSvg
          customStrokeColor={Colors.Custom.Gray500}
          isSelected={true}
        />
      </Pressable>
    </View>
  );
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele: any = false;

    if (selectedTab?.key === 'dashboard') {
      ele = DashboardTopBar;
    }  else {
      ele = stateData.memberTopBar
    }
    return ele;
  };
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    // setSearchStringText('');
  };
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tagsTabList().findIndex((item) => item.path === path);
    getEmployerName()

    setSelectedTab(tagsTabList()[tabIndex]);
  }, []);
  return (
    <View flex={1}>
      <View style={styles.titleWrapper}>
        <TitleSubtitleView
          title={'Audit Logs'}
          subtitleLabelId="servicesManagementSubtitle"
        />
      </View>
      {isEmployerRole() ? (
        <MembersManagement
          isHideMainTitleBar={true}
          callBackToParent={(data: any) => {
            setStateData((prev) => {
              return {
                ...prev,
                memberTopBar: data?.tableTopBar,
              };
            });
          }}
        />
      ) : (
        <View style={styles.tagContainer}>
          <CustomTabsView
            showBackButton={isEmployerRole() ? false : true}
            contentTopMargin={0}
            showHeading={true}
            basePath={`/members/employers/${id}`}
            tabsList={tagsTabList()}
            tabListWrapperStyle={{
              paddingHorizontal: 16,
              backgroundColor: '#fff',
            }}
            canNavigate={true}
            rightView={() => {
              return getSelectedTopBar(selectedTab);
            }}
            onTabClick={(tabItem) => {
              onTabClick(tabItem);
            }}
          />
        </View>
      )}
    </View>
  );
}

export default React.memo(EmployerMainView)
