export enum CodeGroupCategory {
  diagnosis = 'Diagnosis',
  allergy = 'Allergy',
  medication = 'Medication',
  procedure = 'Procedure',
  labOrder = 'Lab Order',
  immunization = 'Immunization',
  problem = 'Problem',
}

export const POPULATION_GROUP_ALL_FILTER_LABEL = 'All'
export const POPULATION_GROUP_ALL_FILTER_CODE = 'ALL'

export const PopulationGroupFilterTypes = [{
  code: POPULATION_GROUP_ALL_FILTER_CODE,
  label: POPULATION_GROUP_ALL_FILTER_LABEL,
}, {
  code: 'STATIC',
  label: 'Static',
}, {
  code: 'DYNAMIC',
  label: 'Dynamic',
}]

export const LeadGroupFilterTypes = [{
  code: POPULATION_GROUP_ALL_FILTER_CODE,
  label: POPULATION_GROUP_ALL_FILTER_LABEL,
}, {
  code: 'STATIC_LEAD',
  label: 'Static',
}, {
  code: 'DYNAMIC_LEAD',
  label: 'Dynamic',
}]

export const POPULATION_FILTER_TYPE = 'POPULATION_FILTER_TYPE'

export const INITIAL_CAMPAIGN_DESCRIPTION_LENGTH = 300;

export const CAMPAIGN_SEGMENT_TABLE_ACTIONS = {
  DELETE: 'DELETE',
  EXECUTE_RULE: 'EXECUTE_RULE'
}

export const RULES_EXECUTION_STATUSES = {
  QUEUED: 'QUEUED',
  START: 'START',
  COMPLETED: 'COMPLETED'
}

export const SUPPORTED_WEARABLES = [
  {
    label: 'Blood Glucose',
    loinc: '2339-0',
    category: 'vital-signs',
    unit: 'mg/dL'
  },
  {
    label: 'Heart Rate',
    loinc: '8867-4',
    category: 'vital-signs',
    unit: 'bpm',
  },
  {
    label: 'Heart Rate Variability',
    loinc: '80404-7',
    category: 'vital-signs',
    unit: 'ms',
  },
  {
    label: 'Resting Heart Rate',
    loinc: '40443-4',
    category: 'vital-signs',
    unit: 'bpm',
  },
  {
    label: 'Oxygen Saturation',
    loinc: '2708-6',
    category: 'vital-signs',
    unit: '%',
  },
  {
    label: 'Steps',
    loinc: '41950-7',
    parentLoinc: '79534-4',
    category: 'activity',
  },
  {
    label: 'Calories',
    loinc: '93822-5',
    parentLoinc: '79534-4',
    category: 'activity',
    unit: 'kcal',
  },
  {
    label: 'Sleep',
    loinc: '82235-3',
    category: 'activity',
    parentLoinc: '82235-3',
    unit: 'hour',
  },
  {
    label: 'Deep sleep',
    loinc: '93831-6',
    category: 'activity',
    parentLoinc: '82235-3',
    unit: 'hour',
  },
  {
    label: 'Awake time',
    loinc: 'LA33709-9',
    category: 'activity',
    parentLoinc: '82235-3',
    unit: 'hour',
  },
  {
    label: 'REM sleep',
    loinc: '93829-0',
    category: 'activity',
    parentLoinc: '82235-3',
    unit: 'hour',
  },
  {
    label: 'Light sleep',
    loinc: '93830-8',
    category: 'activity',
    parentLoinc: '82235-3',
    unit: 'hour',
  },
];


export const LOCATION_SPECIFIC_FIELDS = [
  'form.name',
  'hasAppointment.appointmentType',
  'hasCondition.status',
  'hasLabResult.name',
  'hasCondition.status', 
  'practitioner',
  'hasCareTeam',
]