
const BulletedListSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 9.5C7.47386 9.5 7.25 9.72386 7.25 10C7.25 10.2761 7.47386 10.5 7.75 10.5V9.5ZM17.5 10.5C17.7761 10.5 18 10.2761 18 10C18 9.72386 17.7761 9.5 17.5 9.5V10.5ZM2.5 9.5C2.22386 9.5 2 9.72386 2 10C2 10.2761 2.22386 10.5 2.5 10.5V9.5ZM3.25 10.5C3.52614 10.5 3.75 10.2761 3.75 10C3.75 9.72386 3.52614 9.5 3.25 9.5V10.5ZM2.5 4.5C2.22386 4.5 2 4.72386 2 5C2 5.27614 2.22386 5.5 2.5 5.5V4.5ZM3.25 5.5C3.52614 5.5 3.75 5.27614 3.75 5C3.75 4.72386 3.52614 4.5 3.25 4.5V5.5ZM2.5 14.5C2.22386 14.5 2 14.7239 2 15C2 15.2761 2.22386 15.5 2.5 15.5V14.5ZM3.25 15.5C3.52614 15.5 3.75 15.2761 3.75 15C3.75 14.7239 3.52614 14.5 3.25 14.5V15.5ZM7.75 4.5C7.47386 4.5 7.25 4.72386 7.25 5C7.25 5.27614 7.47386 5.5 7.75 5.5V4.5ZM17.5 5.5C17.7761 5.5 18 5.27614 18 5C18 4.72386 17.7761 4.5 17.5 4.5V5.5ZM7.75 14.5C7.47386 14.5 7.25 14.7239 7.25 15C7.25 15.2761 7.47386 15.5 7.75 15.5V14.5ZM17.5 15.5C17.7761 15.5 18 15.2761 18 15C18 14.7239 17.7761 14.5 17.5 14.5V15.5ZM7.75 10.5H17.5V9.5H7.75V10.5ZM2.5 10.5H3.25V9.5H2.5V10.5ZM2.5 5.5H3.25V4.5H2.5V5.5ZM2.5 15.5H3.25V14.5H2.5V15.5ZM7.75 5.5H17.5V4.5H7.75V5.5ZM7.75 15.5H17.5V14.5H7.75V15.5Z"
        fill="#6F7A90"
      />
    </svg>
  );
};

export default BulletedListSvg;
