import {CALL_CONFIGURATION_TRANSFER_TO_CODE} from "./CallExtensionConst";
import {IExtensionList, IExtensionResponseData} from "./interface";

export const getFormattedCallExtensionList = (extensionList: IExtensionResponseData[]) => {
  return extensionList.map((extension) => {
    return {
      id: extension?.id,
      extensionName: extension?.extensionName,
      extensionNumber: extension?.extensionNumber,
      transferToType: extension?.transferToType,
      transferToEntityId: extension?.transferToEntityId,
      isActive: extension?.isActive,
      accountUuid: extension?.accountUuid,
      isExistingDeskPhoneExtension: extension?.isExistingDeskPhoneExtension,
      selectedNumber: {
        ...extension?.virtualPhoneNumberAssignee,
        title: extension?.virtualPhoneNumberAssignee?.virtualPhoneNumber
      },
      selectedDeskPhone: {
        ...extension?.sipPhoneCredential,
        title: extension?.sipPhoneCredential?.phoneName,
      },
      selectedUser: {
        ...extension?.user,
        id: extension?.user?.uuid || extension?.user?.id,
        title: extension?.user?.name || ''
      },
      isDisabled: extension?.isExistingDeskPhoneExtension,
    }
  })
}

export const getTransferToTypeText = (transferTo: string, extension: IExtensionList) => {
  switch (transferTo) {
    case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
      return extension?.selectedNumber?.title;
    case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
      return extension?.selectedDeskPhone?.title;
    case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
      return extension?.selectedUser?.title;
    default:
      return '';
  }
}

export const getExtensionIdByTransferType = (transferTo: string, extension: IExtensionResponseData) => {
  switch (transferTo) {
    case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
      return extension?.virtualPhoneNumberAssignee?.virtualPhoneNumber;
    case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
      return extension?.sipPhoneCredential?.sipUri;
    case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
      return extension?.user?.uuid;
    default:
      return '';
  }
}

export const getExtensionName = (extension: IExtensionList) => {
  return extension?.extensionName;
}