import React from 'react';
import {Svg, Path, Rect} from 'react-native-svg';

function FilterIconSvgForAlerts() {
  return (
    <Svg
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M10.116.5H7.3C3.383.5 1.424.5 1.054 1.686c-.37 1.187 1.19 2.494 4.31 5.108.928.777 1.391 1.166 1.64 1.714.247.548.247 1.183.247 2.452v3.104c0 .484 0 .726.055.879.143.4.532.627.917.537.148-.034.333-.167.704-.433.364-.262.546-.393.697-.546.382-.39.637-.906.726-1.47.035-.22.035-.458.035-.933v-1.323c0-1.181 0-1.772.218-2.291.218-.52.628-.907 1.448-1.68l.168-.157c2.737-2.58 4.105-3.87 3.716-5.008C15.545.5 13.735.5 10.116.5z"
      ></Path>
    </Svg>
  );
}

export default FilterIconSvgForAlerts;
