import {Table} from 'antd';
import {Badge, HStack, Text} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Pressable, View} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {Colors} from '../../../styles/Colors';
import CustomConfirmationModal from '../../common/CustomConfirmationModal/CustomConfirmationModal';
import Divider from '../../common/CustomDrawer/Divider';
import DangerSvg from '../../common/Svg/DangerSvg';
import MessageDeleteSvg from '../../common/Svg/MessageDeleteSvg';
import MessageEditSvg from '../../common/Svg/MessageEditSvg';
import SmallUserSvg from '../../common/Svg/SmallUserSvg';
import EyeOpenSvg from '../../common/Svg/WidgetsSvg/EyeOpenSvg';
import {TableWrapper} from '../../common/TableWrapper';
import {
  cellDateRenderFunction,
  cellTextRenderFunction,
  getOnCallScheduleTableColumns,
} from './common';
import {bindUuidsWithAccountUsers} from './commonUtils';
import {ON_CALL_SCHEDULE_VIEW} from './const';
import {
  IOnCallSchedule,
  IOnCallScheduleTableColumnsParams,
  IOnCallScheduleTableProps,
} from './interface';
import {replaceHashValueToString} from '../../../utils/commonUtils';

const OnCallScheduleTable = (props: IOnCallScheduleTableProps) => {
  const intl = useIntl();
  const commonData = React.useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const IvrUsageTypeList = mlovData['IvrUsageType'] || [];

  const [stateData, setStateData] = React.useState({
    showCannotDeleteModal: false,
    cannotDeleteModalMessage: '',
  } as {
    showCannotDeleteModal: boolean;
    cannotDeleteModalMessage: string;
  });

  const cellScheduleTypeRenderFunction = (
    data: IOnCallSchedule
  ): JSX.Element => {
    const IvrUsageType = IvrUsageTypeList.find((value) => {
      return value.id === data.phoneTreeTypeId;
    });
    const renderValue = IvrUsageType?.value || '';
    return cellTextRenderFunction({
      value: renderValue,
    });
  };

  const cellUsersRenderFunction = (data: IOnCallSchedule): JSX.Element => {
    const uuids = data?.onCallScheduleDurations?.[0]?.onCallScheduleUsers?.map(
      (users) => {
        return users.userId;
      }
    );
    const scheduledAccountUsers = bindUuidsWithAccountUsers(
      uuids,
      props?.accountUsers || []
    );
    return (
      <HStack style={{flexWrap: 'wrap'}}>
        {scheduledAccountUsers.map((user) => {
          return (
            <Badge
              style={{
                paddingVertical: '2px',
                paddingHorizontal: '6px',
                marginVertical: 4,
                marginHorizontal: 4,
                backgroundColor: Colors.FoldPixel.GRAY50,
                alignSelf: 'center',
              }}
              borderRadius={4}
            >
              <HStack alignItems={'center'}>
                <SmallUserSvg
                  customStrokeColor={Colors.FoldPixel.GRAY250}
                  width={12}
                  height={12}
                />
                <Text
                  style={{
                    fontWeight: '400',
                    fontSize: 14,
                    lineHeight: 16.8,
                    color: Colors.FoldPixel.GRAY300,
                    marginLeft: 4,
                  }}
                >
                  {user?.user?.name}
                </Text>
              </HStack>
            </Badge>
          );
        })}
      </HStack>
    );
  };

  const cellActionsRenderFunction = (data: IOnCallSchedule): JSX.Element => {
    return (
      <HStack alignItems={'center'}>
        <Pressable
          disabled={false}
          style={{padding: 4}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.EDIT, data);
          }}
        >
          <MessageEditSvg
            width={20}
            height={20}
            customStrokeColor={Colors.FoldPixel.GRAY300}
          />
        </Pressable>
        <Divider />
        <Pressable
          disabled={false}
          style={{padding: 4}}
          onPress={() => {
            props.onActionPerformed(
              COMMON_ACTION_CODES.OPEN_MODAL,
              ON_CALL_SCHEDULE_VIEW.VIEW_SCHEDULE,
              data
            );
          }}
        >
          <EyeOpenSvg
            width={20}
            height={20}
            customStrokeColor={Colors.FoldPixel.GRAY300}
          />
        </Pressable>
        <Divider />
        <Pressable
          disabled={false}
          style={{padding: 4}}
          onPress={() => {
            if (data?.onCallScheduleLinkedPhoneTree?.length) {
              setStateData((prev) => {
                return {
                  ...prev,
                  showCannotDeleteModal: true,
                  cannotDeleteModalMessage: replaceHashValueToString(
                    {
                      count: data?.onCallScheduleLinkedPhoneTree?.length
                        ? data?.onCallScheduleLinkedPhoneTree?.length + ''
                        : '',
                    },
                    intl.formatMessage({id: 'mappedScheduleDeleteAlertMessage'})
                  ),
                };
              });
            } else {
              props.onActionPerformed(COMMON_ACTION_CODES.DELETE, data);
            }
          }}
        >
          <MessageDeleteSvg
            width={20}
            height={20}
            customStrokeColor={Colors.FoldPixel.GRAY300}
          />
        </Pressable>
      </HStack>
    );
  };

  const onCallScheduleTableColumns: IOnCallScheduleTableColumnsParams[] = [
    {
      textLocalId: 'scheduleName',
      key: 'scheduleName',
      dataIndex: 'scheduleName',
      renderFunction: (value) => {
        return cellTextRenderFunction({value: value});
      },
    },
    {
      textLocalId: 'users',
      key: 'scheduleUsers',
      width: '30%',
      renderFunction: cellUsersRenderFunction,
    },
    {
      textLocalId: 'date',
      key: 'scheduleDate',
      renderFunction: cellDateRenderFunction,
    },
    {
      textLocalId: 'phoneTreeType',
      key: 'scheduleType',
      width: '15%',
      renderFunction: cellScheduleTypeRenderFunction,
    },
    {
      textLocalId: 'action',
      key: 'scheduleAction',
      width: '15%',
      renderFunction: cellActionsRenderFunction,
    },
  ];

  return (
    <View
      style={{
        overflow: 'hidden',
      }}
    >
      <TableWrapper pagination={{}}>
        <Table
          loading={props.isLoading}
          columns={
            onCallScheduleTableColumns.map((column) => {
              return getOnCallScheduleTableColumns(column);
            }) as any[]
          }
          onRow={(_record) => {
            return {
              onClick: () => {
                props.onActionPerformed(
                  COMMON_ACTION_CODES.OPEN_MODAL,
                  ON_CALL_SCHEDULE_VIEW.VIEW_SCHEDULE,
                  _record
                );
              },
            };
          }}
          dataSource={props?.onCallScheduleList || []}
        ></Table>
      </TableWrapper>
      {stateData.showCannotDeleteModal && (
        <CustomConfirmationModal
          isOpen={stateData.showCannotDeleteModal}
          headerText="cannotDeleteSchedule"
          message={stateData.cannotDeleteModalMessage}
          hideCancelBtn={true}
          customIcon={<DangerSvg />}
          customOkBtnText={intl.formatMessage({id: 'okay'})}
          customOkBtnStyle={{
            borderColor: Colors.FoldPixel.GRAY250,
            backgroundColor: Colors.Custom.White,
            width: '100%',
          }}
          customOkBtnTextStyle={{color: Colors.FoldPixel.GRAY300}}
          onConfirm={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showCannotDeleteModal: false,
              };
            });
          }}
        />
      )}
    </View>
  );
};

export default OnCallScheduleTable;
