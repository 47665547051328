import { Box, HStack, Skeleton, View } from 'native-base';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FILTER_COMPONENT_CODE,
  PRODUCT_SERVICES_FLOW_TYPE_CODE
} from '../../../../constants';
import { PRODUCT_SIDEBAR_CODE } from '../../../../constants/SectionConst';
import { ICommerceOauthTokenResp } from '../../../../context/interfaces';
import { ProductsAndServicesContext } from '../../../../context/ProductsAndServicesDataContext';
import { FlowType } from '../../../../context/WorkflowContext';
import { IStockLocationData } from '../../../../services/ProductAndServices/interfaces/StockLocation';
import { commonBodyContentContainer } from '../../../../styles/CommonBodyContentStyle';
import { capitalizeText } from '../../../common/ContactRelationView/ContactRelationUtils';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../../common/CustomTabsView/interfaces';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES
} from '../../../common/TableTopBar';
import AutomationTopBar from '../../Contacts/PatientsAdminPage/AutomationTopBar';
import WorkflowView from '../../Workflow/Workflow/WorkflowView';
import { getOrdersListTopBarButtons } from './Orders/OrdersTableTopbar';
import PaymentSetup from './PaymentSetup/PaymentSetup';
import {
  ITaxonData
} from './Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';
import ProductTableMainView from './Products/ProductTableView/ProductTableMainView';
import { getProductListTopBarButtons } from './Products/ProductTableView/ProductUtils/ProductTableColumn';
import { styles } from './ProductsAndServicesStyles';
import { getPackagesTopBarButtons } from './JourneyPackages/PackagesTableColumn';
import JourneyPackagesView from './JourneyPackages/JourneyPackagesView';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { getConfigDataFromCode } from '../../../../utils/commonUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { isJourneyPackagesEnable } from './JourneyPackages/PackagesUtils';
import {PackagesLabelView} from './JourneyPackages/PackagesLabelsView';
import {getPackagesLabelTopBarButtons} from './JourneyPackages/Helper';
import {PACKAGES_LABEL} from './ProductsAndServicesConst';
import {useIntl} from 'react-intl';
import {USER_ACCESS_PERMISSION} from '../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';

const ProductsAndServices = () => {
  const {type, tabName} = useParams();
  const [searchStringText, setSearchStringText] = useState('');
  const journeyPackagesConfig = getConfigDataFromCode(CONFIG_CODES.ENABLE_JOURNEY_PACKAGE);
  const isPackagesEnable = isJourneyPackagesEnable(journeyPackagesConfig);
  const intl = useIntl();
  const tabType = {
    products: PRODUCT_SERVICES_FLOW_TYPE_CODE.PRODUCTS,
    services: PRODUCT_SERVICES_FLOW_TYPE_CODE.SERVICES,
    journeys: PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS,
  };
  const pageTitle = () => {
    switch (type) {
      case tabType.products:
        return {
          title: 'Products Management',
          automationType: FlowType.products,
        };
        break;
      case tabType.services:
        return {
          title: 'Services Management',
          automationType: FlowType.services,
        };
        break;
      case tabType.journeys:
        return {
          title: 'Care Journeys Management',
          automationType: FlowType.journeys,
        };
        break;
    }
  };

  const getSelectedTypeTaxonId = () => {
    const tabType =
      type === PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS
        ? 'care-journeys'
        : type;
    const selectedTabCategory = productsAndServicesState.taxons.find((item) => {
      return item.attributes?.permalink === tabType;
    });
    if (selectedTabCategory) {
      return selectedTabCategory.id;
    }
  };

  const [productsAndServicesState, setProductsAndServicesState] = useState({
    isLoading: false,
    selectedTabCode: PRODUCT_SIDEBAR_CODE.CATEGORY,
    tokenData: {} as ICommerceOauthTokenResp,
    stockLocations: [] as IStockLocationData[],
    taxons: [] as ITaxonData[],
    addEditPayment: false,
  });

  const orderDetailTabView: ITabsList[] = [
    {
      key: type || '',
      title: capitalizeText(type || ''),
      path: type || 'products',
    },
    ...(isPackagesEnable
      ? [
          {
            key: 'packages',
            title: 'Packages',
            path: 'packages',
          },
          PACKAGES_LABEL,
        ]
      : []),
  ];

  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const [completeOrderCheck, setCompleteOrderCheck] = useState(true);
  const [dateRangeFilter, serDateRangeFilter] = useState({
    fromDate: '',
    toDate: '',
  });

  const [packagesState, setPackagesStateData] = useState({
    selectedActionCode: '',
    selectedRawData: '' as any,
    openCreateDrawer: false,
    addLabelDrawer: false
  });

  const setDefaultSelectedTab = () => {
    if (type) {
      const key = tabName ? tabName : type;
      const selectedTabView = orderDetailTabView;
      const results = selectedTabView.filter((obj) => {
        return obj.key == key;
      });
      setSelectedTab(results[0]);
    } else {
      setSelectedTab(orderDetailTabView[0]);
    }
  };

  useEffect(() => {
    setDefaultSelectedTab();
  }, []);

  useEffect(() => {
    setDefaultSelectedTab();
  }, [type]);

  const servicesTabs = (): ITabsList[] => {
    return orderDetailTabView;
  };

  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setSearchStringText('');
  };
  const onTabChanged = (path: string) => {
    const tab = orderDetailTabView.find((item) => {
      return item.path === path;
    })
    if (tab) {
      setSelectedTab(tab);
      setSearchStringText('');
    }
  }
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.NEW_PACKAGE:
        setPackagesStateData((prev) => ({
          ...prev,
          openCreateDrawer: true,
          selectedRawData: actionData,
        }));
        break;
      case TABLE_TOP_BAR_ACTION_CODES.NEW_LABEL:
        setPackagesStateData((prev) => ({
          ...prev,
          addLabelDrawer: true,
          selectedRawData: actionData,
        }));
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        if (selectedTab?.key === 'packages') {
          setPackagesStateData((prev) => ({
            ...prev,
            selectedActionCode:
              prev.selectedActionCode ===
              TABLE_TOP_BAR_ACTION_CODES.PACKAGE_FILTER
                ? ''
                : TABLE_TOP_BAR_ACTION_CODES.PACKAGE_FILTER,
            selectedRawData: actionData,
          }));
        }
        break;
    }
  };
  const handleSearchChange = (actionData: any) => {
    setSearchStringText(actionData.searchString);
  };
  const onFilterActionPerfomed = () => {
    // onFilterActionPerfomed
  };

  const onActionPerformed = (actioncode: string, rawData: any) => {
    switch (actioncode) {
      case FILTER_COMPONENT_CODE.DATE:
        serDateRangeFilter({
          ...dateRangeFilter,
          fromDate: rawData.fromDate,
          toDate: rawData.toDate,
        });
        break;
      case FILTER_COMPONENT_CODE.COMPLETED:
        setCompleteOrderCheck(rawData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.NEW_PACKAGE:
          setPackagesStateData((prev) => ({
            ...prev,
            openCreateDrawer: true,
            selectedRawData: rawData,
          }));
        break;
      case COMMON_ACTION_CODES.CANCEL:
          setPackagesStateData((prev) => ({
            ...prev,
            openCreateDrawer: false,
            selectedRawData: '',
          }));
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
          setPackagesStateData((prev) => ({
            ...prev,
            openCreateDrawer: false,
            addLabelDrawer: false,
            selectedRawData: '',
          }));
        break;
    }
  };

  const [productTableMainViewState, setProductTableMainViewState] = useState({
    productData: {} as any,
    isRowSelected: false,
    loading: false,
  });
  const navigate = useNavigate();

  const openRegularProductView = (productData: any) => {
    setProductTableMainViewState((prev) => {
      return {
        ...prev,
        isRowSelected: true,
        productData,
      };
    });
  };

  const onProductTableRowClick = (productData: any) => {
    if (productData.id) {
      navigate(
        `/admin/commerce/${type}/product/${productData.productId ? productData.productId: -1}/journey/${productData.id}`
      );
    } else {
      if (productData.tabType && productData.tabType.length > 0) {
        if (productData.tabType === 'journeys') {
          navigate(
            `/admin/commerce/${productData.tabType}/product/-1/journey/create`
          );
        } else if (
          productData.tabType === 'products' ||
          productData.tabType === 'services' ||
          productData.tabType === 'membership'
        ) {
          openRegularProductView(productData);
        }
      }
    }
  };

  const ordertabBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        searchText={searchStringText}
        placeHolderText={'Search'}
        isDividerNotVisible={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getOrdersListTopBarButtons(
          [onFilterActionPerfomed],
          onActionPerformed,
          type
        )}
      />
    </>
  );
  const ProductTabBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        searchText={searchStringText}
        isDividerNotVisible={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getProductListTopBarButtons(type || '', [
          () => {
            onProductTableRowClick({tabType: type} as any);
          },
        ])}
      />
    </>
  );

  const PackagesTabBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        searchText={searchStringText}
        isDividerNotVisible={true}
        showFilterIcon
        filterText={intl.formatMessage({id: 'filterPackage'})}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getPackagesTopBarButtons(onTableTopBarActionPerformed)}
      />
    </>
  );
  const LabelsTabBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        searchText={searchStringText}
        isDividerNotVisible={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getPackagesLabelTopBarButtons(onTableTopBarActionPerformed)}
      />
    </>
  );
  const workflowUrl = `create?currentTag=TRIGGER&flowType=${
    pageTitle()?.automationType || ''
  }`;

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab?.key === 'orders') {
      ele = ordertabBar;
    } else if (selectedTab?.key === type) {
      ele = ProductTabBar;
    } else if (selectedTab?.key === 'automation') {
      ele = <AutomationTopBar workflowUrl={workflowUrl} onTableTopBarActionPerformed={onTableTopBarActionPerformed}></AutomationTopBar>;
    } else if (selectedTab?.key === 'packages') {
      ele = PackagesTabBar;
    } else if (selectedTab?.key === PACKAGES_LABEL.key) {
      ele = LabelsTabBar;
    }
    return ele;
  };

  return (
    <ProductsAndServicesContext.Provider
      value={{
        tokenData: productsAndServicesState.tokenData,
        stockLocationList: productsAndServicesState.stockLocations,
      }}
    >
      <View backgroundColor={'white'} flex={1}>
        <View style={styles.tagContainer}>
          <CustomTabsView
            hideAutomationSettingsButton={selectedTab?.key === PACKAGES_LABEL.key}
            canNavigate={true}
            showHeading
            heading={ type=='journeys'? "journeysTitle":'productTitle'}
            basePath={`/admin/commerce/${type}`}
            extraBigRightView
            tabListWrapperStyle={{
            }}
            handleWithOnTab={true}
            tabsList={servicesTabs()}
            onTabClick={(tabItem) => {
              onTabClick(tabItem);
            }}
            rightView={() => {
              return getSelectedTopBar(selectedTab);
            }}
            onTabChanged={onTabChanged}
            bottomOffset={150}
            flowType={FlowType.journeys}
          />
        </View>
        <HStack
          flex={1}
          style={[commonBodyContentContainer.pageContentContainer]}
        >
          {productsAndServicesState.isLoading ? (
            <Skeleton.Text margin={4} lines={4} />
          ) : (
            <>
              <Box style={styles.productSideBar}>
                <View
                  bgColor={'white'}
                  borderRadius={0}
                >
                  {selectedTab?.key === type && (
                    <ProductTableMainView
                      searchText={searchStringText}
                      type={type || ''}
                      tabCategoryId={getSelectedTypeTaxonId()}
                      productTableMainViewState={productTableMainViewState}
                      onRowClicked={onProductTableRowClick}
                    />
                  )}
                  {selectedTab?.key === 'automation' && (
                    <WorkflowView
                      searchText={searchStringText}
                      showTableTopBar={false}
                      flowType={pageTitle()?.automationType || ''}
                    />
                  )}
                  {selectedTab?.key === 'paymentSetup' && (
                    <PaymentSetup
                      addEditPaymentEvent={
                        productsAndServicesState.addEditPayment
                      }
                    />
                  )}
                  {selectedTab?.key === 'packages' && (
                    <JourneyPackagesView
                      onActionPerformed={onActionPerformed}
                      searchString={searchStringText}
                      selectedActionCode={packagesState.selectedActionCode}
                      openCreateDrawer={packagesState.openCreateDrawer}
                    />
                  )}
                  {selectedTab?.key === PACKAGES_LABEL.key && (
                    <PackagesLabelView
                      onActionPerformed={onActionPerformed}
                      searchString={searchStringText}
                      selectedActionCode={packagesState.selectedActionCode}
                      addLabelDrawer={packagesState.addLabelDrawer}
                    />
                  )}
                </View>
              </Box>
            </>
          )}
        </HStack>
      </View>
    </ProductsAndServicesContext.Provider>
  );
};

export default ProductsAndServices;
