import { DATE_FORMATS } from "../../../constants";
import { getDateStrFromFormatWithTimezone } from "../../../utils/DateUtils";
import { IInternalUsers } from "../../common/CustomUserSearch/interface";
import { IOnCallSchedule, IOnCallScheduleDuration } from "./interface";

export const collectAllUserUuidsFromOnCallScheduleDuration = (scheduleDuration: IOnCallScheduleDuration) => {
  return scheduleDuration?.onCallScheduleUsers?.map((user: any) => {
    return user.userId;
  }) || []
}

export const collectAllUserUuidsFromOnCallSchedule = (onCallSchedule: IOnCallSchedule): string[] => {
  const result: string[] = onCallSchedule?.onCallScheduleDurations?.flatMap(
    (scheduleDuration: IOnCallScheduleDuration) => {
      return (
        collectAllUserUuidsFromOnCallScheduleDuration(scheduleDuration)
      );
    }
  ) || [];
  return result;
}

export const collectAllUserUuidsFromOnCallSchedules = (data: IOnCallSchedule[]): string[] => {
  let uuids: any =
    data?.map((onCallSchedule) => {
      return collectAllUserUuidsFromOnCallSchedule(onCallSchedule)
    }) || [];

  uuids = uuids?.flat(2);
  uuids = Array?.from(new Set(uuids));
  return uuids || [];
};

export const bindUuidsWithAccountUsers = (uuids: string[], accountUser: IInternalUsers[]): IInternalUsers[] => {
  const uuidSet = new Set(uuids);
  const bindedData = accountUser.filter((user) => {
    return uuidSet.has(user.user.uuid)
  });
  return bindedData;
}

export const getOnCallScheduleWeeksString = (daysArray: number[]): string => {
  const weekDays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  daysArray.sort((a, b) => a - b);

  const result: string[] = [];
  let i = 0;

  while (i < daysArray.length) {
    const start = daysArray[i];
    let end = start;

    while (i + 1 < daysArray.length && daysArray[i + 1] === daysArray[i] + 1) {
      end = daysArray[i + 1];
      i++;
    }

    if (start === end) {
      result.push(weekDays[start]);
    } else if (end === start + 1) {
      result.push(`${weekDays[start]}, ${weekDays[end]}`);
    } else {
      result.push(`${weekDays[start]} to ${weekDays[end]}`);
    }
    i++;
  }

  // Correctly format the final string to avoid redundant "and"
  if (result.length > 1) {
    return result.slice(0, -1).join(', ') + ' and ' + result[result.length - 1];
  } else {
    return result[0] || "";
  }
};

export const formattedDateTime = (date: Date): string => {
  const dateString = getDateStrFromFormatWithTimezone(
    date || '',
    undefined,
    DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT
  )

  return dateString;
};


export const getFormattedOnCallScheduleDateTimeString = (fromDate: Date | null, toDate: Date | null, daysArray: number[]): string => {
  const weekString = getOnCallScheduleWeeksString(
    daysArray
  );
  const dateString = `${fromDate ? formattedDateTime(fromDate) : ''} to ${toDate ? formattedDateTime(toDate) : ''} on ${weekString}`;

  return dateString
}