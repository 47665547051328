import {useLazyQuery} from '@apollo/client';
import {useToast} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, View} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import BaseService from '../../../services/CommonService/BaseService';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import CustomConfirmationModal from '../../common/CustomConfirmationModal/CustomConfirmationModal';
import {IInternalUsers} from '../../common/CustomUserSearch/interface';
import {collectAllUserUuidsFromOnCallSchedules} from './commonUtils';
import {ON_CALL_SCHEDULE_VIEW} from './const';
import CreateOrEditOnCallScheduleDrawer from './CreateOrEditOnCallScheduleDrawer';
import {
  IOnCallSchedule,
  IOnCallScheduleProps,
  IOnCallScheduleState,
} from './interface';
import OnCallScheduleTable from './OnCallScheduleTable';
import './style.css';
import ViewOnCallScheduleDrawer from './ViewOnCallScheduleDrawer';

const OnCallSchedule = (props: IOnCallScheduleProps) => {
  const toast = useToast();
  const intl = useIntl();
  const axios = BaseService.getSharedInstance().axios;
  const {width} = Dimensions.get('window');
  const drawerWidth = width / 3;

  const [stateData, setStateData] = useState({
    selectedView: '',
    userList: [],
    event: '',
    selectedData: {},
    selectedInboxData: {},
    selectedOnScheduleRow: null,
    onCallScheduleList: [],
    accountUsers: [],
    loading: false,
    inboxes: [],
  } as IOnCallScheduleState);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [getInternalUsersDataByUUIDs] = useLazyQuery(
    InboxQueries.GetInternalUsersByUUIDs,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getOnCallSchedulesList = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const res = await axios.get(`/cloud-telephony/api/v1/on-call-schedule`);
      let onCallScheduleList: IOnCallSchedule[] = [];
      let accountUsers: IInternalUsers[] = [];
      if (res?.data?.length) {
        onCallScheduleList = res?.data;

        const uuids =
          collectAllUserUuidsFromOnCallSchedules(onCallScheduleList);
        const internalUsersData: any = await getInternalUsersDataByUUIDs({
          variables: {
            userUuids: uuids,
          },
        });

        if (internalUsersData.data?.accountUsers) {
          accountUsers = internalUsersData.data?.accountUsers || [];
        }
      }

      setStateData((prev) => {
        return {
          ...prev,
          onCallScheduleList: onCallScheduleList,
          accountUsers: accountUsers,
          loading: false,
        };
      });
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const onActionPerformed = async (
    actionCode: string,
    data?: any,
    value?: any
  ): Promise<void> => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        props.onActionPerformed(actionCode, data);
        setStateData((prev) => {
          return {
            ...prev,
            selectedOnScheduleRow: null,
          };
        });
        break;
      case COMMON_ACTION_CODES.OPEN_MODAL:
        if (data === ON_CALL_SCHEDULE_VIEW.VIEW_SCHEDULE) {
          setStateData((prev) => {
            return {
              ...prev,
              selectedOnScheduleRow: value,
            };
          });
        }
        props.onActionPerformed(actionCode, data);
        break;
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedOnScheduleRow: data,
          };
        });
        props.onActionPerformed(
          COMMON_ACTION_CODES.OPEN_MODAL,
          ON_CALL_SCHEDULE_VIEW.CREATE_SCHEDULE
        );
        break;
      case COMMON_ACTION_CODES.UPDATE:
        try {
          const res = await axios.post(
            `/cloud-telephony/api/v1/on-call-schedule`,
            data
          );
          if (res?.data?.data?.created) {
            props.onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
            showToast(
              toast,
              intl.formatMessage({id: 'scheduleCreatedSuccessfully'}),
              ToastType.success,
              4000
            );
            getOnCallSchedulesList();
          } else {
            value();
          }
        } catch (error) {
          value();
        } finally {
          setStateData((prev) => {
            return {
              ...prev,
              selectedOnScheduleRow: null,
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.CREATE_ON_CALL_SCHEDULE:
        try {
          const res = await axios.post(
            `/cloud-telephony/api/v1/on-call-schedule`,
            data
          );
          if (res?.data?.data?.created) {
            props.onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
            showToast(
              toast,
              intl.formatMessage({id: 'scheduleCreatedSuccessfully'}),
              ToastType.success,
              4000
            );
            getOnCallSchedulesList();
          } else {
            value();
          }
        } catch (error) {
          value();
        }
        break;
      case COMMON_ACTION_CODES.DELETE:
        const isConfirmed = Boolean(value);
        if (isConfirmed) {
          try {
            const res = await axios.delete(
              `/cloud-telephony/api/v1/on-call-schedule/${data?.id}`
            );
            if (res?.data?.count) {
              props.onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
              showToast(
                toast,
                intl.formatMessage({id: 'scheduleDeletedSuccessfully'}),
                ToastType.success,
                4000
              );
              getOnCallSchedulesList();
            } else {
              showToast(
                toast,
                intl.formatMessage({id: 'unableToDeleteSchedule'}),
                ToastType.error,
                4000
              );
            }
          } catch (error) {
            showToast(
              toast,
              intl.formatMessage({id: 'unableToDeleteSchedule'}),
              ToastType.error,
              4000
            );
          } finally {
            setStateData((prev) => {
              return {
                ...prev,
                selectedOnScheduleRow: null,
              };
            });
          }
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              selectedOnScheduleRow: data,
            };
          });
          setOpenConfirmationModal(true);
        }
        break;
      default:
        props.onActionPerformed(actionCode, data);
    }
  };

  useEffect(() => {
    getOnCallSchedulesList();
  }, []);

  return (
    <>
      <View
        style={{
          overflow: 'hidden',
          backgroundColor: '#fff',
        }}
      >
        <OnCallScheduleTable
          isLoading={stateData.loading}
          onActionPerformed={onActionPerformed}
          accountUsers={stateData.accountUsers}
          onCallScheduleList={stateData.onCallScheduleList}
        />
      </View>
      {props?.selectedDrawerCode === ON_CALL_SCHEDULE_VIEW.VIEW_SCHEDULE && (
        <ViewOnCallScheduleDrawer
          width={drawerWidth}
          selectedOnCallScheduleData={stateData.selectedOnScheduleRow}
          accountUsers={stateData.accountUsers}
          onActionPerformed={onActionPerformed}
        />
      )}

      {props?.selectedDrawerCode === ON_CALL_SCHEDULE_VIEW.CREATE_SCHEDULE && (
        <CreateOrEditOnCallScheduleDrawer
          width={drawerWidth}
          accountUsers={stateData.accountUsers}
          selectedOnCallScheduleData={stateData.selectedOnScheduleRow}
          onActionPerformed={onActionPerformed}
        />
      )}

      {openConfirmationModal && (
        <CustomConfirmationModal
          isOpen={openConfirmationModal}
          headerText="deleteScheduleQuestion"
          message="deleteScheduleModalText"
          onCancel={() => {
            setOpenConfirmationModal(false);
          }}
          onConfirm={() => {
            setOpenConfirmationModal(false);
            onActionPerformed(
              COMMON_ACTION_CODES.DELETE,
              stateData.selectedOnScheduleRow,
              true
            );
          }}
        />
      )}
    </>
  );
};

export default OnCallSchedule;
