import React from 'react';
import {Svg, Path} from 'react-native-svg';

function HighSeveritySvg() {
  return (
    <Svg
      width="12"
      height="13"
      fill="none"
      viewBox="0 0 12 13"
    >
      <Path
        fill="#D92D20"
        d="M2.5 2.5v5l1.235-.247a4.338 4.338 0 012.462.226 4.338 4.338 0 002.664.18l.107-.026a.702.702 0 00.532-.681V3.268a.6.6 0 00-.745-.582 4 4 0 01-2.456-.166l-.102-.041a4.338 4.338 0 00-2.462-.226L2.5 2.5z"
      ></Path>
      <Path
        stroke="#D92D20"
        strokeLinecap="round"
        d="M2.5 11.5v-4m0 0v-5m0 5l1.235-.247a4.338 4.338 0 012.462.226 4.338 4.338 0 002.664.18l.107-.026a.702.702 0 00.532-.681V3.268a.6.6 0 00-.745-.582 4 4 0 01-2.456-.166l-.102-.041a4.338 4.338 0 00-2.462-.226L2.5 2.5m0 0v-1"
      ></Path>
    </Svg>
  );
}

export default HighSeveritySvg;
