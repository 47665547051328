import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Divider,
  HStack,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
  useToast,
  View,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useSearchParams} from 'react-router-dom';
import {IPAD_MINI_WIDTH, IPAD_WIDTH, PARENT_CODE, TEAM_USERS_TYPE, TEAM_USERS_TYPE_LIST} from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { COMMUNICATION_APP_BASE_URL } from '../../../../constants/BaseUrlConst';
import { GROUP_TYPE_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import {
  branchRecordDummyData,
  teamRecordDummyData,
} from '../../../../dummyData/teamMembersDummyData';
import {UserQueries} from '../../../../services';
import BaseService from '../../../../services/CommonService/BaseService';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {getAllAgentsList} from '../../../../services/ProfileImage/ProfileImage';
import TeamQueries from '../../../../services/Team/TeamQueries';
import {Colors} from '../../../../styles';
import {commonBodyContentContainer} from '../../../../styles/CommonBodyContentStyle';
import {
  getAccountId,
  getAccountName,
  getAccountUUID,
  getAgentsList,
  getAssociatedAccounts,
  getUserId,
  isMasterAccount,
  isZoomEnabled,
} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import InstantChat from '../../../common/ChatDrawer/InstantChat';
import { IAccount, IHostInfo, IMeeting, IUserList } from '../../../common/MeetingView/interface';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../common/TableTopBar';
import { scheduleHMSMeet } from '../../../common/ZoomView/ZoomServices';
import {OverrideModal} from '../../ContentManagement/OverrideModal';
import EditUserWithBusinessHours from './AddEditUser/EditUserWithBusinessHours';
import {TEAM_MEMBERS_VIEW_CODE} from './AddEditUser/TeamMemberConst';
import DuplicateUsersInfoModal from './DuplicateUsersInfoModal';
import EditAccountDrawer from './EditAccount/EditAccountDrawer';
import {IAccountData} from './EditAccount/interface';
import {IUser, IUsersResponse} from './interfaces';
import {InviteUserDrawer} from './InviteUserDrawer';
import {PractitionerData, SingleEmployer} from './InviteUserDrawer/interfaces';
import {getFormattedPractitionerData, inviteUser} from './InviteUserDrawer/InviteUserDrawerUtils';
import {AddMemberModal} from './TeamMembersModals';
import {TeamMembersTable} from './TeamMembersTable';
import {deactivateUser, resetUserPassword} from './TeamMembersTable/Helper';
import {
  cancelInvite,
  getFinalUserList,
  getFormattedInvitedUserData,
  getInvitedUsers,
  getPostRequestBodyForInviteUser,
} from './TeamMembersUtils';
import {styles} from './TeamMembersViewStyles';
import { CHANNEL_TYPE_CODE } from '../../TeamInbox/Conversations/ConversationConst';
import { useIntl } from 'react-intl';
import { getPractitionersData, getPractitionersDataByAccount } from '../../../../services/CommonService/AidBoxService';
import { LocationDetails } from './AddEditUser/interfaces';
import { usePractitionerIdentifierFilter } from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/usePractitionerIdentifierFilter/usePractitionerIdentifierFilter';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';

interface ITeamMembersView {
  inviteUser?: string;
  resetCheck?: () => void;
  stateData?:any;
  onClose?:(actionCode: string,actionData: any) => void;
  updateContactCount?:(count: number) => void;
}
const TeamMembersView = (props: ITeamMembersView) => {
  const {height} = Dimensions.get('window');
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [inviteUserDrawer, setInviteUserDrawer] = useState(false);
  const toast = useToast();
  const accountUUID = getAccountUUID();
  const accountId = getAccountId();
  const accountName = getAccountName();
  const [deactivateUserModal, setDeactivateUserModal] = useState(false);
  const [isFetchingLoggedInInfo, setIsFetchingLoggedInInfo] = useState(false);
  const [resendInviteModal, setResendInviteModal] = useState(false);
  const [instantChatModal, setInstantChatModal] = useState(false);
  const [cancelInviteModal, setCancelInviteModal] = useState(false);
  const [deleteLocationModal, setDeleteLocationModal] = useState({
    openModal: false,
    selectedId: '',
  });

  const [teamRecord] = useState(teamRecordDummyData);
  const [branchRecord] = useState(branchRecordDummyData);
  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [stateData, setStateData] = useState({
    loading: false,
    userData: [] as IUser[],
    selectedUser: {} as IUser,
    showAddEdit: false,
    showPracticeLocation: false,
    selectedViewCode: COMMON_ACTION_CODES.COMPLETE,
    selectedLocationData: {} as any,
    accountData: {} as IAccountData,
    selectedData: {} as any,
    externalLocations: [] as LocationDetails[],
    filterTypeValue: TEAM_USERS_TYPE_LIST[0].value
  });
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    pageSize: 10,
    offSet: 0,
    total: 0
  })
  const mlovData = useContext(CommonDataContext);
  const associatedAccounts = getAssociatedAccounts();
  const userData = mlovData?.userData;
  const currentUrl = window.location.href;
  const [userList, setUserList] = useState([] as IUserList[]);
  const [meetingObj, setMeetingObj] = useState({
    meetingName: `Meeting : ${userData?.name} <> ${stateData.selectedUser.name}`,
    patientName: stateData.selectedUser.name,
    sendEmail: true,
    error: '',
    inviteeList: [] as IUserList[],
    selectedInvitee: [] as number[],
    account: {
      accountId,
      accountUUID,
      accountName,
    } as IAccount,
    hosInfo: {
      name: userData?.name,
      email: userData?.email,
      uuid: userData?.uuid,
    } as IHostInfo,
  } as IMeeting);
  const isMasterAccountFlag = isMasterAccount();
  const [filteredData, setFilteredData] = useState([] as any);
  const [externalUserList, setExternalUserList] = useState<PractitionerData[]>([])
  const [employerList, setEmployerList] = useState<SingleEmployer[]>([]);
  const [searchParams] = useSearchParams();
  const isWalkThrough = searchParams.get('isWalkThrough') || false;
  const userId = getUserId()
  const intl = useIntl();
  const { practitionerAPILoading, practitionerList } = usePractitionerIdentifierFilter();

  const baseService = BaseService.getSharedInstance().axios;
  const [getAccountUsers] = useLazyQuery(InboxQueries.GetAgents);
  const [getUserCustomAttr] = useLazyQuery(UserQueries.GET_USER_CUSTOM_ATTR)

  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: accountUUID,
      query: '%%',
    },
  });

  const getEmployersList = async () => {
    try {
      const response = await getEmployers();
      setEmployerList(response.data.employers || []);
    } catch (error) {
      setEmployerList([]);

    }
  };


  useEffect(() => {
    getEmployersList();
    getAgentsData();
  }, []);
  const getCurrentSubdomain = () => {
    if (window.location.host.includes('localhost')) {
      return 'demo.dev.foldhealth.io';
    }
    return window.location.host;
  };

  const setLoading = (value: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  };

  const getPractitionerList = async () => {
    try {
      if (isMasterAccountFlag) {
        const promiseList = associatedAccounts.map((account) => {
          if (accountUUID !== account.uuid) {
            return getPractitionersDataByAccount(account.uuid)
              .then(response => ({ status: 'fulfilled', value: response }))
              .catch(error => ({ status: 'rejected', reason: error }));
          }
        }).filter(Boolean);

        const results = await Promise.allSettled(promiseList);
        const successfulResponses = results
          .filter(result => result.status === 'fulfilled')
          .map((result: any) => {
            return result.value;
          });

        let resultEntry: any = {
          entry: []
        };
        successfulResponses.forEach((response: any)=> {
          if (response.value?.data?.entry?.length){
            resultEntry = {
              ...response.value?.data,
              entry: resultEntry.entry.concat(response.value?.data?.entry)
            }
          }
        })
        const data = getFormattedPractitionerData(resultEntry);
        setExternalUserList(data);
      } else {
      const responses = await getPractitionersData();
      const data = getFormattedPractitionerData(responses.data);
      setExternalUserList(data);
    }
    } catch (error) {
    }
  };

  const setUserData = (data: IUser[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        userData: [...data],
      };
    });
  };

  const agentsData = getAgentsList()

  const getTableFilterData = () => {
    const newData = stateData?.userData?.map((user) => {
      let  employerObj:any = {}
      if (user?.accountUsers?.[0]?.employerId) {
        employerObj = employerList?.find((emp) => {
          return emp.id == user?.accountUsers?.[0]?.employerId;
        });
      }
      const matchUser = agentsData?.find((agent: any) => {
        if (user?.id === agent?.id) {
          return agent;
        }
      });
      if (matchUser)
        return {
          ...user,
          agent: matchUser,
          employerId: employerObj?.name || '',
        };
      else
        return {...user, employerId: employerObj?.name || ''};
    });
    setFilteredData(newData);
  };

  const setAddEditModalDetails = (show: string, user?: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: user || ({} as IUser),
        selectedViewCode: show,
      };
    });
  };
  const setAddPracticeLocationState = (code: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedViewCode: code,
      };
    });
  };

  const setEditLocationDrawer = (code: string, selectedLocation?: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedViewCode: code,
        selectedLocationData: selectedLocation,
      };
    });
  };

  const fetchUserData = async (offSet?: number) => {
    setLoading(true);
    let sortedMembers = [] as IUser[];
    let count = 0;

    if (stateData.filterTypeValue === TEAM_USERS_TYPE.ACCOUNT_USERS) {
      const response = await getUsersData(
        {
         variables: {
           searchString: `%${props?.stateData?.selectedRawData?.searchString || ''}%`,
           accountId: accountId,
           limit: paginationState.pageSize,
           offset: offSet !== undefined ? offSet : paginationState.offSet,
         },
        }
       )
       const data: any = response;
       const array = data.data?.users?.filter((item: IUser) => {
        let roles = '';
        item.userRoles?.forEach((element: any) => {
          roles += element?.userRole?.customRoleCode;
        });
        const externalUserIds: string[] = []
          item.externalUserMap?.forEach((externalUser)=> {
            if (externalUser.externalUserId) {
              externalUserIds.push(externalUser.externalUserId)
            }
          })
          item.externalUserIds = externalUserIds
        if (!roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS') ) {
          return item;
        }
      });
       sortedMembers = getFinalUserList({
        currentUsers: array || [],
        invitedUsers: [],
      });
      count = data?.data?.userAggregate?.aggregate?.count || 0;
      setPaginationState(prev=> {
        return {
          ...prev,
          total: count
        }
      })
    } else if (TEAM_USERS_TYPE.INVITED_USERS === stateData.filterTypeValue ) {
      const invitedUsersDataResp = await getInvitedUsers({
        baseService,
        searchString: props?.stateData?.selectedRawData?.searchString || '',
        limit: paginationState.pageSize,
        offSet: offSet !== undefined ? offSet : paginationState.offSet,
      })
      const invitedUsersData = invitedUsersDataResp?.data?.invitedUsers ? JSON.parse(invitedUsersDataResp?.data?.invitedUsers) : []
      const formattedData = getFormattedInvitedUserData(
        invitedUsersData
      );
       sortedMembers = getFinalUserList({
        currentUsers: [] ,
        invitedUsers: formattedData,
      })
      count = invitedUsersDataResp?.data?.userResetPasswordsAggregate?._count?.id || 0;
      setPaginationState(prev=> {
        return {
          ...prev,
          total: count
        }
      })
    }

    props.updateContactCount?.(count)
    setUserData(sortedMembers || []);
    setLoading(false);
  };

  const getUserSubtitle = async () => {
    try {
      const response = await getUserCustomAttr({variables: {
        userId: userId
      }})
      const subtitle = response?.data?.user?.subtitle || ''
      setMeetingObj(prev => {
        return {
          ...prev,
          hosInfo: {
            ...prev.hosInfo,
            subtitle
          }
        }
      })
    } catch (error) {

    }
  }

  const getAgentsData = async () => {
    const responseData = await getAccountUsers({
      variables: {
        accountId: accountId,
        roleCode: '',
      },
    });
    if (responseData?.data?.accountUsers?.length) {
      const loggedInUserOb = responseData?.data?.accountUsers.find((user: IUserList) => user.userId === userData?.id);
      if (loggedInUserOb.userId) {
        setMeetingObj((prev: any) => {
          return {
            ...prev,
            selectedInvitee: [loggedInUserOb.userId],
            inviteeList: [loggedInUserOb],
          };
        });
      }
    setUserList(responseData?.data?.accountUsers);
    }
  };


  useEffect(() => {
    getTableFilterData();
  }, [stateData]);

  useEffect(()=> {
    if (paginationState.currentPage !== 1) {
      setPaginationState((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offSet: 0
        };
      });
    } else {
      fetchUserData(0);
    }
  },[
    props?.stateData?.selectedRawData?.searchString ,
    stateData.selectedViewCode === COMMON_ACTION_CODES.COMPLETE,
    stateData.filterTypeValue
  ])
  useEffect(() => {
    fetchUserData()
  }, [paginationState.currentPage, paginationState.pageSize]);

  useEffect(() => {
    if (isWalkThrough) {
      setInviteUserDrawer(true);
    }
  }, [props]);

  useEffect(() => {
    if (props.inviteUser) {
      setInviteUserDrawer(true);
    }
  }, [props.inviteUser]);

  useEffect(() => {
    getUserSubtitle();
  }, []);

useEffect(()=> {
  onTableTopBarActionPerformed(props.stateData.selectedActionCode,{})
},[props.stateData.selectedActionCode])


  const filterDataBySearch = (searchValue: string) => {
    const filteredData = stateData.userData.filter((data) => {
      return (
        data.name?.toLowerCase().includes(searchValue) ||
        data.name?.includes(searchValue)
      );
    });
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        filterDataBySearch(actionData.searchString);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.NEW:
        setAddPracticeLocationState(
          TEAM_MEMBERS_VIEW_CODE.ADD_NEW_PRACTICE_LOCATION
        );
        break;
      case TABLE_TOP_BAR_ACTION_CODES.EDIT_ACCOUNT:
        setAddPracticeLocationState(TEAM_MEMBERS_VIEW_CODE.EDIT_ACCOUNT);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.EDIT_LOCATION:
        setEditLocationDrawer(
          TABLE_TOP_BAR_ACTION_CODES.EDIT_LOCATION,
          actionData
        );
        break;
      case TABLE_TOP_BAR_ACTION_CODES.EDIT:
        setAddEditModalDetails(TEAM_MEMBERS_VIEW_CODE.EDIT_USER);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.DELETE:
        //setDeleteLocationModal(true)
        setDeleteLocationModal((prev: any) => {
          return {
            ...prev,
            openModal: true,
            selectedId: actionData.practiceLocation.id,
          };
        });
        break;
      case COMMON_ACTION_CODES.DUPLICATE:
        setStateData(prev=> {
          return{
            ...prev,
            selectedViewCode: COMMON_ACTION_CODES.DUPLICATE,
            selectedData: actionData
          }
        })
      break;
    }
  };

  // get team list
  const getTeamList = (): string[] => {
    const teamList: string[] = [];
    teamRecord.map((team) => {
      teamList.push(team.teamName);
    });
    return teamList;
  };

  // get branch list:
  const getBranchList = (): string[] => {
    const branchList: string[] = [];
    branchRecord.map((branch) => {
      branchList.push(branch.branchName);
    });
    return branchList;
  };

  // table action functions
  const handleSwitchToggle = (teamRecord: IUser) => {
    // handleSwitchToggle
  };

  const handleProfileBtnClick = (teamRecord: IUser) => {
    // handleProfileBtnClick
  };

  const handlePasswordBtnClick = (teamRecord: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord,
      };
    });
    setConfirmationModal(true);
  };

  const handleDeactivateUserBtnClick = (teamRecord: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord,
      };
    });
    setDeactivateUserModal(true);
  };

  const handleResendInvite = (teamRecord: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord,
      };
    });
    setResendInviteModal(true);
  };

  const handleInstantChat = (teamRecord: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord,
      };
    });
    setInstantChatModal(true);
  };

  const handleInstantMeet = (teamRecord: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord,
      };
    });
    const selectedInvites = userList.filter((user: IUserList) => {
      return [teamRecord.id].includes(user.userId);
    });
    setMeetingObj((prev) => {
      return {
        ...prev,
        inviteeList: selectedInvites,
      };
    });
        //meet api call
    handleStartMeeting(teamRecord);
  };

  const isZoomEnabledAccount = isZoomEnabled();

  const handleStartMeeting = async (teamRecord: IUser) => {
    // if (meetingObj.selectedInvitee.length < 1) {
    //   setError((prev: any) => {
    //     return {
    //       ...prev,
    //       internalUsers: 'please select internal users',
    //     };
    //   });
    //   return;
    // }
    meetingObj.formattedContactData = {
      email: teamRecord.email,
      phoneObj: {
        value: ''
      },
      contactUUID: teamRecord.uuid,
      type: 'USER',
      firstName: teamRecord.name,
      lastName: '',
    }
    meetingObj.leaveUrl = currentUrl;

    const scheduleMeetRes = await scheduleHMSMeet(
      accountId,
      accountUUID,
      accountName,
      meetingObj,
      [meetingObj.formattedContactData],
      meetingObj.hosInfo || ({} as IHostInfo),
      isZoomEnabledAccount
    );
    if (isZoomEnabledAccount) {
      window.open(scheduleMeetRes.data.link, '_blank');
      return;
    }
    const meeting: any = scheduleMeetRes?.data?.data;

    const joinMeetingObj = {
      userName:
        meeting.meetingData.hostInfo.first_name +
        ' ' +
        meeting.meetingData.hostInfo.last_name || '',
      authToken: meeting.meetingData.clientToken,
      meetingUrl: meeting?.meetingData?.externalMeetingData?.meetingUrl,
      settings: {
        isAudioMuted: false,
        isVideoMuted: true,
      },
      accountId: accountId,
      accountUUID: accountUUID,
    };

    const stringRep = JSON.stringify(joinMeetingObj);
    const encoded = window.btoa(stringRep);
    // window.open(`http://localhost:3000?token=${encoded}`, '_blank');
    window.open(`${COMMUNICATION_APP_BASE_URL()}?token=${encoded}`, '_blank');
   // closeModal();
  };

  const handleCancelInvite = (teamRecord: IUser) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord,
      };
    });
    setCancelInviteModal(true);
  };

  const onCloseAddEditDrawer = () => {
    setAddEditModalDetails('', {} as IUser);
  };


  const onCloseAfterCompleteEditUser = (actionCode?: string) => {
    // window.location.reload();
    setAddEditModalDetails('', {} as IUser);
    fetchUserData(0);
  };

  const handleOnEditUser = (user: IUser) => {
    setAddEditModalDetails(TEAM_MEMBERS_VIEW_CODE.EDIT_USER, user);
  };

  const onResetPasswordConfirmation = () => {
    const selectedUser = stateData.selectedUser;
    const roleCode =
      selectedUser.userRoles &&
      selectedUser.userRoles[0]?.userRole.roleCategory.code;
    const userUuid = selectedUser.uuid || '';
    resetUserPassword({
      baseService: baseService,
      email: selectedUser.email || '',
      userId: selectedUser.id,
      userUuid: userUuid,
      role: roleCode ? roleCode : '',
      name: selectedUser.name || '',
    })
      .then((response) => {
        showToast(toast, 'Password reset successful!', ToastType.success);
      })
      .catch((error) => {
        showToast(toast, getErrorMessage(error), ToastType.error);
      });
  };

  const onDeactivateUserConfirmation = () => {
    const selectedUser = stateData.selectedUser;
    const isActive = selectedUser.accountUsers?.[0]?.isActive;
    const userId = selectedUser.id;
    const email = selectedUser.email || '';
    const selectedUserAccountId = selectedUser?.accountUsers?.[0]?.account?.id || accountId as number
    const selectedUserAccountUuid = selectedUser?.accountUsers?.[0]?.account?.uuid || accountUUID
    deactivateUser({userId, email, baseService, isActive: !isActive, selectedUserAccountId, selectedUserAccountUuid})
      .then((response) => {
        const toastMessage = isActive
          ? 'User Deactivated Successfully!'
          : 'User Activated Successfully!';
        showToast(toast, toastMessage, ToastType.success);
      })
      .catch((error) => {
        showToast(toast, getErrorMessage(error), ToastType.error);
      });
  };

  const onResendInviteConfirmation = () => {
    const userData = getPostRequestBodyForInviteUser(stateData.selectedUser);
    inviteUser({baseService: baseService, body: userData})
      .then((response) => {
        showToast(toast, 'User Invited!', ToastType.success);
      })
      .catch((error) => {
        showToast(toast, getErrorMessage(error), ToastType.error);
      });
  };

  const onCancelInviteConfirmation = () => {
    const data = stateData.selectedUser;
    const email = data.email || '';
    cancelInvite({baseService, accountUuid: accountUUID, email})
      .then((response) => {
        const affectedRows =
          response?.data?.data?.update_user_reset_passwords?.affected_rows;
        if (affectedRows) {
          showToast(toast, 'Invitation Canceled', ToastType.success);
          fetchUserData(0);
        } else {
          showToast(toast, 'Error in cancelling invitation', ToastType.error);
        }
      })
      .catch((error) => {
        showToast(toast, getErrorMessage(error), ToastType.error);
      });
  };

  const [DELETE_PRACTICE_LOCATION] = useMutation(
    UserQueries.DELETE_PRACTICE_LOCATION
  );

  const onDeleteConfirmation = async () => {
    const updatePracticeLocationRes = await DELETE_PRACTICE_LOCATION({
      variables: {
        id: deleteLocationModal.selectedId,
      },
    });
    if (updatePracticeLocationRes?.data?.updatePracticeLocation?.id) {
      setDeleteLocationModal((prev: any) => {
        return {
          ...prev,
          openModal: false,
          selectedId: '',
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'practiceLocationDeleteSuccess'}),
        ToastType.success,
      );
    }
  };

  const getErrorMessage = (error: any) => {
    return (
      error?.response?.data?.message ||
      'Something went wrong. Please try again later.'
    );
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offSet = currentPageSize * (page - 1);
    setPaginationState(prev=> {
      return {
        ...prev,
        currentPage: page,
        offSet: offSet,
        pageSize: currentPageSize
      }
    })
  };
  return (
    <View bgColor={'white'}>
      <View
        style={[
          styles.mainContainer,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <HStack style={styles.stackContainer}>
          <View style={styles.rightViewContainer}>
            <AddMemberModal
              isModalOpen={openModal}
              onModalClose={() => setOpenModal(false)}
              onSave={(teamMember) => {
                //handleAddTeamMember(teamMember);
              }}
              branchList={getBranchList()}
              teamList={getTeamList()}
            />
            <View
              style={{
                backgroundColor: '#fff',
              }}
              height={isSmallScreen ? height - 350 : height - 190}
            >
              <View style={{flexDirection: 'row', alignItems: 'center'}} mt={2} mb={2}>
                <HStack flex={0.9} ml={3} alignItems={'center'}>
                  <Text>Show: </Text>
                  <ModalActionAntSelect
                    allowClear={false}
                    showSearch={false}
                    value={stateData.filterTypeValue}
                    onChange={(type: any) => {
                      setStateData((prev: any) => {
                        return {
                          ...prev,
                          filterTypeValue: type,
                        };
                      });
                    }}
                    data={TEAM_USERS_TYPE_LIST}
                    optionProps={{key: 'value', value: 'value', label: 'label'}}
                    customStyle={{
                      fontSize: 15,
                      fontWeight: 400,
                      width: 150,
                      borderColor: Colors.Custom.Gray300,
                    }}
                  />
                </HStack>
              </View>
              <Divider orientation="horizontal" width={'100%'} />
              {(stateData.loading || practitionerAPILoading) ? (
                <View style={{padding: '20px'}}>
                  <Skeleton.Text lines={10} />
                </View>
              ) : (
                <Stack
                  space={2}
                  direction={isSmallScreen ? 'column-reverse' : 'row'}
                  h={'100%'}
                >
                  {/* <View flex={0.23} h={'100%'}>
                    <TeamMemberRightSideView
                      accountData={stateData.accountData}
                      externalLocations={stateData.externalLocations}
                      teamUsers={stateData.userData}
                      onActionPerformed={(code: string, data: any) => {
                        onTableTopBarActionPerformed(code, data);
                      }}
                      locationsData={locationsData}
                    />
                  </View> */}
                  <View
                    flex={1}
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <TeamMembersTable
                      stateData={paginationState}
                      handlePageChange={handlePageChange}
                      externalUserList={externalUserList}
                      data={filteredData}
                      onPasswordBtnClick={(teamRecord: IUser) =>
                        handlePasswordBtnClick(teamRecord)
                      }
                      onProfileBtnClick={(teamRecord: IUser) =>
                        handleProfileBtnClick(teamRecord)
                      }
                      onSwitchToggle={(teamRecord: IUser) =>
                        handleSwitchToggle(teamRecord)
                      }
                      onEditUser={(user: IUser) => {
                        handleOnEditUser(user);
                      }}
                      onDeactivateUser={(user) => {
                        handleDeactivateUserBtnClick(user);
                      }}
                      onCancelInvite={(user) => {
                        handleCancelInvite(user);
                      }}
                      onResendInvite={(user) => {
                        handleResendInvite(user);
                      }}
                      onInstantChat={(user) => {
                        handleInstantChat(user);
                      }}
                      onInstantMeet={(user) => {
                        handleInstantMeet(user);
                      }}
                    />
                  </View>
                </Stack>
              )}
            </View>
          </View>
        </HStack>
        {stateData.selectedViewCode === TEAM_MEMBERS_VIEW_CODE.EDIT_USER && (
          <EditUserWithBusinessHours
            roleIsDisable={false}
            licensedStates={practitionerList.filter(item => item.userId === stateData.selectedUser.uuid)}
            onClose={(actionCode) => {
              if (actionCode === COMMON_ACTION_CODES.COMPLETE) {
                onCloseAfterCompleteEditUser();
                return;
              }
              onCloseAddEditDrawer();
            }}
            user={stateData.selectedUser}
            parentCode={PARENT_CODE.TEAM_MANAGEMENT_PANEL}
          />
        )}

        {stateData.selectedViewCode === TEAM_MEMBERS_VIEW_CODE.EDIT_ACCOUNT && (
          <EditAccountDrawer
            showDrawer={
              stateData.selectedViewCode === TEAM_MEMBERS_VIEW_CODE.EDIT_ACCOUNT
                ? true
                : false
            }
            onClose={(code?: string) => {
              setTimeout(() => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    selectedViewCode: '',
                  };
                });
                props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE,{})
              }, 500);
              if (code === 'ONCOMPLETE') {
                if (isWeb()) {
                  //setIsFetchingLoggedInInfo(true)
                  window.location.reload();
                }
              }
            }}
          />
        )}
        <OverrideModal
          isOpen={confirmationModal}
          onClose={() => {
            setConfirmationModal(false);
          }}
          onConfirm={() => {
            onResetPasswordConfirmation();
            setConfirmationModal(false);
          }}
          textContent={{
            headerText: 'Confirmation',
            message: 'Do you want to send reset password instructions?',
          }}
        />

        {deactivateUserModal && (
          <OverrideModal
            isOpen={deactivateUserModal}
            onClose={() => {
              setDeactivateUserModal(false);
            }}
            onConfirm={() => {
              onDeactivateUserConfirmation();
              setDeactivateUserModal(false);
            }}
            textContent={{
              headerText: 'Confirmation',
              message: `Do you want to ${
                stateData.selectedUser.accountUsers?.[0]?.isActive
                  ? 'deactivate'
                  : 'activate'
              } this user?`,
            }}
          />
        )}

        {inviteUserDrawer && (
          <InviteUserDrawer
            employerList={employerList}
            isOpen={inviteUserDrawer}
            onCallBackAction={onTableTopBarActionPerformed}
            onClose={(isRefresh?: boolean) => {
              if (props.resetCheck) {
                props.resetCheck();
              }
              setInviteUserDrawer(false);
              if (isRefresh) {
                fetchUserData(0);
              }
            }}
          />
        )}

        {resendInviteModal && (
          <OverrideModal
            isOpen={resendInviteModal}
            onClose={() => {
              setResendInviteModal(false);
            }}
            onConfirm={() => {
              onResendInviteConfirmation();
              setResendInviteModal(false);
            }}
            textContent={{
              headerText: 'Confirmation',
              message: `Do you want to resend the invitation to this user?`,
            }}
          />
        )}
        {cancelInviteModal && (
          <OverrideModal
            isOpen={cancelInviteModal}
            onClose={() => {
              setCancelInviteModal(false);
            }}
            onConfirm={() => {
              onCancelInviteConfirmation();
              setCancelInviteModal(false);
            }}
            textContent={{
              headerText: 'Confirmation',
              message: `Do you want to cancel the invitation sent to this user?`,
            }}
          />
        )}

        {deleteLocationModal.openModal && (
          <OverrideModal
            isOpen={deleteLocationModal.openModal}
            onClose={() => {
              setDeleteLocationModal((prev: any) => {
                return {
                  ...prev,
                  openModal: false,
                  selectedId: '',
                };
              });
            }}
            onConfirm={() => {
              onDeleteConfirmation();
            }}
            textContent={{
              headerText: 'Confirmation',
              message: `Do you want to delete the location?`,
            }}
          />
        )}
        {stateData.selectedViewCode === COMMON_ACTION_CODES.DUPLICATE ? (
          <DuplicateUsersInfoModal
            isOpen={
              stateData.selectedViewCode === COMMON_ACTION_CODES.DUPLICATE
                ? true
                : false
            }
            dataList={stateData.selectedData}
            onClose={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedData: {} as any,
                  selectedViewCode: '',
                };
              });
            }}
          />
        ) : (
          <></>
        )}
        {instantChatModal && (
          <InstantChat
            groupType={GROUP_TYPE_CODES.INTERNAL}
            name={''}
            secondaryUserUUID={stateData.selectedUser.uuid}
            isDrawerVisible={instantChatModal}
            onActionPerformed={(actionCode) => {
              if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                setInstantChatModal(false);
              }
            }}
            selectedInboxTypeCode={CHANNEL_TYPE_CODE.CHANNEL_INTERNAL}
          />
        )}
      </View>
    </View>
  );
};

export default TeamMembersView;
