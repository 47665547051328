import Svg, {Path} from 'react-native-svg';
import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const SmallUserSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor, width, height} = props;
  const bgColor = customStrokeColor || Colors.Custom.Gray500;

  return (
    <Svg
      width={width ? width : '12'}
      height={height ? height : '12'}
      viewBox="0 0 12 12"
      fill="none"
    >
      <Path
        d="M8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3Z"
        stroke={bgColor}
      />
      <Path
        d="M10 8.75C10 9.99264 10 11 6 11C2 11 2 9.99264 2 8.75C2 7.50736 3.79086 6.5 6 6.5C8.20914 6.5 10 7.50736 10 8.75Z"
        stroke={bgColor}
      />
    </Svg>
  );
};

export default SmallUserSvg;
