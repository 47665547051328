import React, {useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import {Skeleton, Text, useToast} from 'native-base';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import EyeOpenSvg from '../../../../common/Svg/WidgetsSvg/EyeOpenSvg';
import {ISelectConversation} from '../MessagingWindow/interface';
import {CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {
  getTotalConversationCountApi,
  handleBulkArchiveAPI,
  handleMarkAsReadAPi,
} from './SelectedConversationUtils';
import {CHANNEL_TYPE_CODE} from '../ConversationConst';
import { localBroadcastEvent } from '../../../../../utils/CustomEventHandler';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../../constants/WebSocketConst';
import { BULK_ARCHIVE_INBOX_CODE, BULK_CONVERSATION_READ_INBOX_CODE } from '../ConversationChannelTabs/ConversationChannelTabConst';
import OverrideAntdModal from '../../../ContentManagement/OverrideModal/OverrideAntdModal';
import { BUTTON_TYPE } from '../../../../../constants';
import { Tooltip } from 'antd';

const SelectConversation = (props: ISelectConversation) => {
  const toast = useToast();
  const intl = useIntl();

  const [state, setState] = useState({
    countLoading: false,
    conversationCount: 0,
    totalConversationCount: 0,
    selectAllConversation: false,
    selectedConversationUuids: [],
    deselectedConversationUuidList: [],
    selectedMentionConversationIds: [],
    deselectedMentionConversationIds: [],
    showConfirmationModal: false,
    selectedConversationCount: 0,
    archiveActionLoading: false,
  });

  useEffect(() => {
    getTotalConversationCount();
  }, [props?.conversationFilterQuery, props?.selectedInboxTypeCode]);

  useEffect(() => {
    if (props?.selectedConversationUuids) {
      if (props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        setState((prev: any) => {
          return {
            ...prev,
            selectedMentionConversationIds:
              props?.selectedConversationUuids || [],
            deselectedMentionConversationIds:
              props?.deselectedConversationUuidList || [],
          };
        });
      } else {
        setState((prev: any) => {
          return {
            ...prev,
            selectedConversationUuids: props?.selectedConversationUuids || [],
            deselectedConversationUuidList:
              props?.deselectedConversationUuidList || [],
          };
        });
      }
    }
  }, [props?.deselectedConversationUuidList, props?.selectedConversationUuids]);

  const getTotalConversationCount = async () => {
    setState((prev) => {
      return {
        ...prev,
        countLoading: true,
      };
    });
    try {
      const filterData = {
        isRead: props?.conversationFilterQuery?.isRead?._eq,
        dateRange: props?.conversationFilterQuery?._and,
        assigneeIds: props?.conversationFilterQuery.assigneeId?._in
      };
      const conversationCount = await getTotalConversationCountApi(
        props?.selectedInboxTypeCode,
        filterData,
        props?.selectedInboxId,
        props?.conversationUserFilterData
      );
      setState((prev: any) => {
        return {
          ...prev,
          totalConversationCount: conversationCount || 0,
          countLoading: false,
        };
      });
    } catch (e) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      setState((prev: any) => {
        return {
          ...prev,
          countLoading: false,
        };
      });
    }
  };

  const handleMarkAsRead = async () => {
    try {
      const filterData = {
        isRead: props?.conversationFilterQuery?.isRead?._eq,
        dateRange: props?.conversationFilterQuery?._and,
      };
      const conversationUuids = await handleMarkAsReadAPi(
        props?.selectedInboxTypeCode,
        state.selectAllConversation,
        state?.selectedConversationUuids,
        state?.deselectedConversationUuidList,
        state?.selectedMentionConversationIds,
        state?.deselectedMentionConversationIds,
        filterData,
        props?.selectedInboxId,
        props?.conversationUserFilterData
      );
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_MARK_AS_READ_CONVERSATION, {
        conversationUuids: conversationUuids
      });
      props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
      showToast(toast, 'Mark as read', ToastType.success);
    } catch (e) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const handleBulkArchive = async () => {
    setState((prev) => {
      return {
        ...prev,
        archiveActionLoading: true,
      }
    })
    try {
      const filterData = {
        isRead: props?.conversationFilterQuery?.isRead?._eq,
        dateRange: props?.conversationFilterQuery?._and,
        assigneeIds: props?.conversationFilterQuery.assigneeId?._in,
      };
      const conversationUuids = await handleBulkArchiveAPI({
        selectedInboxTypeCode: props?.selectedInboxTypeCode,
        selectAllConversation: state.selectAllConversation,
        selectedConversationUuids: state?.selectedConversationUuids,
        deselectedConversationUuidList: state?.deselectedConversationUuidList,
        filterQuery: filterData,
        inboxId: props?.selectedInboxId,
        conversationUserFilterData: props?.conversationUserFilterData
      });
      props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
      localBroadcastEvent(
        CONVERSATION_LOCAL_EVENT_CODES.LOCAL_BULK_ARCHIVE_CONVERSATION,
        {
          conversationUuids: conversationUuids,
        }
      );
      setState((prev) => {
        return {
          ...prev,
          archiveActionLoading: false,
        }
      })
      showToast(
        toast,
        intl.formatMessage({id: 'archivedSuccessfully'}),
        ToastType.success
      );
    } catch (e) {
      setState((prev) => {
        return {
          ...prev,
          archiveActionLoading: false,
        }
      })
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const getMarkAsReadButtonElement = () => {
    return (
        <Pressable
          style={{
            paddingHorizontal: 6,
            paddingVertical: 6,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: Colors.Custom.Gray200,
          }}
          onPress={() => {
            handleMarkAsRead();
          }}
        >
          <Stack
            direction="row"
            style={{
              alignItems: 'center',
            }}
          >
            <EyeOpenSvg />
            <Text
              style={{
                paddingRight: 4,
                paddingLeft: 8
              }}
              fontSize={'sm'}
              color={Colors.Custom.Gray500}
            >
              {'Mark as Read'}
            </Text>  
          </Stack>
        </Pressable>
    );
  };

  const getArchiveButtonElement = () => {
    const checkSelectedConversationCountLimit = getSelectedConversationCount() > 500
    return (
      <Tooltip
        title={
          checkSelectedConversationCountLimit
            ? intl.formatMessage({id: 'disableBulkArchive'})
            : ''
        }
        placement={'top'}
      >
        <Pressable
          style={{
            paddingHorizontal: 6,
            paddingVertical: 6,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: Colors.Custom.Gray200,
            opacity: checkSelectedConversationCountLimit ? 0.3 : 1,
          }}
          disabled={checkSelectedConversationCountLimit}
          onPress={() => {
            setState((prev) => {
              return {
                ...prev,
                showConfirmationModal: true,
              };
            });
          }}
        >
          <Stack
            direction="row"
            style={{
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                paddingRight: 2,
                paddingLeft: 4,
              }}
              fontSize={'sm'}
              color={Colors.Custom.Gray500}
            >
              {'Archive'}
            </Text>
          </Stack>
        </Pressable>
      </Tooltip>
    );
  }

  const checkSelectAllEnable = () => {
    return (
      state.selectAllConversation ?
      (props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
        ? !state?.deselectedMentionConversationIds?.length
        : !state?.deselectedConversationUuidList?.length)
      : (state?.selectedConversationUuids?.length === state.totalConversationCount || 
        state?.selectedMentionConversationIds?.length === state.totalConversationCount)
    );
  };

  const getSelectedConversationCount = () => {
    const isMentionChannelTab =
      props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION;
    let selectedConversationCount = 0;
    if (state.selectAllConversation) {
      const deSelectedConversationCount = isMentionChannelTab
        ? state?.deselectedMentionConversationIds?.length
        : state.deselectedConversationUuidList?.length; 
      selectedConversationCount = state.totalConversationCount - deSelectedConversationCount;
    } else {
      selectedConversationCount = isMentionChannelTab
        ? state.selectedMentionConversationIds?.length
        : state.selectedConversationUuids?.length;
    }
    return selectedConversationCount;
  };

  const getConfirmationModalElementView = () => {
    const selectedConversationCount = getSelectedConversationCount();
    return (
      <OverrideAntdModal
        isOpen={state.showConfirmationModal}
        onClose={() => {
          setState((prev) => {
            return {
              ...prev,
              showConfirmationModal: false,
            };
          });
        }}
        onConfirm={() => {
          handleBulkArchive();
        }}
        closeButtonText="cancel"
        closeButtonType={BUTTON_TYPE.PRIMARY}
        confirmButtonText="confirm"
        confirmButtonType={BUTTON_TYPE.SECONDARY}
        textContent={{
          headerText: 'Bulk Action?',
          message: `This action will affect ${
            selectedConversationCount > 1
              ? `all ${selectedConversationCount} conversations`
              : `1 conversation`
          }. Are you sure you want to continue?`,
        }}
        confirmButtonLoading={state.archiveActionLoading}
      />
    )
  }

  return (
    <View style={{flex: 1}}>
      <Stack
        direction="row"
        style={{flex: 1, alignItems: 'center', padding: 16}}
      >
        <Stack direction="row" style={{flex: 0.5}}>
          <View style={{marginTop: 4}}>
            <Text
              style={{
                paddingRight: 16,
                borderRightWidth: 1,
                borderRightColor: Colors.Custom.Gray200,
              }}
              fontSize={'md'}
              color={Colors.Custom.Gray500}
            >{`Selected ${getSelectedConversationCount()}`}</Text>
          </View>
          {BULK_CONVERSATION_READ_INBOX_CODE.includes(
            props?.selectedInboxTypeCode
          ) && (
            <View style={{paddingLeft: 16}}>
              {getMarkAsReadButtonElement()}
            </View>
          )}
          {BULK_ARCHIVE_INBOX_CODE.includes(props?.selectedInboxTypeCode) && (
            <View style={{paddingLeft: 16}}>{getArchiveButtonElement()}</View>
          )}
        </Stack>
        <Stack direction="row" style={{flex: 0.5, justifyContent: 'flex-end'}}>
          {!state.countLoading ? (
            <>
              <Pressable
                onPress={() => {
                  const isAllSelected = checkSelectAllEnable();
                  props?.onActionPerformed(
                    CONVERSATION_ACTION_CODES.SELECT_ALL_CONVERSATION,
                    isAllSelected
                  );
                  setState((prev) => {
                    return {
                      ...prev,
                      selectAllConversation: !isAllSelected,
                    };
                  });
                }}
              >
                <Text
                  style={{
                    paddingRight: 16,
                    borderRightWidth: 1,
                    borderRightColor: Colors.Custom.Gray200,
                  }}
                  fontSize={'md'}
                  color={Colors.Custom.mainPrimaryPurple}
                >{`${checkSelectAllEnable() ? `Unselect all` : `Select all`} ${
                  state.totalConversationCount
                } conversations`}</Text>
              </Pressable>
            </>
          ) : (
            <Skeleton.Text lines={1} width={'40%'} />
          )}
          <Pressable
            onPress={() => {
              props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
            }}
          >
            <AntIcon
              name="close"
              size={20}
              color={Colors.Custom.Gray400}
              style={{paddingLeft: 16}}
            />
          </Pressable>
        </Stack>
      </Stack>
      {state?.showConfirmationModal && getConfirmationModalElementView()}
    </View>
  );
};

export default SelectConversation;
