import React from 'react';
import {Svg, Path} from 'react-native-svg';

function LowSeveritySvg() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <Path
        fill="#F79009"
        d="M3.333 2.667v6.666l1.647-.33a5.785 5.785 0 013.283.302 5.785 5.785 0 003.552.241l.143-.035a.936.936 0 00.709-.909V3.691a.8.8 0 00-.994-.776 5.332 5.332 0 01-3.274-.222l-.136-.054a5.785 5.785 0 00-3.283-.302l-1.647.33z"
      ></Path>
      <Path
        stroke="#F79009"
        strokeLinecap="round"
        strokeWidth="1.333"
        d="M3.333 14.667V9.333m0 0V2.667m0 6.666l1.647-.33a5.785 5.785 0 013.283.302 5.785 5.785 0 003.552.241l.143-.035a.936.936 0 00.709-.909V3.691a.8.8 0 00-.994-.776 5.332 5.332 0 01-3.274-.222l-.136-.054a5.785 5.785 0 00-3.283-.302l-1.647.33m0 0V1.333"
      ></Path>
    </Svg>
  );
}

export default LowSeveritySvg;
