import {View} from 'native-base';
import {
  default as AntIcon,
  default as Icon,
} from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {default as MaterialCommunityIcons} from 'react-native-vector-icons/MaterialCommunityIcons';
import {ACCESS_PROVISION_VIEW } from '../../constants';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import { USER_ROLE_CODES } from '../../constants/MlovConst';
import {Colors} from '../../styles';
import { getCurrentUserRole, isChildAccount, isMasterAccount } from '../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import LocalStorage from '../../utils/LocalStorage';
import Appointment from '../common/Svg/Appointment';
import BrandedSvg from '../common/Svg/BrandedSvg';
import CareJourney from '../common/Svg/CareJourney';
import Consumer from '../common/Svg/Consumer';
import EducationContent from '../common/Svg/EducationContent';
import PatientOutlineIcon from '../common/Svg/PatientOutlineIcon';
import AdminSvg from '../common/Svg/SideMenu/AdminSvg';
import AnalyticsSvg from '../common/Svg/SideMenu/Analytics';
import BillingSvg from '../common/Svg/SideMenu/BillingSvg';
import CalendarSvg from '../common/Svg/SideMenu/CalendarSvg';
import CallsSvg from '../common/Svg/SideMenu/CallsSvg';
import CampaignSvg from '../common/Svg/SideMenu/CampaignSvg';
import ContentManagementSvg from '../common/Svg/SideMenu/ContentManagementSvg';
import DashboardSvg from '../common/Svg/SideMenu/DashboardSvg';
import EmployerSvg from '../common/Svg/SideMenu/EmployerSvg';
import FormSvg from '../common/Svg/SideMenu/FormSvg';
import HomeSvg from '../common/Svg/SideMenu/HomeSvg';
import LeadSvg from '../common/Svg/SideMenu/LeadSvg';
import LogsSvg from '../common/Svg/SideMenu/LogsSvg';
import MembershipSvg from '../common/Svg/SideMenu/MembershipSvg';
import MessagesSvg from '../common/Svg/SideMenu/MessagesSvg';
import OpportunitySvg from '../common/Svg/SideMenu/OpportunitySvg';
import PatientsSvg from '../common/Svg/SideMenu/PatientsSvg';
import PracticeSvg from '../common/Svg/SideMenu/Practice';
import ProductSvg from '../common/Svg/SideMenu/ProductSvg';
import ReportSvg from '../common/Svg/SideMenu/ReportSvg';
import ServicesSvg from '../common/Svg/SideMenu/ServicesSvg';
import SettingSvg from '../common/Svg/SideMenu/SettingSvg';
import TasksSvg from '../common/Svg/SideMenu/TasksSvg';
import TeamManagmentSvg from '../common/Svg/SideMenu/TeamManagmentSvg';
import VirtualCallCenter from '../common/Svg/SideMenu/VirtualCallCenter';
import WebWidgetsSvg from '../common/Svg/SideMenu/WebWidgetsSvg';
import { CHANNEL_TYPE_CODE } from '../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import { USER_PERMISSIONS_CONST } from '../RightSideContainer/UserAccess/UserPermissionConst';
import {
  MAIN_MENU_CODES,
  PROFILE_MENU_CONST,
  SIDE_MENU_CONST,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE,
} from './SideBarConst';
import {
  ISideBarLocation,
  ISideBarMenu,
  ISideBarSubMenu,
} from './SideBarInterface';
import CalenderNavSvg from '../common/Svg/ConversationAllSvg/CalenderNavSvg';
import {usePermissions} from '../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';

export const getIconsFromMainMenu = (
  subMenuCode: string,
  selectedMenuCode: string,
  isCollapsed?: boolean,
  isPrimary?: boolean
): JSX.Element => {
  let iconElem = <></>;
  switch (subMenuCode) {
    case MAIN_MENU_CODES.PRACTICE:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PracticeSvg />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CONSUMER:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <Consumer isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.HOME:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <HomeSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.DASHBOARD:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <DashboardSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CALENDAR:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <CalenderNavSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CALENDARCHECK:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <Appointment isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.TASKS:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <TasksSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.EMPLOYER:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <AntIcon
            size={20}
            name="switcher"
            color={
              selectedMenuCode === MAIN_MENU_CODES.ANALYTICS
                ? // ? Colors.primary['500']
                  '#fff'
                : isPrimary
                ? Colors.primary['300']
                : '#fff'
            }
          />

          {false && (
            <EmployerSvg
              isSelected={selectedMenuCode === MAIN_MENU_CODES.EMPLOYER}
            />
          )}
        </View>
      );
      break;
    case MAIN_MENU_CODES.REPORTS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <ReportSvg
            isSelected={selectedMenuCode === MAIN_MENU_CODES.REPORTS}
          />
        </View>
      );
      break;
    case MAIN_MENU_CODES.OPPORTUNITY:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <OpportunitySvg
            isSelected={selectedMenuCode === MAIN_MENU_CODES.OPPORTUNITY}
          />
        </View>
      );
      break;
    case MAIN_MENU_CODES.ANALYTICS:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          {/* <AntIcon size={20} name="barschart" color={'#fff'} /> */}
          <AnalyticsSvg />
        </View>
      );
      break;
    case MAIN_MENU_CODES.INBOX:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <MessagesSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.LINK:
      iconElem = (
        <Feather
          size={20}
          style={{paddingRight: 6}}
          name="external-link"
          color={
            selectedMenuCode === MAIN_MENU_CODES.LINK
              ? Colors.primary['500']
              : isPrimary
              ? Colors.primary['300']
              : '#fff'
          }
        />
      );
      break;
    case MAIN_MENU_CODES.PRODUCTS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <ProductSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CARE_JOURNEY:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <CareJourney isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.LEAD:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <LeadSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.MEMBERS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PatientsSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.MEMBERSHIP:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PatientsSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.BILLING:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <BillingSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.MEMBERSHIPS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <MembershipSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.PAYMENTS:
      iconElem = (
        <Feather
          size={20}
          style={{paddingRight: isCollapsed ? 0 : 6}}
          name="credit-card"
          color={
            selectedMenuCode === MAIN_MENU_CODES.PAYMENTS
              ? Colors.primary['500']
              : isPrimary
              ? Colors.primary['300']
              : '#fff'
          }
        />
      );
      break;
    case MAIN_MENU_CODES.DESIGNER:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <AdminSvg isPrimary={isPrimary} />
        </View>
      );
      break;

    case MAIN_MENU_CODES.SERVICES:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <ServicesSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.SETTING:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <SettingSvg isPrimary={isPrimary} width={24} height={24} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.GROUPS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <MaterialCommunityIcons
            size={20}
            name="account-group-outline"
            color={
              selectedMenuCode === MAIN_MENU_CODES.ANALYTICS
                ? // ? Colors.primary['500']
                  '#fff'
                : isPrimary
                ? Colors.primary['300']
                : '#fff'
            }
          />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CAMPAIGN_MANAGER:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <CampaignSvg />
        </View>
      );

      break;
    case MAIN_MENU_CODES.TAGS_MANAGEMENT:
      iconElem = (
        <Icon
          size={20}
          style={{paddingRight: isCollapsed ? 0 : 6}}
          name="tago"
          color={
            selectedMenuCode === MAIN_MENU_CODES.TAGS_MANAGEMENT
              ? Colors.primary['500']
              : isPrimary
              ? Colors.primary['300']
              : '#fff'
          }
        />
      );
      break;
    case MAIN_MENU_CODES.LOGS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <LogsSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.COMMUNICATION:
      iconElem = (
        <Feather
        size={20}
        style={{paddingRight: isCollapsed ? 0 : 6}}
        name="message-square"
        color={'#fff'}
      />
      );
      break;
    case MAIN_MENU_CODES.FORMS:
      iconElem = (
        <View display={'inline'} w={5} ml={1} mr={isCollapsed ? 0 : 2}>
          <FormSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.FORMS2:
      iconElem = (
        <View display={'inline'} w={5} ml={1} mr={isCollapsed ? 0 : 2}>
          <CalendarSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.INTEGRATION_CONFIG:
      iconElem = (
        <Icon
          size={20}
          style={{paddingRight: 6}}
          name="scan1"
          color={
            selectedMenuCode === MAIN_MENU_CODES.INTEGRATION_CONFIG
              ? '#fff'
              : isPrimary
              ? Colors.primary['300']
              : '#fff'
          }
        />
      );
      break;
    case MAIN_MENU_CODES.AUTOMATION:
      iconElem = (
        <Icon
          size={20}
          style={{paddingRight: isCollapsed ? 0 : 6}}
          name="sharealt"
          color={
            selectedMenuCode === MAIN_MENU_CODES.AUTOMATION
              ? '#fff'
              : isPrimary
              ? Colors.primary['300']
              : '#fff'
          }
        />
      );
      break;
    case MAIN_MENU_CODES.SCHEDULE:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <CalendarSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.VIRTUAL_CALL_CENTER:
      if (ACCESS_PROVISION_VIEW.IVR_VIEW) {
        iconElem = (
          <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
            <Feather
              name={'headphones'}
              size={20}
              color={
                selectedMenuCode === MAIN_MENU_CODES.AUTOMATION
                  ? '#fff'
                  : isPrimary
                  ? Colors.primary['300']
                  : '#fff'
              }
            />
            {false && (
              <VirtualCallCenter
                isSelected={selectedMenuCode === MAIN_MENU_CODES.SCHEDULE}
              />
            )}
          </View>
        );
      }
      break;
    case MAIN_MENU_CODES.ACCOUNT_SETTINGS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <TeamManagmentSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.TASK_MANAGEMENT:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          <TasksSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CONTENT_MANAGEMENT:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <ContentManagementSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CONTENT_MANAGEMENT_EMAIL_TEMPLATES:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <BrandedSvg
            isSelected={
              selectedMenuCode ===
              MAIN_MENU_CODES.CONTENT_MANAGEMENT_EMAIL_TEMPLATES
            }
            isPrimary={isPrimary}
          />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CONTENT_MANAGEMENT_PATIENT_EDUCATION:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <EducationContent
            isSelected={
              selectedMenuCode ===
              MAIN_MENU_CODES.CONTENT_MANAGEMENT_PATIENT_EDUCATION
            }
            isPrimary={isPrimary}
          />
        </View>
      );
      break;
    case MAIN_MENU_CODES.ADMIN_PATIENTS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PatientsSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.PATIENT:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PatientsSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.USER_ACCESS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PatientsSvg />
        </View>
      );
      break;
    case MAIN_MENU_CODES.WEB_WIDGETS:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <WebWidgetsSvg isPrimary={isPrimary} />
        </View>
      );
      break;
    case MAIN_MENU_CODES.PROFILE:
      iconElem = (
        <View display={'inline'} w={5} mr={isCollapsed ? 0 : 2}>
          <PatientOutlineIcon defaultColor='#fff'/>
        </View>
      );
      break;
    case MAIN_MENU_CODES.ANALYTICS_EMPLOYER:
      iconElem = (
        <View display={'inline'} mr={isCollapsed ? 0 : 2}>
          {/* <AntIcon size={20} name="barschart" color={'#fff'} /> */}
          <AnalyticsSvg
            isSelected={selectedMenuCode === MAIN_MENU_CODES.ANALYTICS}
          />
        </View>
      );
      break;
    case MAIN_MENU_CODES.CALL_LOGS:
        iconElem = (
          <View display={'inline'} mr={isCollapsed ? 0 : 2}>
            <CallsSvg
              isSelected={selectedMenuCode === MAIN_MENU_CODES.CALL_LOGS}
            />
          </View>
        );
      break;
  }
  return iconElem;
};

export const getSideBarObjectFromLocationPath = (
  currentPath: string,
  sideBarMenus: ISideBarMenu[]
): ISideBarLocation => {
  const menuObj: ISideBarLocation = {
    mainMenu: '',
    subMenuCode: '',
  };
  if (currentPath) {
    currentPath = currentPath.substring(1);
  }

  if (currentPath?.includes('admin')) {
    menuObj.mainMenu = MAIN_MENU_CODES.SETTING;
    return menuObj;
  }

  const menuConfig = sideBarMenus?.find((item: ISideBarMenu) => {
    const subMenuCondition = item?.subMenu?.find((subItem: ISideBarSubMenu) => {
      const check =
        currentPath.includes(subItem.path) ||
        subItem.settingPath == currentPath;
      return check;
    });
    const condition =
      item?.path == currentPath ||
      item?.settingPath == currentPath ||
      subMenuCondition;
    return condition;
  });

  if (menuConfig) {
    menuObj.mainMenu = menuConfig?.menuCode;
  }

  return menuObj;
};

export const getMenuConfig = (
  userUUID: string,
  path?: string,
  showIntegrationTab?: boolean,
  config?: {
    enableCareProgram: boolean,
  }
): {
  sideBarMenus: ISideBarMenu[];
  defaultMenu: string;
} => {
  const currentUserRoles = getCurrentUserRole();
  const isEmployerOwner = currentUserRoles.some((role) => {
    return USER_ROLE_CODES.EMPLOYER === role.code;
  });
  const isMasterAccountFlag = isMasterAccount();
  const isChildAccountFlag = isChildAccount();
  const {check} = usePermissions()
  let SIDE_BAR_USER_TAB = USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER;
  if (isEmployerOwner) {
    SIDE_BAR_USER_TAB = USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.EMPLOYER;
  }
  if (isMasterAccountFlag) {
    SIDE_BAR_USER_TAB = USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.MASTER_ACCOUNT;
  }
  // const SIDE_BAR_ADMIN_PANEL_TAB =
  //   USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.ADMIN;
  let sideBarMenuDefaultCodes = SIDE_BAR_USER_TAB;
  const defaultMenu = SIDE_BAR_USER_TAB[0];
  const defaultConfig: any = {
    sideBarMenus: [],
    defaultMenu: defaultMenu,
  };
  const disableAnalytics = isAccountConfigEnabled(CONFIG_CODES.DISABLE_ANALYTICS);
  const pathList = (path || '').split('/');
  if (
    (pathList.length >= 1 && pathList[1] === 'admin') ||
    (window.name == 'DESIGNER' &&
      (window.location + '').indexOf('admin') != -1 &&
      !path)
  ) {
    sideBarMenuDefaultCodes = SIDE_BAR_USER_TAB;
    defaultConfig.defaultMenu = SIDE_BAR_USER_TAB[0];
  }

  const sideBarMenus: ISideBarMenu[] = [];
  sideBarMenuDefaultCodes.forEach((menuCode: string) => {
    const isAlloowed =  check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, menuCode).hasAccess
    if (!isAlloowed) {
      return 
    }
    const singleMenuObj: any =
      SIDE_MENU_CONST.find((singleMenuObj) => {
        return singleMenuObj.menuCode === menuCode;
      }) || {};
     if (singleMenuObj?.menuCode == MAIN_MENU_CODES.CONSUMER) {
      config?.enableCareProgram
      singleMenuObj.path = config?.enableCareProgram ? 'members/myPatient' : 'members/patient';
     }
      if (
      singleMenuObj &&
      Object.keys(singleMenuObj) &&
      Object.keys(singleMenuObj).length
    ) {
      sideBarMenus.push(singleMenuObj);
    }
  });

  sideBarMenus.forEach((data: any, index: number)=> {
    if(data.menuCode === MAIN_MENU_CODES.INBOX){
      LocalStorage.getItem('communicationLastSelectedTab')
      .then((response) => {
        if (response) {
          const responseData = JSON.parse(response);
          const defaultPath = getDefaultPathForInbox(responseData?.[userUUID] ?? '');
          if (defaultPath) {
            data.path = defaultPath;
          }
        }
      })
    }
  })

  defaultConfig.sideBarMenus = sideBarMenus;
  defaultConfig.defaultMenu = sideBarMenus[0].menuCode;
  if (disableAnalytics || isChildAccountFlag) {
    defaultConfig.sideBarMenus = defaultConfig.sideBarMenus.filter(
      (singleMenuObj: ISideBarMenu) => {
        return singleMenuObj.menuCode !== MAIN_MENU_CODES.ANALYTICS;
      }
    );
  }

  if (USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW && currentUserRoles.length && isEmployerOwner) {
      defaultConfig.sideBarMenus.push(PROFILE_MENU_CONST)
      defaultConfig.sideBarMenus = defaultConfig.sideBarMenus.filter((item: any) => item.menuCode !== MAIN_MENU_CODES.ANALYTICS);

      defaultConfig.sideBarMenus.forEach((data: any, index: number)=> {
        if(data.menuCode === MAIN_MENU_CODES.ANALYTICS_EMPLOYER){
          data.menuName = 'dashboard'
        }else if(data.menuCode === MAIN_MENU_CODES.CONSUMER){
          data.menuName = 'employees'

          LocalStorage.getItem('user')
          .then((result) => {
            const userData = JSON.parse(result);
            const updateUserData: any = userData;

            data.path = `employees/${updateUserData.data.employerId}`
          });
        }
      })
  }else if(currentUserRoles.length && !isEmployerOwner){
    defaultConfig.sideBarMenus = defaultConfig.sideBarMenus.filter((item: any) => item.menuCode !== MAIN_MENU_CODES.ANALYTICS_EMPLOYER);
    defaultConfig.defaultMenu = defaultConfig.sideBarMenus[0].menuCode;
  }
  return defaultConfig;
};

export const getDefaultPathForInbox = (selectedTab: string) => {
  switch (selectedTab) {
    case CHANNEL_TYPE_CODE.CHANNEL_ALL:
      return 'inbox/allConversations';
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
      return 'inbox/conversations';
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
      return 'inbox/sms';
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
      return 'inbox/email';
    case CHANNEL_TYPE_CODE.CHANNEL_INTERNAL:
      return 'inbox/internal';
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
      return 'inbox/fax'

    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME:
      return 'inbox/assigned_me';
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER:
      return 'inbox/assigned_other';
    case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED:
      return 'inbox/unassigned';
    case CHANNEL_TYPE_CODE.CHANNEL_MENTION:
      return 'inbox/mentions';
    case CHANNEL_TYPE_CODE.CHANNEL_STARRED:
      return 'inbox/starrted';
    case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED:
      return 'inbox/archived';
    case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS:
      return 'inbox/missed';
    default:
      return '';
  }
};

export const getDefaultPath = (userUUID: string, path?: string): string => {
  const menuConfig = getMenuConfig(userUUID, path);
  const mainMenu = menuConfig.sideBarMenus.filter(
    (menu) => menu.menuCode === menuConfig.defaultMenu
  );

  const location = window.location + '';
  const locationSubPath = location.split('#/');
  if (locationSubPath.length > 1 && locationSubPath[1].length && !path) {
    return locationSubPath[1];
  } else if (mainMenu && mainMenu.length > 0) {
    if (mainMenu[0].path) {
      return mainMenu[0].path;
    }
  }
  return '/';
};
