import React from 'react';
import { Svg, Path } from 'react-native-svg';

function AttachmentsBackArrowSvg() {
  return (
    <Svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.667 10H3.333m0 0l5-5m-5 5l5 5"
      ></Path>
    </Svg>
  );
}

export default AttachmentsBackArrowSvg;
