import {Colors} from '../../../styles/Colors';
import {Collapse, Select as AntSelect} from 'antd';
import React from 'react';
import {useIntl} from 'react-intl';
import {
  Box,
  HStack,
  Text,
  View,
  Skeleton,
  VStack,
  Pressable,
  Input,
  TextArea,
  Tooltip,
  Spinner,
  useToast
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Radar} from '@ant-design/charts';
const {Option} = AntSelect;
const {Panel} = Collapse;
import CommonService from '../../../services/CommonService/CommonService';
import HealthMapSvg from '../../common/Svg/HealthMapSvg';
import RadialBarChart from '@ant-design/plots/es/components/radial-bar';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import UnitySvg from '../../common/Svg/UnitySvg';
import {LLMSkeletonLoader} from './LLMSkeletonLoader';
import './Styles.css';
import { CommonDataContext } from '../../../context/CommonDataContext';
import NoDataFoundWithCustomIcon from '../../common/NoDataFound/NoDataFoundWithCustomIcon';
import InfoCircleSvg from '../../common/Svg/InfoCircleSvg';
import LikeSvg from '../../common/Svg/LikeSvg';
import DislikeSvg from '../../common/Svg/DislikeSvg';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { getUserUUID } from '../../../utils/commonUtils';
import CheckSvg from '../../common/Svg/CheckSvg';
import CloseSvg from '../../common/Svg/CloseSvg';
import { formatTimestamp } from '../../../utils/llmUtils';
import { FeedbackReasonItem, FeedbackReasonState } from './interfaces';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { TestIdentifiers, testID } from '../../../testUtils';

export const LLMMap = (props: {contactUUID ?: string ,widgetName : string}) => {
  const toast = useToast();
  const [stateData, setStateData] = useState<{
    radarChartdata: any[];
    barChartData: any[];
    loading: boolean;
    isExecuted: boolean;
  }>({
    radarChartdata: [],
    barChartData: [],
    loading: true,
    isExecuted: true,
  });
  const [resultID, setResultID] = useState('');
  const userUUID = getUserUUID();
  const [lastSync, setLastSync] = useState<string>('');
  const [reasonCode, setReasonsCode] = useState<FeedbackReasonState>({
    all: [],
    thumpsUp: [],
    thumpsDown: [],
  });

  const [showFeedbackModal, setShowFeedbackModal] = useState<{
    loading :  boolean,
     show : boolean
  }>({
    loading : false,
    show : false
  });

  const [feedback, setFeedback] = useState<{
    score: any;
    comment: string;
  }>({
    score: undefined,
    comment: '',
  });

  const [selectedTag, setSelectedTag] = useState<{
    index: string | number;
    selected: boolean;
    shortCode: string;
    textInputReason: string;
  }>({
    index: -1,
    selected: false,
    shortCode: '',
    textInputReason: '',
  });
  useEffect(() => {
    getFeedBackStatus(resultID, userUUID);
  }, [resultID,reasonCode.all]);
  const [isActive, setIsActive] = useState(false);
  const [changeChartFlag, setChangeChartFlag] = useState(true);
  const [showAnimation , setShowAnimation] = useState(true)
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const lastSyncMessage = `Last Synced: ${lastSync}`
  const chartsList = [
    {
      key: 1,
      label: 'Radar',
    },
    {
      key: 2,
      label: 'Bar',
    },
  ];

  const fetchData = (isCached: boolean) => {
    llmflowService
      .post(`/v4/summary/healthmap`, {
        contactUuid: props?.contactUUID,
        isCatched: isCached,
      })
      .then((result: any) => {
        setLastSync(formatTimestamp(result?.data?.syncDate))
        setIsActive(false);
        const filteredData = Array.isArray(result.data.list)
          ? result.data.list.map((item: any) => ({
              key: item.key,
              value: item.score == -1 ? 0 : (item.score * 100) / item.max,
              reason: item?.reason || ''
            }))
          : [];
        setStateData({
          radarChartdata:
            Array.isArray(result?.data?.list) && result?.data?.list?.length > 0
              ? result?.data?.list.map((item:any)=>{
                  item.value = item.score
                return item
              })
              : [],
          barChartData:
            Array.isArray(result?.data?.list) && result?.data?.list?.length > 0
              ? filteredData
              : [],
          loading: false,
          isExecuted: true,
        });
        setResultID(result?.data?.id);
        setShowFeedbackModal( (prev)=>({...prev, show:false}));
        getFeedBackStatus(result?.data?.id, userUUID);
        setSelectedTag({
          index: -1,
          selected: false,
          shortCode: '',
          textInputReason: '',
        });
      })
      .catch((error: any) => {
        setIsActive(false);

        setStateData({
          radarChartdata: [],
          barChartData: [],
          loading: false,
          isExecuted: false,
        });
      });
  };

  const loaderTemeplateValues = [
    intl.formatMessage({id: 'analyzingHealth'}),
    intl.formatMessage({id: 'analyzingReports'}),
    intl.formatMessage({id: 'synthesizingLearnings'}),
    intl.formatMessage({id: 'creatingHealthMap'}),
  ];

  useEffect(() => {
    fetchData(true);
  }, []);

  useEffect(() => {
    setShowAnimation(false)
  }, [showFeedbackModal, feedback.score,selectedTag.index]);

  const BarChartConfig = {
    xField: 'key',
    yField: 'value',
    radius: 0.8,
    tooltip: {
      customContent: (title:any, items:any) => {
        return (
          <div style={{ padding: '10px'}}>
            {title && <strong>{title}</strong>}
            {items.map((item:any, index:any) => (
              <>
              {item?.value != -1 ? <div key={index} style={{ paddingTop: '5px', paddingBottom: '5px'}}>
                {'Score'}: {item?.value}
              </div> : <></>}
              <div key={index}>
                {'Inference'}: {item?.data?.reason || ''}
              </div> </>
            ))}
          </div>
        );
      },
    },
    scale: {
      y: {
        domain: [0, 200],
      },
    },
    innerRadius: 0.2,
    meta: {
      value: {
        max: 100,
      },
    },
  };

  const RadarChartConfig = {
    data: stateData?.radarChartdata,
    xField: 'key',
    yField: 'value',
    area: {
      style: {
        fillOpacity: 0.2,
      },
    },
    tooltip: {
      customContent: (title:any, items:any) => {
        return (
          <div style={{ padding: '10px'}}>
            {title && <strong>{title}</strong>}
            {items.map((item:any, index:any) => (
              <div key = {index}>
                <div key={index} >
                { item?.value != -1 ? <div key={index} style={{ paddingTop: '5px'}}>
                {'Score'}: {item?.value}
               </div> : <></>}</div>
               <div key={index} style={{ paddingTop: '5px'}}>
                {'Inference'}: {item?.data?.reason || ''}
               </div>
              </div>
            ))}
          </div>
        );
      },
    },
    meta: {
      value: {alias: 'reason'},
    },
    yAxis: {
      min: -1,
      max: 5,
      offset: 0,
      label: {
        formatter: (val: number) => {
          return val == -1 ? '' : val;
        },
      },
    },
    xAxis: {
      label: {
        offset: 10,
        position: 'center',
        style: {
          fontSize: 10,
          textAlign: 'center',
        },
      },
    },

    axis: {
      x: {
        title: false,
        grid: true,
      },
      y: {
        gridAreaFill: 'rgba(0, 0, 0, 0.04)',
        label: false,
        title: false,
      },
    },
  };

  const fetchReasonCodes = () => {
    llmflowService
      .get(`/v1/feedback/reason_code`)
      .then((result: any) => {
        const filteredReasonCodeThumbDown = result?.data?.reason_codes?.filter(
          (reason: any) => !reason.reaction_code || reason.reaction_code == null
        );
        const filteredReasonCodeThumbup = result?.data?.reason_codes?.filter(
          (reason: any) => reason.reaction_code || reason.reaction_code == null
        );
        setReasonsCode({
          all: result?.data?.reason_codes,
          thumpsUp: filteredReasonCodeThumbup,
          thumpsDown: filteredReasonCodeThumbDown,
        });
      })
      .catch((error: any) => {

      });
  };

  const postFeedback = () => {
    llmflowService
      .post(`/v1/feedback`, {
        user_id: userUUID,
        result_id: resultID,
        score: feedback.score,
        comment:
          selectedTag.shortCode === 'Other'
            ? selectedTag.textInputReason
            : selectedTag.shortCode,
      })
      .then((result: any) => {
        setShowFeedbackModal({loading : false,show :false});
      })
      .catch((error: any) => {
        setShowFeedbackModal({loading : false,show :false});
        showToast(
          toast,
          'Unable to save the Feedback',
          ToastType.error,
          1000
        );
        getFeedBackStatus(resultID,userUUID)
      });
  };

  const getFeedBackStatus = (resultID: any, userID: any) => {
    resultID  &&
    llmflowService
      .get(`v1/feedback/status`, {
        params: {
          result_id: resultID,
          user_id: userID,
        },
      })
      .then((result: any) => {
        setFeedback({
          comment: result?.data?.data?.comment,
          score: result?.data?.data?.score,
        });
        if (result?.data?.data?.score !== -1 && reasonCode.all.length) {
          const isShortCodePresent = reasonCode.all.some(
            (item) => item.short_code === result?.data?.data?.comment
          );
          if (isShortCodePresent) {
            setSelectedTag((prev) => ({
              ...prev,
              index: result?.data?.data?.comment,
              selected: true,
              shortCode: result?.data?.data?.comment,
              textInputReason: '',
            }));
          } else if (result?.data?.data?.comment) {
              setSelectedTag((prev) => ({
                ...prev,
                index: 'Other',
                selected: true,
                shortCode: 'Other',
                textInputReason: result?.data?.data?.comment,
              }));
          }
        } else {
          setSelectedTag({
            index: -1,
            selected: false,
            shortCode: '',
            textInputReason: '',
          });
        }
      })
  };

  const renderTags = (reasonCode: FeedbackReasonItem, index: string) => {
    const isSelected = selectedTag.index === index && selectedTag.selected;

    return (
          <Pressable
            key={reasonCode.id}
            onPress={() => {
              setSelectedTag((prev) => {
                return {
                  ...prev,
                  index: index,
                  selected: !isSelected,
                  shortCode: !isSelected? reasonCode.short_code :'',
                };
              });
            }}
          >
            <View
              style={{
                marginTop: 4,
                borderRadius: 4,
                paddingHorizontal: 4,
                backgroundColor: isSelected
                  ? Colors.FoldPixel.PRIMARY100
                  : Colors.FoldPixel.GRAY50,
                marginRight: 4,
                borderWidth: 0.1,
                borderColor: Colors.FoldPixel.GRAY50,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  color: isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500,
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                {reasonCode.short_code}
              </Text>
              {isSelected && (
                <Pressable
                  onPress={() => {
                    setSelectedTag((prev) => {
                      return {
                        ...prev,
                        previndex:undefined,
                        selected: false,
                        shortCode: '',
                      };
                    });
                  }}
                >
                  <AntIcon color={isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500} name='close' />
                </Pressable>
              )}
            </View>
          </Pressable>
    );
  };

  useEffect(() => {
    fetchReasonCodes();
  }, []);


  return (
    <Box
      mb={2}
      shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
      borderRadius={8}
      borderColor={Colors.Custom.BorderColor}
      borderWidth={1}
    >
      <Collapse
        className='llm-collapse'
        defaultActiveKey={[1]}
        collapsible='header'
        style={{
          borderWidth: 0.5,
          ...(!isSidecarContext && {
            borderColor: Colors.Custom.ContainerBGColor,
            // backgroundImage:
            //   'linear-gradient(237.73deg, #D2EBEF 0.27%, #F5E4FE 102.44%)',
          }),
        }}
        expandIconPosition='end'
      >
        <Panel
          extra={
            <div className='llm-panel'>
              <View minWidth={100}>
                <AntSelect
                  placement='bottomRight'
                  removeIcon
                  loading={stateData.loading}
                  defaultValue={'Radar'}
                  allowClear={false}
                  disabled={stateData.loading}
                  onChange={(res: any) => {
                    setShowAnimation(true);
                    setChangeChartFlag(res !== 2);
                    if (stateData.barChartData.length === 0) {
                      fetchData(true);
                      setStateData({
                        barChartData: [],
                        radarChartdata: [],
                        loading: true,
                        isExecuted: true,
                      });
                    }
                  }}
                  {...testID(TestIdentifiers.llm.healthMap.healthMapDropDown)}
                >
                  {chartsList.map((item: any) => {
                    return (
                      <Option
                        value={item.key}
                        key={item.key}
                        title={item.label}
                      >
                        {item.label}
                      </Option>
                    );
                  })}
                </AntSelect>
              </View>
            </div>
          }
          header={
            <HStack alignItems={'center'} space={3}>
             <span {...testID(TestIdentifiers.llm.healthMap.healthMapIcon)}><HealthMapSvg /></span>
              <VStack>
                <Text
                  color={Colors.Custom.ProfileViewTextColor}
                  fontSize={20}
                  fontWeight={600}
                  {...testID(TestIdentifiers.llm.healthMap.healthMapTitle)}
                >
                  {props.widgetName ? props.widgetName : intl.formatMessage({id: 'healthMap'})}
                </Text>
              </VStack>
            </HStack>
          }
          key={'1'}
        >
          <div className='llm-content'>
            <View padding={1}>
              {stateData?.isExecuted ? (
                stateData?.loading ? (
                  <View padding={4} height={200} justifyContent={'center'}>
                    <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'llmmap' + 'skeleton'}/>
                  </View>
                ) : stateData?.barChartData?.length > 0 ? (
                  changeChartFlag ? (
                    <View
                      width={isSidecarContext ? 220 : 350}
                      height={isSidecarContext ? 220 : 350}
                      justifyItems={'center'}
                      alignSelf={'center'}
                    >
                      <Radar {...RadarChartConfig} animation={false} />
                    </View>
                  ) : (
                    <View
                      padding={2}
                      width={350}
                      height={350}
                      justifyItems={'center'}
                      alignSelf={'center'}
                    >
                      <RadialBarChart
                        animation={showAnimation}
                        data={stateData?.barChartData}
                        {...BarChartConfig}
                      />
                    </View>
                  )
                ) : (
                  <View padding={3}>
                    <NoDataFoundWithCustomIcon
                      icon={<InfoCircleSvg></InfoCircleSvg>}
                      displayString={intl.formatMessage({id: 'noDataFound'})}
                    ></NoDataFoundWithCustomIcon>
                  </View>
                )
              ) : (
                <Text
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  color={Colors.Custom.Gray500}
                  padding={4}
                >
                  {intl.formatMessage({id: 'errorInFetchingData'})}
                </Text>
              )}
            </View>
            <HStack
              space={2}
              style={{
                flexDirection: 'row',
                paddingHorizontal: 12,
                justifyContent: 'space-between',
                paddingVertical: 5,
                borderBottomLeftRadius: 9,
                borderBottomRightRadius: 9,
              }}
            >
              <View
                style={{flexDirection: 'row', alignItems: 'center', flex: 1}}
                {...testID(TestIdentifiers.llm.healthMap.healthMapPoweredByUnity)}
              >
                <UnitySvg iconKey={'UnitySvg' + 'UnitySvg'}/>
                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={600}
                  noOfLines={1}
                  style={{
                    // @ts-ignore: Unreachable code error
                    backgroundImage:
                      'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textAlign: 'center',
                  }}
                >
                  {intl.formatMessage({id: 'poweredByUnity'})}
                </Text>

                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={550}
                  noOfLines={1}
                  style={{
                    color: Colors.FoldPixel.GRAY200,
                    fontFamily: 'Courier',
                    textAlign: 'center',
                  }}
                >
                  {intl.formatMessage({id: 'beta'})}
                </Text>
              </View>
              {!stateData.loading &&
                stateData?.isExecuted &&
                stateData?.barChartData?.length > 0 && (
                  <>
                    <Tooltip
                      label={intl.formatMessage({id: 'dislike'})}
                      placement='top'
                      hasArrow
                    >
                      <Pressable
                        flexDirection={'row'}
                        alignItems={'center'}
                        onPress={() => {
                          setFeedback({score: 0, comment: ''});
                          setShowFeedbackModal((prev) => ({
                            ...prev,
                            show: true,
                          }));
                        }}
                        {...testID(TestIdentifiers.llm.healthMap.healthMapThumpsDown)}
                      >
                        <DislikeSvg
                          isActive={feedback.score === 0 ? true : false}
                          keyy={'alerttable'}
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip
                      label={intl.formatMessage({id: 'like'})}
                      placement='top'
                      hasArrow
                    >
                      <Pressable
                        flexDirection={'row'}
                        alignItems={'center'}
                        onPress={() => {
                          setFeedback({score: 1, comment: ''});
                          setShowFeedbackModal((prev) => ({
                            ...prev,
                            show: true,
                          }));
                        }}
                        {...testID(TestIdentifiers.llm.healthMap.healthMapThumbsUp)}
                      >
                        <LikeSvg
                          isActive={feedback.score === 1 ? true : false}
                          keyy={'alerttable'}
                        />
                      </Pressable>
                    </Tooltip>
                  </>
                )}
              <Tooltip
                isDisabled={!lastSync}
                label={lastSyncMessage}
                placement='top right'
                hasArrow
              >
                <Pressable
                  flexDirection={'row'}
                  alignItems={'center'}
                  onPress={() => {
                    setIsActive(true);
                    fetchData(false);
                    setStateData({
                      barChartData: [],
                      radarChartdata: [],
                      loading: true,
                      isExecuted: true,
                    });
                  }}
                  {...testID(TestIdentifiers.llm.healthMap.healthMapHardRefresh)}
                >
                <HardRefreshIconSvg isActive={isActive} iconKey={'llmmap'}/>
              </Pressable>
              </Tooltip>
            </HStack>
            {!stateData.loading && showFeedbackModal.show && reasonCode.all && (
              <VStack
                borderColor={Colors.Custom.Gray200}
                borderWidth={'1'}
                borderRadius={12}
                flex={1}
                margin={3}
              >
                <HStack padding={2} flex={1}>
                  <Text
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={14.4}
                    color={Colors.FoldPixel.GRAY300}
                    flex={2}
                  >
                    {feedback.score === 0 ? 'Help us to Improve.' : null}
                    {feedback.score === 1 ? 'Thanks! Any more feedback?' : null}
                  </Text>
                  <Pressable
                    marginLeft={2}
                    onPress={() => {
                      postFeedback();
                      setShowFeedbackModal((prev) => ({
                        ...prev,
                        loading: true,
                      }));
                    }}
                    flex={0.1}
                  >
                    {showFeedbackModal.loading ? (
                      <Spinner></Spinner>
                    ) : (
                      <CheckSvg></CheckSvg>
                    )}
                  </Pressable>
                  <Pressable
                    marginLeft={2}
                    flex={0.1}
                    onPress={() => {
                      setShowFeedbackModal((prev) => ({...prev, show: false}));
                      setFeedback({score: undefined, comment: ''});
                      getFeedBackStatus(resultID, userUUID);
                    }}
                  >
                    <CloseSvg></CloseSvg>
                  </Pressable>
                </HStack>
                <View
                  flexWrap={'wrap'}
                  flexDir={'row'}
                  paddingX={2}
                  paddingBottom={selectedTag.shortCode === 'Other' ? 0 : 2}
                >
                  <>
                    {feedback.score == 0 &&
                      reasonCode.thumpsDown.map((name: FeedbackReasonItem, index: number) =>
                        renderTags(name, name.short_code)
                      )}
                    {feedback.score == 1 &&
                      reasonCode.thumpsUp.map((name: FeedbackReasonItem, index: number) =>
                        renderTags(name, name.short_code)
                      )}
                  </>
                </View>
                {selectedTag.shortCode === 'Other' && selectedTag.index && (
                  <HStack flex={1} padding={2}>
                    <TextArea
                      value={selectedTag.textInputReason}
                      _focus={{
                        borderColor: Colors.Custom.Gray300,
                      }}
                      borderColor={Colors.Custom.Gray300}
                      placeholderTextColor={Colors.Custom.Gray300}
                      width={'100%'}
                      numberOfLines={5}
                      onChangeText={(input) => {
                        setSelectedTag((prev: any) => {
                          return {
                            ...prev,
                            textInputReason: input,
                          };
                        });
                      }}
                      placeholder='Provide Additional Feedback'
                    />
                  </HStack>
                )}
              </VStack>
            )}
          </div>
        </Panel>
      </Collapse>
    </Box>
  );
};
