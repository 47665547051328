const ActionButtonSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83333 14C9.83333 14.9205 9.08714 15.6667 8.16667 15.6667C7.24619 15.6667 6.5 14.9205 6.5 14C6.5 13.0796 7.24619 12.3334 8.16667 12.3334C9.08714 12.3334 9.83333 13.0796 9.83333 14Z"
        stroke="#6F7A90"
        stroke-linecap="round"
      />
      <path
        d="M15.6667 14C15.6667 14.9205 14.9205 15.6667 14 15.6667C13.0795 15.6667 12.3333 14.9205 12.3333 14C12.3333 13.0796 13.0795 12.3334 14 12.3334C14.9205 12.3334 15.6667 13.0796 15.6667 14Z"
        stroke="#6F7A90"
        stroke-linecap="round"
      />
      <path
        d="M21.5 14C21.5 14.9205 20.7538 15.6667 19.8333 15.6667C18.9129 15.6667 18.1667 14.9205 18.1667 14C18.1667 13.0796 18.9129 12.3334 19.8333 12.3334C20.7538 12.3334 21.5 13.0796 21.5 14Z"
        stroke="#6F7A90"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default ActionButtonSvg;
