import {Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {Dimensions, View} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {getInboxColumns} from './EFaxInboxTableColumn';
import {IEFaxInbox} from '../Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import {useLazyQuery} from '@apollo/client';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {getAccountId, getUserId} from '../../../../utils/commonUtils';
import {Skeleton} from 'native-base';
import {IEFaxInboxTable} from './interface';

const EFaxInboxTable = (props: IEFaxInboxTable) => {
  const {height} = Dimensions.get('window');
  const finalHeight = height - 210;
  const accountId = getAccountId();
  const userId = getUserId();

  const [state, setState] = useState({
    loading: true,
    inboxList: [] as IEFaxInbox[],
  });

  useEffect(() => {
    if (!props?.enableUpdateDrawer) {
      getInboxList();
    }
  }, [props?.enableUpdateDrawer]);

  const [getInboxes] = useLazyQuery(
    InboxQueries.GetUserEFaxInboxListWithInboxMembers,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getInboxList = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await getInboxes({
      variables: {
        accountId: accountId,
        userId: userId,
      },
    });
    setState((prev) => {
      return {
        ...prev,
        loading: false,
        inboxList: response?.data?.inboxes,
      };
    });
  };

  return (
    <>
      {state.loading ? (
        <View style={{padding: 6}}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <Table
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          style={{minHeight: finalHeight}}
          scroll={{x: 800, y: finalHeight}}
          dataSource={state.inboxList}
          columns={getInboxColumns(props.setAction)}
          pagination={false}
          onRow={(data) => {
            return {
              onClick: () => props.setAction(COMMON_ACTION_CODES.EDIT, data),
            };
          }}
        />
      )}
    </>
  );
};

export default EFaxInboxTable;
