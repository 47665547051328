import {LazyQueryExecFunction, OperationVariables} from '@apollo/client';
import {AxiosInstance} from 'axios';
import {AUTO_RESPONSE_TYPES} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {USER_ROLE_CODES} from '../../../constants/MlovConst';
import BaseService from '../../../services/CommonService/BaseService';
import {ITimezone} from '../../../services/Location/interfaces';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {createAvailabilityCalendarGroupData} from '../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityHelper';
import {
  IAvailabilityCalendarData,
  IPracticeAvailability,
} from '../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import {
  AutoReplyViewState,
  AutoReplyViewUtilsArgs,
  IAutoResponseConfig,
  IPracticeAvailabilityConfig,
} from './interfaces';

export class AutoReplyViewUtils {
  private state: AutoReplyViewState;
  private accountUuid: string;
  private practiceScheduleAvailabilityTypeId: string;
  private userUuid: string;
  private baseService: AxiosInstance;
  private toast: any;
  private setState: React.Dispatch<React.SetStateAction<AutoReplyViewState>>;
  private getTimezoneOfAccountAndLocations: LazyQueryExecFunction<
    any,
    OperationVariables
  >;
  private getAvailabilitiesAPI: LazyQueryExecFunction<any, OperationVariables>;

  showLoading: boolean;

  constructor(args: AutoReplyViewUtilsArgs) {
    this.state = args.state;
    this.accountUuid = args.accountUuid;
    this.userUuid = args.userUuid;
    this.practiceScheduleAvailabilityTypeId =
      args.practiceScheduleAvailabilityTypeId;
    this.showLoading = this.state.loading;
    this.baseService = args.baseService;
    this.toast = args.toast;
    this.setState = args.setState;
    this.getTimezoneOfAccountAndLocations =
      args.getTimezoneOfAccountAndLocations;
    this.getAvailabilitiesAPI = args.getAvailabilitiesAPI;

    // binding
    this.onMount = this.onMount.bind(this);
    this.getAccountLocations = this.getAccountLocations.bind(this);
    this.getPracticeAvailabilityWhereCondition =
      this.getPracticeAvailabilityWhereCondition.bind(this);
    this.setLocationAndAvailabilityData =
      this.setLocationAndAvailabilityData.bind(this);
    this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
    this.isLocationSelected = this.isLocationSelected.bind(this);
    this.isReplyAdded = this.isReplyAdded.bind(this);
    this.getPostRequestObject = this.getPostRequestObject.bind(this);
    this.getPracticeAvailabilityConfigByCalendarData =
      this.getPracticeAvailabilityConfigByCalendarData.bind(this);
    this.getAutoResponseConfig = this.getAutoResponseConfig.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getAutoResponsesFromApi = this.getAutoResponsesFromApi.bind(this);
    this.getIdByReplyType = this.getIdByReplyType.bind(this);
  }

  onMount() {
    this.getAccountLocations();
  }

  async onSubmit() {
    this.setState((prev) => {
      return {
        ...prev,
        isSubmitting: true,
      };
    });
    try {
      const body = this.getPostRequestObject();
      const response = await this.baseService.post(
        '/crm-nest/conversation/auto-response',
        {data: body}
      );
      showToast(this.toast, 'Responses saved successfully', ToastType.success);
      this.setState((prev) => {
        return {
          ...prev,
          isSubmitting: false,
        };
      });
    } catch (error) {

      showToast(
        this.toast,
        'Error in saving responses. Please try again later',
        ToastType.success
      );
      this.setState((prev) => {
        return {
          ...prev,
          isSubmitting: false,
        };
      });
    }
  }

  private async getAccountLocations() {
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const accountLocationResponse =
        await this.getTimezoneOfAccountAndLocations({
          variables: {
            tenantId: this.accountUuid,
            roleCode: USER_ROLE_CODES.EMPLOYER,
          },
        });
      const availabilitiesResponse = await this.getAvailabilitiesAPI({
        variables: {
          whereCondition: this.getPracticeAvailabilityWhereCondition(),
        },
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      });
      const autoResponses = await this.getAutoResponsesFromApi();
      this.setLocationAndAvailabilityData({
        accountLocationResponse,
        availabilitiesResponse,
        autoResponses,
      });
    } catch (error) {
      this.setState((prev) => {
        return {
          ...prev,
          loading: false,
          accountLocations: [],
        };
      });
    }
  }

  private async getAutoResponsesFromApi(): Promise<IAutoResponseConfig[]> {
    try {
      const response = await this.baseService.get(
        '/crm-nest/conversation/auto-response'
      );
      return response.data;
    } catch (error) {

      return [];
    }
  }

  private getPracticeAvailabilityWhereCondition() {
    let whereCondition: any = {};
    whereCondition = {
      isDeleted: {_eq: false},
      typeId: {_eq: this.practiceScheduleAvailabilityTypeId},
    };

    whereCondition.userId = {_is_null: true};
    return whereCondition;
  }

  private setLocationAndAvailabilityData(args: {
    accountLocationResponse: any;
    availabilitiesResponse: any;
    autoResponses: IAutoResponseConfig[];
  }) {
    const {accountLocationResponse, availabilitiesResponse, autoResponses} =
      args;
    const accountLocations =
      accountLocationResponse?.data?.accountLocations || [];
    const timezones = accountLocationResponse?.data?.timezones || [];
    const accountUsers = accountLocationResponse?.data?.users || [];
    const availabilityCalendarData: IAvailabilityCalendarData[] =
      createAvailabilityCalendarGroupData({
        practiceAvailabilities: availabilitiesResponse?.data?.schedules || [],
        timezones,
        accountLocations,
        accountUsers,
        isUserSchedule: false,
      });
    const smsResponse = autoResponses.find(
      (item) => item.replyType === AUTO_RESPONSE_TYPES.SMS
    );
    const emailResponse = autoResponses.find(
      (item) => item.replyType == AUTO_RESPONSE_TYPES.EMAIL
    );
    const widgetResponse = autoResponses.find(
      (item) => item.replyType === AUTO_RESPONSE_TYPES.WIDGET
    );
    const chatResponse = autoResponses.find(
      (item) => item.replyType === AUTO_RESPONSE_TYPES.CHAT
    );
    this.setState((prev) => {
      return {
        ...prev,
        emailResponseId: emailResponse?.id || '',
        smsResponseId: smsResponse?.id || '',
        webWidgetResponseId: widgetResponse?.id || '',
        loading: false,
        accountLocations: accountLocations,
        availabilityCalendarData: availabilityCalendarData,
        timezones: timezones,
        emailReply: emailResponse?.replyText || '',
        smsReply: smsResponse?.replyText || '',
        selectedAccountLocationForSMS: smsResponse?.accountLocationUuid || '',
        selectedAccountLocationForEmail:
          emailResponse?.accountLocationUuid || '',
        selectedAccountLocationForWidget:
          widgetResponse?.accountLocationUuid || '',
        webWidgetReply: widgetResponse?.replyText || '',
        chatReply: chatResponse?.replyText || '',
        chatResponseId: chatResponse?.id || '',
        selectedAccountLocationForChat: chatResponse?.accountLocationUuid || '',
      };
    });
  }

  isSubmitDisabled() {
    return !(this.isLocationSelected() && this.isReplyAdded());
  }

  private isLocationSelected() {
    const {
      selectedAccountLocationForEmail,
      selectedAccountLocationForSMS,
      selectedAccountLocationForWidget,
      selectedAccountLocationForChat,
    } = this.state;
    return (
      selectedAccountLocationForEmail ||
      selectedAccountLocationForSMS ||
      selectedAccountLocationForWidget ||
      selectedAccountLocationForChat
    );
  }

  private isReplyAdded() {
    const {emailReply, smsReply, webWidgetReply, chatReply} = this.state;
    return emailReply || smsReply || webWidgetReply || chatReply;
  }

  private getPostRequestObject(): IAutoResponseConfig[] {
    const result: IAutoResponseConfig[] = [];
    if (this.state.selectedAccountLocationForSMS) {
      result.push(
        this.getAutoResponseConfig({
          replyText: this.state.smsReply || '',
          replyType: AUTO_RESPONSE_TYPES.SMS,
          selectedAccountLocation: this.state.selectedAccountLocationForSMS,
        })
      );
    }
    if (this.state.selectedAccountLocationForEmail) {
      result.push(
        this.getAutoResponseConfig({
          replyText: this.state.emailReply || '',
          replyType: AUTO_RESPONSE_TYPES.EMAIL,
          selectedAccountLocation: this.state.selectedAccountLocationForEmail,
        })
      );
    }
    if (this.state.selectedAccountLocationForWidget) {
      result.push(
        this.getAutoResponseConfig({
          replyText: this.state.webWidgetReply || '',
          replyType: AUTO_RESPONSE_TYPES.WIDGET,
          selectedAccountLocation: this.state.selectedAccountLocationForWidget,
        })
      );
    }

    if (this.state.selectedAccountLocationForChat) {
      result.push(
        this.getAutoResponseConfig({
          replyText: this.state.chatReply || '',
          replyType: AUTO_RESPONSE_TYPES.CHAT,
          selectedAccountLocation: this.state.selectedAccountLocationForChat,
        })
      );
    }
    return result;
  }

  private getAutoResponseConfig(args: {
    selectedAccountLocation: string;
    replyType: string;
    replyText: string;
  }): IAutoResponseConfig {
    const {replyText, replyType, selectedAccountLocation} = args;
    const calendarData = this.state.availabilityCalendarData.find((item) => {
      return item.availabilities.find(
        (a) => a.locationId === selectedAccountLocation
      );
    });
    return {
      id: this.getIdByReplyType({replyType}),
      accountId: this.accountUuid,
      accountLocationUuid: selectedAccountLocation,
      replyText: replyText,
      replyType: replyType,
      userId: this.userUuid,
      practiceAvailabilityConfig:
        this.getPracticeAvailabilityConfigByCalendarData({
          availabilityData: calendarData,
        }),
    };
  }

  private getPracticeAvailabilityConfigByCalendarData(args: {
    availabilityData?: IAvailabilityCalendarData;
  }): IPracticeAvailabilityConfig[] {
    const result: IPracticeAvailabilityConfig[] = [];
    const {availabilityData} = args;
    availabilityData?.availabilities.forEach((item) => {
      result.push({
        beginDate: item.beginDate || '',
        daysOfWeek: item.daysOfWeek || '',
        endDate: item.endDate || '',
        endTime: item.endTime || '',
        id: item.id || '',
        inboxId: item.inboxId || '',
        isVirtualLocation: item.isVirtualLocation || false,
        isDeleted: item.isDeleted,
        locationId: item.locationId || '',
        startTime: item.startTime || '',
        timezoneId: item.timezoneId || '',
        timezone: item?.timezone || ({} as ITimezone),
        typeId: item.typeId || '',
      });
    });
    return result;
  }

  private getIdByReplyType(args: {replyType: string}): string {
    const {replyType} = args;
    if (replyType === AUTO_RESPONSE_TYPES.SMS) {
      return this.state.smsResponseId;
    }
    if (replyType === AUTO_RESPONSE_TYPES.EMAIL) {
      return this.state.emailResponseId;
    }
    if (replyType === AUTO_RESPONSE_TYPES.WIDGET) {
      return this.state.webWidgetResponseId;
    }
    if (replyType === AUTO_RESPONSE_TYPES.CHAT) {
      return this.state.chatResponseId;
    }
    return '';
  }
}

export const updateBusinessHours = async (args: {
  data: IPracticeAvailability[];
}) => {
  const {data} = args;
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/conversation/update-business-hours';
  try {
    const response = await baseService.post(url, {data});
  } catch (error) {

  }
};

export const updateBusinessHoursInCloudTelephony = async (args: {
  data: IPracticeAvailability[];
}) => {
  const {data} = args;
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'cloud-telephony/business-hours/';
  try {
    const response = await baseService.post(url, {data});
  } catch (error) {

  }
};

export function getPracticeAvailabilityConfigByCalendarData(args: {
  availabilityData?: IAvailabilityCalendarData;
}): IPracticeAvailabilityConfig[] {
  const result: IPracticeAvailabilityConfig[] = [];
  const {availabilityData} = args;
  availabilityData?.availabilities.forEach((item) => {
    result.push({
      beginDate: item.beginDate || '',
      daysOfWeek: item.daysOfWeek || '',
      endDate: item.endDate || '',
      endTime: item.endTime || '',
      id: item.id || '',
      inboxId: item.inboxId || '',
      isVirtualLocation: item.isVirtualLocation || false,
      isDeleted: item.isDeleted,
      locationId: item.locationId || '',
      startTime: item.startTime || '',
      timezoneId: item.timezoneId || '',
      timezone: item?.timezone || ({} as ITimezone),
      typeId: item.typeId || '',
    });
  });
  return result;
}

export const getAutoResponsesFromApi = async (autoResponseData: {
  inboxId: number;
  assigneeUuid?: string
}): Promise<IAutoResponseConfig | undefined> => {
  const {inboxId, assigneeUuid} = autoResponseData;
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.get(
      '/crm-nest/conversation/inbox-auto-response',
      {
        params: {
          inboxId,
          assigneeUuid
        },
      }
    );
    return response.data;
  } catch (error) {

  }
};

export const addOrUpdateAutoResponse = async (autoResponseData: {
  body: IAutoResponseConfig;
}) => {
  const {body} = autoResponseData;
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/conversation/inbox-auto-response';
  return baseService.post(url, {data: body});
};

export const addTimeZonesToAvailabilities = (data: {
  availabilities: IPracticeAvailabilityConfig[];
  timezones: ITimezone[];
}) => {
  const {availabilities, timezones} = data;
  availabilities.forEach((item) => {
    const timezoneId = item.timezoneId;
    const timezone = timezones.find((item) => item.uuid === timezoneId);
    if (timezone) {
      item.timezone = timezone;
    }
  });
  return availabilities;
};

export const getUserAutoResponse = async (autoResponseData: {
  inboxId: number;
  userUuid: string;
}): Promise<IAutoResponseConfig | null> => {
  const {inboxId, userUuid} = autoResponseData;
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.get(
      '/crm-nest/conversation/user-auto-response',
      {
        params: {
          inboxId,
          userUuid,
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

