import React from 'react';
import {Text} from 'react-native';
import {useIntl} from 'react-intl';
import {Modal} from 'antd';
import FoldButtonV2, {
  ButtonType,
} from '../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {Pressable} from 'react-native';
import {Colors} from '../../../styles';
import CrossIcon from '../../common/Svg/CrossIcon';
import Stack from '../../common/LayoutComponents/Stack';

export interface SidecarConfirmationPopupButton {
  btnText: string;
  onClick: () => void;
  isDeleting?: boolean;
  buttonType: ButtonType;
}

interface ISidecarInProgressNotePopup {
  buttonActions: SidecarConfirmationPopupButton[];
  header: string;
  message: string;
  onClose: () => void;
}

const SidecarConfirmationPopup = (props: ISidecarInProgressNotePopup) => {
  const {buttonActions} = props;
  const intl = useIntl();

  return (
    <Modal
      className="confirmation-dialog"
      open
      closeIcon={null}
      closable={false}
      footer={null}
    >
      <Stack direction="column">
        <Stack
          direction="row"
          style={{justifyContent: 'space-between', alignItems: 'center'}}
        >
          <Text
            style={{
              marginBottom: 4,
              fontSize: 20,
              fontWeight: '600',
              color: Colors.FoldPixel.GRAY400,
            }}
          >
            {props.header}
          </Text>
          <Pressable onPress={props.onClose}>
            <CrossIcon size={36} />
          </Pressable>
        </Stack>
        <Text style={{fontSize: 16, color: Colors.FoldPixel.GRAY300, width: '80%'}}>
          {props.message}
        </Text>
        <Stack direction="row" style={{marginTop: 20}}>
          {buttonActions.map((button: any, index: number) => {
            return (
              <FoldButtonV2
                height={30}
                flex={1}
                key={index + button.btnText}
                buttonType={button.buttonType}
                label={intl.formatMessage({id: button.btnText})}
                onPress={button.onClick}
              />
            );
          })}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default SidecarConfirmationPopup;
