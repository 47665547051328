
import {useLazyQuery} from '@apollo/client';
import {DatePicker, DatePickerProps} from 'antd';
import {RangePickerProps} from 'antd/lib/date-picker';
import moment from 'moment';
import {Center, Skeleton, Text, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {ViewStyle} from 'react-native';
import {useLocation} from 'react-router-dom';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {EmployerDashboard} from '../../../../services';
import {getSectionDataByResourceCode} from '../../../../services/Analytics';
import {Colors} from '../../../../styles/Colors';
import {getFormattedDate, getMomentObj, isCurrentDateInFutureComparedToOther} from '../../../../utils/DateUtils';
import {getMlovListByCategory} from '../../../../utils/mlovUtils';
import EmployerDropDown from '../../Analytics/CommonComponents/EmployerDropDown';
import {ALL_MEMBERS} from '../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import {graphColorList} from '../../Analytics/EmployerAnalytics/graphColors';
import {FormResponseDetails} from '../../Analytics/EmployerAnalytics/interface';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import {Employer} from '../../BrazilCodeMR/pages/analytics/AnalyticsController';
import {FORM_ANALYTICS_KEYS} from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import {checkNoteCategoryForm} from '../FormBuilderWidget/FormList/FormListUtils';
import {IForm, IFormCategory} from '../interfaces';
import FormCountCard from './FormCountCard';
import FormLineChart from './FormLineChart';
import FormPieChart from './FormPieChart';
import ScoreGroupCard from './ScoreGroupCard';
import {styles} from './style';
import {IPageSection, ISectionDisplayConfig} from './types';

interface ISectionHeader {
    heading?: string;
    subHeading?: string;
    shouldShowDateFilter?: boolean;
    employerDropDown?: boolean;
    pageSections?: IPageSection
}

const FormAnalytics = (props: {
    dateRange?: Record<string, string>,
    setDateRange?: (range: Record<string, string>) => void,
    onEmployerChange: (employer: Employer) => void;
    employerData: Employer
    selectedForm?: IForm
    locationIds?: string[]
}) => {
    const {onEmployerChange, employerData} = props;
    const [GetDefaultPage] = useLazyQuery(
        EmployerDashboard.GetDefaultPageForFormAnalytics
    );
    const context = useContext(CommonDataContext);
    const ehrCapabilities = context.ehrCapabilities || [];

    const [pages, setPages] = useState<any[]>([]);
    const intl = useIntl();

    const [resourceData, setResourceData] = useState<Record<string, any>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<Record<string, string>>({
        from: props?.dateRange?.["from"] || moment().subtract(1, 'month').startOf('month').format('yyyy-MM-DDTHH:mm:ss'),
        to: props?.dateRange?.["to"] || moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss'),
    });
    const [resourceCodeList, setResourceCodeList] = useState<string[]>([])
    const location = useLocation();

    const isFormNoteAnalytics = checkNoteCategoryForm(
      (props?.selectedForm?.formCategory as IFormCategory) ||
        location?.state?.selectedForm?.formCategory
    );

    useEffect(() => {
      fetchDateByResourceCode(
        resourceCodeList,
        employerData?.id === ALL_MEMBERS ? undefined : employerData?.id
      );
    }, [dateRange]);


    useEffect(() => {
        setLoading(true);
        GetDefaultPage({
            variables: {
                dashboardType: isFormNoteAnalytics ? 'FORM_NOTE_DEFAULT_DASHBOARD' : 'FORM_DEFAULT_DASHBOARD'
            },
            fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        }).then((resp) => {
            if (resp?.data?.pages?.[0]) {
                setPages(resp?.data?.pages)
                const resourceCodeListRaw = fetchResourceCode(resp?.data?.pages?.[0]?.pageSections, []);

                setResourceCodeList(resourceCodeListRaw)

                fetchDateByResourceCode(
                    resourceCodeListRaw,
                    employerData?.id === ALL_MEMBERS ? undefined : employerData?.id
                );

            }
        })
    }, []);


    const sectionTypes = getMlovListByCategory(
        MLOV_CATEGORY.SectionType,
    )



    const fetchResourceCode = (pageSections: IPageSection[], resourceObj: string[]): string[] => {
        pageSections?.forEach((pageSection: IPageSection) => {
            const sectionType = sectionTypes.find((type) => type.id == pageSection.type);

            if (sectionType?.code == "HEADER" && pageSection?.childSections?.length) {
                fetchResourceCode(pageSection.childSections, resourceObj);
            } else {
                if (pageSection?.resource?.code) {
                    resourceObj.push(pageSection?.resource?.code);
                }

            }
        })
        return resourceObj;
    }

    const fetchDateByResourceCode = async (resourceList: string[], employerId?: string) => {
        if (!resourceList.length) {
            setLoading(false);
        } else {
            !loading && setLoading(true);
            const resourceDataToReturn: Record<string, any> = {};
            for (let i = 0; i < resourceList.length; i++) {
                const resp = await getSectionDataByResourceCode(
                    resourceList[i],
                    {
                        "resourceCode": resourceList[i],
                        "isAccountDashboard": false,
                        "appliedFilter": [
                            {
                                "filterCode": "DATE_RANGE",
                                "filterValue": {
                                    "fromDate": dateRange.from,
                                    "toDate": dateRange.to,
                                }
                            }, {
                                "filterCode": "FORM_ID",
                                "filterValue": {
                                    "value": location?.state?.selectedForm?.id
                                }
                            },
                            {
                                filterCode: 'LOCATION',
                                filterValue: props?.locationIds && props?.locationIds?.length > 0 ? props?.locationIds : undefined,
                            }
                        ],
                        ...(employerId ? { employerId: employerId} : ''),
                        }
                )
                resourceDataToReturn[resourceList[i]] = resp.data;
                setResourceData({ ...resourceDataToReturn });
            }
            setLoading(false);
        }
    }


    const computeStyles = (sectionDisplayConfig?: ISectionDisplayConfig) => {
        const styles = [];
        if (sectionDisplayConfig?.childSections) {
            styles.push({
                ...sectionDisplayConfig?.childSections
            })
        }
        if (sectionDisplayConfig?.section) {
            styles.push({
                ...sectionDisplayConfig?.section
            })
        }

        return styles;
    }

    const getPieData = (pieResp: FormResponseDetails) => {
        const pieData = Object.keys(pieResp)
          .filter(
            (pieType) => {
                const filterTotalAndCompleteCount =
                  pieType !== FORM_ANALYTICS_KEYS.COMPLETION_RATE &&
                  pieType !== FORM_ANALYTICS_KEYS.TOTAL_FORM_LOG_COUNT;

                if (isFormNoteAnalytics) {
                  return (
                    filterTotalAndCompleteCount && pieType !== FORM_ANALYTICS_KEYS.PENDING_FORM_LOG_COUNT
                  );
                }

                return filterTotalAndCompleteCount;
            }
          )
          .map((pieChartData: string) => {
            const pieChartObj = {
              type: pieChartData,
              value: pieResp[
                pieChartData as keyof FormResponseDetails
              ] as number,
            };
            if (pieChartData === FORM_ANALYTICS_KEYS.SUBMITTED_FORM_LOG_COUNT && isFormNoteAnalytics) {
              pieChartObj.type = FORM_ANALYTICS_KEYS.SIGNED_OR_AMMENDED;
            }
            return pieChartObj;
          });

        return pieData;
    }

    const renderWidgets = (pageSections: IPageSection) => {
        if (loading && pageSections?.resource?.code && !resourceData[pageSections.resource.code]) {
            return (
                <Center margin={4} flex={1}>
                    <Skeleton.Text lines={5} />
                </Center>
            )
        }
        switch (pageSections?.resource?.code) {
            case 'FORM_TOTAL_QUESTIONS':
                return (
                    <FormCountCard header={pageSections.heading} count={resourceData[pageSections.resource.code]?.result?.questionCount || '0'} tooltipText={pageSections.additionalAttributes?.tooltip?.info}/>
                );
            case 'FORM_COMPLETION_RATE':
                // const totalFormLogCount = Math.floor(resourceData[pageSections.resource.code]?.result?.totalFormLogCount || 0);
                const completionRate = parseFloat(parseFloat((resourceData[pageSections.resource.code]?.result?.completionRate || 0)).toFixed(2));
                // const percent = totalFormLogCount && completionRate ? (completionRate * 100 / totalFormLogCount).toFixed(2) : 0;
                return (
                    <FormCountCard header={pageSections.heading} count={`${completionRate}%`} tooltipText={pageSections.additionalAttributes?.tooltip?.info}/>
                );
            case 'FORM_TOTAL_SEND_COUNT':
                return (
                    <FormCountCard header={pageSections.heading} count={resourceData[pageSections.resource.code]?.result?.totalFormLogCount || '0'} countSuffix='times' tooltipText={pageSections.additionalAttributes?.tooltip?.info}/>

                );
            case 'FORM_RESPONSES':

                const pieResp = resourceData[pageSections.resource.code]?.result;
                if (!pieResp) {
                    return null;
                }

                const pieData = getPieData(pieResp);

                return (
                    <View style={styles.pieChartContainer}>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-start'
                        }}>
                            <View style={{
                                flexDirection: 'column'
                            }}>
                                <Text fontWeight={600} fontSize={18}>
                                    {pageSections.heading}
                                </Text>
                                <Text color={Colors.Custom.IATextColor} fontWeight={500} fontSize={16}>
                                    {pageSections.subHeading}
                                </Text>
                            </View>

                        </View>
                        <View style={{ flexDirection: 'row',alignItems:'center',justifyContent:'center' }}>
                            <FormPieChart data={pieData} height={pageSections?.sectionDisplayConfig?.widgetStyle?.height} width={pageSections?.sectionDisplayConfig?.widgetStyle?.height} hideLegend={true} />
                            {
                               pieData.find((item) => item.value != 0) ? <View style={{
                                flexDirection: 'column',
                                justifyContent:'center'
                            }}>
                                {
                                    pieData?.map((data, index) => {
                                        return (
                                            <View style={{
                                                flexDirection:'row',
                                                alignItems:'center',
                                                marginBottom:10,
                                                marginLeft:15
                                            }}>
                                                <View style={{
                                                    width: 10,
                                                    height: 10,
                                                    borderRadius: 10,
                                                    marginRight:10,
                                                    marginLeft:10,
                                                    backgroundColor: graphColorList[index]
                                                }}>
                                                </View>
                                                <View style={{
                                                    flexDirection: 'column'
                                                }}>
                                                    <Text fontWeight={200} style={{
                                                        fontSize: 12
                                                    }} color={Colors.Custom.Gray700} >
                                                        {intl.formatMessage({id:data.type})}
                                                    </Text>  <Text fontWeight={600} style={{
                                                        fontSize: 16
                                                    }}>
                                                        {data.value}
                                                    </Text>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>:null
                            }

                        </View>
                    </View>
                );
            case 'AVG_FORM_SCORE':
                const lineChartResp = resourceData[pageSections.resource.code]?.result;
                let lineChartData: { type: string, value: number, month: string }[] = [];
                if (lineChartResp && lineChartResp?.length) {
                    lineChartData = lineChartResp?.map((lineChart: any) => {
                        return ({
                            "type": "Average Rating",
                            "value": lineChart?.avgScore || 0,
                            "month": moment(lineChart?.month).format('MMM yy'),
                        })
                    })
                }
                return (
                    <View style={styles.lineChartContainer}>
                        <Text fontWeight={600} fontSize={18}>
                            {pageSections.heading}
                        </Text>
                        <Text color={Colors.Custom.IATextColor} size={'smSemiBold'} fontSize={14}>
                            {`${moment(dateRange.from).format('MMM yyyy')} - ${moment(dateRange.to).format('MMM yyyy')}`}
                        </Text>
                        <FormLineChart tooltipText="Average score" xLabel='Months' yLabel={pageSections.heading} data={lineChartData} height={pageSections?.sectionDisplayConfig?.widgetStyle?.height} />
                    </View>
                );
            case 'AVG_FORM_GROUP_SCORE':
                const avgFormGroupResp = resourceData[pageSections.resource.code]?.result;
                if (!avgFormGroupResp?.length) {
                    return <View> <NodataViewTypeOne viewCode="TEXT_ICON" /></View>
                }

                return <>{(avgFormGroupResp?.map((resp: any) => <ScoreGroupCard header={resp.groupName} leftHeader='Average Score' leftValue={parseFloat(parseFloat(resp.avgScore)?.toFixed(2))} rightHeader='Interpretation' rightValue={resp?.interpretation || '-'} />))}</>


        }
    }

    const renderHeader = (params: ISectionHeader): JSX.Element => {
        const {heading, subHeading, shouldShowDateFilter, employerDropDown} = params;
        if (heading || subHeading) {
            return (
                <View style={styles.headerContainer}>
                    <View>
                        {heading ? <Text style={{
                            marginRight: 6,
                        }} size={'smBold'} fontSize={18}>
                            {heading}
                        </Text> : null}
                        {subHeading ? <Text style={{
                            marginRight: 6,
                        }} size={'smBold'} fontSize={18}>
                            {subHeading}
                        </Text> : null}
                    </View>
                    <View style={{flexDirection: 'row'}}>
                    {employerDropDown && (
                    <EmployerDropDown
                      onEmployerChange={(employer: Employer) => {
                        fetchDateByResourceCode(
                          resourceCodeList,
                          employer?.id === ALL_MEMBERS
                            ? undefined
                            : employer?.id
                        );
                        onEmployerChange(employer);
                      }}
                      employerData={employerData}
                      customStyles={{marginRight: 12}}
                    /> )}
                        {shouldShowDateFilter ? <View
                            style={styles.datePickerContainer}
                        >
                            <DatePicker
                                clearIcon={false}
                                style={{
                                    borderWidth: 0
                                }}
                                suffixIcon={null}
                                format={
                                    DATE_FORMATS.ANALYTICS_DATE_PICKER
                                }
                                disabledDate={(date) => {
                                    return isCurrentDateInFutureComparedToOther(date?.toString(), dateRange?.to)
                                }}
                                onChange={(
                                    value: DatePickerProps['value'] | RangePickerProps['value'],
                                    dateString: string
                                ) => {
                                    const dateRangeValue = {
                                        from: value
                                          ? getFormattedDate(
                                              value?.toString(),
                                              DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                                            )
                                          : '',
                                        to: dateRange.to,
                                      };
                                    props?.setDateRange?.(dateRangeValue);

                                    setDateRange(dateRangeValue);

                                }}
                                picker="month"
                                value={getMomentObj(dateRange.from || '')}
                            />
                            <DatePicker
                                clearIcon={false}
                                style={{
                                    borderWidth: 0
                                }}
                                format={
                                  DATE_FORMATS.ANALYTICS_DATE_PICKER
                                }
                                disabledDate={(date) => {
                                    return isCurrentDateInFutureComparedToOther(dateRange?.from, date?.toString())
                                }}
                                onChange={(
                                    value: DatePickerProps['value'] | RangePickerProps['value'],
                                    dateString: string
                                ) => {
                                    const dateRangeValue = {
                                        to: value
                                          ? getFormattedDate(
                                              value?.toString(),
                                              DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                                            )
                                          : '',
                                        from: dateRange.from,
                                      };
                                    props?.setDateRange?.(dateRangeValue)
                                    setDateRange(dateRangeValue)
                                }}
                                picker="month"
                                value={getMomentObj(dateRange.to || '')}
                            />
                        </View> : null}
                    </View>
                </View>
            )
        }
    return <></>
    }

    const getRenderHeader = (params: ISectionHeader): JSX.Element => {
      const {
        heading,
        subHeading,
        shouldShowDateFilter,
        employerDropDown,
        pageSections,
      } = params;
      const renderHeaderObj: ISectionHeader = {
        heading: pageSections?.heading || heading || '',
        subHeading: pageSections?.subHeading || subHeading || '',
        shouldShowDateFilter: !!shouldShowDateFilter,
        employerDropDown: !!employerDropDown,
      };
      return renderHeader(renderHeaderObj);
    };

    const getRenderHeadersParams = () : ISectionHeader => {
      return isFormNoteAnalytics
        ? {
            heading: 'Note Insights',
            subHeading: '',
            shouldShowDateFilter: true,
            employerDropDown: true,
          }
        : {
            heading: 'Form Insights',
            subHeading: '',
            shouldShowDateFilter: true,
            employerDropDown: true,
          };
    };

    const renderSections = (pageSections: IPageSection[]) => {

        return (
            <>
                {
                    pageSections?.map((pageSection: IPageSection, index: number) => {
                        if (!pageSection?.isVisible) {
                            return null;
                        }
                        const sectionType = sectionTypes.find((type) => type.id == pageSection.type);

                        if (sectionType?.code == "HEADER") {
                            return (
                                <>
                                    {getRenderHeader({pageSections: pageSection})}
                                    <View style={computeStyles(pageSection.sectionDisplayConfig) as ViewStyle}>
                                        {pageSection.childSections ? renderSections(pageSection.childSections) : null}
                                    </View>
                                </>)
                        } else {
                            return (
                                <View overflowX={'scroll'} style={computeStyles(pageSection.sectionDisplayConfig) as ViewStyle}>
                                    {renderWidgets(pageSection)}
                                </View>
                            )
                        }

                    })
                }
            </>
        )

    }
    return (
      <>
        {pages?.length ? (
          <>
            {getRenderHeader(getRenderHeadersParams())}
            {renderSections(pages?.[0]?.pageSections as IPageSection[])}
          </>
        ) : (
          <Skeleton.Text lines={15} />
        )}
      </>
    );
}

export default FormAnalytics;
