import {View} from "react-native";
import {TELEPHONY_VIEW} from "../../../constants/TelephonyConst";
import {AddCallExtensionDrawer} from "./AddCallExtensionDrawer";
import {useEffect, useState} from "react";
import {CallExtensionNoDataView} from "./CallExtensionNoDataView";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {CallExtensionTable} from "./CallExtensionTable";
import {getFormattedCallExtensionList} from "./CallExtensionUtils";
import {deleteCallExtension, getCallExtensionList, updateCallExtensionStatus} from "./AddCallExtensionService";
import {ICustomToast} from "../Contacts/CustomField/interface";
import {useToast} from "../../Toast/ToastProvider";
import {CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from "../Contacts/CustomField/CustomFieldConst";
import {ToastType} from "../../../utils/commonViewUtils";
import {useIntl} from "react-intl";
import {ICallExtensionConfigurationTable, ICallExtensionConfigurationTableState, IExtensionList} from "./interface";
import {FHAlertDialog} from "../../common/FHAlertDialog";
import {BUTTON_TYPE} from "../../../constants";
import {useLazyQuery} from "@apollo/client";
import UserPracticeLocationQueries from "../../../services/Location/UserPracticeLocationQueries";
import {getAccountUUID, getAllowedUserAccountLocationUuids, getAllowedUserPracticeLocationUuids, getUserUUID} from "../../../utils/commonUtils";
import {getLocationUuidFromPracticeLocations} from "../../common/CustomUserSearch/CustomUserSearchUtils";
import {ExtensionDeleteConfirmationModal} from "./ExtensionDeleteConfirmationModal";
import {isAccountConfigEnabled} from "../../../utils/configUtils";
import {CONFIG_CODES} from "../../../constants/AccountConfigConst";
import { MAIN_MENU_CODES } from "../../SideMenuBar/SideBarConst";
import { USER_ACCESS_PERMISSION } from "../UserAccess/UserAccessPermission";

export const ExtensionConfigurationTable = (props: ICallExtensionConfigurationTable) => {
  const [stateData, setStateData] = useState<ICallExtensionConfigurationTableState>({
    loading: true,
    locationLoading: false,
    extensionList: [],
    total: 0,
    limit: 10,
    pageNo: 1,
    selectedCallExtension: {} as IExtensionList,
    showDeleteModal: false,
    locationUuids: [],
    userPracticeLocation: [],
  })
  const toast = useToast();
  const intl = useIntl();
  const currentUserUUID = getUserUUID();
  const accountUuid = getAccountUUID();
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }
  const [getUserPracticeLocation] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: currentUserUUID,
      },
    }
  );

  const [GetUsersByUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetUsersByUserPracticeLocations, {
      fetchPolicy: 'no-cache',
    }
  );

  const fetchCallExtensionList = async (searchString?: string) => {
    setStateData((prev)=> {
      return {
        ...prev,
        loading: true
      }
    })
    const params = {
      pageNo: stateData?.pageNo,
      pageSize: stateData?.limit,
      searchString: searchString,
    }
    try {
      const response = await getCallExtensionList(params);
      if (response?.data?.data?.length) {
        const callExtensions = response?.data?.data;
        const formattedData = getFormattedCallExtensionList(callExtensions);
        setStateData((prev)=> {
          return {
            ...prev,
            extensionList: formattedData,
            loading: false,
            total: response?.data?.count,
          }
        })
      } else {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false,
            extensionList: []
          }
        })
      }
    } catch (error: any) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const getUserLocation =  async () => {
    if (!isCommunicationLocationHandlingEnabled) {
      return;
    }
    try {
      setStateData((prev) => {
        return {
          ...prev,
          locationLoading: true,
        };
      });
      const userPracticeLocationsResp = await GetUsersByUserPracticeLocations({
        variables: {
          locationUuids: allowedUserAccountLocationUuids,
          accountUuid: accountUuid
        },
      });
      const usersWithMatchingLocations = userPracticeLocationsResp?.data?.userPracticeLocations?.map(
        (value: {uuid: string; userUuid: string}) => {
          return value?.userUuid;
        }
      );
      setStateData((prev) => {
        return {
          ...prev,
          locationUuids: allowedUserPracticeLocationUuids,
          userPracticeLocation: usersWithMatchingLocations,
          locationLoading: false
        }
      });
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          locationLoading: false
        }
      });
    }
  }

  const onExtensionUpdate = (callExtension: IExtensionList) => {
    if (callExtension?.id) {
      const updatedList = stateData?.extensionList?.map((item: any) => {
        return item?.id === callExtension?.id
        ? callExtension : item
      });
      setStateData((prev) => ({
        ...prev,
        extensionList: updatedList,
        loading: false,
      }));
      showToast({
        toastType: ToastType.success,
        message: intl.formatMessage({id: 'statusUpdatedSuccessfully'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  const onStatusChange = async (callExtension: IExtensionList) => {
    setStateData((prev) => ({
      ...prev,
      loading: true,
    }));
    const payload = {
      id: callExtension?.id,
      isActive: !callExtension?.isActive
    }
    try {
      const response = await updateCallExtensionStatus(payload);
      if (response?.data?.id) {
        const updatedList = stateData?.extensionList?.map((item: IExtensionList) =>
          item?.id === response?.data?.id
            ? {...item, isActive: response?.data?.isActive}
            : item
        );
        setStateData((prev) => ({
          ...prev,
          extensionList: updatedList,
          loading: false,
        }));
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'statusUpdatedSuccessfully'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
      } else {
        setStateData((prev) => ({
          ...prev,
          loading: false
        }));
      }
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        loading: false,
      }));
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const deleteExtension = async (extension: IExtensionList) => {
    if (extension?.id) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: true
        }
      })
      try {
        const params = {
          id: extension?.id
        }
        const response = await deleteCallExtension(params);
        if (response?.data?.id) {
          setStateData((prev)=> {
            return {
              ...prev,
              loading: false,
              showDeleteModal: false,
              selectedCallExtension: {} as IExtensionList
            }
          })
          fetchCallExtensionList();
          showToast({
            toastType: ToastType.success,
            message: intl.formatMessage({id: 'deletedSuccessfully'}),
            duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
            closeAllPrevToast: true,
          })
        }
      } catch (error: any) {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false,
            selectedCallExtension: {} as IExtensionList
          }
        })
        showToast({
          toastType: ToastType.error,
          message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
          closeAllPrevToast: true,
        })
      }
    }
  }

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedCallExtension: actionData
          }
        })
        return props?.onActionPerformed(COMMON_ACTION_CODES.OPEN);
      case COMMON_ACTION_CODES.CLOSE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedCallExtension: {} as IExtensionList
          }
        })
        return props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE);
      case COMMON_ACTION_CODES.SUCCESS:
        props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE);
        if (stateData?.selectedCallExtension?.id) {
          // onExtensionUpdate(actionData);
          fetchCallExtensionList();
        } else {
          fetchCallExtensionList();
        }
        setStateData((prev) => {
          return {
            ...prev,
            selectedCallExtension: {} as IExtensionList
          }
        })
        return;

      case COMMON_ACTION_CODES.DELETE:
        return setStateData((prev) => {
          return {
            ...prev,
            showDeleteModal:  true,
            selectedCallExtension: actionData
          }
        })
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        return onStatusChange(actionData);

      case COMMON_ACTION_CODES.CONFIRM:
        return deleteExtension(stateData?.selectedCallExtension);

      case COMMON_ACTION_CODES.CANCEL:
        return setStateData((prev) => {
          return {
            ...prev,
            showDeleteModal:  false,
            selectedCallExtension: {} as IExtensionList,
          }
        })

      default:
        return;
    }
  }

  const onPagination = (page: number, pageSize: number) => {
    const pageNumber = page;
    const pageLimit = pageSize;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        offSet: offset,
        pageNo: page,
        limit: pageLimit,
      }
    })
  };

  useEffect(() => {
    fetchCallExtensionList(props?.searchString);
    getUserLocation();
  }, [stateData?.pageNo, stateData?.limit, props?.searchString]);

  return (
    <>
      <View>
        {!stateData?.loading && stateData?.extensionList?.length === 0 && !props?.searchString ? (
          <View style={{
            height: 600,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CallExtensionNoDataView
              title={'createCallExtension'}
              buttonMessage={'createFirstCallExtension'}
              onCreateFirstCallExtension={()=> {
                props?.onActionPerformed(COMMON_ACTION_CODES.OPEN);
              }}
            />
          </View>) : <CallExtensionTable
            onPagination={onPagination}
            pageNo={stateData?.pageNo}
            pageSize={stateData?.limit}
            total={stateData?.total}
            data={stateData?.extensionList}
            onActionPerformed={onActionPerformed}
            loading={stateData?.loading}
          />
        }
      </View>
      {props?.selectedCode === TELEPHONY_VIEW.ADD_CONFIGURATION && (
        <AddCallExtensionDrawer
          onActionPerformed={onActionPerformed}
          selectedCallExtension={stateData?.selectedCallExtension}
          locationsUuid={stateData?.locationUuids}
          userPracticeLocation={stateData?.userPracticeLocation}
        />
      )}
      {stateData?.showDeleteModal && <ExtensionDeleteConfirmationModal
        onActionPerformed={onActionPerformed}
      />}
    </>
  )
}