import {View, Text} from 'react-native';
import React, {useContext, useEffect, useState} from 'react';
import {componentKeys} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getAccountUUID, getFoldAccessToken} from '../../../utils/commonUtils';
import {ILeftContainerProps} from './interfaces';
import {FormViewType} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {getPatientEHRUrl} from '../../../utils/capabilityUtils';
import {FHForm} from '../../RightSideContainer/Forms/FHFormio';
import useEHRCapabilities from '../../../screens/BusinessStudio/useEHRCapabilities';

const HomeMonitoring = (props: ILeftContainerProps) => {
  const {personData} = props;
  const contextData = useContext(CommonDataContext);
  const foldAccessToken = getFoldAccessToken();
  const accountUUID = getAccountUUID();
  const contactUUID = personData?.contactUUID;
  const patientId =
    props.personData?.patientId || props.personData?.patientUuid;
  const accountLocationUuid = props.personData?.accountLocationUuid || '';
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const accountConfigCapabilities = contextData.accountConfigCapabilities;
  const openInEhrUrl = getPatientEHRUrl(patientId || '', accountLocationUuid, '');

  // const {date: ccmDate, loading: ccmDateLoading} =
  // useLatestCareProgramEnrollmentDate({
  //   type: CARE_PROGRAM_TYPES.CCM,
  //   contactId: contactUUID || '',
  // });


  const [intakeOptions] = useState({
    foldAccessToken,
    patientId,
    accountUUID,
    contactId: contactUUID,
    backgroundSaveEnabled: true,
    componentView: FormViewType.compact,
    ehrCapabilities,
    accountLocationUuid,
    openInEhrUrl,
    accountConfigCapabilities,
    // ccmDate,
    userData: contextData.userData,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    userSettings: contextData.userSettings,
    
  });

  const [enabledComponents] = useState([{
    key: componentKeys.HOME_MONITORING,
    type: 'homeMonitoringView',
    label: 'Home Monitoring',
    hideLabel: true,
  }]);

  // useEffect(() => {
  //   setIntakeOptions(prev => ({
  //     ...prev,
  //     ccmDate,
  //   }));
  // }, [ccmDate]);

  // if (ccmDateLoading) {
  //   return <FormComponentLoader />;
  // }

  return (
    <View style={{
      marginTop: 12,
    }}>
      <FHForm optionData={intakeOptions} components={enabledComponents} />
    </View>
  );
};

export default HomeMonitoring;
