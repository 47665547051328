import axios from "axios";
import { RequestGroup } from "fhir/r4";
import { PAMISearchType } from "../../components/common/PAMISearch/PAMISearch";
import { OrderType } from "../../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants";
import { ReportCategory, getOrderType } from "../../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils";
import { INTEGRATION_BASE_URL } from "../../constants/BaseUrlConst";
import { FHIR_RESOURCE } from "../../constants/FhirConstant";
import { BASE_URL } from "../../constants/Configs";
import { getMomentObj } from "../../utils/DateUtils";
import LocalStorage from "../../utils/LocalStorage";
import { IAvailableEhr } from "../../components/PersonOmniView/MiddleContainer/interfaces";
import { gql } from "@apollo/client";
import { getSidecarLocationGroupId } from "../../sidecar/common/SideCardCommonUtils";

export enum ReportSource {
  healthGorilla = 'HealthGorilla',
  athena = 'athena'
}

export const getOrderingURL = async (
  type: OrderType,
  contactId: string | number,
  noteId: string,
  orderCallbackUrl: string,
  newHGFlow: boolean,
  callback: (response: any) => void,
  error: (error: any) => void,
  updatedPatientData?: any,
  orderId?: string,
) => {
  const body = {
    contactId,
    category: getOrderType(type),
    noteId,
    callbackUrl: orderCallbackUrl,
    hgFlow: newHGFlow,
    ...(orderId && { orderId: orderId }),
    ...(updatedPatientData && { patient: updatedPatientData }),
  };
  const headers = await getHeaders();
  axios
    .post(`${INTEGRATION_BASE_URL}/api/order/widget-url`, body, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};

export const getOrdersFromNote = async (
  type: OrderType,
  patientId: string,
  noteId: string,
  callback: (response: any) => void,
  error: (error: any) => void,
  locationId?: string,
) => {
  const headers = await getHeaders();
  let searchQueryString = `patient=${patientId}&category=${getOrderType(type)}&based-on=DocumentReference/${noteId}`;
  if (locationId) {
    searchQueryString += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    searchQueryString += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  axios
    .get(`${INTEGRATION_BASE_URL}/fhir/RequestGroup?${searchQueryString}`, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};

export const getRequestGroupByPatientId = async (
  patientId: string,
  locationId?: string
) => {
  const headers = await getHeaders();
  let searchQueryString = `patient=${patientId}`;
  if (locationId) {
    searchQueryString += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    searchQueryString += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios
    .get(`${INTEGRATION_BASE_URL}/fhir/RequestGroup?${searchQueryString}`, {
      headers,
    });
};


export const getDiagnosticReportByPatientId = async (
  patientUuid: string,
  locationId?: string,
  queryParams?: any,
  type?: string,
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/DiagnosticReport?type=${type || ReportCategory.LAB}&patient=${patientUuid}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers, ...(queryParams && { params: queryParams })});
};

export const getLabReports = async (
  patientUuid: string,
  locationId?: string,
  queryParams?: any
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Observation?category=laboratory&patient=${patientUuid}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers, ...(queryParams && { params: queryParams })});
};

export const getRelevantClinicalCodes = async (code: string, resourceType?: string, userId?: string, locationId?: string) => {
  let path = `${INTEGRATION_BASE_URL}/config/resource-configuration?code=${code}`;

   if (resourceType) {
     path += `&resource-type=${resourceType}`;
   }
   if (userId) {
     path += `&user-id=${userId}`;
   }
   if(locationId) {
    path += `&location=${locationId}`;
   } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }

  const headers = await getHeaders();
  return axios.get(path, {headers});
};

export const addOrUpdateRelevantClinicalCodes = async (
  body: {
    configCode: string;
    configValuePayload: any;
    resourceType: string;
    userId?: string;
    locationId?: string;
  },
  callback: (response: any) => void,
  error: (error: any) => void,
) => {
  const {locationId} = body;
  let path = `${INTEGRATION_BASE_URL}/config/resource-configuration${
    locationId ? `?location=${locationId}` : ``
  }`;

  if (!locationId) {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }

  const headers = await getHeaders();
  axios
    .post(path, body, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};


export const getMedicationRequestByPatientId = async (
  patientId: string,
  locationId?: string,
  useProxy?: boolean,
  additionalHeaders?: {[index: string]: any},
) => {
  const path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/fhir/MedicationRequest`
    : `${INTEGRATION_BASE_URL}/fhir/MedicationRequest`;
  const headers = await getHeaders();
  let searchQueryString = `patient=${patientId}`;
  if (locationId) {
    searchQueryString += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    searchQueryString += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios
    .get(`${path}?${searchQueryString}`, {
      headers: {...headers, ...additionalHeaders},
    });
};

export const populatePrescriptionForPatient = async (
  contactId: string
) => {
  const headers = await getHeaders();
  const body = {
    contactId
  };
  return axios
    .put(`${INTEGRATION_BASE_URL}/api/order/prescription/populate`, body, {
      headers,
    });
};

export const populatePrescriptionForNote = async (
  contactId: string,
  noteId: string,
  callback: (response: any) => void,
  error: (error: any) => void,
) => {
  const headers = await getHeaders();
  const body = {
    contactId,
    documentReferenceId: noteId
  };
  axios
    .put(`${INTEGRATION_BASE_URL}/api/order/prescription/populate`, body, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};

export const getDiagnosticReport = async (
  patientId: string,
  serviceRequests: string[], // format: ["ServiceRequest/1234", "ServiceRequest/4566"]
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  type?: OrderType,
  locationId?: string,
) => {
  // let path = `${INTEGRATION_BASE_URL}/fhir/DiagnosticReport?type=${getOrderType(type)}&patient=${patientId}`;
  let path = `${INTEGRATION_BASE_URL}/fhir/DiagnosticReport?patient=${patientId}`;
  if (type) {
    path += `&type=${getOrderType(type)}`;
  }
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  if (serviceRequests.length) {
    serviceRequests.forEach((value) => {
      path += `&based-on=${value}`;
    })
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const reportOrderSubmission = async (
  query: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
) => {

  const path = `${INTEGRATION_BASE_URL}/api/order/callback${query}`;
  const headers = await getHeaders({isPublic: true});
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

const getHeaders = async (options?: {
  isPublic: boolean;
}) => {
  const userDataString = await LocalStorage.getItem('user');
  const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
  const userData = JSON.parse(userDataString);
  const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
  const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headersObj: any = {
    ...(!options?.isPublic && {'access-token': responseAccessToken}),
    ...(!options?.isPublic && {fold_access_token: fold_access_token}),
    'account-uuid': accountUUID,
  };
  if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
    headersObj['form-widget-access-token'] = formWidgetAccessToken;
  }
  return headersObj;
};


export const updateMultipleDiagnosticReport = async (
  body: any,
  callback: (response: any) => void,
  error: (error: any) => void,
) => {
  const headers = await getHeaders();
  axios
    .put(`${INTEGRATION_BASE_URL}/fhir/DiagnosticReport/-1`, body, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};

export const getOrderingURLFromIntegration = async (
  body: any,
  callback: (response: any) => void,
  error: (error: any) => void,
) => {
  const headers = await getHeaders();
  axios
    .post(`${INTEGRATION_BASE_URL}/api/order/widget-url`, body, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};

export const downloadReport = async (
  documentUrl: string,
  source: ReportSource,
  returnPromise: boolean,
  callback?: (response: any) => void,
  error?: (error: any) => void,
  reportId?: string,
  ehrConfig?: IAvailableEhr,
  locationId?: string,
) => {
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headers: any = {
    'access-token': responseAccessToken,
    fold_access_token: fold_access_token,
    'account-uuid': accountUUID,
  };
  let url = `${INTEGRATION_BASE_URL}/api/order/download?source=${source}&reference=${encodeURIComponent(documentUrl)}`
  if (reportId && ehrConfig?.isElation) {
    url = `${INTEGRATION_BASE_URL}/api/order/download?reference=${encodeURIComponent(documentUrl)}&reportId=${reportId}`
  }
  if (locationId) {
    url += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const promise = axios.get(url,
    {
      headers,
      responseType: 'blob',
    }
  );
  if (returnPromise) {
    return promise;
  }
  promise
    .then((response: any) => callback?.(response))
    .catch((err: any) => { error?.(err) });
};

export const getMedicationStatementAndPatientReportedOrder = async (
  patientId: string,
  locationId?: string,
  useProxy?: boolean,
  getEHRAndPatientReported?: boolean,
  additionalHeaders?: {[index: string]: any},
) => {
  const context = getEHRAndPatientReported ? 'api/patient' : 'fhir';
  const path = useProxy
  ? `${BASE_URL}/carestudio-nest/proxy/integration/${context}/MedicationStatement`
  : `${INTEGRATION_BASE_URL}/${context}/MedicationStatement`;
  const headers = await getHeaders();
  let searchQueryString = `patient=${patientId}`;
  if (locationId) {
    searchQueryString += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    searchQueryString += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios
    .get(`${path}?${searchQueryString}`, {
      headers: {...headers, ...additionalHeaders},
    });
};

export const getDocumentRefByReferenceId = async (noteId: string,foldSource?: boolean, locationId?: string) => {
  const headers = await getHeaders();
  let queryParams = '';
  if (foldSource) {
    queryParams += `?foldSource=true`;
  }
  if (locationId) {
    queryParams += `${queryParams ? '&' : '?'}locationId=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    if (locationGroupId) {
      queryParams += `${queryParams ? '&' : '?'}locationGroupId=${locationGroupId}`;
    }
  }
  return axios
    .get(`${INTEGRATION_BASE_URL}/fhir/DocumentReference/${noteId}${queryParams}`, {
      headers,
    });
};

export const getOrderByRefAndPatientId = async (
  type: OrderType,
  patientId: string,
  noteId: string,
  locationId?: string,
) => {
  const headers = await getHeaders();
  let searchQueryString = `patient=${patientId}&category=${getOrderType(type)}&based-on=DocumentReference/${noteId}`;
  if (locationId) {
    searchQueryString += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    searchQueryString += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios
    .get(`${INTEGRATION_BASE_URL}/fhir/RequestGroup?${searchQueryString}`, {
      headers,
    });
};

export const getMedicationForNote = async (
  contactId: string,
  noteId: string,
) => {
  const headers = await getHeaders();
  const body = {
    contactId,
    documentReferenceId: noteId
  };
  return axios
    .put(`${INTEGRATION_BASE_URL}/api/order/prescription/populate`, body, {
      headers,
    });
};
export const syncOrdersInHGSession = async (params: {
  sessionStart: Date,
  sessionEnd: Date,
  patientId: string,
  documentReferenceId?: string,
}) => {
  const headers = await getHeaders();
  const body = {
    patientId: params.patientId,
    startDate: getMomentObj(params.sessionStart).toISOString(),
    endDate: getMomentObj(params.sessionEnd).add(5, 'minutes').toISOString(),
    documentReferenceId: params.documentReferenceId,
  };
  return axios
  .put(`${INTEGRATION_BASE_URL}/api/order/fetch`, body, {
    headers,
  });
}

export const unlockPatientNoteById = async (
  noteId: string,
  locationId?: string,
  foldSource?: boolean
) => {
  const headers = await getHeaders();
  let url = `${INTEGRATION_BASE_URL}/api/patient/DocumentReference/${noteId}/unlock`;
  if (locationId) {
    url += `?location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `?locationGroupId=${locationGroupId}` : '';
  }
  if (foldSource) {
    url += `&foldSource=${foldSource}`;
  }
  return axios.put(url, {}, { headers });
};

export const addOrUpdateReport = async (
  body: {
    attachmentIds: string[];
    requestGroupId: string;
    serviceRequestId: string;
    patientId: string;
    reportName: string;
    diagnosticReportId?: string;
  }
) => {
  const headers = await getHeaders();
  return axios
    .put(`${INTEGRATION_BASE_URL}/api/order/report`, body, {
      headers,
    });
};

export const getAuditTrailByResourceId = async (
  noteId: string,
  locationId?: string
) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/api/patient/audit?resourceId=${noteId}&resourceType=DocumentReference`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.get(path, {
    headers,
  });
};

export const getVersionDetailByAuditId = async (
  auditId: string,
  locationId?: string
) => {
  const headers = await getHeaders();
  const path = `${INTEGRATION_BASE_URL}/api/patient/audit/${auditId}${
    locationId ? `?location=${locationId}` : ``
  }`;
  return axios.get(path, {
    headers,
  });
};

export const getCodes = async (params: {
  searchType: PAMISearchType;
  searchString: string;
  offset: number;
  pageSize: number;
}) => {
  const headers = await getHeaders();
  return axios
    .get(`${INTEGRATION_BASE_URL}/api/codes?limit=${params.pageSize}&offset=${params.offset}&searchType=${params.searchType}&searchString=${params.searchString}`, {
      headers,
  });
}

export const getQuestionnaire = async (code: string, locationId?: string) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.QUESTIONNAIRE}?source=health-gorilla&code=${code}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
};

export const addOrUpdateOrder = async (order: RequestGroup) => {
  const headers = await getHeaders();
  return axios
    .put(`${INTEGRATION_BASE_URL}/fhir/RequestGroup/${order.id}`, order, {
      headers,
    });
};

export const deleteOrder = async (orderId: string) => {
  const headers = await getHeaders();
  return axios
    .delete(`${INTEGRATION_BASE_URL}/fhir/RequestGroup/${orderId}`, {
      headers,
    });
};
