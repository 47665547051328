import React from 'react';
import {Svg, G, Defs, ClipPath, Path} from 'react-native-svg';

function DismissedAlertsSvg() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <G clipPath="url(#clip0_174_4042)">
        <path
          stroke="#6F7A90"
          strokeLinecap="round"
          d="M12.333 3.667l-8.666 8.666m11-4.333A6.667 6.667 0 111.333 8a6.667 6.667 0 0113.334 0z"
        ></path>
      </G>
      <Defs>
        <ClipPath id="clip0_174_4042">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default DismissedAlertsSvg;
