import {Button, HStack, Spacer, Text, VStack, Select, View} from 'native-base';
import {IHieInvalidField, IHieRequest} from './interface';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PAMISearch, {
  PAMISearchType,
} from '../../../../common/PAMISearch/PAMISearch';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT} from '../../../../../constants';
import {useIntl} from 'react-intl';
import {HieInvalidFields} from './HieRequestsUtils';
import {useState} from 'react';
import {DatePicker} from 'antd';
import {
  getMomentObjectWithDateStringAndFormat,
  isFutureDate,
} from '../../../../../utils/DateUtils';
import CustomTextAreaField, {
  CustomTextInputMode,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/TextAreaComponent/CustomTextAreaField';
import {debounce} from 'lodash';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../styles';
import {styles} from './PendingHieRequestsStyles';
import {ACTION_CODES} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import './PendingHieRequests.css';
import FHIRPractitionerSearch from '../../../../common/FHIRPractitionerSearch/FHIRPractitionerSearch';

interface HieMatchDataViewProps {
  hieRequest: IHieRequest;
  onContinueClick: (invalidFields: IHieInvalidField[]) => void;
  headerText: string;
  onActionPerform: (id: string, code: string) => void;
  contactLocationId?: string
}

export const HieMatchDataView = (props: HieMatchDataViewProps) => {
  const intl = useIntl();
  const {hieRequest, onContinueClick, onActionPerform, contactLocationId} = props;

  const [componentState, setComponentState] = useState({
    invalidFields: hieRequest.invalidFields || [],
  });

  const updateValueByType = (field: IHieInvalidField, value: any) => {
    setComponentState((prev) => {
      const updatedInvalidFields = prev.invalidFields?.map((invalidField) => {
        if (invalidField.field === field.field) {
          invalidField.value = value;
        }
        return invalidField;
      });
      return {
        ...prev,
        invalidFields: updatedInvalidFields,
      };
    });
  };

  const renderCodeView = (invalidHieFields: IHieInvalidField[]) => {
    const invalidCodeField = invalidHieFields?.find(
      (invalidHieField) => invalidHieField.inputType === HieInvalidFields.code
    );
    if (invalidCodeField) {
      return (
        <VStack>
          <Text fontSize={16} fontWeight={'500'} marginBottom={2}>
            Match To Record
          </Text>
          <PAMISearch
            showAdditionalDetails={false}
            enablePaginationOnScroll
            searchType={
              invalidCodeField?.extraData?.searchType || PAMISearchType.problem
            }
            placeholder={invalidCodeField?.placeholder || 'Search Problem'}
            isShowError={false}
            onChange={(value) => {
              updateValueByType(invalidCodeField, value);
            }}
            locationId={contactLocationId}
          />
        </VStack>
      );
    }
  };

  const renderNoteView = (invalidHieFields: IHieInvalidField[]) => {
    const invalidNoteField = invalidHieFields?.find(
      (invalidHieField) =>
        invalidHieField.inputType === HieInvalidFields.textArea
    );
    if (invalidNoteField) {
      return (
        <VStack marginTop={getHorizontalInvalidFields()?.length > 1 ? 3 : 0}>
          <CustomTextAreaField
            showError={false}
            placeholder={'Note'}
            defaultValue={invalidNoteField.value}
            macroTemplatesEnabled={true}
            onValueChange={debounce((text: string) => {
              updateValueByType(invalidNoteField, text);
            }, 500)}
            additionalProps={{
              totalLines: 2,
              flex: 1,
              isReadOnly: false,
              borderRadius: 8,
            }}
          />
        </VStack>
      );
    }
  };

  const getMandatoryDetailsTextVisibility = () => {
    const invalidNoteField = componentState.invalidFields?.find(
      (invalidHieField) =>
        invalidHieField.inputType === HieInvalidFields.textArea
    );

    return getHorizontalInvalidFields()?.length || invalidNoteField;
  };

  const renderHorizontalFields = (invalidHieField: IHieInvalidField) => {
    if (invalidHieField.inputType === HieInvalidFields.date) {
      const onsetDateFormat = invalidHieField?.extraData?.dateFormat;
      const isOnlyYearFormat =
        invalidHieField?.extraData?.dateFormat === 'YYYY';
      const label = intl.formatMessage({
        id: isOnlyYearFormat ? 'sinceYear' : 'sinceDate',
      });

      return (
        <View flex={1}>
          <DatePicker
            className="match-data-date-picker"
            picker={isOnlyYearFormat ? 'year' : 'date'}
            disabled={false}
            placeholder={label}
            format={isOnlyYearFormat ? onsetDateFormat : DISPLAY_DATE_FORMAT}
            disabledDate={(current: any) => {
              return current && isFutureDate(current);
            }}
            value={
              invalidHieField.value
                ? getMomentObjectWithDateStringAndFormat(
                    invalidHieField.value,
                    onsetDateFormat || ''
                  )
                : undefined
            }
            style={{
              height: 40,
              borderRadius: 8,
              color: 'black',
            }}
            onChange={(value) => {
              const onSetDateTime = onsetDateFormat
                ? value?.format(onsetDateFormat)
                : value?.toISOString();
              updateValueByType(invalidHieField, onSetDateTime);
            }}
          />
        </View>
      );
    }

    if (invalidHieField.inputType === HieInvalidFields.select) {
      const selectList = invalidHieField?.extraData?.statusList;

      return (
        <View flex={1}>
          <Select
            placeholder={invalidHieField.label || 'Select Status'}
            selectedValue={invalidHieField.value?.code}
            onValueChange={(value: string) => {
              updateValueByType(invalidHieField, value);
            }}
            borderRadius={8}
            size={'medium'}
          >
            {selectList.map((option: any, index: number) => {
              return (
                <Select.Item
                  key={`${option.id}_${index}`}
                  value={option.code}
                  label={option.display}
                />
              );
            })}
          </Select>
        </View>
      );
    }

    if (invalidHieField.inputType === HieInvalidFields.input) {
      return (
        <View flex={1}>
          <CustomTextAreaField
            showError={false}
            inputMode={CustomTextInputMode.textfield}
            placeholder={invalidHieField.placeholder}
            defaultValue={invalidHieField.value}
            macroTemplatesEnabled={true}
            onValueChange={debounce((text: string) => {
              updateValueByType(invalidHieField, text);
            }, 500)}
            additionalProps={{
              totalLines: 1,
              flex: 1,
              height: '40px',
              isReadOnly: false,
              borderRadius: 8,
            }}
          />
        </View>
      );
    }

    if (invalidHieField.inputType === HieInvalidFields.practitioner) {
      return (
        <View flex={1}>
          <FHIRPractitionerSearch
            value={invalidHieField.value}
            data={invalidHieField.extraData?.practitionerData || []}
            loading={false}
            placeholder={invalidHieField.placeholder}
            isShowError={false}
            disabled={false}
            onChange={(value) => {
              updateValueByType(invalidHieField, value);
            }}
          />
        </View>
      );
    }
  };

  const getHorizontalInvalidFields = () => {
    return componentState.invalidFields.filter(
      (invalidField) =>
        invalidField.inputType !== HieInvalidFields.textArea &&
        invalidField.inputType !== HieInvalidFields.code
    );
  };

  return (
    <Stack
      space={4}
      direction="column"
      style={{
        backgroundColor: Colors.Custom.White,
        marginTop: 8,
        padding: 12,
        borderRadius: 8,
        borderWidth: 0.5,
        borderColor: Colors.FoldPixel.GRAY150,
      }}
    >
      <Stack direction="column" space={12}>
        {renderCodeView(componentState.invalidFields)}
        {getMandatoryDetailsTextVisibility() && (
          <Text fontSize={16} fontWeight={'500'}>
            Fill Mandatory Fields
          </Text>
        )}
        {getHorizontalInvalidFields()?.length > 0 ? (
          <Stack direction="row" space={12}>
            {getHorizontalInvalidFields()?.map((invalidField) => {
              return renderHorizontalFields(invalidField);
            })}
          </Stack>
        ) : (
          null
        )}
        {renderNoteView(componentState.invalidFields)}
      </Stack>

      <Stack direction="row">
        <Spacer />
        <Button.Group space={2} marginTop={2}>
          <Button
            variant="unstyled"
            style={styles.saveButton}
            onPress={() => {
              onContinueClick(componentState.invalidFields || []);
            }}
          >
            <DisplayText
              textLocalId="save"
              extraStyles={{
                color: Colors.Custom.White,
                fontSize: 14,
              }}
            />
          </Button>
          <Button
            variant="unstyled"
            style={styles.cancelButton}
            onPress={() => {
              onActionPerform?.(
                hieRequest.id,
                ACTION_CODES.HIDE_MATCH_DATA_VIRW
              );
            }}
          >
            <DisplayText
              textLocalId="cancel"
              extraStyles={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 14,
              }}
            />
          </Button>
        </Button.Group>
      </Stack>
    </Stack>
  );
};
