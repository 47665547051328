
import {AxiosResponse} from 'axios';
import BaseService from '../../../../services/CommonService/BaseService';
import { IRecordingAnnouncement } from './interface';
import { CUSTOM_FIELD_PATH } from '../../CallConfiguration/CallExtensionApiPath';

export const updateRecordingAnnouncement = async (recordingAnnouncement: IRecordingAnnouncement) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.put(CUSTOM_FIELD_PATH.CALL_RECORDING_ANNOUNCEMENT_API, {
    ...recordingAnnouncement
  })
  return response;
};
