import React, { useRef, useState } from 'react';
import { View, Text } from 'react-native';
import { Pressable, Stack } from 'native-base';
import { Colors } from '../../styles/Colors';
import { ModalActionDatePicker } from '../common/ModalActionCommonComponent/ModalActionDatePicker';
import { Calendar, Modal } from 'antd';
import {
    DatePicker as AntdDatePicker,
    DatePickerProps as AntDatePickerProps,
} from 'antd'
import { Moment } from 'moment';
import IMask from 'imask';
import moment from 'moment';
import { DATE_FORMATS, DISPLAY_DATE_FORMAT } from '../../constants/StringConst';
import { getDateObjectFromAPIFormatToMyFormat, getDateStrFromFormat, getEndOfDate, getEndOfUpcomingSunday, getFormattedDate, getMomentObjectWithDateStringAndFormat, isBeforeDate } from '../../utils/DateUtils';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import BackArrowSvg from '../common/Svg/SideCarSvg/BackArrowSvg';
import ClosedCollapseSvg from '../common/Svg/SideCarSvg/ClosedCollpaseSvg';


const SNOOZE_ACTION_CODES = {
    TOMORROW: 'tomorrow',
    NEXT_WEEK: 'next_week',
    CHOOSE_DATE: 'choose_date',
}

const snoozeOptions = [
    { label: 'For today', value: SNOOZE_ACTION_CODES.TOMORROW },
    { label: 'For this week', value: SNOOZE_ACTION_CODES.NEXT_WEEK },
    { label: 'Choose a date', value: SNOOZE_ACTION_CODES.CHOOSE_DATE },
];  

interface ISnoozeViewProps {
    onSelectSnoozeOption: (option: string) => void;
    onBackClick: () => void;
    onDatePickerOpen: () => void;
}

const SnoozeView = (props: ISnoozeViewProps) => {
    const { onSelectSnoozeOption, onBackClick, onDatePickerOpen } = props;
    const MASKED = IMask.createMask({
        blocks: {
            DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
            MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
            YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
        },
        format: (date: Date) => moment(date).format(DISPLAY_DATE_FORMAT),
        mask: Date,
        parse: (date: string) => moment(date, DISPLAY_DATE_FORMAT),
        pattern: DISPLAY_DATE_FORMAT,
    })
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);
    const datePickerRef: any = useRef(null);

    const getDateByCode = (code: string) => {
        const date = new Date();
        switch (code) {
            case SNOOZE_ACTION_CODES.TOMORROW:
                return getEndOfDate(date).toISOString();
            case SNOOZE_ACTION_CODES.NEXT_WEEK:
                return getEndOfUpcomingSunday(date).toISOString();
            default:
                return ''
        }
    }

    const handleSnoozeOptionSelect = (value: string) => {
        if (value === SNOOZE_ACTION_CODES.CHOOSE_DATE) {
            onDatePickerOpen()
        } else {
            const dateTimeForSnooze = getDateByCode(value)
            onSelectSnoozeOption(dateTimeForSnooze);
        }
    };

    return (
        <Stack direction="column" style={{ padding: 0, borderRadius: 16 }}>
            <Pressable
                style={{
                    padding: 4,
                    borderRadius: 4,
                }}
                onPress={onBackClick}
                _hover={{
                    backgroundColor: Colors.FoldPixel.GRAY50
                }}
            >
                <Stack
                    direction={'row'}
                    space={2}
                    style={{ padding: 4, alignItems: 'center' }}
                >
                    <BackArrowSvg />
                    <Text
                        style={{
                            color: Colors.FoldPixel.GRAY300,
                            fontSize: 12,
                            fontWeight: '500',
                            lineHeight: 14.4,
                            letterSpacing: 0.5,
                        }}
                    >
                        SNOOZE
                    </Text>
                </Stack>
            </Pressable>

            {snoozeOptions.map((option, index) => (
                <Pressable
                    style={{
                        padding: 4,
                        borderRadius: 4,
                    }}
                    key={index}
                    onPress={() => {
                        handleSnoozeOptionSelect(option.value);
                    }}
                    _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY50,
                    }}
                >
                    <Stack
                        direction={'row'}
                        style={{ padding: 4, alignItems: 'center' }}
                        space={4}
                    >
                        <Text
                            style={{
                                fontSize: 14,
                                fontWeight: '400',
                                color: Colors.FoldPixel.GRAY400,
                            }}
                        >
                            {option.label}
                        </Text>
                        {
                            option.value === SNOOZE_ACTION_CODES.CHOOSE_DATE && <ClosedCollapseSvg />
                        }
                    </Stack>
                </Pressable>
            ))}
        </Stack>
    );
};

export default SnoozeView;