import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { IFormCommonData } from "../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { IContact } from "../../RightSideContainer/TeamInbox/Conversations/interfaces";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { AWV_VISIT_CODE, FoldScoreUsedFacts } from "./constant";
import { getPrimaryGenderCode, getUserUUID, isAllowToShowExternalPatientId } from "../../../utils/commonUtils";
import { getMlovIdFromCode, getMlovListFromCategory } from "../../../utils/mlovUtils";
import { DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, FHIR_RESOURCE, GROUP_MEMBER_TYPE, MLOV_CATEGORY, PERSON_TYPES, SCORE_TYPE } from "../../../constants";
import { getCompletedTaskStatusId } from "../CareDashboard/CareDashboardUtils/CareDashboardUtils";
import { TASK_ALERT_CATEGORY_CODES, TASK_PRIORITY_CODES } from "../../../constants/MlovConst";
import { useApolloClient } from "@apollo/client";
import { getAgeValue, getCurrentTimeZone, getDateStrFromFormat, getYearsAndMonthsSinceDate, isDateBetweenFutureDays, isDateBetweenLastDays } from "../../../utils/DateUtils";
import { ICondition } from "../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces";
import { getCurrentPatientDeceasedData, getPatientClaim, getResourceContentWithHeaders } from "../../../services/CommonService/AidBoxService";
import { getAppointmentDateTime } from "../CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper";
import { useIntl } from "react-intl";
import { IFactPromise } from "../MemberInfoListItem/interface";
import TaskQueries, { GET_TASK_ALERTS } from "../../../services/Task/TaskQueries";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../constants/Configs";
import { ComponentType } from "../../RightSideContainer/Forms/FHFormio/CustomComponents/Diagnosis/interfaces";
import { getMedicationStatementAndPatientReportedOrder } from "../../../services/CommonService/OrderService";
import { Colors } from "../../../styles/Colors";
import { Box, Divider, HStack, Text, View, VStack } from 'native-base';
import { PendingIconSvg } from "../../SideCar/assets/images/PendingIconSvg";
import { getEhrConfig } from "../../../utils/capabilityUtils";
import { Claim } from "fhir/r4";
import { DisplayCardAvatar } from "../DisplayCard/DisplayCardAvatar";
import moment from "moment";
import { Progress } from "antd";
import AlertSeverityIcon from "../../../assets/Icons/AlertSeverityIcon";
import { useCareProgramDeclinedInfo } from "./customHook/useCareProgramDeclinedInfo";
import CheckRoundIcon from "../../../assets/Icons/CheckRoundIcon";
import AthenaHealthIcon from "../../../assets/Icons/AthenaHealthIcon";

const ContactPopoverContent = (props: {selectedRecord: any}) => {
  const { selectedRecord } = props;
  const [factState, setFactState] = useState({
    loading: true,
    factDetails: {} as Record<FoldScoreUsedFacts, any>,
    awvDetails: {} as any,
  });
  const[deceasedDate, setDeceasedDate] = useState('')
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const userSettings = contextData.userSettings;
  const allowToShowExternalPatientId = isAllowToShowExternalPatientId(userSettings);
  const userUuid = getUserUUID();
  const taskStatusMlov =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const taskPriorityMlovs =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_PRIORITY
    ) || [];
  const taskAlertDisplayCategoryMlovs =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const taskAlertDisplayCategoryIds = getMlovIdFromCode(taskAlertDisplayCategoryMlovs, TASK_ALERT_CATEGORY_CODES.PENDING);
  const highPriorityAlertIdList = taskPriorityMlovs
  ?.filter((item) => item.code === TASK_PRIORITY_CODES.HIGH)
  .map((item) => item.id);
  const mediumPriorityAlertIdList = taskPriorityMlovs
  ?.filter((item) => item.code === TASK_PRIORITY_CODES.MEDIUM)
  .map((item) => item.id);

  const client = useApolloClient();
  const defaultTimeZone = getCurrentTimeZone();
  const intl = useIntl();
  const appointmentData = selectedRecord?.appointmentData;
  const hccScores = selectedRecord?.contactData?.contactScoreAudit;
  const usedContactFacts: any = selectedRecord?.contactData?.contactScore?.reference?.usedFacts;
  const chronicConditions: ICondition[] = selectedRecord?.chronicCondition || [];
  const carePrograms = selectedRecord?.contactCarePrograms || [];
  const contactData = selectedRecord?.contactData;
  const ehrConfig = getEhrConfig(
    contextData?.locationId ||
      contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  const currentGender = contactData?.person?.gender?.value;
  const birthDate = contactData?.person?.birthDate
    ? moment(contactData?.person?.birthDate).format(DISPLAY_SLASH_DATE_FORMAT)
    : '';
  const contactType =
  contactData?.contactType?.contactType ?
    contactData.contactType.contactType.code === PERSON_TYPES.CUSTOMER
      ? 'Patient'
      : contactData.contactType.contactType.code === PERSON_TYPES.CUSTOMER ? 'Prospect'
        : contactData.contactType.contactType.value
    : 'Prospect';
  const genderProfileCode = getPrimaryGenderCode(contactData);
  const sexAtBirth = contactData?.person?.sexAtBirth?.value;
  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
    const patientDeceasedDate = await getCurrentPatientDeceasedData(contactData || {});
     setDeceasedDate(patientDeceasedDate)
   }
  const ageValue = contactData?.person?.birthDate
    ? getAgeValue(contactData?.person?.birthDate, deceasedDate)
    : '';
  let upcomingAppointmentDateAndTime: string;
  if(appointmentData){
    upcomingAppointmentDateAndTime = getAppointmentDateTime(appointmentData, defaultTimeZone);
  }

  const formatDuration = (years: number, months: number): string => {
    let duration = '';
    if (years > 0) {
      duration += `${years} ${years > 1 ? 'years' : 'year'}`;
    }
    if (months > 0) {
      if (duration) {
        duration += ' ';
      }
      duration += `${months} ${months > 1 ? 'months' : 'month'}`;
    }
    return duration.trim();
  };

  const fetchDataForFact = async (fact: FoldScoreUsedFacts, promiseList: IFactPromise[]): Promise<any> => {
    switch (fact) {
      case FoldScoreUsedFacts.PENDING_HIGH_ALERT_7_DAYS:
        promiseList.push({
          fact,
          promise: client.query({
            query: GET_TASK_ALERTS,
            variables: {
              params: {
                assigneeIds: [userUuid],
                taskAlertDisplayCategoryIds: [taskAlertDisplayCategoryIds],
                priorityIds: highPriorityAlertIdList,
                includeOnlyAlerts: true,
                contactIds: [contactData?.uuid],
                taskTypeIds: [],
              },
            },
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
            fetchPolicy: 'no-cache',
          }),
        });
        break;

      case FoldScoreUsedFacts.PENDING_MEDIUM_ALERT_7_DAYS:
        promiseList.push({
          fact,
          promise: client.query({
            query: GET_TASK_ALERTS,
            variables: {
              params: {
                assigneeIds: [userUuid],
                taskAlertDisplayCategoryIds: [taskAlertDisplayCategoryIds],
                priorityIds: mediumPriorityAlertIdList,
                includeOnlyAlerts: true,
                contactIds: [contactData?.uuid],
                taskTypeIds: [],
              },
            },
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
            fetchPolicy: 'no-cache',
          }),
        });
        break;

      case FoldScoreUsedFacts.ANY_CONDITION_30_DAYS:
        promiseList.push({
          fact,
          promise: new Promise<any>((resolve, reject) => {
            getResourceContentWithHeaders(
              FHIR_RESOURCE.CONDITION,
              `patient=${contactData?.patient?.patientId}&category=${ComponentType.Condition}`,
              false,
              contextData.headers,
              (response) => resolve(response),
              (error) => reject(error),
              contextData.locationId,
              false
            );
          }),
        });
        break;

      case FoldScoreUsedFacts.GTE_5_ACTIVE_MEDICATION:
      case FoldScoreUsedFacts.MEDICATION_ADDED_30_DAY:
      case FoldScoreUsedFacts.ACTIVE_5_MEDICATION_MEDICATION_ADDED_30_DAY:
        promiseList.push({
          fact,
          promise: getMedicationStatementAndPatientReportedOrder(
            contactData?.patient?.patientId,
            contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
            false,
            true,
            contextData.headers
          ),
        });
        break;

      case FoldScoreUsedFacts.TASK_ADHERENCE_LT_75:
      case FoldScoreUsedFacts.TASK_ADHERENCE_LT_50:
      case FoldScoreUsedFacts.TASK_ADHERENCE_LT_25:
      case FoldScoreUsedFacts.TASK_ADHERENCE_LT_10:
        promiseList.push({
          fact,
          promise: client.query({
            query: TaskQueries.GET_AGGREGATE_TASK_COUNTS,
            variables: {
              pendingParams: {
                assigneeIds: [],
                assigneeIdsNotIn: [contactData?.uuid],
                contactIds: [contactData?.uuid],
                statusIdsNotIn: [completedStatusId],
              },
              completedParams: {
                assigneeIds: [],
                assigneeIdsNotIn: [contactData?.uuid],
                contactIds: [contactData?.uuid],
                statusIds: [completedStatusId],
              },
            },
            fetchPolicy: 'no-cache',
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
          }),
        });
        break;
      default:
        break
    }
  };

  const fetchFactDetails = async () => {
    setFactState({ ...factState, loading: true });
    try {
      const promiseList: IFactPromise[] = [];
      Object.keys(usedContactFacts).forEach((fact:any) => {
        fetchDataForFact(fact, promiseList);
      });
      const results = await Promise.all(
        promiseList.map(({ promise, fact }) =>
          promise
          .then((data) => ({ fact, data }))
          .catch((error) => {
            return {};
          })
        )
      );
      const validResults = results.filter((result): result is { fact: FoldScoreUsedFacts; data: any } =>
        result && 'fact' in result && 'data' in result
      );
      const claimResponse = await getPatientClaim(contactData?.patient?.patientId, contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid || '')
      const {isPending, date} = getLastAwvProgramDateAndPendingStatus(claimResponse);
      const awvdetailsObj = {
        isPending: isPending,
        lastVisitDate: date,
      }
      const factDetails: Record<FoldScoreUsedFacts, any> = processFactResults(validResults);
      setFactState({ loading: false, factDetails, awvDetails: awvdetailsObj });
    } catch (error) {
      setFactState({ ...factState, loading: false });
    }
  };

  const processFactResults = (results: { fact: FoldScoreUsedFacts; data: any }[]): Record<FoldScoreUsedFacts, any> => {
    return results.reduce((acc, { fact, data }) => {
      switch (fact) {
        case FoldScoreUsedFacts.PENDING_HIGH_ALERT_7_DAYS:
        case FoldScoreUsedFacts.PENDING_MEDIUM_ALERT_7_DAYS:
          acc[fact] = data?.data?.getTasks?.aggregate?.total;
          break;
        case FoldScoreUsedFacts.ANY_CONDITION_30_DAYS:
          const conditions = (data?.entry || []).filter((condition: any) => {
            const clinicalStatus = condition?.resource?.clinicalStatus?.coding?.[0]?.code;
            return clinicalStatus.toLowerCase() === "active";
          }).map((condition: any) => {
            const displayName = condition?.resource?.code?.coding?.[0]?.display;
            const onsetDateTime = condition?.resource?.onsetDateTime;
            const {years, months} = getYearsAndMonthsSinceDate(onsetDateTime);
            return {
              name: displayName,
              onsetDate: {
                years: years,
                months: months,
              },
            };
          });
          acc[fact] = conditions;
          break;
        case FoldScoreUsedFacts.GTE_5_ACTIVE_MEDICATION:
        case FoldScoreUsedFacts.MEDICATION_ADDED_30_DAY:
        case FoldScoreUsedFacts.ACTIVE_5_MEDICATION_MEDICATION_ADDED_30_DAY:
          const medications = data?.data?.resource?.data?.entry;
        const activeMedicationCount = medications.filter((medication: any) =>
          medication?.resource?.status.toLowerCase() === 'active').length;
        acc[fact] = activeMedicationCount;
          break;
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_75:
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_50:
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_25:
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_10:
          const taskResponse = data?.data;
          const pendingTasksCount = taskResponse?.pendingTasks?.aggregate?.total;
          const completedTasksCount = taskResponse?.completedTasks?.aggregate?.total;
          const tastAdherence = Math.round(completedTasksCount*100/(pendingTasksCount+completedTasksCount));
          acc[fact] = tastAdherence;
          break;
        default:
          break;
      }
      return acc;
    }, {} as Record<FoldScoreUsedFacts, any>);
  };

  const getAwvPending = () => {
    return (
      <HStack style={{alignItems: 'center', borderRadius: 4, backgroundColor: Colors.Custom.PendingTaskBackgroundColor, paddingHorizontal: 2}}>
        <PendingIconSvg size={10}/>
        <Text
          fontSize={12}
          marginLeft={1}
          color={Colors.Custom.PendingTaskFontColor}
        >
          {intl.formatMessage({id: 'pending'})}
        </Text>
      </HStack>
    )
  }

  const getLastAwvProgramDateAndPendingStatus = (claimData: any) => {
    if (!ehrConfig?.isAthena && !ehrConfig?.isElation) {
      return { isPending: false, date: '' };
    }
    if (claimData?.data?.entry?.length > 0) {
      const claimEntries: any[] = claimData?.data?.entry;
      let currentYearAwvVisitDate: string | undefined = '';
      let subSequenceAWV: string | undefined = '';
      const awvVisitDates: string[] = [];
      let isSubsequentVisitPending = true;
      for (const claimEntry of claimEntries) {
        const resource: Claim = claimEntry?.resource || {};
        (resource?.procedure || []).forEach((patientProcedure: any) => {
          (patientProcedure.procedureCodeableConcept?.coding || []).forEach((procedureCode: any) => {
            const visitDate = resource.billablePeriod?.end;
            if (visitDate) {
              if (procedureCode?.code === AWV_VISIT_CODE.AWV_VISIT ||
                  procedureCode?.code === AWV_VISIT_CODE.INITIAL_VISIT) {
                awvVisitDates.push(visitDate);
                if (isDateBetweenLastDays(visitDate, 366)) {
                  currentYearAwvVisitDate = visitDate;
                }
              }
              if (procedureCode?.code === AWV_VISIT_CODE.SUB_SEQUENT_VISIT) {
                subSequenceAWV = visitDate;
                if (isDateBetweenFutureDays(visitDate, 90)) {
                  isSubsequentVisitPending = false;
                }
                awvVisitDates.push(visitDate);
              }
            }
          });
        });
      }
      const isPending = !currentYearAwvVisitDate || awvVisitDates.length == 0 || isSubsequentVisitPending;
      if (awvVisitDates.length) {
        awvVisitDates.sort((date1: string, date2: string) => {
          return new Date(date2).getTime() - new Date(date1).getTime();
        });
        const formattedDate = getDateStrFromFormat(awvVisitDates[0], DATE_FORMATS.CALENDAR_LIB_FORMAT);
        return {isPending: isPending, date: formattedDate};
      }
      return {isPending: isPending, date: ''};
    }
    return {isPending: true, date: ''};
  };

  const getHCCScores = () => {
    const hccV24Score = selectedRecord?.contactData?.contactScore?.hccV24;
    const hccV28Score = selectedRecord?.contactData?.contactScore?.hccV28;
    let hccV24ScoreDiff, hccV28Scorediff;
    const hccV24AuditScores = hccScores?.filter(
      (item:any) => item?.scoreType ===  SCORE_TYPE.HCC_V_24
    );
    const hccV28AuditScores = hccScores?.filter(
      (item:any) => item?.scoreType ===  SCORE_TYPE.HCC_V_28
    );
    if (hccV24AuditScores?.length >= 2) {
      hccV24ScoreDiff = (hccV24AuditScores?.[0].scoreValue - hccV24AuditScores?.[1].scoreValue);
      if (hccV24ScoreDiff !== 0) {
        hccV24ScoreDiff = +hccV24ScoreDiff.toFixed(2);
      }
    }
    else {
      hccV24ScoreDiff = 0;
    }
    if (hccV28AuditScores?.length >= 2) {
      hccV28Scorediff = (hccV28AuditScores?.[0].scoreValue - hccV28AuditScores?.[1].scoreValue);
      if (hccV28Scorediff !== 0) {
        hccV28Scorediff = +hccV28Scorediff.toFixed(2);
      }
    }
    else {
      hccV28Scorediff = 0;
    }
    return {hccV24Score, hccV28Score, hccV24ScoreDiff, hccV28Scorediff};
  }

  const renderPatientInfoCard = () => {
    return (
      <Fragment key={'patientsInfo'}>
        <HStack marginY={1}>
          <View>
            <DisplayCardAvatar
              avatarStyle={{
                avatarSize: '12',
                width: 48,
                height: 48,
              }}
              isLetterAvatarShow
              userData={{
                userId: contactData?.id || '',
                userType: GROUP_MEMBER_TYPE.CONTACT,
                genderCode: genderProfileCode as any,
                contactType: contactData?.contactType?.contactType?.code || '',
                name: contactData?.person,
                imgSrc:
                  contactData?.contactProfilePhotos &&
                    contactData?.contactProfilePhotos.length
                    ? contactData?.contactProfilePhotos[0]?.profilePhotoUrl
                    : undefined,
                userName: contactData?.name || '',
              }}
            />
          </View>
          <VStack style={{ marginLeft: 8 }} flex={1}>
            <Text
              size={'smBold'}
              color={'coolGray.700'}
              justifyContent={'center'}
              style={{maxWidth: 300}}
              isTruncated={true}
              marginLeft={2}
            >
              {contactData?.name ? contactData?.name : ' '}
            </Text>
            <HStack>
              <View justifyContent={'center'} marginLeft={2}>
                <Text color={Colors.FoldPixel.GRAY200} size={'xsMedium'}>
                  {contactType} •{' '}
                  <Text color={sexAtBirth == currentGender ? Colors.FoldPixel.GRAY200 : Colors.Custom.badgeColorOrange} size={'xsMedium'}>
                    {currentGender}
                  </Text>
                  {' '}• {ageValue} ({birthDate})
                </Text>
              </View>
            </HStack>
          </VStack>
        </HStack>
        <Divider />
      </Fragment>
    )
  }

  const renderTaskAdherence = (percent: number) => {
    return (
      <Fragment key={'taskAdherence'}>
        <VStack marginY={1} display={'flex'} style={{ width: '100%', paddingRight: '5px' }}>
          <Text color={Colors.FoldPixel.GRAY200}>{intl.formatMessage({id: 'taskAdherence'})}</Text>
          <Progress strokeColor={Colors.Custom.SuccessColor} percent={percent} />
        </VStack>
        <Divider />
      </Fragment>
    );
  };

  const renderPatientEngaged = (fact: FoldScoreUsedFacts) => {
    return (
      <Fragment key={'patientEngaged'}>
        <VStack marginY={1} display={'flex'}>
          <Text color={Colors.FoldPixel.GRAY200}>{intl.formatMessage({id: 'patientEngagement'})}</Text>
          <Text>Last engaged {parseInt((fact.match(/(\d+)_DAYS/))?.[1] || '0',10)} days ago</Text>
        </VStack>
        <Divider />
      </Fragment>
    );
  };

  const renderAlertTasks = (facts: FoldScoreUsedFacts[], data: any) => {
    const renderHighAlert = facts.includes(FoldScoreUsedFacts.PENDING_HIGH_ALERT_7_DAYS);
    const renderMediumAlert = facts.includes(FoldScoreUsedFacts.PENDING_MEDIUM_ALERT_7_DAYS);
    return (
      <Fragment key={'alert'}>
        <VStack marginY={1} display={'flex'}>
          <Text color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'alerts'})}</Text>
          <HStack>
            {renderHighAlert && (
              <HStack
                paddingX={1}
                borderRadius={4}
                backgroundColor={Colors.Custom.MissedTaskBackgroundColor}
                alignItems={'center'}
                space={1}
              >
                <AlertSeverityIcon priorityCode={TASK_PRIORITY_CODES.HIGH}/>
                <Text color={Colors.FoldPixel.STATUS_ERROR} fontSize={12}>{data[FoldScoreUsedFacts.PENDING_HIGH_ALERT_7_DAYS]} Severe</Text>
              </HStack>
            )}
            {renderMediumAlert && (
              <HStack
                marginLeft={renderHighAlert ? 1 : 0}
                paddingX={1}
                borderRadius={4}
                backgroundColor={Colors.Custom.PendingTaskBackgroundColor}
                alignItems={'center'}
                space={1}
              >
                <AlertSeverityIcon priorityCode={TASK_PRIORITY_CODES.MEDIUM}/>
                <Text color={Colors.FoldPixel.STATUS_IN_PROGRESS} fontSize={12}>{data[FoldScoreUsedFacts.PENDING_MEDIUM_ALERT_7_DAYS]} Moderate</Text>
              </HStack>
            )}
          </HStack>
        </VStack>
        <Divider />
      </Fragment>
    );
  };

  const renderAnyConditions = (data: any[]) => {
    return (data.length !== 0 && (
      <Fragment key={'anyCondition'}>
        <VStack marginY={1} display={'flex'}>
          <Text fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'anyConditions'})}</Text>
          <VStack>
            {(data).map((condition: any, index: number) => {
              return( <HStack>
                <Text color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={400}>
                {condition.name}
                {!!condition.onsetDate?.years && (
                  <Text wordBreak={'break-word'} color={Colors.FoldPixel.GRAY200}>
                    {` (${formatDuration(condition.onsetDate?.years, condition.onsetDate?.months)})`}
                  </Text>
                    )}
                </Text>
              </HStack>);
            })}
          </VStack>
        </VStack>
        <Divider />
      </Fragment>
    ))
  };

  const renderActiveMedicationsCount = (count: any) => {
    return (
      <Fragment key={'activeMedication'}>
        <VStack marginY={1} display={'flex'}>
          <Text fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'medication'})}</Text>
          <Text>{count} Active</Text>
        </VStack>
        <Divider />
      </Fragment>
    );
  };

  const renderByFact = (facts: FoldScoreUsedFacts[], data: any) => {
    const alertFacts: FoldScoreUsedFacts[] = [];
    const renderedComponents: JSX.Element[] = [];
    facts.forEach(fact => {
      switch (fact) {
        case FoldScoreUsedFacts.PENDING_HIGH_ALERT_7_DAYS:
        case FoldScoreUsedFacts.PENDING_MEDIUM_ALERT_7_DAYS:
          alertFacts.push(fact);
          break;
        case FoldScoreUsedFacts.ANY_CONDITION_30_DAYS:
          const component = renderAnyConditions(data[fact]);
          if (component) {
            renderedComponents.push(component);
          }
          break
        case FoldScoreUsedFacts.GTE_5_ACTIVE_MEDICATION:
        case FoldScoreUsedFacts.MEDICATION_ADDED_30_DAY:
        case FoldScoreUsedFacts.ACTIVE_5_MEDICATION_MEDICATION_ADDED_30_DAY:
          renderedComponents.push(renderActiveMedicationsCount(data[fact]));
          break;
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_75:
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_50:
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_25:
        case FoldScoreUsedFacts.TASK_ADHERENCE_LT_10:
          renderedComponents.push(renderTaskAdherence(data[fact]));
          break;
        case FoldScoreUsedFacts.PATIENT_ENGAGED_30_DAYS:
        case FoldScoreUsedFacts.PATIENT_ENGAGED_90_DAYS:
        case FoldScoreUsedFacts.PATIENT_ENGAGED_180_DAYS:
          renderedComponents.push(renderPatientEngaged(fact));
          break;
        default:
          break;
      }
    });
    if (alertFacts.length > 0) {
      renderedComponents.push(renderAlertTasks(alertFacts, data));
    }
    return renderedComponents.length > 0 ? renderedComponents : null;
  };

  const renderContentFromUsedFacts = () => {
    const facts: FoldScoreUsedFacts[] = [];
    Object.keys(factState.factDetails).forEach((key) => {
      const fact = key as FoldScoreUsedFacts;
      if (factState.factDetails[fact]) {
        facts.push(fact);
      }
    });
    return renderByFact(facts, factState.factDetails);
  };

  const renderFoldScore = () => {
    const foldScore = selectedRecord?.contactData?.contactScore?.foldScore;
    return (
      <Fragment key={'foldScore'}>
        <HStack marginY={1} alignItems={'center'}>
          <Text fontWeight={400} color={Colors.FoldPixel.GRAY300} fontSize={14} marginBottom={1}>{intl.formatMessage({id: 'foldScore'})}: </Text>
          {foldScore ? (
            <View 
              paddingX={1}
              borderRadius={4}
              backgroundColor={Colors.FoldPixel.GRAY50}
            >
              <Text color={Colors.FoldPixel.GRAY250} fontSize={10} fontWeight={500}>{foldScore}</Text>
            </View>
          ) : (
            <Text>-</Text>
          )} 
        </HStack>
        <Divider />
      </Fragment>
    )
  }

  const renderExternalPatientId = () => {
    return ( allowToShowExternalPatientId && (
      <Fragment key={'externalId'}>
        <HStack marginY={1} alignItems={'center'}>
          <AthenaHealthIcon />
          <Text fontWeight={400} color={Colors.FoldPixel.GRAY200} fontSize={14} marginLeft={1}>{intl.formatMessage({id: 'athenaId'})}: #{contactData?.patient?.patientId}</Text>
        </HStack>
        <Divider />
      </Fragment>
    ))
  }

  const renderHCCScores = () => {
    const {hccV24Score, hccV28Score, hccV24ScoreDiff, hccV28Scorediff} = getHCCScores();
    return (
      <Fragment key={'hcc'}>
        <VStack marginY={1} display={'flex'}>
          <Text fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'hcc'})}</Text>
          {(!!hccV24Score && !!hccV28Score) ? (
            <HStack>
              {!!hccV24Score && (
                <View
                  paddingX={1}
                  borderRadius={4}
                  backgroundColor={Colors.FoldPixel.GRAY50}
                  alignItems={'center'}
                >
                  {`${hccV24Score} ${!!hccV24ScoreDiff ? ` (${hccV24ScoreDiff})` : ''}`}
                </View>
              )}
              { !!hccV28Score && (
                <View
                  marginLeft={1}
                  paddingX={1}
                  borderRadius={4}
                  backgroundColor={Colors.FoldPixel.GRAY50}
                  alignItems={'center'}
                >
                  {`${hccV28Score} ${!!hccV28Scorediff ? ` (${hccV28Scorediff})` : ''}`}
                </View>
              )}
            </HStack>
            ) : (
            <Text>-</Text>
          )}
        </VStack>
        <Divider />
      </Fragment>
    )
  }

  const renderAWVDetails = () => {
    return (
      <Fragment key={'awv'}>
        <VStack marginY={1} display={'flex'}>
          <HStack>
            <Text fontWeight={400} color={Colors.FoldPixel.GRAY200} fontSize={14} marginBottom={1}>{intl.formatMessage({id: 'awvVisit'})}: </Text>
            {factState?.awvDetails?.isPending && getAwvPending()}
          </HStack>
            <Text marginLeft={1} color={Colors.FoldPixel.GRAY200} fontSize={12}>Last Visit: {!!factState?.awvDetails?.lastVisitDate ? factState?.awvDetails?.lastVisitDate : '-'}</Text>
        </VStack>
        <Divider />
      </Fragment>
    )
  }

  const renderAppointmentData = () => {
    return (!!appointmentData && (
      <Fragment key={'appt'}>
        <VStack marginY={1} display={'flex'}>
          <Text fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'upcomingAppointment'})}</Text>
          <VStack borderLeftColor={'#EEB200'} borderLeftWidth={2} paddingLeft={2}>
            <Text
              noOfLines={1}
              fontSize={14}
              color={Colors.FoldPixel.GRAY400}
              maxW={'80%'}
              isTruncated
              >
              {appointmentData?.appointmentType?.eventName}
            </Text>
            <Text
            color={Colors.FoldPixel.GRAY200}
            fontSize={12}
            >
              {upcomingAppointmentDateAndTime}
            </Text>
          </VStack>
        </VStack>
        <Divider />
      </Fragment>
    ))
  }

  const renderChronicConditions = () => {
    return (chronicConditions.length !== 0 && (
      <Fragment key={'conditions'}>
        <VStack marginY={1} display={'flex'}>
          <Text fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'chronicConditions'})}</Text>
          <VStack>
            {(chronicConditions).map((condition: ICondition, index: number) => {
              const {years, months} = getYearsAndMonthsSinceDate(condition?.onSetDateTime || '');
              return( <HStack>
                <Text wordBreak={'break-word'} color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={400}>
                {condition.name}
                {!!years && (
                  <Text color={Colors.FoldPixel.GRAY200}>
                    {` (${formatDuration(years, months)})`}
                  </Text>
                )}
                </Text>
              </HStack>);
            })}
          </VStack>
        </VStack>
        <Divider />
      </Fragment>
    ))
  }

  const renderCarePrograms = () => {
    return (carePrograms.length !== 0 && (
      <Fragment key={'careProgram'}>
        <VStack marginY={1} display={'flex'}>
          <Text fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY200} marginBottom={1}>{intl.formatMessage({id: 'careProgramEligibility'})}</Text>
          <VStack>
            {(carePrograms).map((careProgram: any, index: number) => {
              const {isDeclined, declinedAtStep} = useCareProgramDeclinedInfo(careProgram);
              return( <HStack key={index} alignItems={'center'}>
                {!isDeclined && (
                  <>
                    <Text color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={400} marginRight={1}>
                    {careProgram?.payerCareProgram?.careProgramType?.value}
                    </Text>
                    <CheckRoundIcon />
                  </>
                )}
              </HStack>);
            })}
          </VStack>
        </VStack>
        <Divider />
      </Fragment>
    ))
  }

  useEffect(() => {
    fetchFactDetails();
  }, []);

  return (
    <VStack>
      {renderPatientInfoCard()}
      {!factState.loading && (
        <Box
          maxHeight={400}
          maxWidth={290}
          overflowY="auto"
          overflowX="hidden"
          width="100%"
        >
          {renderFoldScore()}
          {renderExternalPatientId()}
          {renderHCCScores()}
          {renderAWVDetails()}
          {renderAppointmentData()}
          {renderChronicConditions()}
          {renderCarePrograms()}
          {renderContentFromUsedFacts()}
        </Box>
      )}
    </VStack>
  );
}

export default ContactPopoverContent;
