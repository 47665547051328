import {useLazyQuery} from '@apollo/client';
import { Dimensions, Platform, StyleSheet } from 'react-native';
import {Checkbox, Drawer, Pagination, Popover, Tooltip, Spin, DatePicker, notification } from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {HStack, View, VStack, Text, Divider, Spinner, useToast, Stack,Input, Icon, useMediaQuery } from 'native-base';
import {useContext, useEffect, useMemo, useState} from 'react';
import CommunicationLogQueries, { GET_DISTINCT_ERROR_MESSAGES, GET_ERROR_SPECIFIC_COUNT } from '../../../services/CommunicationLog/CommunicationLogQueries';
import CustomTabsView from '../CustomTabsView/CustomTabsView';
import {COMMUNICATION_LOG_TAB} from './CommunicationLogChart';
import AlphabetAvatar from '../Avatar/AlphabetAvatar';
import {DATE_FORMATS, GROUP_MEMBER_TYPE, PARENT_CODE} from '../../../constants';
import { Colors } from '../../../styles/Colors';
import { getDateStrFromFormat, getMomentObjFromFormat } from '../../../utils/DateUtils';
import { Pressable } from 'native-base';
import ActionButtonSvg from '../Svg/ActionButtonSvg';
import { styles } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/ActionsView/ActionsViewStyles';
import BaseService from '../../../services/CommonService/BaseService';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { getAccountId, getAccountUUID } from '../../../utils/commonUtils';
import Close from '../../../assets/svg/Close';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { DisplayText } from '../DisplayText/DisplayText';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import AddTaskSvg from '../Svg/SideCarSvg/AddTaskSvg';
import EditIconSvg from '../Svg/InterventionSvg/EditIconSvg';
import { AddOrUpdateLead } from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { PERSON_ACTION_CODES } from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import LeadQueries from '../../../services/Lead/LeadQueries';
import PatientSearchAndSelect from '../PatientSearch/PatientSearchAndSelect';
import moment from 'moment';
import SearchIconSvg from '../Svg/SearchMessageAllSvg/SearchIconSvg';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { ZapGradientIcon } from '../CircleIconView/CustomAutomationIcon';
import AutomationAnalyticsSvg from '../../../assets/svg/AutomationAnalyticsSvg';
import CampaignAnalyticsSvg from '../../../assets/svg/CampaignAnalyticsSvg';
import Feather from 'react-native-vector-icons/Feather';
import './CommunicationLogChart.css';
import EditUserWithBusinessHours from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/EditUserWithBusinessHours';
import { COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES } from '../../../constants/ActionConst';
import { IUsersResponse } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import UserQueries from '../../../services/User/UserQueries';
import AddEditUser from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUser';
import { ILoginUserData } from '../../../Interfaces';
import NewSearchIconSvg from '../../../assets/svg/NewSearchIconSvg';
import { SearchBar } from '../SearchBar';
import CrossIconSvg from '../Svg/CrossIconSvg';
import MessagingContactDetailsDrawer from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import RetrySvg from '../../../assets/svg/RetrySvg';

const { RangePicker } = DatePicker;
const COMMUNICATION_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
} as const;

interface selectedMemberFilter {
  email?: string;
  label?: string;
  key?: string
}
interface FiltersState {
  selectedFailingReasonFilter: string[] | null;
  selectedDateRange: string[] | null;
  sms: {
    inputValue: string;
    showSearch: boolean;
    selectedMemberFilter: selectedMemberFilter | null;
  };
  email: {
    inputValue: string;
    showSearch: boolean;
    selectedMemberFilter: selectedMemberFilter | null;
  };
}

const MemberListByCommunicationType = (props: {
  communicationType: string;
  originService: string;
  typeId?: string;
  startDate?: string;
  endDate?: string;
  total: number;
  type:'sms' | 'email';
  selectedFailingReasonFilter: string[];
}) => {
  const [state, setState] = useState({
    pageSize: 10,
    pageNo: 0,
    loading: false,
    communicationLogs: [],
  });
  const [ids, setIds] = useState<any[]>([]);
  const [multipleResendLoading, setMultipleResendLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const baseService = BaseService.getSharedInstance().axios;
  const accountUUID = getAccountUUID();
  const toast = useToast();
  const intl = useIntl();
  const [filters, setFilters] = useState<FiltersState>({
    selectedFailingReasonFilter: props?.selectedFailingReasonFilter || [],
    selectedDateRange: [props?.startDate || '',props?.endDate || ''],
    sms: {
      inputValue: '',
      showSearch: false,
      selectedMemberFilter: null
    },
    email: {
      inputValue: '',
      showSearch: false,
      selectedMemberFilter: null
    }
  });
  const screenHeight = Dimensions.get('window').height;

  useMemo(() => {
    setFilters((prev: any) => {
      return { ...prev, selectedFailingReasonFilter: [] }
    })
  }, [props.type]);

  const [getFailedCommunicationLogData] = useLazyQuery(
    CommunicationLogQueries.GET_FAILED_LOG,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const toggleId = (id: any) => {
    setIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };
  const clearIds = () => {
    setIds([]);
  }
  const onCompleteLoad = (response: any) => {
    setState((oldData) => {
      return {
        ...oldData,
        communicationLogs: response?.data?.communicationLogs || [],
        loading: false,
      };
    });
  };
  const setLoading = (loading: boolean) => {
    setState((oldData) => {
      return { ...oldData, loading };
    });
  };
  const failedStatusCodeList = ['failed', 'undelivered'];
  const getFailedCommunicationLogDataByWorkflowMasterId = () => {
    const variables = {
      limit: state.pageSize,
      offset: state.pageNo * state.pageSize,
      communicationLogBoolExp: {
        createdAt: {
          _gte: filters?.selectedDateRange?.length ?  filters?.selectedDateRange[0] : props.startDate,
          _lte: filters?.selectedDateRange?.length ?  filters?.selectedDateRange[1] : props.endDate
        },
        originService: { _eq: 'WORKFLOW' },
        communicationType: {
          _eq: props.communicationType,
        },
        communicationStatus: {
          status: {
            _in: failedStatusCodeList,
          },
        },

        communicationTags: {
          tag: {
            _eq: props.typeId,
          },
          tagType: { _eq: 'workflowMasterId' },
        },
        ...(filters.selectedFailingReasonFilter && filters.selectedFailingReasonFilter.length > 0 && {
          communicationStatus: {
            communicationErrorInfo: {
              code: {
                _in: filters.selectedFailingReasonFilter,
              }
            }
          },
        }),
        ...(filters[props.type]?.selectedMemberFilter?.label && {
          _or: [
            {
              receiverContact: {
                name: { _ilike: `%${filters[props.type].selectedMemberFilter?.label}%` },
              },
            },
            {
              receiverUser: {
                name: { _ilike: `%${filters[props.type].selectedMemberFilter?.label}%` },
              },
            },
          ],
        }),
        ...((filters?.sms?.showSearch && filters?.sms?.inputValue && props.type == 'sms')  && {
          _or: [
            {
              receiverContact: {
                email: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
            {
              receiverUser: {
                email: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
            {
              receiverContact: {
                phoneNumber: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
          ],
        }),
        ...((filters?.email?.showSearch && filters?.email?.inputValue && props.type == 'email')  && {
          _or: [
            {
              receiverContact: {
                email: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
            {
              receiverUser: {
                email: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
            {
              receiverContact: {
                phoneNumber: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
          ],
        }),
      },
    };
    setLoading(true);
    getFailedCommunicationLogData({
      variables: variables,
    }).then((response) => {
      onCompleteLoad(response);
    });
  };

  const handleResend = async (key?: any) => {
    setMultipleResendLoading(true);
 
    const url = `/crm-nest/communication-log/resend`;
    const data = {
      communicationLogIds: key ? [key] : ids,
    };
    const headers = {
      accountId: accountUUID,
    };
    await baseService
      .post(url, data, { headers: headers })
      .then((response) => {
        notification.success({
          message: intl.formatMessage({
            id: 'resendSuccess'
          }),
          duration: 3.0,
          placement: 'top'
        });
        setMultipleResendLoading(false);
        setIds([]);
      })
      .catch((error) => {
        setMultipleResendLoading(false);
        setIds([]);
        showToast(toast, intl.formatMessage({ id: 'errorMsg' }), ToastType.error);
      });
  };
  const getFailedCommunicationLogDataByCampaignId = () => {
    const variables = {
      limit: state.pageSize,
      offset: state.pageNo * state.pageSize,
      communicationLogBoolExp: {
        createdAt: {
          _gte: filters?.selectedDateRange?.length ?  filters?.selectedDateRange[0] : props.startDate,
          _lte: filters?.selectedDateRange?.length ?  filters?.selectedDateRange[1] : props.endDate
        },
        originService: { _eq: 'WORKFLOW' },
        communicationType: {
          _eq: props.communicationType,
        },
        communicationStatus: {
          status: {
            _in: failedStatusCodeList,
          },
        },

        communicationTags: {
          tag: {
            _eq: props.typeId,
          },
          tagType: { _eq: 'campaignId' },
        },
        ...(filters.selectedFailingReasonFilter && filters.selectedFailingReasonFilter.length > 0 && {
          communicationStatus: {
            communicationErrorInfo: {
              code: {
                _in: filters.selectedFailingReasonFilter,
              }
            }
          },
        }),
        ...(filters[props.type].selectedMemberFilter?.label && {
          _or: [
            {
              receiverContact: {
                name: { _ilike: `%${filters[props.type].selectedMemberFilter?.label}%` },
              },
            },
            {
              receiverUser: {
                name: { _ilike: `%${filters[props.type].selectedMemberFilter?.label}%` },
              },
            },
          ],
        }),
        ...((filters?.sms?.showSearch && filters?.sms?.inputValue && props.type == 'sms')  && {
          _or: [
            {
              receiverContact: {
                email: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
            {
              receiverUser: {
                email: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
            {
              receiverContact: {
                phoneNumber: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
          ],
        }),
        ...((filters?.email?.showSearch && filters?.email?.inputValue && props.type == 'email')  && {
          _or: [
            {
              receiverContact: {
                email: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
            {
              receiverUser: {
                email: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
            {
              receiverContact: {
                phoneNumber: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
          ],
        }),
      },
    };
    setLoading(true);
    getFailedCommunicationLogData({
      variables: variables,
    }).then((response) => {
      onCompleteLoad(response);
    });
  };

  const getFailedCommunicationLogDataForOther = () => {
    const variables = {
      limit: state.pageSize,
      offset: state.pageNo * state.pageSize,
      communicationLogBoolExp: {
        createdAt: {
          _gte: filters?.selectedDateRange?.length ?  filters?.selectedDateRange[0] : props.startDate,
          _lte: filters?.selectedDateRange?.length ?  filters?.selectedDateRange[1] : props.endDate
        },
        _or: [
          { originService: { _neq: 'WORKFLOW' } },
          { originService: { _is_null: true } },
        ],
        communicationType: {
          _eq: props.communicationType,
        },
        communicationStatus: {
          status: {
            _in: failedStatusCodeList,
          },
        },
        ...(filters.selectedFailingReasonFilter && filters.selectedFailingReasonFilter.length > 0 && {
          communicationStatus: {
            communicationErrorInfo: {
              code: {
                _in: filters.selectedFailingReasonFilter,
              }
            }
          },
        }),
        ...(filters[props.type]?.selectedMemberFilter?.label && {
          _or: [
            {
              receiverContact: {
                name: { _ilike: `%${filters[props.type]?.selectedMemberFilter?.label}%` },
              },
            },
            {
              receiverUser: {
                name: { _ilike: `%${filters[props.type]?.selectedMemberFilter?.label}%` },
              },
            },
          ],
        }),
        ...((filters?.sms?.showSearch && filters?.sms?.inputValue && props.type == 'sms')  && {
          _or: [
            {
              receiverContact: {
                email: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
            {
              receiverUser: {
                email: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
            {
              receiverContact: {
                phoneNumber: { _ilike: `%${filters?.sms?.inputValue}%` },
              },
            },
          ],
        }),
        ...((filters?.email?.showSearch && filters?.email?.inputValue && props.type=='email')  && {
          _or: [
            {
              receiverContact: {
                email: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
            {
              receiverUser: {
                email: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
            {
              receiverContact: {
                phoneNumber: { _ilike: `%${filters?.email?.inputValue}%` },
              },
            },
          ],
        }),
      },
    };
    setLoading(true);
    getFailedCommunicationLogData({
      variables: variables,
    }).then((response) => {
      onCompleteLoad(response);
    });
  };

  useEffect(() => {
    if (props.originService == COMMUNICATION_LOG_TAB.AUTOMATION.KEY) {
      getFailedCommunicationLogDataByWorkflowMasterId();
    } else if (props.originService == COMMUNICATION_LOG_TAB.CAMPAIGN.KEY) {
      getFailedCommunicationLogDataByCampaignId();
    } else if (props.originService == COMMUNICATION_LOG_TAB.OTHER.KEY) {
      getFailedCommunicationLogDataForOther();
    }
  }, [
    props.communicationType,
    props.originService,
    props.typeId,
    props.startDate,
    props.endDate,
    state.pageNo,
    state.pageSize,
    filters,
    props.type,
  ]);

  const handleSearchInputChange = (value:string, type:string) => {
    setFilters((prev:any) => ({
      ...prev,
      [type]: {
        ...prev[type],
        inputValue: value
      }
    }));
  };

  const toggleSearch = (type:string) => {
    setFilters((prev:any) => ({
      ...prev,
      [type]: {
        ...prev[type],
        showSearch: !prev[type].showSearch
      }
    }));
  };

  const isSearchOpen = filters.sms.showSearch || filters.email.showSearch;

  const handleSearchIconPress = () => {
    if (filters[props.type].showSearch) {
      handleClearSearch();
      toggleSearch(props.type);
    } else {
      toggleSearch(props.type);
    }
  };
  
  const handleClearSearch = () => {
    handleSearchInputChange("", props.type);
  };
  
  const handleSearchChange = (text: string) => {
    handleSearchInputChange(text, props.type);
  };
  
  return (
    <>
      <HStack alignItems={"center"}
        space={1.7}
        flexWrap={'wrap'}
        gap={10}
        style={{ marginBottom: isSearchOpen ? '10px' : '3px' }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1.5 }}>
          {!filters[props.type].showSearch ? (
            <Tooltip title={`Search`}>
              <Pressable onPress={handleSearchIconPress}>
                <View style={{ paddingRight: 4 }}>
                  <NewSearchIconSvg />
                </View>
              </Pressable>
            </Tooltip>
          ) : (
            <SearchBar
              height={8}
              width={'100%'}
              searchBarInputStyles={{ backgroundColor: '#FFFFFF' }}
              placeholderText={'Search by email or contact number'}
              borderRadius={8}
              onChange={handleSearchChange}
              leftElement={
                <View margin={3}>
                  <SearchIconSvg
                    size={18}
                    customStrokeColor={Colors.FoldPixel.GRAY200}
                  />
                </View>
              }
              rightElement={
                <Pressable
                  height={'40px'}
                  width={'40px'}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onPress={handleSearchIconPress}
                >
                  <CrossIconSvg customStrokeColor={Colors.FoldPixel.GRAY300} size={20} />
                </Pressable>
              }
            />
          )}
          {!filters[props.type].showSearch && (
            <>
              <FilterWrapperComponent
                label='Members'
                filterType='members'
                memberVal={filters[props.type].selectedMemberFilter || null}
                onOptionSelectForMembers={(value: selectedMemberFilter) => setFilters((prev) => ({
                  ...prev,
                  [props.type]: {
                    ...prev[props.type],
                    selectedMemberFilter: value
                  }
                }))}
              />
              <FilterWrapperComponent
                label='Reason for Failure'
                filterType='failingReason'
                onOptionSelect={(value: string[]) => setFilters(prev => ({ ...prev, selectedFailingReasonFilter: value }))}
                type={props.type}
                typeId={props?.typeId}
                startDate={props?.startDate}
                endDate={props?.endDate}
              />
              <FilterWrapperComponent
                label='Date'
                filterType='date'
                onDateChange={(value: string[]) => setFilters(prev => ({ ...prev, selectedDateRange: value }))}
                startDate={props.startDate}
                endDate={props.endDate}
              />
            </>
          )}
        </View>
      </HStack>
      <VStack height={screenHeight - 320} position={'sticky'}>
        <View  >
          <HStack flex={1} paddingY={2}>
            <View flex={0.05} alignItems={'center'}>
            </View>
            <Text
              flex={0.37}
              fontSize={14}
              fontWeight={500}
              style={{ color: '#6F7A90' }}
            >
              {'Member List'}
            </Text>
            <Text
              flex={0.4}
              fontSize={14}
              fontWeight={500}
              style={{ color: '#6F7A90' }}
            >
              {'Reason for Failure'}
            </Text>
          </HStack>
          <Divider
            style={{ width: '100%', backgroundColor: Colors.Custom.Gray200 }}
          />
        </View>
        <VStack height={'650px'} flex={1} paddingBottom={2} overflow={'auto'}>
          {state.loading ? (
            <View justifyContent="center" padding={8} flex={1}>
              <Spinner size={'lg'} />
            </View>
          ) : (
            <View overflowY={'auto'} >
              {state.communicationLogs.map((communicationLog: any) => {
                return (
                  <>
                    <TableCell
                      communicationLog={communicationLog}
                      ids={ids}
                      toggleId={toggleId}
                      handleResend={handleResend}
                    ></TableCell>
                    <Divider
                      style={{
                        width: '100%',
                        backgroundColor: Colors.Custom.Gray200,
                      }}
                    />
                  </>
                );
              })}
            </View>
          )}
        </VStack>
      </VStack>
      <Pagination
        className='wrapper'
        total={props.total}
        current={state.pageNo + 1}
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
        showSizeChanger={false}
        pageSize={state.pageSize}
        onChange={(pageNo, pageSize) => {
          setState((prev) => ({
            ...prev,
            pageNo: pageNo - 1,
            pageSize: pageSize,
          }));
        }}
      />
      {ids.length ? (
        <ResendMultiple
          ids={ids}
          handleResend={handleResend}
          clearIds={clearIds}
          multipleResendLoading={multipleResendLoading}
        ></ResendMultiple>
      ) : null}
    </>
  );
};

type CommunicationType =
  typeof COMMUNICATION_TYPE[keyof typeof COMMUNICATION_TYPE];

  type CommunicationErrorCode = {
    message: string;
    code: string;
    FailedCommunications_aggregate: {
      aggregate: {
        count: number;
      };
    };
  };
  
  type QueryData = {
    communicationErrorCodes: CommunicationErrorCode[];
  };
  


  const FilterWrapperComponent = (props: {
    label?: string;
    filterType: 'members' | 'failingReason' | 'date';
    marginBottom?: number;
    onOptionSelect?: (selectedOptions: string[]) => void;
    onOptionSelectForMembers?: (selectedOptions: any) => void;
    onDateChange?: (dateStrings: string[]) => void;
    onClose?: () => void;
    startDate?: string;
    endDate?: string;
    type?: string;
    typeId?: string;
    memberVal?: any;
  }) => {
    const [showChild, setShowChild] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState([props?.startDate ?? '', props?.endDate ?? '']);
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [limit] = useState(10);
    const [optionData, setOptionData] = useState([]);

    const [fetchErrorMessages, { data, loading, error }] = useLazyQuery(GET_ERROR_SPECIFIC_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.communicationErrorCodes) {
          const newOptions = data.communicationErrorCodes.map((item: {
            message: string,
            code: string,
            failedCommunicationAggregate: any
          }) => ({
            message: item.message,
            code: item.code,
            count: item.failedCommunicationAggregate?.aggregate?.count
          }));
    
          const filteredNewOptions = newOptions.filter((option: { message: string, code: string }) => !selectedOptions.includes(option.message));
          setOptionData((prevOptions) => (searchQuery ? newOptions : [...prevOptions, ...filteredNewOptions]));
          setOptions((prevOptions) => (searchQuery ? newOptions : [...filteredNewOptions]));
          setHasMore(data.communicationErrorCodes.length >= limit);
        }
      },
    });

    const communicationService = props.type === 'sms' 
    ? 'TWILIO' 
    : props.type === 'email' 
    ? 'MAILGUN' 
    : undefined;

    useEffect(() => {
      if (props.filterType === 'failingReason') {
        setSearchQuery('');
        setSelectedOptions([]);
        const whereClause = {
          _and: [
            {
              message: {
                _ilike: searchQuery ? `%${searchQuery}%` : '',
              },
            },
            {
              message: {
                _is_null: false,
              },
            },
            ...(communicationService ? [
              {
                communication_service: {
                  _eq: communicationService,
                },
              },
            ] : []),
          ],
        };

        const variables = {
          where: whereClause,
          tag: props?.typeId,
          communicationType: communicationService,
          startDate: props?.startDate,
          endDate: props?.endDate,
          limit: searchQuery ? 10 : limit,
          offset: 0,
        };

        fetchErrorMessages({ variables });
      }
    }, [props.filterType,searchQuery, communicationService, limit]);

    useEffect(() => {
      if (props.memberVal) {
        setShowChild(false);
      }
    }, [props.memberVal]);

    useEffect(() => {
      setSelectedDateRange([props.startDate ?? '', props.endDate ?? '']);
    }, [props.startDate, props.endDate]);

    const handleOptionSelect = (option:[]) => {
      setSelectedOptions((prev:any) => {
        const newSelectedOptions = prev.includes(option)
          ? prev.filter((item:any) => item !== option)
          : [...prev, option];
        const selectedCodes = optionData
          .filter((item:{message:string,code:string}) => newSelectedOptions.includes(item.message))
          .map((item:any) => item.code);
        props?.onOptionSelect?.(selectedCodes);
        return newSelectedOptions;
      });
    };

    const handleDateChange = (date:any,dateStrings: string[]) => {
      if (dateStrings[0] === '' && dateStrings[1] === '') {
        setSelectedDateRange([props.startDate ?? '', props.endDate ?? '']);
      } else {
        setSelectedDateRange(dateStrings);
      }
      props?.onDateChange?.(dateStrings);
    };

    const filteredOptions = options.filter((option:any) => option.message.toLowerCase().includes(searchQuery.toLowerCase()));
    const combinedOptions = [...selectedOptions, ...filteredOptions.filter(option => !selectedOptions.includes(option))];

    const renderSelectedOptions = () => {
      if (props.filterType === 'members' && props.memberVal) {
        return `${props.memberVal.label.slice(0, 3)}...`;
      }
      if (props.filterType === 'date' && selectedDateRange) {
        return `${moment(selectedDateRange[0]).format('YYYY-MM-DD')} - ${moment(selectedDateRange[1]).format('YYYY-MM-DD')}`;
      }
      if (selectedOptions.length === 0 && props.filterType !== 'date') return 'All';
      if (selectedOptions.length <= 1) {
        const option: string = selectedOptions[0];
        if (option.length > 0) {
          return `${option.slice(0, 10)}...`;
        }
        return option;
      }
      return `${selectedOptions[0]?.slice(0, 10)}... + ${selectedOptions.length - 1}`;
    };

    const getRangePickerDefaultValue = (): any => {
      let defaultValue: any[] = [];
      if (selectedDateRange[0] && selectedDateRange[1]) {
        defaultValue = [
          getMomentObjFromFormat(
            selectedDateRange[0],
            DATE_FORMATS.NEXT_PREV_MESSAGES_DATE_FORMAT
          ),
          getMomentObjFromFormat(
            selectedDateRange[1],
            DATE_FORMATS.NEXT_PREV_MESSAGES_DATE_FORMAT
          ),
        ];
      } else {
        defaultValue = [];
      }
      return defaultValue;
    };


  return (
    <>
      {props.filterType === 'date' ? (
        <RangePicker
          showTime
          onChange={handleDateChange}
          value={getRangePickerDefaultValue()}
          style={{
            fontSize: '12px',
            width: '40%',
            height: '40px'
          }}
        />
      ) : (
        <Popover overlayClassName='communicationlog-popover'
          content={
            <View style={[componentStyles.dropdownContent, { width: 350, maxHeight: 400 }]}>
              {props.filterType === 'failingReason' && (
                <>
                  <Input
                    value={searchQuery}
                    onChange={(e: any) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    style={componentStyles.searchInput}
                  />
                  {loading ? (
                    <Spin />
                  ) : (
                    <>
                      {error && <Text>Error: {error.message}</Text>}
                      <View style={componentStyles.optionsContainer}>
                        {combinedOptions
                          .filter((option: any) => option.count > 0)
                          .sort((option1: any, option2: any) => option2.count - option1.count)
                          .slice(0, limit)
                          .map((option: any) => (
                            <Pressable
                              key={option.message}
                              onPress={() => handleOptionSelect(option.message)}
                              style={[
                                componentStyles.option,
                                selectedOptions.includes(option.message) && { backgroundColor: Colors.Custom.Gray200 }
                              ]}
                            >
                              <Text>{option.message} ({option.count})</Text>
                            </Pressable>
                          ))}
                      </View>
                      {!searchQuery && combinedOptions.filter((option: any) => option.count > 0).length > limit && (
                        <Text style={{ color: Colors.Custom.Primary300, fontWeight: 'bold' }}>Showing the first {limit} results. Refine your search for more results.</Text>
                      )}
                    </>
                  )}
                </>
              )}
              {props.filterType === 'members' && (
                <PatientSearchAndSelect
                  label={'channelPatientOrMember'}
                  value={props.memberVal?.label ? `${props.memberVal?.label} (${props.memberVal.email ? `${props.memberVal.email}, ` : 'No email, '})` : ''}
                  showEmail={false}
                  isProspect={false}
                  disableUserSearch={false}
                  isDisabled={false}
                  placeholder="Search Member or Staff"
                  disableLeadsSearch={true}
                  onChange={(participant: any, data: any) => {
                    props?.onOptionSelectForMembers?.(data);
                  }}
                />
              )}
            </View>
          }
          trigger="click"
          placement="bottomLeft"
          open={showChild}
          onOpenChange={(visible) => {
            setShowChild(visible);
            if (!visible) {
              props?.onClose?.();
            }
          }}
        >
          <Pressable
            onPress={() => setShowChild(!showChild)}
            style={[componentStyles.filterButton, { marginBottom: props.marginBottom }]}
          >
            <HStack alignItems="center" space={2}>
              <Text style={componentStyles.label}>
                {props.label ? `${props.label}: ` : ''}
              </Text>
              <Text style={componentStyles.selectedText}>
                {renderSelectedOptions()}
              </Text>
              <AntIcon name="down" color={Colors.Custom.Gray400} size={12} />
            </HStack>
          </Pressable>
        </Popover>
      )}
    </>
  );
};    


const componentStyles = StyleSheet.create({
  filterButton: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.Custom.Gray200,
    backgroundColor: Colors.Custom.ContainerBGColor,
    minHeight: 40,
    marginRight: 8,
    paddingHorizontal: 10,
    paddingVertical: 8,
    minWidth: 'auto',
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: 14,
    color: "#6F7A90",
  },
  selectedText: {
    fontSize: 14,
  },
  dropdownContent: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    padding: 5,
    borderTopWidth: 0,
    maxHeight: 200,
    overflowY: 'auto',
    backgroundColor: 'white',
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    borderRadius: 5,
    zIndex: 1000,
  },
  searchInput: {
    padding: 10,
    borderBottomWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  optionsContainer: {
    maxHeight: 150,
    overflowY: 'auto',
  },
  option: {
    padding: 10,
  },
});

const CommunicationLogChartMemberList = (props:any) => {
  const [state, setState] = useState({
    currentSelectTap: 'SMS'
  });

  useEffect(() => {
  }, [props.communicationTypeList]);

  return (
    <Drawer
      width={800}
      open={props.open}
      onClose={() => props.setIsOpen(false)}
      bodyStyle={{overflow: 'hidden'}}
      title={
        <>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px'}}>
            <ModalActionTitle
              title={props?.originService == COMMUNICATION_LOG_TAB.OTHER.KEY ? 'Failed Email' : `${props?.originService} Error`}
              style={{ padding: 8 }}
            />
            <Pressable onPress={() => props?.setIsOpen(false)}>
              <Feather
                name='x'
                size={24}
                color={Colors.FoldPixel.GRAY300}
              />
            </Pressable>
          </View>

          {props?.originService !== COMMUNICATION_LOG_TAB.OTHER.KEY && (
            <View
              flex={1}
              flexDirection={'row'}
              gap={'8px'}
              borderRadius={'8px'}
              style={{
                borderColor: '#D0D6E1',
                borderWidth: 1,
                backgroundColor: '#F6F7F8',
                width: '100%',
                height: '53px',
                marginBottom: '-35px',
                padding: '8px',
              }}
            >
              <View paddingY={2} alignSelf={'center'} flex={8}>
              <HStack alignItems={'center'} space={1}>
                {props?.originService === COMMUNICATION_LOG_TAB.AUTOMATION.KEY ? (
                  <View style={{ width: 20, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AutomationAnalyticsSvg />
                  </View>
                ) : props?.originService === COMMUNICATION_LOG_TAB.CAMPAIGN.KEY ? (
                  <View style={{ width: 20, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CampaignAnalyticsSvg />
                  </View>
                ) : null}
                <Text fontSize={14} color={'#000000'}>
                  {props?.errorTitle}
                </Text>
              </HStack>
               
                {props?.originService === COMMUNICATION_LOG_TAB.AUTOMATION.KEY ? (
                  <Text
                  style={{
                    fontSize: 12,
                    color: '#8A94A8',
                    marginTop: -1
                  }}
                >
                  Created by {props?.userName}
                </Text>
            
                ) : null}
              </View>
            </View>
          )}
        </>
      }
    >
      <View style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <VStack height={'85vh'} position={'sticky'} top={0} zIndex={1000} background='#fff'>
          <CustomTabsView
            borderBottomWidth={2}
            onTabClick={(tab) => {
              setState((oldValue) => {
                return { ...oldValue, currentSelectTap: tab?.key };
              });
            }}
            
            tabsList={(props.communicationTypeList || []).map(
              (communicationType: string) => {
                const type = communicationType === COMMUNICATION_TYPE.EMAIL ? 'email' : 'sms';
                return {
                  key: communicationType,
                  title: communicationType === COMMUNICATION_TYPE.EMAIL 
                          ? 'Email' 
                          : communicationType.toUpperCase(),
                  count: type === 'email' ? props.emailCount : props.smsCount,
                  tabContainerElem: () => {
                    return (
                      <VStack padding={5}>
                        <MemberListByCommunicationType
                          communicationType={communicationType}
                          originService={props.originService}
                          typeId={props.typeId}
                          startDate={props.startDate}
                          endDate={props.endDate}
                          total={type === 'email' ? props.emailCount : props.smsCount}
                          type={type}
                          selectedFailingReasonFilter={[]}
                        />
                      </VStack>
                    );
                  },
                };
              }
            )}
            tabListWrapperStyle={{ marginHorizontal: 8 }}
          />
        </VStack>
      </View>
    </Drawer>
  );
};

const ResendMultiple = (props: {
  ids: any;
  handleResend: any;
  clearIds: any;
  multipleResendLoading :boolean
}) => {
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData.sidecarContext?.isSidecar;
  return (
    <>
      <div
        className={
          isSidecar
            ? 'sidecar-modal-container'
            : 'modal-container-communicationLog'
        }
        style={{
          width: '15%',
          height: '60px'
        }}
      >
        <Stack
          direction="row"
          style={{ alignItems: 'center', justifyContent: 'center', marginTop: -5}}
        >
          <Checkbox checked></Checkbox>
          <Text
            style={{ fontSize: 13, color: Colors.Custom.Gray500 }}
            paddingLeft={2}
          >
            {' '}
            {props.ids.length} selected
          </Text>
          <View
            style={{
              width: 1,
              height: 24,
              backgroundColor: Colors.Custom.Gray300,
              marginHorizontal: 10,
            }}
          />
          <Stack direction="row" space={2}>
            <Pressable
              onPress={() => {
                props.handleResend();
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.primary[300],
                  height: '32px',
                  width: '68px',
                  justifyContent: 'center',
                  paddingHorizontal: '8px',
                  borderRadius: 4,
                }}
              >{props.multipleResendLoading ?
                <Spinner></Spinner> :
                <DisplayText
                  textLocalId={'Resend'}
                  size={'smBold'}
                  extraStyles={{
                    color: 'white',
                  }}
                />}
              </View>
            </Pressable>
          </Stack>
          <View
            style={{
              width: 1,
              height: 24,
              backgroundColor: Colors.Custom.Gray300,
              marginHorizontal: 10,
            }}
          />
          <Pressable onPress={props.clearIds}>
            <Close fill={Colors.Custom.Gray500} size={20} />
          </Pressable>
        </Stack>
      </div>
    </>
  );
};

const TableCell = (props: {
  communicationLog: any;
  ids: any;
  toggleId: any;
  handleResend: any;
  // onActionPerformed: (actionCode: string, selectedData: unknown) => void;
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [stateData, setStateData] = useState({
    showEditDrawer: false as boolean,
    visible: false,
    isDeceased: false,
    deceasedDate: ''
  });
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  const accountId = userData.account_id || '';
  const [contactInfo, setContactInfo] = useState<IContact>();
  const [userEditData, setUserEditData] = useState({});
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const [getContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (props.communicationLog?.receiverUser?.id) {
      const userId = props.communicationLog.receiverUser.id;
    }
  }, [props.communicationLog?.receiverUser?.id]);

  useEffect(() => {
    if (props.communicationLog?.receiverContact?.id) {
      getContact({
        variables: {
          id: props.communicationLog.receiverContact.id,
        },
      }).then((resp) => {
        if (resp?.data) {
          setContactInfo(resp.data.contact);
        } else {
          setContactInfo({} as IContact);
        }
      });
    }
  }, [props.communicationLog?.receiverContact?.id]);

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    switch (actionCode) {
      case PERSON_ACTION_CODES.CANCEL:
        setStateData({ ...stateData, showEditDrawer: false });
        break;
      default:
        setStateData({ ...stateData, showEditDrawer: false });
        break;
    }
  };

  const handlePopover = () => {
    setShowPopover(!showPopover);
  };

  const handleClose = (actionCode: string | undefined) => {
    if (actionCode) {
      onViewChangeActionPerformed(actionCode);
    }
    handlePopover();
    setStateData((old) => {
      return { ...old, showEditDrawer: false }
    })
  };

  const handleDrawerClose = (actionCode: string | undefined) => {
    if (actionCode) {
      onViewChangeActionPerformed(actionCode);
    }
    setIsDrawerVisible(false);
    setSelectedRow(null);
  };

  const handlePatientViewClick = () => {
    setSelectedRow(props.communicationLog);
    setIsDrawerVisible(true);
  };

  const onCallAction = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.CALL, rowData);
  };
  const onSmsAction = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.SMS, rowData);
  };
  const onChatAction = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.CHAT, rowData);
  };
  const onMailAction = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.MAIL, rowData);
  };
  const onMergeContactAction = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.MERGE, rowData);
  };
  const onAddRelationAction = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.ADD_RELATION, rowData);
  };
  const onConvertTo = (rowData: any, actionCode: any) => {
    onActionPerformed(actionCode, rowData);
  };
  const onSetTag = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.SET_TAG, rowData);
  };

  const onDeactivate = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.DEACTIVATE, rowData);
  };

  const onEditLead = (rowData: any) => {
    onActionPerformed(COMMON_ACTION_CODES.EDIT, rowData);
  };

  const onActionPerformed = (actionCode: string, rowData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onEditLead(rowData);
        break;
      case COMMON_ACTION_CODES.CALL:
        onCallAction(rowData);
        break;
      case COMMON_ACTION_CODES.SMS:
        onSmsAction(rowData);
        break;
      case COMMON_ACTION_CODES.CHAT:
        onChatAction(rowData);
        break;
      case COMMON_ACTION_CODES.MAIL:
        onMailAction(rowData);
        break;
      case COMMON_ACTION_CODES.MERGE:
        onMergeContactAction(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_RELATION:
        onAddRelationAction(rowData);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        onConvertTo(rowData, actionCode);
        break;
      case COMMON_ACTION_CODES.SET_TAG:
        onSetTag(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE, rowData);
        break;
      case COMMON_ACTION_CODES.RESET_PASSWORD:
        onActionPerformed(COMMON_ACTION_CODES.RESET_PASSWORD, rowData);
        break;
      case COMMON_ACTION_CODES.SEND_FORMS:
        onActionPerformed(COMMON_ACTION_CODES.SEND_FORMS, rowData);
        break;
      case COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE:
        onActionPerformed(
          COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.CREATE_TASK:
        onActionPerformed(COMMON_ACTION_CODES.CREATE_TASK, rowData);
        break;
      case COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        onActionPerformed(
          COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT:
        onActionPerformed(
          COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK:
        onActionPerformed(
          COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        onActionPerformed(
          COMMON_ACTION_CODES.CREATE_AUTOMATION,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        onActionPerformed(COMMON_ACTION_CODES.ON_BULK_ACTION, rowData);
        break;
      case COMMON_ACTION_CODES.DEACTIVATE:
        onDeactivate(rowData);
        break;
      case COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM:
        onActionPerformed(
          COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL:
        onActionPerformed(
          COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.ECM_OUTREACH_VIEW:
        onActionPerformed(COMMON_ACTION_CODES.ECM_OUTREACH_VIEW, rowData);
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT:
        onActionPerformed(COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT, rowData);
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT:
        onActionPerformed(COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT, rowData);
        break;
      case COMMON_ACTION_CODES.ACTIONABLE_TASK_VIEW:
        onActionPerformed(COMMON_ACTION_CODES.ACTIONABLE_TASK_VIEW, rowData);
        break;
    }
  };

  return (
    <>
      <HStack paddingY={4} flex={1.1}>
        <View flex={0.06} justifyContent={'center'} alignItems={'center'}>
          <Checkbox
            key={props?.communicationLog?.id}
            checked={props.ids.includes(props?.communicationLog?.id)}
            onChange={() => {
              props.toggleId(props.communicationLog?.id);
            }}
          ></Checkbox>
        </View>
        <View flex={0.4}>
          <HStack>
            {props.communicationLog?.receiverResourceType === 'CONTACT' ? (
              <Pressable onPress={handlePatientViewClick}>
                <AlphabetAvatar
                  size={'12'}
                  style={{
                    alignItems: 'center',
                    borderRadius: 6,
                    borderWidth: 1,
                  }}
                  disableTop
                  name={props.communicationLog?.receiverContact?.name}
                  textStyle={{
                    fontSize: 18,
                    textAlign: 'center',
                    padding: 1,
                  }}
                  userType={props.communicationLog?.receiverResourceType}
                />
              </Pressable>
            ) : (
              <AlphabetAvatar
                size={'12'}
                style={{
                  alignItems: 'center',
                  borderRadius: 6,
                  borderWidth: 1,
                }}
                disableTop
                name={props.communicationLog?.receiverUser?.name}
                textStyle={{
                  fontSize: 18,
                  textAlign: 'center',
                  padding: 1,
                }}
                userType={props.communicationLog?.receiverResourceType}
              />
            )}
            <VStack alignContent={'center'} ml={2} justifyContent={'center'}>
              <Tooltip title={props.communicationLog?.receiverResourceType === 'CONTACT'
                ? props.communicationLog?.receiverContact?.name
                : props.communicationLog?.receiverUser?.name}>
                {props.communicationLog?.receiverResourceType === 'CONTACT' ? (
                  <Pressable onPress={handlePatientViewClick}>
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      noOfLines={1}
                      style={{ color: 'black' }}
                    >
                      {props.communicationLog?.receiverContact?.name}
                    </Text>
                  </Pressable>
                ) : (
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    noOfLines={1}
                    style={{ color: 'black' }}
                  >
                    {props.communicationLog?.receiverUser?.name}
                  </Text>
                )}
              </Tooltip>

              <Text fontSize={12} fontWeight={400} paddingTop={1}>
                <Text style={{ color: '#A7A7A7' }}>{'Sent on: '}</Text>
                <Text style={{ color: '#A7A7A7', marginLeft: 0.5 }}>
                  {getDateStrFromFormat(
                    props.communicationLog.createdAt,
                    DATE_FORMATS.MESSAGE_DATE_FORMAT
                  )}
                </Text>
              </Text>
            </VStack>
          </HStack>
        </View>
        <View flex={0.54} justifyContent={'center'} alignItems={'start'}>
          <Text
            fontSize={14}
            fontWeight={400}
            style={{ color: '#D92D20' }}
          >
            {props?.communicationLog?.communicationStatus[0]
              ?.communicationErrorInfo?.message
              ? props?.communicationLog?.communicationStatus[0]
                ?.communicationErrorInfo?.message
              : props?.communicationLog?.communicationStatus[0]
                ?.twilioErrorInfo?.message}
          </Text>
        </View>
        <HStack flex={0.1} justifyContent={'center'} space={4}>
          <View justifyContent={'center'} alignItems={'end'}>
            <Popover
              key={props.communicationLog?.id}
              overlayInnerStyle={{
                borderRadius: 16,
                padding: 0,
                width: '150px',
                paddingLeft: 2,
              }}
              content={
                <VStack style={{ marginTop: -5, borderRadius: 16, marginBottom: -10 }}>
                  <Pressable
                    onPress={() =>
                      setStateData({ ...stateData, showEditDrawer: true })
                    }
                  >
                    <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
                      <EditIconSvg />
                      <Text style={styles.mainMenuTxt}>
                        <DisplayText
                          size={'smMedium'}
                          textLocalId={
                            props.communicationLog?.receiverResourceType !==
                              'CONTACT'
                              ? 'Edit User'
                              : 'Edit Member'
                          }
                        />
                      </Text>
                    </HStack>
                  </Pressable>

                  {stateData.showEditDrawer &&
                    (props.communicationLog?.receiverResourceType !==
                      'CONTACT' ? (
                      <AddEditUser
                        onClose={handleClose}
                        user={{
                          id: props.communicationLog?.receiverUser?.id,
                          uuid: props.communicationLog?.receiverUser?.uuid,
                        }}
                      />
                    ) : (
                      <AddOrUpdateLead
                        {...contactInfo}
                        singleLeadData={contactInfo}
                        onFormCompleteAction={(actionCode: string) => {
                          onViewChangeActionPerformed(actionCode);
                          handlePopover();
                        }}
                        personType={
                          contactInfo?.contactType?.contactType?.code
                        }
                        personTypeUuid={
                          contactInfo?.contactType?.contactType?.id
                        }
                      />
                    ))}
                  <Pressable
                    style={{ display: 'flex' }}
                    onPress={() => {
                      setShowPopover(!setShowPopover);
                      props.handleResend(props.communicationLog?.id);
                    }}
                  >
                    <HStack
                      style={[
                        styles.mainMenuContent,
                        styles.firstMenu,
                        styles.lastMenu,
                      ]}
                    >
                      <View style={{ marginTop: 4 }}>
                        <RetrySvg strokeColor='black' />
                      </View>
                      <Text style={styles.mainMenuTxt}>
                        <DisplayText size={'smMedium'} textLocalId={'Retry'} />
                      </Text>
                    </HStack>
                  </Pressable>
                </VStack>
              }
              title=""
              trigger="click"
              open={showPopover}
              onOpenChange={(status: boolean) => setShowPopover(status)}
              placement={'left'}
            >
              <Tooltip>
                <Pressable
                  key={props.communicationLog?.id}
                  onPress={() => {
                    setShowPopover(!showPopover);
                  }}
                >
                  <ActionButtonSvg></ActionButtonSvg>
                </Pressable>
              </Tooltip>
            </Popover>
          </View>
        </HStack>
      </HStack>
      {isDrawerVisible && selectedRow && (
        <MessagingContactDetailsDrawer
          contactData={selectedRow?.receiverResourceType === 'CONTACT'
            ? selectedRow?.receiverContact
            : selectedRow?.receiverUser}
          isDrawerVisible={isDrawerVisible}
          contactType={selectedRow?.receiverResourceType}
          onSideBarActionPerformed={(action, actionData) => {
            if (action === CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              handleDrawerClose(action);
            } else if (action === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
              onActionPerformed(action, actionData);
              handleDrawerClose(action);
            } else {
              onActionPerformed(action, actionData);
            }
          }}
          borderLessView={true}
        />
      )}
    </>
  );
};

export default CommunicationLogChartMemberList;
