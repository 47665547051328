export const formatEFaxNumber = (eFaxNumber: string) => {
  const cleaned = ('' + eFaxNumber)?.replace(/\D/g, '');
  const match = cleaned?.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return eFaxNumber;
};

export const getUpdatedErrorMessageForInboxMemberSetting = (stateData: any) => {
  let inboxMemberErrorMsg = '';
  let inboxNameErrorMsg = '';
  let isErrorPresent = false;

  if (!stateData?.selectedUsersUuid || !stateData?.selectedUsersUuid?.length) {
    inboxMemberErrorMsg = 'Please select inbox members';
    isErrorPresent = true;
  }
  if (!stateData?.inboxName) {
    inboxNameErrorMsg = 'Please enter inbox name';
    isErrorPresent = true;
  }
  return {
    errors: {
      inboxMember: inboxMemberErrorMsg,
      inboxName: inboxNameErrorMsg,
    },
    isErrorPresent,
  };
};
