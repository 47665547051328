import {Table} from "antd";
import {Spinner, VStack} from "native-base";
import {Dimensions} from "react-native";
import {useNavigate, useParams} from "react-router-dom";
import {COMMON_ACTION_CODES} from "../../../../../constants/ActionConst";
import PageBodyContainer from "../../../../common/PageBodyContainer/PageBodyContainer";
import {TableWrapper} from "../../../../common/TableWrapper";
import {IQualityMeasuresListView} from "../../HelperFiles/interface";
import {getQualityMeasuresListColumns} from '../../HelperFiles/TablesColumnsView';
const QualityMeasuresList = (props: IQualityMeasuresListView) => {
  const {stateData, handlePageChange, data} = props;
  const {height} = Dimensions.get('window');
  const {contractId} = useParams();
  const navigate = useNavigate()
  const finalHeight = height - 220;
  const onActionPerformed = (code: string, data?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.DETAIL_VIEW:
        navigate(`/members/contracts/${contractId}/qualityMeasures/${data.measureId}/overview`);
        break;
    }
  }
  return (
    <PageBodyContainer>
      <TableWrapper
        wrapperStyle={{alignItems: 'center'}}
        minHeight={230}
        pagination={{
          current: stateData?.currentPage,
          pageSize: stateData?.pageSize || 10,
          total: stateData?.total,
          onChange: (currentPage, currentPageSize) => {
            handlePageChange?.(currentPage, currentPageSize);
          },
        }}
        hideOnSinglePage={false}
      >
        <Table
          scroll={{x: 1000, y: finalHeight}}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          columns={getQualityMeasuresListColumns(onActionPerformed)}
          dataSource={data}
          pagination={false}
          loading={{
            spinning: stateData.loading,
            indicator: (
              <VStack justifyContent="center" height={'100%'}>
                <Spinner size={'lg'} />
              </VStack>
            ),
          }}
          rowKey={(row: any) => `quality_measures_list_${row.id}`}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onActionPerformed(COMMON_ACTION_CODES.DETAIL_VIEW, record)
              },
            };
          }}
        />
      </TableWrapper>
    </PageBodyContainer>
  )
}

export default QualityMeasuresList;
