import React from 'react';
import {Path, Svg} from 'react-native-svg';
import { Colors } from '../../styles/Colors';
import { ICommonSvgProps } from '../../components/common/Svg/interfaces';

function AthenaHealthIcon(props: ICommonSvgProps) {
  return (
    <Svg width={props.width || "16"} height={props.height || "17"} viewBox="0 0 16 17" fill="none">
      <Path d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H12C14.2091 0.5 16 2.29086 16 4.5V12.5C16 14.7091 14.2091 16.5 12 16.5H4C1.79086 16.5 0 14.7091 0 12.5V4.5Z" fill={Colors.Custom.LightGreenBackground}/>
      <Path d="M7.11378 8.26496C7.11378 8.26496 6.00423 6.88943 5.98062 5.3691C5.95701 3.87291 7.01935 2.8111 7.01935 2.8111C7.01935 2.8111 8.1289 4.18663 8.15251 5.70695C8.17612 7.20315 7.11378 8.26496 7.11378 8.26496Z" fill={Colors.Custom.OliveGreen}/>
      <Path d="M13.0393 6.55158C13.0393 6.55158 13.1573 9.59223 11.6936 11.6917C10.2064 13.7671 7.68036 14.1291 7.68036 14.1291C7.68036 14.1291 7.56232 11.0884 9.02599 8.98893C10.4897 6.88943 13.0393 6.55158 13.0393 6.55158ZM8.90795 6.1896C8.90795 6.59984 9.21485 6.91356 9.61618 6.91356C10.0175 6.91356 10.3244 6.59984 10.3244 6.1896C10.3244 5.77935 10.0175 5.46563 9.61618 5.46563C9.21485 5.46563 8.90795 5.80348 8.90795 6.1896Z" fill={Colors.Custom.DarkGreen}/>
      <Path d="M7.06657 11.4021C7.06657 11.378 7.06657 11.378 7.06657 11.4021C7.06657 10.7747 6.97214 8.9648 6.02783 7.63753C4.87106 6.04481 2.93525 5.77935 2.93525 5.77935C2.93525 5.77935 2.84082 8.12017 3.97398 9.71289C4.77663 10.8471 6.00423 11.3056 6.64163 11.4745V13.9843C6.64163 14.1049 6.73606 14.2015 6.8541 14.2015C6.97214 14.2015 7.06657 14.1049 7.06657 13.9843V11.4021Z" fill={Colors.Custom.OliveGreen}/>
    </Svg>
  );
}

export default AthenaHealthIcon;