import React from 'react';
import {Path, Svg} from 'react-native-svg';

const FailedEmailSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="22"
      height="19"
      fill="none"
      viewBox="0 0 22 19"
    >
      <Path
        fill="#6F7A90"
        d="M2.091 16.768l-.353.354.353-.354zm17.657 0l.354.354-.354-.354zm1.672-8.84a.5.5 0 00-1 .025l1-.026zm-9.5-5.488a.5.5 0 100-1v1zM5.24 5.556a.5.5 0 10-.64.768l.64-.768zm1.839 2.183l.32-.384-.32.384zm8.002.384a.5.5 0 10-.64-.768l.64.768zM18.335.811a.5.5 0 10-1 0h1zm-1 4.329a.5.5 0 001 0h-1zm1 2.5a.5.5 0 10-1 0h1zm-1 .5a.5.5 0 001 0h-1zm-4.415 9.3h-4v1h4v-1zm-4 0c-1.9 0-3.274-.001-4.323-.142-1.033-.139-1.675-.406-2.152-.883l-.707.707c.694.694 1.582 1.013 2.726 1.167 1.13.152 2.584.151 4.456.151v-1zm-8.5-7.5c0 1.872-.001 3.325.15 4.456.155 1.144.474 2.032 1.168 2.726l.707-.707c-.477-.477-.744-1.119-.883-2.153-.141-1.048-.142-2.422-.142-4.322h-1zm20 0c0 1.9-.001 3.274-.142 4.322-.14 1.034-.406 1.675-.883 2.153l.707.707c.694-.694 1.013-1.582 1.167-2.726.152-1.13.15-2.585.15-4.456h-1zm-7.5 8.5c1.871 0 3.325.001 4.456-.15 1.144-.155 2.032-.474 2.726-1.168l-.707-.707c-.478.477-1.119.744-2.153.883-1.048.14-2.422.142-4.322.142v1zm-4-17c-1.872 0-3.325-.001-4.456.15-1.144.155-2.032.474-2.726 1.168l.707.707c.477-.477 1.119-.744 2.152-.883C5.646 2.441 7.02 2.44 8.92 2.44v-1zm-7.5 8.5c0-1.9.001-3.274.142-4.322.139-1.034.406-1.675.883-2.153l-.707-.707C1.044 3.452.725 4.34.57 5.484.419 6.614.42 8.07.42 9.94h1zm20 0c0-.925.018-1.279 0-2.013l-1 .026c.018.72 0 1.02 0 1.987h1zm-12.5-7.5h3v-1h-3v1zM4.6 6.324l2.159 1.8.64-.77L5.24 5.557l-.64.768zm2.159 1.8c.908.756 1.624 1.354 2.251 1.757.639.411 1.235.654 1.91.654v-1c-.411 0-.818-.14-1.369-.495-.563-.362-1.224-.912-2.152-1.685l-.64.768zm7.682-.77c-.928.774-1.59 1.324-2.152 1.686-.552.355-.958.495-1.37.495v1c.676 0 1.272-.243 1.91-.654.627-.403 1.344-1 2.252-1.758l-.64-.768zM17.335.812V5.14h1V.811h-1zm0 6.829v.5h1v-.5h-1z"
      ></Path>
    </Svg>
  );
};

export default FailedEmailSvg;
