import BaseService from '../../../services/CommonService/BaseService';
import CMSService from '../../../services/CommonService/CMSService';
import CareStudioService from '../../../services/CommonService/CareStudioService';
import {
  ICmsEmailBody,
  IConversationDraftMainResp,
  ICreateEmailConversationData,
  ICreateEmailConversationResponse,
  IDraftData,
  IMessageDraftTypes,
  ISaveDraftBody,
} from './interfaces';

export const sendCMSEmail = async (data: ICmsEmailBody) => {
  const cmsInstance = CMSService.getCMSServiceInstance();
  const cmsService = cmsInstance.cmsAxiosService;
  const response = await cmsService.post('email/', data, {});
  return response.data;
};

export const sendCMSEmailWithAttachment = async (data: FormData) => {
  const cmsInstance = CMSService.getCMSServiceInstance();
  const cmsService = cmsInstance.cmsAxiosService;
  const response = await cmsService.post('email/attachments', data, {});
  return response.data;
};

export const saveDraft = async <T extends IMessageDraftTypes>(
  data: ISaveDraftBody<T>
) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/conversation/draft`;
  const response = await baseService.post(url, data);
  return response.data;
};

export const getDraft = async <T extends IMessageDraftTypes>(data: {
  conversationUuid: string;
  draftType: T;
  abortSignal: AbortSignal;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/conversation/${data.conversationUuid}/draft/${data.draftType}`;
  const response = await baseService.get<IDraftData<T> | null>(url, {
    signal: data?.abortSignal
  });
  return response.data;
};

export const getDraftForConversationList = async(data: {
  conversationUuids: string[];
  abortSignal: AbortSignal;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/conversation/drafts`;
  const bodyData = {
    conversationUuids: data?.conversationUuids
  }
  const response = await baseService.post<IConversationDraftMainResp>(url, bodyData, {
    signal: data?.abortSignal
  });
  return response.data;
};

export const deleteDraft = async (data: {
  conversationUuid: string;
  draftType: IMessageDraftTypes;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/conversation/${data.conversationUuid}/draft/${data.draftType}`;
  const response = await baseService.delete(url);
  return response.data;
};

export const createEmailConversation = async (
  data: ICreateEmailConversationData
) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/conversation/email-conversation`;
  const response = await baseService.post<ICreateEmailConversationResponse>(
    url,
    data
  );
  return response.data;
};

export const downloadDocument = async (documentId: string) => {
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const service = careStudioInstance.careStudioAxiosService;
  const url = `/attachment/${documentId}/file`;
  const response = await service.get(url, {
    responseType: 'blob',
  });
  return response.data;
};
