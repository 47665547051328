import {useLazyQuery, useQuery} from '@apollo/client';
import {Checkbox,Spin} from 'antd';
import {
  Box,
  Divider,
  HStack,
  Input,
  Text,
  VStack,
  View,
  useToast
} from 'native-base';
import {useContext,useEffect,useState} from 'react';
import {useIntl} from 'react-intl';
import {ViewStyle} from 'react-native';
import {IUser} from '../../../../Interfaces';
import {GROUP_MEMBER_TYPE} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import  {
  GetUsersTaskAccess,
  GetUsersTaskPoolAccess,
} from '../../../../services/TaskPool/TaskPoolQueries';
import {GET_USER_FOR_SCHEDULE_ACCESS} from '../../../../services/User/UserQueries';
import {Colors} from '../../../../styles';
import {filterWorkflowUser,getAccountUUID,getUserUUID} from '../../../../utils/commonUtils';
import {getMlovId} from '../../../../utils/mlovUtils';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import './UserAutoCompleteStyles.css';
import {FilterWrapperComponent} from '../../CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import {TaskFilterType} from '../../CareDashboard/CareDashboardTopBar/interfaces';
import {IAllUserPoolSelect, IUserPool, IUserSearch} from '../../CareDashboard/CareDashboardWidget/UserAutoComplete';
import Feather from 'react-native-vector-icons/Feather';
import { TASK_TABS } from '../../CareDashboard/CareDashboardConstants';
import {UserRole} from '../../../RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces';


export interface ISelectedUsers {
  value: string,
  label: string,
  key: string,
}

export const UserAutoCompleteMultiSelect = (props: {
  usersData?: IUser[];
  selectedValue?: IUserSearch[];
  selectedUserName?: string;
  selectedUserUUID?: string;
  onChange: (participants: IUserSearch[]) => void;
  onPoolChange?: (newUserPool: IUserPool[]) => void;
  setAllUserPoolList?: (allUsersPools: IUserPool[]) => void;
  onAllUserPoolChange?: (newUserPool: IAllUserPoolSelect[]) => void;
  onPoolUserAccessChange?: (newUserPoolAccess: any) => void;
  onAccessUserData?: (userData: IUserSearch[]) => void;
  labelText?: string;
  onActionPerformed?: (tabCode: any, rawData: any) => void;
  selectedUserPool?: IUserPool[];
  isMentionOrCreatedSelect?: boolean;
  filterType?: string;
  selectedTab?: string;
}) => {
  const toast = useToast();
  const intl = useIntl();
  const accountId = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = mlovData.sidecarContext?.isSidecar;
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );
  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  const [accountUsers, setAccountUsers] = useState<any[]>([]);
  const [isRefetch] = useState(false);
  const [value, setValue] = useState<IUserSearch[]>(props.selectedValue ? props.selectedValue : []);
  const [userLoading, setUserLoading] = useState(false);
  const [userData, setUserData] = useState<IUserSearch[]>([]);
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState<IUserSearch>({
    label: '',
    key: '',
    value: '',
    userRoles: []
  });
  const [searchString, setSearchString] = useState<string>("");
  const unAssignedUser = {
    label: 'Unassigned',
    key: 'null',
    value: 'null'
  };
  const sortedUserData = userData
      ?.slice()
      .sort((a, b) => a.label.localeCompare(b.label));
  if (!props?.isMentionOrCreatedSelect) {
    sortedUserData.unshift(unAssignedUser);
  }
  const [stateData, setStateData] = useState({
    isSelectAll: sortedUserData?.length === props.selectedValue?.length ? true : false as boolean,
    prevSelectedValues: props.selectedValue ? props.selectedValue : [] as IUserSearch[],
  })

  const userDataPostProcessing = async (users: any[]) => {
    let transformedUsers: IUserSearch[] = [];
    let toTransform: IUserSearch[] = [];

    if (users && users.length > 0) {
      toTransform = users
        .filter((user: any) => {
          if (props.selectedUserPool) {
            const userPoolUsers = props.selectedUserPool[0].userPoolUsers;
            const filterUserPool =
              userPoolUsers &&
              userPoolUsers.filter(
                (userPoolUserItem: any) =>
                  userPoolUserItem.userId === user.userId
              );
            return filterUserPool && filterUserPool.length;
          }

          return true;
        })
      const updatedAccountUsers = accountUsers.map((account: any) => account.userId)
      toTransform = toTransform.filter((user: any) => updatedAccountUsers.includes(user.userId))
      transformedUsers = toTransform.map((user: any): IUserSearch => {
        return {
          label: accountUsers.filter(
            (accountUserItem: any) => accountUserItem.userId === user.userId
          )?.[0]?.userName,
          value: user.userId,
          key: user.userId,
          userRoles: accountUsers.filter(
            (accountUserItem: any) => accountUserItem.userId === user.userId
          )?.[0]?.userRoles,
        };
      });
    }
    setUserData(transformedUsers);
    props.onAccessUserData && props.onAccessUserData(transformedUsers);
    if (
      (!value || !value.length) &&
      !props.selectedValue &&
      (props.selectedUserName || props.selectedUserUUID)
    ) {
      const user = transformedUsers.find(
        (item) =>
          item.label === props.selectedUserName ||
          item.key === props.selectedUserUUID
      );
      if (user) {
        setValue([user]);
      }
    }
    getAllTaskPoolAndFilter(transformedUsers);
    return transformedUsers;
  };

  const getUserIds = (selectedValue: IUserSearch[])=> {
    if (selectedValue) {
      return selectedValue?.filter((user)=>{
        return (user?.key !== 'null' && user?.key)
      }).map((user)=> user?.key) || [];
    } else {
      return [loggedInUserId];
    }
  }

  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const accountUsers: any[] = [];
      if (data && data?.users && data?.users.length) {
        data.users = filterWorkflowUser(data.users, loggedInUserId);
        (data.users || []).forEach((user: any) => {
          accountUsers.push({
            userName: user.name,
            userId: user.uuid,
            email: user.email || '',
            userRoles: user.userRoles,
          });
          if (user?.uuid === loggedInUserId) {
            setCurrentLoggedInUser({
              label: user?.name,
              value: user?.uuid,
              key: user?.uuid,
              userRoles: user?.userRoles,
            });
          }
        });
      }
      setAccountUsers(accountUsers);
    },
    onError: (error: any) => {
      toast.show({
        render: () => {
          return (
            <Box bg="info.500" px="2" py="1" rounded="sm" mb={5}>
              Something went wrong!!!
            </Box>
          );
        },
        placement: 'top',
      });
    },
  });


  const [getUserTaskPoolAccess] = useLazyQuery(GetUsersTaskPoolAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      // fetching all account users amd filtering login user data
      const filterUserPoolAccessData: any = data.userPoolAccesses?.filter(
        (dataItem: any) => dataItem.userId === loggedInUserId
      );

      if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
        // logged In user have the other pool user access
        const userPoolAccessUsersData =
          filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
        const loggedInUser = accountUsers.filter(
          (accountItem: any) => accountItem.userId === loggedInUserId
        );
        if (loggedInUser && loggedInUser.length) {
          userPoolAccessUsersData.unshift(loggedInUser[0]);
        }
        userDataPostProcessing(userPoolAccessUsersData);
        fetchAllTaskPoolAccessUserPool(userPoolAccessUsersData);
      } else {
        if (currentLoggedInUser?.key) {
          const userPoolAccessUsersData = [{...currentLoggedInUser, userId: currentLoggedInUser?.key}];
          userDataPostProcessing(userPoolAccessUsersData);
        }
        fetchAllTaskPoolAccessUserPool([
          {
            id: '',
            userId: loggedInUserId,
            isDeleted: false,
          },
        ]);
      }
      setUserLoading(false);
    },
    onError: (error: any) => {
      setUserLoading(false);
      toast.show({
        render: () => {
          return (
            <Box bg="info.500" px="2" py="1" rounded="sm" mb={5}>
              Something went wrong!!!
            </Box>
          );
        },
        placement: 'top',
      });
    },
  });

  /**
   * Login user task pool or selected user task pool (For Team Task)
   */
  const [getUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  /**
   * Login user's manageable users's task pool (For Add task task pool dropdown)
   */
  const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const getAllTaskPoolAndFilter = async (users: IUserSearch[])=> {
    const taskPoolData = await getUserTaskPools({
      variables: {
        userId: getUserIds(users),
        userPoolTypeId: accessUserTaskPoolTypeId,
      },
    });
    const newUserPool: IUserPool[] = [];
    taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
      const isUserPoolAlreadyPresent = newUserPool.some(userPool => userPool.id === userPoolItem?.userPoolId);
      if (!isUserPoolAlreadyPresent) {
        newUserPool.push({
          ...userPoolItem.userPool,
          id: userPoolItem?.userPoolId,
        });
      }
    });
    props?.setAllUserPoolList?.(newUserPool)
  }


  const fetchSelectedUserPool = async () => {
    try {
      const taskPoolData = await getUserTaskPools({
        variables: {
          userId: getUserIds(value),
          userPoolTypeId: accessUserTaskPoolTypeId,
        },
      });
      const newUserPoolAccess: any[] = [];
      const newUserPool: IUserPool[] = [];
      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;
        const isUserPoolAlreadyPresent = newUserPool.some(userPool => userPool.id === userPoolItem?.userPoolId);
        if (!isUserPoolAlreadyPresent) {
          newUserPool.push({
            ...userPoolItem.userPool,
            id: userPoolItem?.userPoolId,
          });
        }
        newUserPoolAccess.push({
          userPoolId,
          userPoolName,
          userPoolUserIds: userPoolUsers.map(
            (userPoolUserItem: any) => userPoolUserItem.userId
          ),
        });
      });
      props.onPoolUserAccessChange &&
        props.onPoolUserAccessChange(newUserPoolAccess);
      props.onPoolChange && props.onPoolChange(newUserPool);
    } catch (error) {
      ''
    }
  };

  /**
   * To fetch selected user Pool for all task pool access users(Users's task pool whom login user can manage)
   */
  const fetchAllTaskPoolAccessUserPool = async (users: any[]) => {
    try {
      const userIds = users.map((userItem: any) => userItem?.userId);
      const taskPoolData = await getAllTaskAccessUserTaskPools({
        variables: {
          userId: userIds,
          userPoolTypeId: accessUserTaskPoolTypeId,
        },
      })
      const newAllUserPool: IAllUserPoolSelect[] = [];

      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

        const findIndex = newAllUserPool.findIndex(
          (userPoolItem: IAllUserPoolSelect) =>
            userPoolName === userPoolItem.userPoolName
        );
        if (findIndex < 0) {
          newAllUserPool.push({
            userPoolId,
            userPoolName,
            userPoolUserIds: userPoolUsers.map(
              (userPoolUserItem: any) => userPoolUserItem.userId
            ),
          });
        }
      });
      props.onAllUserPoolChange && props.onAllUserPoolChange(newAllUserPool);
    } catch (error) {
      ''
    }
  };

  useEffect(() => {
    if (props.usersData && props.usersData.length) {
      userDataPostProcessing(props.usersData);
    } else {
      setUserLoading(true);
      getAccountUsers({
        variables: {
          accountUUID: accountId,
          roleCode: USER_ROLE_CODES.EMPLOYER
        },
      }).then(() => {
        getUserTaskPoolAccess({
          variables: {
            userId: [loggedInUserId],
            userPoolTypeId: accessUserTypeId,
          },
        })
      });
    }
  }, [props.usersData, isRefetch]);

  useEffect(() => {
    if (props?.selectedValue) {
      setValue(props.selectedValue);
      setStateData((prev)=> {
        return {
          ...prev,
          prevSelectedValues: props?.selectedValue || [],
        }
      })
    } else {
      setValue([]);
      setStateData((prev)=> {
        return {
          ...prev,
          prevSelectedValues: [],
        }
      })
    }
  }, [props.selectedValue]);

  useEffect(() => {
    if (!props.selectedUserPool) {
      fetchSelectedUserPool();
    }
  }, [props?.selectedValue]);

  useEffect(()=> {
    setStateData((prev)=> {
      return {
        ...prev,
        isSelectAll: sortedUserData?.length === props.selectedValue?.length ? true : false,
      }
    })
  },[sortedUserData?.length, props.selectedValue?.length])

  const getUserDataFromId = () => {
    const selectedUsersData = value?.map((user)=>{
      const matchedData = userData?.find((item) => item?.key === user?.key);
      return {
        label: matchedData?.label || '',
        key: matchedData?.key || '',
        value: matchedData?.value || '',
        userRoles: matchedData?.userRoles,
      };
    }) || [] as any;
    if (selectedUsersData?.length) {
      return selectedUsersData;
    }
    return [currentLoggedInUser];
  };

  const isAssigneeChanged = (prevSelectedValues: any, selectedValue: any)=> {
    const prevSelectedUsersId = prevSelectedValues.map((user:any)=>user?.key).sort();
    const currentSelectedUsersId = selectedValue.map((user:any)=>user?.key).sort();
    return JSON.stringify(prevSelectedUsersId) !== JSON.stringify(currentSelectedUsersId);
  }

  const getPlaceholderText = ()=> {
    switch(props?.selectedTab) {
      case TASK_TABS.MENTIONED:
        return "Search Mention";
      case TASK_TABS.CREATED:
        return "Search Created by";
      default:
      return "Search Assignee"
    }
    return "Search Assignee"
  }
  const getUserRoles = (userRoles: UserRole[]) => {
    return  [...new Set(
       userRoles
         .filter((role) => role?.userRole?.roleName || role?.userRole?.userRole?.value)
         .map((role) => role.userRole?.roleName || role?.userRole?.userRole?.value)
     )].join(', ');
   }

  const getLabelText = ()=> {
    switch(props?.selectedTab) {
      case TASK_TABS.MENTIONED:
        return "Select Mention";
      case TASK_TABS.CREATED:
        return "Select Created by";
      default:
      return "Select Assignee"
    }
    return "Select Assignee"
  }

    return (
      <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        filterType = {props?.isMentionOrCreatedSelect ? props?.filterType : TaskFilterType?.Assignee}
        label={props?.labelText || "Assigned to"} value={value?.map((user) => user.label)}
        selectDefaultUser={()=>{
          setValue([currentLoggedInUser]);
        }}
        overlayClassName="assignee-select"
        onClose={()=> {
          setSearchString("");
          if (isAssigneeChanged(stateData?.prevSelectedValues, value)) {
            fetchSelectedUserPool();
            props.onChange(isSidecarContext ? value : getUserDataFromId());
            if (!props?.isMentionOrCreatedSelect){
              props?.onActionPerformed?.(
                COMMON_ACTION_CODES.ASSIGNEE_CHANGE,
                {selectedUsers: value },
              );
            }
          }
        }}
      >
        <View style={{
          width: 350,
        }}>
          <View style={{
            borderBottomColor: Colors.Custom.Gray200,
            paddingBottom: 14,
            marginHorizontal: 16
          }}>
            <Text fontWeight={600} style={{
              marginHorizontal: 4,
              marginVertical: 8
            }}>
              {getLabelText()}
            </Text>
            <Input
              placeholder={getPlaceholderText()}
              value={searchString}
              onChangeText={setSearchString}
              width={'100%'}
            />
          </View>
          <Divider mb={4}/>
          <View marginX={4}>
            <Checkbox
              checked={stateData?.isSelectAll}
              className="copy-checkbox"
              onChange={(event) => {
                if(event?.target?.checked) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      isSelectAll: true
                    }
                  })
                  setValue(sortedUserData);
                } else {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      isSelectAll: false
                    }
                  })
                  setValue([]);
                }
              }}
            >
              <HStack
                paddingX={2}
              >
                <Text>
                  {'Select All'}
                </Text>
              </HStack>
            </Checkbox>
          </View>
          <View overflowY={'scroll'} style={{
            maxHeight: 400,
            width: 350,
          }}>
            <Divider mt={3}/>
            {userLoading ? <Spin size="small" /> : sortedUserData?.length ?
              sortedUserData?.filter((item: IUserSearch) => { return item.label?.toLowerCase().includes(searchString.toLowerCase())})?.map((item: IUserSearch, index: number) => {
                return (
                  <View width={'100%'}>
                  <View marginX={4} py={3}>
                    <Checkbox
                      value={item?.value||""}
                      checked={value?.find((subitem: any) => subitem.key === item.key) ? true : false}
                      key={item?.key}
                      className="copy-checkbox"
                      onChange={(event) => {
                        let tmpArray = value;
                        if (event?.target?.checked) {
                          tmpArray = [...tmpArray,item];
                        } else {
                          tmpArray = tmpArray.filter((subItem) => {
                            return item?.key !== subItem?.key
                          })
                        }
                        setValue(tmpArray);
                        if (tmpArray?.length === sortedUserData?.length) {
                          setStateData((prev)=>{
                            return {
                              ...prev,
                              isSelectAll: true
                            }
                          })
                        } else {
                          setStateData((prev)=>{
                            return {
                              ...prev,
                              isSelectAll: false
                            }
                          })
                        }
                      }}

                    >
                      <HStack
                        paddingX={2}
                        alignItems={'center'}
                      >
                        <View>
                          {item?.key === 'null' ?
                          <View
                          width={'12'}
                          height={'12'}
                          style={[
                            {
                              borderRadius: 50,
                              borderColor: Colors.Custom.Gray500,
                              borderWidth: 1,
                              alignItems: 'center',
                              alignSelf: 'center',
                              justifyContent: 'center',
                              alignContent: 'center',
                            },
                          ]}
                        >
                          <Feather
                            name="user"
                            size={22}
                            color={Colors.Custom.Gray500}
                          />
                        </View> :
                          <DisplayCardAvatar
                            avatarStyle={{
                              avatarSize: '12',
                              width: 48,
                              height: 48,
                            }}
                            userData={{
                              userType: GROUP_MEMBER_TYPE.USER,
                              userId: item.value,
                              name: item?.label,
                              userName: item?.label,
                              imgSrc: '',
                            }}
                            isLetterAvatarShow
                          />}
                        </View>
                        <VStack
                          alignSelf='center'
                          flex={1}
                          marginLeft={2}
                          justifyContent={'flex-start'}
                        >
                          <Text fontWeight={'bold'} fontSize={16}>
                            {item?.label}
                          </Text>
                          <Text color={Colors?.Custom?.Gray500}>{getUserRoles(item?.userRoles || [])}</Text>
                        </VStack>
                      </HStack>
                    </Checkbox>
                  </View>
                  <Divider marginX={4}/>
                  </View>
                )
              }) :
              <Text style={{
                marginTop: 8
              }} size={'smMedium'}
                color={Colors.Custom.Gray700}>
                {intl.formatMessage({
                  id: 'noDataFound',
                })}
              </Text>
            }
          </View>
        </View>
      </FilterWrapperComponent>
    )
};

export default UserAutoCompleteMultiSelect;
