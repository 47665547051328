import {Text} from 'native-base';
import {View} from 'react-native';
import {Colors} from '../../../styles/Colors';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {getFormattedOnCallScheduleDateTimeString} from './commonUtils';
import {
  IOnCallSchedule,
  IOnCallScheduleDuration,
  IOnCallScheduleTableColumnsParams,
} from './interface';
import {getMachineTimeZone} from '../../../utils/commonUtils';
import {v4} from 'uuid';

export const getOnCallScheduleTableColumns = (
  params: IOnCallScheduleTableColumnsParams
): any => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
        textLocalId={params.textLocalId}
      />
    ),
    dataIndex: params.dataIndex,
    key: params.key,
    width: params?.width || '20%',
    fixed: 'left',
    render: params.renderFunction,
    responsive: ['md'],
  };
};

export const cellTextRenderFunction = (data: {value: string}): JSX.Element => {
  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <View style={{marginLeft: 12}}>
        <Text
          style={{marginTop: 4}}
          size={'smMedium'}
          color={Colors.Custom.Gray500}
        >
          {data?.value}
        </Text>
      </View>
    </View>
  );
};

export const cellDateRenderFunction = (data: IOnCallSchedule): JSX.Element => {
  const scheduleDurationsLength = data?.onCallScheduleDurations?.length;
  const firstOnScheduleDuration = data?.onCallScheduleDurations?.[0];
  const fromDate = firstOnScheduleDuration?.fromDate
    ? new Date(firstOnScheduleDuration?.fromDate)
    : null;
  const toDate = firstOnScheduleDuration?.toDate
    ? new Date(firstOnScheduleDuration?.toDate)
    : null;
  const dateString = getFormattedOnCallScheduleDateTimeString(
    fromDate,
    toDate,
    JSON.parse(firstOnScheduleDuration?.daysOfWeek || '[]') || []
  );

  return (
    <View>
      <Text
        style={{marginTop: 4}}
        size={'smMedium'}
        color={Colors.Custom.Gray500}
      >
        {dateString}
        {scheduleDurationsLength > 1 ? (
          <>
            &nbsp;...
            <Text size={'smMedium'} color={Colors.Custom.mainPrimaryPurple}>
              More
            </Text>
          </>
        ) : (
          <></>
        )}
      </Text>
    </View>
  );
};

export const getNewCallScheduleDuration = (): IOnCallScheduleDuration => {
  const usersTimezone = getMachineTimeZone();

  return {
    key: v4(),
    fromDate: '',
    toDate: '',
    timezone: usersTimezone,
    daysOfWeek: '[]',
    onCallScheduleUsers: [],
  };
};
