import {Menu, Popover} from 'antd';
import {Divider, HStack, Pressable, View, VStack} from 'native-base';
import { useContext, useState } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FHIR_RESOURCE} from '../../constants/FhirConstant';
import {Colors} from '../../styles';
import {testID, TestIdentifiers} from '../../testUtils';
import {getResourceAbilities} from '../../utils/capabilityUtils';
import { getUserUUID, isEnableCareProgram } from '../../utils/commonUtils';
import {DisplayText} from '../common/DisplayText/DisplayText';
import EmployerProfile from '../RightSideContainer/EmployerManagement/EmployerProfile';
import {getUserPermissionByEntityAndActionCode} from '../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from './SideBarConst';
import {
  getIconsFromMainMenu,
  // getIconsFromSubMenu,
  getMenuConfig,
  getSideBarObjectFromLocationPath,
} from './SideBarHelper';
import {
  ISideBarLocation,
  ISideBarMenu,
  ISideBarProps,
  ISideBarSubMenu,
} from './SideBarInterface';
import './SideMenuBar.css';
import { IGlobalWarnings } from '../../utils/interfaces';
import { GLOBAL_WARNINGS } from '../../utils/commonViewUtils';
import { FilterDataContext } from '../../context/FilterDataContext';
import { CommonDataContext } from '../../context/CommonDataContext';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {usePermissions} from '../CustomHooks/usePermissions';

const SideMenuBar = (props: ISideBarProps) => {

  const commonData = useContext(CommonDataContext)
  const userSettings = commonData.userSettings;
  const enableCareProgram = isEnableCareProgram(userSettings);
  const location = useLocation();
  const integrationAdminCapabilities = getResourceAbilities(
    FHIR_RESOURCE.INTEGRATION_ADMIN,
    '',
    ''
  );
  const {check} = usePermissions()
  const showIntegrationTab = integrationAdminCapabilities?.isEnabled || false;
  const userUUID = getUserUUID();
  const menuConfig = getMenuConfig(userUUID, location.pathname, showIntegrationTab, {enableCareProgram: enableCareProgram});
  const menuObj: ISideBarLocation = getSideBarObjectFromLocationPath(
    location.pathname,
    menuConfig.sideBarMenus
  );
  let sideBarOpenKeys = [menuObj.mainMenu || menuConfig.defaultMenu];
  const currentSelectedKey = [menuObj.mainMenu || menuConfig.defaultMenu];
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const handleSubMenuClick = (menuCode: string) => {
    sideBarOpenKeys = [menuCode || ''];
    props.onSideBarMenuChange(menuCode);
  };

  const filterContext = useContext(FilterDataContext)

  return (
    <View {...testID(TestIdentifiers.sidebar)}>
      {/* {location.pathname.indexOf('admin') != -1 && !props.menuCollaps && <></>}
      {!props.menuCollaps && location.pathname.indexOf('admin') == -1 && <></>} */}

      <Menu
        defaultOpenKeys={currentSelectedKey}
        openKeys={sideBarOpenKeys}
        selectedKeys={currentSelectedKey}
        style={{border: 'none', backgroundColor: 'transparent', margin: '0px'}}
        mode="vertical"
        className="sideBarMenu"
      >
        {menuConfig.sideBarMenus.map(
          (menuItem: ISideBarMenu, menuIndex: number): JSX.Element => {
            const entityCode = !(menuItem.menuCode === MAIN_MENU_CODES.SETTING) && menuItem?.path?.includes('admin')
            ? 'ADMIN_PANEL_WINDOW'
            : 'DASHBOARD_WINDOW'
            const hasMenuAccess  = check(entityCode, menuItem?.menuCode,'').hasAccess
            if (!hasMenuAccess) {
              return <></>;
            }

            return (
              <Menu.Item
                key={menuIndex}
                onClick={() => {
                  handleSubMenuClick(menuItem.menuCode);
                }}
                id={menuItem.menuCode}
                style={{
                  // paddingLeft:
                  //   currentSelectedKey[0] === menuItem.menuCode ? 16 : 16,
                  // paddingRight:
                  //   currentSelectedKey[0] === menuItem.menuCode ? 16 : 16,
                  // height:menuItem.menuCode === MAIN_MENU_CODES.DESIGNER ? 60 : '',
                  height: 85,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                  display: 'flex',
                  padding: 0,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '0px',
                }}
              >
                {menuItem.menuCode === MAIN_MENU_CODES.DESIGNER && false && (
                  <Divider
                    key={menuItem.menuCode}
                    my={1}
                    style={{width: '100%'}}
                    bg={Colors.Custom.Gray200}
                  />
                )}
                {menuItem.menuCode === MAIN_MENU_CODES.DESIGNER && false ? (
                  <Link
                    // key={'link_' + menuIndex}
                    to={menuItem.path || ''}
                    target="DESIGNER"
                  >
                    <HStack
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      style={{marginTop: 12}}
                    >
                      <VStack
                        flex={1}
                        style={[
                          {
                            paddingLeft: props.menuCollaps ? 0 : 10,
                            paddingRight: props.menuCollaps ? 0 : 10,
                          },
                          props.menuCollaps && {alignItems: 'center'},
                        ]}
                      >
                        <HStack alignItems={'center'}>
                          <VStack>
                            {getIconsFromMainMenu(
                              menuItem.menuCode,
                              currentSelectedKey[0],
                              props.menuCollaps
                            )}
                          </VStack>
                          {props.menuCollaps && (
                            <VStack>
                              <DisplayText
                                textLocalId={menuItem.menuName}
                                size={'xsMedium'}
                                extraStyles={{
                                  color: '#fff',
                                  // currentSelectedKey[0] === menuItem.menuCode
                                  //   ? Colors.primary['600'] || ''
                                  //   : Colors.Custom.Gray700,
                                }}
                              />
                            </VStack>
                          )}
                        </HStack>
                      </VStack>
                      {props.menuCollaps && (
                        <VStack style={{marginRight: 12}}>
                          {getIconsFromMainMenu(
                            MAIN_MENU_CODES.LINK,
                            MAIN_MENU_CODES.LINK
                          )}
                        </VStack>
                      )}
                    </HStack>
                  </Link>
                ) : (
                  <View
                    backgroundColor={
                      menuObj.mainMenu && currentSelectedKey[0] === menuItem.menuCode
                        ? '#7F56D9'
                        : 'transparent'
                    }
                    style={[
                      {
                        width: '100%',
                        height: 85,
                        justifyContent: 'center',
                        borderBottomColor: '#5938A8',
                        borderBottomWidth: 0.5,
                      },
                      props.menuCollaps && {alignItems: 'center'},
                    ]}
                  >
                    <Pressable
                      onPress={() => {
                        filterContext?.clearFilterData?.()

                        const menuData = GLOBAL_WARNINGS.find(
                          (menu: IGlobalWarnings) =>
                            menu.menuCode === menuItem.menuCode
                        );
                        if (!menuData?.menuCode) {
                          props?.onGlobalWarningOpen({} as IGlobalWarnings);
                        } else {
                          props?.onGlobalWarningOpen(menuData);
                        }
                      }}
                      _hover={{
                        bg: Colors.primary[400],
                      }}
                      style={[
                        {
                          width: '100%',
                          height: 85,
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                      ]}
                      {...testID(`${menuItem.menuCode}_${TestIdentifiers.sidebar}`)}
                    >
                      {menuItem?.subMenu?.length && false ? (
                        <Popover
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                            backgroundColor: '#825AC700',
                            boxShadow: 'none',
                          }}
                          overlayClassName="leftoverlaypopup"
                          showArrow={false}
                          content={
                            <VStack
                              style={{
                                borderRadius: 10,
                                backgroundColor: '#3B226C',
                                overflow: 'hidden',
                              }}
                            >
                              {menuItem?.subMenu?.map(
                                (subMenu: ISideBarSubMenu, index: number) => {
                                  const entityCode = subMenu?.settingPath?.includes('admin')
                                  ? 'ADMIN_PANEL_WINDOW'
                                  : 'DASHBOARD_WINDOW';
                                  const hasSettingAccess = check(entityCode, subMenu?.settingMenuCode || '','').hasAccess
                                  const hasSubMenuAccess = check(entityCode, subMenu?.subMenuCode || '','').hasAccess
                                  if (!hasSubMenuAccess) {
                                    return <></>;
                                  }
                                  return (
                                    <Pressable
                                      style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingVertical: 10,
                                        paddingHorizontal: 15,
                                      }}
                                      _hover={{
                                        bg: Colors.primary[500],
                                      }}
                                    >
                                      <Link
                                        // key={'link_' + menuIndex}
                                        to={subMenu.path || ''}
                                        className="link-visited"
                                      >
                                        <HStack alignItems={'center'}>
                                          {getIconsFromMainMenu(
                                            subMenu.subMenuCode,
                                            currentSelectedKey[0],
                                            props.menuCollaps
                                          )}
                                          <DisplayText
                                            key={subMenu.subMenuCode}
                                            size={'xsMedium'}
                                            textLocalId={subMenu.menuName}
                                            extraStyles={{
                                              color: '#fff',
                                              marginRight: 7.5,
                                              marginLeft: 7.5,
                                              // currentSelectedKey[0] === menuItem.menuCode
                                              //   ? Colors.primary['600'] || ''
                                              //   : Colors.Custom.Gray700,
                                            }}
                                          />
                                        </HStack>
                                      </Link>
                                      {subMenu?.hasSetting &&
                                      subMenu.settingPath &&
                                      hasSettingAccess ? (
                                        <View
                                          style={{
                                            borderLeftColor: '#B895EC',
                                            borderLeftWidth: 1,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <Link
                                            // key={'link_' + menuIndex}
                                            to={subMenu.settingPath || ''}
                                            className="link-visited"
                                          >
                                            {getIconsFromMainMenu(
                                              MAIN_MENU_CODES.SETTING,
                                              currentSelectedKey[0],
                                              props.menuCollaps
                                            )}
                                          </Link>
                                        </View>
                                      ) : null}
                                    </Pressable>
                                  );
                                }
                              )}
                              {/* {menuItem?.hasSetting && hasSettingAccessMain ? (
                                <Pressable
                                  style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingVertical: 10,
                                    paddingHorizontal: 15,
                                    borderTopColor: '#B895EC',
                                    borderTopWidth: 0.5,
                                  }}
                                  _hover={{
                                    bg: Colors.primary[500],
                                  }}
                                  onPress={() => {
                                    navigate(menuItem.settingPath || '');
                                  }}
                                >
                                  <HStack alignItems={'center'}>
                                    {getIconsFromMainMenu(
                                      MAIN_MENU_CODES.SETTING,
                                      currentSelectedKey[0],
                                      props.menuCollaps
                                    )}
                                    <DisplayText
                                      size={'smMedium'}
                                      textLocalId={'settings'}
                                      extraStyles={{
                                        color: '#fff',
                                        marginLeft: 7.5,
                                        // currentSelectedKey[0] === menuItem.menuCode
                                        //   ? Colors.primary['600'] || ''
                                        //   : Colors.Custom.Gray700,
                                      }}
                                    />
                                  </HStack>
                                </Pressable>
                              ) : null} */}
                            </VStack>
                          }
                          trigger="hover"
                          placement="right"
                          overlayStyle={{borderRadius: 8}}
                        >
                          <View
                            style={{
                              alignItems: 'center',
                              width: 85,
                              height: 68,
                              justifyContent: 'center',
                            }}
                          >
                            {getIconsFromMainMenu(
                              menuItem.menuCode,
                              currentSelectedKey[0],
                              props.menuCollaps
                            )}
                            {props.menuCollaps ? (
                              <DisplayText
                                key={menuItem.menuCode}
                                size={'xsMedium'}
                                textLocalId={menuItem.menuName}
                                extraStyles={{
                                  color: '#fff',
                                  // currentSelectedKey[0] === menuItem.menuCode
                                  //   ? Colors.primary['600'] || ''
                                  //   : Colors.Custom.Gray700,
                                }}
                              />
                            ) : null}
                          </View>
                        </Popover>
                      ) : (
                        <Link
                          // key={'link_' + menuIndex}
                          to={menuItem.path || ''}
                          className="link-visited"
                          onClick={(e)=> {
                            if(menuItem.path === 'profile'){
                              e.preventDefault();
                              setOpenProfileModal(true);
                            }
                          }}
                        >
                          <View style={{alignItems: 'center'}}>
                            {getIconsFromMainMenu(
                              menuItem.menuCode,
                              currentSelectedKey[0],
                              props.menuCollaps
                            )}
                            {props.menuCollaps ? (
                              <DisplayText
                                key={menuItem.menuCode}
                                size={'xsNormal'}
                                textLocalId={menuItem.menuName}
                                extraStyles={{
                                  color: '#fff',
                                  fontWeight: '400',
                                  fontSize: 12,
                                  lineHeight: 20,
                                }}
                              />
                            ) : null}
                          </View>
                        </Link>
                      )}
                    </Pressable>
                  </View>
                )}
              </Menu.Item>
            );
          }
        )}
      </Menu>
      {openProfileModal &&
        <EmployerProfile
          close={()=>{
            setOpenProfileModal(false)
          }}
        />
      }
    </View>
  );
};

export default SideMenuBar;
