import React from 'react';
import {Svg, G, Path, Defs} from 'react-native-svg';

function DismissIconSvg() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <G clipPath="url(#clip0_254_1591)">
        <Path
          stroke="#D92D20"
          strokeLinecap="round"
          d="M12.333 3.667l-8.666 8.666m11-4.333A6.667 6.667 0 111.333 8a6.667 6.667 0 0113.334 0z"
        ></Path>
      </G>
      <Defs>
        <clipPath id="clip0_254_1591">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </clipPath>
      </Defs>
    </Svg>
  );
}

export default DismissIconSvg;
