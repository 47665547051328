import { Drawer, Table } from 'antd';
import { Skeleton, View, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { Colors } from '../../../../styles';
import { accountLocationUuidListByUserPracticeLocationList, getAccountUUID, getFoldLocations, getLocationNames, getUserPracticeLocations, getUserUUID, isLoginUserBusinessOwner } from '../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { ITabsList } from '../../../common/CustomTabsView/interfaces';
import {
  deleteCampaign,
  getCampaignsByStatus,
  getContactsWithinGroup,
  updateCampaignStatus
} from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import AddOrUpdateCampaign from './AddOrUpdateCampaign/AddOrUpdateCampaign';
import { CampaignAnalytics } from './CampaignAnalytics';
import { getCampaignColumns } from './Helper';
import { ICampaignData, ICampaignTableColumnsArgs, ILocation } from './interfaces';
import { useIntl } from 'react-intl';
import { isSubsetArray } from '../../Workflow/workflowUtils';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';

const {height, width} = Dimensions.get('window');
const finalWidth = width * 0.7;

const CampaignManagementTable = (props: any) => {
  const userUuid = getUserUUID()
  const navigate = useNavigate();
  const [campaignTableState, setCampaignTableState] = useState({
    isLoading: true,
    searchString: props?.campaignState?.searchString || '',
    campaignTableData: [] as ICampaignData[],
    campaignTableDataRaw: [] as ICampaignData[],
    page: 1,
    pageSize: 10,
    sort: 'desc',
    showCampaignAnalytics: false,
    selectedCampaign: {} as ICampaignData,
    showAddOrEdit: false,
    duplicate: false,
    selectedTab: {} as ITabsList,
  });
  const intl = useIntl();
  const isMsoEnabled = isAccountConfigEnabled( CONFIG_CODES.IS_MSO_ENABLED ) 
  const isBusinessOwner = isLoginUserBusinessOwner()
  const mlovData = useContext(CommonDataContext);
  const userPracticeLocationsObj = mlovData.userPracticeLocations;
  const [searchParams, setSearchParams] = useSearchParams();
  const [foldLocations, setFoldLocations] = useState([]);
  const createNew = !!searchParams.get('createNew')

  const accountUuid = getAccountUUID();
  const [descriptionData, setDescriptionData] = useState<any>({
    expandedTextState: {},
  })
  const [userPracticeLocations, setUserPracticeLocations] = useState< string[] >([]);

  const getUserPracticeLocation=async()=>{
    const practiceLocations = accountLocationUuidListByUserPracticeLocationList(userPracticeLocationsObj);
    setUserPracticeLocations(practiceLocations)
  }
  useEffect(() => {
    setCampaignTableState((prev) => {
      return {
        ...prev,
        ...props.campaignState
      };
    });
  }, [props]);

  useEffect(()=>{
    if(isMsoEnabled){
      getUserPracticeLocation()
    }
  },[])

  useEffect(() => {
    loadCampaigns();
  }, [campaignTableState.selectedTab, userPracticeLocations]);

  useEffect(() => {
      onTableTopBarSearch();
  }, [campaignTableState.searchString]);

  const loadCampaigns = async(): Promise<void> => {
    setCampaignTableState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    let fLocations = [...foldLocations]
    if (isMsoEnabled && !fLocations?.length) {
        fLocations = await getFoldLocations()
        if (fLocations?.length) setFoldLocations(fLocations)
    }

    const tabKeys = [campaignTableState.selectedTab?.key]
    if(tabKeys[0] === 'ENDED'){
      tabKeys.push('ABORTED')
    }
    getCampaignsByStatus(accountUuid, tabKeys, userPracticeLocations || [], isMsoEnabled, isBusinessOwner)
      .then(async (resp) => {
        if (resp?.campaigns) {
          let campaigns = resp?.campaigns
          if(isMsoEnabled){
            campaigns = await Promise.all(
              campaigns.map(async (campaign : ICampaignData) => {
                const result: ILocation[] = await getLocationNames(fLocations, campaign.campaignLocations);
                const names = result?.map((loc:ILocation) => loc?.name) || [];
                return {
                  ...campaign,
                  locationNames:names,
                };
              })
            );
          }
          setCampaignTableState((prev) => {

            return ({
            ...prev,
            campaignTableData: campaigns,
            campaignTableDataRaw: campaigns,
          }) });

          if(campaignTableState.searchString){
            onTableTopBarSearch()
          }


        }
      })
      .finally(() => {
        setCampaignTableState((prev) => ({
          ...prev,
          isLoading: false,
          showAddOrEdit: createNew ? true : prev.showAddOrEdit,
          selectedCampaign: createNew ? {} as ICampaignData : prev.selectedCampaign,
          duplicate: createNew ? false : prev.duplicate
        }));
        searchParams.delete('createNew')
        setSearchParams(searchParams);
      });
  };

  const onDeleteCampaign = (rowData: ICampaignData) => {
    const templateId = String(rowData.id);
    deleteCampaign({id: templateId}).then((resp) => {
      if (resp?.updateCampaign) {
        const newCampaignData = campaignTableState.campaignTableData.filter(
          (item) => item.id !== resp?.updateCampaign.id
        );
        setCampaignTableState((prev) => {
          return {
            ...prev,
            campaignTableData: newCampaignData,
          };
        });
      } else {
        loadCampaigns();
      }
    });
  };
  const toast = useToast();

  const onRowClicked = (rowData: ICampaignData) => {
    // on row clicked
    setCampaignTableState({
      ...campaignTableState,
      selectedCampaign: rowData,
      showAddOrEdit: true,
      duplicate: false,
    });
  };

  const onToggleEnable = async (rowData: ICampaignData, status?: boolean) => {
    loadCampaigns();
  };

  const onCampaignTableActionPerformed = (
    actionCode: string,
    actionData: ICampaignData,
    status?: boolean
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.COPY:
        setCampaignTableState({
          ...campaignTableState,
          selectedCampaign: actionData,
          showAddOrEdit: true,
          duplicate: true,
        });
        break;
      case COMMON_ACTION_CODES.EDIT:
        setCampaignTableState({
          ...campaignTableState,
          selectedCampaign: actionData,
          showAddOrEdit: true,
          duplicate: false,
        });
        break;

      case COMMON_ACTION_CODES.DELETE:
        onDeleteCampaign(actionData);
        break;

      case COMMON_ACTION_CODES.ROW_CLICKED:
        onRowClicked(actionData);
        break;
      case COMMON_ACTION_CODES.ENABLE:
        onToggleEnable(actionData, status);
        break;
      case COMMON_ACTION_CODES.SHOW_GRAPH:
        setCampaignTableState((prev) => {
          return {
            ...prev,
            showCampaignAnalytics: true,
            selectedCampaign: actionData,
          };
        });
        break;
    }
  };

  const onTableTopBarSearch = () => {

    setCampaignTableState((prev) => {
      const newCampaignData = prev.campaignTableDataRaw.filter(
        (item) => item.name?.toLowerCase().includes(prev.searchString?.toLowerCase().trim())
      );
      return {
        ...prev,
        campaignTableData: newCampaignData,
      };
    });
  };

  const campaignTableColumnArgs: ICampaignTableColumnsArgs = {
    actionFn: onCampaignTableActionPerformed,
    setDescriptionData: setDescriptionData,
    descriptionData: descriptionData,
    userPracticeLocations: userPracticeLocations,
    isMsoEnabled: isMsoEnabled
  };
  return (
      <View
        style={[
          {
            backgroundColor: '#fff',
            borderColor: Colors.Custom.BorderColor,
            borderWidth: 1,
            borderTopWidth: 0
          },
        ]}
      >
        <View>
          {!campaignTableState?.isLoading && (
            <View
              style={{
                backgroundColor: '#fff',
                borderColor: Colors.Custom.BorderColor,
                //borderWidth: 1,
                borderRadius: 8,
                overflow: 'hidden',
              }}
            >
              <Table
                // rowClassName={(record, index) =>
                //   index % 2 == 0 ? 'table-row-light' : ''
                // }
                className="lead-list-table-view patient-list-view"
                scroll={{x: 1500, y: height - 240}}
                dataSource={campaignTableState?.campaignTableData || []}
                columns={getCampaignColumns(campaignTableColumnArgs)}
                // onChange={(pagination, filters, sorter: any) => {
                // }}
                rowKey={(row) => row.id as string}
                onRow={(_record: any) => {
                  const isAllowed = isMsoEnabled ? isSubsetArray(_record?.campaignLocations, userPracticeLocations) : true;
                  return {
                    onClick: () => {
                      if (isAllowed || isBusinessOwner) {
                        onRowClicked(_record);
                      }
                    },
                    style: {
                      cursor: (isAllowed || isBusinessOwner) ? 'pointer' : 'not-allowed'
                    },
                  };
                }}
                pagination={{
                  current: campaignTableState.page,
                  pageSize: campaignTableState.pageSize,
                  position: ['bottomCenter'],
                  onChange: (currentPage, currentPageSize) => {
                    setCampaignTableState((prev) => {
                      return {
                        ...prev,
                        page: currentPage,
                        pageSize: currentPageSize,
                      };
                    });
                  },
                }}
              />
            </View>
          )}

          {campaignTableState?.isLoading && <Skeleton.Text lines={4} />}
          <Drawer
            visible={campaignTableState?.showAddOrEdit}
            width={finalWidth}
            onClose={() => {
              setCampaignTableState((prev:any) => {
                return {
                  ...prev,
                  selectedCampaign: {}
                };
              });
              props.closeDrawer();
            }}
            headerStyle={{
              display: 'none',
            }}
          >
            {campaignTableState?.showAddOrEdit ? (
              <AddOrUpdateCampaign
                shouldShowEdit={
                  campaignTableState?.selectedCampaign?.id  ?  (campaignTableState?.selectedCampaign?.status == 'DRAFT' || campaignTableState?.duplicate) : campaignTableState?.showAddOrEdit
                }
                initData={campaignTableState?.selectedCampaign}
                isDuplicate={campaignTableState?.duplicate}
                close={() => {
                  setCampaignTableState((prev:any) => {
                    return {
                      ...prev,
                      selectedCampaign: {}
                    };
                  });
                  loadCampaigns();
                  props.closeDrawer();
                }}
                showLeadAndPatient={true}
              />
            ) : null}
          </Drawer>
          {campaignTableState.showCampaignAnalytics && (
            <CampaignAnalytics
              data={campaignTableState.selectedCampaign}
              isOpen={campaignTableState.showCampaignAnalytics}
              onClose={() => {
                setCampaignTableState((prev:any) => {
                  return {
                    ...prev,
                    selectedCampaign: {},
                    showCampaignAnalytics: false,
                  };
                });
              }}
              userPracticeLocations={userPracticeLocations ?? []}
              foldLocations={foldLocations}
            />
          )}
        </View>
      </View>
  );
};

export default CampaignManagementTable;
