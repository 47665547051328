import { IGroupConversation, IGroupMember } from './../../../../../../services/Conversations/interfaces';
import {
  getPatientDataFromGroupMemberList,
  isGroupConversation,
  isPrivateGroupConversation,
  isUserGroupsGroup,
} from './../../MessagingWindow/MessagingUtils';
import {uniqBy} from 'lodash';
import moment from 'moment';
import {Platform} from 'react-native';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {ILoginUserData, IMlov} from '../../../../../../Interfaces';
import {
  IConversationCreated,
  IMessageCreated,
  IMessageCreatedFormattedData,
} from '../../../../../../Interfaces/WebSocketEventInterfaces';
import {Colors} from '../../../../../../styles/Colors';
import {getMlovValueFromId} from '../../../../../../utils/mlovUtils';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {
  ASSIGNED_CODE,
  CHANNEL_TYPE,
  CHANNEL_TYPE_CODE,
  CONVERSATION_TAB_CODES,
  CONVERSATION_SUBTAB_CODES,
} from '../../ConversationConst';
import {ICallConversationActivities, IContact, IConversationData, IConversationMentionsData, IConversationMessage, IEFaxConversationActivities, IEmailConversationActivities, INewConversationResponse, MESSAGE_TYPE} from '../../interfaces';
import {getChannelTypeFilter} from '../ConversationFilters/ConversationFilterUtils';
import {CONVERSATION_STATUS, GROUP_MEMBER_TYPE, MODULE_PAGINATION_COUNT, PATIENT_SEARCH_MODULE_CODE, SENDER_TYPE} from '../../../../../../constants/StringConst';
import {assignStatusValue} from '../ConversationConst';
import {CALL_MESSAGE_TYPE_NUM, MESSAGE_CUSTOM_CARD_TYPE, IO_MESSAGE_TYPE_NUM} from '../../MessagingWindow/MessagingCustomCard/CustomCardConst';
import {getChosenNameConditionV2, getPatientSearchORCondition} from '../../../../Contacts/ContactsUtils';
import {SUPPORTED_EVENT_CODE} from '../../../../../../constants/WebSocketConst';
import { getUserId, isAllCallsChannelInboxType, isEFaxChannelInbox, isEmailChannelInboxType } from '../../../../../../utils/commonUtils';
import {isNumeric} from '../../../../../../utils/commonUtils';
import { IChannelTypes } from '../../ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import {EMAIL_INBOX_USER_CODE} from '../../../../../common/PreferencesSetting/PreserenceConst';
import {IConversationFilter} from './interface';
import { IConversationFilterValue } from '../../ConversationChannelNew/Interfaces';

export const getFormattedConversationDataComingFromWS = (
  wsConversationData: IConversationCreated,
  statusMlovList: IMlov[],
  conversationFilter: any,
  selectedInboxTypeCode: string

): IConversationData => {
  let newConversationData: IConversationData = {} as any;

  let conversationStatus = 0;

  if (wsConversationData.status) {
    const value = getMlovValueFromId(
      statusMlovList,
      wsConversationData?.status || ''
    );
    conversationStatus = parseInt(value);
  }
  if (
    isConversationFilterApplied(
      conversationFilter,
      conversationStatus,
      wsConversationData?.contact_inbox?.id,
      selectedInboxTypeCode,
      wsConversationData as any
    )
  ) {
    newConversationData = {
      accountId: wsConversationData.account_id,
      additionalAttributes: wsConversationData?.additional_attributes as any,
      assigneeId: 0,
      contactId: wsConversationData?.contact_inbox?.contact_id,
      id: wsConversationData?.id,
      uuid: '',
      displayId: wsConversationData?.id,
      unreadMessages: [],
      unreadMsgCount: 0,
      conversationMessages: [],
      conversationContact: {} as any,
      conversationInbox: {
        id: wsConversationData?.inbox_id || -1,
      } as any,
      inboxId: wsConversationData?.inbox_id || -1,
      status: conversationStatus,
      assignedUser: {} as any,
      groupConversation: {} as any,
    };
  }
  return newConversationData;
};

export const isConversationFilterApplied = (
  conversationFilter: IConversationFilter,
  conversationStatus = 0,
  currentInboxId: number,
  selectedInboxTypeCode: string,
  receivedMsgData: IMessageCreated,
) => {

  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
    const assigneeIds = getAssigneeIdsFromFilter(conversationFilter);
    const incomingEventCode = receivedMsgData?.eventCode;
    let isFilterApplied = false;
    if (assigneeIds?.length && receivedMsgData?.conversation?.assignee_id) {
      if (assigneeIds?.includes(receivedMsgData?.conversation?.assignee_id)) {
        isFilterApplied = true;
      }
    } else if (incomingEventCode === SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED) {
      const assigneeId = receivedMsgData?.additionalData?.assigneeId || receivedMsgData?.additional_data?.assigneeId;
      if (assigneeId && assigneeIds?.includes(assigneeId)) {
        isFilterApplied = true;
      }
    }
    return isFilterApplied;
  } else {
    const statusValue = getStatusFromFilter(conversationFilter);
    const inboxId = getInboxIdFromFilter(conversationFilter);
    const isFilterApplied =
      (statusValue === -1 && inboxId === -1) ||
      (statusValue === conversationStatus && inboxId === -1) ||
      (inboxId === currentInboxId && statusValue === -1) ||
      (inboxId === currentInboxId && statusValue === conversationStatus);
    return isFilterApplied;
  }
};

export const getFormattedMsgData = (
  data: IMessageCreated
): IConversationMessage => {
  let createdAt = '';
  let updatedAt = '';
  if (data?.data && Object.keys(data)?.length) {
    data = data?.data;
  }
  if (data.created_at || data.createdAt) {
    const createdAtDate = moment(data.created_at || data.createdAt);
    createdAt = createdAtDate.toISOString();
  }
  if (data.updated_at || data.updatedAt) {
    const updatedAtDate = moment(data.updated_at || data.updatedAt);
    updatedAt = updatedAtDate.toISOString();
  }
  const msgData: IConversationMessage = {
    id: data.id,
    content: data.content,
    senderId: data.sender_id,
    senderType: data.sender_type,
    contentType: data.content_type,
    createdAt: createdAt,
    updatedAt: updatedAt,
    messageType: data.message_type ?? data.messageType,
    status: data.status,
    sourceId: data.source_id,
    senderContact: data?.sender_type?.toLowerCase() == 'contact' ? ((data?.sender || {}) as any) : {} as any,
    senderUser: data?.sender_type?.toLowerCase() == 'user' ? ((data?.sender || {}) as any) : {} as any,
    messageAttachments: [],
    private: data.private,
    uuid: data?.uuid || '',
    contentAttributes: data?.content_attributes || data?.contentAttributes || {}
  };
  if (data?.attachments && data.attachments.length) {
    msgData.messageAttachments = [
      {
        activeStorageAttachments: [
          {
            activeStorageBlob: {
              filename: 'File 1',
              contentType:
                data.attachments[data.attachments.length - 1]?.file_type ||
                'file',
            },
          },
        ],
      },
    ];
  }
  return msgData;
};

export const getFormattedMsgDataForNewChange = (
  formattedMessageData: IMessageCreatedFormattedData
): IConversationMessage => {
  let createdAt = '';
  let updatedAt = '';
  if (formattedMessageData.createdAt) {
    const createdAtDate = moment(formattedMessageData.createdAt);
    createdAt = createdAtDate.toISOString();
  }
  if (formattedMessageData.updatedAt) {
    const updatedAtDate = moment(formattedMessageData.updatedAt);
    updatedAt = updatedAtDate.toISOString();
  }
  const msgData: IConversationMessage = {
    id: formattedMessageData.id,
    content: formattedMessageData.content,
    senderId: formattedMessageData.senderId,
    senderType: formattedMessageData.senderType,
    contentType: formattedMessageData.contentType,
    createdAt: createdAt,
    updatedAt: updatedAt,
    messageType: formattedMessageData.messageType,
    status: formattedMessageData.status,
    senderContact: formattedMessageData?.senderType?.toLowerCase() == 'contact' ? ((formattedMessageData?.sender || {}) as any) : {} as any,
    senderUser: formattedMessageData?.senderType?.toLowerCase() == 'user' ? ((formattedMessageData?.sender || {}) as any) : {} as any,
    messageAttachments: [],
    private: formattedMessageData.private,
    uuid: formattedMessageData?.uuid || '',
    contentAttributes: formattedMessageData?.contentAttributes || {}
  };
  if (formattedMessageData?.attachments && formattedMessageData.attachments.length) {
    msgData.messageAttachments = [
      {
        activeStorageAttachments: [
          {
            activeStorageBlob: {
              filename: 'File 1',
              contentType:
                formattedMessageData.attachments[formattedMessageData.attachments.length - 1]?.file_type ||
                'file',
            },
          },
        ],
      },
    ];
  }
  return msgData;
};
export const getInboxIdFromFilter = (conversationFilter: IConversationFilter): number => {
  let inboxId = -1;
  if (conversationFilter?.inboxId) {
    inboxId = conversationFilter?.inboxId;
  }
  return inboxId;
};

const getStatusFromFilter = (conversationFilter: IConversationFilter): number => {
  let statusId = -1;
  if (conversationFilter?.statusId) {
    statusId = conversationFilter?.statusId;
  }
  return statusId;
};

export const getAssigneeIdsFromFilter = (conversationFilter: IConversationFilter): number[] => {
  const assigneeIdList = conversationFilter?.assignedUsersIds || [];
  return assigneeIdList;
};


export const isConversationPresent = (
  conversationList: IConversationData[],
  contactId: number
): boolean => {
  return conversationList.some((conversation) => {
    return conversation.contactId === contactId;
  });
};

export const isConversationPresentByConversationId = (
  conversationList: IConversationData[],
  conversationId: number,
  selectedInboxTypeCode?: string,
  conversationUuid?: string
): boolean => {
  if (conversationUuid) {
    return conversationList.some((conversation) => {
      return conversation.uuid === conversationUuid;
    });
  } else {
    return conversationList.some((conversation) => {
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS || selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ALL) {
        return conversation.id === conversationId;
      }
      return conversation.displayId === conversationId;
    });
  }

};


export const isConversationPresentByConversationUuid = (
  conversationList: IConversationData[],
  conversationUuid: string
): boolean => {
  let isConversationPresent = false;
  if (conversationUuid && conversationList?.length) {
    isConversationPresent = conversationList?.some((conversation) => {
      return conversation?.uuid === conversationUuid;
    });
  }
  return isConversationPresent;
};

export const isConversationFilterAppliedV2 = (
  conversationFilter: IConversationFilter,
): boolean => {
  let isConversationFilterPresent = false;
  if (conversationFilter?.assignedUsersIds ||
      (conversationFilter?.dateRange?.fromDate && conversationFilter?.dateRange?.toDate) ||
      conversationFilter?.inboxId ||
      conversationFilter?.locationUuids ||
      conversationFilter?.readFilter
    ) {
      isConversationFilterPresent = true;
  }
  return isConversationFilterPresent;
};

export const isConversationMentionPresentByConversationUuid = (
  conversationList: IConversationMentionsData[],
  conversationUuid: string
): boolean => {
  let isConversationPresent = false;
  if (conversationUuid && conversationList?.length) {
    isConversationPresent = conversationList?.some((conversation) => {
      return conversation?.conversation?.uuid === conversationUuid;
    });
  }
  return isConversationPresent;
};

export const getBgColorForConversationItem = (
  item: IConversationData,
  newAddedConversation: IConversationData,
  selectedConversation: IConversationData,
  selectedInboxTypeCode: string,
  isSideCarContext?: boolean,
): string => {
  let bgColor = isWeb() ? '' : '#FFF';
  if (isWeb()) {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      if (newAddedConversation && newAddedConversation.conversationMentionId === item.conversationMentionId) {
        bgColor = Colors.Custom.Gray100;
      } else if (selectedConversation && selectedConversation.conversationMentionId === item.conversationMentionId) {
        bgColor = Colors.Custom.Gray100;
      }
    } else {
      if (isSideCarContext === true) {
        // no need to show selected in side car context
      } else {
        if (selectedConversation && selectedConversation.id === item.id) {
          bgColor = Colors.Custom.Gray100;
        }
      }
    }
  }
  if (item?.conversationMessages[0]?.private) {
    bgColor = '#FFF6F2'
  }
  return bgColor;
};

export const getStyleForRemovedConversationFromList = (selectedInboxTypeCode: string, conversationData: IConversationData, currentUserId: number | string) => {
  switch (selectedInboxTypeCode) {
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME: {
      if (conversationData?.assignedUser?.id !== currentUserId) {
        return {
          opacity: 0.6
        };
      }
      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED: {
      if (conversationData?.assignedUser?.uuid) {
        return {
          opacity: 0.6
        };
      } else if (conversationData?.status === CONVERSATION_STATUS.RESOLVED && conversationData?.conversationMessages[0]?.messageType === 2) {
        return {
          opacity: 0.6
        };
      }

      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED: {
      if (
        conversationData?.status === CONVERSATION_STATUS.OPEN
      ) {
        return {
          opacity: 0.6,
        };
      }
      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS: {
      if (conversationData?.status === CONVERSATION_STATUS.RESOLVED) {
        return {
          opacity: 0.6
        };
      }
      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_ALL:
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX: {
      if (conversationData?.status === CONVERSATION_STATUS.RESOLVED && conversationData?.conversationMessages[0]?.messageType === 2) {
        return {
          opacity: 0.6
        };
      }
      break;
    }
  }
  return {};
}
export const getStyleSelectedConversationItem = (
  item: IConversationData,
  selectedConversation: IConversationData,
  selectedInboxTypeCode: string,
  currentUserId: number | string
): any => {
  let selectedConversationStyle: any = {};
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    if (selectedConversation && selectedConversation.conversationMentionId === item.conversationMentionId) {
      selectedConversationStyle = {
        backgroundColor: Colors.Custom.Gray100,
      };
    }
  } else {
    if (selectedConversation && selectedConversation.id === item.id) {
      selectedConversationStyle = {
        backgroundColor: Colors.Custom.Gray100,
      };
    }
    selectedConversationStyle = getStyleForRemovedConversationFromList(selectedInboxTypeCode, item, currentUserId)
  }
  return selectedConversationStyle;
};

export const getUniqueConversationList = (
  conversationList: IConversationData[],
  isMentionTab?: boolean
): IConversationData[] => {
  const finalConversationList = [] as IConversationData[];
  let tempConversationList = conversationList;
  if (!isMentionTab)
   tempConversationList = uniqBy(conversationList, (conversationData) => {
    return conversationData.id;
  });

  tempConversationList?.forEach((singleConversation) => {
    if (singleConversation.inboxId > 0) {
      if (
        singleConversation?.conversationContact &&
        !singleConversation?.conversationContact?.isDeleted
      ) {
        finalConversationList.push(singleConversation);
      }
    } else if (
      isGroupConversation(singleConversation) &&
      isPrivateGroupConversation(singleConversation)
    ) {
      const {contactData} = getPatientDataFromGroupMemberList(
        singleConversation?.groupConversation?.groupMembers || []
      );
      if (!contactData?.isDeleted) {
        finalConversationList.push(singleConversation);
      }
    } else {
      finalConversationList.push(singleConversation);
    }
  });

  return finalConversationList;
};

export const getUniqueConversationListByUuid = (
  conversationList: IConversationData[]
): IConversationData[] => {
  if (conversationList?.length) {
    const finalConversationList = uniqBy(conversationList, (conversationData) => {
      return conversationData?.uuid;
    });
    return finalConversationList;
  }
  return [];
};

export const getConversationListAfterMessageReceived = (
  conversationList: IConversationData[],
  selectedConversation: IConversationData,
  messageData: IMessageCreated,
  userId: string,
  selectedInboxTypeCode: string,
  selectedSubtabCode?: string,
): IConversationData[] => {
  let finalConversationList = [] as IConversationData[];
  if (messageData?.data && Object.keys(messageData)?.length) {
    messageData = messageData?.data;
  }
  conversationList = uniqBy(conversationList, (conversationData) => {
    return conversationData.id;
  });
  const msgData = getFormattedMsgData(messageData);
  let isConversationUuidMatched = false;
  for (let index = 0; index < conversationList.length; index++) {
    const singleConversationItem = conversationList[index];
    if (singleConversationItem.uuid === messageData?.conversation_uuid || singleConversationItem.uuid === messageData?.conversationUuid) {
      isConversationUuidMatched = true;
      break;
    }

  }
  conversationList.forEach((singleConversationData) => {
    let finalSingleConversationData = {...singleConversationData};
    const messageType = messageData?.message_type ?? messageData?.messageType;
    if (isConversationUuidMatched) {
      if (
        canShowRealTimeMessage(selectedInboxTypeCode, messageType) &&
        (singleConversationData?.uuid === messageData?.conversation_uuid ||
          singleConversationData?.uuid === messageData?.conversationUuid)
      ) {
        let unreadMessages = [
          ...singleConversationData.unreadMessages ?? [],
          {
            id: msgData.id,
            messageType: messageType,
          },
        ];
        let unreadMsgCount = (singleConversationData?.unreadMsgCount || 0) + 1;
        let isOnline = false;
        if (
          isWeb() &&
          (selectedConversation.displayId ===
            singleConversationData.displayId ||
            messageData?.sender?.id?.toString() == userId)
        ) {
          unreadMessages = [];
          unreadMsgCount = 0;
        } else if (messageData?.sender?.id?.toString() == userId) {
          unreadMessages = [];
          unreadMsgCount = 0;
        }
        if (
          singleConversationData.contactId &&
          messageData.sender &&
          messageData.sender.id === singleConversationData.contactId &&
          messageData.sender.type &&
          messageData.sender.type === 'contact'
        ) {
          isOnline = true;
        }
        let updateMsgData = msgData;
        if (isEmailChannelInboxType(selectedInboxTypeCode)) {
          const senderType = messageData?.sender?.type ?? messageData?.senderType;
          if (
            selectedSubtabCode === CONVERSATION_SUBTAB_CODES.INBOX &&
            senderType !== SENDER_TYPE.CONTACT
          ) {
            updateMsgData = {
              ...updateMsgData,
              updatedAt:
                singleConversationData.conversationMessages?.[0]?.updatedAt,
            };
          } else if (
            selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT &&
            senderType !== SENDER_TYPE.USER
          ) {
            updateMsgData = {
              ...updateMsgData,
              updatedAt:
                singleConversationData.conversationMessages?.[0]?.updatedAt,
            };
          }
        }

        finalSingleConversationData = {
          ...singleConversationData,
          conversationMessages: [updateMsgData],
          conversationContact: {
            ...singleConversationData.conversationContact,
            isOnline: isOnline,
          },
          unreadMessages: [...unreadMessages] || [],
          unreadMsgCount: unreadMsgCount,
        };
        finalConversationList = [
          finalSingleConversationData,
          ...finalConversationList,
        ];
      } else {
        finalConversationList.push(finalSingleConversationData);
      }
    } else {
      if ((CALL_MESSAGE_TYPE_NUM?.includes(messageType)
        && singleConversationData.id === messageData.conversation_id) || (singleConversationData.displayId === messageData.conversation_id)) {
        let unreadMessages = [
          ...singleConversationData.unreadMessages,
          {
            id: msgData.id,
            messageType: messageType,
          },
        ];
        let unreadMsgCount = (singleConversationData?.unreadMsgCount || 0) + 1
        let isOnline = false;
        if (
          isWeb() &&
          (selectedConversation.displayId === singleConversationData.displayId ||
            messageData?.sender?.id?.toString() == userId)
        ) {
          unreadMessages = [];
          unreadMsgCount = 0;
        } else if (messageData?.sender?.id?.toString() == userId) {
          unreadMessages = [];
          unreadMsgCount = 0;
        }
        if (
          singleConversationData.contactId &&
          messageData.sender &&
          messageData.sender.id === singleConversationData.contactId &&
          messageData.sender.type &&
          messageData.sender.type === 'contact'
        ) {
          isOnline = true;
        }
        finalSingleConversationData = {
          ...singleConversationData,
          conversationMessages: [msgData],
          conversationContact: {
            ...singleConversationData.conversationContact,
            isOnline: isOnline,
          },
          unreadMessages: [...unreadMessages] || [],
          unreadMsgCount: unreadMsgCount,
        };
        finalConversationList = [
          finalSingleConversationData,
          ...finalConversationList,
        ];
      } else {
        finalConversationList.push(finalSingleConversationData);
      }
    }
  });
  return finalConversationList;
};

export const canShowRealTimeMessage = (
  selectedInboxTypeCode: string,
  messageType: number
) => {
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS) {
    return CALL_MESSAGE_TYPE_NUM.includes(messageType);
  }
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
    return IO_MESSAGE_TYPE_NUM.includes(messageType);
  }
  return true;
};

export const getConversationListAfterReadMessages = (
  conversationList: IConversationData[],
  readConversationData: IConversationData
): IConversationData[] => {
  const finalConversationList = [] as IConversationData[];
  conversationList.forEach((singleConversationData) => {
    let finalSingleConversationData = {...singleConversationData};
    if (singleConversationData.id === readConversationData.id) {
      finalSingleConversationData = {
        ...singleConversationData,
        unreadMessages: [],
        unreadMsgCount: 0
      };
    }
    finalConversationList.push(finalSingleConversationData);
  });
  return finalConversationList;
};

export const getConversationListSearchCondition = (
  searchString: string,
  selectedTabCode: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  selectedInboxTypeCode: string = CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
  userData: ILoginUserData,
  userTypeId: any,
  isEFaxMessagingEnabled: boolean,
  selectedSubtabCode?: string,
  emailInboxUserPreference?: string,
  otherContactTypeId?: string,
  isEmailUserPreferenceEnabled?: boolean,
  conversationUserFilterData?: IConversationFilterValue
) => {
  let searchFilter: any = {};
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1
  })
  const channelType: IChannelTypes[]= [CHANNEL_TYPE.CHANNEL_TWILIO_SMS,CHANNEL_TYPE.CHANNEL_EMAIL]
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX)
  }
  const conversationContactSearchCondition = getConversationContactSearchCondition(searchString, searchStringArray);
  switch (selectedInboxTypeCode) {
    case CHANNEL_TYPE_CODE.CHANNEL_ALL:
      searchFilter = getAllChannelSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        isEFaxMessagingEnabled,
        emailInboxUserPreference,
        otherContactTypeId,
        isEmailUserPreferenceEnabled,
        conversationUserFilterData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
      searchFilter = getWebChannelWidgetSearchFilter(
        searchString,
        selectedTabCode,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        conversationUserFilterData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
      searchFilter = getSMSConversationSearchFilter(userData, conversationContactSearchCondition, conversationUserFilterData)
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
      {
        const channelTypeObj: any = getChannelTypeFilter(CHANNEL_TYPE_CODE.CHANNEL_EMAIL, userData) || {};
        delete channelTypeObj?.status;
        const inboxId = conversationUserFilterData?.inboxId;
        const emailSearchFilterData = {
          ...(inboxId && inboxId > 0
            ? {inboxId: {_eq: inboxId}}
            : {inboxId: {_neq: -1}}),
          ...channelTypeObj,
          _or: [
            {
              conversationContact: {
                ...conversationContactSearchCondition,
                ...((isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER && otherContactTypeId) ? {
                  contactType: {
                    contactType: {
                      id: {
                        _in: [otherContactTypeId]
                      }
                    }
                  }
                } : {}),
                ...((isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD && otherContactTypeId) ? {
                  contactType: {
                      contactType: {
                        id: {
                          _nin: [otherContactTypeId]
                        }
                      }
                    }
                } : {}),
              }
            },
            {
              subject: {
                _ilike: searchText,
              },
              ...((isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER && otherContactTypeId) ? {
                conversationContact: {
                  contactType: {
                    contactType: {
                      id: {
                        _in: [otherContactTypeId]
                      }
                    }
                  }
                }
              } : {}),
              ...((isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD && otherContactTypeId) ? {
                conversationContact: {
                  contactType: {
                    contactType: {
                      id: {
                        _nin: [otherContactTypeId]
                      }
                    }
                  }
                }
              } : {}),
            },
          ]
        };
        searchFilter = {
          conversations: emailSearchFilterData,
          isDeleted: {
            _eq: false,
          }
        };
        if (isEmailChannelInboxType(selectedInboxTypeCode)) {
          if (selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT) {
            searchFilter.lastOutgoingEmailAt = {
              _is_null: false
            }
          } else {
            searchFilter.lastIncomingEmailAt = {
              _is_null: false
            };
          }
        }
      }
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
      {
        const channelTypeObj: any =
          getChannelTypeFilter(CHANNEL_TYPE_CODE.CHANNEL_E_FAX, userData) || {};
        delete channelTypeObj?.status;
        const inboxId = conversationUserFilterData?.inboxId;
        searchFilter = {
          conversations: {
            ...(inboxId && inboxId > 0
              ? {inboxId: {_eq: inboxId}}
              : {inboxId: {_neq: -1}}),
            ...channelTypeObj,
            conversationContact: conversationContactSearchCondition,
          },
          isDeleted: {
            _eq: false,
          },
        };
        if (isEFaxChannelInbox(selectedInboxTypeCode)) {
          if (selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT) {
            searchFilter.lastOutgoingEFaxAt = {
              _is_null: false
            }
          } else {
            searchFilter.lastIncomingEFaxAt = {
              _is_null: false
            };
          }
        }
      }
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_INTERNAL:
      {
        searchFilter = getInterUserSearchFilter(searchString, groupTypeCodeIdObj, userUuid) || {};
      }
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_MENTION:
      searchFilter = getMentionConversationSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        userTypeId,
        isEFaxMessagingEnabled,
        conversationUserFilterData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED:
      searchFilter = getArchivedConversationSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        isEFaxMessagingEnabled,
        conversationUserFilterData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME:
      searchFilter = getAssignMeConversationSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        isEFaxMessagingEnabled,
        conversationUserFilterData
        );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS:
      searchFilter = getMisscedcallConversationSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        conversationUserFilterData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER:
      searchFilter = getAssignedOtherConversationSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        isEFaxMessagingEnabled,
        conversationUserFilterData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED:
      searchFilter = getUnAssignedSearchFilter(
        searchString,
        groupTypeCodeIdObj,
        userUuid,
        userData,
        isEFaxMessagingEnabled);
      break;
    case CHANNEL_TYPE_CODE.ALL_CALLS:
        searchFilter = getAllCallsConversationSearchFilter(
          searchString,
          groupTypeCodeIdObj,
          userUuid,
          userData,
          conversationUserFilterData
        );
      break;
  }

  return searchFilter;
};

const getMentionConversationSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  userTypeId: any,
  isEFaxMessagingEnabled: boolean,
  conversationUserFilterData?: IConversationFilterValue
) => {
  let searchFilter: any = {};
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
    CHANNEL_TYPE.CHANNEL_WEB_WIDGET,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  const locationUuids = conversationUserFilterData?.locationUuids;
  const inboxId = conversationUserFilterData?.inboxId;
  const readFilter = conversationUserFilterData?.readFilter;
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1;
  });
  const conversationContactSearchCondition =
    getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    userId: {
      _eq: userUuid,
    },
    userTypeId: {_eq: userTypeId},
    ...(readFilter === true
      ? {isRead: true}
      : readFilter === false
      ? {isRead: false}
      : {}),
    conversation: {
      conversationMentions: {
        userType: {
          code: {
            _eq: 'USER',
          },
        },
        userId: {
          _eq: userUuid,
        },
      },
      ...(locationUuids?.length && inboxId && inboxId > 0
        ? {
            _or: [
              {
                inboxId: {_eq: inboxId},
                conversationInbox: {
                  channelType: {
                    _in: channelType,
                  },
                  inboxMembers: {
                    userId: {
                      _eq: userData.id,
                    },
                  },
                },
                _or: [
                  {
                    conversationContact: conversationContactSearchCondition,
                  },
                  {
                    subject: {
                      _ilike: searchText,
                    },
                  },
                ],
              },
              {
                practiceLocationUuid: {
                  _in: locationUuids,
                },
                groupConversation: {
                  name: {
                    _ilike: searchText,
                  },
                  groupMembers: {
                    groupUserId: {
                      _eq: userUuid,
                    },
                  },
                },
              },
            ],
          }
        : locationUuids?.length
        ? {
            practiceLocationUuid: {
              _in: locationUuids,
            },
            groupConversation: {
              name: {
                _ilike: searchText,
              },
              groupMembers: {
                groupUserId: {
                  _eq: userUuid,
                },
              },
            },
          }
        : inboxId && inboxId > 0
        ? {
            inboxId: {_eq: inboxId},
            conversationInbox: {
              channelType: {
                _in: channelType,
              },
              inboxMembers: {
                userId: {
                  _eq: userData.id,
                },
              },
            },
            _or: [
              {
                conversationContact: conversationContactSearchCondition,
              },
              {
                subject: {
                  _ilike: searchText,
                },
              },
            ],
          }
        : {
            _or: [
              {
                inboxId: {
                  _neq: -1,
                },
                conversationInbox: {
                  channelType: {
                    _in: channelType,
                  },
                  inboxMembers: {
                    userId: {
                      _eq: userData.id,
                    },
                  },
                },
                _or: [
                  {
                    conversationContact: conversationContactSearchCondition,
                  },
                  {
                    subject: {
                      _ilike: searchText,
                    },
                  },
                ],
              },
              {
                groupConversation: {
                  name: {
                    _ilike: searchText,
                  },
                  groupMembers: {
                    groupUserId: {
                      _eq: userUuid,
                    },
                  },
                },
              },
            ],
          }),
    },
  };
  return searchFilter;
};

const getMisscedcallConversationSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  conversationUserFilterData?: IConversationFilterValue
) => {
  let searchFilter: any = {};
  const inboxId = conversationUserFilterData?.inboxId;
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1
  })
  const conversationContactSearchCondition = getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    _or: [
      {
        ...((inboxId && inboxId > 0) ? {inboxId: {_eq: inboxId}} : {inboxId: {_neq: -1}}),
        conversationInbox: {
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        conversationMessages: {
          messageType: {
            _eq: MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD
          }
        },
        lastMissCallAt: {
          _is_null: false,
        },
        conversationContact: conversationContactSearchCondition,
        status: {_neq: assignStatusValue.ARCHIVED},
      }
    ],
  };
  return searchFilter;
};

const getAllCallsConversationSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  conversationUserFilterData?: IConversationFilterValue
) => {
  let searchFilter: any = {};
  let searchStringArray = searchString.split(' ');
  const inboxId = conversationUserFilterData?.inboxId;
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1;
  });
  const conversationContactSearchCondition =
    getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    _or: [
      {
        ...(inboxId && inboxId > 0
          ? {inboxId: {_eq: inboxId}}
          : {inboxId: {_neq: -1}}),
        conversationInbox: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          inboxMembers: {
            userId: {
              _eq: userData.id,
            },
          },
        },
        conversationMessages: {
          messageType: {
            _eq: MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD,
          },
        },
        conversationContact: conversationContactSearchCondition,
        status: {_neq: assignStatusValue.ARCHIVED},
      },
    ],
  };
  return searchFilter;
};

const getAssignMeConversationSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  isEFaxMessagingEnabled: boolean,
  conversationUserFilterData?: IConversationFilterValue
) => {
  let searchFilter: any = {};
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  const locationUuids = conversationUserFilterData?.locationUuids;
  const inboxId = conversationUserFilterData?.inboxId;
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1;
  });
  const conversationContactSearchCondition =
    getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    assigneeId: {_eq: userData.id},
    status: {_neq: assignStatusValue.ARCHIVED},
    ...(locationUuids?.length && inboxId && inboxId > 0
      ? {
          _or: [
            {
              inboxId: {_eq: inboxId},
              conversationInbox: {
                channelType: {
                  _in: channelType,
                },
                inboxMembers: {
                  userId: {
                    _eq: userData.id,
                  },
                },
              },
              _or: [
                {
                  conversationContact: conversationContactSearchCondition,
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                },
              ],
            },
            {
              practiceLocationUuid: {
                _in: locationUuids,
              },
              groupConversation: {
                groupType: {
                  code: {
                    _in: 'PRIVATE',
                  },
                },
                name: {_ilike: searchText},
              },
            },
          ],
        }
      : locationUuids?.length
      ? {
          practiceLocationUuid: {
            _in: locationUuids,
          },
          groupConversation: {
            groupType: {
              code: {
                _in: 'PRIVATE',
              },
            },
            name: {_ilike: searchText},
          },
        }
      : inboxId && inboxId > 0
      ? {
          inboxId: {_eq: inboxId},
          conversationInbox: {
            channelType: {
              _in: channelType,
            },
            inboxMembers: {
              userId: {
                _eq: userData.id,
              },
            },
          },
          _or: [
            {
              conversationContact: conversationContactSearchCondition,
            },
            {
              subject: {
                _ilike: searchText,
              },
            },
          ],
        }
      : {
          _or: [
            {
              inboxId: {_neq: -1},
              conversationInbox: {
                channelType: {
                  _in: channelType,
                },
                inboxMembers: {
                  userId: {
                    _eq: userData.id,
                  },
                },
              },
              _or: [
                {
                  conversationContact: conversationContactSearchCondition,
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                },
              ],
            },
            {
              groupConversation: {
                groupType: {
                  code: {
                    _in: 'PRIVATE',
                  },
                },
                name: {_ilike: searchText},
              },
            },
          ],
        }),
  };
  return searchFilter;
};

const getAssignedOtherConversationSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  isEFaxMessagingEnabled: boolean,
  conversationUserFilterData?: IConversationFilterValue
) => {
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  let searchFilter: any = {};
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1;
  });
  const locationUuids = conversationUserFilterData?.locationUuids;
  const inboxId = conversationUserFilterData?.inboxId;
  const assigneeIds = conversationUserFilterData?.assigneeIds;
  const conversationContactSearchCondition =
    getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    status: {_neq: assignStatusValue.ARCHIVED},
    ...(assigneeIds?.length
      ? {assigneeId: {_in: assigneeIds}}
      : {assigneeId: {_neq: userData?.id}}),
    ...(locationUuids?.length && inboxId && inboxId > 0
      ? {
          _or: [
            {
              inboxId: {_eq: inboxId},
              conversationInbox: {
                channelType: {
                  _in: channelType,
                },
              },
              _or: [
                {
                  conversationContact: conversationContactSearchCondition,
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                },
              ],
            },
            {
              practiceLocationUuid: {
                _in: locationUuids,
              },
              groupConversation: {
                groupType: {
                  code: {
                    _in: 'PRIVATE',
                  },
                },
                name: {_ilike: searchText},
              },
            },
          ],
        }
      : locationUuids?.length
      ? {
          practiceLocationUuid: {
            _in: locationUuids,
          },
          groupConversation: {
            groupType: {
              code: {
                _in: 'PRIVATE',
              },
            },
            name: {_ilike: searchText},
          },
        }
      : inboxId && inboxId > 0
      ? {
          inboxId: {_eq: inboxId},
          conversationInbox: {
            channelType: {
              _in: channelType,
            },
          },
          _or: [
            {
              conversationContact: conversationContactSearchCondition,
            },
            {
              subject: {
                _ilike: searchText,
              },
            },
          ],
        }
      : {
          _or: [
            {
              inboxId: {_neq: -1},
              conversationInbox: {
                channelType: {
                  _in: channelType,
                },
              },
              _or: [
                {
                  conversationContact: conversationContactSearchCondition,
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                },
              ],
            },
            {
              groupConversation: {
                groupType: {
                  code: {
                    _in: 'PRIVATE',
                  },
                },
                name: {_ilike: searchText},
              },
            },
          ],
        }),
  };
  return searchFilter;
};

const getUnAssignedSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  isEFaxMessagingEnabled: boolean,
) => {
  let searchFilter: any = {};
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1
  });
  const conversationContactSearchCondition = getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    _or: [
      {
        inboxId: {_neq: -1},
        conversationInbox: {
          channelType: {
            _in: channelType
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        _or: [
          {
            conversationContact: conversationContactSearchCondition,
          },
          {
            subject: {
              _ilike: searchText,
            },
          },
        ],
      },
      {
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
          groupType: {
            code: {
              _in: 'PRIVATE'
            }
          },
          name: {_ilike: searchText},
        }
      }
    ],
    assigneeId: {_is_null: true},
    status: {_neq: assignStatusValue.ARCHIVED},
  };
  return searchFilter;
};

const getArchivedConversationSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  isEFaxMessagingEnabled: boolean,
  conversationUserFilterData?: IConversationFilterValue
) => {
  let searchFilter: any = {};
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
    CHANNEL_TYPE.CHANNEL_WEB_WIDGET,
  ];
  const locationUuids = conversationUserFilterData?.locationUuids;
  const inboxId = conversationUserFilterData?.inboxId;
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1;
  });
  const conversationContactSearchCondition =
    getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    status: {_eq: assignStatusValue.ARCHIVED},
    ...(locationUuids?.length && inboxId && inboxId > 0
      ? {
          _or: [
            {
              inboxId: {_eq: inboxId},
              conversationInbox: {
                channelType: {
                  _in: channelType,
                },
                inboxMembers: {
                  userId: {
                    _eq: userData.id,
                  },
                },
              },
              _or: [
                {
                  conversationContact: conversationContactSearchCondition,
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                },
              ],
            },
            {
              practiceLocationUuid: {
                _in: locationUuids,
              },
              groupConversation: {
                name: {
                  _ilike: searchText,
                },
                groupMembers: {
                  groupUserId: {
                    _eq: userUuid,
                  },
                },
                groupType: {
                  id: {
                    _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                  },
                },
              },
            },
          ],
        }
      : locationUuids?.length
      ? {
          practiceLocationUuid: {
            _in: locationUuids,
          },
          groupConversation: {
            name: {
              _ilike: searchText,
            },
            groupMembers: {
              groupUserId: {
                _eq: userUuid,
              },
            },
            groupType: {
              id: {
                _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
              },
            },
          },
        }
      : inboxId && inboxId > 0
      ? {
          inboxId: {_eq: inboxId},
          conversationInbox: {
            channelType: {
              _in: channelType,
            },
            inboxMembers: {
              userId: {
                _eq: userData.id,
              },
            },
          },
          _or: [
            {
              conversationContact: conversationContactSearchCondition,
            },
            {
              subject: {
                _ilike: searchText,
              },
            },
          ],
        }
      : {
          _or: [
            {
              inboxId: {
                _neq: -1,
              },
              conversationInbox: {
                channelType: {
                  _in: channelType,
                },
                inboxMembers: {
                  userId: {
                    _eq: userData.id,
                  },
                },
              },
              _or: [
                {
                  conversationContact: conversationContactSearchCondition,
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                },
              ],
            },
            {
              groupConversation: {
                name: {
                  _ilike: searchText,
                },
                groupMembers: {
                  groupUserId: {
                    _eq: userUuid,
                  },
                },
                groupType: {
                  id: {
                    _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                  },
                },
              },
            },
          ],
        }),
  };
  return searchFilter;
};

export const getWebChannelWidgetSearchFilter = (
  searchString: string,
  selectedTabCode: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  conversationUserFilterData?: IConversationFilterValue
) => {
  const channelTypeObj: any =
    getChannelTypeFilter(CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET, userData) || {};
  let searchFilter: any = {};
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1
  })
  const locationUuids = conversationUserFilterData?.locationUuids;
  const conversationContactSearchCondition = getConversationContactSearchCondition(searchString, searchStringArray);
  switch (selectedTabCode) {
    case CONVERSATION_TAB_CODES.RECENT: {
      searchFilter = {
        ...(locationUuids?.length && {practiceLocationUuid: {
          _in: locationUuids,
        }}),
        _or: [
          {
            conversationContact: conversationContactSearchCondition,
            ...channelTypeObj
          },
          {
            groupConversation: {
              groupMembers: {
                groupUserId: {
                  _eq: userUuid,
                },
              },
              groupType: {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                },
              },
              name: {
                _ilike: searchText,
              },
            },
          }
        ],

      };
      break;
    }
    case CONVERSATION_TAB_CODES.PROSPECTS:
      searchFilter = {
        inboxId: {
          _neq: -1,
        },
        ...channelTypeObj,
        _or: [
          {conversationContact: {name: {_ilike: searchText}}},
          {conversationMessages: {content: {_ilike: searchText}}},
        ],
      };
      break;
    case CONVERSATION_TAB_CODES.PATIENTS: {
      searchFilter = {
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
          groupType: {
            id: {
              _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
            },
          },
          name: {
            _ilike: searchText,
          },
        },
      };
      break;
    }
    case CONVERSATION_TAB_CODES.INTERNAL: {
      searchFilter = {
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
          groupType: {
            _or: [
              {id: {_eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.INTERNAL]}},
              {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.USER_BROADCAST],
                },
              },
            ],
          },
          _or: [
            {
              name: {
                _ilike: searchText,
              },
            },
            {
              groupMembers: {
                user: {
                  name: {
                    _ilike: searchText,
                  },
                },
              },
            },
          ],
        },
      };
      break;
    }
  }
  return searchFilter;
};

export const getInterUserSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string
) => {
  let searchFilter: any = {};
  const searchText = '%' + searchString + '%';
  searchFilter = {
    groupConversation: {
      groupMembers: {
        groupUserId: {
          _eq: userUuid,
        },
      },
      groupType: {
        id: {_eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.INTERNAL]},
      },
      _or: [
        {
          name: {
            _ilike: searchText,
          },
        },
        {
          groupMembers: {
            user: {
              name: {
                _ilike: searchText,
              },
            },
          },
        },
      ],
    },
  };
  return searchFilter;
};

export const getAllChannelSearchFilter = (
  searchString: string,
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string,
  userData: ILoginUserData,
  isEFaxMessagingEnabled?: boolean,
  emailInboxUserPreference?: string,
  otherContactTypeId?: string,
  isEmailUserPreferenceEnabled?: boolean,
  conversationUserFilterData?: IConversationFilterValue
) => {
  const channelTypeObj: any =
    getChannelTypeFilter(CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET, userData) || {};
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
    CHANNEL_TYPE.CHANNEL_WEB_WIDGET,
  ];
  const locationUuids = conversationUserFilterData?.locationUuids;
  const inboxId = conversationUserFilterData?.inboxId;
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  let searchFilter: any = {};
  const searchText = '%' + searchString + '%';
  let searchStringArray = searchString.split(' ');
  searchStringArray = searchStringArray.filter((char) => {
    return char.length >= 1;
  });
  const conversationContactSearchCondition =
    getConversationContactSearchCondition(searchString, searchStringArray);
  searchFilter = {
    ...(locationUuids?.length && inboxId && inboxId > 0
      ? {
          _or: [
            {
              inboxId: {_eq: inboxId},
              conversationInbox: {
                inboxMembers: {
                  userId: {
                    _eq: userData.id,
                  },
                },
                channelType: {
                  _in: channelType,
                },
              },
              _or: [
                {
                  conversationContact: {
                    ...conversationContactSearchCondition,
                    ...(isEmailUserPreferenceEnabled &&
                    emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER &&
                    otherContactTypeId
                      ? {
                          contactType: {
                            contactType: {
                              id: {
                                _in: [otherContactTypeId],
                              },
                            },
                          },
                        }
                      : {}),
                    ...(isEmailUserPreferenceEnabled &&
                    emailInboxUserPreference ===
                      EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD &&
                    otherContactTypeId
                      ? {
                          contactType: {
                            contactType: {
                              id: {
                                _nin: [otherContactTypeId],
                              },
                            },
                          },
                        }
                      : {}),
                  },
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                  ...(isEmailUserPreferenceEnabled &&
                  emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER &&
                  otherContactTypeId
                    ? {
                        conversationContact: {
                          contactType: {
                            contactType: {
                              id: {
                                _in: [otherContactTypeId],
                              },
                            },
                          },
                        },
                      }
                    : {}),
                  ...(isEmailUserPreferenceEnabled &&
                  emailInboxUserPreference ===
                    EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD &&
                  otherContactTypeId
                    ? {
                        conversationContact: {
                          contactType: {
                            contactType: {
                              id: {
                                _nin: [otherContactTypeId],
                              },
                            },
                          },
                        },
                      }
                    : {}),
                },
              ],
            },
            {
              practiceLocationUuid: {
                _in: locationUuids,
              },
              groupConversation: {
                name: {
                  _ilike: searchText,
                },
                groupMembers: {
                  groupUserId: {
                    _eq: userUuid,
                  },
                },
                groupType: {
                  id: {
                    _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                  },
                },
              },
            },
          ],
        }
      : locationUuids?.length
      ? {
          practiceLocationUuid: {
            _in: locationUuids,
          },
          groupConversation: {
            name: {
              _ilike: searchText,
            },
            groupMembers: {
              groupUserId: {
                _eq: userUuid,
              },
            },
            groupType: {
              id: {
                _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
              },
            },
          },
        }
      : inboxId && inboxId > 0
      ? {
          inboxId: {_eq: inboxId},
          conversationInbox: {
            inboxMembers: {
              userId: {
                _eq: userData.id,
              },
            },
            channelType: {
              _in: channelType,
            },
          },
          _or: [
            {
              conversationContact: {
                ...conversationContactSearchCondition,
                ...(isEmailUserPreferenceEnabled &&
                emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER &&
                otherContactTypeId
                  ? {
                      contactType: {
                        contactType: {
                          id: {
                            _in: [otherContactTypeId],
                          },
                        },
                      },
                    }
                  : {}),
                ...(isEmailUserPreferenceEnabled &&
                emailInboxUserPreference ===
                  EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD &&
                otherContactTypeId
                  ? {
                      contactType: {
                        contactType: {
                          id: {
                            _nin: [otherContactTypeId],
                          },
                        },
                      },
                    }
                  : {}),
              },
            },
            {
              subject: {
                _ilike: searchText,
              },
              ...(isEmailUserPreferenceEnabled &&
              emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER &&
              otherContactTypeId
                ? {
                    conversationContact: {
                      contactType: {
                        contactType: {
                          id: {
                            _in: [otherContactTypeId],
                          },
                        },
                      },
                    },
                  }
                : {}),
              ...(isEmailUserPreferenceEnabled &&
              emailInboxUserPreference ===
                EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD &&
              otherContactTypeId
                ? {
                    conversationContact: {
                      contactType: {
                        contactType: {
                          id: {
                            _nin: [otherContactTypeId],
                          },
                        },
                      },
                    },
                  }
                : {}),
            },
          ],
        }
      : {
          _or: [
            {
              inboxId: {
                _neq: -1,
              },
              conversationInbox: {
                inboxMembers: {
                  userId: {
                    _eq: userData.id,
                  },
                },
                channelType: {
                  _in: channelType,
                },
              },
              _or: [
                {
                  conversationContact: {
                    ...conversationContactSearchCondition,
                    ...(isEmailUserPreferenceEnabled &&
                    emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER &&
                    otherContactTypeId
                      ? {
                          contactType: {
                            contactType: {
                              id: {
                                _in: [otherContactTypeId],
                              },
                            },
                          },
                        }
                      : {}),
                    ...(isEmailUserPreferenceEnabled &&
                    emailInboxUserPreference ===
                      EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD &&
                    otherContactTypeId
                      ? {
                          contactType: {
                            contactType: {
                              id: {
                                _nin: [otherContactTypeId],
                              },
                            },
                          },
                        }
                      : {}),
                  },
                },
                {
                  subject: {
                    _ilike: searchText,
                  },
                  ...(isEmailUserPreferenceEnabled &&
                  emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER &&
                  otherContactTypeId
                    ? {
                        conversationContact: {
                          contactType: {
                            contactType: {
                              id: {
                                _in: [otherContactTypeId],
                              },
                            },
                          },
                        },
                      }
                    : {}),
                  ...(isEmailUserPreferenceEnabled &&
                  emailInboxUserPreference ===
                    EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD &&
                  otherContactTypeId
                    ? {
                        conversationContact: {
                          contactType: {
                            contactType: {
                              id: {
                                _nin: [otherContactTypeId],
                              },
                            },
                          },
                        },
                      }
                    : {}),
                },
              ],
            },
            {
              groupConversation: {
                name: {
                  _ilike: searchText,
                },
                groupMembers: {
                  groupUserId: {
                    _eq: userUuid,
                  },
                },
                groupType: {
                  id: {
                    _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                  },
                },
              },
            },
          ],
        }),
  };
  return searchFilter;
};

export const getSMSConversationSearchFilter = (
  userData: ILoginUserData,
  conversationContactSearchCondition: any,
  conversationUserFilterData?: IConversationFilterValue
) => {
  const channelTypeObj: any =
    getChannelTypeFilter(CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS, userData) || {};
  delete channelTypeObj?.status;
  const inboxId = conversationUserFilterData?.inboxId;
  let searchFilter: any = {};
  searchFilter = {
    ...(inboxId && inboxId > 0
      ? {inboxId: {_eq: inboxId}}
      : {inboxId: {_neq: -1}}),
    ...channelTypeObj,
    conversationContact: conversationContactSearchCondition,
  };
  return searchFilter;
};

export const getConversationFinalVariables = (
  selectedInboxTypeCode: string,
  userTypeId: any,
  userUuid: string,
  userId: string | number,
  conversationFilter: any,
  selectedSubtabCode?: string,
  selectedInboxUserIds?: string[],
  isSideCarContext?: boolean,
) => {
  const finalVariablesFilter: any = {
    limit: isSideCarContext ? 20 : MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE,
    offset: 0,
    loginUserUuid: userUuid,
    orderByObject: {lastActivityAt: `desc_nulls_last`},
    whereCondition: conversationFilter,
    unreadMessagesWhere: {
      senderType: {
        _is_null: false
      },
      senderId: {
        _neq: userId
      },
      messageType: {
        _neq: -1
      }
    },
    unreadMessagesWhereCount: {
      useruuid: userUuid,
      userid: userId,
      messagetype: "{-1}",
      messageconditionin: false
    },
    conversationMessagesWhere: {
      messageType: {
        _neq: -1
      }
    }
  };

  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS) {
    finalVariablesFilter.orderByObject = {lastMissCallAt: `desc`};
    finalVariablesFilter.conversationMessagesWhere = {
      messageType: {
        _in: [2, 4]
      }
    };
    finalVariablesFilter.unreadMessagesWhere = {
      senderType: {
        _is_null: false
      },
      senderId: {
        _neq: userId
      },
      messageType: {
        _eq: 4
      }
    };
    finalVariablesFilter.unreadMessagesWhereCount = {
      useruuid: userUuid,
      userid: userId,
      messagetype: "{4}",
      messageconditionin: true
    }
  } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
    finalVariablesFilter.orderByObject = {lastSmsAt: `desc`};
    finalVariablesFilter.whereCondition = {...conversationFilter, lastSmsAt: {_is_null: false}};
    finalVariablesFilter.unreadMessagesWhere = {
      senderType: {
        _is_null: false
      },
      senderId: {
        _neq: userId
      },
      messageType: {
        _in: [0, 1]
      }
    };
    finalVariablesFilter.unreadMessagesWhereCount = {
      useruuid: userUuid,
      userid: userId,
      messagetype: "{0,1}",
      messageconditionin: true
    };
    finalVariablesFilter.conversationMessagesWhere = {
      messageType: {
        _in: [0, 1]
      }
    };
  } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    finalVariablesFilter.orderByObject = {updatedAt: `desc`};
    delete conversationFilter.accountId;
    finalVariablesFilter.whereCondition = {...conversationFilter};
  } else if (isEmailChannelInboxType(selectedInboxTypeCode)) {
    delete finalVariablesFilter.conversationMessagesWhere.messageType;
    if (selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT) {
      finalVariablesFilter.orderByObject = {
        lastOutgoingEmailAt: `desc_nulls_last`
      }

      finalVariablesFilter.whereCondition = {
        conversations: finalVariablesFilter.whereCondition,
        isDeleted: {
          _eq: false
        },
        lastOutgoingEmailAt: {
          _is_null: false
        }
      };
    } else {
      finalVariablesFilter.orderByObject = {
        lastIncomingEmailAt: `desc_nulls_last`
      }

      finalVariablesFilter.whereCondition = {
        conversations: finalVariablesFilter.whereCondition,
        isDeleted: {
          _eq: false
        },
        lastIncomingEmailAt: {
          _is_null: false
        }
      };
    }
  } else if (isAllCallsChannelInboxType(selectedInboxTypeCode)) {
    finalVariablesFilter.orderByObject = {lastCallAt: `desc`};
    finalVariablesFilter.conversationMessagesWhere = {
      messageType: {
        _in: [2, 4]
      }
    };
    finalVariablesFilter.unreadMessagesWhere = {
      senderType: {
        _is_null: false
      },
      senderId: {
        _neq: userId
      },
      messageType: {
        _eq: 4
      }
    };
    finalVariablesFilter.unreadMessagesWhereCount = {
      useruuid: userUuid,
      userid: userId,
      messagetype: "{4}",
      messageconditionin: true
    }
  }
  else if (isEFaxChannelInbox(selectedInboxTypeCode)) {
    if (selectedSubtabCode === CONVERSATION_SUBTAB_CODES.SENT) {
      finalVariablesFilter.orderByObject = {
        lastOutgoingEFaxAt: `desc_nulls_last`
      }

      finalVariablesFilter.whereCondition = {
        conversations: finalVariablesFilter.whereCondition,
        isDeleted: {
          _eq: false
        },
        lastOutgoingEFaxAt: {
          _is_null: false
        }
      };
    } else {
      finalVariablesFilter.orderByObject = {
        lastIncomingEFaxAt: `desc_nulls_last`
      }

      finalVariablesFilter.whereCondition = {
        conversations: finalVariablesFilter.whereCondition,
        isDeleted: {
          _eq: false
        },
        lastIncomingEFaxAt: {
          _is_null: false
        }
      };
    }
  }
  return finalVariablesFilter;
}

export const formatConversationMentionData = (conversationMentions: IConversationMentionsData[]) => {
  const finalConversationList = [] as IConversationData[];
  const tempConversationList = conversationMentions;
  tempConversationList?.forEach((singleConversation: IConversationMentionsData) => {
    if (singleConversation.conversationMessages?.id) {
      const conversationsList: any = {
        ...singleConversation.conversation,
        conversationMentionId: singleConversation.id,
        isRead: singleConversation?.isRead || false,
        conversationMessages: [singleConversation.conversationMessages]
      };
      finalConversationList.push(
        conversationsList
      )
    }
  })
  return finalConversationList;
}

export const formatConversationsEmailData = (conversationEmails: IEmailConversationActivities[]) => {
  const finalConversationList = [] as IConversationData[];
  const tempConversationList = conversationEmails;
  tempConversationList?.forEach((singleConversation: IEmailConversationActivities) => {
    if (singleConversation.conversations?.id) {
      const finalConversationData = {
        ...singleConversation?.conversations,
        lastOutgoingEmailAt: singleConversation?.lastOutgoingEmailAt,
      };
      finalConversationList.push(
        finalConversationData
      )
    }
  })
  return finalConversationList;
}

export const formatConversationsCallActivityData = (conversationEmails: ICallConversationActivities[]) => {
  const finalConversationList = [] as IConversationData[];
  const tempConversationList = conversationEmails;
  tempConversationList?.forEach((singleConversation: ICallConversationActivities) => {
    if (singleConversation.conversation?.id) {
      const finalConversationData = {...singleConversation.conversation};
      finalConversationData.conversationMessages = [singleConversation?.lastCallMessage];
      finalConversationList.push(
        finalConversationData
      )
    }
  });
  return finalConversationList;
}

export const formatConversationsEFaxData = (conversationEFaxs: IEFaxConversationActivities[]) => {
  const finalConversationList = [] as IConversationData[];
  const tempConversationList = conversationEFaxs;
  tempConversationList?.forEach((singleConversation: IEFaxConversationActivities) => {
    if (singleConversation.conversations?.id) {
      const finalConversationData = {
        ...singleConversation?.conversations,
        lastOutgoingEFaxAt: singleConversation?.lastOutgoingEFaxAt,
      };
      finalConversationList.push(
        finalConversationData
      )
    }
  })
  return finalConversationList;
}



export const getConversationUuidsFromConversationDataList = (conversationDataList: IConversationData[]) => {
  const finalConversationUuidList: string[] = [];
  conversationDataList?.forEach((singleConversation: IConversationData) => {
    if (singleConversation?.uuid) {
      finalConversationUuidList.push(
        singleConversation?.uuid
      )
    }
  });
  return finalConversationUuidList;
}

export const getAssignedCodeForAssignedEvent = (eventData: IMessageCreated) => {
  const additionalData = eventData?.additionalData;
  if (additionalData?.assigneeId) {
    return ASSIGNED_CODE.ASSIGNED;
  } else {
    return ASSIGNED_CODE.UN_ASSIGNED;
  }
}



const getPatientSearchORConditionForConversation = (searchString: string, searchStringArray: string[], searchModuleCode?: string) => {
  const conditionArray = [] as any[];
  let searchString1 = '';
  let searchString2 = '';
  let searchString3 = '';
  if (searchStringArray?.length > 3) {
    searchString1 = searchStringArray[0] || '';
    searchString2 = searchStringArray
      .slice(1, searchStringArray.length - 1)
      .join(' ');
    searchString3 = searchStringArray[searchStringArray.length - 1] || '';
  } else if (searchStringArray.length) {
    searchString1 = searchStringArray[0] || '';
    searchString2 = searchStringArray[1] || '';
    searchString3 = searchStringArray[2] || '';
  }

  if (searchString.indexOf('@') != -1) {
    conditionArray.push({
      email: {
        _ilike: '%' + searchString + '%',
      },
    });
  } else if (isNumeric(searchString)) {
    conditionArray.push({
      phoneNumber: {
        _ilike: '%' + searchString + '%',
      },
    });
    // NOTE: We need this in case when > lead is created with number as name
    conditionArray.push({
      name: {
        _ilike: '%' + searchString + '%',
      }
    });
  } else if (searchStringArray.length === 1) {
    const chosenNameConditionArr = getChosenNameConditionForConversation([searchString])
    searchString = searchString1;
    conditionArray.push({
      person: {
        _or:[
          {
              firstName: {
                _ilike: '%' + searchString + '%',
              },
          },
          {
            lastName: {
              _ilike: '%' + searchString + '%',
            },
          },
          {
            middleName: {
              _ilike: '%' + searchString + '%',
            },
          },
          ...chosenNameConditionArr
        ]
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
  } else if (searchStringArray.length === 2) {
    const chosenNameConditionArr = getChosenNameConditionForConversation([searchString1, searchString2])
    conditionArray.push({
      person: {
        _or:[
          {
            firstName: {
              _ilike: '%' + searchString1 + '%',
            },
            lastName: {
              _ilike: '%' + searchString2 + '%',
            },
          },
          {
            firstName: {
              _ilike: '%' + searchString2 + '%',
            },
            lastName: {
              _ilike: '%' + searchString1 + '%',
            },
          },
          {
            middleName: {
              _ilike: '%' + searchString1 + '%',
            },
            lastName: {
              _ilike: '%' + searchString2 + '%',
            },
          },
          {
            firstName: {
              _ilike: '%' + searchString1 + '%',
            },
            middleName: {
              _ilike: '%' + searchString2 + '%',
            },
          },
          ...chosenNameConditionArr
        ]
      },
    });

    // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };

  } else if (searchStringArray.length === 3) {
    const chosenNameConditionArr = getChosenNameConditionForConversation([searchString1, searchString2, searchString3]);
    conditionArray.push({
      person: {
        _or:[
          {
            firstName: {
              _ilike: '%' + searchString1 + '%',
            },
            lastName: {
              _ilike: '%' + searchString3 + '%',
            },
          },
          {
            firstName: {
              _ilike: '%' + searchString2 + '%',
            },
            lastName: {
              _ilike: '%' + searchString1 + '%',
            },
          },
          {
            middleName: {
              _ilike: '%' + searchString2 + '%',
            },
            lastName: {
              _ilike: '%' + searchString3 + '%',
            },
          },
          {
            firstName: {
              _ilike: '%' + searchString2 + '%',
            },
            middleName: {
              _ilike: '%' + searchString3 + '%',
            },
          },
          ...chosenNameConditionArr
        ]
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
  } else if (searchStringArray.length > 3) {
    const chosenNameConditionArr = getChosenNameConditionForConversation([searchString1, searchString2, searchString3]);
    conditionArray.push({
      person: {
        _or:[
          {
            firstName: {
              _ilike: '%' + searchString1 + '%',
            },
            middleName: {
              _ilike: '%' + searchString2 + '%',
            },
            lastName: {
              _ilike: '%' + searchString3 + '%',
            },
          },
          ...chosenNameConditionArr
        ]
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
  }
  return conditionArray;
}



const getChosenNameConditionForConversation = (searchStrings: string[]) => {
  const conditionArray = [] as any[];
  try {
    const generateCombinations = (arr: string[]) => {
      const combinations = [] as any;
      const permute = (array: string[], current: string[] = []) => {
        if (array.length === 0) {
          combinations.push([...current]);
          return;
        }
        for (let itemIndex = 0; itemIndex < array.length; itemIndex++) {
          const remaining = array.slice(0, itemIndex).concat(array.slice(itemIndex + 1));
          permute(remaining, current.concat(array[itemIndex]));
        }
      };
      permute(arr);
      return combinations;
    };

    const partialMatch = (searchString: string) => `%${searchString}%`;

    if (searchStrings.length === 1) {
      const searchString = searchStrings[0] || '';
      conditionArray.push({
          chosenName: {
            _ilike: partialMatch(searchString),
          },
      });
    } else if((searchStrings.length > 1)) {
      for (const combination of generateCombinations(searchStrings)) {
        conditionArray?.push({
          chosenName: {
            _ilike: partialMatch(combination.join(" ")),
          },
      });
      }
    }
  } catch (error) {
    return [];
  }
  return conditionArray;
};
function getConversationContactSearchCondition(searchString: string, searchStringArray: string[]) {
  const nameSearchConditions = getPatientSearchORConditionForConversation(searchString, searchStringArray, PATIENT_SEARCH_MODULE_CODE.CONVERSATION_LIST);
  let conversationContactSearchCondition = {_or: nameSearchConditions};
  if (nameSearchConditions?.length === 1) {
    conversationContactSearchCondition = nameSearchConditions[0];
  }
  return conversationContactSearchCondition;
}

export const getUniqueMemberListByUuid = (
  memberList: IGroupMember[]
): IGroupMember[] => {
  if (memberList?.length) {
    const finalMemberList = uniqBy(memberList, (memberData) => {
      return memberData?.user?.uuid || memberData?.contact?.uuid || memberData.id;
    });
    return finalMemberList;
  }
  return [];
};
