import { Stack, useMediaQuery, View, VStack } from 'native-base';
import { useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../constants';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import { Colors } from '../../../styles/Colors';
import { getCurrentUserRole, getCurrentUserRoleCodes, getFeatureFlag, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../utils/commonUtils';
import { isWeb } from '../../../utils/platformCheckUtils';
import { ActiveLivesGraph } from '../../RightSideContainer/Analytics/ActiveLivesGraph';
import { AppointmentTypeGraph } from '../../RightSideContainer/Analytics/AppointmentTypeGraph';
import { FormResponseGraph } from '../../RightSideContainer/Analytics/FormResponseGraph';
import { LoginEmployeeGraph } from '../../RightSideContainer/Analytics/LoginEmployeeGraph';
import { isFormAccessAllowed, isLeadAccessAllowed, isMessageDashboardAllowed, isPatientAccessAllowed, isScheduleAccessAllowed } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import PageBodyContainer from '../PageBodyContainer/PageBodyContainer';
import TitleSubtitleView from '../TitleSubtitleView/TitleSubtitleView';
import AgeGroupsChart from './AgeGroupsChart';
import BusinessGoals from './BusinessGoals/BusinessGoals';
import FoldAdvantangeReview from './FoldAdvantangeReview';
import GenderGroupsChart from './GenderGroupsChart';
import { PatientEngagementGraph } from './PatientEngagementGraph';
import TaskDueTodayDashboard from './TaskDueToday/TaskDueTodayDashboard';
import KPI from '../../RightSideContainer/KPI/pages/analytics'
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import CommunicationLogChart from './CommunicationLogChart';
import {getAccountConfigValue} from '../../../utils/configUtils';

const AppDashboardView = () => {
  const { height } = Dimensions.get('window');

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const commonData: any = useContext(CommonDataContext);
  const showPatientEngagementGraph = isMessageDashboardAllowed() && isPatientAccessAllowed();
  const showAgeAndGenderGraph = isPatientAccessAllowed();
  const showFormsGraph = isFormAccessAllowed();;
  const showAppointmentTypesGraph = isScheduleAccessAllowed();
  const showLoginCountGraph = isLeadAccessAllowed();
  const currentUserRoles = getCurrentUserRole()
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()

  const widgetList: any = []
  const hideSectionOrWidgetListConfig = getAccountConfigValue(CONFIG_CODES.HIDE_SECTION_OR_WIDGET)
  let hideSectionOrWidgetListValue: any = []
  try {
    hideSectionOrWidgetListValue = JSON.parse(hideSectionOrWidgetListConfig)
  } catch  {
    hideSectionOrWidgetListValue = []
  }
  if (!hideSectionOrWidgetListValue.includes('FOLD_ADVANTAGE')) {
    widgetList.push(<FoldAdvantangeReview />)
  }
  if (isBusinessOwner) {
    widgetList.push(<CommunicationLogChart></CommunicationLogChart>);
  }
  if (showPatientEngagementGraph) {
    widgetList.push(<PatientEngagementGraph />);
  }
  if (showLoginCountGraph) {
    widgetList.push(<LoginEmployeeGraph isDashboard />);
  }
  const [isShowAppointmentTypesGraph, setIsShowAppointmentTypesGraph] = useState(showAppointmentTypesGraph);
  if (isShowAppointmentTypesGraph) {
    widgetList.push(<AppointmentTypeGraph isDashboard setIsShowAppointmentTypesGraph={setIsShowAppointmentTypesGraph} />);
  }
  if (showAgeAndGenderGraph) {
    widgetList.push(<AgeGroupsChart></AgeGroupsChart>);
    widgetList.push(<GenderGroupsChart></GenderGroupsChart>);
  }

  if (showFormsGraph) {
    widgetList.push(<FormResponseGraph isDashboard />);
  }
  const currentUserRoleCodes = getCurrentUserRoleCodes();
  // alert(JSON.stringify(currentUserRoleCodes));
  if(isBusinessOwner || currentUserRoleCodes.includes('WORKFLOW') || currentUserRoleCodes.includes('CUSTOMER_SUCCESS')){
    widgetList.push(<ActiveLivesGraph />);
  }
  const isDashboardView = getFeatureFlag(
    CONFIG_CODES.ENABLE_EMPLOYER_REPORTING,
    commonData
  );

  
  return (
    <View>
      <View>
        <TitleSubtitleView
          titleLabelId="appInfoTitle"
          subtitleLabelId="appInfoTitleSubtitle"
        />
      </View>
      {(isBusinessOwner || currentUserRoleCodes.includes('WORKFLOW') || currentUserRoleCodes.includes('CUSTOMER_SUCCESS')) && isDashboardView ? <KPI /> :
      <View style={{ paddingTop: 8, backgroundColor: '#fff', paddingHorizontal: 16 }}>
      <PageBodyContainer style={{ }}>
        <VStack
          style={{ height: height - 85, overflow: 'scroll' }}
          space={'2'}
        >
          <VStack
            //marginBottom={-1.5}
            overflow={(!isWeb() || isIPadMiniScreen || isIPadScreen) ? 'scroll' : null}
          >
            <BusinessGoals></BusinessGoals>
          </VStack>
          <Stack space={'2'} direction={isSmallScreen ? 'column' : 'row'} key={widgetList.length}>
            <VStack flex={1}>
              <TaskDueTodayDashboard></TaskDueTodayDashboard>
            </VStack>
            {widgetList.length > 0 && <VStack flex={2} height={'100%'} space="2">
              <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                flex={1}
                space="2"
              >
                {widgetList.length > 0 ? <Stack flex={1}>{widgetList[0]}</Stack> : <Stack flex={1} />}
                {widgetList.length > 1 ? <Stack flex={1}>{widgetList[1]}</Stack> : <Stack flex={1} />}
              </Stack>

              {widgetList.length > 2 && (
                <Stack
                  direction={isSmallScreen ? 'column' : 'row'}
                  space="2"
                  flex={1}
                >
                  {widgetList.length > 2 ? <Stack flex={1}>{widgetList[2]}</Stack> : <Stack flex={1} />}
                  {widgetList.length > 3 ? <Stack flex={1}>{widgetList[3]}</Stack> : <Stack flex={1} />}
                  {/* <TotalCountGraph /> */}
                </Stack>
              )}
            </VStack>}


          </Stack>
          {widgetList.length > 4 &&
            <Stack
              direction={isSmallScreen ? 'column' : 'row'}
              space="2"
            >
              {widgetList.length > 4 ? <Stack flex={1}>{widgetList[4]}</Stack> : <Stack flex={1} />}
              {widgetList.length > 5 ? <Stack flex={1}>{widgetList[5]}</Stack> : <Stack flex={1} />}
              {widgetList.length > 6 ? <Stack flex={1}>{widgetList[6]}</Stack> : <Stack flex={1} />}
            </Stack>
          }
          {widgetList.length > 7 &&
            <Stack
              direction={isSmallScreen ? 'column' : 'row'}
              space="2"
            >
              {widgetList.length > 7 ? <Stack flex={1}>{widgetList[7]}</Stack> : <Stack flex={1} />}
              <VStack flex={2}></VStack>
            </Stack>
          }
        </VStack>
      </PageBodyContainer>
    </View>
      }

    </View>
  );
};

export default AppDashboardView;
