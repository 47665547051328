// Helper
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
// Views
import {Menu, Table} from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import {Box, Center, Spinner, useToast, View,Skeleton} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import { formsListData } from '../../../../../dummyData/leadFilterDummyData';
import { IMlov } from '../../../../../Interfaces/CommonInterfaces';
import {FormsQueries} from '../../../../../services';
import {Colors} from '../../../../../styles';
import {testID, TestIdentifiers} from '../../../../../testUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import { getMlovObjectFromId } from '../../../../../utils/mlovUtils';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import FHAlertDialog from '../../../../common/FHAlertDialog/FHAlertDialog';
import {
  ITableTopBarProps,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import {TableWrapper} from '../../../../common/TableWrapper';
import {IForm, IFormCategory} from '../../interfaces';
import {getColumns} from './FormListHelper';
import { isFormAnalyticsEnabled } from '../../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { EmailDrawerCommonV2 } from '../../../../common/EmailDrawerCommonV2';
import { TEMPLATES_CONST } from '../../../TeamInbox/Conversations/ConversationConst';

export enum FormTopBarAction {
  addNew = 'addNew',
  addExisting = 'addExisting',
}

const FormList = (props: {
  addNewForm: () => void;
  addGlobalTheme: () => void;
  onEditForm: (form: any) => void;
  onAddExistingForm: () => void;
  onAddFormClone: (form: any) => void;
  onPrintForm: (form: any) => void;
  onFormResponse: (form: any) => void;
  categoryList: IFormCategory[];
  searchString: string;
  onFormPending: (form: any) => void;
  onGraphClick?:(form: any,screen?:string) => void;
  locationList: string[];
}) => {
  const intl = useIntl();
  const toast = useToast();
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const formAnalyticsEnabled = isFormAnalyticsEnabled(userSettings);
  const [deleteForm] = useMutation(FormsQueries.DELETE_FORM_BY_ID);
  const [updateForm] = useMutation(FormsQueries.UPDATE_FORM_BY_ID);
  const [formListState, setFormListState] = useState<{
    searchString: string;
    page: number;
    pageSize: number;
    deleteConfirmationOpen: boolean;
    selectedForm?: any;
    formList: IForm[];
    totalForms: number;
    loading: boolean;
    sortBy:string;
    sortOrder: SortOrder;
    filterCategoryIds: string[];
  }>({
    totalForms: 0,
    formList: [],
    searchString: '',
    page: 1,
    pageSize: 10,
    deleteConfirmationOpen: false,
    selectedForm: undefined,
    loading: false,
    sortBy: 'createdOn',
    sortOrder: 'descend',
    filterCategoryIds:[]
  });
  const [getForms, {loading, error, data, refetch}] = useLazyQuery(
    FormsQueries.GET_FORMS_WITH_FILTERS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted(data) {
        const formList = data.getForms?.forms || [];
        const totalForms = data.getForms?.total || 0;
        (formList || []).forEach((form: any) => {
          form['formCategory'] = getMlovObjectFromId(form?.categoryId, props?.categoryList as unknown as IMlov[] || []);
          form['isShownToPatient'] = form?.formContactSharePermissions?.[0]?.isShownToPatient === true;
        })
        setFormListState((prev) => {
          return {
            ...prev,
            formList,
            totalForms: totalForms,
          };
        });
      },
    }
  );
  const [formEmailState, setFormEmailState] = useState({
    form: {} as any,
    isEmailDrawerOpen: false,
  });

  let apiData = data;
  if (error) {
    apiData = {
      forms: [],
    };
  }

  const getCustomerListTopBarButtons = (): ITableTopBarProps => {
    return {
      title: '',
      buttonList: [],
      // buttonList: [
      //   {
      //     btnText: 'createForm',
      //     size: 'sm',
      //     backgroundColor: Colors.secondary['100'],
      //     textColor: Colors.secondary['800'],
      //     borderColor: Colors.secondary['200'],
      //     variant: '',
      //     leftIcon: (
      //       <Icon
      //         as={AntIcon}
      //         name={'plus'}
      //         size="4"
      //         color={Colors.secondary['800']}
      //       />
      //     ),
      //     btnClick: () => {
      //       props.addNewForm();
      //     },
      //     accessibilityProps: testID(TestIdentifiers.createForm),
      //   },
      // ],
    };
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setFormListState((prev) => ({
          ...prev,
          searchString: actionData.searchString,
          page: 1,
        }));
        break;
    }
  };

  const onDelete = (form: any) => {
    setFormListState((prev) => ({
      ...prev,
      deleteConfirmationOpen: true,
      selectedForm: form,
    }));
  };

  const deleteItem = () => {
    if (!formListState.selectedForm) {
      return;
    }
    setFormListState((prev) => ({
      ...prev,
      deleteConfirmationOpen: false,
      selectedForm: undefined,
      loading: true,
    }));
    deleteForm({
      variables: {id: formListState.selectedForm.id},
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        setTimeout(() => {
          showToast(
            toast,
            intl.formatMessage({id: 'formDeletedMessage'}),
            ToastType.success,
            1000
          );
          setFormListState((prev) => {
            return {
              ...prev,
              formList: [],
              totalForms: 0,
              loading: false,
            };
          });
          refetch();
        }, 1000);
      },
      onError: () => {
        setFormListState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      },
    });
  };

  const onDefaultFormChange = (isSelected: boolean, form: IForm) => {
    const formAddData = {
      data: {
        name: form.name,
        categoryId: form.categoryId,
        isDefault: isSelected,
      },
    };
    updateForm({
      variables: {data: {...formAddData.data, id: form.id}},
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        setFormListState((prev) => {
          return {
            ...prev,
            formList: [],
            totalForms: 0,
            page: 1,
            pageSize: 10,
          };
        });
        refetch();
      },
      onError(error) {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
        );
      },
    });
  };

  const addNewMenu = () => {
    return (
      <Menu
        items={[
          {
            key: FormTopBarAction.addNew,
            label: intl.formatMessage({id: 'addNew'}),
          },
          {
            key: FormTopBarAction.addExisting,
            label: intl.formatMessage({id: 'addExisting'}),
          },
        ]}
        onClick={(data: any) => {
          if (data.key === FormTopBarAction.addNew) {
            props.addNewForm();
          } else if (data.key === FormTopBarAction.addExisting) {
            props.onAddExistingForm();
          }
        }}
      />
    );
  };

  const onSendFormEmail = (form: any) => {
    setFormEmailState((prev) => {
      return {
        ...prev,
        isEmailDrawerOpen: true,
        form: form,
      };
    });
  };

  const {height} = Dimensions.get('window');
  const finalHeight = height - 265;


  const getSortKey = (key: string) => {
    switch (key) {
      case 'createdOn':
        return 'createdOn';
      case 'name':
        return 'name';
      case 'submittedCount':
        return 'submittedCount';
      case 'pendingCount':
        return 'pendingCount';
      case 'updatedOn':
        return 'updatedOn';
      default:
        return 'submittedCount'
    }
  };


  const getFormList = () => {    
    let requestParams: { limit: number, offset: number, name?: any, categoryIds?: any, orderBy?: any, locationIds?: string[] };
    requestParams = {
        offset: (formListState.page - 1) * formListState.pageSize,
        limit: formListState.pageSize,
        ...(formListState.sortOrder && {orderBy: {[getSortKey(`${formListState.sortBy}`)]: formListState.sortOrder === 'ascend' ? 'asc' : 'desc'  } }),
        ...(props?.searchString && { name: props?.searchString || '' }),
        locationIds: props.locationList
    };
    formListState.filterCategoryIds.length && (requestParams.categoryIds = formListState.filterCategoryIds)
    // reset the params if the filter is removed
    if (formListState.filterCategoryIds.length === 0) {
      requestParams = {
        ...requestParams,
        offset: (formListState.page - 1) * formListState.pageSize,
        limit: formListState.pageSize,
        ...((formListState.sortOrder === undefined || formListState.sortOrder === null) && {orderBy: {createdOn: 'desc'}}),
        ...(props?.searchString && { name: props?.searchString || '' }),
      };
    }

    if (
      formListState.sortOrder === undefined ||
      formListState.sortOrder === null
    ) {
      requestParams = {
        ...requestParams,
        offset: (formListState.page - 1) * formListState.pageSize,
        limit: formListState.pageSize,
        ...(props?.searchString && { name: props?.searchString || '' }),
      };
    }
    getForms({
      variables: {
        params: requestParams,
      },
    });
  }

  useEffect(() => {
    getFormList();
  }, [
    formListState.page,
    formListState.pageSize,
    formListState.sortBy,
    formListState.sortOrder,
    formListState.filterCategoryIds,
  ]);

  useEffect(()=> {    
    if(formListState.page === 1){
      getFormList();
    }else{
      setFormListState((prev)=> ({...prev, page: 1}))
    }
  }, 
  [props?.searchString])

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFormListState((prev) => ({
      ...prev,
      page: pagination?.current,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.field,
      sortOrder: sorter?.order,
      filterCategoryIds: filters?.categoryId || [],
    }));
  };

  const isComponentLoading = loading || formListState.loading;

  return (
    <View overflow="hidden">
      <Box borderRadius={0} overflow={'hidden'}>
        {!loading && !formListState.loading && (
          <View backgroundColor={'#fff'}>
            <Table
              // rowClassName={(record, index) =>
              //   index % 2 !== 0 ? 'table-row-light' : ''
              // }
              rowClassName={(record, index) => {
                return index % 2 == 0 ? 'table-row-light' : '';
              }}
              columns={getColumns(
                intl,
                props.onEditForm,
                onDelete,
                props.onPrintForm,
                props.onFormResponse,
                props.onFormPending,
                onDefaultFormChange,
                props.categoryList,
                props.onAddFormClone,
                onSendFormEmail,
                {
                  sortBy: formListState.sortBy,
                  sortOrder: formListState.sortOrder,
                  filterIds: formListState.filterCategoryIds,
                  isFormAnalyticsEnabled: formAnalyticsEnabled,
                },
                props?.onGraphClick
              )}
              rowKey={(row) => row.id}
              onRow={(row) => {
                return {
                  onClick: () => {
                    props.onEditForm(row);
                  },
                };
              }}
              dataSource={formListState.formList}
              scroll={{y: finalHeight, x: 800}}
              pagination={{
                position: ['bottomCenter'],
                current: formListState.page,
                pageSize: formListState.pageSize,
                total: formListState?.totalForms,
              }}
              onChange={onTableChange}
            />
          </View>
        )}
        {(loading || formListState.loading) && (
          <Center margin={4} {...testID(TestIdentifiers.pageLoading)} flex={1}>
            <Skeleton.Text lines={5}/>
          </Center>
        )}
      </Box>
      <FHAlertDialog
        isOpen={formListState.deleteConfirmationOpen}
        header={intl.formatMessage({id: 'deleteForm'})}
        message={intl.formatMessage({id: 'deleteFormAlertMessage'})}
        buttonActions={[
          {
            textLocalId: 'cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
            },
            onPress: () => {
              setFormListState((prev) => ({
                ...prev,
                deleteConfirmationOpen: false,
                selectedForm: undefined,
              }));
            },
          },
          {
            textLocalId: 'delete',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
            },
            onPress: () => {
              deleteItem();
            },
          },
        ]}
      />
      {formEmailState.isEmailDrawerOpen && (
        <EmailDrawerCommonV2
          isOpen={formEmailState.isEmailDrawerOpen}
          onClose={() => {
            setFormEmailState((prev) => {
              return {
                ...prev,
                form: {},
                isEmailDrawerOpen: false,
              };
            });
          }}
          onEmailSent={() => {
            setFormEmailState((prev) => {
              return {
                ...prev,
                form: {},
                isEmailDrawerOpen: false,
              };
            });
          }}
          selectedForms={[
            {id: formEmailState.form.id, name: formEmailState.form.name},
          ]}
          templateCategoryCode={TEMPLATES_CONST.MULTIPLE_PATIENT_FORMS}
        />
      )}
    </View>
  );
};

export default FormList;
