import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 14,
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray300,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelected: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 10,
    borderColor: '#7F56D9',
    backgroundColor: '#7F56D9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedRadioCircle: {
    width: 10,
    height: 10,
    borderColor: '#7F56D9',
    backgroundColor: 'white',
    borderRadius: 5,
  },
});
