import React from 'react';
import { Path, Svg } from "react-native-svg";
import { ICommonSvgProps } from "../../../common/Svg/interfaces";

export const PendingIconSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.size || "14"}
      height={props.size || "14"}
      viewBox="0 0 14 14"
      fill="none"
    >
      <Path
        d="M7 1V3M13 7H11M7 13V11M1 7H3M2.75726 2.75735L4.17148 4.17157M11.2426 2.75735L9.82834 4.17157M11.2427 11.2426L9.82852 9.82841M2.75745 11.2426L4.17166 9.82841"
        stroke="#F79009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
