import {Path, Svg} from 'react-native-svg';

const ErrorEmailSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="12"
      height="10"
      fill="none"
      viewBox="0 0 12 10"
    >
      <Path
        stroke="#D92D20"
        strokeLinecap="round"
        d="M3 3l1.08.9c.918.765 1.377 1.147 1.92 1.147.543 0 1.002-.382 1.92-1.147L9 3M5 9h2c1.886 0 2.828 0 3.414-.586C11 7.828 11 6.886 11 5c0-1.886 0-2.828-.586-3.414C9.828 1 8.886 1 7 1H5c-1.886 0-2.828 0-3.414.586C1 2.172 1 3.114 1 5c0 1.886 0 2.828.586 3.414C2.172 9 3.114 9 5 9z"
      ></Path>
    </Svg>
  );
};

export default ErrorEmailSvg;
