import {CALL_EXTENSION_NAME_MAX_CHAR_LIMIT, EXCLUDED_EXTENSION_NUMBERS} from "./CallExtensionConst";
import {IAddCallExtensionDrawerState, IAddCallExtensionErrors} from "./interface";

export const validateAddExtensionData = (
  callExtensionData: IAddCallExtensionDrawerState
) => {
  const errors = {} as IAddCallExtensionErrors;
  let validate = true;

  errors.status = !callExtensionData?.status?.code
    ? 'Status is required'
    : '';

  errors.configurationName = !callExtensionData?.configurationName.trim()
    ? 'Name is required'
    : callExtensionData?.configurationName.trim()?.length < 3
    ? 'Enter minimum 3 characters'
    : callExtensionData?.configurationName?.length > CALL_EXTENSION_NAME_MAX_CHAR_LIMIT
    ? `Maximum ${CALL_EXTENSION_NAME_MAX_CHAR_LIMIT} characters are allowed`
    : '';

  errors.configurationCode = !callExtensionData?.configurationCode.trim()
    ? 'Extension code is required'
    : callExtensionData?.configurationCode?.length < 3 || callExtensionData?.configurationCode?.length > 6
    ? 'Extension must be 3 to 6 digits'
    : callExtensionData?.configurationCode[0] === '1' ? "The extension number shouldn't start with 1 since the USA country code is +1."
    : EXCLUDED_EXTENSION_NUMBERS.includes(callExtensionData?.configurationCode.trim()) ? 'Extension code is excluded' : ''

  errors.transferTo = !callExtensionData?.transferTo?.code
    ? 'Transfer to is required'
    : !callExtensionData?.selectedNumber?.id && !callExtensionData?.selectedDeskPhone?.id && !callExtensionData?.selectedUser?.id
    ? 'Please select option'
    : '';

  if (errors.status || errors.transferTo || errors.configurationCode) {
    validate = false;
  }
  return {
    validate: validate,
    errors: errors
  };
}