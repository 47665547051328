import {HStack, ITextProps, Text, View} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles/Colors';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';

const AlertBanner = (props: {
  message: string;
  visible: boolean;
  textColor?: string;
  iconColor?: string;
  backgroundColor?: string;
  customIcon?: string;
  customContainerStyle?: StyleProp<ViewStyle>;
  customContainerProps?: IHStackProps;
  customIconStyle?: StyleProp<TextStyle>;
  customTextStyle?: StyleProp<TextStyle>;
  customTextProps?: React.ForwardRefExoticComponent<ITextProps>;
}) => {
  const textColor = props.textColor || Colors.FoldPixel.GRAY400;
  const iconColor = props.iconColor || Colors.FoldPixel.STATUS_ERROR;
  const backgroundColor = props.backgroundColor || Colors.Custom.Red100;

  return (
    <View style={{display: props.visible ? 'flex' : 'none'}}>
      <HStack
        style={[
          {
            borderRadius: 4,
            backgroundColor: backgroundColor,
            paddingVertical: 5,
            paddingHorizontal: 8,
            alignItems: 'center',
          },
          props.customContainerStyle,
        ]}
        {...(props.customContainerProps ? props.customContainerProps : {})}
      >
        {props.customIcon ? (
          props.customIcon
        ) : (
          <Feather
            name="info"
            size={16}
            style={[{color: iconColor, marginRight: 8}, props.customIconStyle]}
          />
        )}
        <Text
          style={[
            {color: textColor, fontWeight: '400', fontSize: 12},
            props.customTextStyle,
          ]}
          {...(props.customTextProps ? props.customTextProps : {})}
        >
          {props.message}
        </Text>
      </HStack>
    </View>
  );
};
export default AlertBanner;
