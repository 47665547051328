import React from 'react';
import {Typography} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import {Dropdown, Menu, Space, Spin} from 'antd';
import './style.css';
import {FontWeight} from '@foldhealth/easy-email-extensions';
import {Colors} from '../../../../../styles/Colors';
const {Text, Title} = Typography;

const SpinContent = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 250,
      }}
    >
      <Spin />
    </div>
  );
};
const DisplayCard: React.FC<any> = ({
  children,
  title,
  subtitle,
  loading,
  itemsMenu = [],
}: {
  children: any;
  title?: any;
  subtitle?: string;
  loading?: boolean;
  itemsMenu?: [] | any;
}) => {
  return (
    <div className="display_card_container">
      <header style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Title level={5} className="display_card_title">
            {title}
          </Title>
          <Text
            //className="display_card_subtitle"
            style={{color: Colors.Custom.GraphSubTitleColor, fontWeight: 500, fontSize: 14,}}
          >
            {subtitle}
          </Text>
        </div>
      </header>
      <main style={{overflowY: 'auto', overflowX: 'hidden'}}>
        {loading ? <SpinContent /> : children}
      </main>
    </div>
  );
};

export default DisplayCard;
