import React from 'react';
import {Svg, Path} from 'react-native-svg';

function ResolvedAlertsSvg() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.667 8.6L4.762 11 10 5m3.333.042l-5.714 6-.286-.375"
      ></Path>
    </Svg>
  );
}

export default ResolvedAlertsSvg;
