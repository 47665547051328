import {useIntl} from 'react-intl';
import {
  MAIN_MENU_CODES,
  PROFILE_MENU_CONST,
  PROFILE_SIDE_BAR_TAB,
  SIDE_MENU_CONST,
  USER_ROLE_SIDE_BAR_CODES,
} from '../../SideMenuBar/SideBarConst';
import {IPermissions, IPermissionsList} from './interface';
import {
  getDefaultPermissionCode,
  USER_ACCESS_PERMISSION,
} from './UserAccessPermission';
import {USER_PERMISSIONS_CONST} from './UserPermissionConst';

export const getPermissionList = (entityCode: string, intl: any) => {
  let tabsPermission = [];
  const permissionList: any = [];
  if (entityCode === USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code) {
    tabsPermission = USER_ROLE_SIDE_BAR_CODES.USER.slice();
    if (USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW) {
      tabsPermission.push(PROFILE_SIDE_BAR_TAB);
    }
    tabsPermission.push(
      MAIN_MENU_CODES.EMPLOYER,
      MAIN_MENU_CODES.PATIENT_DETAILS,
      MAIN_MENU_CODES.SIGN_AMEND_EHR_NOTE
    );
  } else {
    tabsPermission = USER_ROLE_SIDE_BAR_CODES.ADMIN.slice();
  }
  tabsPermission = tabsPermission.filter(
    (tabCode: any) => tabCode !== MAIN_MENU_CODES.ANALYTICS_EMPLOYER
  );

  const tabMap = tabsPermission.reduce((acc: any, tabCode) => {
    acc[tabCode] = {code: tabCode};
    return acc;
  }, {});

  const sideBarMenu =
    USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW &&
      entityCode === USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
      ? [...SIDE_MENU_CONST, PROFILE_MENU_CONST]
      : SIDE_MENU_CONST;
  sideBarMenu.forEach((sideMenuData) => {
    const {menuCode, menuName, path} = sideMenuData;
    if (tabMap[menuCode]) {
      tabMap[menuCode] = {
        ...tabMap[menuCode],
        menuName,
        path,
        permissionCode: getDefaultPermissionCode(),
        entityCode,
      };
    }
  });
  Object.values(tabMap).forEach((permission: any) => {
    const {code, menuName, path} = permission;
    if (
      (![MAIN_MENU_CODES.PRODUCTS, MAIN_MENU_CODES.PROFILE].includes(code) &&
        code &&
        menuName &&
        path) ||
      [
        MAIN_MENU_CODES.PATIENT_DETAILS,
        MAIN_MENU_CODES.SIGN_AMEND_EHR_NOTE,
      ].includes(code)
    ) {
      if (
        [
          MAIN_MENU_CODES.PATIENT_DETAILS,
          MAIN_MENU_CODES.SIGN_AMEND_EHR_NOTE,
        ].includes(code)
      ) {
        permission.permissionCode = getDefaultPermissionCode();
        permission.menuName = intl.formatMessage({id: code});
        permission.entityCode = entityCode;
      }
      permissionList.push(permission);
    }
  });

  return permissionList;
};

export const getFormattedPermissionList = (args: {
  permissionList: IPermissionsList[],
  accountUuid: string,
  roleCode: string,
  accountRoleId: string
}) => {
  const {accountUuid, permissionList, roleCode, accountRoleId} = args
  const formattedPermission: IPermissions[] = permissionList.map(
    (singlePermission) => {
      return {
        accountUUID: accountUuid,
        actionCode: singlePermission.actionCode,
        entityCode: singlePermission.entityCode,
        permissionCode: singlePermission.permissionCode,
        userRoleCode: roleCode,
        actionId: singlePermission.actionId,
        accountRoleId: accountRoleId
      };
    }
  );
  return formattedPermission;
};
