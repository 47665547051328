import {Drawer, Dropdown, Menu} from 'antd';
import {Button, HStack, View} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {BUTTON_TYPE, RESPONSE_VIEW_CODE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import CustomDropdownButton from '../../common/Buttons/CustomDropdownButton';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import PlusIcon from '../../common/Svg/PlusSvg';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../common/TableTopBar';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import AddGlobalTheme from '../Forms/FormBuilderWidget/AddOrUpdateForm/AddGlobalTheme';
import FormBuilderWidget from '../Forms/FormBuilderWidget/FormBuilderWidget';
import {FormTopBarAction} from '../Forms/FormBuilderWidget/FormList/FormList';
import AddOrUpdateCampaign from './CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import {ICampaignData} from './CampaignManagement/interfaces';
import {styles} from './ContentManagementStyle';
import {
  getPathFromHash,
  navigateToAutomationScreen,
} from './ContentManagementUtils';
import {EmailComponents} from './EmailComponents';
import {EmailTemplates} from './EmailTemplates';
import {EmailTemplateOptions} from './EmailTemplates/EmailTemplateOptions';
import {EMAIL_TEMPLATE_OPTION_CODES} from './EmailTemplates/EmailTemplateOptions/EmailTemplateOptions';
import {IMediaLibraryData} from './MediaLibrary/interfaces';
import {MediaLibraryListView} from './MediaLibrary/MediaLibraryListView';
import {MEDIA_VIEW_CODES} from './MediaLibrary/MediaLibraryUtils';
import {PatientEducationMainView} from './PatientEducation/PatientEducationMainView';
import {PushNotifications} from './PushNotifications';
import {SingleNotification} from './PushNotifications/interfaces';
import {PushNotificationViewCodes} from './PushNotifications/PushNotificationConsts';
import {SMSTemplates} from './SMSTemplates';
import {SingleSMSTemplate} from './SMSTemplates/interfaces';
import {SMSTemplatesViewCodes} from './SMSTemplates/SMSTemplatesConsts';
import { USER_ACCESS_PERMISSION } from '../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import LLMTemplates from './LLMTemplates/LLMTemplates';
import { LLMTemplateViewCodes } from './LLMTemplates/llmTemplatesConsts';
import { getAccountUUID, getUserId, isSummaryComponentVisible } from '../../../utils/commonUtils';
import { SingleLLMTemplate } from './LLMTemplates/interfaces';
import { Responses } from '../TeamInbox';

export type EmailTemplatesViewCodes = 'templates' | 'components';

const ContentManagement = () => {
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const intl = useIntl();
  const [articlesTableState, setArticlesTableState] = useState({
    searchString: '',
    page: 1,
  });
  const [campaignTableState, setCampaignTableState] = useState({
    campaignTableData: [] as ICampaignData[],
    campaignTableDataRaw: [] as ICampaignData[],
  });

  const [mediaLibraryState, setMediaLibraryState] = useState({
    viewCode: MEDIA_VIEW_CODES.MEDIA_LIST_VIEW,
    searchString: '',
    selectedRow: {} as IMediaLibraryData,
  });

  const [notificationState, setNotificationState] = useState({
    viewCode: PushNotificationViewCodes.LIST,
    selectedRow: {} as SingleNotification,
    searchString: '',
  });

  const [smsTemplateState, setSmsTemplateState] = useState({
    viewCode: SMSTemplatesViewCodes.LIST,
    selectedRow: {} as SingleSMSTemplate,
    searchString: '',
  });
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    channel: undefined,
    templateId: undefined,
  });
  const [emailTemplatesState, setEmailTemplatesState] = useState({
    viewCode: 'templates' as EmailTemplatesViewCodes,
  });
  const [llmTemplateState, setLLMTemplateState] = useState({
    viewCode: LLMTemplateViewCodes.LIST,
    selectedRow: {} as SingleLLMTemplate,
    searchString: '',
  })
  const [formState, setFormState] = useState({
    isModalOpen: false,
    isAddExiting: false,
    isGlobalModalOpen: false,
    searchString: '',
  });

  const [componentState, setComponentState] = useState({
    searchString: '',
  });

  const [templateResponseState, setTemplateResponseState] = useState({
    searchString: '',
    currentModal: '',
    modalVisible: false,
  });
  const navigateToCreateComponentScreen = () => {
    navigate('/admin/contentManagement/emailComponent/create');
  };

  const userId : number | string = getUserId()
  const accountId = getAccountUUID()
  const isLLMTemplateVisible = isSummaryComponentVisible(accountId, userId)

  const navigate = useNavigate();
  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'emailTemplates',
        title: 'Emails',
        path: 'emailTemplates',
        tabContainerElem: () => {
          return (
            <EmailTemplates
              page={articlesTableState.page}
              searchString={articlesTableState.searchString}
              showTableTopBar={false}
              viewCode={emailTemplatesState.viewCode}
              onChangeViewCode={(viewCode: EmailTemplatesViewCodes) => {
                setEmailTemplatesState((prev) => {
                  return {
                    ...prev,
                    viewCode: viewCode,
                  };
                });
              }}
            />
          );
        },
      },
      {
        key: 'components',
        title: 'Components',
        path: 'emailComponent',
        tabContainerElem() {
          return <EmailComponents searchString={componentState.searchString} />;
        },
      },

      {
        key: 'forms',
        title: 'Forms',
        path: 'forms',
        resourceCode: MAIN_MENU_CODES.FORMS,
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        tabContainerElem: () => {
          return (
            <FormBuilderWidget
              isModalOpen={formState.isModalOpen}
              isAddExisting={formState.isAddExiting}
              setIsModalOpen={(flag: boolean) => {
                setFormState((prev) => {
                  return {
                    ...prev,
                    isModalOpen: flag,
                  };
                });
              }}
              setIsAddExisting={(flag: boolean) => {
                setFormState((prev) => {
                  return {
                    ...prev,
                    isAddExiting: flag,
                  };
                });
              }}
              searchString={formState.searchString}
            />
          );
        },
      },
      {
        key: 'smsTemplates',
        title: 'SMS',
        path: 'smsTemplates',
        tabContainerElem: () => {
          return (
            <SMSTemplates
              viewCode={smsTemplateState.viewCode}
              selectedRow={smsTemplateState.selectedRow}
              onActionPerformed={onSMSTemplatesActionPerformed}
              searchString={smsTemplateState.searchString}
            />
          );
        },
      },
      {
        key: 'pushNotifications',
        title: 'Push Notifications',
        path: 'pushNotifications',
        tabContainerElem: () => {
          return (
            <PushNotifications
              viewCode={notificationState.viewCode}
              selectedRow={notificationState.selectedRow}
              onActionPerformed={onNotificationsActionPerformed}
              searchString={notificationState.searchString}
            />
          );
        },
      },
      {
        key: 'mediaLibrary',
        title: 'Media',
        path: 'mediaLibrary',
        tabContainerElem: () => {
          return (
            <MediaLibraryListView
              viewCode={mediaLibraryState.viewCode}
              selectedRow={mediaLibraryState.selectedRow}
              searchString={mediaLibraryState.searchString}
              onActionPerformed={onMediaLibraryActionPerformed}
            />
          );
        },
      },
      {
        key: 'educationalArticles',
        title: 'Articles',
        path: 'patientEducation',
        tabContainerElem: () => {
          return (
            <PatientEducationMainView
              page={articlesTableState.page}
              searchString={articlesTableState.searchString}
              showTableTopBar={false}
            />
          );
        },
      },
      ...(isLLMTemplateVisible ? [
        {
          key: 'llmTemplates',
          title: 'Synopsis Template',
          path: 'llmTemplates',
          tabContainerElem: () => {
            return (
              <LLMTemplates
                viewCode={llmTemplateState.viewCode}
                selectedRow={llmTemplateState.selectedRow}
                onActionPerformed={onLLMTemplatesActionPerformed}
                searchString={llmTemplateState.searchString}
              />
            );
          },
        }

      ]: []),
      {
        key: 'cannedResponse',
        title: 'Template Responses',
        path: 'cannedResponse',
        tabContainerElem: () => {
          return (
            <Responses
              isClinicalResponse
              searchString={templateResponseState.searchString}
              currentModal={templateResponseState.currentModal}
              modalVisible={templateResponseState.modalVisible}
              showTableTopBar={false}
              onChangeViewCallBack={(text: string) => {
                setTemplateResponseState((prev) => ({...prev, currentModal: text}));
              }}
            />
          );
        },
      },
    ];
    return array;
  };

  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
  };
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setArticlesTableState((prev) => {
          return {
            ...prev,
            page: 1,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onFormTopBarActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setFormState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onComponentTopbarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setComponentState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onNotificationTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setNotificationState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onSMSTemplateTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setSmsTemplateState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onLLMTemplateTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setLLMTemplateState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onMediaLibraryTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setMediaLibraryState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  const onTableTopBarActionPerformedCampManag = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        const newCampaignData = campaignTableState.campaignTableDataRaw.filter(
          (item) => item.name?.includes(actionData?.searchString?.trim())
        );
        setCampaignTableState((prev) => {
          return {
            ...prev,
            campaignTableData: newCampaignData,
          };
        });
        break;
    }
  };

  const handleCreateTemplateOption = () => {
    navigate('/admin/contentManagement/emailTemplates/create');
  };

  const handlePasteTemplateOption = () => {
    navigate('/admin/contentManagement/emailTemplates/html');
  };

  const handleUploadZipOption = () => {
    navigate('/admin/contentManagement/emailTemplates/html', {
      state: {
        unZipOption: true,
      },
    });
  };

  const onEmailTemplatesOptionActionPerformed = (code: string) => {
    switch (code) {
      case EMAIL_TEMPLATE_OPTION_CODES.CREATE:
        handleCreateTemplateOption();

        break;
      case EMAIL_TEMPLATE_OPTION_CODES.PASTE_IN_CODE:
        handlePasteTemplateOption();
        break;
      case EMAIL_TEMPLATE_OPTION_CODES.UPLOAD_ZIP:
        handleUploadZipOption();
        break;
    }
  };

  const PatientEducationTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        onActionPerformed={onTableTopBarActionPerformed}
        searchBarWidth={180}
        buttonList={[
          {
            btnClick: () => {
              navigate('/admin/contentManagement/patientEducation/create');
            },
            btnText: 'new',
            backgroundColor: Colors.secondary['100'],
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            size: 'sm',
            variant: 'PRIMARY',
            leftIcon: <PlusIcon />,
          },
        ]}
      />
    </>
  );

  const MediaLibraryTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onMediaLibraryTableTopBarActionPerformed(actionCode, actionData);
        }}
        title=""
        searchBarWidth={180}
        buttonList={[
          {
            btnClick: () => {
              setMediaLibraryState((prev) => {
                return {
                  ...prev,
                  viewCode: MEDIA_VIEW_CODES.MEDIA_ADD_VIEW,
                };
              });
            },
            btnText: 'new',
            colorScheme: 'primary',
            id: 2,
            size: 'sm',
            backgroundColor: Colors.secondary['100'],
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            variant: '',
            leftIcon: <PlusIcon />,
          },
        ]}
      />
    </>
  );

  const EmailTemplatesTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        searchBarWidth={180}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        buttonList={[
          {
            btnText: `${
              emailTemplatesState.viewCode == 'templates'
                ? 'new'
                : 'Create Component'
            }`,
            id: 2,
            size: 'sm',
            backgroundColor: Colors.secondary['100'],
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            variant: '',
            leftIcon: <PlusIcon />,
            ...(emailTemplatesState.viewCode === 'templates' && {
              content: (
                <EmailTemplateOptions
                  onTemplateOptionActionPerformed={
                    onEmailTemplatesOptionActionPerformed
                  }
                />
              ),
            }),
            btnClick() {
              if (emailTemplatesState.viewCode == 'components') {
                navigateToCreateComponentScreen();
              } else {
              }
            },
          },
        ]}
      />
    </>
  );

  const ComponentsTopbar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        searchBarWidth={180}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onComponentTopbarActionPerformed(actionCode, actionData);
        }}
        buttonList={[
          {
            btnText: `new`,
            id: 2,
            size: 'sm',
            backgroundColor: Colors.secondary['100'],
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            variant: '',
            leftIcon: <PlusIcon />,
            btnClick() {
              navigateToCreateComponentScreen();
            },
          },
        ]}
      />
    </>
  );

  const CampaignManagmentTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        searchBarWidth={180}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformedCampManag(actionCode, actionData);
        }}
        buttonList={[
          {
            btnClick: () => {
              setCampaignDrawerState({
                isVisible: true,
                channel: undefined,
                templateId: undefined,
              });
            },
            btnText: 'new',
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            variant: '',
            leftIcon: <PlusIcon />,
          },
        ]}
      />
    </>
  );

  const addNewMenu = () => {
    return (
      <Menu
        items={[
          {
            key: FormTopBarAction.addNew,
            label: intl.formatMessage({id: 'addNew'}),
          },
          {
            key: FormTopBarAction.addExisting,
            label: intl.formatMessage({id: 'addExisting'}),
          },
        ]}
        onClick={(data: any) => {
          if (data.key === FormTopBarAction.addNew) {
            setFormState((prev) => {
              return {
                ...prev,
                isAddExiting: false,
                isModalOpen: !prev.isModalOpen,
              };
            });
          } else if (data.key === FormTopBarAction.addExisting) {
            setFormState((prev) => {
              return {
                ...prev,
                isAddExiting: true,
                isModalOpen: !prev.isModalOpen,
              };
            });
          }
        }}
      />
    );
  };

  const FormTopBar: JSX.Element = (
    <HStack justifyContent={'end'} alignItems={'center'} space={2}>
      <View>
        <TableTopBar
          onActionPerformed={(actionCode: string, actionData: any) => {
            onFormTopBarActionPerformed(actionCode, actionData);
          }}
          {...{
            title: '',
            buttonList: [],
          }}
          searchBarWidth={180}
          isDividerNotVisible={true}
        />
      </View>
      <HStack alignItems={'center'}>
        <Dropdown overlay={addNewMenu} placement="bottomRight">
          <Button h={8} variant="transparent">
            <CustomDropdownButton title={intl.formatMessage({id: 'new'})} />
          </Button>
        </Dropdown>

        {/* <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              setFormState((prev) => {
                return {
                  ...prev,
                  isGlobalModalOpen: !prev.isGlobalModalOpen,
                };
              });
            },
            leftIcon: <PlusIcon />,
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'globalTheme',
            }),
            withRightBorder: false,
          }}
        ></FoldButton> */}
      </HStack>
    </HStack>
  );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele: any = false;
    const selectedTabKey = selectedTab?.key;

    if (selectedTabKey === 'educationalArticles') {
      ele = PatientEducationTopBar;
    } else if (selectedTabKey === 'mediaLibrary') {
      ele = MediaLibraryTopBar;
    } else if (selectedTabKey === 'emailTemplates') {
      ele = EmailTemplatesTopBar;
    } else if (selectedTabKey === 'campaignManagement') {
      ele = CampaignManagmentTopBar;
    } else if (selectedTabKey === 'pushNotifications') {
      ele = PushNotificationTopBar;
    } else if (selectedTabKey === 'smsTemplates') {
      ele = SMSTemplatesTopBar;
    } else if (selectedTabKey === 'forms') {
      ele = FormTopBar;
    } else if (selectedTabKey === 'components') {
      ele = ComponentsTopbar;
    } else if (selectedTabKey === 'llmTemplates'){
      ele = LLMTemplatesTopBar;
    } else if (selectedTabKey ===  'cannedResponse') {
      ele = (
        <TableTopBar
          title={``}
          onActionPerformed={(actionCode, actionData) => {
            if (actionCode === TABLE_TOP_BAR_ACTION_CODES.SEARCH) {
              setTemplateResponseState((prev) => ({...prev, searchString: actionData?.searchString || ''}));
            }
          }}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchText={templateResponseState.searchString}
          placeHolderText={intl.formatMessage({id: 'searchTemplate'})}
          searchBarWidth={180}
          buttonList={[
            {
              btnText: 'new',
              size: 'smBold',
              textColor: Colors.Custom.mainPrimaryPurple,
              borderColor: Colors.Custom.mainPrimaryPurple,
              backgroundColor: '#ffffff00',
              variant: BUTTON_TYPE.PRIMARY,
              leftIcon: <PlusIcon/>,
              btnClick: () => {
                setTemplateResponseState((prev) => ({
                  ...prev,
                  currentModal: RESPONSE_VIEW_CODE.ADD_RESPONSE,
                  modalVisible: !prev.modalVisible
                }));
              },
            },
          ]}
        />
      );
    } else {
      ele = PatientEducationTopBar;
    }
    return ele;
  };

  const PushNotificationTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        onActionPerformed={onNotificationTableTopBarActionPerformed}
        searchBarWidth={180}
        buttonList={[
          {
            btnClick: () => {
              setNotificationState((prev) => {
                return {
                  ...prev,
                  viewCode: PushNotificationViewCodes.ADD,
                };
              });
            },
            btnText: 'new',
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            variant: '',
            leftIcon: <PlusIcon />,
          },
        ]}
      />
    </>
  );

  const SMSTemplatesTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        onActionPerformed={onSMSTemplateTableTopBarActionPerformed}
        searchBarWidth={180}
        buttonList={[
          {
            btnClick: () => {
              setSmsTemplateState((prev) => {
                return {
                  ...prev,
                  viewCode: SMSTemplatesViewCodes.ADD,
                };
              });
            },
            btnText: 'new',
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            variant: '',
            leftIcon: <PlusIcon />,
          },
        ]}
      />
    </>
  );

  const LLMTemplatesTopBar: JSX.Element = (
    <>
    <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title=""
        onActionPerformed={onLLMTemplateTableTopBarActionPerformed}
        searchBarWidth={180}
        buttonList={[
          {
            btnClick: () => {
              setLLMTemplateState((prev) => {
                return {
                  ...prev,
                  viewCode: LLMTemplateViewCodes.ADD,
                };
              });
            },
            btnText: 'new',
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            variant: '',
            leftIcon: <PlusIcon />,
          },
        ]}
      />
    </>
  )

  const onNotificationsActionPerformed = (
    actionCode: string,
    record: SingleNotification
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        setNotificationState((prev) => {
          return {
            ...prev,
            viewCode: PushNotificationViewCodes.DELETE,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.EDIT:
        setNotificationState((prev) => {
          return {
            ...prev,
            viewCode: PushNotificationViewCodes.EDIT,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.CANCEL:
        setNotificationState((prev) => {
          return {
            ...prev,
            viewCode: PushNotificationViewCodes.LIST,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
      case COMMON_ACTION_CODES.LIST_VIEW:
        setNotificationState((prev) => {
          return {
            ...prev,
            viewCode: PushNotificationViewCodes.LIST,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        setCampaignDrawerState({
          isVisible: true,
          channel: 'notification',
          templateId: record?.id,
        });
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        navigateToAutomationScreen({
          navigate,
          record: record,
          type: 'notification',
        });
        break;
    }
  };

  const onSMSTemplatesActionPerformed = (
    actionCode: string,
    record: SingleSMSTemplate
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        setSmsTemplateState((prev) => {
          return {
            ...prev,
            viewCode: SMSTemplatesViewCodes.DELETE,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.EDIT:
        setSmsTemplateState((prev) => {
          return {
            ...prev,
            viewCode: SMSTemplatesViewCodes.EDIT,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.CANCEL:
        setSmsTemplateState((prev) => {
          return {
            ...prev,
            viewCode: SMSTemplatesViewCodes.LIST,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
      case COMMON_ACTION_CODES.LIST_VIEW:
        setSmsTemplateState((prev) => {
          return {
            ...prev,
            viewCode: SMSTemplatesViewCodes.LIST,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        setCampaignDrawerState({
          isVisible: true,
          channel: 'sms',
          templateId: record?.id,
        });
        break;

      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        navigateToAutomationScreen({
          navigate,
          record: record,
          type: 'sms',
        });
        break;
    }
  };

  // const changeLLMViewCode = (viewCode:LLMTemplateViewCodes) => {
  //   setLLMTemplateState((prev) => {
  //     return{
  //       ...prev,
  //       viewCode: viewCode
  //     }
  //   })
  // }

  const onLLMTemplatesActionPerformed = (
    actionCode: string,
    record: SingleLLMTemplate
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        setLLMTemplateState((prev) => {
          return {
            ...prev,
            viewCode: LLMTemplateViewCodes.DELETE,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.EDIT:
        setLLMTemplateState((prev) => {
          return {
            ...prev,
            viewCode: LLMTemplateViewCodes.EDIT,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.CANCEL:
        setLLMTemplateState((prev) => {
          return {
            ...prev,
            viewCode: LLMTemplateViewCodes.CANCEL,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
      case COMMON_ACTION_CODES.LIST_VIEW:
        setLLMTemplateState((prev) => {
          return {
            ...prev,
            viewCode: LLMTemplateViewCodes.LIST,
            selectedRow: record,
          };
        });
        break;
      default:
        setLLMTemplateState((prev) => {
          return {
            ...prev,
            viewCode: LLMTemplateViewCodes.LIST,
            selectedRow: record,
          };
        });
    }
  }

  const onMediaLibraryActionPerformed = (
    actionCode: string,
    record: IMediaLibraryData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        setMediaLibraryState((prev) => {
          return {
            ...prev,
            selectedRow: record,
            viewCode: MEDIA_VIEW_CODES.MEDIA_DELETE_VIEW,
          };
        });
        break;
      case COMMON_ACTION_CODES.LIST_VIEW:
        setMediaLibraryState((prev) => {
          return {
            ...prev,
            viewCode: MEDIA_VIEW_CODES.MEDIA_LIST_VIEW,
            selectedRow: record,
          };
        });
        break;

      case COMMON_ACTION_CODES.PREVIEW:
        setMediaLibraryState((prev) => {
          return {
            ...prev,
            viewCode: MEDIA_VIEW_CODES.MEDIA_PREVIEW_VIEW,
            selectedRow: record,
          };
        });
        break;
    }
  };

  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tagsTabList().findIndex((item) => item.path === path);
    setSelectedTab(tagsTabList()[tabIndex === -1 ? 0 : tabIndex]);
  }, []);

  return (
    <View flex={1}>
      <Drawer
        visible={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            channel: undefined,
            templateId: undefined,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            channel={campaignDrawerState?.channel}
            templateId={campaignDrawerState?.templateId}
            close={() => {
              setCampaignDrawerState({
                isVisible: false,
                channel: undefined,
                templateId: undefined,
              });
            }}
          />
        ) : null}
      </Drawer>

      {/* <View style={{backgroundColor: '#fff'}}>
        <TitleSubtitleView
          titleLabelId="contentManagement"
          subtitleLabelId="contentManagementSubtitle"
        />
      </View> */}
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          contentTopMargin={0}
          showHeading
          heading="contentTitle"
          basePath={'/admin/contentManagement'}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          minScreenSupportWidth={1600}
          isAddMaxTab
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          bottomOffset={140}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
      {formState.isGlobalModalOpen && (
        <AddGlobalTheme
          isVisible={formState.isGlobalModalOpen}
          onCancel={() => {
            {
              setFormState((prev) => {
                return {
                  ...prev,
                  isGlobalModalOpen: !prev.isGlobalModalOpen,
                };
              });
            }
          }}
          onComplete={() => {
            setFormState((prev) => {
              return {
                ...prev,
                isGlobalModalOpen: !prev.isGlobalModalOpen,
              };
            });
          }}
        />
      )}
    </View>
  );
};

export default ContentManagement;
