import {Path, Svg} from 'react-native-svg';

const ErrorSvg = (props: {strokeColor?: string; size?: number, style?: React.CSSProperties}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="12"
      height="10"
      fill="none"
      viewBox="0 0 12 10"
    >
      <Path
        fill="#D92D20"
        d="M2.474 4.703l-.435-.245.435.245zm7.052 0l-.436.246.436-.246zm-.182-.322l.436-.246-.436.246zm-6.688 0l.435.245-.435-.245zM6 .5V1 .5zm-.5 7a.5.5 0 001 0h-1zm1-3a.5.5 0 00-1 0h1zm-1-1.25a.5.5 0 001 0h-1zm1-.25a.5.5 0 00-1 0h1zm2.409 1.626l.181.323.871-.492-.181-.322-.871.491zM6.182 9h-.364v1h.364V9zM2.91 4.949l.181-.323-.87-.49-.182.322.87.49zM5.818 9c-1.37 0-2.347-.001-3.053-.094-.71-.093-1.003-.264-1.152-.478L.793 9c.399.572 1.058.794 1.841.897.787.104 1.843.103 3.184.103V9zm-3.78-4.542C1.442 5.516.962 6.364.71 7.047.454 7.74.38 8.407.793 9l.82-.572c-.134-.192-.18-.456.034-1.035.218-.589.647-1.353 1.263-2.444l-.871-.491zm7.052.49c.616 1.092 1.045 1.856 1.263 2.445.213.579.168.843.034 1.035l.82.572c.414-.594.34-1.26.084-1.953-.252-.683-.732-1.53-1.33-2.59l-.87.492zM6.182 10c1.34 0 2.397.001 3.184-.103.783-.103 1.442-.325 1.841-.897l-.82-.572c-.15.214-.442.385-1.152.478C8.53 8.999 7.552 9 6.182 9v1zM9.78 4.135c-.721-1.278-1.29-2.29-1.829-2.974C7.406.469 6.806 0 6 0v1c.35 0 .692.178 1.165.78.48.609 1.005 1.538 1.744 2.846l.87-.49zm-6.689.491C3.83 3.318 4.355 2.39 4.835 1.78 5.308 1.178 5.65 1 6 1V0c-.806 0-1.406.469-1.951 1.16-.539.685-1.108 1.697-1.829 2.975l.871.491zM6.5 7.5v-3h-1v3h1zm0-4.25V3h-1v.25h1z"
      ></Path>
    </Svg>
  );
};

export default ErrorSvg;
