import { DatePicker, Select, Skeleton } from 'antd';
import { Divider, HStack, Image, Text, VStack, View, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { DISPLAY_DATE_FORMAT, MLOV_CATEGORY, TRACKING_RESOURCE_TYPE } from '../../../../../constants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { Colors } from '../../../../../styles/Colors';
import { getMomentObj, isPastDay } from '../../../../../utils/DateUtils';
import { getVitalListFromCapability } from '../../../../../utils/capabilityUtils';
import { isJourneyPackagesEnabled } from '../../../../../utils/commonUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../../../utils/mlovUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { IContactsPracticeLocationsData } from '../../../MembersManagement/interfaces';
import { IPackage, ITaxonData } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';
import { JourneyDurationInputForAssign } from '../../AddOrUpdateJourney/DetailsAndMembers/DetailsAndMembers';
import { IJourneyDurationData } from '../../AddOrUpdateJourney/DetailsAndMembers/Interfaces';
import { CareJourneyDocumentsList } from '../../AddOrUpdateJourney/UploadDocuments/CareJourneyDocumentsList';
import { IAssignCareJourneyNavigationState } from '../AssignCareJourneyWorkflow/AssignCareJourneyWorkflow';
import { getFilteredLocation, getJourneyStartDate, getSelectedCareJourney, isContactInSelectedLocation } from './PatientCareJourneyHelper';
import { IPatientCareJourneyDetailProps, IPatientDetailViewProps } from './PatientJourneyInterface';
import MultipleLabAnalyteSearch from '../../../../common/LabSearch/MultipleLabAnalyteSearch';
import { getEnabledVitalListFromCapability } from '../../../../../utils/VitalUtils';


const PatientCareJourneyDetailView = (props: IPatientDetailViewProps) => {
  const commonDataContext = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const location = useLocation();
  const patientCareJourneyId = location?.state?.patientCareJourneyId;
  const locationId = props?.stateData?.contactPracticeLocations[0]?.accountLocation?.uuid
  const state = location.state as IAssignCareJourneyNavigationState;
  const contactsPracticeLocationsData = location?.state?.bulkApiData?.groups[0]?.contactsPracticeLocationsData;
  const isPackagesEnabled = isJourneyPackagesEnabled();
  const journeyDurationUnitList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION,
    ) || [];

  const journeyDetail = props.journeyDetail;
  const vitalList = getEnabledVitalListFromCapability(locationId);

  const [patientCareJourney, setPatientCareJourney] = useState<IPatientCareJourneyDetailProps>({
    selectedJourney: {
      name: journeyDetail.name,
      description: journeyDetail.description,
      memberOutcome: journeyDetail.memberOutcome,
      businessOutcome: journeyDetail.businessOutcome,
      triggerEvents: [],
      duration: journeyDetail.duration,
      careTeam: journeyDetail.careTeam,
      prevCareTeam: journeyDetail.prevCareTeam,
      shipping_category_id: journeyDetail.shipping_category_id,
      available_on: journeyDetail.available_on,
      price: props.productDetail?.data?.attributes?.display_price || journeyDetail.display_price,
      cost_currency: 'USD',
      compare_at_price: props.productDetail?.data?.attributes?.display_compare_at_price || journeyDetail.compare_at_price,
      sku: journeyDetail.sku,
      startDate: getJourneyStartDate(journeyDetail),
      careJourneyTrackingResources: journeyDetail?.careJourneyTrackingResources,
      taxons: [] as ITaxonData[],
      patientCareJourneyLocations: patientCareJourneyId ? journeyDetail?.patientCareJourneyLocations : journeyDetail?.careJourneyLocations?.locations,
      patientRecommendedCareJourney : patientCareJourneyId ? journeyDetail?.patientRecommendedCareJourney : journeyDetail?.recommendedCareJourney?.recommendedCareJourney,
      patientCareJourneyDocuments: patientCareJourneyId ? journeyDetail?.patientCareJourneyDocuments : journeyDetail?.careJourneyDocuments,
      showLocationAlert : false
    },
    loading: false,
  });

  const getDurationUnitValue = () => {
    return patientCareJourney.selectedJourney?.duration?.unit
      ? getMlovValueFromId(
          journeyDurationUnitList,
          patientCareJourney.selectedJourney?.duration?.unit,
        )
      : '';
  };

  const getFilteredAccountLocation = ()=> {
    return getFilteredLocation(props?.accountLocation || [],patientCareJourney?.selectedJourney?.patientCareJourneyLocations || [])
  }

  const selectedCareJourney = ()=> {
    return getSelectedCareJourney(props?.stateData?.careJourneys || [],patientCareJourney?.selectedJourney?.patientRecommendedCareJourney || [])
  }

  const showLocationError = ()=> {
    if (contactsPracticeLocationsData) {
      let showError = false;
      contactsPracticeLocationsData.forEach((contactPracticeLocations: IContactsPracticeLocationsData)=>{
        const checkContactInSelectedLocation = isContactInSelectedLocation(getFilteredAccountLocation(), contactPracticeLocations);
        const patientCareJourneyId = props?.stateData?.patientCareJourneyId;
        if (!checkContactInSelectedLocation && !patientCareJourneyId && patientCareJourney?.selectedJourney?.patientCareJourneyLocations?.length) {
          showError = true;
        }
      })
      return showError;
    }
    return compareContactLocation();
  }

  const compareContactLocation = ()=> {
    const checkContactInSelectedLocation = isContactInSelectedLocation(getFilteredAccountLocation(),props?.stateData);
    if (!checkContactInSelectedLocation && !patientCareJourneyId && patientCareJourney?.selectedJourney?.patientCareJourneyLocations?.length) {
      return true;
    }
    return false;
  }

  const getDuration = () => {
    return ` ${patientCareJourney.selectedJourney?.duration?.value} ${getDurationUnitValue()}`;
  };

  const showDocumentList = ()=> {
    const patientCareJourneyDocuments = patientCareJourney?.selectedJourney?.patientCareJourneyDocuments || [];
    return patientCareJourneyDocuments?.length ?  true : false;
  }

  useEffect(() => {
    if (props?.onChange) {
      const showLocationMatchError = showLocationError() || false;
      patientCareJourney.selectedJourney.showLocationAlert = showLocationMatchError;
      props.onChange(patientCareJourney.selectedJourney);
    }
  }, [patientCareJourney.selectedJourney.duration, patientCareJourney.selectedJourney.startDate, patientCareJourney.selectedJourney.careJourneyTrackingResources]);

  return (
    <View style={{padding: 16}}>
      {patientCareJourney.loading && (
        <View width="full">
          <Skeleton active />
        </View>
      )}
      {!patientCareJourney.loading && (
        <View
        style={{
          backgroundColor: '#fff',
          borderColor: Colors.Custom.BorderColor,
          borderWidth: 1,
          borderRadius: 8,
          padding: 8,
          minHeight: 500,
          flexDirection:'row',
        }}>
          <HStack flex={1} justifyContent={'space-between'} space={2} marginBottom={4}>
            <HStack flex={1} style={{ paddingHorizontal: 12 }}>
              <VStack flex={1}>
                <Text fontWeight={700} fontSize={24} fontFamily={'Manrope'}>
                  {patientCareJourney?.selectedJourney?.name}
                </Text>
                {/* {patientCareJourney?.selectedJourney?.price &&
                patientCareJourney?.selectedJourney?.compare_at_price && (
                  <HStack marginTop={1}>
                    <Text
                      fontWeight={700}
                      fontSize={18}
                      fontFamily={'Manrope'}
                      color={Colors.Custom.Gray700}>
                      {`${patientCareJourney?.selectedJourney?.price}  `}
                    </Text>
                    <Text
                      fontWeight={400}
                      fontSize={18}
                      fontFamily={'Manrope'}
                      color={Colors.Custom.Gray700}
                      style={{ textDecorationLine: 'line-through' }}
                    >
                      ({patientCareJourney?.selectedJourney?.compare_at_price})
                    </Text>
                  </HStack>
                )} */}
                <Divider marginTop={4} marginBottom={4} />
                <HStack>
                  <VStack>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        color: Colors.Custom.Gray700,
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: 'Manrope',
                        paddingLeft: 6
                      }}
                      textLocalId={'startDate'}
                    />
                    <View marginTop={1.5} width={200} borderColor={'#D0D5DD'}>
                      <DatePicker
                        style={
                          {
                            height: 40,
                            borderRadius: 6
                          }
                        }
                        value={patientCareJourney.selectedJourney.startDate ? getMomentObj(patientCareJourney.selectedJourney.startDate) : undefined}
                        format={DISPLAY_DATE_FORMAT}
                        disabled={props.disableStartDateAndDurationInput}
                        onChange={(value, dateString) => {
                          setPatientCareJourney(prev => {
                            const selectedJourney = prev.selectedJourney;
                            selectedJourney.startDate = dateString;
                            return {
                              ...prev,
                              selectedJourney: selectedJourney,
                            }
                          });
                        }}
                        disabledDate={(current: any) => {
                          return current && isPastDay(current);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") {
                            const inputElement = e.target as HTMLInputElement;
                            if (inputElement.value === "") {
                            setPatientCareJourney((prev) => {
                              const selectedJourney = prev.selectedJourney;
                              selectedJourney.startDate = undefined;
                              return {
                                ...prev,
                                selectedJourney: selectedJourney,
                              };
                            });
                          }
                          }
                        }
                      }
                      />
                    </View>
                  </VStack>
                  <VStack marginLeft={2}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        color: Colors.Custom.Gray700,
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: 'Manrope',
                        paddingLeft: 8
                      }}
                      textLocalId={'journeyDuration'}
                    />
                    <HStack marginTop={1.5} marginBottom={4}>
                      <JourneyDurationInputForAssign
                        labelId={''}
                        value={
                          patientCareJourney.selectedJourney.duration || {} as IJourneyDurationData
                        }
                        isReadOnly={props.disableStartDateAndDurationInput}
                        maxWidth={150}
                        unitDropDownHeight={9}
                        onChange={(value) => {
                          setPatientCareJourney(prev => {
                            const selectedJourney = prev.selectedJourney;
                            selectedJourney.duration = value;
                            return {
                              ...prev,
                              selectedJourney: selectedJourney,
                            }
                          });
                        }}
                      />
                    </HStack>
                  </VStack>
                </HStack>
                <Divider marginTop={1} marginBottom={4} />
                <DisplayText
                  size={'mdSemibold'}
                  extraStyles={{
                    fontWeight: 700,
                    fontSize: 18,
                    fontFamily: 'Manrope',
                    marginTop: 1,
                  }}
                  textLocalId={'aboutJourney'}
                />
                <Text
                  fontWeight={400}
                  fontSize={14}
                  fontFamily={'Manrope'}
                  color={Colors.Custom.Gray700}>
                  {patientCareJourney.selectedJourney?.description}
                </Text>

                {isPackagesEnabled && (
                  <HStack flex={1} justifyContent={'space-between'}>
                    <VStack flex={0.49} mt={2}>
                      <DisplayText
                        textLocalId={'locations'}
                        size={'smMedium'}
                        extraStyles={{
                          fontWeight: 700,
                          fontSize: 18,
                          fontFamily: 'Manrope',
                          marginTop: 1,
                        }}
                      />
                      {getFilteredAccountLocation().length ? (
                        <Text
                          fontWeight={400}
                          fontSize={14}
                          marginTop={'2px'}
                          fontFamily={'Manrope'}
                          color={Colors.Custom.Gray700}
                        >
                          {getFilteredAccountLocation()?.map((location: any) => {
                              return location.label
                            }).join(', ')}
                        </Text>
                      ) : (
                        <Text
                          fontWeight={400}
                          fontSize={14}
                          marginTop={'2px'}
                          fontFamily={'Manrope'}
                          color={Colors.Custom.Gray700}
                        >
                          {'-'}
                        </Text>
                      )}
                    </VStack>
                    <VStack mt={2} flex={0.49}>
                      <DisplayText
                        textLocalId={'recommendedPackages'}
                        size={'smMedium'}
                        extraStyles={{
                          fontWeight: 700,
                          fontSize: 18,
                          fontFamily: 'Manrope',
                          marginTop: 1,
                        }}
                      />
                      {props.selectedPackages.length ? (
                        props.selectedPackages.map(
                          (recommendedPackage: IPackage) => {
                            return (
                              <Text
                                fontWeight={400}
                                fontSize={14}
                                marginTop={'2px'}
                                fontFamily={'Manrope'}
                                color={Colors.Custom.Gray700}
                              >
                                {recommendedPackage.name}
                              </Text>
                            );
                          }
                        )
                      ) : (
                        <Text
                          fontWeight={400}
                          fontSize={14}
                          marginTop={'2px'}
                          fontFamily={'Manrope'}
                          color={Colors.Custom.Gray700}
                        >
                          {'-'}
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                )}

                {/* {patientCareJourney.selectedJourney?.memberOutcome && (
                  <View marginTop={4}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                        fontFamily: 'Manrope',
                      }}
                      textLocalId={'memberOutcome'}
                    />
                    <Text
                      fontWeight={400}
                      fontSize={14}
                      fontFamily={'Manrope'}
                      color={Colors.Custom.Gray700}>
                      {patientCareJourney.selectedJourney?.memberOutcome}
                    </Text>
                  </View>
                )}

                {patientCareJourney.selectedJourney?.businessOutcome && (
                  <View marginTop={4}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                        fontFamily: 'Manrope',
                      }}
                      textLocalId={'businessOutcome'}
                    />
                    <Text
                      fontWeight={400}
                      fontSize={14}
                      fontFamily={'Manrope'}
                      color={Colors.Custom.Gray700}>
                      {patientCareJourney.selectedJourney?.businessOutcome}
                    </Text>
                  </View>
                )} */}

                 <View marginTop={4} flexDirection={'row'}>
                   <VStack flex={1}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                        fontFamily: 'Manrope',
                      }}
                      textLocalId={'vitalsTracking'}
                    />
                    <Select showArrow={true} filterOption={(input, option: any) => {
                          return (
                            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          );
                        }} value={(patientCareJourney.selectedJourney.careJourneyTrackingResources|| []).filter((careJourneyTrackingResource)=>{return careJourneyTrackingResource?.trackingResourceType == TRACKING_RESOURCE_TYPE?.VITALS && !careJourneyTrackingResource?.isDeleted}).map((careJourneyTrackingResource)=>{
                  return careJourneyTrackingResource.trackingResourceCode
                })} allowClear style={{ width: '98%' }}  options={vitalList.map((vital)=>{
                  return {
                    label : vital?.foldDisplay || '',
                    value: vital?.loinc || '',
                  }
                })} onChange={(v1)=>{
                  let careJourneyTrackingResources = JSON.parse(JSON.stringify(patientCareJourney.selectedJourney.careJourneyTrackingResources||[]))

                  careJourneyTrackingResources = careJourneyTrackingResources?.map((careJourneyTrackingResource:any)=>{
                    if(careJourneyTrackingResource.trackingResourceType == TRACKING_RESOURCE_TYPE?.VITALS && v1.indexOf( careJourneyTrackingResource?.trackingResourceCode)==-1){
                      careJourneyTrackingResource.isDeleted = true
                    }
                    return careJourneyTrackingResource
                  })
                  v1.forEach((trackingResourceCode)=>{
                    const isPresent= careJourneyTrackingResources.some((careJourneyTrackingResource:any)=>{ if( careJourneyTrackingResource?.trackingResourceCode == trackingResourceCode){
                      careJourneyTrackingResource.isDeleted = false;
                      return true;
                    }})
                    if(!isPresent){
                      careJourneyTrackingResources.push({
                        trackingResourceCode: trackingResourceCode,
                        trackingResourceType:TRACKING_RESOURCE_TYPE?.VITALS,
                        isDeleted:false
                      })
                    }
                  });
                  careJourneyTrackingResources = careJourneyTrackingResources.filter((careJourneyTrackingResource:any)=>{
                    return !(careJourneyTrackingResource.isDeleted && !careJourneyTrackingResource?.id)
                  })
                  setPatientCareJourney((prev)=>{
                    return  {
                      ...prev,
                      selectedJourney: {
                        ...prev.selectedJourney,
                        careJourneyTrackingResources:careJourneyTrackingResources
                      }
                    }
                  })
                }} placeholder="Please select" mode="multiple"></Select>
                </VStack>
                <VStack flex={1}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                        fontFamily: 'Manrope',
                      }}
                      textLocalId={'labTestTracking'}
                    />
                    <MultipleLabAnalyteSearch isShowError={false} value={(patientCareJourney.selectedJourney.careJourneyTrackingResources|| []).filter((careJourneyTrackingResource)=>{return careJourneyTrackingResource?.trackingResourceType == TRACKING_RESOURCE_TYPE?.LAB_TEST && !careJourneyTrackingResource?.isDeleted}).map((careJourneyTrackingResource)=>{
                  return careJourneyTrackingResource.trackingResourceCode
                })} onChange={(v1:any)=>{
                  let careJourneyTrackingResources = JSON.parse(JSON.stringify(patientCareJourney.selectedJourney.careJourneyTrackingResources||[]))
                  
                  careJourneyTrackingResources = careJourneyTrackingResources?.map((careJourneyTrackingResource:any)=>{
                    if(careJourneyTrackingResource.trackingResourceType == TRACKING_RESOURCE_TYPE?.LAB_TEST && v1.indexOf( careJourneyTrackingResource?.trackingResourceCode)==-1){
                      careJourneyTrackingResource.isDeleted = true
                    }
                    return careJourneyTrackingResource
                  })
                  v1.forEach((trackingResourceCode:string)=>{
                    const isPresent= careJourneyTrackingResources.some((careJourneyTrackingResource:any)=>{ if( careJourneyTrackingResource?.trackingResourceCode == trackingResourceCode){
                      careJourneyTrackingResource.isDeleted = false;
                      return true;
                    }})
                    if(!isPresent){
                      careJourneyTrackingResources.push({
                        trackingResourceCode: trackingResourceCode,
                        trackingResourceType:TRACKING_RESOURCE_TYPE?.LAB_TEST,
                        isDeleted:false
                      })
                    }
                  });
                  careJourneyTrackingResources = careJourneyTrackingResources.filter((careJourneyTrackingResource:any)=>{
                    return !(careJourneyTrackingResource.isDeleted && !careJourneyTrackingResource?.id)
                  })
                  setPatientCareJourney((prev)=>{
                    return  {
                      ...prev,
                      selectedJourney: {
                        ...prev.selectedJourney,
                        careJourneyTrackingResources:careJourneyTrackingResources
                      }
                    }
                  })
                }} />
                </VStack> 
                </View> 

                



              </VStack>
            </HStack>
          </HStack>
          <VStack ml={-2} borderLeftWidth={1} borderColor={'gray.300'} flex={0.3}>
              <View mt={3} pl={2} pb={3} borderBottomWidth={1} borderColor={'gray.300'}>
                <DisplayText
                  textLocalId={'supportingDocuments'}
                  size={'smMedium'}
                  extraStyles={{
                    fontWeight: 700,
                    fontSize: 18,
                    fontFamily: 'Manrope',
                    marginTop: 1,
                  }}
                />
              </View>
              {showDocumentList()  ? <View mt={2} pl={2}>
                <CareJourneyDocumentsList disableAction={true} documents={patientCareJourney?.selectedJourney?.patientCareJourneyDocuments}/>
              </View> : <VStack mt={2} alignItems={'center'} space={2} >
                  <Image
                    resizeMode="contain"
                    size={'sm'}
                    source={require('../../../../../assets/images/EmptyStatePng/noDocument.png')}
                    alt="image"
                  ></Image>
                  <Text fontSize={'10px'} fontWeight={600} color={'gray.400'}>
                    {'No Files Added'}
                  </Text>
                </VStack>}
          </VStack>
      </View>
      )}
    </View>
  );
};

export default PatientCareJourneyDetailView;
