import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Icon, IconButton, Spinner, Text, useMediaQuery, View, useToast} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import FontAwesome from 'react-native-vector-icons/Ionicons';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {LOCATION_TYPE_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CloudTelephonyQueries} from '../../../../services';
import {getDefaultPhoneNumberByLocation} from '../../../../services/CloudTelephony/CloudTelephonyQueries';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getAccountUUID,
  getUserUUID,
  numericStringMask,
} from '../../../../utils/commonUtils';
import {getMlovIdFromCode} from '../../../../utils/mlovUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import PhoneRingSvg from '../../../common/Svg/PhoneRingSvg';
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import {IUser} from '../interfaces';
import AssignPurchaseView from '../PurchaseNumberView/AssignPurchaseView';
import NumberSetupView from '../PurchaseNumberView/NumberSetupView';
import {styles} from './ActiveNumbersTableStyles';
import { updateRecordingAnnouncement } from './ActiveNumberTelephonyService';
import { IRecordingAnnouncement } from './interface';
import { useIntl } from 'react-intl';
interface IEditAssigneeToNumber {
  onFormCompleteAction: (actionCode: string, data?: any, selectedInbox?:any) => void;
  selectedData: any;
  userList: IUser[];
  ivrFlowList?: any;
  inboxes?:any[];
  isFirstTimeSetup?: boolean;
}

const EditAssigneeToNumber = (props: IEditAssigneeToNumber) => {
  const axios = BaseService.getSharedInstance().axios;
  const currentUserUUID = getUserUUID();
  const accountUUID = getAccountUUID()
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const toast = useToast();
  const mask = `(###) ###-####`;
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const assigneesList = mlovData['VirtualNumberAssignee'] || [];
  const tempFlowType = mlovData['FlowType'] || [];
  const userAssigneeType = getMlovIdFromCode(assigneesList,'USER');
  const clinicAssigneeType = getMlovIdFromCode(assigneesList, 'CLINIC');
  const otherAssigneeType = getMlovIdFromCode(assigneesList,'OTHER');
  const [stateData, setStateData] = useState({
    selectedUser:  '',
    error: '',
    assigneeType: assigneesList[0]?.id,
    assigneeTypeCode: assigneesList[0]?.code,
    selectedFlowType:  '', //flowTypeMlov[0].id,
    selectedFlowTypeCode: '', //flowTypeMlov[0].code,
    forwardNumberValue: '',
    oldForwardNumberValue: '',
    selectedIvrFlow: '',
    clinicName: '',
    othersName: '',
    otpView: 'OTP_SEND',
    resendOtp: true,
    otpValue: '',
    otpInputDisabled: false,
    selectedUserName: '',
    stdCode: '+1',
    ///
    name: '',
    selectedUsersIds: [] as number[],
    selectedPhoneNumber: '',
    errors: {} as any,
    isEnableSmsInbox: false,
    smsLoading: false,
    selectedInbox: {} as any,
    selectedAccountLocation: {} as IAccountLocation,
    defaultForLocationResp: [],
    isAnnouncementEnabled: false,
  });
  const [UpdateVirtualNumber] = useMutation(
    CloudTelephonyQueries.UPDATE_VIRTUAL_NUMBER
  );

  const onCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      props.onFormCompleteAction(COMMON_ACTION_CODES.COMPLETED, {});
    }, 100);
  };
  const onEditAction = () => {
    if (
      stateData.otpView === 'OTP_SEND' &&
      stateData.forwardNumberValue.length &&
      stateData.selectedFlowTypeCode === 'NUMBER_FORWARD'
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          error: 'Please click on Send OTP to get otp',
        };
      });
      return;
    } else if(stateData.selectedFlowTypeCode === 'IVR') {
      setStateData((prev) => {
        return {
          ...prev,
          error: 'Please click on Set Ivr Flow',
        };
      });
      return;
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          error: '',
        };
      });
    }
    setLoading(true);
    let body = {} as any;
    let createVNObject = {
      virtualPhoneNumber: props.selectedData.phoneNumber.phoneNumber,
    } as any;
    if(stateData.assigneeTypeCode == 'USER'){
      createVNObject = {...createVNObject, assigneeId: stateData.selectedUser};
      createVNObject = {...createVNObject, assigneeTypeId: userAssigneeType};
      createVNObject = {...createVNObject, assignee: stateData.name, flowTypeId: null,flowType: null, flowId: null};
    } if(stateData.assigneeTypeCode == 'CLINIC') {
      createVNObject = {...createVNObject, assigneeId: stateData.selectedAccountLocation.uuid};
      createVNObject = {...createVNObject, assigneeTypeId: clinicAssigneeType};
      createVNObject = {...createVNObject, assignee: stateData.selectedAccountLocation?.practiceLocation?.name, flowTypeId: null,flowType: null, flowId: null};
    } else {
      createVNObject = {...createVNObject, assigneeId: null, assigneeTypeId: null};
      createVNObject = {...createVNObject, assigneeTypeId: otherAssigneeType};
      createVNObject = {...createVNObject, assignee: stateData.name};
    }
    if (stateData.assigneeTypeCode == 'USER') {
      body = JSON.stringify({
        userUUID: stateData.selectedUser,
        name: stateData.name,
        phoneNumber: props.selectedData.phoneNumber.phoneNumber,
      });
    } else if (stateData.assigneeTypeCode == 'CLINIC') {
      body = JSON.stringify({
        userUUID: stateData?.selectedAccountLocation?.uuid,
        name: stateData?.selectedAccountLocation?.practiceLocation?.name,
        phoneNumber: props.selectedData.phoneNumber.phoneNumber,
      });
    } else if (stateData.assigneeTypeCode == 'OTHER') {
      body = JSON.stringify({
        userUUID: stateData.othersName.replace(/\s+/g, ''),
        name: stateData.name,
        phoneNumber: props.selectedData.phoneNumber.phoneNumber,
      });
    }
    if (
      !stateData.name
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          error: 'Please select Users/Name',
        };
      });
      return;
    }
    const bodyStr = body;
    axios
      .put(`/cloud-telephony/phone-numbers/update`, bodyStr)
      .then(async (res) => {
        const responseData: any = await UpdateVirtualNumber({
          context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
          variables: {
            updateObject: createVNObject,
            id: props.selectedData?.rawData?.id,
          },
        });
        if (props.onFormCompleteAction) {
          props.onFormCompleteAction(COMMON_ACTION_CODES.FLOW_UPDATED, {});
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 100);
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');
  const finalWidth =
  isIPadScreen || isIPadMiniScreen ? (width * 2) / 4 : (width * 2) / 7;
  const isNumberIsDefaultForLocation = async () => {
    const locationId = props.selectedData?.rawData?.assigneeId
    const defaultNumberForLocation: any = await getDefaultPhoneNumberByLocation({
      accountUuid: accountUUID,
      locationId: locationId,
    })
    if (defaultNumberForLocation?.data?.length) {
      setStateData(prev=> {
        return {
          ...prev,
          defaultForLocationResp: defaultNumberForLocation?.data
        }
      })
    }
  }

  const updateRecordingAnnouncementFlag = async (isEnabled: boolean) => {
    const recordingAnnouncement: IRecordingAnnouncement = {
      isAnnouncementEnabled: isEnabled,
      id: props.selectedData?.rawData?.id
    }
    const response = await updateRecordingAnnouncement(recordingAnnouncement);

    if (response?.data?.id) {
      setStateData((prev) => ({
        ...prev,
        isAnnouncementEnabled: response?.data?.isAnnouncementEnabled,
        loading: false,
      }));
      showToast(
        toast,
        intl.formatMessage({id: 'statusUpdatedSuccessfully'}),
        ToastType.success
      );
    } else {
      setStateData((prev) => ({
        ...prev,
        loading: false
      }));
    }
  }

  useEffect(() => {
    if (props.selectedData.assignedTypeCode === LOCATION_TYPE_CODES.AT_CLINIC) {
      isNumberIsDefaultForLocation()
    }
    setStateData((prev) => ({
      ...prev,
      isAnnouncementEnabled: props.selectedData?.rawData?.isAnnouncementEnabled
    }));
  }, [])
  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      closable={true}
      onClose={() => {
        onCancel();
      }}
      title={<ModalActionTitle
        title={'setupNumber'}
        size={'medium'}
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'done',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              onCancel();
            },
          },
        ]}
      />}
    >
      {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
      {stateData.error ? (
        <Text size={'smMedium'} style={{color: Colors.error['500']}}>
          {stateData.error}
        </Text>
      ) : (
        <></>
      )}

      <View style={{padding: 24, paddingTop: 0}}>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <View
          flexDirection={'row'}
          alignItems={'center'}
          style={{marginBottom: 32}}
        >
          <View
            style={{
              //paddingHorizontal: 13,
              //marginRight: 11,
              //marginLeft: 11,
            }}
          >
            <PhoneRingSvg />
          </View>
          <View style={{marginLeft: 10}}>
            <Text
              size={'lgBold'}
              fontWeight={'600'}
              color={Colors.Custom.Gray900}
            >
              {numericStringMask(
                props.selectedData?.phoneNumber?.phoneNumber.slice(2),
                mask
              )}
            </Text>
            <Text size={'smMedium'} color={Colors.Custom.Gray500}>
              {props.selectedData?.phoneNumber?.name}
            </Text>
          </View>
        </View>
        {stateData?.defaultForLocationResp?.length ? (
            <View
              style={{
                width: 'max-content',
                paddingHorizontal: 8,
                paddingVertical: 3,
                borderRadius: 20,
                marginRight: 2,
                backgroundColor: Colors.Custom.mainPrimaryPurple,
                borderWidth: 1,
                borderColor: Colors.Custom.Gray100,
                marginBottom: 32,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Icon
                as={FontAwesome}
                color={Colors.Custom.Gray100}
                size={4}
                name="location-outline"
              />
              <Text size="smMedium" color={Colors.Custom.Gray100}>
                Default
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
        <View style={{padding: 8, paddingTop: 0, paddingBottom: 20, paddingLeft: 0}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{
                width: 35,
                height: 19,
                borderRadius: 4,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <div style={{
                  cursor: 'pointer'
                }} className={`call-extension-switch ${stateData.isAnnouncementEnabled ? 'checked' : ''}`} onClick={(event) => {
                  event.stopPropagation();
                  updateRecordingAnnouncementFlag(!stateData.isAnnouncementEnabled);
                }}>
                  <div className={`call-extension-switch-handle ${stateData.isAnnouncementEnabled ? 'checked' : ''}`} ></div>
                </div>
            </View>
            <View>
              <Text size={'smMedium'} color={'gray.500'} paddingLeft={'2'}>
                Toggle to Play or Skip Call Recording Announcement
              </Text>
            </View>
          </View>
        </View>
        <View>
          <NumberSetupView
            inboxes={props.inboxes}
            ivrFlowList={props.ivrFlowList}
            isEditMode={true}
            selectedData={props.selectedData}
            userList={props.userList}
            isFirstTimeSetup={props?.isFirstTimeSetup}
            formActionPerformed={(
              code: string,
              data?: any,
              selectedInbox?: any
            ) => {
              setLoading(data?.loading || false);
              if (
                code === COMMON_ACTION_CODES.COMPLETED ||
                code === COMMON_ACTION_CODES.FLOW_UPDATED ||
                code === COMMON_ACTION_CODES.ADD_IVR_FLOW
              ) {
                props.onFormCompleteAction(code);
              } else if (code === COMMON_ACTION_CODES.TOGGLE_SMS_INBOX ||
                code === COMMON_ACTION_CODES.CANCEL) {
                props.onFormCompleteAction(code, data, selectedInbox);
              }
            }}
            updateCallBack={(data) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  ...data,
                };
              });
            }}
          />
        </View>
      </View>
    </Drawer>
  );
};

export default EditAssigneeToNumber;
