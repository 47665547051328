import { ColumnsType } from 'antd/lib/table';
import { Text, View, Pressable, Image } from 'react-native';
import {useIntl} from 'react-intl';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {Colors} from '../../../styles';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import './style.css'
import {getTransferToTypeText} from './CallExtensionUtils';
import {CALL_CONFIGURATION_TRANSFER_TO_CODE} from './CallExtensionConst';
import {numericStringMask} from '../../../utils/commonUtils';
import {PHONE_NUMBER_MASK} from '../../../constants';
import ExtensionUserSvg from '../../common/Svg/TelephonySvg/ExtensionUserSvg';
import ExtensionAddSvg from '../../common/Svg/TelephonySvg/ExtensionAddSvg';
import {IExtensionList} from './interface';
import {Tooltip} from 'antd';
import ExtensionDeskPhoneSvg from '../../common/Svg/TelephonySvg/ExtensionDeskPhoneSvg';

export const getCallConfigurationColumns = (
  onCallConfigActionPerformed: (actionCode: string, record: IExtensionList) => void
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.FoldPixel.GRAY300,
            fontWeight: 500,
            fontSize: 14,
          }}
          textLocalId="extensionName"
        />
      ),
      dataIndex: 'extensionName',
      key: 'extensionName',
      fixed: 'left',
      width: '23%',
      render: (
        extensionName: string,
      ) => (
        <Text
          style={{
            fontSize: 14,
            fontWeight: '500',
            color: Colors.FoldPixel.GRAY300,
          }}
        >
          {extensionName}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.FoldPixel.GRAY300,
            fontWeight: 500,
            fontSize: 14,
          }}
          textLocalId="pressConfiguration"
        />
      ),
      dataIndex: 'extensionNumber',
      key: 'extensionNumber',
      width: '20%',
      render: (extensionNumber: string) => (
        <Text
          style={{
            fontSize: 14,
            fontWeight: '500',
            color: Colors.FoldPixel.GRAY300,
          }}
        >
          {extensionNumber}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.FoldPixel.GRAY300,
            fontWeight: 500,
            fontSize: 14,
          }}
          textLocalId="transferTo"
        />
      ),
      dataIndex: 'transferToType',
      key: 'transferToType',
      width: '23%',
      render: (transferToType: string, record: IExtensionList) => {
        const transferToTypeText = getTransferToTypeText(transferToType, record);
        return (
          <>
            <Text>
              <View style={{
                ...(transferToType !== CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER ? {backgroundColor : Colors.Custom.Gray100} : {}),
                  paddingVertical: 2,
                  paddingHorizontal : transferToType !== CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER  ? 6 : 4,
                  ...(transferToType !== CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER ? {borderWidth : 1} : {}),
                  ...(transferToType !== CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER ? {borderColor : Colors.Custom.Gray100} : {}),
                  borderRadius: 4,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.USER && <ExtensionUserSvg/>}
                {transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE && <ExtensionDeskPhoneSvg/>}
                <Text style={{
                  ...(transferToType !== CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER ? {marginLeft: 4} : {}),
                  fontSize: 14,
                  fontWeight: '500',
                  color: Colors.FoldPixel.GRAY300,
                }}>
                    {transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER ? numericStringMask(transferToTypeText.slice(2), PHONE_NUMBER_MASK) : transferToTypeText}
                </Text>
              </View>
              </Text>
          </>
        )
      }
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.FoldPixel.GRAY300,
            fontWeight: 500,
            fontSize: 14,
          }}
          textLocalId="statusCapital"
        />
      ),
      dataIndex: 'isActive',
      key: 'isActive',
      width: '12%',
      render: (isActive: boolean, record: IExtensionList) => {
        return (
          <View style={{
            flexDirection: 'row',
          }}>
            <View style={{
              width: 34,
              height: 19,
              borderRadius: 4,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <div style={{
                cursor: record?.isExistingDeskPhoneExtension ? 'not-allowed' : 'pointer'
              }} className={`call-extension-switch ${isActive ? 'checked' : ''}`} onClick={(event) => {
                event.stopPropagation();
                if (record?.isExistingDeskPhoneExtension) {
                  return;
                }
                onCallConfigActionPerformed(COMMON_ACTION_CODES.STATUS_CHANGED, record)
              }}>
                <div className={`call-extension-switch-handle ${isActive ? 'checked' : ''}`} ></div>
              </div>
            </View>
            <Text style={{
              marginLeft: 8,
              fontSize: 14,
              fontWeight: '500',
              color: Colors.FoldPixel.GRAY300,
            }}>
              {isActive ? 'Active' : 'Inactive'}
            </Text>
          </View>
        )
      }
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.FoldPixel.GRAY300,
            fontWeight: 500,
            fontSize: 14,
          }}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '10%',
      render: (record: IExtensionList) => {
        return (
          <View style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <Tooltip title={record?.isExistingDeskPhoneExtension ? '' : 'Edit'} placement={'top'}>
              <Pressable
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
                disabled={record?.isExistingDeskPhoneExtension}
                onPress={() => {
                  if (record?.isDisabled) {
                    return
                  }
                  onCallConfigActionPerformed(COMMON_ACTION_CODES.EDIT,record);
                }}
              >
                <Image
                  resizeMode="contain"
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require('../../../assets/images/editExtension.png')}
                />
              </Pressable>
            </Tooltip>
            <View
              style={{
                width: 0.5,
                height: 16,
                marginLeft: 14,
                backgroundColor: Colors.FoldPixel.GRAY150,
              }}
            />
            <Tooltip title={record?.isExistingDeskPhoneExtension ? '' : 'Delete'} placement={'top'}>
              <Pressable
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  marginLeft: 14,
                }}
                disabled={record?.isExistingDeskPhoneExtension}
                onPress={() => {
                  if (record?.isDisabled) {
                    return
                  }
                  onCallConfigActionPerformed(COMMON_ACTION_CODES.DELETE,record);
                }}
              >
                <Image
                  resizeMode="contain"
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require('../../../assets/images/trash.png')}
                />
              </Pressable>
            </Tooltip>
        </View>
        );
      },
    },
  ];
  return columns;
};