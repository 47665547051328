import { ApolloError, useLazyQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { VStack, View, useToast } from 'native-base';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { DISPLAY_DATE_FORMAT, MLOV_CATEGORY } from '../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { DOCUMENT_SUBJECT_TYPE_CODES, DOCUMENT_TYPE_CODES, MLOV_CODES, PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import {
  FlowType,
  IWorkflowContextData,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { CareJourneyQueries } from '../../../../../services';
import * as DocumentQueries from '../../../../../services/Document/DocumentQueries';
import { IAccountPracticeLocationResp } from '../../../../../services/Location/interfaces';
import { GetPackage } from '../../../../../services/Stripe/StripeServices';
import TeamQueries from '../../../../../services/Team/TeamQueries';
import { IPatientCareJourneyNodeLog } from '../../../../../services/WorkflowExecutionLog/WorkflowExecutionLogInterfaces';
import { commonBodyContentContainer } from '../../../../../styles/CommonBodyContentStyle';
import { getMomentObj } from '../../../../../utils/DateUtils';
import { accountLocationUuidListByUserPracticeLocationList, getAccountUUID, getEhrDisplayNameByCodes, getUserUUID } from '../../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import {
  getCareStudioMlovListByCategoryCode,
  getMlovCodeFromId,
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../../../utils/mlovUtils';
import { DetailViewForm } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/DetailViewForm';
import { JOURNEY_START_TYPES } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { IProductDetailForm } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {
  ICareJourneyTabStateData,
  IPackage,
  ITaxonData
} from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';
import { ISingleProductApiRespData } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/interface';
import { IUserInputField } from '../../../Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import { INPUT_FIELD_TYPE } from '../../../Workflow/FlowComponent/StateNodes/NodeInputField';
import { ILibNode } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/IWorkflow';
import { getNodeLevelExecutionStatusByWorkflowExecutionId, getWorkflowExecutionIdByWorkflowId, updateEmailNames, updatePushNotificationNames, updateSMSNames } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import PatientCareJourneyDetailView from '../../JourneysOfCare/PatientCareJourney/PatientCareJourneyDetailView';
import PatientCareJourneyTeam from '../../JourneysOfCare/PatientCareJourney/PatientCareJourneyTeam';
import {
  getUserInputDataFromUserInputFieldList,
  isTabDisabled
} from '../AddOrUpdateJourneyHelper';
import CareJourneyPreview from '../CareJourneyPreview/CareJourneyPreview';
import CareJourneyTeam, {
  ICcareTeamRoleSuggestions
} from '../CareJourneyTeam/CareJourneyTeam';
import { IJourneyDurationData } from '../DetailsAndMembers/Interfaces';
import JourneyDesign from '../JourneyDesign/JourneyDesign';
import { getDefaultNodesToBeRendered } from '../JourneyDesign/JourneyDesignHelper';
import { getNodeMetadata, getWorkFlowDataById } from '../JourneyMetadataService';
import { JOURNEY_TABS } from '../JourneyTabs';
import {formatPatientDocumentObj, getDocumentObj, getStartDateForCloneJourney} from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/ProductFormUtils';
import { getFormTemplateUuidList, updateFormNames } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowUtils';
import { GET_FORM_NAME_BY_IDS } from '../../../../../services/Forms/FormsQueries';
import CommonService from '../../../../../services/CommonService/CommonService';
import { getAccountLocationsWithEhrCode } from '../../../../../services/CommonService/IntegrationService';
import { ILocationObject } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowTriggerView/interfaces';

const AddOrUpdateJourneyTabs = (props: {
  handleTabsDataLoading?: (data: {[key: string]: boolean}) => void;
  isAssignWorkflow: boolean;
  selectedStep: string;
  onDetailChange: (
    details: {secondaryMasterId?: string} & IProductDetailForm,
    nodes: any[],
    edges: any[],
    isSaveButtonDisabled: boolean,
    noUserConfiguredForSomeRole?: boolean,
  ) => void;
  setErrors?: any;
  errors?: any;
  isTabOrBtnClicked?: boolean;
  isClone?: boolean;
  careJourneyId?: string
  setJourneyDetailsLoading?: (loading: boolean) => void
  }) => {
  const accountUuid = getAccountUUID();
  const toast = useToast();
  const intl = useIntl();
  const params = useParams();
  const location = useLocation();
  const patientCareJourneyId = location.state?.patientCareJourneyId as string;
  const contactUuid = location?.state?.personData?.contactUUID;
  const workflowMasterId = location.state?.workflowMasterId as string;
  const workflowId = location.state?.workflowId as string;
  const {errors, setErrors, isTabOrBtnClicked} = props;
  const mlovData = useContext(CommonDataContext);
  const careJourneyId = params?.careJourneyId || props?.careJourneyId;
  const documentMlovCategoryId = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.DOCUMENT_TYPE)[0]?.categoryId;
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const userPracticeLocations = accountLocationUuidListByUserPracticeLocationList(mlovData?.userPracticeLocations);
  const [getCareJourneyById] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEY_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getCareJourneyDocuments] = useLazyQuery(
    DocumentQueries.GET_CARE_JOURNEY_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  )

  const [getDocumentCategories] = useLazyQuery(
    CareJourneyQueries.GET_DOCUMENT_CATEGORIES_BY_CATEGORY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getAccountPracticeLocations] =
    useLazyQuery<IAccountPracticeLocationResp>(TeamQueries.GetLocations, {
      variables: {accountUuid: accountUuid},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    });

  const [getPatientCareJourneyById] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getAllCareJourney] = useLazyQuery(
    CareJourneyQueries.GET_ALL_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  // const [getNodeLevelExecutionStatusByWorkflowId] = useLazyQuery(
  //   GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_ID,
  //   {
  //     fetchPolicy: 'no-cache',
  //     context: {service: CARESTUDIO_APOLLO_CONTEXT},
  //   }
  // );

  const journeyDuration =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION
    ) || [];

  const monthsId = getMlovIdFromCode(journeyDuration, MLOV_CODES.MONTH);
  const documentSubjectTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.DOCUMENT_SUBJECT_TYPE
    ) || [];
  const careJourneyDocumentSubjectTypeId = getMlovIdFromCode(
    documentSubjectTypeMlovs,
    DOCUMENT_SUBJECT_TYPE_CODES.CARE_JOURNEY
  );
  const patientCareJourneyDocumentSubjectTypeId = getMlovIdFromCode(
    documentSubjectTypeMlovs,
    DOCUMENT_SUBJECT_TYPE_CODES.CONTACT
  );

  const defaultDuration: IJourneyDurationData = {
    value: 6,
    unit: monthsId,
    unitList: journeyDuration,
  };

  const [workflowContextData, setWorkflowContextData] =
    useState<IWorkflowContextData>({
      nodeMasterDataMap: {},
      nodeMetaData: undefined,
      flowType: FlowType.careJourney,
      setNodeMasterDataMap: (data) => {
        setWorkflowContextData((prev) => ({...prev, nodeMasterDataMap: data}));
      },
    });

  const [screenConfig, setScreenConfig] = useState<{
    libraryNodeList: ILibNode[];
    loading: boolean;
  }>({
    libraryNodeList: [],
    loading: true,
  });

  const [productDetails, setProductDetails] = useState<
    ISingleProductApiRespData | undefined
  >();

  const [workflowData, setWorkflowData] = useState<{
    nodes: any[];
    edges: any[];
  }>({nodes: [], edges: []});

  const [details, setDetails] = useState<
    {secondaryMasterId?: string} & IProductDetailForm
  >({
    name: '',
    description: '',
    memberOutcome: '',
    businessOutcome: '',
    triggerEvents: [],
    duration: defaultDuration,
    careTeam: [],
    prevCareTeam: [],
    shipping_category_id: '',
    available_on: '',
    price: '',
    cost_currency: 'USD',
    compare_at_price: '',
    sku: '',
    startDate: '',
    taxons: [] as ITaxonData[],
    careJourneyLocations: undefined,
    patientCareJourneyLocations: [],
    recommendedCareJourney: undefined,
    patientRecommendedCareJourney: [],
    careJourneyDocuments: [],
    startType: '',
    startTypeId: ''
  });

  const [careTeamRoleSuggestionsState, setCareTeamRoleSuggestionsState] =
    useState<ICcareTeamRoleSuggestions>({roles: {}});

  const [careTeamRequiredRoleState, setCareTeamRequiredRoleState] =
    useState<ICcareTeamRoleSuggestions>({roles: {}});

  const [patientCareJourneyStatusState, setPatientCareJourneyStatusState] =
    useState<string>();

  const [stateData,setStateData] = useState<ICareJourneyTabStateData>({
    accountLocation: [],
    accountLocationData: [],
    contactPracticeLocations: location?.state?.personData?.contactData?.contactPracticeLocations || [],
    contactStateId: location?.state?.personData?.stateId,
    patientCareJourneyId: patientCareJourneyId,
    careJourneys: [],
    careJourneysData: [],
    selectedPackages: []
  })

  const getSelectedTypeTaxon = () => {
    const tabType = params.type === 'journeys' ? 'care-journeys' : params.type;

    const selectedTabCategory = details.taxons?.find((item) => {
      return item.attributes?.permalink === tabType;
    });

    if (selectedTabCategory) {
      return selectedTabCategory;
    }
  };

  // const getTaxons = (completion: () => void) => {
  //   getTaxonsList({
  //     'filter[name_cont]': '',
  //   })
  //     .then((res: AxiosResponse<ITaxonsApiResp>) => {
  //       if (isValidTaxonResponse(res?.data)) {
  //         setDetails((prev) => {
  //           return {
  //             ...prev,
  //             taxons: res?.data.data,
  //           };
  //         });
  //       }
  //       completion();
  //     })
  //     .catch((err) => {
  //
  //       completion();
  //     });
  // };

  useEffect(() => {
    props.onDetailChange(
      details,
      workflowData.nodes,
      workflowData.edges,
      isTabDisabled(JOURNEY_TABS.PREVIEW, props.isAssignWorkflow, details)
    );
  }, [details, workflowData]);

  useEffect(() => {
    const apiSuccess = {
      patientCareJourney: (patientCareJourneyId ? false : true),
      careJourney: false,
      nodeMetaData: false,
      workflowData: false,
      // productData: false,
      // taxons: false,
    };

    getNodeMetaData(() => {
      apiSuccess.nodeMetaData = true;
      if (
        apiSuccess.patientCareJourney &&
        apiSuccess.careJourney &&
        apiSuccess.workflowData
        // &&
        // apiSuccess.productData &&
        // apiSuccess.taxons
      ) {
        setScreenConfig((prev) => ({...prev, loading: false}));
      }
    });

    if (careJourneyId) {
      // apiSuccess.taxons = true;

      if (patientCareJourneyId) {
        getPatientCareJourneyData(patientCareJourneyId, () => {
          apiSuccess.patientCareJourney = true;
          if (
            apiSuccess.patientCareJourney &&
            apiSuccess.careJourney &&
            apiSuccess.workflowData
            // &&
            // apiSuccess.productData &&
            // apiSuccess.taxons
          ) {
            setScreenConfig((prev) => ({...prev, loading: false}));
          }
        });
      }

      getCareJourneyData(careJourneyId, () => {
        apiSuccess.careJourney = true;
        if (
          apiSuccess.patientCareJourney &&
          apiSuccess.nodeMetaData &&
          apiSuccess.workflowData
          // &&
          // apiSuccess.productData &&
          // apiSuccess.taxons
        ) {
          setScreenConfig((prev) => ({...prev, loading: false}));
          props.setJourneyDetailsLoading && props.setJourneyDetailsLoading(false);
        }
      });

      getWorkflowById(workflowMasterId || careJourneyId, accountUuid, async () => {
        apiSuccess.workflowData = true;
        if (
          apiSuccess.patientCareJourney &&
          apiSuccess.nodeMetaData &&
          apiSuccess.careJourney
          // &&
          // apiSuccess.productData &&
          // apiSuccess.taxons
        ) {
          setScreenConfig((prev) => ({...prev, loading: false}));
        }
      });
      // apiSuccess.productData = true;
      // if (params.id && params.id !== '-1') {
        // getProductDetailsById(params.id, () => {
        //   apiSuccess.productData = true;
        //   if (
        //     apiSuccess.patientCareJourney &&
        //     apiSuccess.nodeMetaData &&
        //     apiSuccess.careJourney &&
        //     apiSuccess.workflowData &&
        //     apiSuccess.taxons
        //   ) {
        //     setScreenConfig((prev) => ({...prev, loading: false}));
        //   }
        // });
      // } else {
      //   apiSuccess.productData = true;
      // }
    } else {
      apiSuccess.careJourney = true;
      apiSuccess.workflowData = true;
      // apiSuccess.productData = true;
      if (
        apiSuccess.patientCareJourney &&
        apiSuccess.careJourney &&
        apiSuccess.nodeMetaData &&
        apiSuccess.workflowData
        // &&
        // apiSuccess.productData
      ) {
        setScreenConfig((prev) => ({...prev, loading: false}));
      }
      // getTaxons(() => {
      //   apiSuccess.taxons = true;
      //   if (
      //     apiSuccess.patientCareJourney &&
      //     apiSuccess.careJourney &&
      //     apiSuccess.nodeMetaData &&
      //     apiSuccess.workflowData &&
      //     apiSuccess.productData
      //   ) {
      //     setScreenConfig((prev) => ({...prev, loading: false}));
      //   }
      // });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      getAccountLocationsWithEhrCode()
        .then((accountLocations: any) => {
          const filteredLocations: any = []
          const practiceLocationsOptions: any[] = [];

          if (accountLocations?.length) {
            accountLocations.forEach((location: ILocationObject) => {
              if (location) {
                practiceLocationsOptions.push({
                  label: `${location?.practiceLocation?.name} ( ${getEhrDisplayNameByCodes(location?.ehrInfo?.ehrCode)} )`,
                  value: location?.uuid,
                  key: location?.uuid,
                  practiceLocation: `${location?.practiceLocation}`,
                  ehrCode: location?.ehrInfo?.ehrCode
                });
              }
            });
          }

          practiceLocationsOptions.forEach((location: any) => {
            if (userPracticeLocations?.includes(location?.value)) {
              filteredLocations.push(location)
            }
          })

          getAllCareJourney()
            .then((res) => {
              let careJourneyOptions: any[] = [];
              if (res?.data?.careJourneys?.length) {
                careJourneyOptions = res?.data?.careJourneys?.filter((journey: any) => journey?.id !== params?.careJourneyId)
              }
              setStateData((prev) => {
                return {
                  ...prev,
                  accountLocation: filteredLocations,
                  accountLocationData: filteredLocations,
                  careJourneys: careJourneyOptions,
                  careJourneysData: careJourneyOptions,
                }
              })
            })
        })
    }
    fetchData()
  }, [])

  // const getProductDetailsById = (productId: string, completion: () => void) => {
  //   getProductDetails(
  //     productId,
  //     (productResp: ISingleProductApiRespData) => {
  //       if (productResp) {
  //         setProductDetails(productResp);
  //         setDetails((prev) => ({
  //           ...prev,
  //           price: productResp?.data?.attributes?.price || prev.price,
  //           cost_currency: 'USD',
  //           compare_at_price: productResp?.data?.attributes?.compare_at_price || prev.compare_at_price,
  //           sku: '',
  //         }))
  //       } else {
  //         showErrorToast();
  //         setScreenConfig((prev) => ({...prev, loading: false}));
  //       }
  //       completion();
  //     },
  //     () => {
  //       showErrorToast();
  //       completion();
  //       setScreenConfig((prev) => ({...prev, loading: false}));
  //     }
  //   );
  // };

  const getNodeAssociatedRoleId = (userInputField: IUserInputField) => {
    if (userInputField?.key === 'role' || userInputField?.key === 'assignedToUserRoleOrTaskPool') {
      return userInputField?.value || undefined;
    } else if (userInputField?.fieldType === INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH) {
       const roleId = userInputField?.value?.roleId || undefined;
       return roleId ? {roleId, name: userInputField?.value?.roleName} : undefined
    }
  }

  const setCareTeamRequiredRole = (nodeId: string, userInputFieldList: IUserInputField[]) => {
    if (!nodeId || !userInputFieldList?.length) return;
    const roleUserInputField = userInputFieldList.find(
      (userInputField) => userInputField?.key === 'role' || userInputField?.key === 'assignedToUserRoleOrTaskPool' || userInputField?.fieldType === INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH
    );
    if (roleUserInputField && getNodeAssociatedRoleId(roleUserInputField)) {
      setCareTeamRequiredRoleState((prevState) => {
        if (!prevState.roles) prevState.roles = {};
        prevState.roles[nodeId] = getNodeAssociatedRoleId(roleUserInputField);
        return prevState;
      });
    } else {
      if (careTeamRequiredRoleState?.roles?.[nodeId]) {
        setCareTeamRequiredRoleState((prevState) => {
          if (prevState?.roles?.[nodeId]) delete prevState.roles[nodeId];
          return prevState;
        });
      }
    }
  }

  const setRequiredRoleNodesData = (nodes: any[]) => {
    setCareTeamRequiredRoleState({roles: {}});
    (nodes || []).forEach(node => {
      setCareTeamRequiredRole(node?.id, node?.data?.metaData?.userInputFieldList)
    });
  };

  const [getFormNames, { loading: formNamesLoading }] = useLazyQuery<{ forms: { id: string; name: string }[] }>(GET_FORM_NAME_BY_IDS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache'
  });

  const updateFormInformation = async (nodes: any[]) => {
    const uuids = getFormTemplateUuidList(nodes)
    const formResponse = await getFormNames({
      variables: { formIds: uuids }
    });
    const formIdToNameMap: { [key: string]: string } = {};
    formResponse?.data?.forms?.forEach((form: { id: string; name: string }) => {
      formIdToNameMap[form?.id] = form?.name;
    });

    const formUpdates = updateFormNames(nodes, formIdToNameMap)
    return formUpdates
  }

  const updateWorkflowDataInformation = async (workflowData: any) => {
    const formUpdate = updateFormInformation(workflowData?.nodes)
    const pushNotificationUpdate = updatePushNotificationNames(workflowData?.nodes)
    const smsUpdate = updateSMSNames(workflowData?.nodes)
    const emailUpdate = updateEmailNames(workflowData?.nodes)
    const update = await Promise.all([formUpdate, pushNotificationUpdate, smsUpdate, emailUpdate])
    return update
  }

  const getWorkflowById = (
    careJourneyId: string,
    accountUuId: string,
    completion: () => void
  ) => {
    const paramsCareJourneyId = params?.careJourneyId || props?.careJourneyId;
    getWorkFlowDataById(
      careJourneyId,
      accountUuId,
      async (response: any) => {
        let secondaryMasterId = response.secondaryMasterId;
        if (!secondaryMasterId && paramsCareJourneyId) {
          secondaryMasterId = paramsCareJourneyId;
        }
        setDetails((prev) => ({
          ...prev,
          secondaryMasterId: secondaryMasterId,
          triggerEvents: [response.triggerEvent],
        }));

        if (workflowId) {
          const workflowExecutionId = await getWorkflowExecutionIdByWorkflowId({
            workflowId: workflowId,
          });
          if (workflowExecutionId) {
            const nodeLogs = await getNodeLevelExecutionStatusByWorkflowExecutionId({
              workflowExecutionId: workflowExecutionId,
            });
            response.nodes = mergeWorkflowNodeExecutionData(response.nodes, nodeLogs);
          }
        }

        response?.nodes?.forEach((node: any)=> {
          node.data.isEdit = false
        })

        const updateWorkflowInfo = await updateWorkflowDataInformation(response)
        setWorkflowData({
          nodes: response.nodes,
          edges: response.edges,
        });
        setRequiredRoleNodesData(response.nodes);
        completion();
      },
      () => {
        showErrorToast();
        completion();
        setScreenConfig((prev) => ({...prev, loading: false}));
      },
      props?.isClone
    );
  };

  const getNodeMetaData = (completion: () => void) => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        setWorkflowContextData((prev) => ({
          ...prev,
          nodeMasterDataMap: response.nodeMasterData?.nodeMap,
          nodeMetaData: {
            frequencyList: response.nodeMetaData?.frequencyList || [],
            triggerUnits: response.nodeMetaData?.triggerUnits || [],
            durationUnits: response.nodeMetaData?.durationUnits || [],
            reminderUnits: response.nodeMetaData?.reminderUnits || [],
          },
        }));
        setScreenConfig({
          ...screenConfig,
          libraryNodeList: response.nodeMasterData?.libNodeList || [],
        });
        completion();
      },
      () => {
        showErrorToast();
        completion();
      }
    );
  };

  const getCarePlanDocumentTypeId = async ()=> {
    const documentCategories = await getDocumentCategories({
      variables: {
        params: {
          categoryId: documentMlovCategoryId
        }
      }
    })
    if (documentCategories?.data?.getDocumentCategories?.length) {
      const categories = documentCategories?.data?.getDocumentCategories;
      const carePlanDocumentCategory = categories.filter((category:any) => {
        return category?.code === DOCUMENT_TYPE_CODES?.CARE_PLAN
      })
      if (carePlanDocumentCategory?.length) {
        return carePlanDocumentCategory[0]?.id
      }
      return;
    }
    return;
  }

  const fetchCareJourneyDocuments = async (id: string)=> {
    const carePlanDocumentTypeId = await getCarePlanDocumentTypeId();
    const careJourneyDocuments = await getCareJourneyDocuments({
      variables: {
        params: {
          subjectId: id,
          documentTypeId: carePlanDocumentTypeId,
          limit: 100,
          offset: 0,
          searchString: "",
          orderBy: {
              caption: "desc"
          }
        }
      }
    })
    if (careJourneyDocuments?.data?.getDocuments?.documents?.length) {
      const documents = careJourneyDocuments?.data?.getDocuments?.documents || [];
      return documents;
    }
    return [];
  }

  const fetchPatientCareJourneyDocuments = async (patientCareJourneyId: string,contactUuid: string)=> {
    const carePlanDocumentTypeId = await getCarePlanDocumentTypeId();
    const careJourneyDocuments = await getCareJourneyDocuments({
      variables: {
        params: {
          subjectId: contactUuid,
          patientCareJourneyId: patientCareJourneyId,
          documentTypeId: carePlanDocumentTypeId,
          limit: 100,
          offset: 0,
          searchString: "",
          orderBy: {
              caption: "desc"
          }
        }
      }
    })
    if (careJourneyDocuments?.data?.getDocuments?.documents?.length) {
      const documents = careJourneyDocuments?.data?.getDocuments?.documents || [];
      return documents;
    }
    return [];
  }

  const getCareJourneyData = (
    careJourneyId: string,
    completion: () => void
  ) => {
    props.setJourneyDetailsLoading && props.setJourneyDetailsLoading(true);
    getCareJourneyById({
      variables: {
        id: careJourneyId,
      },
    })
      .then(async (response: any) => {
        if (response && response.data && response.data.careJourney) {
          const careJourneyDocuments = await fetchCareJourneyDocuments(careJourneyId) || [];
          const formateCareJourneyDocuments = params?.patientId ? formatPatientDocumentObj(careJourneyDocuments, contactUuid, patientCareJourneyDocumentSubjectTypeId) : getDocumentObj(careJourneyDocuments, careJourneyId, careJourneyDocumentSubjectTypeId);
          const journeyData = response.data.careJourney;
          const careJourneyLocations = journeyData?.careJourneyLocations || [];
          const locationIds = journeyData?.careJourneyLocations?.map((location: any)=> location.locationId) || []
          const careJourneyRecommendedJourneys = journeyData?.careJourneyRecommendedCareJourneys || [];
          const RecommendedJourneyIds = journeyData?.careJourneyRecommendedCareJourneys?.map((journey: any)=> journey.packageId) || []
          let prevCareTeam = journeyData.careJourneyTeam
          if (props?.isClone) {
            prevCareTeam = []
            journeyData.title = `Clone - ${journeyData.title}`
          }
          if(careJourneyRecommendedJourneys.length){
            await getSelectedPackage(careJourneyRecommendedJourneys[0].packageId)
          }
          setDetails((prev) => ({
            ...(prev || details),
            name: journeyData.title,
            description: journeyData.description,
            memberOutcome: journeyData.memberOutcome,
            businessOutcome: journeyData.businessOutcome,
            statusId: journeyData.statusId,
            ...(!patientCareJourneyId && {
              duration: {
                value: journeyData.duration || defaultDuration.value,
                unit: journeyData.durationUnitId || defaultDuration.unit,
                unitList: defaultDuration.unitList,
              },
              prevCareTeam: prevCareTeam,
              careTeam: journeyData.careJourneyTeam.map(
                (team: any) => team.roleId
              ),
              careJourneyTrackingResources: (props.isAssignWorkflow ? (journeyData?.careJourneyTrackingResources||[])?.map((careJourneyTrackingResource:any)=>{
                return {...careJourneyTrackingResource, id:undefined}
              }) : journeyData?.careJourneyTrackingResources)  || []
            }),
            careJourneyLocations:{previousLocations:careJourneyLocations,locations:locationIds},
            recommendedCareJourney:{previousRecommendedCareJourney:careJourneyRecommendedJourneys,recommendedCareJourney:RecommendedJourneyIds},
            careJourneyDocuments:formateCareJourneyDocuments,
            startType: journeyData?.startType?.code || '',
            startDateTime: props?.isClone ? getStartDateForCloneJourney(journeyData?.startDateTime) : journeyData?.startDateTime,
            startTypeId: journeyData?.startTypeId
          }));

          setCareTeamRoleSuggestionsState(journeyData.careJourneyTeam.map(
            (team: any) => team.roleId
          ),
          )
        } else {
          showErrorToast();
        }
        completion();
      })
      .catch((error: ApolloError) => {

        showErrorToast();
        completion();
      });
  };

  const getSelectedPackage = async (id: string) => {
    try{
      const packageResponse  = await GetPackage(id)
      if(packageResponse && packageResponse.data){
        setStateData((prev)=>({
          ...prev,
          selectedPackages: [packageResponse.data]
        }))
      }
    }
    catch(error){
      showErrorToast();
    }
  }

  const onPackageSelect = (selectedPackage?: IPackage) => {
    setStateData((prev)=>({
      ...prev,
      selectedPackages: !!selectedPackage ? [selectedPackage] : []
    }))
  }

  function getPatientCareJourneyData(patientCareJourneyId: string, completion: () => void) {
    getPatientCareJourneyById({
      variables: {
        id: patientCareJourneyId,
      },
    }).then(async (response: any) => {
      if (response && response.data && response.data.patientCareJourney) {
        const patientCareJourneyDocuments = await fetchPatientCareJourneyDocuments(patientCareJourneyId || '' ,contactUuid) || [];
        const formatePatientCareJourneyDocuments = formatPatientDocumentObj(patientCareJourneyDocuments, contactUuid, patientCareJourneyDocumentSubjectTypeId) || [];
        const patientCareJourneyData = response.data.patientCareJourney;
        const locationIds = patientCareJourneyData?.patientCareJourneyLocations?.map((location: any)=> location.locationId) || []
        const patientCareJourneyStatusId = patientCareJourneyData?.statusId;
        const RecommendedJourneyIds = patientCareJourneyData?.patientCareJourneyRecommendedCareJourney?.map((journey: any)=> journey?.recommendedCareJourneyId) || [];
        let patientCareJourneyStatusCode: string | undefined = undefined;
        if (patientCareJourneyStatusId) {
          const mlovs = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS) || [];
          const code = getMlovCodeFromId(mlovs, patientCareJourneyStatusId);
          if (code) {
            patientCareJourneyStatusCode = code;
          }
        }
        setPatientCareJourneyStatusState(patientCareJourneyStatusCode);

        const startDateObj = getMomentObj(patientCareJourneyData.startDateTime);
        let startDate: string;
        if (startDateObj.isValid()) {
          startDate = startDateObj.format(DISPLAY_DATE_FORMAT);
        }

        setDetails((prev) => ({
          ...(prev || details),
          duration: {
            value: patientCareJourneyData.duration,
            unit: patientCareJourneyData.durationUnitId,
            unitList: defaultDuration.unitList,
          },
          startDate: startDate || undefined,
          prevCareTeam: patientCareJourneyData.patientCareJourneyTeam,
          careTeam: patientCareJourneyData.patientCareJourneyTeam?.map?.(
            (team: any) => team.roleId
          ),
          careTeamUsers: patientCareJourneyData.patientCareJourneyTeam?.map?.((user: any) => {
            return {
              ...user,
              uuid: user.userId,
            }
          }),
          careJourneyTrackingResources: patientCareJourneyData?.careJourneyTrackingResources || [],
          patientCareJourneyLocations:locationIds,
          patientRecommendedCareJourney:RecommendedJourneyIds,
          patientCareJourneyDocuments:formatePatientCareJourneyDocuments
        }));
      } else {
        showErrorToast();
      }
      completion();
    })
    .catch((error: ApolloError) => {

      showErrorToast();
      completion();
    });
  }

  function mergeWorkflowNodeExecutionData(nodes: any[], nodeLogs: IPatientCareJourneyNodeLog[]) {
    if (!nodeLogs?.length || !nodes?.length) {
      return nodes;
    }

    nodeLogs.forEach((nodeLog) => {
      if (nodeLog.nodeId && nodeLog.status === 'COMPLETE') {
        nodes.some((node) => {
          if (node.id === nodeLog.nodeId) {
            if (node.data?.metaData) {
              node.data.metaData.isNodeExecutionCompletedForCareJourney = true;
            }
          }
        });
      }
    });

    return nodes;
  }

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const handleDetailFormChange = useCallback(
    (data) => {
      const updatedNodes = getDefaultNodesToBeRendered(
        workflowData.nodes || [],
        workflowData.edges || [],
        data.productDetailForm?.triggerEvents || [],
        workflowContextData.nodeMasterDataMap
      );
      setDetails((prev) => ({
        ...prev,
        ...data.productDetailForm,
      }));
      setWorkflowData({
        nodes: updatedNodes.nodes,
        edges: updatedNodes.edges,
      });
    },
    [
      workflowContextData.nodeMasterDataMap,
      workflowData.edges,
      workflowData.nodes,
    ]
  );

  const handleJourneyDetailsChange = useCallback((journeyDetails) => {
    setDetails((prev) => {
      return {
        ...prev,
        duration: journeyDetails?.duration,
        startDate: journeyDetails?.startDate,
        careJourneyTrackingResources: journeyDetails?.careJourneyTrackingResources,
        patientCareJourneyLocations: journeyDetails?.patientCareJourneyLocations || [],
        patientRecommendedCareJourney: journeyDetails?.patientRecommendedCareJourney || [],
        showLocationAlert: journeyDetails?.showLocationAlert || false
      };
    });

    props.onDetailChange(
      details,
      workflowData.nodes,
      workflowData.edges,
      isTabDisabled(JOURNEY_TABS.PREVIEW, props.isAssignWorkflow, details)
    );
  }, []);

  const handleAssignFlowCareTeamChange = (
    careTeamUser: any[],
    careTeam: any[],
    noUserConfiguredForSomeRole?: boolean,
  ) => {
    setDetails((prev) => {
      return {
        ...prev,
        careTeamUsers: careTeamUser,
        careTeam: careTeam,
      };
    });

    props.onDetailChange(
      details,
      workflowData.nodes,
      workflowData.edges,
      isTabDisabled(JOURNEY_TABS.PREVIEW, props.isAssignWorkflow, details),
      noUserConfiguredForSomeRole,
    );
  };

  const handleNodesChange = useCallback((nodes) => {
    if (workflowData.nodes?.length !== nodes?.length) {
      setRequiredRoleNodesData(nodes);
    }
    setWorkflowData((prev) => ({...prev, nodes}));
  }, []);

  const handleEdgesChange = useCallback((edges) => {
    setWorkflowData((prev) => ({...prev, edges}));
  }, []);

  function setCareTeamRoleSuggestions(
    nodeId: string,
    userInputFieldList: IUserInputField[]
  ) {
    if (!nodeId || !userInputFieldList?.length) return;
    const roleUserInputField = userInputFieldList.find(
      (userInputField) => userInputField?.key === 'role' || userInputField?.fieldType === INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH
    );
    if (roleUserInputField && getNodeAssociatedRoleId(roleUserInputField)) {
      setCareTeamRoleSuggestionsState((prevState) => {
        if (!prevState.roles) prevState.roles = {};
        prevState.roles[nodeId] = getNodeAssociatedRoleId(roleUserInputField);
        return prevState;
      });
    } else {
      if (careTeamRoleSuggestionsState?.roles?.[nodeId]) {
        setCareTeamRoleSuggestionsState((prevState) => {
          if (prevState?.roles?.[nodeId]) delete prevState.roles[nodeId];
          return prevState;
        });
      }
    }
  }

  const onUpdateData = useCallback(
    (locationIds: string[]) => {
      setWorkflowContextData((prev) => {
        return {
          ...prev,
          locationIds: locationIds,
        };
      });
    },
    [workflowContextData?.locationIds]
  );

  const handleNodeDataChange = useCallback((nodeId, config) => {
    setCareTeamRoleSuggestions(nodeId, config);
    setCareTeamRequiredRole(nodeId, config);
    setDetails((prev) => {
      let updatedNodes: any[] = prev.updatedNodes || [];
      const dataToBeUpdated = {
        nodeId: nodeId,
        userInputFieldList: getUserInputDataFromUserInputFieldList(config),
      };
      if (updatedNodes.length > 0) {
        const filteredNodes = updatedNodes.filter(
          (node) => node.nodeId !== nodeId
        );
        filteredNodes.push(dataToBeUpdated);
        updatedNodes = filteredNodes;
      } else {
        updatedNodes = [dataToBeUpdated];
      }
      return {...prev, updatedNodes: updatedNodes};
    });
  }, []);

  function onCareTeamUpdate(careTeam: any) {
    if (props.isAssignWorkflow) {
      setDetails((prev) => ({
        ...prev,
        careTeamUsers: careTeam,
      }));
    } else {
      setDetails((prev) => ({...prev, careTeam: careTeam}));
    }
  }

  function resetCareTeamRoleSuggestions() {
    if (Object.values(careTeamRoleSuggestionsState?.roles || {}).length === 0)
      return;
    setCareTeamRoleSuggestionsState(() => {
      return {roles: {}};
    });
  }

  return (
    <View style={[commonBodyContentContainer.pageContentContainer]}>
      {screenConfig.loading && (
        <View width="full">
          <Skeleton active />
        </View>
      )}
      {!screenConfig.loading && (
        <VStack>
          <Content className="common-scroll" style={{overflow: 'scroll'}}>
            {props.selectedStep === JOURNEY_TABS.DETAIL_AND_MEMBERS &&
              !props.isAssignWorkflow && (
                <WorkflowContext.Provider value={workflowContextData}>
                <DetailViewForm
                  handleTabsDataLoading={props?.handleTabsDataLoading}
                  isReadOnly={props.isAssignWorkflow}
                  productData={productDetails}
                  journeyData={{
                    isCareJourney: true,
                    isAssignWorkflow: props.isAssignWorkflow,
                    careJourneyId: careJourneyId
                  }}
                  onPackageSelect={onPackageSelect}
                  selectedPackages={stateData.selectedPackages}
                  isClone={props?.isClone}
                  productDetailForm={details}
                  onChange={handleDetailFormChange}
                  selectedTaxon={getSelectedTypeTaxon()}
                  customErrors={errors}
                  setCustomErrors={setErrors}
                  isTabOrBtnClicked={isTabOrBtnClicked}
                  accountLocation={stateData?.accountLocation}
                  stateData={stateData}
                  onUpdateData={onUpdateData}
                />
                </WorkflowContext.Provider>
              )}
            {props.selectedStep === JOURNEY_TABS.DETAIL_AND_MEMBERS &&
              props.isAssignWorkflow && (
                <PatientCareJourneyDetailView
                  productDetail={productDetails}
                  journeyDetail={details}
                  selectedPackages={stateData.selectedPackages}
                  careJourneyId={params?.careJourneyId || props?.careJourneyId || ''}
                  disableStartDateAndDurationInput={(!!patientCareJourneyStatusState && patientCareJourneyStatusState !== PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED) || details.startType === JOURNEY_START_TYPES.FIXED}
                  onChange={handleJourneyDetailsChange}
                  accountLocation={stateData?.accountLocation}
                  stateData={stateData}

                />
              )}
            {props.selectedStep === JOURNEY_TABS.JOURNEY_DESIGN &&
              props.isAssignWorkflow && (
                <WorkflowContext.Provider value={workflowContextData}>
                  <JourneyDesign
                    setWorkflowContext={setWorkflowContextData}
                    journeyDetails={details}
                    isAssignWorkflow={props.isAssignWorkflow}
                    nodes={workflowData.nodes || []}
                    edges={workflowData.edges || []}
                    libNodeIdList={screenConfig.libraryNodeList}
                    onNodeDataChange={handleNodeDataChange}
                    onNodesChange={handleNodesChange}
                    onEdgesChange={handleEdgesChange}
                  />
                </WorkflowContext.Provider>
              )}
            {props.selectedStep === JOURNEY_TABS.JOURNEY_DESIGN &&
              !props.isAssignWorkflow && (
                <WorkflowContext.Provider value={workflowContextData}>
                  <JourneyDesign
                    setWorkflowContext={setWorkflowContextData}
                    journeyDetails={details}
                    isAssignWorkflow={props.isAssignWorkflow}
                    nodes={
                      workflowData?.nodes?.map((node) => {
                        return {
                          ...node,
                          data: {
                            ...node.data,
                            isEdit: false,
                          },
                        };
                      }) || []
                    }
                    edges={workflowData.edges || []}
                    libNodeIdList={screenConfig.libraryNodeList}
                    onNodeDataChange={handleNodeDataChange}
                    onNodesChange={handleNodesChange}
                    onEdgesChange={handleEdgesChange}
                  />
                </WorkflowContext.Provider>
              )}
            {props.selectedStep === JOURNEY_TABS.CARE_TEAM &&
              !props.isAssignWorkflow && (
                <CareJourneyTeam
                  isAssignWorkflow={props.isAssignWorkflow}
                  careTeam={details.careTeam || []}
                  careTeamUsers={details.careTeamUsers || []}
                  careTeamRoleSuggestions={careTeamRoleSuggestionsState}
                  resetCareTeamRoleSuggestions={resetCareTeamRoleSuggestions}
                  onChange={onCareTeamUpdate}
                  careTeamRequiredRoles={careTeamRequiredRoleState}
                />
              )}
            {props.selectedStep === JOURNEY_TABS.CARE_TEAM &&
              props.isAssignWorkflow && (
                <PatientCareJourneyTeam
                  careTeam={details.careTeam || []}
                  careTeamRoleSuggestions={careTeamRoleSuggestionsState}
                  careTeamUsers={details.careTeamUsers || []}
                  onChange={handleAssignFlowCareTeamChange}
                  startDate={details?.startDate}
                  careTeamRequiredRoles={careTeamRequiredRoleState}
                />
              )}
            {props.selectedStep === JOURNEY_TABS.PREVIEW && (
              <WorkflowContext.Provider value={workflowContextData}>
                <CareJourneyPreview
                  isAssignWorkflow={props.isAssignWorkflow}
                  journeyDetails={details}
                  nodes={workflowData.nodes || []}
                  edges={workflowData.edges || []}
                  nodeMasterDataMap={workflowContextData.nodeMasterDataMap}
                  nodeMetaData={workflowContextData.nodeMetaData}
                  careTeamRoleSuggestions={careTeamRoleSuggestionsState}
                  resetCareTeamRoleSuggestions={resetCareTeamRoleSuggestions}
                  onUpdateCareTeam={onCareTeamUpdate}
                />
              </WorkflowContext.Provider>
            )}
          </Content>
        </VStack>
      )}
    </View>
  );
};

export default AddOrUpdateJourneyTabs;
