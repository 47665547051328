import {Box, ScrollView, Spinner, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {getDocumentsReferenceWithPatientId} from '../../../../services/CommonService/AidBoxService';
import {Colors} from '../../../../styles';
import {IAvailableEhr, INotesFormattedDataProps, IPersonData} from '../interfaces';
import {OrderType} from '../Orders/OrdersAndReports/OrderConstants';
import Header from './components/Header';
import AllOrders from './components/OrderTabs/AllOrders';
import ErxOrders from './components/OrderTabs/ErxOrders';
import ImagingOrders from './components/OrderTabs/ImagingOrders';
import LabOrders from './components/OrderTabs/LabOrders';
import {IPatientNotesData} from './interfaces';
import {OrderTimeLineTabCode} from './OrderTimelineConstants';
import './OrderTimeLineStyles.scss';
import {getFormmattedNotes} from './utils';
import {
  getFormattedNotesData,
  getCurrentEHR,
  getFormattedNoteForCareTimeline,
} from '../PatientNotes/PatientNotesHelper';
import {IPatientNotesInitData} from '../PatientNotes/PatientNotes';
import {EHRName} from '../PatientNotes/interfaces';
import {useLazyQuery} from '@apollo/client';
import {GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS} from '../../../../services/User/UserQueries';
import { IOrderTimelineSelectedFilters } from './OrderTimelineFilter';
import { getUserUUID, isLoggedInUserHealthCoach, isPhysicianRole } from '../../../../utils/commonUtils';
import { CareTimeline } from '../CareTimeline/CareTimeline';
import AddOrUpdateOrders from '../Orders/OrdersAndReports/AddOrUpdateOrders/AddOrUpdateOrders';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { CommonDataContext } from '../../../../context/CommonDataContext';
export enum OrderTimeLineScreenContext {
  ORDER_TIMELINE = 'ORDER_TIMELINE',
  ORDER_NOTE = 'PATIENT_NOTES',
}

const OrdersTimelineView = (props: {
  personData: IPersonData;
  unFormattedContactData?: any;
}) => {
  const {personData, unFormattedContactData} = props;
  const accountLocationId = personData?.accountLocationUuid;
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    accountLocationId
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const patientId = personData?.patientId || personData?.patientUuid;
  const contextData = useContext(CommonDataContext);
  const currentUserId = getUserUUID();
  const currentEHR = getCurrentEHR(accountLocationId, '');
  const isElation = currentEHR === EHRName.ELATION;
  const isFold = currentEHR === EHRName.FOLD;
  const [userList, setUserList] = useState<
    {
      user: any;
      uuid: string;
      externalUserId: string;
    }[]
  >();
  const [notes, setNotes] = React.useState<{
    data: IPatientNotesData[];
    loading: boolean;
  }>({
    data: [],
    loading: true,
  });
  const [componentState, setComponentState] = React.useState({
    activeTab: OrderTimeLineTabCode.ALL,
    orderType: '' as any,
    showAddNewOrderModal: false,
    isExternalOrder: false,
    refetch: false,
    screenContext: OrderTimeLineScreenContext.ORDER_TIMELINE,
    selectedNote: {} as IPatientNotesInitData,
    searchQuery: '',
    filter: {} as IOrderTimelineSelectedFilters | undefined,
    selectedNoteData: {} as any,
  });
  const [getUserList, {loading: userListLoading}] = useLazyQuery(
    GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        data?.accountUsers.length && setUserList(data?.accountUsers);
      },
    }
  );
  const fetchNotes = async () => {
    await getDocumentsReferenceWithPatientId(patientId || '', undefined, undefined, accountLocationId)
      .then((res) => {
        const notesData = getFormmattedNotes(res);
        const formattedData = getFormattedNotesData(
          res.data.entry,
          {
            id: '',
            name: '',
          },
          currentEHR
        );
        setNotes({data: notesData, loading: false});
        getUserList({
          variables: {
            userIds: isFold ? formattedData.userIds : [],
            externalUserIds: isElation ? formattedData.userIds : [],
          },
        });
      })
      .catch((err) => {

      });
  };

  const getUserName = (id: string) => {
    return isFold
      ? userList?.find((item) => item?.user?.uuid === id)?.user?.name || ''
      : userList?.find(
          (item) =>
            item?.externalUserId === id || item?.user?.externalUserId === id
        )?.user?.name || '';
  };

  useEffect(() => {
    fetchNotes();
  }, [componentState.refetch]);

  const getMainView = (activeTab: string, notesData: IPatientNotesData[]) => {
    switch (activeTab) {
      case OrderTimeLineTabCode.ALL:
        return (
          <AllOrders
            unformmatedContactDetails={props.unFormattedContactData}
            searchQuery={componentState.searchQuery}
            notesData={notesData}
            personData={personData}
            activeFilter={componentState.filter}
            handleNoteSelect={handleNoteSelect}

          />
        );
      case OrderTimeLineTabCode.ERX:
        return (
          <ErxOrders
            unformmatedContactDetails={props.unFormattedContactData}
            searchQuery={componentState.searchQuery}
            notesData={notesData}
            personData={personData}
            activeFilter={componentState.filter}
            handleNoteSelect={handleNoteSelect}
          />
        );
      case OrderTimeLineTabCode.LAB:
        return (
          <LabOrders
            unformmatedContactDetails={props.unFormattedContactData}
            activeFilter={componentState.filter}
            searchQuery={componentState.searchQuery}
            notesData={notesData}
            personData={personData}
            handleNoteSelect={handleNoteSelect}
          />
        );
      case OrderTimeLineTabCode.IMAGING:
        return (
          <ImagingOrders
            unformmatedContactDetails={props.unFormattedContactData}
            activeFilter={componentState.filter}
            searchQuery={componentState.searchQuery}
            notesData={notesData}
            personData={personData}
            handleNoteSelect={handleNoteSelect}
          />
        );
      default:
        return <></>;
    }
  };

  const getOrderTypeFromTab = (tab: string) => {
    switch (tab) {
      case OrderTimeLineTabCode.ALL:
        return '';
      case OrderTimeLineTabCode.ERX:
        return OrderType.MED;
      case OrderTimeLineTabCode.LAB:
        return OrderType.LAB;
      case OrderTimeLineTabCode.IMAGING:
        return OrderType.RAD;
      default:
        return '';
    }
  };

  const handleNoteSelect = (note: any) => {
    try {
      const formatResponseParam: INotesFormattedDataProps = {
        noteResponse: [ {
          resource: note
        }],
        ehrConfig: { isFold: true } as IAvailableEhr,
        loginUserId: currentUserId,
        contextData: contextData,
        accountUserList: userList || [],
        elationFormData: {},
        additionalFlags:{
          foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled
        }
      };
      const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
      const noteData = patientNotes?.length ? patientNotes[0] : undefined;
      if (noteData && noteData.status) {
        setComponentState((prev) => ({
          ...prev,
          selectedNoteData: noteData,
          screenContext: OrderTimeLineScreenContext.ORDER_NOTE,
        }));
      }
    } catch (error) {
    }
  };

  return (
    <Box
      w="100%"
      flex={1}
      background="white"
      style={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}
      borderColor={Colors.Custom.Gray200}
      borderWidth={1}
      mt={1}
    >
      {componentState.screenContext ===
      OrderTimeLineScreenContext.ORDER_NOTE ? (
        <>
          <CareTimeline
            personData={personData}
            unFormattedContactData={unFormattedContactData}
            orderTimelineNoteData={componentState.selectedNoteData}
            goBackHandler={() =>{
              setComponentState((prev) => ({
                ...prev,
                selectedNote: {} as IPatientNotesInitData,
                screenContext: OrderTimeLineScreenContext.ORDER_TIMELINE,
              }));
              }
            }
          />
        </>
      ) : (
        <>
          <Header
            activeTab={componentState.activeTab}
            activeFilter={componentState.filter}
            searchQuery={componentState.searchQuery}
            locationId={accountLocationId}
            onNewOrder={(code, isExternalOrder) =>
              setComponentState((prev) => ({
                ...prev,
                orderType: getOrderTypeFromTab(code),
                showAddNewOrderModal: true,
                isExternalOrder: isExternalOrder,
              }))
            }
            onTabChange={(tab: string) =>
              setComponentState((prev) => ({
                ...prev,
                activeTab: tab,
              }))
            }
            onFilterChange={(filter: IOrderTimelineSelectedFilters | undefined) =>
              setComponentState((prev) => ({
                ...prev,
                filter: filter,
              }))
            }
            onSearchQueryChange={(text) =>
              setComponentState((prev) => ({...prev, searchQuery: text}))
            }
          />
          {notes.loading ? (
            <VStack h={500} justifyContent="center">
              <Spinner size="lg" />
            </VStack>
          ) : (
            <ScrollView px={5}>
              {getMainView(componentState.activeTab, notes.data)}
            </ScrollView>
          )}

          {componentState.showAddNewOrderModal && (
            <AddOrUpdateOrders
              isVisible={componentState.showAddNewOrderModal}
              isExternalOrder={componentState.isExternalOrder}
              orderType={componentState.orderType}
              patientContactId={props.personData?.id || ''}
              unFormattedContactData={props.unFormattedContactData}
              personData={props.personData}
              onClose={() => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    showAddNewOrderModal: false,
                    isExternalOrder: false,
                    orderType: '' as any,
                  };
                });
                fetchNotes();
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default OrdersTimelineView;
