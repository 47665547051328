import {useLazyQuery, useMutation} from '@apollo/client';
import {HStack, Icon, VStack} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {View} from 'native-base';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {TELEPHONY_VIEW} from '../../../../constants/TelephonyConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CloudTelephonyQueries, UserQueries} from '../../../../services';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {getAccountId, getAccountUUID, getUserUUID} from '../../../../utils/commonUtils';
import ActiveNumbersTable from '../ActiveNumbersTable/ActiveNumbersTable';
import DeleteReleaseNumber from '../ActiveNumbersTable/DeleteReleaseNumber';
import EditAssigneeToNumber from '../ActiveNumbersTable/EditAssigneeToNumber';
import {
  getFormattedIvrData,
  getVirtualNumberFormattedList,
} from '../CloudTelephonyUtils';
import {IFLOWS} from '../interfaces';
import AddUpdateIvrFlow from '../IVRFlows/AddUpdateIvrFlow';
import {useNavigate} from 'react-router-dom';
import {Dimensions} from 'react-native';
import {RIGHT_SIDE_CONTAINER_CODE} from '../../../../constants';
import {IIvrList} from './interface';
import PurchaseNumberView from '../PurchaseNumberView/PurchaseNumberView';
import SmsInboxDrawer from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxDrawer';
import {SMS_INBOX_ACTION_CODES} from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {deleteInboxSMSUrl} from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsService';
import {formatIntegrationsData} from '../../TeamInbox/Integrations/Helper/formatIntegrationsData';
import {ILoginUserData} from '../../../../Interfaces';
import {CHANNEL_TYPE} from '../../TeamInbox/Conversations/ConversationConst';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import SelectPatientCallDrawer from '../ActiveNumbersTable/SelectPatientCallDrawer';
import { EventBus } from '../../../../utils/EventBus';
import { IContact } from '../../TeamInbox/Conversations/interfaces';
import {IExtensionData} from '../../CallConfiguration/interface';

const CloudTelephonyView = (props: {
  searchString?: string;
  openModal?: boolean;
  showTableTopBar?: boolean;
  selectedCode?: string;
  onActionPerformed: (actionCode: string, data?: any) => void;
  selectedData?: any;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  const accountUuid = getAccountUUID();
  const accountId = getAccountId()
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const assigneesTypeList = mlovData['VirtualNumberAssignee'] || [];
  const navigate = useNavigate();
  const {height} = Dimensions.get('window');
  const [openModal, setOpenModal] = useState(false);

  const [stateData, setStateData] = useState({
    selectedView: '',
    userList: [] as any,
    event: '',
    selectedData: {} as any,
    selectedInboxData: {} as any,
    loading: false,
    ivrFlowList: [] as IIvrList[],
    inboxes: [] as any[]
  });
  const [deleteInbox] = useMutation(InboxQueries.DeleteInbox);
  const [updateChannelTwilioSms] = useMutation(InboxQueries.updateChannelTwilioSms);

  const [GET_USERS_IVR] = useLazyQuery(UserQueries.Get_Users_IVR, {
    fetchPolicy: 'no-cache',
  });
  const [GET_SINGLE_VIRTUAL_PHONE_NUMBER] = useLazyQuery(
    CloudTelephonyQueries.GET_SINGLE_VIRTUAL_PHONE_NUMBER
  );

  const [getInboxes] = useLazyQuery(InboxQueries.GetInboxesData, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
    variables: {
      whereCondition: {
        accountId: {
          _eq: userData.account_id
        },
        channelType: {
          _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
        },
        isDeleted: {
          _eq: false
        }
      }
    }
  });

  const getIvrFlowList = async () => {
    setStateData(prev=> {
      return{
        ...prev,
        loading: true
      }
    })
    const res = await axios.get(
      `/cloud-telephony/flow?accountUUID=${accountUuid}`
    );
    if (res?.data?.length) {
      const response: IFLOWS[] = res?.data?.length ? res?.data : [];
      const formattedIvrData = getFormattedIvrData(response);
      setStateData((prev) => {
        return {
          ...prev,
          ivrFlowList: formattedIvrData,
          loading: false
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getUsersList = async () => {
    const responseData = await GET_USERS_IVR({
      variables: {
        accountUUID: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
    if (responseData?.data?.users?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          userList: responseData?.data?.users,
        };
      });
    } else {
    }
  };
  const onDeleteInboxAction = (singleInboxData: any) => {
    deleteInbox({
      variables: {
        id: singleInboxData?.key,
      },
    }).then((res) => {
      updateChannelTwilioSms({
        variables: {
          id: singleInboxData?.channel_id,
        },
      }).then((res) => {
        deleteInboxSMSUrl({
          bodyParams: {
            phoneNumber:
              singleInboxData.phoneNumber,
            smsUrl: '',
          },
        }).then((resp) => {
        });

        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            event: 'REFETCH_INBOXES',
            selectedView: '',
            selectedInbox: {} as any
          };
        });

        setTimeout(() => {
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
              event: '',
            };
          });
        }, 1000)
      });
    });
  };

  const onActionPerformed = async (code: string, data?: any, selectedInbox?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.TOGGLE_SMS_INBOX:
        if (!selectedInbox?.isDeleted) {
          onDeleteInboxAction(selectedInbox);
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              selectedView: RIGHT_SIDE_CONTAINER_CODE.SMS_INBOX_VIEW,
              selectedData: {
                data,
                selectedInbox
              },
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.CANCEL:
        getInboxes().then((inboxResp) => {
          const inboxes = formatIntegrationsData(inboxResp?.data);
          setStateData((prev) => {
            return {
              ...prev,
              inboxes: inboxes
            };
          });
        })
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: {
              data,
              selectedInbox
            },
            selectedInboxData: {},
          };
        });

        props.onActionPerformed('', {
          selectedCode: '',
        });
        break;
      case COMMON_ACTION_CODES.ADD_IVR_FLOW:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: TELEPHONY_VIEW.ADD_NEW_IVR_FLOW,
            selectedData: data,
          };
        });
        break;
      case COMMON_ACTION_CODES.FLOW_UPDATED:
        if (!selectedInbox?.isDeleted) {
          onDeleteInboxAction(selectedInbox);
        }
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            event: '',
            selectedView: '',
            selectedData: {},
            selectedInboxData: {},
          };
        });
        setTimeout(() => {
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
              event: 'COMPLETED',
            };
          });
        }, 200);
        break;
      case COMMON_ACTION_CODES.DELETE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: RIGHT_SIDE_CONTAINER_CODE.CONTACT_DELETE_VIEW,
            selectedData: data,
            selectedInboxData: selectedInbox,
          };
        });
        break;
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
            selectedData: data,
          };
        });
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: {},
            selectedInbox: {} as any
          };
        });
        break;
      case COMMON_ACTION_CODES.EDIT_MODE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            event: 'COMPLETED',
            loading: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.DELETED:
        if (selectedInbox) {
          onDeleteInboxAction(selectedInbox);
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              selectedView: '',
              event: 'COMPLETED',
              loading: false,
              selectedInbox: {} as any
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.COMPLETED:
        setStateData((prev) => {
          return {
            ...prev,
            event: 'COMPLETED',
            selectedView: '',
            selectedData: {},
            selectedInbox: {} as any,
            loading: false,
          };
        });
        setTimeout(async () => {
          const singleData = await GET_SINGLE_VIRTUAL_PHONE_NUMBER({
            context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
            variables: {
              uuid: data?.id,
            },
          });
          const formatedData = getVirtualNumberFormattedList(
            singleData?.data?.virtualPhoneNumberAssignees || [],
            assigneesTypeList,
            stateData.userList
          );

          setStateData((prev) => {
            return {
              ...prev,
              selectedView:'',
              selectedData: formatedData[0],
              loading: false,
              selectedInbox: {} as any
            };
          });
        }, 200);
        break;
      case COMMON_ACTION_CODES.SETUP_NUMBER: {
        props.onActionPerformed(COMMON_ACTION_CODES.SETUP_NUMBER, {
          id: data.id,
          phoneNumber: {
            name: data.phoneNumber?.name,
            phoneNumber: data.phoneNumber.phoneNumber
          }
        })
      }
        break;
      case COMMON_ACTION_CODES.CALL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: RIGHT_SIDE_CONTAINER_CODE.CALL_WITH_PATIENT_DRAWER,
            selectedData: data,
          };
        });
        break;
      default:
        setStateData((prev) => {
          return {
            ...prev,
            event: 'COMPLETED',
            loading: false,
            selectedInbox: {} as any
          };
        });
    }
  };

  useEffect(() => {
    getIvrFlowList();
    getUsersList();
  }, []);
  useEffect(() => {
    getInboxes().then((inboxResp) => {
      const inboxes = formatIntegrationsData(inboxResp?.data);
      setStateData((prev) => {
        return {
          ...prev,
          inboxes: inboxes
        };
      });
    })
  }, [stateData.event])
  useEffect(() => {
    if (props.openModal) {
      setOpenModal(props.openModal);
    }
  }, [props.openModal]);

  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string,
    extensionData: IExtensionData,
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(COMMON_ACTION_CODES.CLOUD_CALL, {
        contactData: contactData,
        fromNumber: fromNumber,
        extensionData: extensionData
      });
    }
  };

  return (
    <>
      <View>
        <View
          style={{
            overflow: 'hidden',
            backgroundColor: '#fff',
          }}
        >
          <ActiveNumbersTable
            loading={stateData.loading}
            ivrFlowList={stateData.ivrFlowList}
            userList={stateData.userList}
            event={stateData.event}
            searchString={props?.searchString}
            onActionPerformed={onActionPerformed}
          />
        </View>
      </View>
      {stateData.selectedView == RIGHT_SIDE_CONTAINER_CODE.SMS_INBOX_VIEW && (<SmsInboxDrawer
        isDrawerVisible={true}
        allUserList={stateData.userList || []}
        selectedInboxData={stateData?.selectedData?.selectedInbox as any}
        selectedPhoneNumber={stateData?.selectedData?.data?.phoneNumber?.phoneNumber}
        onSmsInboxActionPerformed={(actionCode: string) => {
          if (actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE) {
            onActionPerformed(COMMON_ACTION_CODES.CANCEL)
          }
          if (actionCode == SMS_INBOX_ACTION_CODES.ON_INBOX_SAVE) {
            setStateData((prev) => {
              return {
                ...prev,
                loading: false,
                event: 'REFETCH_INBOXES',
                selectedView: '',
                selectedData: {},
              };
            });

            setTimeout(() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  loading: false,
                  event: '',
                  selectedView: '',
                  selectedData: {},
                };
              });
            }, 1000)
          }
          // onSmsInboxActionPerformed(actionCode);
        }}
      />)}
      {stateData.selectedView == TELEPHONY_VIEW.ADD_NEW_IVR_FLOW && (
        <AddUpdateIvrFlow
          purchasedNumbers={[]}
          onFormCompleteAction={(code: string) => {
            setTimeout(() => {
              onActionPerformed(code);
            }, 500);
          }}
        />
      )}
      {RIGHT_SIDE_CONTAINER_CODE.CONTACT_DELETE_VIEW ===
        stateData.selectedView && (
          <DeleteReleaseNumber
            onFormCompleteAction={onActionPerformed}
            selectedData={stateData.selectedData}
            selectedInboxData={stateData.selectedInboxData}
          />
        )}
      {RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW ===
        stateData.selectedView && (
          <EditAssigneeToNumber
            userList={stateData.userList}
            onFormCompleteAction={onActionPerformed}
            selectedData={stateData.selectedData}
            ivrFlowList={stateData.ivrFlowList}
            inboxes={stateData.inboxes}
          />
        )}
      {props.selectedCode === TELEPHONY_VIEW.PURCHASE_NEW_NUMBER && (
        <PurchaseNumberView
          inboxes={stateData.inboxes}
          onFormCompleteAction={(actionCode: string, data: any) => {
            setTimeout(() => {
              onActionPerformed(actionCode, data);
            }, 500);
          }}
          ivrFlowList={stateData.ivrFlowList}
          userList={stateData.userList}
        />
      )}
      {props.selectedCode === TELEPHONY_VIEW.SETUP_NUMBER && (
        <EditAssigneeToNumber
          userList={stateData.userList}
          onFormCompleteAction={onActionPerformed}
          selectedData={props.selectedData}
          ivrFlowList={stateData.ivrFlowList}
          inboxes={stateData.inboxes}
          isFirstTimeSetup={true}
        />
      )}
      {stateData.selectedView ===
        RIGHT_SIDE_CONTAINER_CODE.CALL_WITH_PATIENT_DRAWER && (
          <SelectPatientCallDrawer
            isDrawerVisible={true}
            selectedRecord={stateData.selectedData}
            handleCloudCall={handleCloudCall}
            onCreateCallConversationActionPerformed={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedView: '',
                  selectedData: {},
                };
              });
            }}
          />
        )}
    </>
  );
};

export default CloudTelephonyView;
