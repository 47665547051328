import {useLazyQuery} from '@apollo/client';
import {Select} from 'antd';
import {Center, HStack, Image, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {MODULE_PAGINATION_COUNT} from '../../../../constants';
import {UserQueries} from '../../../../services';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {getAccountId, getUserUUID} from '../../../../utils/commonUtils';
import {styles} from '../Conversations/ConversationsStyles';
import CallLogDetails from './CallLogDetails';
import CallLogList from './CallLogList';
import {getCallLogsParams} from './CallLogsUtils';
import {ICallLogEntry, ISipNumberList} from './interface';
import {CONVERSATION_STORAGE_KEY} from '../Conversations/ConversationContainer/ConversationConst';
import {CHANNEL_TYPE_CODE} from '../Conversations/ConversationConst';
import LocalStorage from '../../../../utils/LocalStorage';
import ConversationFilter from '../Conversations/ConversationContainer/ConversationFilters/ConversationFilter';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import USFlagSVG from '../../../common/Svg/FlagIcon/USFlagSVG';
import { useIntl } from 'react-intl';
import NoCallLogsSvg from '../../../common/Svg/NoCallLogsSvg';
import { IUserInboxList } from '../Conversations/ConversationChannelTabs/interfaces';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import {getSipPhoneCredentialList} from '../../../../services/CloudTelephony/CloudTelephonyQueries';
const {Option} = Select;

const CallLogs = (props: any) => {
  const [callLogListState, setCallLogListState] = useState<{
    list: ICallLogEntry[];
    page: number;
    pageSize: number;
    isLoading: boolean;
    isLoadMoreLoading: boolean;
    conversationFilterQuery: any;
    conversationFilterProps: any;
    toNumber: any;
    userList: any;
    filterModal: boolean,
    showFetchMore: boolean,
    sipNumberList: ISipNumberList[],
    userAndSipNumberLoading: boolean,
  }>({
    list: [],
    page: 1,
    pageSize: MODULE_PAGINATION_COUNT.CALL_LOG_SIZE,
    isLoading: false,
    isLoadMoreLoading: false,
    conversationFilterQuery: {} as any,
    conversationFilterProps: {} as any,
    toNumber: undefined,
    userList: null,
    filterModal: false,
    showFetchMore: true,
    sipNumberList: [],
    userAndSipNumberLoading: false,
  });
  const intl = useIntl();
  const userUuid = getUserUUID();
  const [selectedCallLogIndex, setSelectedCallLogIndex] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [selectedCallLogDetails, setSelectedCallLogDetails] = useState<{
    list: ICallLogEntry[];
    page: number;
    pageSize: number;
    contactId?: any;
    selectedLog: any;
  }>({
    list: [],
    page: 1,
    pageSize: MODULE_PAGINATION_COUNT.CALL_LOG_SIZE,
    contactId: null,
    selectedLog: {},
  });
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );

  const [getAccountUsers] = useLazyQuery(UserQueries.GET_MANAGER_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      tenantId: getAccountId(),
    },
  });

  const {axios} = BaseService.getSharedInstance();

  const ifNumberExists = (number: string) => {
    const selectedNumber = props.userVirtualMobileNo?.some((item: any) => {
      if (item?.virtualPhoneNumber === number) {
        return true;
      }
    });
    return selectedNumber;
  }

  const setUserList = async () => {
    const userList = await getAccountUsers();
    setCallLogListState((prev) => {
      return {
        ...prev,
        userList: userList?.data.users,
      }
    });
  };

  useEffect(() => {
    if (props?.userVirtualMobileNo?.length > 0) {
      LocalStorage.getItem('callLogsFilterToNumber')
        .then((response) => {
          if (response?.[userUuid]) {
            if (response?.[userUuid]?.phoneNumber && ifNumberExists(response?.[userUuid]?.phoneNumber)) {
              const callLogFilters: any = props?.conversationFilterQuery;
              if (callLogFilters?.callLogFilters) {
                callLogFilters.callLogFilters.toNumber = response?.[userUuid]?.phoneNumber;
              }
              setCallLogListState((prev) => {
                return {
                  ...prev,
                  list: [],
                  page: 1,
                  isLoading: true,
                  toNumber: response?.[userUuid]?.phoneNumber || undefined,
                  conversationFilterQuery: {
                    //...callLogListState.conversationFilterQuery,
                    ...callLogFilters
                  },
                  conversationFilterProps: props?.conversationFilterProps,
                };
              });
            } else {
              setDefaultState();
            }
          } else {
            setDefaultState();
          }
        })
    } else {
      setDefaultState();
    }
  }, [props?.conversationFilterQuery, props?.userVirtualMobileNo, props?.conversationFilterProps,]);

  useEffect(() => {
    if (callLogListState.isLoading) {
      fetchCallLogs(1, true);
      setUserList();
    }
  }, [callLogListState.isLoading]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCallLogListState((prev) => {
        return {
          ...prev,
          list: [],
          page: 1,
          isLoading: true,
        };
      });
      //fetchCallLogs(1, true);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchString]);

  const setDefaultState = () => {
    const callLogFilters: any = props?.conversationFilterQuery;
    if (callLogListState.toNumber?.length) {
      callLogFilters.callLogFilters.toNumber = callLogListState.toNumber;
    }
    setCallLogListState((prev) => {
      return {
        ...prev,
        list: [],
        page: 1,
        isLoading: true,
        conversationFilterQuery: callLogFilters,
        conversationFilterProps: props?.conversationFilterProps,
      };
    });
  }

  const fetchCallLogs = (page: number, isFirstFetch?: boolean) => {
    setCallLogListState((prev) => {
      return {
        ...prev,
        isLoadMoreLoading: true,
      };
    });
    axios
      .get(`/crm-nest/communication/call/log?`, {
        params: getCallLogsParams(
          page,
          callLogListState.pageSize,
          callLogListState?.conversationFilterQuery?._and,
          callLogListState?.conversationFilterQuery?.callLogFilters,
          searchString,
          undefined,
          props?.userVirtualMobileNo,
          isCommunicationLocationHandlingEnabled
        ),
      })
      .then((result) => {

        if (result?.data) {
          setCallLogListState((prev) => {
            return {
              ...prev,
              list: page === 1 ? [...result?.data] : [...callLogListState?.list, ...result?.data],
              page,
              isLoading: false,
              isLoadMoreLoading: false,
              showFetchMore: result?.data?.length >= MODULE_PAGINATION_COUNT.CALL_LOG_SIZE
            };
          });
          if (isFirstFetch) {
            fetchContactCallLogs(selectedCallLogDetails?.page, result?.data);
          }
        } else {
          setCallLogListState((prev) => {
            return {
              ...prev,
              isLoading: false,
              isLoadMoreLoading: false,
            };
          });
        }
      })
      .catch((error) => {

        setCallLogListState((prev) => {
          return {
            ...prev,
            isLoading: false,
            isLoadMoreLoading: false,
          };
        });
      });
  };

  const fetchContactCallLogs = (page: number, list?: ICallLogEntry[]) => {
    const selectedCallLog =
      list?.[selectedCallLogIndex] ??
      callLogListState?.list?.[selectedCallLogIndex];

    if (selectedCallLog?.id) {
      const contactId =
        list?.[selectedCallLogIndex]?.contactUuid ??
        callLogListState.list?.[selectedCallLogIndex]?.contactUuid;
      const params = getCallLogsParams(
        page,
        callLogListState.pageSize,
        callLogListState?.conversationFilterQuery?._and,
        callLogListState?.conversationFilterQuery?.callLogFilters,
        searchString,
      );
      if (contactId) {
        axios
          .get(
            `/crm-nest/communication/call/log?page=${page}&perPage=${selectedCallLogDetails?.pageSize}&contactUuid=${contactId}`,
            {
              params: {
                locationNumber: params?.locationNumber
              }
            }
          )
          .then((result) => {
            if (result?.data) {
              if (page === 1) {
                setSelectedCallLogDetails({
                  ...selectedCallLogDetails,
                  list: [...result?.data],
                  page,
                  contactId,
                });
              } else {
                setSelectedCallLogDetails({
                  ...selectedCallLogDetails,
                  list: [...result?.data],
                  page,
                  contactId,
                });
              }
            }
          })
          .catch((error) => {

          });
      } else if (callLogListState.list?.[selectedCallLogIndex]) {
        setSelectedCallLogDetails({
          ...selectedCallLogDetails,
          list: [callLogListState.list?.[selectedCallLogIndex]],
          page,
          contactId,
        });
      } else {
        setSelectedCallLogDetails({
          ...selectedCallLogDetails,
          list: [selectedCallLog],
          page,
          contactId: selectedCallLog?.contact?.contactId,
        });
      }
    } else if (selectedCallLog?.contact?.contactUuid) {
      setSelectedCallLogDetails({
        ...selectedCallLogDetails,
        list: [selectedCallLog],
        page,
        contactId: selectedCallLog?.contact?.contactId,
      });
    }
  };

  useEffect(() => {
    fetchContactCallLogs(1, callLogListState?.list);
  }, [selectedCallLogIndex]);


  const onModalAction = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.FILTER_CHANGED:
        if (
          actionData.conversationFilterState ||
          actionData.conversationFilterQuery
        ) {
          const callLogFilters: any = actionData.conversationFilterQuery;

          if (callLogListState?.toNumber) {
            callLogFilters.callLogFilters.toNumber = callLogListState?.toNumber;
          }
          setCallLogListState((prev) => {
            return {
              ...prev,
              conversationFilterProps: actionData.conversationFilterProps,
              conversationFilterQuery: {
                ...callLogFilters,
                //...callLogListState.conversationFilterQuery
              },
              filterModal: false,
              isLoading: true,
            };
          });
        } else {
          setCallLogListState((prev) => {
            return {
              ...prev,
              filterModal: false,
            };
          });
        }

        break;
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setCallLogListState((prev) => {
          return {
            ...prev,
            filterModal: false,
          };
        });
        break;
    }
  };

  const getAvailableVirtualNumbers = () => {
    return (
      <Select
        allowClear
        showSearch={true}
        style={{width: '100%'}}
        placeholder="Select Phone Number"
        value={props?.userVirtualMobileNo?.length ? (callLogListState?.toNumber) : undefined}
        filterOption={(input: string, option: any) => {
          return (option?.searchString || '')?.toLowerCase?.()?.includes(input?.toLowerCase?.());
        }}
        onChange={(selectedNumber: any) => {
          //if (selectedNumber?.length) {
          const storageNumber: any = {};
          storageNumber[userUuid] = {
            phoneNumber: selectedNumber,
          };
          LocalStorage.setItem('callLogsFilterToNumber', storageNumber);
          const storageValue: any = {};
          storageValue[userUuid] = {
            selectedInboxAllData: {
              selectedInboxTypeCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
              channelTwilioSms: {
                phoneNumber: selectedNumber,
              }
            },
          };
          LocalStorage.setItem(CONVERSATION_STORAGE_KEY.SMS_INBOX_LOCAL_KEY, storageValue);

          const callLogFilters: any = callLogListState?.conversationFilterQuery;
          if (callLogFilters?.callLogFilters) {
            callLogFilters.callLogFilters.toNumber = selectedNumber;
          }

          setCallLogListState((prev) => {
            return {
              ...prev,
              toNumber: selectedNumber || undefined,
              conversationFilterQuery: {
                ...callLogFilters
              },
              isLoading: true,
              list: [],
            };
          });
          // }
        }}
        className={'chat-inbox-select'}
      >
        {props?.userVirtualMobileNo?.length && props.userVirtualMobileNo.map((singleVirtualNum: any) => {
          return (
            <Option
              key={singleVirtualNum?.id}
              searchString={`${singleVirtualNum?.virtualPhoneNumber} ${singleVirtualNum?.assigneeName || ''}`}
              value={singleVirtualNum?.virtualPhoneNumber}
            >
              <View flexDirection={'row'}>
                <View style={{padding: 5}}>
                  <USFlagSVG />
                </View>
                <View style={{marginLeft: 12}}>
                  <Text fontSize={'14px'} color={Colors.Custom.Gray900}>
                    {singleVirtualNum?.virtualPhoneNumber}
                  </Text>
                  {
                    singleVirtualNum?.assigneeName && (
                      <Text
                        style={{marginTop: 0, width: 175}}
                        fontSize={'12px'}
                        color={Colors.Custom.Gray500}
                        noOfLines={1}
                      >
                        {singleVirtualNum?.assigneeName || ''}
                      </Text>
                    )
                  }

                </View>
              </View>
            </Option>
          );
        })}
      </Select>
    );
  };

  return (
    <View>
      <HStack style={{}}>
        <View flex={0.25}>
          <View mt={4} ml={4} mr={4}>
            {getAvailableVirtualNumbers()}
          </View>
          <CallLogList
            onPressItem={(index) => {
              setSelectedCallLogIndex(index);
            }}
            selectedCallLog={selectedCallLogIndex || 0}
            callLogs={callLogListState?.list}
            conversationFilterProps={callLogListState.conversationFilterProps}
            loadMoreCallLogs={() => {
              fetchCallLogs(callLogListState?.page + 1, false);
            }}
            isLoading={callLogListState?.isLoading}
            isLoadMoreLoading={callLogListState?.isLoadMoreLoading}
            showFetchMore={callLogListState?.showFetchMore}
            setSearchText={(searchString: string) => {
              setSearchString(searchString);
            }}
            reloadCallLogs={() => {
              setSelectedCallLogIndex(0);
              setCallLogListState((prev) => {
                return {
                  ...prev,
                  isLoading: true,
                };
              });
            }}
            onFilterModal={() => {
              setCallLogListState((prev) => {
                return {
                  ...prev,
                  filterModal: true,
                };
              });
            }}
            userList={callLogListState.userList || []}
            sipNumberList={callLogListState?.sipNumberList || []}
            isDialPadVisible={true}
          />
        </View>
        <View
          style={{
            borderColor: Colors.Custom.Gray200,
            borderWidth: 0.5,
          }}
        />
        {(callLogListState.list.length > 0 && selectedCallLogDetails.list?.length > 0) ? (
          <View flex={0.75}>
            <CallLogDetails
              onClickNext={() => {
                fetchContactCallLogs(selectedCallLogDetails?.page + 1, callLogListState?.list);
              }}
              onClickPrev={() => {
                fetchContactCallLogs(selectedCallLogDetails?.page - 1, callLogListState?.list);
              }}
              callLogDetails={selectedCallLogDetails?.list}
              callLogList={callLogListState?.list}
              selectedLog={
                callLogListState?.list?.[selectedCallLogIndex] ||
                callLogListState?.list?.[0]
              }
              callLogDetailsPage={selectedCallLogDetails?.page}
              userList={callLogListState?.userList || []}
              sipNumberList={callLogListState?.sipNumberList || []}
            />
          </View>
        ) : (
          <VStack
            flex={0.75}
            style={[
              styles.noDataFound,
              {
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                borderColor: Colors.Custom.Gray200,
              },
            ]}
          >
            <VStack flex={1}>
              <Center
                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
                height={'100%'}
              >
                <NoCallLogsSvg />
                <Text
                  padding={4}
                  fontSize={24}
                  fontWeight={'700'}
                  color={Colors.Custom.Gray500}
                >
                  {intl.formatMessage({id: 'noCallLogs'})}
                </Text>
              </Center>
            </VStack>
          </VStack>
        )}
      </HStack>

      {callLogListState.filterModal &&
        <ConversationFilter
          showModal={callLogListState.filterModal}
          onModalAction={onModalAction}
          selectedTab={CHANNEL_TYPE_CODE.CALL_LOGS}
          inboxList={{} as IUserInboxList}
          selectedData={callLogListState?.conversationFilterProps}
          userVirtualMobileNo={props?.userVirtualMobileNo}
          userFilterData={{} as any}
        />
      }
    </View>
  );
};

export default CallLogs;
