import {useApolloClient} from '@apollo/client';
import {useState} from 'react';
import {MLOV_CATEGORY, USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {getMlovIdFromCodeAndCategory} from '../../../../utils/mlovUtils';
import {IPermissions, IPermissionsList} from '../interface';
import permissionQueries from '../permissionQueries';
import {getFormattedPermissionList} from '../utils';

interface IUseAddRoleAndPermission {
  accountUuid?: string;
  roleCode?: string;
  roleName?: string;
  permissionList?: IPermissionsList[];
  accountRoleId?: string;
  fetchRoleCategory?: string[];
}
const useAddRoleAndPermission = (args: IUseAddRoleAndPermission) => {
  const {roleCode, roleName, permissionList, accountRoleId, accountUuid, fetchRoleCategory} = args;
  const customRoleMlovId = getMlovIdFromCodeAndCategory(USER_ROLE_CODES.CUSTOM_ROLE,MLOV_CATEGORY.USER_ROLES, false);
  const client = useApolloClient();
  const [stateData, setStateData] = useState({
    submitLoading: false,
    errorName: '',
    fecthRoleLoading: false
  });
  const createAccountRoleFunc = async () => {
    if (!roleName) {
      setStateData((prev) => {
        return {
          ...prev,
          errorName: 'Please enter role name',
          submitLoading: false
        };
      });
      return;
    }
    const accountRoleResp = await client
      .query({
        query: permissionQueries.createAccountRole,
        variables: {
          customRoleCode: roleCode,
          roleName: roleName,
          roleId: customRoleMlovId,
        },
        fetchPolicy: 'no-cache',
      })
      .catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            errorName: 'Error in creating Role',
          };
        });
      });
    return {id: accountRoleResp?.data?.createAccountRole?.id || ''};
  };
  const createPermissionByRoleFunc = async (roleId?: string) => {
    if (accountUuid && roleCode && permissionList?.length) {
    const currentRoleId = roleId || accountRoleId;
    if (!currentRoleId || !permissionList?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          errorName: 'Account Role and permission List not valid',
          submitLoading: false
        };
      });
      return;
    }
    const permission = getFormattedPermissionList({
      accountRoleId: currentRoleId || '',
      accountUuid,
      permissionList: permissionList,
      roleCode: roleCode,
    });
    const userRolePermissionResp = await client
      .query({
        query: permissionQueries.createUserRolePermissions,
        variables: {
          objects: permission,
        },
        fetchPolicy: 'no-cache',
      })
      .catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            errorName: 'Error in create roles permission',
          };
        });
      });
    return {
      ...(userRolePermissionResp?.data?.createUserPermissions || {}),
    };
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          errorName: 'missing required paremeter',
        };
      });
    }
  };
  const createRoleAndPermission = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        submitLoading: true,
      };
    });
    const accountRoleResp = await createAccountRoleFunc();
    if (accountRoleResp?.id) {
      const createUserRolePermissionResp = await createPermissionByRoleFunc(
        accountRoleResp.id
      );
      if (createUserRolePermissionResp?.returning?.length) {
        setStateData((prev) => {
          return {
            ...prev,
            submitLoading: false,
          };
        });
        return {
          id: accountRoleResp.id,
          permission: createUserRolePermissionResp?.returning,
        };
      }
    }
    setStateData((prev) => {
      return {
        ...prev,
        submitLoading: false,
      };
    });
  };
  const getAccountRoles = async() => {
    setStateData((prev) => {
      return {
        ...prev,
        errorName: 'Error in fetching Role',
        fecthRoleLoading: true
      };
    });
    let roles = []
    const accountRoleResp = await client
    .query({
      query: permissionQueries.getAccountRoles,
      variables: {
        category: fetchRoleCategory
      },
      fetchPolicy: 'no-cache',
    })
    .catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          errorName: 'Error in fetching Role',
          fecthRoleLoading: false
        };
      });
    });
    if (accountRoleResp?.data?.accountRoles?.length) {
      roles = accountRoleResp?.data?.accountRoles
    }
    setStateData((prev) => {
      return {
        ...prev,
        errorName: '',
        fecthRoleLoading: false
      };
    });
    return roles
  }
  return {
    createRoleAndPermission,
    createAccountRoleFunc,
    createPermissionByRoleFunc,
    getAccountRoles,
    submitLoading: stateData.submitLoading,
    error: stateData.errorName,
    fecthRoleLoading:  stateData.fecthRoleLoading
  };
};

export default useAddRoleAndPermission;
