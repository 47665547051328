import moment from 'moment';
import {DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, PERSON_TYPES, PERSON_TYPES_TEXT, QUALITY_MEASURES_PATIENT_TYPE} from '../../../../constants';
import {getAgeValue, getDateStrFromFormat} from '../../../../utils/DateUtils';
import {
  IContactData,
  IContractResp,
  IMeasureReport,
  IPatientList,
  IqmContractQualityMeasuresResp,
  IQualityMeasuresList,
} from './interface';

export const getFormattedContracts = (contractsResp: IContractResp) => {
  const contracts = contractsResp?.selectQmContract;
  const formattedContracts = contracts.map((contract) => {
    return {
      ...contract,
      livesCovered: contract?.contractContacts_aggregate?.aggregate?.count || 0,
      startDate: getDateStrFromFormat(
        contract.startDate,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      ),
      endDate: getDateStrFromFormat(
        contract.endDate,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      ),
      projectedRevenue: formatProjectedRevenue(contract.projectedRevenue),
    };
  });
  return formattedContracts;
};

export function formatProjectedRevenue(value: string) {
  if (!value) {
    return '';
  }
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return '';
  }
  return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const getFormattedQualityMeasuresList = (
  qualityMeasuresList: IqmContractQualityMeasuresResp
) => {
  const formattedQualityMeasures: IQualityMeasuresList[] =
    qualityMeasuresList.qmContractQualityMeasures.map((qmItem) => {
      return {
        careGap: 0,
        id: qmItem.id,
        goal: qmItem.goal,
        name: qmItem?.qualityMeasure?.name,
        totalPatients: 0,
        performanceScore: '0%',
        measureId: qmItem?.qualityMeasure?.id
      };
    });
  return formattedQualityMeasures;
};

export const getContactAndMeasureFormattedData = (args:{
  qmMeasureReports: IMeasureReport[],
  contactData: IContactData[],
  patientType: string
}
): IPatientList[] => {
  const {
    qmMeasureReports,
    contactData,
    patientType
  } = args;
  const contactMap = new Map(contactData.map((contact) => [contact.uuid, contact]));
  return qmMeasureReports.map((report) => {
    const contact = contactMap.get(report.contactId);
    const contactType = contact?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER
      ? PERSON_TYPES_TEXT.PATIENT
      : PERSON_TYPES_TEXT.PROSPECT;
    const gender = contact?.person?.sexAtBirth?.value || contact?.person?.gender?.value || '';
    const birthDate = contact?.person?.birthDate ? moment(contact.person.birthDate).format(DISPLAY_SLASH_DATE_FORMAT) : '';
    const ageValue = contact?.person?.birthDate ? getAgeValue(contact.person.birthDate, '') : '';
    let reason = ''
    if (patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED) {
      reason = report.qualificationReason || '' 
    } else if (patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED) {
      reason = report.disqualificationReason
    }
    return {
      id: report.id,
      reason: reason,
      payer: report?.qmContract?.payer,
      contactData: {
        uuid: contact?.uuid || '',
        memberType: contactType,
        name: contact?.name || '',
        gender: gender,
        age: ageValue,
        dob: birthDate,
      },
    };
  });
};
export function extractNumber(input: string): number | null {
  const match = input.match(/\d+(\.\d+)?/);
  if (match) {
    return parseFloat(match[0]);
  }
  return null;
}