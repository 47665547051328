import {Drawer, notification} from 'antd';
import AddOrUpdatePatientNote from '../PersonOmniView/MiddleContainer/CareTimeline/AddOrUpdatePatientNote';
import {
  IPatientNoteCardProps,
  ViewType,
} from '../PersonOmniView/MiddleContainer/interfaces';
import {useContext, useState} from 'react';
import {CommonDataContext} from '../../context/CommonDataContext';
import {getEHRName, getResourceAbilities} from '../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../constants/FhirConstant';
import {
  getEhrConfig,
  getUserDetailByUserUuid,
} from '../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {useQuery} from '@apollo/client';
import {GET_USERS_FOR_CALENDAR_WITH_LOCATION} from '../../services/User/UserQueries';
import {
  MLOV_CATEGORY,
  USER_PREFERENCE_CODE,
  USER_ROLE_CODES,
} from '../../constants/MlovConst';
import {
  getAccountUUID,
  getUserUUID,
  isPhysicianRole,
} from '../../utils/commonUtils';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {DocStatus} from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import TimeLineSkeletonLoader from '../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import {FormsQueries} from '../../services';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../utils/mlovUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {SidecarPatientInfoBanner} from './SidecarPatientInfoBanner';
import Stack from '../common/LayoutComponents/Stack';
import {View} from 'react-native';
import {Divider} from 'native-base';
import useEHRCapabilities from '../../screens/BusinessStudio/useEHRCapabilities';

interface ISidecarAddNotesDrawerProps {
  contactData: IContact;
  formattedContactData: IContact;
  onClose: () => void;
  onSaveNote: (noteData: {id: string; docStatus: string}) => void;
  noteData: IPatientNoteCardProps;
  isNewNote: boolean;
  accountUserList: any[];
}

interface ISidecarAddNotesDrawerState {
  defaultTemplate?: {
    formId: string;
    id?: string;
  };
  defaultNoteLoading: boolean;
  currentUserData: any;
}

export const SidecarAddNotesDrawer = (props: ISidecarAddNotesDrawerProps) => {
  const commonData = useContext(CommonDataContext);
  const contactLocationUuid =
    props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const ehrCapabilities = useEHRCapabilities({locationId: contactLocationUuid});
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    contactLocationUuid
  );

  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };

  const currentEHR: any = getEHRName(contactLocationUuid, '');
  const ehrConfig = getEhrConfig(currentEHR);
  const isPhysicianUser = isPhysicianRole();
  const userUUID = getUserUUID();

  const currentUserData = getUserDetailByUserUuid(
    props.accountUserList || [],
    userUUID
  );

  const [componentState, setComponentState] =
    useState<ISidecarAddNotesDrawerState>({
      currentUserData: currentUserData || ({} as any),
      defaultNoteLoading: true,
    });

  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.DEFAULT_FORM
  );

  useQuery(
    FormsQueries.GET_DEFAULT_FORM,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userId: userUUID,
        userPreferencesTypeId: userPreferenceId,
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onError: (error) => {
        setComponentState((prev) => ({
          ...prev,
          defaultNoteLoading: false
        }));
      },
      onCompleted: (data) => {
        let defaultForm =
          data?.userPreferences.length > 0 &&
          data?.userPreferences[data?.userPreferences.length - 1]
            ?.preferencesJson;
        const id = data?.userPreferences[data?.userPreferences.length - 1]?.id;
        if (defaultForm && id) {
          defaultForm = JSON.parse(defaultForm);
          setComponentState((prev) => ({
            ...prev,
            defaultTemplate: {formId: defaultForm.defaultForm, id},
            defaultNoteLoading: false
          }));
        }
        else {
          setComponentState((prev) => ({
            ...prev,
            defaultNoteLoading: false
          }));
        }
      },
    }
  );  

  const getNoteConfigureForAddNewNote = () => {
    return {
      createdByUserName: componentState.currentUserData?.name,
      status: DocStatus.PRELIMINARY,
      authorUuid: componentState.currentUserData?.uuid,
      linkedAppointmentId: undefined,
      resourceId: '',
      isAllowToDelete: true,
      isAllowToPrint: true,
      isAllowToUnlock: true,
      isAllowToAutoSave: true,
      isAllowToSign: true,
      isAllowToSave: true,
      formId: componentState?.defaultTemplate?.formId,
      isFoldDrivenNote: componentState?.defaultTemplate?.formId ? true : false
    } as IPatientNoteCardProps;
  };

  const componentLoading = componentState.defaultNoteLoading;  

  return (
    <Drawer
      className="create-note-from-alert-drawer"
      open={true}
      width={'100%'}
      style={{padding: 0}}
    >
      {componentLoading ? (
        <TimeLineSkeletonLoader />
      ) : (
        <Stack direction="column">
          <View style={{paddingVertical: 12, paddingHorizontal: 12}}>
            <SidecarPatientInfoBanner contactData={props?.contactData} />
          </View>

          <Divider height={'1px'} />

          <AddOrUpdatePatientNote
            isNewNote={props.isNewNote}
            paddingHorizontal={12}
            hideBottomOrderActionBar
            disableDefaultTemplateChange={false}
            viewType={ViewType.DRAWER}
            ehrCapabilities={ehrCapabilities}
            elationFormData={elationFormData}
            personData={props.formattedContactData}
            isLinkAppointment={false}
            ehrConfig={ehrConfig}
            unFormattedContactData={props.contactData}
            isPhysicianUser={isPhysicianUser}
            goBackHandler={props.onClose}
            noteData={
              props.isNewNote ? getNoteConfigureForAddNewNote() : props.noteData
            }
            accountUserList={props.accountUserList || []}
            onAddNoteSuccessCallback={(noteData) => {
              props?.onSaveNote({
                id: noteData.id,
                docStatus: noteData.docStatus,
              });
            }}
            defaultTemplate={componentState?.defaultTemplate}
          />
        </Stack>
      )}
    </Drawer>
  );
};
