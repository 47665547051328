import {useLazyQuery, useMutation} from '@apollo/client';
import {useContext, useEffect, useState} from 'react';
import FeedsQueries from '../../../../../services/Feeds/FeedsQueries';
import {FEED_SERVICE_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {
  FEED_RESOURCE_TYPE,
  TIME_LINE_SOURCE_TYPES,
} from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import {
  formatFhirRequestObj,
  formatHieDataResponse,
  getFilteredHieRequests,
  getUpdateGroupHieRequestsStatus,
  getUpdatedErrorGroupHieRequests,
  groupHieRequests,
  getInValidCodeObj,
  updateHieGroupInArr,
} from './HieRequestsUtils';
import {HieStatus, IHieRequest, IHieRequestGroup} from './interface';
import {getEhrConfig, getVitalListFromCapability} from '../../../../../utils/capabilityUtils';
import {fhirAddOrUpdate} from '../../../../../services/CommonService/AidBoxService';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {
  FHIR_RESOURCE,
  RESOURCE_BLOCK_MESSAGE_ID,
} from '../../../../../constants/FhirConstant';
import {LOADING_TYPES} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import {showPopupNotification} from '../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {HTTP_ERROR_CODE} from '../../../../../constants';
import {EventBus} from '../../../../../utils/EventBus';
import {ADD_UPDATE_EVENTS} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import usePractitioners from '../../../../common/FHIRPractitionerSearch/usePractitioners';
import { getEnabledVitalListFromCapability } from '../../../../../utils/VitalUtils';
import {IFormCommonData} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';

interface IHieRequestsParams {
  patientUuid?: string;
  accountLocationUuid: string;
  patientId?: string;
  contactUuid?: string;
  skip?: boolean;
}

interface ILoader {
  id: string;
  type: string;
}

interface IHieRequestsState {
  pendingHieRequests: IHieRequest[];
  hieRequestGroups: IHieRequestGroup[];
  updateHieRequestsLoading: ILoader[];
  loading: boolean;
}

export const useHieRequests = (params: IHieRequestsParams) => {
  const {patientUuid, accountLocationUuid, patientId, contactUuid, skip} =
    params;
  const commonData = useContext(CommonDataContext);
  const accountUuid = getAccountUUID();
  const intl = useIntl();
  const [componentState, setComponentState] = useState<IHieRequestsState>({
    pendingHieRequests: [],
    hieRequestGroups: [],
    updateHieRequestsLoading: [],
    loading: false,
  });

  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE) || false;

  const vitalList = getEnabledVitalListFromCapability(accountLocationUuid);
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});

  const practitionerData = usePractitioners({
    skip: false,
    hasLoggedInContext: true,
    locationId: accountLocationUuid
  });

  const ehrConfig = getEhrConfig(accountLocationUuid, '');

  const metaData = {
    enabledVitalList: vitalList,
    ehrCapabilities: ehrCapabilities,
    patientId: patientId || '',
    accountUuid: accountUuid,
    practitionerData: practitionerData.data,
    ehrConfig: ehrConfig,
    accountLocationUuid: accountLocationUuid
  };

  const [getHieRequests] = useLazyQuery(FeedsQueries.GET_FEEDS, {
    fetchPolicy: 'no-cache',
    context: {
      service: FEED_SERVICE_APOLLO_CONTEXT,
      headers: {},
    },
  });

  const [updateHieRequest] = useMutation(FeedsQueries.UPDATE_FEED_STATUS, {
    fetchPolicy: 'no-cache',
    context: {
      service: FEED_SERVICE_APOLLO_CONTEXT,
      headers: {},
    },
  });

  const broadcastUpdateEvent = (resourceType: string) => {
    let eventType = '';

    switch (resourceType) {
      case FHIR_RESOURCE.OBSERVATION:
        eventType = ADD_UPDATE_EVENTS.VITAL;
        break;
      case FHIR_RESOURCE.CONDITION:
        eventType = ADD_UPDATE_EVENTS.PROBLEM;
        break;
      case FHIR_RESOURCE.ALLERGY:
        eventType = ADD_UPDATE_EVENTS.ALLERGY;
        break;
      case FHIR_RESOURCE.MEDICATION_STATEMENT:
        eventType = ADD_UPDATE_EVENTS.MED;
        break;
      case FHIR_RESOURCE.IMMUNIZATION:
        eventType = ADD_UPDATE_EVENTS.IMMUNIZATION;
        break;
      case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
        eventType = ADD_UPDATE_EVENTS.FAMILY_HISTORY;
        break;
      case FHIR_RESOURCE.PROCEDURE:
        eventType = ADD_UPDATE_EVENTS.SURGICAL_HISTORY;
        break;
    }
    if (eventType) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(eventType, 'detail');
    }
  };

  useEffect(() => {
    if (!isHIEEnabled) {
      return;
    }

    if (!skip) {
      setComponentState((prev) => ({
        ...prev,
        loading: true,
      }));
    }
    if (!skip && practitionerData.fetched) {
      fetchHieRequests(true);
    }
  }, [practitionerData.fetched]);

  const updateHieRequestsLoader = (
    ids: string[],
    status: string,
    isRemove?: boolean
  ) => {
    setComponentState((prev) => {
      let updatedLoadingHieRequests = prev.updateHieRequestsLoading;
      if (isRemove) {
        updatedLoadingHieRequests = updatedLoadingHieRequests.filter(
          (loadingObj) => !ids.includes(loadingObj.id)
        );
      } else {
        const loadingType =
          status === HieStatus.accepted
            ? LOADING_TYPES.ACCEPT
            : LOADING_TYPES.DECLINE;
        for (let i = 0; i < ids.length; i++) {
          updatedLoadingHieRequests.push({id: ids[i], type: loadingType});
        }
      }

      return {
        ...prev,
        updateHieRequestsLoading: updatedLoadingHieRequests,
      };
    });
  };

  const updateFeedStatus = async (
    hieRequests: IHieRequest[],
    status: HieStatus
  ) => {
    try {
      const response = await updateHieRequest({
        variables: {
          ids: hieRequests?.map((hieRequest) => hieRequest.id),
          syncStatus: status,
        },
      });

      if (response.data.updateFeedsData.affected_rows) {

        let currentHieRequestGroups : IHieRequestGroup[] = [];

        setComponentState((prev) => {
          currentHieRequestGroups = prev.hieRequestGroups;
          return prev;
        });

        const updatedGroupHieRequests = getUpdateGroupHieRequestsStatus(
          hieRequests,
          currentHieRequestGroups,
          status
        );

        setComponentState((prev) => ({
          ...prev,
          hieRequestGroups: updatedGroupHieRequests,
        }));
      }
      return response;
    } catch (error) {
      showError(intl.formatMessage({id: 'apiErrorMsg'}));
    }
  };

  const updateHieRequestStatus = async (
    hieRequest: IHieRequest,
    status: HieStatus
  ) => {
    updateHieRequestsLoader([hieRequest.id], status);

    try {
      const formattedRequestObj = formatFhirRequestObj(
        hieRequest.resourceType,
        hieRequest.resourceData,
        metaData,
        false
      );

      const acceptedStatus = status === HieStatus.accepted;

      if (acceptedStatus) {
        const updatedFhirResponse = await addOrUpdateFhirRecord(
          hieRequest,
          formattedRequestObj
        ) as any;

        if (updatedFhirResponse?.response) {
          const response = await updateFeedStatus([hieRequest], status);

          if (response) {
            updateHieRequestsLoader([hieRequest.id], status, true);
            if (status === HieStatus.accepted) {
              broadcastUpdateEvent(hieRequest?.resourceType);
            }
          }
        }
        else if(updatedFhirResponse?.error){
          updateHieRequestsLoader([hieRequest.id], status, true);
          hieRequest.error = getErrorMessage(updatedFhirResponse?.error);
          const invalidObj = getInValidCodeObj(hieRequest.resourceType);
          if(invalidObj) {
            hieRequest.invalidFields = [invalidObj]
          }
          const updatedErrorRequestGroups = getUpdatedErrorGroupHieRequests([hieRequest], componentState.hieRequestGroups);
          updateHieRequestGroupsState(updatedErrorRequestGroups);
        }
      } else {
        const response = await updateFeedStatus([hieRequest], status);

        if (response) {
          updateHieRequestsLoader([hieRequest.id], status, true);
        }
      }
    } catch (error) {
      showError('', error);
      updateHieRequestsLoader([hieRequest.id], status, true);
    }
  };

  const updateHieGroupLoader = (hieGroup: IHieRequestGroup, updateObj: any) => {
    const updatedHieGroups = updateHieGroupInArr(
      componentState.hieRequestGroups,
      hieGroup,
      updateObj
    );
    setComponentState((prev) => ({
      ...prev,
      hieRequestGroups: updatedHieGroups,
    }));
  };

  const getHieGroupPromiseObj = (hieGroup: IHieRequestGroup) => {
    if (hieGroup.resourceType === FHIR_RESOURCE.OBSERVATION) {
      const filteretdHieRequests = getFilteredHieRequests(hieGroup.orders);
      const formattedRequestObj = formatFhirRequestObj(
        hieGroup.resourceType,
        filteretdHieRequests.map((order) => order.resourceData),
        metaData,
        true
      );
      return [
        addOrUpdateFhirRecord(hieGroup?.orders?.[0], formattedRequestObj),
      ];
    }

    return getFilteredHieRequests(hieGroup.orders).map((order) => {
      const formattedRequestObj = formatFhirRequestObj(
        order.resourceType,
        order.resourceData,
        metaData,
        false
      );
      return addOrUpdateFhirRecord(order, formattedRequestObj);
    });
  };

  const callAcceptAllApi = async (requests: IHieRequest[]) => {
    const allRecordsCount = requests?.length;
    const errorRequests = [];
    const successRequests = [];

    updateHieRequestsLoader(requests?.map(request => request.id), HieStatus.accepted);

    for (let i = 0; i < allRecordsCount; i++) {
      try {
        const hieRequest = requests[i];

        const formattedRequestObj = formatFhirRequestObj(
          hieRequest.resourceType,
          hieRequest.resourceData,
          metaData,
          false
        );
        const updatedFhirResponse = (await addOrUpdateFhirRecord(
          hieRequest,
          formattedRequestObj
        )) as any;

        if (updatedFhirResponse?.response) {
          const response = await updateFeedStatus(
            [hieRequest],
            HieStatus.accepted
          );
          if (response) {
            successRequests.push(updatedFhirResponse.hieRequest);
          }
        } else if (updatedFhirResponse?.error) {
          hieRequest.error = getErrorMessage(updatedFhirResponse?.error);
          const invalidObj = getInValidCodeObj(hieRequest.resourceType);
          if(invalidObj) {
            hieRequest.invalidFields = [invalidObj]
          }
          errorRequests.push(hieRequest);
        }
      } catch (error) {
        showError(intl.formatMessage({id: 'apiErrorMsg'}));
      }
    }

    updateHieRequestsLoader(requests?.map(request => request.id), HieStatus.accepted, true);

    return {
      errorRequests: errorRequests,
      successRequests: successRequests,
    };
  };

  const handleAcceptAll = async (hieGroup: IHieRequestGroup) => {
    const invalidRecordExist = hieGroup.orders.some(
      (order) => order.invalidFields && order.invalidFields?.length > 0
    );

    if (invalidRecordExist) {
      showPopupNotification(
        'Error',
        "Review and correct each field. Some are missing data or don't match records.",
        'error',
        400
      );
      return;
    }

    updateHieGroupLoader(hieGroup, {acceptAllLoading: true});

    const filteredRequests = getFilteredHieRequests(hieGroup?.orders);

    const {errorRequests, successRequests} = await callAcceptAllApi(
      filteredRequests
    );

    if (errorRequests?.length > 0) {
      showError(
        `Can not reconcile ${errorRequests?.length} out of ${filteredRequests?.length} records at this time.`
      );

      const updatedErrorRequestsGroups = getUpdatedErrorGroupHieRequests(errorRequests, componentState.hieRequestGroups);
      updateHieRequestGroupsState(updatedErrorRequestsGroups);
    }

    if (successRequests?.length > 0) {
      broadcastUpdateEvent(hieGroup?.orders?.[0]?.resourceType);
      updateHieGroupLoader(hieGroup, {acceptAllLoading: false});
    }
  };

  const getErrorMessage = (error?: any) => {
    let errorMessageText = '';
    if (error) {
      const errorData = error?.response?.data;
      const errorText = errorData?.extensions?.detailedError?.[0]?.details?.text || '';
      errorMessageText =
        error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
          ? intl.formatMessage({
              id: RESOURCE_BLOCK_MESSAGE_ID,
            })
          : errorData?.message;

        if (errorText && errorText.trim().startsWith('{')) {
          const errorObj = JSON.parse(errorText);
          errorMessageText = errorObj?.error;
        }
        else if(errorText && typeof errorText === 'string'){
          errorMessageText = errorText;
        }
    }
    return errorMessageText;
  };

  const showError = (errorMessage: string, error?: any) => {
    const errorMessageText = getErrorMessage(error) || errorMessage;
    showPopupNotification('Error', errorMessageText, 'error', 400);
  };

  const addOrUpdateFhirRecord = (
    hieRequest: IHieRequest,
    formattedRequestObj: any
  ) => {
    return new Promise((resolve, reject) => {
      fhirAddOrUpdate(
        hieRequest.resourceType,
        undefined,
        formattedRequestObj,
        {
          patientId: patientId,
          locationId: accountLocationUuid,
        },
        (response) => {
          if (response) {
            resolve({response: response, hieRequest});
          }
        },
        (error) => {
          resolve({error: error, hieRequest});
        }
      );
    });
  };

  const updateHieRequestGroupsState = (
    hieRequestGroups: IHieRequestGroup[]
  ) => {
    setComponentState((prev) => ({
      ...prev,
      hieRequestGroups: hieRequestGroups,
    }));
  };

  const fetchHieRequests = async (showLoader: boolean) => {
    if (showLoader) {
      setComponentState((prev) => ({
        ...prev,
        loading: showLoader,
      }));
    }
    try {
      const hieRequestsResponse = await getHieRequests({
        variables: {
          feedDataWhereCondition: {
            isDeleted: {_eq: false},
            _and: [
              {
                _or: [
                  {
                    patient_id: {
                      _eq: patientUuid,
                    },
                  },
                  {
                    patient_id: {
                      _eq: contactUuid,
                    },
                  },
                ],
              },
              {
                display_type: {
                  _in: [
                    FHIR_RESOURCE.ALLERGY,
                    FHIR_RESOURCE.CONDITION,
                    FHIR_RESOURCE.IMMUNIZATION,
                    FHIR_RESOURCE.MEDICATION_STATEMENT,
                    FHIR_RESOURCE.OBSERVATION,
                    FHIR_RESOURCE.PROCEDURE,
                    FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
                  ],
                },
              },
              {
                sync_status: {
                  _is_null: true,
                },
              },
              {
                source: {
                  _eq: TIME_LINE_SOURCE_TYPES.HIE,
                },
              },
            ],
            categoryId: {
              _is_null: true,
            },
          },
        },
      });

      if (hieRequestsResponse?.data?.feed_data?.length) {
        const formattedHieRequests = formatHieDataResponse(
          hieRequestsResponse?.data?.feed_data,
          metaData
        );
        const hieRequestGroups = groupHieRequests(formattedHieRequests);

        setComponentState((prev) => ({
          ...prev,
          pendingHieRequests: formattedHieRequests,
          hieRequestGroups: hieRequestGroups,
          loading: false,
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          pendingHieRequests: [],
          hieRequestGroups: []
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));

    }
  };

  return {
    pendingHieRequests: componentState.pendingHieRequests,
    hieRequestGroups: componentState.hieRequestGroups,
    updateHieRequestStatus: updateHieRequestStatus,
    updateHieRequestsLoading: componentState.updateHieRequestsLoading,
    updateHieRequestGroupsState: updateHieRequestGroupsState,
    loading: componentState.loading,
    fetchHieRequests: fetchHieRequests,
    handleAcceptAll: handleAcceptAll,
    metaData: metaData,
  };
};
