import {Path, Svg} from 'react-native-svg';

const ExclamationSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <Path
        stroke="#3A485F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 4.633V7.3m.83-5.994L8.646 2c.205.174.46.28.728.301l1.066.085a1.28 1.28 0 011.175 1.175l.084 1.066c.022.268.128.523.303.728l.693.814a1.28 1.28 0 010 1.661l-.693.814c-.175.205-.281.46-.303.729l-.085 1.066a1.28 1.28 0 01-1.174 1.175l-1.066.085a1.28 1.28 0 00-.728.301l-.814.694a1.281 1.281 0 01-1.662 0L5.355 12a1.28 1.28 0 00-.728-.302l-1.066-.085a1.28 1.28 0 01-1.174-1.174L2.3 9.373A1.28 1.28 0 002 8.644l-.693-.814a1.28 1.28 0 010-1.66L2 5.354c.175-.205.28-.46.3-.728l.086-1.066a1.281 1.281 0 011.175-1.175l1.066-.085A1.28 1.28 0 005.355 2l.814-.694a1.281 1.281 0 011.662 0zM7.034 9.3v.067h-.066V9.3h.066z"
      ></Path>
    </Svg>
  );
};

export default ExclamationSvg;
