import { gql } from '@apollo/client';

export const GET_FAILED_COMMUNICATION_LOG_COUNT = gql`
  query getFailedCommunicationLogCount(
    $startDate: timestamp
    $endDate: timestamp
  ) {
    campaignFailedCount: communicationLogAggregate(
      where: {
        createdAt: {_gte: $startDate, _lte: $endDate}
        communicationStatus: {status: {_in: ["failed", "undelivered"]}}
        communicationTags: {tagType: {_eq: "campaignId"}}
        originService: {_eq: "WORKFLOW"}
      }
    ) {
      aggregate {
        count
      }
    }
    workflowFailedCount: communicationLogAggregate(
      where: {
        createdAt: {_gte: $startDate, _lte: $endDate}
        communicationStatus: {status: {_in: ["failed", "undelivered"]}}
        communicationTags: {tagType: {_eq: "workflowMasterId"}}
        originService: {_eq: "WORKFLOW"}
      }
    ) {
      aggregate {
        count
      }
    }
    otherFailedCount: communicationLogAggregate(
      where: {
        createdAt: {_gte: $startDate, _lte: $endDate}
        communicationStatus: {status: {_in: ["failed", "undelivered"]}}
        originService: {_neq: "WORKFLOW"}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FAILED_LOG = gql`
  query getFailedLog($limit: Int, $offset: Int, $communicationLogBoolExp: communication_log_bool_exp!) {
  communicationLogs(limit: $limit, offset: $offset, where: $communicationLogBoolExp) {
    id
    receiverContact {
      id
      uuid
      name
    }
    receiverUser {
      name
      id
      uuid
    }
    communicationStatus {
      communicationErrorInfo {
        message
        code
      }
      twilioErrorInfo {
        message
        code
      }
    }
    createdAt
    communicationType
    originService
    receiverResourceType
    receiverResourceId
  }
}

`;

export const GET_ERROR_SPECIFIC_COUNT = gql`
  query GetAggregateErrorCountByTag($tag: uuid!, $startDate: timestamp, $endDate: timestamp, $communicationType: String) {
  communicationErrorCodes (where: {communication_service: {_eq: $communicationType}}) {
    code
    message
    failedCommunicationAggregate: failedCommunications_aggregate(where: {communicationLog: {communicationTags: {tag: {_eq: $tag}}, createdAt: { _gte: $startDate, _lte: $endDate }}}) {
      aggregate {
        count
      }
    }
    }
}
`;

export const GET_DISTINCT_ERROR_MESSAGES = gql`
  query getDistinctErrorMessages(
    $where: communication_error_codes_bool_exp
    $limit: Int
    $offset: Int
  ) {
    communicationErrorCodes(
      distinct_on: [message]
      where: $where
      limit: $limit
      offset: $offset
    ) {
      message
      code
    }
  }
`;

export default {
  GET_FAILED_COMMUNICATION_LOG_COUNT,
  GET_FAILED_LOG,
  GET_ERROR_SPECIFIC_COUNT,
};
