import React from 'react';
import {Svg, Path} from 'react-native-svg';

function ScheduleAppointmentSvg() {
  return (
    <Svg width="16" height="17" fill="none" viewBox="0 0 16 17">
      <Path
        fill="#6F7A90"
        d="M12 11.564a.5.5 0 100-1v1zm-2.667-1a.5.5 0 000 1v-1zm1.834-.867a.5.5 0 10-1 0h1zm-1 2.735a.5.5 0 101 0h-1zm-6-9.573a.5.5 0 101 0h-1zm1-1.026a.5.5 0 00-1 0h1zm5.666 1.026a.5.5 0 101 0h-1zm1-1.026a.5.5 0 00-1 0h1zM1.667 5.778a.5.5 0 100 1v-1zm12.666 1a.5.5 0 000-1v1zm-7.666-3.42h2.666v-1H6.667v1zm7.5 4.971v1.367h1V8.33h-1zm-4.834 6.338H6.667v1h2.666v-1zm-7.5-4.97V8.329h-1v1.367h1zm4.834 4.97c-1.272 0-2.174-.002-2.858-.096-.668-.092-1.054-.265-1.337-.554l-.716.698c.499.51 1.132.739 1.916.847.769.106 1.752.105 2.995.105v-1zM.833 9.697c0 1.275 0 2.28.102 3.066.105.797.325 1.442.821 1.952l.716-.698c-.284-.292-.455-.693-.545-1.384-.093-.705-.094-1.633-.094-2.936h-1zm13.334 0c0 1.303-.001 2.23-.094 2.936-.09.691-.26 1.092-.545 1.384l.716.698c.496-.51.716-1.155.82-1.952.104-.785.103-1.791.103-3.066h-1zm-4.834 5.97c1.243 0 2.226 0 2.995-.105.784-.108 1.417-.336 1.916-.847l-.716-.698c-.283.29-.668.462-1.337.554-.684.095-1.586.096-2.858.096v1zm0-12.308c1.272 0 2.174.001 2.858.095.668.093 1.054.265 1.337.555l.716-.698c-.499-.511-1.132-.74-1.916-.847-.768-.106-1.752-.105-2.995-.105v1zm5.834 4.97c0-1.276 0-2.281-.102-3.066-.105-.798-.325-1.443-.821-1.952l-.716.698c.284.292.455.692.545 1.384.093.705.094 1.633.094 2.936h1zm-8.5-5.97c-1.243 0-2.226-.001-2.995.105-.784.108-1.417.336-1.916.847l.716.698c.283-.29.669-.462 1.337-.555.684-.094 1.586-.095 2.858-.095v-1zm-4.834 5.97c0-1.303.001-2.231.094-2.936.09-.692.261-1.092.545-1.384l-.716-.698c-.496.51-.716 1.154-.82 1.952-.104.785-.103 1.79-.103 3.066h1zM12 10.564h-1.333v1H12v-1zm-1.333 0H9.333v1h1.334v-1zm-.5-.867v1.367h1V9.697h-1zm0 1.367v1.367h1v-1.367h-1zm-5-8.205V1.833h-1V2.86h1zm6.666 0V1.833h-1V2.86h1zM1.667 6.778h12.666v-1H1.667v1z"
      ></Path>
    </Svg>
  );
}

export default ScheduleAppointmentSvg;
