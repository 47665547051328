import React from 'react';
import {Pressable} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {StyleProp, ViewStyle} from 'react-native';
import {Colors} from '../../styles';

const RadioButton = (props: {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  isDisabled?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  const {isChecked, isDisabled, onChange} = props;
  return (
    <Pressable
      disabled={isDisabled}
      justifyContent={'center'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      alignItems={'center'}
      onPress={() => onChange(!isChecked)}
      h={6}
      w={6}
      rounded={'full'}
      background={
        isChecked
          ? Colors.FoldPixel.PRIMARY300
          : isDisabled
          ? Colors.FoldPixel.GRAY50
          : Colors.Custom.White
      }
      borderWidth={1}
      borderColor={
        isChecked ? Colors.FoldPixel.PRIMARY300 : Colors.FoldPixel.GRAY200
      }
      style={props.style}
    >
      {isChecked && (
        <Pressable
          rounded={'full'}
          borderWidth={5}
          borderColor={Colors.Custom.White}
        ></Pressable>
      )}
    </Pressable>
  );
};

export default RadioButton;
