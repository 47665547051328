import {
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import { v4 as uuidV4 } from 'uuid';
import {Drawer, Empty, Typography} from 'antd';
import moment from 'moment';
import {
  Box, Center, FlatList,
  HStack,
  Pressable, ScrollView, Skeleton, Text, useToast, View,
  VStack
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {MLOV_CATEGORY} from '../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {APPOINTMENT_STATUS_CODES, GROUP_TYPE_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  AppointmentQueries,
  FormsQueries,
  TaskQueries
} from '../../../../services';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../../styles';
import {getVitalListFromCapability} from '../../../../utils/capabilityUtils';
import {
  getAccountId,
  getUserFullName,
  getUserId,
  getUserUUID
} from '../../../../utils/commonUtils';
import {
  getCurrentTimeZone,
  getDateObject,
  getDateRangeForCalendar,
  getDayBack
} from '../../../../utils/DateUtils';
import {
  getMlovCodeFromId,
  getMlovCodeIdObj,
  getMlovId,
  getMlovListFromCategory
} from '../../../../utils/mlovUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {getFormattedSubmittedFormList} from '../../../PersonOmniView/RightContainer/ListView/SubmittedFormsList';
import {CHANNEL_TYPE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {
  IConversationData,
  IConversationResponse
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getContactDataAndType} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import MessagingWindow from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindow';
import {MessagingWindowHeader} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindowHeader';
import {BottomViewAction} from '../../../TaskCard/TaskCardHelper';
import AddOrUpdateTask from '../../AddTask/AddOrUpdateTask';
import {
  getAppointmentUserId,
  getPrimaryContactName
} from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {APPOINTMENT_BOOKING_VIEW_CODES} from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';
import AppointmentDetail from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import {
  IAppointmentDetail,
  ICalendarEvent
} from '../../CalendarWidget/CalendarWidgetInterfaces';
import { ParticipantType } from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {TASK_ASSIGNEE_TYPE_CODES} from '../../CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import {getCompletedTaskStatusId} from '../../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import FormResponseView from '../../FormResponseView/FormResponseView';
import TaskDueTodaylListItem from './TaskDueTodaylListItem';
import {styles} from './TaskDueTodayStyle';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
const {Title} = Typography;

export const FILTER_CODES = {
  ALL: 'ALL',
  APPOINTMENTS: 'APPOINTMENTS',
  TASKS: 'TASKS',
  FORMS: 'FORMS',
  MESSAGES: 'MESSAGES',
};

const TaskDueTodayDashboard = () => {
  const currentUserId = getUserUUID();
  const currentUserName = getUserFullName();
  const userId = getUserId();
  const accountId = getAccountId();
  const mlovData = useContext(CommonDataContext);
  const vitals = getVitalListFromCapability('', '');
  const intl = useIntl();
  const toast = useToast();
  const [taskDetail, setTaskDetail] = useState({
    isLoading: false,
    //taskCount: 0,
    task: [] as ITask[],
    appointmentEvents: [],
    conversationList: [] as IConversationData[],
    finalDueTaskAllList: [] as any,
    formListData: [] as any,
    selectedFilterCode: FILTER_CODES.ALL,
    totalCount: 0,
  });
  const groupTypeList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);

  const [groupListPanelData, setGroupListPanelData] = useState({
    isMessageWindowVisible: false,
    selectedConversation: {} as IConversationData,
  });
  const [selectedTaskDetail, setSelectedTaskDetail] = useState<{
    show: boolean;
    task?: ITask;
  }>({
    show: false,
  });

  const contactInfo = getContactDataAndType(
    groupListPanelData?.selectedConversation || ({} as any)
  );
  const openTaskDetailView = (task: ITask) => {
    setSelectedTaskDetail({
      show: true,
      task: task,
    });
  };
  const [loadingData, setLoadingData] = useState<{
    loading: boolean;
    taskIdWithAction?: any;
  }>({
    loading: false,
  });
  const [formResponseTableState, setFormResponseTableState] = useState<{
    isDrawerVisible: boolean;
    selectedRow?: any;
  }>({
    isDrawerVisible: false,
    selectedRow: undefined,
  });
  const [selectedViewCode, setSelectedViewCode] = useState(
    APPOINTMENT_BOOKING_VIEW_CODES.BOOKING_VIEW
  );
  const [selectedEvents, setSelectedEvents] = useState({} as any);
  const contactData: any = contactInfo?.contactData || ({} as any);
  const todayStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'all_assigned'
  );
  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  /**
   * getDoTodayTasksQuery Query
   */
  const [getDoTodayTasks] = useLazyQuery(TaskQueries.GET_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getSubmittedFormResponse] = useLazyQuery(
    FormsQueries.GET_LAST_SUBMITTED_FORM,
    {
      fetchPolicy: 'no-cache',
      variables: {
        fromDate: moment().startOf('day'),
        toDate: moment().endOf('day'),
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [getContactDetails] = useLazyQuery(
    ContactsQueries.getContactListByUuids,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const fetchData = async () => {
    const submittedFormResponses = await getSubmittedFormResponse();
    const formattedFormResponse = getFormattedSubmittedFormList(submittedFormResponses?.data);
    setTaskDetail((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    Promise.all([
      fetchDueTodayTask(),
      fetchAppointments(),
      getContactNameWithFormdata(formattedFormResponse),
    ]).then((responses) => {
      const finalDueTaskList = [] as any;
      const dueTodayTaskList = (responses[0]?.data?.getTasks?.tasks || []).filter(
        (data: any) => data?.status?.code !== 'completed',
      );
      let todayAppointmentList = (responses[1]?.data?.appointments || []);
      const unreadConversationList = (responses[2]?.data?.conversations || []).filter((conversation: any) => {
        return !!conversation?.unreadMessages?.length;
      });
      const formList = responses[2] || [];
      todayAppointmentList = todayAppointmentList.filter((item: any) => {
        return !item?.isBlockedInterval
      });
      todayAppointmentList.filter((item: any) => {
        if (!item?.isBlockedInterval) {
          finalDueTaskList.push({
            type: 'appointment',
            taskList: item,
            id: uuidV4(),
          });
        }
      });
      const totalCount =
        dueTodayTaskList.length +
        finalDueTaskList.length +
        unreadConversationList.length +
        formList.length;
      dueTodayTaskList.forEach((item: any) => {
        item.assignedBy = item.assignedByUser;
        finalDueTaskList.push({
          type: 'task',
          taskList: item,
          id: uuidV4(),
        });
      });

      unreadConversationList.forEach((item: any) => {
        finalDueTaskList.push({
          type: 'conversation',
          taskList: item,
          id: uuidV4(),
        });
      });
      formList.forEach((item: any) => {
        finalDueTaskList.push({
          type: 'form',
          taskList: item,
          id: uuidV4(),
        });
      });
      setTaskDetail((prev) => {
        return {
          ...prev,
          isLoading: false,
          finalDueTaskAllList: finalDueTaskList,
          selectedFilterCode: FILTER_CODES.ALL,
          task: dueTodayTaskList,
          appointmentEvents: todayAppointmentList,
          conversationList: unreadConversationList,
          formListData: formList,
          totalCount,
        };
      });
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const getContactNameWithFormdata = async (formattedFormResponse: any) => {
    const allContactIds = (formattedFormResponse || []).map((item: any) => {
      return item?.contactId
    })
    const contactData = await getContactName(allContactIds);
    if (contactData) {
      const finalData: any = []
      formattedFormResponse.map((item: any) => {
        contactData.map((contactItem: any) => {
          if (item.contactId === contactItem.uuid) {
            const contactWithForm = {
              ...item,
              contactName: contactItem?.name || '',
            }
            finalData.push(contactWithForm)
          }
        })
      })
      return finalData;
    }
    return formattedFormResponse;
  }

  const getContactName = async (allContactIds: []) => {
    const response = await getContactDetails({
      variables: {
        contactUuidList: allContactIds,
      },
    });
    return response?.data?.contacts || [];
  }

  const [getBookedAppointments] = useLazyQuery(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: any) => {
        if (data && data.appointments && data.appointments.length > 0) {
          const indexMap: any = {};
          const appointments = (data?.appointments || []).sort(
            (
              appointment1: IAppointmentDetail,
              appointment2: IAppointmentDetail
            ) => {
              const date1: any = new Date(appointment1.startDateTime);
              const date2: any = new Date(appointment2.startDateTime);
              return date1 - date2;
            }
          );
          const updatedEvents = appointments.map(
            (
              appointment: IAppointmentDetail,
              index: number
            ): ICalendarEvent => {
              const startDateStr = moment(appointment.startDateTime).format(
                'YYYY/MM/DD'
              );
              if (!indexMap[startDateStr]) {
                indexMap[startDateStr] = 0;
              }
              indexMap[startDateStr] = indexMap[startDateStr] + 1;
              return {
                id: appointment.id,
                //title: getAppointmentName(appointment, indexMap[startDateStr]),
                dayIndex: indexMap[startDateStr],
                start: getDateObject(appointment.startDateTime),
                end: getDateObject(appointment.endDateTime),
                statusCode: getMlovCodeFromId(
                  appointmentStatusList,
                  appointment.statusId
                ),
                detail: appointment,
                primaryContactName: getPrimaryContactName(appointment),
                locationCode:
                  appointment.appointmentType?.locationType?.code || '',
                resourceId: getAppointmentUserId(appointment),
              };
            }
          );
          return updatedEvents;
        } else {
          setTaskDetail((prev) => ({
            ...prev,
            events: [],
            isLoading: false,
          }));
        }
      },
      onError: (error: any) => {

      },
    }
  );

  const fetchAppointments = async () => {
    const userIdList: string[] = [];
    const dateRange = getDateRangeForCalendar('day', new Date());
    const appointmentStatusIds = appointmentStatusList
    .filter(
      (status) => ![
        APPOINTMENT_STATUS_CODES.CANCELLED, APPOINTMENT_STATUS_CODES.DECLINED, APPOINTMENT_STATUS_CODES.NO_SHOW,
      ].includes(status.code)
    )
    .map(
      (status) => status.id
    );
    userIdList.push(currentUserId);

    return getBookedAppointments({
      variables: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        userIds: userIdList,
        statusIds: appointmentStatusIds,
        //accountLocationIds: [],
      },
    });
  };

  const fetchDueTodayTask = () => {
    const paramsObject: any = {
      taskDueDateCategoryId: todayStatusUuid,
      assigneeIds: [currentUserId],
      priorityIds: [],
      contactIds: [],
      statusIds: [],
      timezone: getCurrentTimeZone(),
    };
    return getDoTodayTasks({
      variables: {
        params: paramsObject,
      },
    });
  };

  const onFilterActionPerformed = (code: string) => {
    setTaskDetail((prev) => ({
      ...prev,
      isLoading: true,
      finalDueTaskAllList: [],
    }));
    switch (code) {
      case FILTER_CODES.ALL:
        const finalDueTaskList: any[] = [];
        taskDetail.task.forEach((item: any) => {
          finalDueTaskList.push({
            type: 'task',
            taskList: item,
            id: uuidV4(),
          });
        });
        taskDetail.appointmentEvents.forEach((item: any) => {
          finalDueTaskList.push({
            type: 'appointment',
            taskList: item,
            id: uuidV4(),
          });
        });
        taskDetail.conversationList.forEach((item: any) => {
          finalDueTaskList.push({
            type: 'conversation',
            taskList: item,
            id: uuidV4(),
          });
        });
        taskDetail.formListData.forEach((item: any) => {
          finalDueTaskList.push({
            type: 'form',
            taskList: item,
            id: uuidV4(),
          });
        });

        setTaskDetail((prev) => {
          return {
            ...prev,
            finalDueTaskAllList: finalDueTaskList,
            selectedFilterCode: FILTER_CODES.ALL,
            isLoading: false,
          };
        });

        break;
      case FILTER_CODES.APPOINTMENTS:
        const appointmentList = taskDetail.appointmentEvents.map((item) => {
          return {
            type: 'appointment',
            taskList: item,
            id: uuidV4(),
          };
        });
        setTaskDetail((prev) => {
          return {
            ...prev,
            finalDueTaskAllList: appointmentList,
            selectedFilterCode: FILTER_CODES.APPOINTMENTS,
            isLoading: false,
          };
        });
        break;
      case FILTER_CODES.FORMS:
        const formList = taskDetail.formListData.map((item: any) => {
          return {
            type: 'form',
            taskList: item,
            id: uuidV4(),
          };
        });
        setTaskDetail((prev) => {
          return {
            ...prev,
            finalDueTaskAllList: formList,
            selectedFilterCode: FILTER_CODES.FORMS,
            isLoading: false,
          };
        });
        break;
      case FILTER_CODES.MESSAGES:
        const messageList = taskDetail.conversationList.map((item: any) => {
          return {
            type: 'conversation',
            taskList: item,
            id: uuidV4(),
          };
        });
        setTaskDetail((prev) => {
          return {
            ...prev,
            finalDueTaskAllList: messageList,
            selectedFilterCode: FILTER_CODES.MESSAGES,
            isLoading: false,
          };
        });
        break;

      case FILTER_CODES.TASKS:
        const taskList = taskDetail.task.map((item: any) => {
          return {
            type: 'task',
            taskList: item,
            id: uuidV4(),
          };
        });
        setTaskDetail((prev) => {
          return {
            ...prev,
            finalDueTaskAllList: taskList,
            selectedFilterCode: FILTER_CODES.TASKS,
            isLoading: false,
          };
        });
        break;
    }
  };

  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
      fetchData();
      setSelectedTaskDetail({
        show: false,
        task: undefined,
      });
      showToast(
        toast,
        intl.formatMessage({id: 'taskCompletedMsg'}),
        ToastType.success,
      );
    },
    onError: (error: any) => {

      showErrorPopup();
    },
  });

  const showErrorPopup = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error,
    );
  };

  const handleBottomViewActions = (
    task: ITask,
    action: BottomViewAction,
  ) => {
    setLoadingData((prev) => ({
      ...prev,
      taskIdWithAction: task.id,
    }));
    switch (action) {
      case BottomViewAction.markAsComplete:
        setLoadingData({
          taskIdWithAction: task.id,
          loading: true,
        });
        updateTaskStatus({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: completedStatusId,
              },
            }
          },
        });
        break;

      default:
        break;
    }
  };

  const renderItem = ({item, index}: {item: any; index: number}) => (
    <TaskDueTodaylListItem
      key={index+item?.id}
      data={item}
      onActionPerformed={(data: any) => {
        if (data.type === 'conversation') {
          setGroupListPanelData({
            isMessageWindowVisible: !groupListPanelData.isMessageWindowVisible,
            selectedConversation: data.taskList || ({} as any),
          });
        } else if (data.type === 'task') {
          openTaskDetailView(data.taskList);
        } else if (data.type === 'appointment') {
          setSelectedViewCode(APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW);
          setSelectedEvents({
            detail: data.taskList,
          });
        } else if (data.type === 'form') {
          setFormResponseTableState((prev) => {
            return {
              ...prev,
              selectedRow: data.taskList,
              isDrawerVisible: true,
            };
          });
        }
      }}
    />
  );
  return (
    <div className="display_card_container" style={{height: '100%'}}>
      <header style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Title level={5} className="display_card_title" style={{height: 10}}>
            {'Your day has ' + taskDetail.totalCount + ' items for you!'}
          </Title>
        </div>
      </header>
      <HStack
        width={'100%'}
        borderBottomWidth={0.3}
        borderBottomColor={Colors.Custom.Gray300}
      ></HStack>

      <VStack
        width={'100%'}
      >
        <HStack
          flex={1}
          alignItems={'center'}
          alignSelf={'stretch'}
          width={'100%'}
          style={styles.filterContainer}
        >
          <Pressable
            onPress={() => {
              onFilterActionPerformed(FILTER_CODES.ALL);
            }}
            style={
              taskDetail.selectedFilterCode == FILTER_CODES.ALL
                ? styles.filterTabSelected
                : styles.filterTab
            }
          >
            <Text
              width={'50px'}
              fontSize={14}
              fontWeight={600}
              style={
                taskDetail.selectedFilterCode == FILTER_CODES.ALL
                  ? styles.selectedText
                  : styles.filterTabText
              }
            >
              {'All'}
            </Text>
          </Pressable>

            <Pressable
              onPress={() => {
                onFilterActionPerformed(FILTER_CODES.APPOINTMENTS);
              }}
              style={
                taskDetail.selectedFilterCode == FILTER_CODES.APPOINTMENTS
                  ? styles.filterTabSelected
                  : styles.filterTab
              }
            >
              <View
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text
                  fontSize={14}
                  fontWeight={600}
                  style={
                    taskDetail.selectedFilterCode == FILTER_CODES.APPOINTMENTS
                      ? styles.selectedText
                      : styles.filterTabText
                  }
                >
                  {'Appointments'}
                </Text>
                <View style={styles.tabBudgeView}>
                  <Text
                    fontSize={12}
                    fontWeight={600}
                    style={styles.tabBudgeText}
                  >
                    {taskDetail.appointmentEvents.length}
                  </Text>
                </View>
              </View>
            </Pressable>

            <Pressable
              onPress={() => {
                onFilterActionPerformed(FILTER_CODES.TASKS);
              }}
              style={
                taskDetail.selectedFilterCode == FILTER_CODES.TASKS
                  ? styles.filterTabSelected
                  : styles.filterTab
              }
            >
              <View
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text
                  fontSize={14}
                  fontWeight={600}
                  style={
                    taskDetail.selectedFilterCode == FILTER_CODES.TASKS
                      ? styles.selectedText
                      : styles.filterTabText
                  }
                >
                  {'Tasks'}
                </Text>
                <View style={styles.tabBudgeView}>
                  <Text
                    fontSize={12}
                    fontWeight={600}
                    style={styles.tabBudgeText}
                  >
                    {taskDetail.task.length}
                  </Text>
                </View>
              </View>
            </Pressable>

            {/* <Pressable
              onPress={() => {
                onFilterActionPerformed(FILTER_CODES.MESSAGES);
              }}
              style={
                taskDetail.selectedFilterCode == FILTER_CODES.MESSAGES
                  ? styles.filterTabSelected
                  : styles.filterTab
              }
            >
              <View
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text
                  fontSize={14}
                  fontWeight={600}
                  style={
                    taskDetail.selectedFilterCode == FILTER_CODES.MESSAGES
                      ? styles.selectedText
                      : styles.filterTabText
                  }
                >
                  {'Messages'}
                </Text>
                <View style={styles.tabBudgeView}>
                  <Text
                    fontSize={12}
                    fontWeight={600}
                    style={styles.tabBudgeText}
                  >
                    {taskDetail.conversationList.length}
                  </Text>
                </View>
              </View>
            </Pressable> */}

            <Pressable
              onPress={() => {
                onFilterActionPerformed(FILTER_CODES.FORMS);
              }}
              style={
                taskDetail.selectedFilterCode == FILTER_CODES.FORMS
                  ? styles.filterTabSelected
                  : styles.filterTab
              }
            >
              <View
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text
                  fontSize={14}
                  fontWeight={600}
                  style={
                    taskDetail.selectedFilterCode == FILTER_CODES.FORMS
                      ? styles.selectedText
                      : styles.filterTabText
                  }
                >
                  {'Forms'}
                </Text>

                <View style={styles.tabBudgeView}>
                  <Text
                    fontSize={12}
                    fontWeight={600}
                    style={styles.tabBudgeText}
                  >
                    {taskDetail.formListData.length}
                  </Text>
                </View>
              </View>
            </Pressable>
          </HStack>

        <VStack>
          {taskDetail.isLoading ? (
            <View style={{padding: 12}}>
              <Skeleton.Text lines={5} />
            </View>
          ) : (
            <VStack style={styles.listContainer}>
              <FlatList
                ListEmptyComponent={
                  <Center height={'100%'} flex={1}>
                    <Empty />
                  </Center>
                }
                disableVirtualization
                height={'550px'}
                contentContainerStyle={{flexGrow: 1, overflow: 'scroll'}}
                data={taskDetail.finalDueTaskAllList}
                keyExtractor={(item: any) => {
                  return '_' + item?.id;
                }}
                renderItem={renderItem}
              />
            </VStack>
          )}
        </VStack>

        {isWeb() && (
          <Drawer
            title={
              <MessagingWindowHeader
                moduleCode={'TaskDashboard'}
                conversation={groupListPanelData.selectedConversation || {}}
                contactData={contactData}
                onActionPerformed={() => {
                  // onActionPerformed
                }}
                isDisplayActions={false}
              />
            }
            visible={groupListPanelData.isMessageWindowVisible}
            width={'40%'}
            onClose={() => {
              setGroupListPanelData({
                isMessageWindowVisible:
                  !groupListPanelData.isMessageWindowVisible,
                selectedConversation: {} as any,
              });
            }}
          >
            <MessagingWindow
              moduleCode={`MessagingWindow/${CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET}`}
              isInDrawerView={true}
              isDisplayHeader={false}
              selectedConversation={groupListPanelData.selectedConversation}
              conversationInbox={
                groupListPanelData.selectedConversation?.conversationInbox
              }
              onConversationActionPerformed={() => {}}
              selectedInboxTypeCode={CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET}
            />
          </Drawer>
        )}

      {selectedTaskDetail.show && (
        <AddOrUpdateTask
          task={selectedTaskDetail.task}
          isVisible={selectedTaskDetail.show}
          assignee={{
            value: selectedTaskDetail?.task?.assigneeId || currentUserId || '',
            label: currentUserName,
            key: selectedTaskDetail?.task?.assigneeId || currentUserId || '',
            type:
              (selectedTaskDetail?.task?.assigneeTypeCode as ParticipantType) ||
              (TASK_ASSIGNEE_TYPE_CODES.User as ParticipantType),
            details: selectedTaskDetail.task?.assigneeUser,
          }}
          onCancel={() => {
            setSelectedTaskDetail({
              show: false,
              task: undefined,
            });
          }}
          onMarkAsComplete={(task:ITask,action:BottomViewAction) => {
            handleBottomViewActions(task,action)}
          }
          onComplete={(
            data: any,
            category?: string,
            existCategory?: string
          ) => {
            setSelectedTaskDetail({
              show: false,
              task: undefined,
            });
            fetchData();
          }}
        />
      )}

        {selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW && (
          <AppointmentDetail
            //hideAction={true}
            //isAppointmentSummary={true}
            reasonsForVisitList={[selectedEvents?.reasonForVisit]}
            event={selectedEvents}
            isVisible={
              selectedViewCode === APPOINTMENT_BOOKING_VIEW_CODES.SUMMARY_VIEW
            }
            onStatusChange={() => {
              //
            }}
            onClose={() => {
              setSelectedViewCode('');
            }}
            onComplete={() => {
              setSelectedViewCode('');
            }}
          />
        )}

        {formResponseTableState.isDrawerVisible && (
          <FormResponseView
            onClose={() => {
              setFormResponseTableState((prev) => {
                return {
                  ...prev,
                  selectedRow: undefined,
                  isDrawerVisible: false,
                };
              });
            }}
            contactId={formResponseTableState?.selectedRow?.contactId || ''}
            formId={formResponseTableState?.selectedRow?.id}
            formName={formResponseTableState?.selectedRow?.name}
            //contactName={personData.firstName}
          />
        )}
      </VStack>
    </div>
  );
};

export default TaskDueTodayDashboard;
