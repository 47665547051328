import {View} from "native-base";
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {QUALITY_MEASURES_PATIENT_TYPE} from "../../../../../../constants";
import {isEmployerRole} from "../../../../../../utils/commonUtils";
import CustomTabsView from "../../../../../common/CustomTabsView/CustomTabsView";
import {ITabsList} from "../../../../../common/CustomTabsView/interfaces";
import {TABLE_TOP_BAR_ACTION_CODES, TableTopBar} from "../../../../../common/TableTopBar";
import TitleSubtitleView from "../../../../../common/TitleSubtitleView/TitleSubtitleView";
import {getPathFromHash} from "../../../../ContentManagement/ContentManagementUtils";
import Programs from "../../Programs/Programs";
import QualityMeasures from "../QualityMeasures";
import OverviewAnalytics from "./OverviewAnalytics/OverviewAnalytics";
import PatientView from "./PatientView/PatientView";

const QualityMeasuresDetails = () => {
  const {contractId,qmId} = useParams();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const [stateData, setStateData] = useState({
    selectedActionCode: '',
    selectedRawData: {} as any,
  })
  const tabList = (): ITabsList[] => {
    const tabs: ITabsList[] = [
      {
        key: 'patients-qualified',
        title: 'Measure Met',
        path: 'patients-qualified',
        tabContainerElem: () => {
          return (
            <PatientView 
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED}
            />
          )
        }
      },
      {
        key: 'patients-unqualified',
        title: 'Measure Not Met',
        path: 'patients-unqualified',
        tabContainerElem: () => {
          return (
            <PatientView 
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED}
            />
          )
        }
      }
    ]
    return tabs
  }
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any,
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode,
            selectedRawData: actionData,
          };
        });
      break;
    }
  };
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    if ( selectedTab.key === 'programs') {
      return false
    }
    return  <TableTopBar
    selectedTab={selectedTab.key}
    title=""
    alignSearchBarFlexEnd={true}
    hStackMinHight={70}
    isDividerNotVisible={true}
    onActionPerformed={(actionCode: string, actionData: any) => {
      onTableTopBarActionPerformed(actionCode, actionData);
    }}
  />
  }
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab)
  }
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tabList().findIndex((item) => item.path === path);
    setSelectedTab(tabList()[tabIndex]);
  }, [])
  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'contracts'}
        />
      </View>
      <View>
        <CustomTabsView
          showBackButton={isEmployerRole() ? false : true}
          contentTopMargin={0}
          showHeading={true}
          basePath={`/members/contracts/${contractId}/qualityMeasures/${qmId}`}
          tabsList={tabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
    </View>
  )
}

export default QualityMeasuresDetails;
