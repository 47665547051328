import { Drawer } from 'antd';
import { VStack, useToast } from 'native-base';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { useSearchParams } from 'react-router-dom';
import { BUTTON_TYPE } from '../../../../../constants';
import CMSService from '../../../../../services/CommonService/CMSService';
import { Colors } from '../../../../../styles';
import { findIsAllowToRunAutomation, getAccountUUID } from '../../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { AutomationWorkflowEmployee } from '../../../MembersManagement/AutomationWorkflowEmployee';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { createCampaignSegment } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import WorkflowAuditFilter from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowAuditFilter';
import { EmailGraphByTag } from '../../../Workflow/Workflow/WorkflowTableView/Helper/EmailGraphByTag';
import { SmsGraphByTag } from '../../../Workflow/Workflow/WorkflowTableView/Helper/SmsGraphByTag';
import WorkflowPersonLevelEmailInfoByExecutionId from '../../../Workflow/Workflow/WorkflowTableView/Helper/WorkflowPersonLevelEmailInfoByExecutionId';
import AddOrUpdateCampaign from '../AddOrUpdateCampaign/AddOrUpdateCampaign';
import { AnalyticsGraphData, CampaignAnalyticsProps } from '../interfaces';

const CampaignAnalytics = (props: CampaignAnalyticsProps) => {
  const { data, isOpen, onClose } = props;
  const name = props?.data?.name || '';
  const [isOpenModel, setOpenModal] = useState(false);
  const [contactList, setContactList] = useState<any>([]);
  const tenantId = getAccountUUID();
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: null,
  });



  const buttonList = [
    ...(findIsAllowToRunAutomation()
      ? [
        {
          show: true,
          id: 2,
          btnText: 'runAutomation',
          actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
          resourceCode: MAIN_MENU_CODES.AUTOMATION,
          textColor: Colors.Custom.mainPrimaryPurple,
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: BUTTON_TYPE.PRIMARY,
          isTransBtn: false,
          onClick: () => {
            setOpenModal(true);
          },
        },
      ]
      : []),
    {
      show: true,
      id: 3,
      btnText: 'Create Campaign',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        setContactList((prev: any) => {
          if (!prev.length) {
            return;
          }
          createCampaignSegment({
            name: '',
            tenantId: tenantId,
            metadata: {
              patientFilter: {
                contactIds: prev.map((contact: any) => {
                  return contact.id ? parseInt(contact.id) : null;
                }).filter((id: any) => { return id != null }),
              },
            },
          }).then((resp) => {
            if (resp?.id) {
              setCampaignDrawerState({
                isVisible: true,
                segmentId: resp?.id,
              });
            }
          });
          return prev;
        });
      },
    },
  ];

  const [state, setState] = useState({
    graphData: {} as AnalyticsGraphData,
    loading: true,
    noEmailsSent: false,
    error: false,
  });

  const cmsInstance = CMSService.getCMSServiceInstance();
  const cmsService = cmsInstance.cmsAxiosService;
  const capitlizeFirst = (str: string) => {
    // checks for null, undefined and empty string
    if (!str) return;
    return str.match('^[a-z]')
      ? str.charAt(0).toUpperCase() + str.substring(1)
      : str;
  };

  const [searchParams, setSearchParams] = useSearchParams({});



  const [selectedTab, setSelectedTab] = useState<any>({ key: 'OVERVIEW' });
  const [isHideEmailGraph, setIsHideEmailGraph] = useState(false);
  const [isHideSmsGraph, setIsHideSmsGraph] = useState(false);
  const toast = useToast()

  useEffect(()=>{
    setSearchParams({});

  }, [selectedTab, isOpen]);


  const tabList = [

    {
      key: 'RESULT',
      title: 'Result',
      tabContainerElem: () => {

        return (
          // <WorkflowExecutionGraph />
          <WorkflowPersonLevelEmailInfoByExecutionId
            setSelectedList={(selectedList: any) => {
              const contactList: any = [];
              selectedList.forEach((log: any) => {
                (log?.resourceLevelLog || []).forEach(
                  (resource: any) => {
                    contactList.push({ id: resource?.resourceId });
                  }
                );
              });

              setContactList(contactList);
            }}
            isShowCheckbox={true}
            total={5}
            campaignId={data.id}
            channelType={data?.channelType}
            userPracticeLocations={props.userPracticeLocations ?? []}
            foldLocations={props.foldLocations ?? []}
          ></WorkflowPersonLevelEmailInfoByExecutionId>
        );
      },
    },
  ]
  if(data?.channelType  == 'EMAIL'){
      tabList.push({
        key: 'EMAIL_OVERVIEW',
        title: 'Email statistics',
        tabContainerElem: () => {
          return (
            <VStack margin={0} height={600} width='100%' overflow={'visible'}  >

            <VStack marginY={5}>
              <WorkflowAuditFilter isAddUniqueEmailStatusFilter={true} isShowEmailStatus={true}  contactList={[]} isDateMandatory={false} intersectedLocationList= { props.userPracticeLocations }
              ></WorkflowAuditFilter>
            </VStack>
            {
              !isHideEmailGraph  && <VStack flex={5} width={'full'} paddingX={5} key={(searchParams.get('emailStatus') || '') +  (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')}>
                <EmailGraphByTag isDateMandatory={false} key={(searchParams.get('emailStatus') || '') + (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')} setIsHideEmailGraph={(value: any) => {
                  // setIsHideEmailGraph(value)
                }} tagData={ {tag:data.id || '', tagType: 'campaignId'}} intersectedLocationIdList= { props.userPracticeLocations }></EmailGraphByTag>
              </VStack>
            }
          </VStack>
          );
        },
      })
  }
  if(data?.channelType  == 'SMS'){
    tabList.push({
      key: 'SMS_OVERVIEW',
      title: 'SMS statistics',
      tabContainerElem: () => {
        return (
          <VStack margin={0} height={600} width='100%' overflow={'visible'}  >

            <VStack marginY={5}>
              <WorkflowAuditFilter isAddUniqueEmailStatusFilter={true} isShowEmailStatus={false} isShowSmsStatus={true} contactList={[]} isDateMandatory={false} intersectedLocationList= { props.userPracticeLocations }></WorkflowAuditFilter>
            </VStack>
            {
              !isHideSmsGraph  && <VStack flex={5} width={'full'} paddingX={5} key={(searchParams.get('smsStatus') || '') +  (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')}>
                <SmsGraphByTag isDateMandatory={false} key={(searchParams.get('smsStatus') || '') + (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')} setIsHideSmsGraph={(value: any) => {
                  // setIsHideSmsGraph(value)
                }} tagData={ {tag:data.id || '', tagType: 'campaignId'} } locationIds= { props.userPracticeLocations }></SmsGraphByTag>
              </VStack>
            }
          </VStack>
        );
      },
    })
  }
  return (
    <>
      <Drawer
        open={isOpen && !campaignDrawerState?.isVisible}
        key={state.loading + '-isLoad'}
        onClose={()=>{
          onClose()
        }}
        width={'75vw'}
        title={
          <ModalActionTitle
            title={name || ''}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'Close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onClose();

                },
              }
            ]}
          />
        }
      >
        <CustomTabsView
          onTabClick={(data) => {
            setSelectedTab(data);
          }}
          tabsList={tabList}
          rightView={() => {
            return selectedTab.key == 'RESULT' && contactList.length ? (
              <ModalActionTitle
                isHeadNotSticky={true}
                title={null}
                titleColor={''}
                buttonList={buttonList}
              />
            ) : (
              <></>
            );
          }}
        />
      </Drawer>

      <Drawer
        visible={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            segmentId: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={(status) => {
              if (status != 'cancel') {
                showToast(
                  toast,
                  ((status == 'created') ? 'Campaign created successfully.' : 'Campaign updated successfully.'),
                  ToastType.success,
                );
              }
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });

            }}
          />
        ) : null}
      </Drawer>

      <Drawer
        visible={isOpenModel}
        width={700}
        onClose={() => setOpenModal(false)}
        closable={true}
        title={<></>}
      >
        <AutomationWorkflowEmployee
          tenantId={tenantId}
          cancelBtnTxt={'Back'}
          initData={{ selectedItems: contactList }}
          onModalClose={(shouldTriggerModal?: boolean) => {
            setOpenModal(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default CampaignAnalytics;
