import {View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {FlowType} from '../../../context/WorkflowContext';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import AllMembersView from '../../common/MemebersView/AllMembersView';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../common/TableTopBar';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import {MAIN_MENU_CODES, updateLeadTabsList} from '../../SideMenuBar/SideBarConst';
import {
  getBulkActionTopBarButton,
  getLeadListTopBarButtons,
} from '../Contacts/Leads/LeadView/LeadTableView/Helper/TopBarButtons';
import CampaignManagementSegmentView from '../ContentManagement/CampaignManagement/CampaignManagementSegmentView';
import {
  isAutomationAllowed,
  USER_ACCESS_PERMISSION,
} from '../UserAccess/UserAccessPermission';
import {Colors} from '../../../styles';
import {BUTTON_TYPE, PERSON_TYPES} from '../../../constants';
import { TAB_KEYS } from '../constants';
import { FilterDataContext } from '../../../context/FilterDataContext';
import { POPULATION_FILTER_TYPE } from '../ContentManagement/CampaignManagement/constants';
import { getFilterDataByType } from '../../../utils/FilterUtils';
import ImportContactLogView from '../Contacts/ImportLog/ImportContactLogView';
import { useLocation } from 'react-router-dom';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getContactTypeId} from '../../../utils/mlovUtils';
import {Member_Screen_Tab_Code} from '../../../screens/MembersScreens/MemberScreenConst';
import {useQuery} from '@apollo/client';
import {MlovQueries} from '../../../services';
import {getAccountUUID} from '../../../utils/commonUtils';
import {IContactTypes} from '../Contacts/CustomField/interface';

const AllLeadsView = () => {
  const basePath = '/members';
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const [stateData, setStateData] = useState({
    showBulkActionTopBar: false,
    selectedViewCode: '',
    searchString: '',
    filtersApplied: [],
    selectedTagsLabels: [],
    personType: '',
    selectedActionCode: '',
    selectedRawData: {} as any,
    selectedExtraData: {} as any,
    buttonList: [],
    selectedGroups: [],
    contactTypeId: '',
    customContactTypes: [] as IContactTypes[],
  });
  const accountUuid = getAccountUUID();
  const mlovData = useContext(CommonDataContext);
  const defaultContactTypes = useQuery(MlovQueries.GetAllDefaultContactTypes, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: accountUuid,
    },
  });
  const customContactTypes = stateData?.customContactTypes;
  const filteredContactTypes = customContactTypes?.filter?.((contactType: IContactTypes) => {
    return (contactType?.isDefault || contactType?.isActive) && contactType?.code !== PERSON_TYPES.CUSTOMER && contactType?.code !== PERSON_TYPES.LEAD;
  })
  const leadTypeUuid = getContactTypeId(
    Member_Screen_Tab_Code.PROSPECTS
  );

  const [browserZoomLevel, setZoomLevel] = useState(window?.devicePixelRatio);

  useEffect(() => {
    const updateZoomLevel = () => {
      setZoomLevel(window?.devicePixelRatio);
    };
    window.addEventListener('resize', updateZoomLevel);
    window.addEventListener('wheel', updateZoomLevel);

    return () => {
      window.removeEventListener('resize', updateZoomLevel);
      window.removeEventListener('wheel', updateZoomLevel);
    };
  }, []);

  useEffect(() => {
    const customContactTypes = defaultContactTypes?.data?.customContactTypes || mlovData?.defaultContactTypes?.customContactTypes;
    setStateData((prev) => {
      return {
        ...prev,
        customContactTypes: customContactTypes
      }
    })
    updateLeadTabsList(customContactTypes);
  },[defaultContactTypes?.data])

  const shouldHideConvertToPatient = (contactType: any) => {
    if (contactType?.can_convert_to_patient) {
      return false;
    } else if (!contactType?.can_convert_to_patient) {
      if (contactType?.isDefault) {
        return false;
      } else {
        return true;
      }
    }
  }

  const getContactTypesTabs = () => {
    const tabs: ITabsList[] = [];
    filteredContactTypes?.forEach((contactType: any) => {
      tabs.push({
        key: 'prospect',
        title: contactType?.value,
        path: contactType?.id,
        tabContainerElem: () => {
          return (
            <AllMembersView
              contactType="prospect"
              selectedActionCode={stateData.selectedActionCode}
              selectedRawData={stateData.selectedRawData}
              selectedExtraData={stateData.selectedExtraData}
              onActionPerformed={onActionPerformed}
              contactTypeId={contactType?.id}
              tab={'prospect'}
              hideConvertToPatient={shouldHideConvertToPatient(contactType)}
            />
          );
        },
      } as ITabsList)
    })
    return tabs;
  }

  const filterContext = useContext(FilterDataContext);
  const populationContextData = getFilterDataByType(filterContext, POPULATION_FILTER_TYPE)

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'prospect',
        title: 'Leads',
        path: leadTypeUuid,
        tabContainerElem: () => {
          return (
            <AllMembersView
              contactType="prospect"
              selectedActionCode={stateData.selectedActionCode}
              selectedRawData={stateData.selectedRawData}
              selectedExtraData={stateData.selectedExtraData}
              onActionPerformed={onActionPerformed}
              contactTypeId={leadTypeUuid}
              tab={'prospect'}
              hideConvertToPatient={false}
            />
          );
        },
      },
      ...getContactTypesTabs(),
      {
        key: 'lead_groups',
        title: 'Lead Groups',
        path: 'lead_groups',
        tabContainerElem: () => {
          return (
            <CampaignManagementSegmentView
              selectedActionCode={stateData.selectedActionCode}
              selectedRawData={stateData.selectedRawData}
              onActionPerformed={onActionPerformed}
            />
          );
        },
      },
      {
        key: 'importSummary',
        path: 'importSummary',
        title: 'Bulk Import Summary',
        tabContainerElem: () => {
          return (
            <ImportContactLogView/>
          );
        },
      }
    ];
    return array;
  };
  const onActionPerformed = (
    tabCode: any,
    rawData?: any,
    extraData?: any
  ): any => {
    switch (tabCode) {
      case COMMON_ACTION_CODES.RESET:
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: '',
            selectedRawData: '',
            selectedExtraData: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.BULK_EMAIL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.BULK_SMS:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_GROUP:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CLEAR_MAIL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        const showBulkActionTopBar = rawData.length > 0 ? true : false;
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            showBulkActionTopBar: showBulkActionTopBar,
          };
        });
        break;
      case COMMON_ACTION_CODES.ADD:
        const contactType: any = {
          id: 1,
          contactType: stateData?.personType,
          contactTypeUuid: stateData?.contactTypeId,
          label: stateData?.personType == Member_Screen_Tab_Code.PATIENTS
              ? 'Add Members'
              : 'Add Lead',
        };
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: contactType,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.IMPORT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        if(selectedTab.key === TAB_KEYS.LEAD_GROUPS){
          savePopulationGroupFilterData(rawData?.searchString || '')
        }
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.SAVE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: '',
            filtersApplied: rawData,
          };
        });
        break;
      case 'BUTTON_LIST':
        setStateData((prev) => {
          return {
            ...prev,
            buttonList: rawData,
          };
        });
        break;
      case COMMON_ACTION_CODES.SELECT_GROUP:
        setStateData((prev) => {
          return {
            ...prev,
            selectedGroups: rawData
          };
        });
        break;

      default:
        break;
    }
    return;
  };
  const downloadButtonClick = () => {
    onActionPerformed(COMMON_ACTION_CODES.IMPORT, {});
  };
  const createLeadButtonClick = () => {
    onActionPerformed(COMMON_ACTION_CODES.EDIT, {});
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.SEARCH, actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH, actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW, {
          selectedViewCode: 'FILTER_VIEW',
        });
        break;
    }
  };

  const savePopulationGroupFilterData = (searchString: string) => {
    const filterData = {
      type: POPULATION_FILTER_TYPE,
      searchString: searchString
    }
    filterContext?.saveFilterData?.(filterData)
  }


  const showAutomationTab = isAutomationAllowed();

  const getTopBarButtons = () => {
    let buttonList;
    if (selectedTab.key !== TAB_KEYS.IMPORT_SUMMARY) {
      buttonList = getLeadListTopBarButtons(
        [downloadButtonClick, createLeadButtonClick],
        onActionPerformed,
        '',
        stateData.personType || '',
        stateData.selectedTagsLabels,
        {}
      )
      return buttonList;
    } else {
      buttonList = {
        buttonList: [],
        title: ''
      }
    }
    return buttonList;
  }

  const isHideSearchBar = () => {
    let isHideSearchBar = false;
    if (selectedTab.key === TAB_KEYS.IMPORT_SUMMARY) {
      isHideSearchBar = true;
    }
    return isHideSearchBar;
  }

  const isShowFilterIcon = () => {
    let showFilterIcon = true;
    if (selectedTab.key === TAB_KEYS.IMPORT_SUMMARY) {
      showFilterIcon = false;
    }
    return showFilterIcon;
  }

  const getTopBarCode = () => {
    let tableTopbarEle: any = false;
    let placeHolderText = 'Search';
    if (selectedTab.key === 'patient' || selectedTab.key === 'prospect') {
      placeHolderText = 'Search by name, email, or phone';
    }
    if (stateData.showBulkActionTopBar) {
      tableTopbarEle = (
        <>
          <TableTopBar
            alignSearchBarFlexEnd={true}
            isDividerNotVisible={true}
            hStackMinHight={'auto'}
            placeHolderText={placeHolderText}
            searchInputStyle={{minWidth: 340}}
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            topContainerStyle={{borderRadius: 0}}
            hideSearchBar={false}
            {...getBulkActionTopBarButton(
              [
                onBulkEmailClicked,
                onBulkEmailSms,
                onCLearAllMail,
                onCreateCampaign,
                onCreateAutomation,
                onCreateGroup,
              ],
              onActionPerformed,
              //mainTableState.topBarTitle
              '',
              showAutomationTab
            )}
          />
        </>
      );
    } else {
      tableTopbarEle = (
        <TableTopBar
          placeHolderText={placeHolderText}
          isDividerNotVisible={true}
          alignSearchBarFlexEnd={true}
          hStackMinHight={'auto'}
          searchInputStyle={{width: 340}}
          topContainerStyle={{borderRadius: 0}}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          {...getTopBarButtons()}
          hideSearchBar={isHideSearchBar()}
          showFilterIcon={isShowFilterIcon()}
          appliedFilters={stateData.filtersApplied}
        />
      );
    }
    return tableTopbarEle;
  };
  const onBulkEmailClicked = () => {
    onActionPerformed(COMMON_ACTION_CODES.BULK_EMAIL);
  };
  const onBulkEmailSms = () => {
    onActionPerformed(COMMON_ACTION_CODES.BULK_SMS);
  };
  const onCLearAllMail = () => {
    onActionPerformed(COMMON_ACTION_CODES.CLEAR_MAIL);
  };
  const onCreateCampaign = () => {
    onActionPerformed(COMMON_ACTION_CODES.CREATE_CAMPAIGN);
  };
  const onCreateAutomation = () => {
    onActionPerformed(COMMON_ACTION_CODES.CREATE_AUTOMATION);
  };
  const onCreateGroup = () => [
    onActionPerformed(COMMON_ACTION_CODES.CREATE_GROUP),
  ];
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab.key === 'patient' || selectedTab.key === 'prospect' || selectedTab.key === TAB_KEYS.IMPORT_SUMMARY) {
      ele = getTopBarCode();
    } else if (selectedTab.key === 'lead_groups') {
      ele = (
        <TableTopBar
          title=""
          selectedTab={selectedTab.key}
          showFilterIcon={true}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          searchText={populationContextData?.searchString || ''}
          appliedFilters={populationContextData?.appliedFilters || stateData.filtersApplied}
          buttonList={[...stateData.buttonList]}
        />
      );
    }
    return ele;
  };
  const onTabClick = (tab: ITabsList) => {
    const contactTypeId = tab?.tabContainerElem?.().props?.contactTypeId
    setSelectedTab(tab);
    setStateData((prev) => {
      return {
        ...prev,
        personType:
          tab.key == 'patient'
            ? 'CUSTOMER'
            : tab.key == 'prospect'
            ? 'LEAD'
            : '',
        filtersApplied: contactTypeId ? prev?.filtersApplied : [],
        contactTypeId: contactTypeId,
        showBulkActionTopBar: false,
      };
    });
  };
  useEffect(() => {
    const tagsList = tagsTabList();
    tagsList.some((tab: any) => {
      if (location?.pathname?.indexOf(tab.path) != -1) {
    setSelectedTab(tab);
        setStateData((prev) => {
          return {
            ...prev,
            personType:
              tab.key == 'patient'
                ? 'CUSTOMER'
                : tab.key == 'prospect'
                ? 'LEAD'
                : '',
          };
        });
      }
    });
  }, [location?.pathname]);

  const getAutomationSettingsButtonVisibility = () => {
    if (
      stateData.showBulkActionTopBar &&
      (selectedTab.key === TAB_KEYS.PATIENT ||
        selectedTab.key === TAB_KEYS.MY_PATIENT ||
        selectedTab.key === TAB_KEYS.PROSPECT ||
        selectedTab.key === TAB_KEYS.IMPORT_SUMMARY)
    ) {
      return false;
    } else if (
      selectedTab.key === TAB_KEYS.LEAD_GROUPS ||
      stateData.selectedGroups?.length ||
      selectedTab.key === TAB_KEYS.IMPORT_SUMMARY
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <View flex={1}>
        <View style={{backgroundColor: '#fff'}}>
          <TitleSubtitleView />
        </View>
        <CustomTabsView
          contentTopMargin={0}
          showHeading={true}
          heading={selectedTab?.key}
          canNavigate={true}
          basePath={basePath}
          tabsList={tagsTabList()}
          extraBigRightView
          settingsPath="/admin/patients/tags"
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            paddingVertical: 16,
            backgroundColor: '#fff',
          }}
          minScreenSupportWidth={(browserZoomLevel < 2) ? 3000 : 2000}
          isAddMaxTab={true}
          leftOffset={['importSummary'].indexOf(selectedTab?.key) == -1 ? 950 : 300}
          hideAutomationSettingsButton={!getAutomationSettingsButtonVisibility()}
          selectedTab={selectedTab}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          onTabClick={(tabItem) => {
            if (tabItem?.key !== selectedTab?.key) {
              const searchObj = {
                searchString: tabItem.key === TAB_KEYS.LEAD_GROUPS && filterContext?.searchString ? filterContext?.searchString : ''
              }
              onTableTopBarActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH, searchObj);
            }
            onTabClick(tabItem);
          }}
          flowType={FlowType.patients}
        />
      </View>
    </>
  );
};

export default AllLeadsView;
