import React from 'react';
import {ICommonSvgProps} from './interfaces';

const MentionSvg = (props: ICommonSvgProps) => {
    const {customStrokeColor} = props;
    const strokeColor = customStrokeColor ? customStrokeColor : '#6F7A90';
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props?.width || 24}
        height={props?.height || 24}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={strokeColor}
          d="M12 21.5C12.2761 21.5 12.5 21.2761 12.5 21C12.5 20.7239 12.2761 20.5 12 20.5V21.5ZM20.4588 15.081L19.989 14.9099V14.9099L20.4588 15.081ZM19.7194 16.0905L20.0585 16.458L20.0585 16.458L19.7194 16.0905ZM19.8402 15.9791L19.5012 15.6116L19.5012 15.6116L19.8402 15.9791ZM16.6931 16.3288L16.4158 16.7448H16.4158L16.6931 16.3288ZM12 20.5C7.30558 20.5 3.5 16.6944 3.5 12H2.5C2.5 17.2467 6.75329 21.5 12 21.5V20.5ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5V2.5C6.75329 2.5 2.5 6.75329 2.5 12H3.5ZM12 3.5C16.6944 3.5 20.5 7.30558 20.5 12H21.5C21.5 6.75329 17.2467 2.5 12 2.5V3.5ZM20.5 12C20.5 13.023 20.3195 14.0028 19.989 14.9099L20.9286 15.2522C21.2985 14.237 21.5 13.1415 21.5 12H20.5ZM20.0585 16.458L20.1793 16.3466L19.5012 15.6116L19.3804 15.723L20.0585 16.458ZM16.1 14.2862V12H15.1V14.2862H16.1ZM16.4158 16.7448C17.5507 17.5014 19.056 17.3829 20.0585 16.458L19.3804 15.723C18.7172 16.3349 17.7213 16.4133 16.9705 15.9127L16.4158 16.7448ZM19.989 14.9099C19.8953 15.1671 19.7259 15.4043 19.5012 15.6116L20.1793 16.3466C20.4965 16.0539 20.7699 15.6879 20.9286 15.2522L19.989 14.9099ZM16.9705 15.9127C16.4267 15.5502 16.1 14.9398 16.1 14.2862H15.1C15.1 15.2742 15.5938 16.1968 16.4158 16.7448L16.9705 15.9127ZM15.1 12C15.1 13.7121 13.7121 15.1 12 15.1V16.1C14.2644 16.1 16.1 14.2644 16.1 12H15.1ZM12 15.1C10.2879 15.1 8.9 13.7121 8.9 12H7.9C7.9 14.2644 9.73563 16.1 12 16.1V15.1ZM8.9 12C8.9 10.2879 10.2879 8.9 12 8.9V7.9C9.73563 7.9 7.9 9.73563 7.9 12H8.9ZM12 8.9C13.7121 8.9 15.1 10.2879 15.1 12H16.1C16.1 9.73563 14.2644 7.9 12 7.9V8.9Z"
        ></path>
      </svg>
    );
  };
  
  export default MentionSvg;
  