
import BaseService from "../../../services/CommonService/BaseService";
import {AxiosResponse} from 'axios';
import {CUSTOM_FIELD_PATH} from "./CallExtensionApiPath";
import {IAddUpdateCallExtensionBody} from "./interface";

export const addUpdateCallExtension = async (addUpdateCallExtensionBody: IAddUpdateCallExtensionBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(CUSTOM_FIELD_PATH.CALL_EXTENSION, {
    ...addUpdateCallExtensionBody
  })
  return response;
};

export const getCallExtensionList = async (params: {
  pageNo: number,
  pageSize: number,
  searchString?: string,
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(CUSTOM_FIELD_PATH.CALL_EXTENSION, {
    params
  })
  return response;
};

export const deleteCallExtension = async (params: {id: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.delete(CUSTOM_FIELD_PATH.CALL_EXTENSION + "/" + params?.id)
  return response;
};

export const updateCallExtensionStatus = async (params: {
  id: string,
  isActive: boolean,
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(CUSTOM_FIELD_PATH.CALL_EXTENSION_UPDATE_STATUS + "/" + params?.id, {
    ...params
  })
  return response;
};