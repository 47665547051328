import { useLazyQuery, useQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { GET_FORM_CATEGORIES, GET_NOTES_FORM } from '../../../services/Forms/FormsQueries';
import { IFormListState } from '../../PersonOmniView/MiddleContainer/PatientNotes/components/DocumentationView';
import { EHRName } from '../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { getCurrentEHR } from '../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import { IFormCategory } from '../../RightSideContainer/Forms/interfaces';
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';

interface IProps {
  value?: IFormListState;

  allowClear?: boolean;
  placeholder?: string;

  onChange?: (data?: IFormListState) => void;
  locationId: string | undefined
}

interface IComponentState {
  searchString?: string;
  formList?: IFormListState[];
}

export function NoteFormTemplateSearch(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({});

  const currentEHR = getCurrentEHR(props?.locationId, '');
  const isFold = currentEHR === EHRName.FOLD;
  const isAthena = currentEHR === EHRName.ATHENA;

  const {loading: allCategoriesLoading} = useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    skip: !(isFold || isAthena),
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
    },
    onCompleted(data) {
      const noteCategoryId = data?.formCategories?.filter((category: IFormCategory) => category.code === 'SOAP')?.[0]?.id;
      fetchAllNoteFormTemplates({
        variables: {
          categoryId: noteCategoryId,
          searchString: '%%',
        },
      });
    },
  });

  const [ fetchAllNoteFormTemplates, { loading: fetchAllNoteFormTemplatesLoading, refetch: refetchAllNoteFormTemplates} ] =
    useLazyQuery(GET_NOTES_FORM, {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (data: any) => {
        setComponentState(prev => ({ ...prev, formList: data?.forms }));
      },
    });

  return (
    <Select
      allowClear={props.allowClear}
      showSearch={true}
      labelInValue={true}
      filterOption={(input: string, option: any) => {
        return (option?.children || '').toLowerCase().includes(input.toLowerCase());
      }}
      value={(props.value?.id && componentState.formList?.length) ? { value: props.value.id } : undefined}
      placeholder={props.placeholder || "Search template"}
      loading={allCategoriesLoading || fetchAllNoteFormTemplatesLoading}
      onChange={(value: any, data) => {
        const formId = data?.value;
        if (!formId) {
          return props.onChange?.(undefined);
        }
        const form = componentState.formList?.find((form) => form?.id === formId);
        return props.onChange?.(form);
      }}
      notFoundContent={(allCategoriesLoading || fetchAllNoteFormTemplatesLoading) && <Spin size="small" />}
    >
      {
        componentState.formList?.map((form) => {
          return (
            <Select.Option key={form.id} value={form.id}>
              {form.name}
            </Select.Option>
          );
        })
      }
      {
        (allCategoriesLoading || fetchAllNoteFormTemplatesLoading) && (
        <Select.Option key={`loading`} disabled>
          <Spin size="small" />
        </Select.Option>
      )}
    </Select>
  );
}
