import { Pressable, Text, View } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getBusinessStudioRoutes } from '../../../routes/BusinessStudioRoutes';
import { isChildAccount, isMasterAccount, IS_FEATURE_FLAG_ENABLED, getCaslAbility,isEnableCareProgram, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess } from '../../../utils/commonUtils';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import AccountTabsView from '../AccountTabsView/AccountTabsView';
import PatientsAdmin from '../Contacts/PatientsAdminPage/PatientsAdmin';
import WorkflowAdmin from '../Contacts/WorkflowAdminPage/WorkflowAdmin';
import { ContentManagement } from '../ContentManagement';
import { styles } from '../ContentManagement/ContentManagementStyle';
import { AddOrUpdateEmailComponent } from '../ContentManagement/EmailComponents/AddOrUpdateEmailComponent';
import {
  EmailTemplateCreator,
  EmailTemplateEditor
} from '../ContentManagement/EmailTemplates/CreateOrEditTemplate';
import { EmailHtmlEditorMainView } from '../ContentManagement/EmailTemplates/EmailHtmlEditor';
import { PatientEducation } from '../ContentManagement/PatientEducation';
import FormAnalytics from '../Forms/Analytics';
import AddOrUpdateForm from '../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateForm';
import FormResponsesList from '../Forms/FormBuilderWidget/FormResponses/FormResponsesList';
import PendingForms from '../Forms/FormBuilderWidget/PendingForms/PendingForms';

import { IntegrationConfig } from '../Integration';
import AddOrUpdateJourney from '../Journeys/AddOrUpdateJourney/AddOrUpdateJourney';
import PatientPortalView from '../PatientPortal/PatientPortalView';
import { Invoices } from '../Sales/Invoices/Invoices';
import MembershipAndSubscriptions from '../Sales/MembershipAndSubscriptions/MembershipAndSubscriptions';
import MembershipProductDetailMainView from '../Sales/MembershipAndSubscriptions/MembershipProducts/MembershipProductDetailMainView';
import TaxonomiesDeatailView from '../Sales/ProductsAndServices/Categories/TaxonomiesDetailView/TaxonomiesDeatailView';
import SubscriptionOrderDetailView from '../Sales/ProductsAndServices/Orders/OrderDetailView/MembershipOrderDetailView/SubscriptionOrderDetailView';
import ProductDetailMainView from '../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailMainView';
import ProductWidgetView from '../Sales/ProductsAndServices/Products/ProductWidgetView/ProductWidgetView';
import ProductsAndServices from '../Sales/ProductsAndServices/ProductsAndServices';
import SchedulingConfig from '../Scheduling/SchedulingConfig';
import TaskManagementView from '../TaskManagment/TaskManagementView';
import AdminCallSettings from '../TeamInbox/AdminCallSettings';
import AdminCommunication from '../TeamInbox/AdminCommunication';
import {JourneyAnalytics} from '../Journeys/Analytics';
import { IntegrationCreate } from '../TeamInbox/Integrations/IntegrationCreate/IntegrationCreate';
import { IntegrationEditWidget } from '../TeamInbox/Integrations/IntegrationEdit/IntegrationEditWidget';
import {
  USER_ACCESS_PERMISSION,
} from '../UserAccess/UserAccessPermission';
import { SettingMenuObj } from './interfaces';
import CostTemplatesMainView from '../Analytics/EmployerAnalytics/CostTemplates/CostTemplatesMainView';
import TemplateDetailView from '../Analytics/EmployerAnalytics/CostTemplates/TemplateDetailView/TemplateDetailView';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import CareProgramBillingTabsView from '../CareManagementBilling/CareProgramBillingTabsView';
import WearablesSettingsView from '../WearableDataIngest/WearablesSettingsView';
import { permitState } from "permit-fe-sdk";
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';
import {usePermissions} from '../../CustomHooks/usePermissions';

const Setting = () => {
  const {height} = Dimensions.get('window');
  const intl = useIntl();
  const [selectedMenu, setSelectedMenu] = React.useState(0);
  const [sideComponent, setSideComponent] = useState(<></>);
  const [searchString, setSearchString] = useState('');
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const currentUserRoles = mlovData?.currentUserRoles
  const isGlobalAdmin = isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()

  const isMasterAccountFlag = isMasterAccount();
  const isChildAccountFlag = isChildAccount();
  const location = useLocation();
  const navigation = useNavigate();

  const useCurrentPath = () => {
    const [{route}]: any = matchRoutes(getBusinessStudioRoutes(), location);

    return route.path;
  };

  const pathPattern: string = useCurrentPath();
  const showMembershipTab =
    IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP')
  const showCostTemplateTab = !isAccountConfigEnabled(CONFIG_CODES.DISABLE_ANALYTICS) &&
                              isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API) && isGlobalAdmin;

  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  const settingMenu: SettingMenuObj[] = isMasterAccountFlag ?
          [
            ...(showCostTemplateTab ?[{
              title: 'costTemplate',
              defaultPath: '/admin/costTemplate/visitSaving',
              routes: [
                {
                  pathPattern: '/admin/costTemplate/:tabName',
                  component: <CostTemplatesMainView />,
                },
                {
                  pathPattern:
                    '/admin/costTemplate/:tabName/:costType/detail/:id',
                  component: <TemplateDetailView />,
                },
              ],
            }] : []),
            ...(isGlobalAdmin ? [{
              title: 'account',
              defaultPath :'/admin/account/teamMembers',
              routes:[{
                pathPattern: '/admin/account/teamMembers',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/accessControl',
                component: <AccountTabsView />,
              }]
            }] : [])
          ]  : [
      ...( isGlobalAdmin ? [{
        title: 'patientsLeads',
        defaultPath: '/admin/patients/tags',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.ADMIN_PATIENTS,
        routes: [
          {
            pathPattern: '/admin/patients/:tabName',
            component: <PatientsAdmin />,
          },
        ],
      }] : []),
      {
        title: 'calendar',
        defaultPath: '/admin/schedule/availability',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.SCHEDULE,
        routes: [
          {
            pathPattern: '/admin/schedule/:tabName',
            component: <SchedulingConfig />,
          },
        ],
      },
      {
        title: 'tasks',
        defaultPath: '/admin/taskManagement/task_pool',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.TASK_MANAGEMENT,
        routes: [
          {
            pathPattern: '/admin/taskManagement/:tabName',
            component: <TaskManagementView />,
          },
        ],
      },
      {
        title: 'messages',
        defaultPath: '/admin/communication/inbox',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.COMMUNICATION,
        routes: [
          {
            pathPattern: '/admin/communication/:tabName',
            component: <AdminCommunication />,
          },
          {
            pathPattern: '/admin/communication/inbox/create',
            component: <IntegrationCreate />,
          },
          {
            pathPattern: '/admin/communication/inbox/edit/:channel_id/:id',
            component: <IntegrationEditWidget />,
          },
        ],
      },
      {
        title: 'call',
        defaultPath: '/admin/call/number',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.CALL,
        routes: [
          {
            pathPattern: '/admin/call/ivr',
            component: <AdminCallSettings />,
          },
          {
            pathPattern: '/admin/call/number',
            component: <AdminCallSettings />,
          },
          {
            pathPattern: '/admin/call/communication_types',
            component: <AdminCallSettings />,
          },
          {
            pathPattern: '/admin/call/on_call_schedule',
            component: <AdminCallSettings />,
          },
          {
            pathPattern: '/admin/call/video_call',
            component: <AdminCallSettings />,
          },
          {
            pathPattern: '/admin/call/call_extension_configuration',
            component: <AdminCallSettings />,
          },
        ],
      },
      ...( isGlobalAdmin ? [{
        title: 'CRM Widgets',
        defaultPath: '/admin/webWidgets',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.WEB_WIDGETS,
        routes: [
          {
            pathPattern: '/admin/webWidgets',
            component: <PatientPortalView />,
          },
        ],
      }] : []),
      // ...(isGlobalAdmin ? [
      //   {
      //     title: 'integrations',
      //     defaultPath: '/admin/integration/config/access',
      //     routes: [
      //       {
      //         pathPattern: '/admin/integration/config/:tabName',
      //         component: <IntegrationConfig />,
      //       },
      //     ],
      //   }] : []
      // ),
      ...(isGlobalAdmin ? [
      {
        title: 'wearables',
        defaultPath: '/admin/wearables/accessConfiguration',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.WEARABLES,
        routes: [
          {
            pathPattern: '/admin/wearables/accessConfiguration',
            component: <WearablesSettingsView />,
          },
        ],
      }
      ] : []),
      {
        title: 'content',
        defaultPath: '/admin/contentManagement',
        actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        resourceCode: MAIN_MENU_CODES.CONTENT_MANAGEMENT,
        routes: [
          {
            pathPattern: '/admin/contentManagement',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/emailTemplates',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/emailComponent',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/mediaLibrary',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/patientEducation',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/pushNotifications',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/smsTemplates',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/cannedResponse',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/llmTemplates',
            component: <ContentManagement />,
          },
          {
            pathPattern:
              '/admin/contentManagement/emailTemplates/editor/:id',
            component: <EmailTemplateEditor shouldHideTitle={true} />,
          },
          {
            pathPattern: '/admin/contentManagement/emailComponent/edit/:id',
            component: <AddOrUpdateEmailComponent />,
          },
          {
            pathPattern: '/admin/contentManagement/emailComponent/create',
            component: <AddOrUpdateEmailComponent />,
          },
          {
            pathPattern: '/admin/contentManagement/emailTemplates/create',
            component: <EmailTemplateCreator shouldHideTitle={true} />,
          },
          {
            pathPattern: '/admin/contentManagement/emailTemplates/html',
            component: <EmailHtmlEditorMainView />,
          },
          {
            pathPattern: '/admin/contentManagement/emailTemplates/html/:id',
            component: <EmailHtmlEditorMainView />,
          },
          {
            pathPattern: '/admin/contentManagement/patientEducation/create',
            component: <PatientEducation isHideTopTab={true} />,
          },
          {
            pathPattern: '/admin/contentManagement/patientEducation/:id',
            component: <PatientEducation isHideTopTab={true} />,
          },
          {
            pathPattern: '/admin/contentManagement/forms',
            component: <ContentManagement />,
          },
          {
            pathPattern: '/admin/contentManagement/forms/create',
            component: <AddOrUpdateForm allowedLocationList={currentUserAllowedLocations} />,
          },
          {
            pathPattern: '/admin/contentManagement/forms/update',
            component: <AddOrUpdateForm allowedLocationList={currentUserAllowedLocations} />,
          },
          {
            pathPattern: '/admin/contentManagement/forms/responses',
            component: <FormResponsesList />,
          },
          {
            pathPattern: '/admin/contentManagement/forms/pending',
            component: <PendingForms />
            },
            {
              pathPattern: '/admin/contentManagement/forms/analytics',
              component: <FormAnalytics />
              },
        ],
      },
    // ...(showFormsTab
    //   ? [
    //       {
    //         title: 'forms',
    //         defaultPath: '/admin/forms/list',
    //         routes: [
    //           {
    //             pathPattern: '/admin/forms/:tabName',
    //             component: <FormsScreen />,
    //           },
    //           {
    //             pathPattern: '/admin/forms/create',
    //             component: <AddOrUpdateForm />,
    //           },
    //           {
    //             pathPattern: '/admin/forms/update',
    //             component: <AddOrUpdateForm />,
    //           },
    //           {
    //             pathPattern: '/admin/forms/responses',
    //             component: <FormResponsesList />,
    //           },
    //         ],
    //       },
    //     ]
    //   : []),

          {
            title: 'journey',
            defaultPath: '/admin/commerce/journeys/journeys',
            actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
            resourceCode: MAIN_MENU_CODES.CARE_JOURNEY,
            routes: [
              {
                pathPattern: '/admin/commerce/:type',
                component: <ProductsAndServices />,
              },
              // {
              //   pathPattern: '/admin/commerce/:type/order/:id',
              //   component: <SubscriptionOrderDetailView />,
              // },
              {
                pathPattern: '/admin/commerce/:type/:tabName',
                component: <ProductsAndServices />,
              },
              // {
              //   pathPattern: '/admin/commerce/:type/orders/payment/:number',
              //   component: <OrderDetailView />,
              // },
              {
                pathPattern: '/admin/commerce/:type/product/:id',
                component: <ProductDetailMainView />,
              },
              {
                pathPattern: '/admin/commerce/:type/product/createWidget',
                component: <ProductWidgetView />,
              },
              {
                pathPattern: '/admin/commerce/:type/taxonomy/:taxonmoyId',
                component: <TaxonomiesDeatailView />,
              },
              {
                pathPattern: '/admin/commerce/:type/product/:id/journey/create',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern:
                  '/admin/commerce/:type/product/:id/journey/:careJourneyId/clone/:clone',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern:
                  '/admin/commerce/:type/product/:id/journey/:careJourneyId',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern: '/admin/commerce/:type/product/:id/journey/create',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern:
                  '/admin/commerce/:type/product/:id/journey/:careJourneyId',
                component: <AddOrUpdateJourney />,
              },

            ],
          },
          {
            title: 'Automations',
            defaultPath: '/admin/workflow/all',
            actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
            resourceCode: MAIN_MENU_CODES.AUTOMATION,
            routes: [
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/all',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/patients',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/communication',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/other',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/microAutomation',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/schedule',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/forms',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/journey',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/labs',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/order',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/databaseTrigger'
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/note',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/tasks',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/imaging-report',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/patient-referral',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/patient-case',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/care-plan',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/vitals',
              },
            ],
          },
      ...(showCostTemplateTab && !isChildAccountFlag ? [
            {
              title: 'costTemplate',
              defaultPath: '/admin/costTemplate/visitSaving',
              routes: [
                {
                  pathPattern: '/admin/costTemplate/:tabName',
                  component: <CostTemplatesMainView />,
                },
                {
                  pathPattern:
                    '/admin/costTemplate/:tabName/:costType/detail/:id',
                  component: <TemplateDetailView />,
                },
              ],
            },
          ]
        : []),
    ...(showMembershipTab
      ? [
          {
            title: 'memberships',
            defaultPath: '/admin/membership/orders',
            actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
            resourceCode: MAIN_MENU_CODES.MEMBERSHIPS,
            routes: [
              {
                pathPattern: '/admin/membership',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/orders',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/products',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/paymentSetup',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/products/:id',
                component: <MembershipProductDetailMainView />,
              },
              {
                pathPattern: '/admin/membership/order/:id',
                component: <SubscriptionOrderDetailView />,
              },
            ],
          },
        ]
      : []),

    // {
    //   title: 'billing',
    //   defaultPath: '/admin/billing',
    //   routes: [
    //     {
    //       pathPattern: '/admin/billing',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/orders',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/plans',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/products',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/paymentSetup',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/order/:id',
    //       component: <BillingOrderDetailView />,
    //     },
    //   ],
    // },
    ...(isGlobalAdmin ? [
      {
        title: 'account',
        defaultPath: !isChildAccountFlag ?
        '/admin/account/teamMembers' :
        '/admin/account/locations' ,

        routes: [
          ...(!isChildAccountFlag
            ? [{
              pathPattern: '/admin/account/teamMembers',
              component: <AccountTabsView />,
            },
            {
              pathPattern: '/admin/account/accessControl',
              component: <AccountTabsView />,
            },
            ]
          : []),
          {
            pathPattern: '/admin/account/locations',
            component: <AccountTabsView />,
          },
          {
            pathPattern: '/admin/account/logs',
            component: <AccountTabsView />,
          },
          {
            pathPattern: '/admin/account/holidays',
            component: <AccountTabsView />,
          },
          {
            pathPattern: '/admin/account/mergedOrDelayed',
            component: <AccountTabsView />,
          },
          {
            pathPattern: '/admin/account/phoneWhiteList',
            component: <AccountTabsView />,
          },
          {
            pathPattern: '/admin/account/emailWhiteList',
            component: <AccountTabsView />,
          },

        ],
      }] : []
      ),
      {
        title: 'CareProgramBilling',
        defaultPath: '/admin/CareProgramBilling/billable',
        routes: [
          {
            pathPattern: '/admin/CareProgramBilling/billable',
            component: <CareProgramBillingTabsView />,
          },
          {
            pathPattern: '/admin/CareProgramBilling/billingHistory',
            component: <CareProgramBillingTabsView />,
          }
        ],
      },

      // ...(isBusinessOwner
      //   ? [
      //       {
      //         title: 'billing',
      //         defaultPath: '/admin/invoice',
      //         routes: [
      //           {
      //             pathPattern: '/admin/invoice',
      //             component: <Invoices />,
      //           },
      //         ],
      //       },
      //     ]
      //   : []),
  ];

  useEffect(() => {
    let sideComponent = settingMenu[0].routes[0].component;
    let selectedMenuIndex = 0;

    settingMenu.forEach((menu, index) => {
      menu.routes.forEach((route) => {
        if (route.pathPattern == pathPattern) {
          sideComponent = route.component;
          selectedMenuIndex = index;
        }
      });
    });

    setSelectedMenu(selectedMenuIndex);
    setSideComponent(sideComponent);
  }, [location.pathname]);
  const menuListItem = (menuItem: SettingMenuObj, index: number) => {
    return <Pressable
    onPress={() => {
      navigation(menuItem.defaultPath);
    }}
    style={{
      borderLeftWidth: selectedMenu == index ? 5 : 0,
      borderLeftColor: '#7F56D9',
      borderStyle: 'solid',
      borderTopWidth: !index ? 1 : 0,
      borderBottomWidth: 1,
      borderTopColor: '#EEEEEE',
      borderBottomColor: '#EEEEEE',
      paddingLeft: 25,
      paddingVertical: 15,
      backgroundColor: selectedMenu == index ? '#7F56D920' : '',
    }}
    _hover={{
      bg: '#7F56D920',
    }}
  >
    <Text
      style={{
        color: selectedMenu == index ? '#7F56D9' : '#000000',
      }}
      size="mdNormal"
    >
      {intl.formatMessage({id: menuItem.title})}
    </Text>
  </Pressable>
  }
  return (
    <View>
      <View style={styles.titleWrapper}>
        <TitleSubtitleView titleLabelId="Integration" />
      </View>
      <View
        style={{
          // padding: 16,
          display: 'flex',
          flexDirection: 'row',
          height: height - 60,
        }}
      >
        <View
          style={{
            width: 200,
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#D6D6D6',
            borderTopWidth: 0,
            overflow: 'scroll',
            borderBottomWidth: 0,
            height: height - 60,
          }}
        >
          {/* <View style={{padding: 10}}>
            <SearchBar
              onChange={(value: string) => {
                const searchString = value || '';
                setSearchString(searchString);
              }}
            />
          </View> */}
          {settingMenu
            ?.filter((menuItem) => {
              if (!searchString || !searchString?.trim?.()) {
                return true;
              }
              if (
                intl
                  .formatMessage({id: menuItem.title})
                  ?.toLowerCase()
                  ?.includes(searchString?.toLowerCase().trim())
              ) {
                return true;
              } else {
                return false;
              }
            })
            ?.map((menuItem, index) => {
              return (
                <>
                {menuItem.resourceCode &&
                <FoldPermitCan
                resource={menuItem?.resourceCode}
                action={menuItem.actionEvent || ''}
                key={index}
                component={menuListItem(menuItem, index)}
                />}
                {!menuItem.resourceCode && menuListItem(menuItem, index)}
                </>
              );
            })}
        </View>
        <View
          style={{
            height: height - 90,
            flex: 1,
            paddingVertical: 2,
            // marginLeft: 16,
            // backgroundColor: '#ffffff',
            // borderWidth: 1,
            // borderStyle: 'solid',
            // borderColor: '#D6D6D6',
            // borderRadius: 10,
            // overflow: 'scroll',
          }}
        >
          {sideComponent}
        </View>
      </View>
    </View>
  );
};
export default Setting;
