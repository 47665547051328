import {useLazyQuery} from '@apollo/client';
import {Drawer, Modal, Select, Spin, Tooltip} from 'antd';
import {debounce} from 'lodash';
import moment from 'moment';
import {Radio, Text, useMediaQuery, View, VStack, HStack, Button, Checkbox} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  IS_DEACTIVATE_CONTACT_ENABLED,
  LOGGED_IN_STATUS,
  MEMBER_STATUS,
  PERSON_TYPES,
} from '../../../constants';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  IAccountPracticeLocationResp,
} from '../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import TagQueries from '../../../services/Tags/TagQueries';
import {Colors} from '../../../styles';
import {getDisplayDateFormatted} from '../../../utils/DateUtils';
import {getContactTypeId, getMlovId} from '../../../utils/mlovUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {formatGetCategoryId} from '../../RightSideContainer/Contacts/Tags/Helper/formatTagsData';
import EmployerDropdownList from '../../RightSideContainer/ContentManagement/CampaignManagement/CampaignSegmentForm/EmployerDropdownList';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionDatePicker} from '../ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {styles} from './FilterDrawerViewStyle';
import { getAccountUUID, getFeatureFlag, getUserUUID, isChildAccount, isEnableCareProgram, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../utils/commonUtils';
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';
import {PATIENT_FILTERS_TYPE_STATUS} from '../../RightSideContainer/MembersManagement/MembersManagementHelper';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import {CustomFieldFilter} from '../../RightSideContainer/Contacts/CustomField/CustomFieldFilter';
import {ICustomAttributesFilters} from '../../RightSideContainer/Contacts/CustomField/interface';
import {useIntl} from 'react-intl';
import InfoSvg from '../Svg/InfoSvg';
import {MEMBER_FILTER_DRAWER_WIDTH} from '../../RightSideContainer/Contacts/CustomField/CustomFieldConst';
import CareManagerSearch from '../CareManagerSearch/CareManagerSearch';
import {trimSpace} from '../../RightSideContainer/Contacts/ContactsUtils';
const {Option} = Select;

const FilterDrawerView = (props: any) => {
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const prospectTypeUuid = getContactTypeId(PERSON_TYPES.LEAD);
  const customerTypeUuid = getContactTypeId(PERSON_TYPES.CUSTOMER)
  const isChildAccountFlag = isChildAccount();
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, mlovData);
  const isAllowedAllLocation =
    isLoginUserBusinessOwner() ||
    isLoggedInUserGlobalAdmin() ||
    isLoggedInUserWorkFlowOrCustomerSuccess();
  const allAccountLocationIds = mlovData.accountLocationListWithEHR
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const intl = useIntl();
  const [visible, setVisible] = useState(true);
  const [stateData, setStateData] = useState({
    contactTypeList: [] as any,
    customContactTypes: [] as any,
    labelsData: props.formData?.selectedLabelsData || [] as any,
    searchString: '',
    loading: false,
    userPracticeLocations: [] as any,
    showFilterWarning: false,
    resetTriggered: false,
    formData: props.formData
      ? props.formData
      : {
        contactTypeSelected: props.initialContactType[0].contactTypeUuid,
        contactLabel: [] as any,
        contactLabelName: [] as any,
        userPracticeLOcation: '',
        userPracticeLocationsName: [] as any,
        status: 'ALL' as string,
        loggedInStatus: 'ALL' as string,
        fromDate: '',
        toDate: '',
        employerIds: [] as any,
        employersName: [] as any,
        selectedLabelsData: [],
        selectedLoggedInData: '',
        selectedContactTypeId: '',
        selectedEmployeeType: [],
        customAttributes: [] as ICustomAttributesFilters[],
        careManagerId: '' as string | undefined,
        careManagerName: '',
      },
  });
  const disabledStartDate = (current: any) => {
    return current && (current > moment().endOf('day'));
  };
  const disabledEndDate = (current: any) => {
    if (stateData?.formData?.fromDate) {
      return current && (current > moment().endOf('day') || current < moment(stateData?.formData?.fromDate).endOf('day').subtract('1','day'));
    }
    return current && current > moment().endOf('day');
  };

  const selectedTagCategoryId: any = formatGetCategoryId(
    mlovData.MLOV.LabelType,
    props.tagType || 'Contact'
  );
  const [currentCategoryId, setCurrentCategoryId] = useState(selectedTagCategoryId);
  const [getUserPracticeLocationsByUserId] = useLazyQuery<IAccountPracticeLocationResp>(
    UserPracticeLocationQueries.GetUserPracticeLocationsByUserId(),
    {
      variables: {accountUuid: accountUuid},
      fetchPolicy: 'no-cache',
    }
  );

  const getAllCustomContactTypes = () => {
    const customContactTypes =
      mlovData?.defaultContactTypes?.customContactTypes || [];

    const filteredContactTypes = customContactTypes.filter((type: any) => {
      if (type.code) {
        return type.code !== PERSON_TYPES.CUSTOMER;
      }
      return true;
    });
    setStateData((prev) => {
      return {
        ...prev,
        customContactTypes: filteredContactTypes || [],
      };
    });
  };
  const selectedTypes= stateData?.formData?.selectedEmployeeType || [];

  const checkIfFilterSelected = () => {
    if (stateData?.formData?.status && stateData?.formData?.status != 'ACTIVE' ||
      stateData?.formData?.contactLabel?.length ||
      stateData?.formData?.userPracticeLOcation?.length ||
      stateData?.formData?.fromDate && stateData?.formData?.toDate ||
      stateData?.formData?.employerIds ||
      stateData?.formData?.selectedContactTypeId) {
      return true;
    } else {
      return false
    }
  }

  const onCloseAction = () => {
    setVisible(false);
    setStateData((prev) => {
      return {
        ...prev,
        formData: {},
      };
    });
    props.onActionPerformed('CANCEL', {});
  }

  const onClose = () => {
    if (checkIfFilterSelected()) {
      setStateData((prev) => {
        return {
          ...prev,
          showFilterWarning: true
        }
      })
    } else {
      onCloseAction()
    }
  };

  const onReset = () => {
    const formData = {selectedEmployeeType:[]}
    setStateData((prev) => {
      return {
        ...prev,
        formData: formData,
        resetTriggered: !stateData?.resetTriggered
      };
    });
  };

  const onSubmit = () => {
    // return;
    setVisible(false);
    const count: any[] = [];
    if (stateData?.formData?.status && stateData?.formData?.status != 'ALL') {
      count.push('Status: ' + stateData?.formData?.status);
    }
    if (
      stateData?.formData?.loggedInStatus &&
      stateData?.formData?.loggedInStatus != 'ALL'
    ) {
      LOGGED_IN_STATUS.map((status) => {
        if (status.value === stateData.formData.loggedInStatus) {
          count.push('Logged In Status: ' + status.label);
        }
      });
    }
    if (stateData?.formData?.contactLabel?.length) {
      count.push('Labels: ' + stateData?.formData?.contactLabelName?.join(', '));
    }
    if (stateData?.formData?.userPracticeLOcation?.length) {
      count.push('Location: ' + stateData?.formData?.userPracticeLocationsName?.join(', '));
    }
    if (stateData?.formData?.fromDate && stateData?.formData?.toDate) {
      count.push(
        'Date: ' +
        getDisplayDateFormatted(stateData?.formData?.fromDate) +
        ' to ' +
        getDisplayDateFormatted(stateData?.formData?.toDate)
      );
    }
    if (stateData?.formData?.employerIds) {
      count.push('Employers: ' + stateData?.formData?.employersName?.join(', '));
    }

    if (stateData?.formData?.contactTypeSelected && stateData?.formData?.contactTypeSelected?.id) {
      count.push('ContactType: ' + stateData?.formData?.contactTypeSelected?.value);
    }

    if (stateData?.formData?.selectedContactTypeId && stateData.formData.selectedContactTypeId.length > 0) {
      const contactTypeValue = stateData.customContactTypes.find((type: any) => {
        return type.id === stateData.formData.selectedContactTypeId;
      })
      count.push('ContactType: ' + contactTypeValue.value);
    }
    if (stateData?.formData?.selectedEmployeeType?.length) {
      count.push('Employee Type: ' + stateData?.formData?.selectedEmployeeType?.join(', '));
    }
    if (stateData?.formData?.customAttributes?.length) {
      const customAttributesName = stateData?.formData?.customAttributes?.filter((customAttribute: any)=> customAttribute?.customAttributeName)
      ?.map((customAttribute: any)=> customAttribute?.customAttributeName)
      if (customAttributesName?.length) {
        count.push(intl.formatMessage({id: 'customField'}) + customAttributesName.join(', '));
      }
    }
    if (stateData?.formData?.careManagerId) {
      count.push('Care Manager: ' + stateData.formData.careManagerName);
    }
    setTimeout(() => {
      props.onActionPerformed('SAVE', stateData.formData, count);
    }, 100);
  };
  const getUserPracticeLocations = async () => {
    const userPracticeLocations = await getUserPracticeLocationsByUserId({
      variables: {
        userId: userUuid
      }
    });
    setStateData((prev) => {
      return {
        ...prev,
        userPracticeLocations: isAllowedAllLocation ? allAccountLocationIds :
          userPracticeLocations.data?.accountLocations || [],
      };
    });
  };

  useEffect(() => {
    getUserPracticeLocations();
    getAllCustomContactTypes();
    setStateData((prev) => {
      return {
        ...prev,
        contactTypeList: props.initialContactType,
      };
    });
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const finalWidth = isIPadScreen || isIPadMiniScreen ? '40vw' : MEMBER_FILTER_DRAWER_WIDTH;

  const [getTagsBySearchString] = useLazyQuery(TagQueries.GetLabelsByFilter, {
    fetchPolicy: 'no-cache',
    onCompleted: (response: any) => {
      if (response.labels.length) {
        setStateData((prev) => {
          return {
            ...prev,
            labelsData: response.labels,
            loading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            labelsData: [],
            loading: false,
          };
        });
      }
    },
    onError: (error) => {
      setStateData((prev) => {
        return {
          ...prev,
          labelsData: [],
          loading: false,
        };
      });
    },
  })

  const onSearchText = () => {
    setStateData(prev => {
      return {
        ...prev,
        loading: true
      }
    })
    getTagsBySearchString({
      variables: {
        category: currentCategoryId,
        searchString: `%${stateData.searchString}%`,
      },
    })
  };

  const addOrDeleteTag = (value: any) => {
    let data = [];
    const contactLabel = stateData?.formData?.contactLabel || [];
    const valueAlreadyPresent = value.filter((tag: any) => contactLabel.indexOf(tag) === -1);
    const contactLabelAlreadyPresent = contactLabel.filter((tag: any) => value.indexOf(tag) === -1);
    const newTag = valueAlreadyPresent.concat(contactLabelAlreadyPresent);
    const ifExist = stateData?.formData?.selectedLabelsData?.some((label: any) => {
      return label.id === newTag[0]
    })
    if (ifExist) {
      const filteredList = stateData?.formData?.selectedLabelsData.filter((label: any) => {
        return label.id !== newTag[0]
      })
      data = filteredList
    } else {
      const selectedTagObj = stateData?.labelsData.filter((label: any) => {
        return label.id === newTag[0]
      })
      const selectedLabelsData = stateData?.formData?.selectedLabelsData || []
      data = [...selectedLabelsData, selectedTagObj[0]]
    }
    return data
  }


  useEffect(() => {
    if (stateData?.searchString.length) {
      onSearchText()
    }
  }, [stateData.searchString])

  return (
    <Drawer
      visible={visible}
      style={{minWidth: 700}}
      width={finalWidth}
      onClose={onClose}
      closable={false}
      title={
        <>
          <ModalActionTitle
            title={'filters'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'reset',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onReset();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'Apply',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack>
        {IS_DEACTIVATE_CONTACT_ENABLED &&
          props?.personTypeUuid === customerTypeUuid && (
            <VStack style={{marginTop: 12}}>
              <View style={[styles.filterTitle]}>
                <Text size={'lgSemibold'} color={'#000'}>
                  Filter by Status
                </Text>
              </View>
              <VStack style={{marginTop: 24}}>
                {
                  <>
                    <Radio.Group
                      style={{paddingLeft: 16}}
                      // colorScheme={'secondary'}
                      name="contactsType"
                      value={
                        stateData.formData.status
                          ? stateData.formData.status
                          : MEMBER_STATUS[2].value
                      }
                      onChange={(nextValue: any) => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              status: nextValue,
                            },
                          };
                        });
                      }}
                    >
                      <HStack space={6}>
                        {MEMBER_STATUS.map(
                          (contactType: any, index: number) => {
                            return (
                              <Radio
                                colorScheme=""
                                isHovered={false}
                                isFocusVisible={false}
                                isFocused={false}
                                key={index}
                                value={contactType.value}
                                style={{marginVertical: 10}}
                              >
                                <Text
                                  size={'smMedium'}
                                  color={Colors.Custom.Gray700}
                                  style={{marginLeft: 8}}
                                >
                                  {contactType.label}
                                </Text>
                              </Radio>
                            );
                          }
                        )}
                      </HStack>
                    </Radio.Group>
                  </>
                }
              </VStack>
            </VStack>
          )}
          {props?.personTypeUuid === customerTypeUuid && ( //Condition for this UI is not visible in Lead and contacts Drawer
           <VStack style={{marginTop: 12}}>
              <View style={[styles.filterTitle]}>
                <Text size={'lgSemibold'} color={'#000'}>
                Filter by Member Login Status
                </Text>
              </View>
              <VStack style={{marginTop: 24}}>
                {
                  <>
                    <Radio.Group
                      style={{paddingLeft: 16}}
                      name="contactsType"
                      value={
                        stateData.formData.loggedInStatus
                          ? stateData.formData.loggedInStatus
                          : LOGGED_IN_STATUS[0].value
                      }
                      onChange={(nextValue: any) => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              loggedInStatus: nextValue,
                            },
                          };
                        });
                      }}
                    >
                      <HStack space={6}>
                        {LOGGED_IN_STATUS.map(
                          (contactType: any, index: number) => {
                            return (
                              <Radio
                                colorScheme=""
                                isHovered={false}
                                isFocusVisible={false}
                                isFocused={false}
                                key={index}
                                value={contactType.value}
                                style={{marginVertical: 10}}
                              >
                                <Text
                                  size={'smMedium'}
                                  color={Colors.Custom.Gray700}
                                  style={{marginLeft: 8}}
                                >
                                  {contactType.label}
                                </Text>
                              </Radio>
                            );
                          }
                        )}
                      </HStack>
                    </Radio.Group>
                  </>
                }
              </VStack>
            </VStack>
           )}
        <VStack style={{marginTop: 12}}>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              Filter by Registered Date
            </Text>
          </View>

          {true && (
            <HStack mt={6} alignItems={'center'} space={6}>
              <ModalActionDatePicker
                label={'fromDate'}
                disabledDate={disabledStartDate}
                format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
                onChange={(date: any) => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      formData: {
                        ...prev.formData,
                        fromDate: date,
                      },
                    };
                  });
                }}
                extraStyle={{flex: 1}}
                placeholder="Select Date"
                value={
                  stateData.formData.fromDate &&
                  moment(stateData.formData.fromDate)
                }
              />
              <ModalActionDatePicker
                label={'toDate'}
                format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
                disabledDate={disabledEndDate}
                onChange={(date: any) => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      formData: {
                        ...prev.formData,
                        toDate: date,
                      },
                    };
                  });
                }}
                extraStyle={{flex: 1}}
                placeholder="Select Date"
                value={
                  stateData.formData.toDate && moment(stateData.formData.toDate)
                }
              />
            </HStack>
          )}
        </VStack>
        {props?.personTypeUuid === customerTypeUuid && !isChildAccountFlag  && (
        <VStack style={{marginTop: 24}}>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              {isVbcTenant ? 'Filter by Contract': 'Filter by Employer'}
            </Text>
          </View>
          <VStack style={{marginTop: 24}}>
            <EmployerDropdownList
              defaultValue={stateData?.formData?.employerIds}
              onChange={(actionData: any, extraData) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev.formData,
                      employerIds: actionData,
                      employersName: extraData,
                    },
                  };
                });
              }}
              isVbcTenant={isVbcTenant}
            />
          </VStack>
        </VStack>
        )}

        {props?.personTypeUuid === customerTypeUuid && isCareProgramEnabled && (
          isCareProgramEnabled &&
          <VStack style={{marginTop: 24}}>
            <View style={[styles.filterTitle]}>
              <Text size={'lgSemibold'} color={'#000'}>
                {intl.formatMessage({id: 'filterByAssignedWith'})}
              </Text>
            </View>
            <View style={{marginTop: 24}}>
              <CareManagerSearch
               value={stateData.formData.careManagerId} onChange={(uuid,name)  => {
                setStateData(prev => ({
                  ...prev,
                  formData: {
                    ...prev.formData,
                    careManagerId: uuid,
                    careManagerName: name
                  }
                }))
              }} />
            </View>
          </VStack>
        )}

        <VStack style={{marginTop: 24}}>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              Filter by Tag
            </Text>
          </View>
          <View style={{marginTop: 24}}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Search Tags"
              showSearch
              value={stateData?.formData?.contactLabel}
              filterOption={false}
              onChange={(value) => {
                const tagNames = value.map((tag: any) => {
                  const tagName = stateData.labelsData.find((label: any) => {
                    return label.id == tag
                  })
                  return tagName?.title || ''
                })
                const selectedTag = addOrDeleteTag(value)
                setStateData((prev) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev.formData,
                      contactLabel: value,
                      contactLabelName: tagNames,
                      selectedLabelsData: selectedTag
                    }
                  };
                });
              }}
              onSearch={debounce((value) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    searchString: value,
                  };
                });
              }, 500)}
              loading={stateData.loading}
              notFoundContent={stateData.loading && <Spin size="large" />}
              style={{width: '100%', }}
            >
              {stateData.labelsData.length && <>
                {stateData.labelsData?.map((label: any) => {
                  return <Option key={label?.id} value={label?.id}>
                    {label?.title}
                  </Option>
                })}
              </>}
            </Select>
          </View>
        </VStack>
        {/* --------------- */}



        <VStack style={{marginTop: 24}}>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              Filter by Location
            </Text>
          </View>
          <View style={{marginTop: 24}}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Search Location"
              showSearch
              filterOption={(input: string, option: any) => {
                return (option?.children || '')?.toLowerCase?.()?.includes(input?.toLowerCase());
              }}
              value={stateData.formData.userPracticeLOcation}
              onChange={(nextValue: any) => {
                const userPracticeLocationsName = nextValue.map((location: any) => {
                  const locationName = stateData?.userPracticeLocations?.find((userLocation: any) => {
                    return userLocation.uuid == location
                  })
                  return locationName?.practiceLocation?.name
                })
                setStateData((prev) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev.formData,
                      userPracticeLOcation: nextValue,
                      userPracticeLocationsName: userPracticeLocationsName
                    },
                  };
                });
              }}
              loading={stateData.loading}
              notFoundContent={stateData.loading && <Spin size="large" />}
              style={{width: '100%', }}
            >
              {stateData?.userPracticeLocations.length && <>
                {stateData?.userPracticeLocations?.map((userLocation: any) => {
                  return <Option key={userLocation?.id} value={userLocation?.uuid}>
                    {userLocation?.practiceLocation?.name}
                  </Option>
                })}
              </>}
            </Select>
          </View>
        </VStack>

        {props?.personTypeUuid === customerTypeUuid  && !isChildAccountFlag && <VStack style={{marginTop: 12}}>
              <View style={[styles.filterTitle]}>
                <Text size={'lgSemibold'} color={'#000'}>
                  Filter by Employee Type
                </Text>
              </View>
              <HStack space={6} style={{marginTop: 24}}>
              {PATIENT_FILTERS_TYPE_STATUS?.map((type)=>{
                return (
                  <Checkbox
                    key={type.value}
                    value={type.value}
                    style={{marginVertical: 10}}
                    alignItems={'center'}
                    isChecked={stateData?.formData?.selectedEmployeeType?.includes(type.value)}
                    onChange={(isChecked) => {
                        if (isChecked) {
                          if (!selectedTypes.includes(type.value)) {
                            selectedTypes.push(type.value);
                          }
                        } else {
                          if (selectedTypes.includes(type.value)) {
                            selectedTypes.splice(selectedTypes.indexOf(type.value), 1);
                          }
                        }
                      setStateData((prev) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            selectedEmployeeType: selectedTypes
                          }
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={{marginLeft: 8}}
                    >
                      {type?.text}
                    </Text>
                  </Checkbox>
                )
              })}
              </HStack>
        </VStack>}

        <VStack style={{marginTop: 12}}>
          <View style={[styles.filterTitle]}>
            <HStack alignItems={'center'} space={2}>
              <Text size={'lgSemibold'}>
                {intl.formatMessage({id: 'filterByCustomField'})}
              </Text>
              <Tooltip
                title={intl.formatMessage({id: 'customFiledFilterMsg'})}
                placement={'top'}
                destroyTooltipOnHide={true}
              >
                <>
                  <InfoSvg customColor={Colors.Custom.Gray500} customDimension={'20'} />
                </>
              </Tooltip>
            </HStack>
          </View>
          <HStack space={6} style={{marginTop: 12}}>
            <CustomFieldFilter
              customAttributes={trimSpace(stateData?.formData?.customAttributes)}
              onReset={stateData?.resetTriggered}
              onDetailChange={(customAttributes: ICustomAttributesFilters[])=> {
                setStateData((prev) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev?.formData,
                      customAttributes: customAttributes
                    }
                  }
                })
              }}
            />
          </HStack>
        </VStack>

      </VStack>
      <Modal title="Confirmation" open={stateData?.showFilterWarning}
        onCancel={() => {
          setStateData((prev) => {
            return {
              ...prev,
              showFilterWarning: false
            }
          })
        }}
        footer={null}
      >
        <View marginX={4}>
          <DisplayText size='mdMedium' textLocalId={'filterRemoveMsg'} />
          <View marginBottom={6}></View>
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onCloseAction()
                },
              }}
              customProps={{
                btnText: 'Yes',
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      showFilterWarning: false
                    }
                  })
                },
              }}
              customProps={{
                btnText: 'No',
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>
    </Drawer>
  );
};

export default FilterDrawerView;
