import {offset} from "jodit/esm/core/helpers";
import { IAuditLogData } from "./Hooks/interface";
import {ISearchContactFilter, SortDirection} from "../../../Contacts/Leads/LeadView/LeadTableView/LeadListView/interface";
import {MemberStatus} from "./GroupDetailMainView";
interface ISingleData {
  [key: string]: IAuditLogData[];
}

export const groupAuditDataByTimeStamp = (feedDataList: IAuditLogData[]) => {
    return feedDataList?.reduce((singleData: ISingleData, singleFeedDataObj) => {
      const date = singleFeedDataObj.createdOn || '';
      const key = new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      });
      if (!singleData[key]) {
        singleData[key] = [];
      }
      singleData[key].push(singleFeedDataObj);
      return singleData;
    }, {});
  };

export const getContactListSearchFilter = (args: {
  limit: number;
  offset: number;
  ruleId: string;
  orderBy?: {
    name?: SortDirection;
    created_at?: SortDirection;
    updated_at?: SortDirection;
  },
  groupContactFilter?:{
    isActive?: boolean;
    selectedCampaign: string;
    selectedWorkflow: string;
    hintStatus: string[];
  }
}) => {
  const {limit, offset, ruleId, orderBy, groupContactFilter} = args;
  const searchContactFilter = {
    limit: limit,
    offset: offset,
    orderBy: orderBy ? orderBy : {
      updated_at: 'desc',
    },
    ruleId: ruleId,
  } as ISearchContactFilter;
  if (groupContactFilter?.isActive != undefined) {
    searchContactFilter.isActive =  groupContactFilter.isActive
  }
  if (groupContactFilter?.selectedWorkflow) {
    searchContactFilter.workflowMasterIdsNotIn = [groupContactFilter?.selectedWorkflow]
  }
  if (groupContactFilter?.selectedCampaign) {
    searchContactFilter.campaignIdsNotIn = [groupContactFilter?.selectedCampaign]
  }
  if (groupContactFilter?.hintStatus?.length) {
    searchContactFilter.hintMembershipStatusCodes = groupContactFilter?.hintStatus
  }
  return searchContactFilter;
};

export const getActiveStatus = (memberStatus: string) => {
  let isActive;
  if (memberStatus == MemberStatus.ACTIVE) {
    isActive = true;
  }
  if (memberStatus == MemberStatus.INACTIVE) {
    isActive = false;
  }
  return isActive
};
  export const RESOURCE_TYPE_CODES = {
    ALL: 'ALL',
    FORM: 'FORM',
    JOURNEY: 'JOURNEY',
  }

  export const parentResourceCodeFilters = [
    {
      code: RESOURCE_TYPE_CODES.ALL,
      value: 'All'
    },
    {
      code: RESOURCE_TYPE_CODES.FORM,
      value: 'Form'
    },
    {
      code: RESOURCE_TYPE_CODES.JOURNEY,
      value: 'Journey'
    }
  ]