import React from 'react';
import {Colors} from '../../../styles';

const HardRefreshIconSvg = (props: {isActive?: boolean , iconKey : any, referral?: boolean}) => {

  return (
    props.referral ? (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.95337 7.55556H2.45337V7.55556L2.95337 7.55556ZM2.95337 8.66667L2.60121 9.02161C2.79615 9.21502 3.11059 9.21502 3.30553 9.02161L2.95337 8.66667ZM4.42541 7.9105C4.62143 7.716 4.62268 7.39942 4.42818 7.20339C4.23369 7.00737 3.91711 7.00612 3.72108 7.20062L4.42541 7.9105ZM2.18566 7.20062C1.98963 7.00612 1.67305 7.00737 1.47856 7.20339C1.28406 7.39942 1.28531 7.716 1.48133 7.9105L2.18566 7.20062ZM12.9105 4.92853C13.0552 5.16377 13.3631 5.23723 13.5983 5.09261C13.8336 4.94799 13.907 4.64005 13.7624 4.40481L12.9105 4.92853ZM14.0428 7.33333L14.3942 6.97772C14.1995 6.7852 13.8861 6.7852 13.6913 6.97772L14.0428 7.33333ZM12.5671 8.08883C12.3707 8.28295 12.3689 8.59953 12.563 8.79593C12.7571 8.99233 13.0737 8.99417 13.2701 8.80006L12.5671 8.08883ZM14.8154 8.80006C15.0118 8.99417 15.3284 8.99233 15.5225 8.79593C15.7166 8.59953 15.7148 8.28295 15.5184 8.08883L14.8154 8.80006ZM4.04545 11.0707C3.90043 10.8358 3.59237 10.7628 3.35737 10.9078C3.12237 11.0529 3.04943 11.3609 3.19446 11.5959L4.04545 11.0707ZM8.55274 1.5C5.18786 1.5 2.45337 4.20747 2.45337 7.55556H3.45337C3.45337 4.76714 5.73273 2.5 8.55274 2.5V1.5ZM2.45337 7.55556L2.45337 8.66667L3.45337 8.66667L3.45337 7.55556L2.45337 7.55556ZM3.30553 9.02161L4.42541 7.9105L3.72108 7.20062L2.60121 8.31173L3.30553 9.02161ZM3.30553 8.31173L2.18566 7.20062L1.48133 7.9105L2.60121 9.02161L3.30553 8.31173ZM13.7624 4.40481C12.6915 2.66285 10.7584 1.5 8.55274 1.5V2.5C10.3991 2.5 12.0153 3.4723 12.9105 4.92853L13.7624 4.40481ZM8.42198 14.5C11.7969 14.5 14.5428 11.7943 14.5428 8.44444H13.5428C13.5428 11.2311 11.2556 13.5 8.42198 13.5V14.5ZM14.5428 8.44444V7.33333H13.5428V8.44444H14.5428ZM13.6913 6.97772L12.5671 8.08883L13.2701 8.80006L14.3942 7.68894L13.6913 6.97772ZM13.6913 7.68894L14.8154 8.80006L15.5184 8.08883L14.3942 6.97772L13.6913 7.68894ZM3.19446 11.5959C4.26953 13.338 6.20952 14.5 8.42198 14.5V13.5C6.56699 13.5 4.94406 12.5269 4.04545 11.0707L3.19446 11.5959Z" fill="#6F7A90" />
      </svg>
    ) :
    props.isActive? (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.45313 7.55556L1.95313 7.55556L1.95313 7.55556L2.45313 7.55556ZM2.45313 8.66667L2.10096 9.02161C2.2959 9.21502 2.61035 9.21502 2.80529 9.02161L2.45313 8.66667ZM3.92516 7.9105C4.12119 7.716 4.12243 7.39942 3.92794 7.20339C3.73345 7.00737 3.41687 7.00612 3.22084 7.20062L3.92516 7.9105ZM1.68541 7.20062C1.48939 7.00612 1.17281 7.00737 0.978312 7.20339C0.783819 7.39942 0.785062 7.716 0.98109 7.9105L1.68541 7.20062ZM12.4103 4.92853C12.5549 5.16377 12.8629 5.23723 13.0981 5.09261C13.3333 4.94799 13.4068 4.64005 13.2622 4.40481L12.4103 4.92853ZM13.5425 7.33333L13.894 6.97772C13.6992 6.7852 13.3858 6.7852 13.191 6.97772L13.5425 7.33333ZM12.0669 8.08883C11.8705 8.28295 11.8686 8.59953 12.0628 8.79593C12.2569 8.99233 12.5734 8.99417 12.7698 8.80005L12.0669 8.08883ZM14.3152 8.80005C14.5116 8.99417 14.8282 8.99233 15.0223 8.79593C15.2164 8.59953 15.2146 8.28295 15.0182 8.08883L14.3152 8.80005ZM3.54521 11.0707C3.40019 10.8358 3.09212 10.7628 2.85712 10.9078C2.62213 11.0529 2.54919 11.3609 2.69421 11.5959L3.54521 11.0707ZM8.0525 1.5C4.68762 1.5 1.95313 4.20747 1.95313 7.55556H2.95313C2.95313 4.76715 5.23248 2.5 8.0525 2.5V1.5ZM1.95313 7.55556L1.95313 8.66667L2.95313 8.66667L2.95313 7.55556L1.95313 7.55556ZM2.80529 9.02161L3.92516 7.9105L3.22084 7.20062L2.10096 8.31173L2.80529 9.02161ZM2.80529 8.31173L1.68541 7.20062L0.98109 7.9105L2.10096 9.02161L2.80529 8.31173ZM13.2622 4.40481C12.1913 2.66285 10.2581 1.5 8.0525 1.5V2.5C9.89885 2.5 11.515 3.4723 12.4103 4.92853L13.2622 4.40481ZM7.92174 14.5C11.2966 14.5 14.0425 11.7943 14.0425 8.44444H13.0425C13.0425 11.2311 10.7554 13.5 7.92174 13.5V14.5ZM14.0425 8.44444V7.33333H13.0425V8.44444H14.0425ZM13.191 6.97772L12.0669 8.08883L12.7698 8.80005L13.894 7.68894L13.191 6.97772ZM13.191 7.68894L14.3152 8.80005L15.0182 8.08883L13.894 6.97772L13.191 7.68894ZM2.69421 11.5959C3.76929 13.338 5.70928 14.5 7.92174 14.5V13.5C6.06674 13.5 4.44381 12.5269 3.54521 11.0707L2.69421 11.5959Z" fill={`url(#${props.iconKey + '1'})`}/>
    <defs>
    <linearGradient id={props.iconKey + '1'} x1="7.99996" y1="-7.42" x2="-6.89423" y2="3.02848" gradientUnits="userSpaceOnUse">
    <stop stop-color="#D2EBEF"/>
    <stop offset="1" stop-color="#F5E4FE"/>
    </linearGradient>
    </defs>
    </svg>
    ): (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.45313 7.55556H1.95313V7.55556L2.45313 7.55556ZM2.45313 8.66667L2.10096 9.02161C2.2959 9.21502 2.61035 9.21502 2.80529 9.02161L2.45313 8.66667ZM3.92516 7.9105C4.12119 7.716 4.12243 7.39942 3.92794 7.20339C3.73345 7.00737 3.41687 7.00612 3.22084 7.20062L3.92516 7.9105ZM1.68541 7.20062C1.48939 7.00612 1.17281 7.00737 0.978312 7.20339C0.783819 7.39942 0.785062 7.716 0.98109 7.9105L1.68541 7.20062ZM12.4103 4.92853C12.5549 5.16377 12.8629 5.23723 13.0981 5.09261C13.3333 4.94799 13.4068 4.64005 13.2622 4.40481L12.4103 4.92853ZM13.5425 7.33333L13.894 6.97772C13.6992 6.7852 13.3858 6.7852 13.191 6.97772L13.5425 7.33333ZM12.0669 8.08883C11.8705 8.28295 11.8686 8.59953 12.0628 8.79593C12.2569 8.99233 12.5734 8.99417 12.7698 8.80006L12.0669 8.08883ZM14.3152 8.80006C14.5116 8.99417 14.8282 8.99233 15.0223 8.79593C15.2164 8.59953 15.2146 8.28295 15.0182 8.08883L14.3152 8.80006ZM3.54521 11.0707C3.40019 10.8358 3.09212 10.7628 2.85712 10.9078C2.62213 11.0529 2.54919 11.3609 2.69421 11.5959L3.54521 11.0707ZM8.0525 1.5C4.68762 1.5 1.95313 4.20747 1.95313 7.55556H2.95313C2.95313 4.76714 5.23248 2.5 8.0525 2.5V1.5ZM1.95313 7.55556L1.95313 8.66667L2.95313 8.66667L2.95313 7.55556L1.95313 7.55556ZM2.80529 9.02161L3.92516 7.9105L3.22084 7.20062L2.10096 8.31173L2.80529 9.02161ZM2.80529 8.31173L1.68541 7.20062L0.98109 7.9105L2.10096 9.02161L2.80529 8.31173ZM13.2622 4.40481C12.1913 2.66285 10.2581 1.5 8.0525 1.5V2.5C9.89885 2.5 11.515 3.4723 12.4103 4.92853L13.2622 4.40481ZM7.92174 14.5C11.2966 14.5 14.0425 11.7943 14.0425 8.44444H13.0425C13.0425 11.2311 10.7554 13.5 7.92174 13.5V14.5ZM14.0425 8.44444V7.33333H13.0425V8.44444H14.0425ZM13.191 6.97772L12.0669 8.08883L12.7698 8.80006L13.894 7.68894L13.191 6.97772ZM13.191 7.68894L14.3152 8.80006L15.0182 8.08883L13.894 6.97772L13.191 7.68894ZM2.69421 11.5959C3.76929 13.338 5.70928 14.5 7.92174 14.5V13.5C6.06674 13.5 4.44381 12.5269 3.54521 11.0707L2.69421 11.5959Z" fill={`url(#${props.iconKey + '2'})`}/>
    <defs>
    <linearGradient id={props.iconKey + '2'}  x1="7.99996" y1="-7.42" x2="-6.89423" y2="3.02848" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1E9DAE"/>
    </linearGradient>
    </defs>
    </svg>)
  )
}

export default HardRefreshIconSvg;
