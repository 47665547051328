import { View } from 'react-native';
import { Colors } from '../../../styles/Colors';

const Divider = (props: {
  height?: number | string;
  width?: number | string;
  marginHorizontal?: number | string;
  backgroundColor?: string;
}) => {
  return (
    <View
      style={{
        width: props?.height || 0.5,
        height: props?.height || 16,
        marginHorizontal: props?.marginHorizontal || 12,
        backgroundColor: props.backgroundColor || Colors.FoldPixel.GRAY150,
      }}
    />
  );
};

export default Divider;
