import {useLazyQuery} from '@apollo/client';
import {Popover} from 'antd';
import {
  Button,
  Divider,
  HStack,
  Pressable,
  Text,
  Tooltip,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useState} from 'react';
import {releaseNumberErr, releaseNumberErrForDefaultAccountLocation, releaseNumberErrForDefaultLocation} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CloudTelephonyQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {EventBus} from '../../../../utils/EventBus';
import DeleteSvg from '../../../common/Svg/DeleteSvg';
import EditSvg from '../../../common/Svg/EditSvg';
import PhoneCallSvg from '../../../common/Svg/PhoneCallSvg';
import {styles} from './ActiveNumbersTableStyles';
import SelectPatientCallDrawer from './SelectPatientCallDrawer';
import {SetDefaultNumber} from './SetDefaultNumberStyle';
import Feather from 'react-native-vector-icons/Feather';
import {getDefaultPhoneNumberByLocation} from '../../../../services/CloudTelephony/CloudTelephonyQueries';

interface IActionViewProps {
  selectedRecord: any;
  onActionClick: (actionCode: string, actionData: any) => void;
  isSmsInbox?: boolean;
  isShowDefaultSetAction?: boolean;
}

interface ILinkIPPhoneAction {
  code: string;
  title: string;
  onClick: () => void;
}

const ActionsView = (props: IActionViewProps) => {
  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const toast = useToast();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };
  const userSettings = commonData.userSettings;
  const isHideNumberDeleteButton =
    userSettings['is_hide_number_delete_button']?.value === 'True' || false;

  // const showDefaultSetOption = () => {
  //   return (
  //     props?.selectedRecord?.assignedTypeCode === ASSIGN_TYPE.CLINIC ||
  //     props?.selectedRecord?.assignedTypeCode === ASSIGN_TYPE.OTHER
  //   );
  // };
  const [GET_DEFAULT_NUMBER] = useLazyQuery(
    CloudTelephonyQueries.GET_DEFAULT_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const handleReleaseNumber = async () => {
    const responses = await GET_DEFAULT_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        virtualPhoneNumberAssigneeId: props?.selectedRecord?.id || '',
        accountUuid: accountUuid,
        tenantId: accountUuid
      },
    });
    const defaultNumberForLocationRes: any = await getDefaultPhoneNumberByLocation({
      accountUuid: accountUuid,
    });

    let isNumberSetDefaultForLocation = false;
    for (const item of defaultNumberForLocationRes?.data) {
      if (item?.locationId && item?.VirtualPhoneNumberAssignees?.virtualPhoneNumber === props?.selectedRecord?.phoneNumber?.phoneNumber) {
        isNumberSetDefaultForLocation = true;
        break;
      }
    }
    if (
      responses?.data?.numberDefaultCommunications?.length
    ) {
      showToast(
        toast,
        releaseNumberErrForDefaultAccountLocation,
        ToastType.info,
        8000
      );
    } else if (isNumberSetDefaultForLocation) {
      showToast(
        toast,
        releaseNumberErrForDefaultLocation,
        ToastType.info,
        8000
      );
    } else if (responses?.data?.numberDefaultCommunications?.length) {
      showToast(toast, releaseNumberErr, ToastType.info, 8000);
    } else {
      props.onActionClick(COMMON_ACTION_CODES.DELETE, props.selectedRecord);
    }
  };

  const linkIPPhoneActions: ILinkIPPhoneAction[] = [
    {
      code: COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES,
      onClick() {
        props.onActionClick(
          COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES,
          props.selectedRecord
        );
      },
      title: 'View Linked IP Phones',
    },
    {
      code: COMMON_ACTION_CODES.LINK_IP_PHONE,
      onClick() {
        props.onActionClick(
          COMMON_ACTION_CODES.LINK_IP_PHONE,
          props.selectedRecord
        );
      },
      title: 'Link New IP Phone',
    },
  ];

  const content: JSX.Element = (
    <VStack>
      {linkIPPhoneActions.map((item) => {
        return (
          <Pressable
            key={item.code}
            onPress={() => {
              item.onClick();
            }}
            style={{
              paddingBottom: 8,
              paddingTop: 12,
              paddingLeft: 4,
              borderBottomWidth: 1,
              borderColor: Colors.Custom.Gray200,
            }}
          >
            <Text>{item.title}</Text>
          </Pressable>
        );
      })}
    </VStack>
  );

  return (
    <View style={styles.iconView}>
      <VStack>
        {props.isShowDefaultSetAction ? (
          <SetDefaultNumber
            onActionClick={props.onActionClick}
            selectedRecord={props?.selectedRecord}
            isSmsInbox={props?.isSmsInbox}
          />
        ) : (
          <HStack>
            {isHideNumberDeleteButton || (
              <Pressable
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 11,
                  marginRight: 4,
                }}
                onPress={() => {
                  handleReleaseNumber();
                }}
              >
                <DeleteSvg />
              </Pressable>
            )}
            <Pressable
              style={{paddingHorizontal: 12, paddingVertical: 11}}
              onPress={() => {
                props.onActionClick(
                  COMMON_ACTION_CODES.EDIT,
                  props.selectedRecord
                );
              }}
            >
              <EditSvg />
            </Pressable>

            <Tooltip label="Call">
              <Pressable
                onPress={() => {
                  if (
                    props.selectedRecord?.phoneNumber?.phoneNumber ||
                    props.selectedRecord?.rowData?.virtualPhoneNumber
                  ) {
                    props.onActionClick(
                      COMMON_ACTION_CODES.CALL,
                      props.selectedRecord
                    );
                  }
                }}
              >
                <View style={{width: 36, height: 36}}>
                  <PhoneCallSvg
                    isEnabled={
                      props.selectedRecord?.phoneNumber?.phoneNumber ||
                      props.selectedRecord?.rowData?.virtualPhoneNumber
                    }
                    customStrokeColor={
                      props.selectedRecord?.phoneNumber?.phoneNumber ||
                      props.selectedRecord?.rowData?.virtualPhoneNumber
                        ? '#667085'
                        : "#D0D5DD"
                    }
                    customBgColor='transparent'
                  />
                </View>
              </Pressable>
            </Tooltip>

            <Popover
              // key={props?.record?.id}
              overlayInnerStyle={{padding: 0, borderRadius: 16}}
              overlayStyle={{padding: 0}}
              overlay={{padding: 0}}
              style={{padding: 0, borderRadius: 16}}
              placement={'left'}
              trigger="click"
              visible={isOpen}
              onVisibleChange={handleVisibleChange}
              content={content}
            >
              <Button
                onPress={() => {
                  setIsOpen(true);
                }}
                style={{
                  // border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <Feather
                  name="more-vertical"
                  size={14}
                  style={{
                    marginLeft: 2,
                    marginRight: 2,
                    fontSize: 14.29,
                    color: Colors.Custom.Gray600,
                  }}
                />
              </Button>
            </Popover>
          </HStack>
        )}
      </VStack>
    </View>
  );
};

export default ActionsView;
