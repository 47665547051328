import {Path, Svg} from 'react-native-svg';

const RetrySvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="12"
      height="13"
      fill="none"
      viewBox="0 0 12 13"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.243 3.367l-.472-.472A5.333 5.333 0 1011.291 6m-1.048-2.633H7.414m2.829 0V.538"
      ></Path>
    </Svg>
  );
};

export default RetrySvg;