import React from 'react';
import {Svg, Path} from 'react-native-svg';

function AddTaskSvg() {
  return (
    <Svg width="16" height="17" fill="none" viewBox="0 0 16 17">
      <Path
        fill="#6F7A90"
        d="M2.31 14.19l-.354.354.354-.354zM13.69 2.81l.354-.354-.354.354zm-9.328 7.878a.5.5 0 00-.724.69l.724-.69zm.4 1.145l-.362.345a.5.5 0 00.724 0l-.362-.345zm2.267-1.655a.5.5 0 00-.724-.69l.724.69zM4.362 6.022a.5.5 0 10-.724.69l.724-.69zm.4 1.145L4.4 7.51a.5.5 0 00.724 0l-.362-.344zM7.029 5.51a.5.5 0 00-.724-.69l.724.69zm7.124 1a.5.5 0 101-.023l-1 .023zM5.99 15.653a.5.5 0 00.022-1l-.022 1zm4.237-4.673a.5.5 0 100 1v-1zm3.509 1a.5.5 0 100-1v1zm-2.255 1.255a.5.5 0 101 0h-1zm1-3.509a.5.5 0 10-1 0h1zM.833 8.5c0 1.557 0 2.775.127 3.724.13.963.4 1.723.996 2.32l.707-.707c-.38-.38-.597-.894-.712-1.746-.117-.867-.118-2.006-.118-3.591h-1zM8 2.333c1.586 0 2.724.001 3.591.118.852.114 1.366.332 1.746.712l.707-.707c-.597-.597-1.357-.867-2.32-.996-.949-.128-2.167-.127-3.724-.127v1zm0-1c-1.557 0-2.775 0-3.724.127-.963.13-1.723.4-2.32.996l.707.707c.38-.38.894-.598 1.746-.712.867-.117 2.006-.118 3.591-.118v-1zM1.833 8.5c0-1.586.001-2.724.118-3.591.115-.852.332-1.366.712-1.746l-.707-.707c-.596.597-.867 1.357-.996 2.32C.832 5.725.833 6.943.833 8.5h1zm1.805 2.878l.762.8.724-.69-.762-.8-.724.69zm1.486.8l1.905-2-.724-.69-1.905 2 .724.69zM3.638 6.711l.762.8.724-.69-.762-.8-.724.69zm1.486.8l1.905-2-.724-.69-1.905 2 .724.69zm10.03-1.023c-.043-1.842-.209-3.13-1.11-4.032l-.707.707c.584.584.774 1.47.816 3.348l1-.023zM6.01 14.653c-1.879-.043-2.764-.232-3.348-.816l-.707.707c.901.901 2.19 1.067 4.033 1.11l.022-1zm8.822-3.153A2.833 2.833 0 0112 14.333v1a3.833 3.833 0 003.833-3.833h-1zM12 14.333A2.833 2.833 0 019.167 11.5h-1A3.833 3.833 0 0012 15.333v-1zM9.167 11.5A2.833 2.833 0 0112 8.667v-1A3.833 3.833 0 008.167 11.5h1zM12 8.667a2.833 2.833 0 012.833 2.833h1A3.833 3.833 0 0012 7.667v1zm-1.774 3.313h1.754v-1h-1.754v1zm1.754 0h1.755v-1H11.98v1zm.5 1.255V11.48h-1v1.755h1zm0-1.755V9.726h-1v1.754h1z"
      ></Path>
    </Svg>
  );
}

export default AddTaskSvg;
