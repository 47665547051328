import {Divider, FlatList, Spinner, Text, Pressable} from 'native-base';
import {View} from 'react-native';
import {Colors} from '../../../../../styles';
import {Drawer} from 'antd';
import {PendingRequestListItem} from '../../../../common/PendingRequestListItem/PendingRequestListItem';
import Stack from '../../../../common/LayoutComponents/Stack';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT} from '../../../../../constants';
import {getFormattedDate} from '../../OtherDetails/OtherDeatilsUtils';
import {
  ACTION_CODES,
  SYNC_STATUS_TYPES,
} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import {HieStatus, IHieRequest, IHieRequestGroup} from './interface';
import {useHieRequests} from './useHieRequests';
import FormLoaderSkeleton from '../../../MiddleContainer/PatientNotes/components/FormLoaderSkeleton';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import {
  getFilteredHieRequests,
  getResourceGroupText,
  getUpdatedMatchedHieRequest,
  updateGroupHieRequests,
} from './HieRequestsUtils';
import {HieMatchDataView} from './HieMatchDataView';
import {getVitalListFromCapability} from '../../../../../utils/capabilityUtils';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../../context/CommonDataContext';

interface PendingHieRequestsProps {
  patientUuid: string;
  patientId: string;
  accountLocationId: string;
  contactUuid?: string;
  contactLocationId?: string;
  onClose: () => void;
}

export const PendingHieRequestsDrawer = (props: PendingHieRequestsProps) => {
  const {patientUuid, accountLocationId, patientId, contactUuid, contactLocationId} = props;
  const {onClose} = props;
  const intl = useIntl();

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const {
    hieRequestGroups,
    updateHieRequestStatus,
    loading,
    updateHieRequestsLoading,
    updateHieRequestGroupsState,
    handleAcceptAll,
    metaData
  } = useHieRequests({
    patientUuid: patientUuid,
    accountLocationUuid: accountLocationId,
    patientId: patientId,
    contactUuid: contactUuid
  });

  const onActionPerform = (hieRequest: IHieRequest, code: string) => {
    switch (code) {
      case ACTION_CODES.ACCEPT:
        updateHieRequestStatus(hieRequest, HieStatus.accepted);
        break;
      case ACTION_CODES.DECLINE:
        updateHieRequestStatus(hieRequest, HieStatus.declined);
        break;
      case ACTION_CODES.SHOW_MATCH_DATA_VIEW:
        updateMatchDataViewVisibility(hieRequest, true);
        break;
      case ACTION_CODES.HIDE_MATCH_DATA_VIRW:
        updateMatchDataViewVisibility(hieRequest, false);
        break;
    }
  };

  const updateMatchDataViewVisibility = (
    hieRequest: IHieRequest,
    isVisible: boolean
  ) => {
    hieRequest.isMatchDataViewVisible = isVisible;
    const updatedHieRequestsGroups = updateGroupHieRequests(
      hieRequest,
      hieRequestGroups
    );
    updateHieRequestGroupsState(updatedHieRequestsGroups);
  };

  const renderCardListItem = ({
    item,
    index,
  }: {
    item: IHieRequestGroup;
    index: number;
  }) => {
    return (
      <View
        style={{
          borderRadius: 12,
          backgroundColor: Colors.FoldPixel.GRAY50,
          marginTop: index === 0 && !isSidecarContext ? 4 : 12,
          borderWidth: 0.5,
          borderColor: Colors.FoldPixel.GRAY150,
        }}
      >
        <Stack direction="row" style={{justifyContent: 'space-between'}}>
          <Text
            fontSize={14}
            fontWeight={'500'}
            marginTop={3}
            paddingLeft={3}
            marginBottom={'10px'}
            color={Colors.FoldPixel.GRAY400}
          >
            {getResourceGroupText(item.resourceType, intl).headerText}
          </Text>
          {getFilteredHieRequests(item.orders)?.length > 1 && (
            <Pressable
              disabled={item.acceptAllLoading}
              onPress={() => {
                handleAcceptAll(item);
              }}
              cursor={item.acceptAllLoading ? 'not-allowed' : 'pointer'}
            >
              <Stack direction="row">
                <Text
                  fontSize={14}
                  fontWeight={'500'}
                  marginTop={3}
                  paddingLeft={3}
                  marginBottom={'10px'}
                  marginRight={3}
                  color={Colors.FoldPixel.PRIMARY300}
                >
                  {'Accept All'}
                </Text>
                {item.acceptAllLoading && <Spinner marginRight={3} />}
              </Stack>
            </Pressable>
          )}
        </Stack>

        <Divider />
        <Stack direction="column" style={{paddingLeft: 12, paddingRight: 12}}>
          {item.orders.map((order) => {
            const display = order.display;
            const formattedDate = display?.date
              ? getFormattedDate(display.date, DISPLAY_DATE_FORMAT)
              : '';

            const loadingObj = updateHieRequestsLoading.find(
              (loading) => loading.id === order.id
            );

            const syncStatusType =
              order.syncStatus === HieStatus.accepted
                ? SYNC_STATUS_TYPES.ACCEPTED
                : SYNC_STATUS_TYPES.DECLINED;
            return (
              <PendingRequestListItem
                title={display?.title || ''}
                date={formattedDate}
                id={order.id}
                onActionPerform={(id, code) => {
                  onActionPerform(order, code);
                }}
                error={order.error}
                key={`request_list_item_${order.id}`}
                isMatchDataViewVisible={order.isMatchDataViewVisible}
                showError={true}
                isDataNotMatched={order.invalidFields?.length ? true : false}
                syncStatusType={syncStatusType}
                loading={loadingObj}
                subTitle={display?.subTitle}
                syncStatus={order.syncStatus}
                matchToView={
                  <HieMatchDataView
                    headerText={
                      getResourceGroupText(item.resourceType, intl)
                        .matchedWithTitle
                    }
                    onActionPerform={(id, code) => {
                      onActionPerform(order, code);
                    }}
                    hieRequest={order}
                    onContinueClick={(invalidFields) => {
                      const updatedMatchedHieRequest =
                        getUpdatedMatchedHieRequest(
                          order,
                          invalidFields,
                          metaData
                        );
                      updatedMatchedHieRequest.isMatchDataViewVisible = false;
                      const updatedHieRequestsGroups = updateGroupHieRequests(
                        updatedMatchedHieRequest,
                        hieRequestGroups
                      );
                      updateHieRequestGroupsState(updatedHieRequestsGroups);
                    }}
                    contactLocationId={contactLocationId}
                  />
                }
                status={display?.status ? capitalizeText(display?.status) : ''}
              />
            );
          })}
        </Stack>
      </View>
    );
  };

  const orderMeta: any[] = [];
  hieRequestGroups.map((hieRequestGroup) => {
    orderMeta.push({
      resourceType: hieRequestGroup.resourceType,
      resourceData: hieRequestGroup?.orders?.[0]?.resourceData,
    });
  });

  return (
    <Drawer
      open={true}
      width={isSidecarContext ? '100%' : '40%'}
      onClose={onClose}
      bodyStyle={{paddingTop: 0}}
      title={
        <ModalActionTitle
          isHeadNotSticky
          title={'HIE Data'}
          buttonList={[
            {
              onClick: () => {
                onClose();
              },
              show: true,
              id: 1,
              btnText: intl.formatMessage({
                id: 'close',
              }),
              size: 'sm',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
            },
          ]}
        />
      }
    >
      {loading ? (
        <FormLoaderSkeleton />
      ) : (
        <FlatList
          keyExtractor={(item) => item.resourceType}
          data={hieRequestGroups}
          renderItem={renderCardListItem}
        />
      )}
    </Drawer>
  );
};
