import moment, {DurationInputArg2, Moment} from 'moment';
import 'moment-timezone';
import {CalendarView} from '../components/common/CalendarWidget/CalendarWidgetEnums';
import {ITopBarData} from '../components/common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import {CALENDAR_WIDGET_DATE_FORMATS, DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, MOMENT_DATE_CONST} from '../constants';
import momentTz from 'moment-timezone';
import { MAX_FUTURE_DATE_RANGE } from '../components/common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';
import { EventValue } from '../components/common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/interface';

export const getDateYearAgo = ()=>{
  return moment().subtract(1, 'year').startOf('month').format('yyyy-MM-DDTHH:mm:ss');
}

export const getDateSixMonthAgo = ()=>{
  return moment().subtract(6, 'month').startOf('month').format('yyyy-MM-DDTHH:mm:ss');
}

export const getCurrentMonthEnd = ()=>{
  return  moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss');
}

export const getYearsAndMonthsSinceDate = (dateString: string): { years: number; months: number } => {
  const currentDate = moment();
  const onsetDate = moment(dateString);
  const years = currentDate.diff(onsetDate, 'years');
  const months = currentDate.diff(onsetDate, 'months') % 12;
  return { years, months };
};

export const getChatDisplayTimeAgo = (dateString: string): string => {
  const isSameDay = moment(dateString).isSame(new Date(), 'day');
  const isSameYear = moment(dateString).isSame(new Date(), 'year');
  let dateTimeString = '';
  if (isSameDay) {
    dateTimeString = moment(dateString).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);
  } else {
    if (isSameYear) {
      dateTimeString = moment(dateString).format(DATE_FORMATS.CHAT_TIME_AGO_MMDD);
    } else {
      dateTimeString = moment(dateString).format(DATE_FORMATS.MESSAGE_DATE_FORMAT_WITHOUT_TIME);
    }
  }
  return dateTimeString;
};

export const getPreviousYearStartDate = (date: string) => {
  return moment(date).subtract(1, 'years').startOf('year').toISOString();
}
export const getPreviousYearEndDate = (date: string) => {
  return moment(date).subtract(1, 'years').endOf('year').toISOString();
}
export const getNextYearStartDate = (date: string) => {
  return moment(date).add(1, 'years').startOf('year').toISOString();
}
export const getNextYearEndDate = (date: string) => {
  return moment(date).add(1, 'years').endOf('year').toISOString();
}

export const getCurrentYearStartDate = () => {
  return moment().startOf('year').toISOString()
}

export const getCurrentYearEndtDate = () => {
  return moment().endOf('year').toISOString()
}

export const getMessageDisplayTimeAgo = (dateString: string): string => {
  const isSameDay = moment(dateString).isSame(new Date(), 'day');
  const isSameYear = moment(dateString).isSame(new Date(), 'year');
  let dateTimeString = '';
  if (isSameDay) {
    dateTimeString = moment(dateString).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);
  } else {
    if (isSameYear) {
      dateTimeString = moment(dateString).format(DATE_FORMATS.SAME_YEAR_MESSAGE_DATE_FORMAT);
    } else {
      dateTimeString = moment(dateString).format(DATE_FORMATS.MESSAGE_DATE_FORMAT_WITHOUT_TIME);
    }
  }
  return dateTimeString;
};
export const getMessageDisplayTimeAgoFullDate = (
  dateString: string
): string => {
  const isSameDay = moment(dateString).isSame(new Date(), 'day');
  const isSameYear = moment(dateString).isSame(new Date(), 'year');
  let dateTimeString = '';
  if (isSameDay) {
    dateTimeString = moment(dateString).format('hh:mm A');
  } else {
    if (isSameYear) {
      dateTimeString = moment(dateString).format(DATE_FORMATS.SAME_YEAR_MESSAGE_DATE_FORMAT);
    } else {
      dateTimeString = moment(dateString).format(DATE_FORMATS.MESSAGE_DATE_FORMAT);
    }
  }
  return dateTimeString;
};

export const getMonthStrFromDate = (date: any) => {
  if (!date) {
    return '';
  }
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const d = new Date(date);
  const name = month[d.getMonth()];
  return name;
};

export const getDateStrFromFormat = (
  dateString?: string | Date | number,
  dateFormatStr = DATE_FORMATS.MESSAGE_DATE_FORMAT
): string => {
  return moment(dateString).format(dateFormatStr)
};

export const getCurrentTimeZone = () => {
  return moment.tz.guess();
};

export const getFormattedDateStringInTimeZone = (
  dateString: string | Date | number,
  dateFormatStr = DATE_FORMATS.MESSAGE_DATE_FORMAT,
  dateType?: string,
  timeZone = getCurrentTimeZone()
): string => {
  const formattedDateString = extractDateBeforeT(dateString)
  if (dateType === MOMENT_DATE_CONST.START_OF) {
    return moment(formattedDateString).tz(timeZone).startOf('month').format(dateFormatStr)
  } else if (dateType === MOMENT_DATE_CONST.END_OF) {
    return moment(formattedDateString).tz(timeZone).endOf('month').format(dateFormatStr)
  }
  return moment(formattedDateString).tz(timeZone).format(dateFormatStr)
};

export const extractDateBeforeT = (dateTimeString: any) => {
  try {
    const parts = dateTimeString?.split('T');
    return parts[0]
  } catch (err) {
  }
}

export const getDateStrFromFormatWithTimezone = (
  dateString: string | Date | number,
  timezone?: string,
  dateFormatStr = DATE_FORMATS.MESSAGE_DATE_FORMAT
): string => {
  if (timezone) {
    return moment(dateString).tz(timezone).format(dateFormatStr);
  }
  return moment(dateString).format(dateFormatStr);
};

export const getDateObjFromDateStrAndFormat = (
  dateString: string | Date,
  dateFormatStr = DATE_FORMATS.MESSAGE_DATE_FORMAT
): Date => {
  const result = moment(dateString, dateFormatStr).toDate();
  return result;
};

export const getMomentObjFromDateStrAndFormat = (
  dateString: string | Date,
  dateFormatStr = DATE_FORMATS.MESSAGE_DATE_FORMAT
) => {
  return moment(dateString, dateFormatStr);
};

export const getMomentObjFromFormat = (
  dateString: any,
  dateFormatStr: string
): any => {
  return moment(moment(dateString).format(dateFormatStr));
};

export const getDateStrFromMomentObj = (
  momentDateObj: any,
  dateFormatStr: string
): string => {
  return momentDateObj.format(dateFormatStr);
};

export const getDateToMomentISOString = (date?: Date): string => {
  const dateObj = date || new Date();
  return moment(dateObj).toISOString();
};

export const getMomentObjectWithDateStringAndFormat = (
  date: string,
  format: string
): moment.Moment => {
  return moment(date, format);
};

export const getMomentObjectWithDateStringAndFormatInUTC = (
  date: string,
  format: string
): moment.Moment => {
  return moment(date, format).utc();
};

export const getDateObjectFromStringAndFormat = (
  date: string,
  format: string
) => {
  return moment(date, format).toDate();
};

export const getDateObjectFromAPIFormatToMyFormat = (date: string, format: string) => {
  return moment(date).format(format);
};

export const convertDateToStringWithoutTimezone = (date: string, format: string) => {
  if (date.charAt(date.length - 1) === 'Z') {
    date = date.slice(0, -1);
  }
  return moment(date).format(format);
};

export const convertDate = (
  date: string,
  fromFormat: string,
  toFormat: string
) => {
  return moment(date, fromFormat).format(toFormat);
};

export const getCalendarDateTitle = (
  currentView: string,
  currentDate: Date
): string => {
  switch (currentView) {
    case CalendarView.day:
      return getDateStrFromFormat(
        currentDate,
        CALENDAR_WIDGET_DATE_FORMATS.DAY_VIEW_TITLE_FORMAT
      );
    case CalendarView.month:
      return getDateStrFromFormat(
        currentDate,
        CALENDAR_WIDGET_DATE_FORMATS.MONTH_VIEW_TITLE_FORMAT
      );
    case CalendarView.week:
      return getWeekViewTitleForCalendar(currentDate);
  }
  return '';
};

export const getPreviousCalendarDate = (
  currentView: string,
  currentDate: Date
) => {
  let date = new Date();

  switch (currentView) {
    case CalendarView.day:
      date = moment(currentDate).subtract(1, 'day').toDate();
      break;
    case CalendarView.month:
      date = moment(currentDate).subtract(1, 'month').toDate();
      break;
    case CalendarView.week:
      date = moment(currentDate).subtract(7, 'day').toDate();
      break;
  }
  return date;
};

export const getNextCalendarDate = (currentView: string, currentDate: Date) => {
  let date = new Date();
  switch (currentView) {
    case CalendarView.day:
      date = moment(currentDate).add(1, 'day').toDate();
      break;
    case CalendarView.month:
      date = moment(currentDate).add(1, 'month').toDate();
      break;
    case CalendarView.week:
      date = moment(currentDate).add(7, 'day').toDate();
      break;
  }
  return date;
};

export const getWeekViewTitleForCalendar = (currentDate: Date): string => {
  // Adding 1 day in current date and subtracting 1 from final calculated week because our calendar has display
  // of sunday, monday and so on and moment considers monday to be first day and sunday as last day
  const startDate = moment(currentDate)
    .add(1, 'days')
    .startOf('isoWeek')
    .subtract(1, 'day');
  const endDate = moment(currentDate)
    .add(1, 'days')
    .endOf('isoWeek')
    .subtract(1, 'day');
  const startMonth = moment(startDate).format(
    CALENDAR_WIDGET_DATE_FORMATS.SHORT_MONTH
  );
  const endMonth = moment(endDate).format(
    CALENDAR_WIDGET_DATE_FORMATS.SHORT_MONTH
  );
  const startDay = startDate.format(CALENDAR_WIDGET_DATE_FORMATS.DAY_FORMAT);
  const endDay = endDate.format(CALENDAR_WIDGET_DATE_FORMATS.DAY_FORMAT);
  if (startMonth === endMonth) {
    return startMonth + ' ' + startDay + ' - ' + endDay;
  } else {
    return startMonth + ' ' + startDay + ' - ' + endMonth + ' ' + endDay;
  }
};

export const isCurrentDateInFutureComparedToOther = (
  currentDate: Date | string,
  otherDate: Date | string
): boolean => {
  return moment(currentDate) > moment(otherDate);
};


export const isCurrentDateComparedToOther = (
  currentDate: Date | string,
  otherDate: Date | string
): boolean => {
  return moment(currentDate) >= moment(otherDate);
};
export const getMomentObj = (date: Date | string, timezone?: string, format?: string): moment.Moment => {
  if (timezone) {
    return moment(date)?.tz(timezone);
  }
  if (format) {
    return moment(date, format);
  }
  return moment(date);
};


export const isPastDay = (date: moment.Moment, timezone?: string): boolean => {
  const startOfCurrentDay = timezone ? moment.tz(timezone as string).startOf('day') : moment().startOf('day');
  const isPastDay = date < startOfCurrentDay;
  return isPastDay;
};
export const isFutureDay = (date: moment.Moment, timezone?: string): boolean => {
  return date > moment.tz(timezone as string).endOf('day');
};
export const isCurrentDay = (date: moment.Moment, timezone?: string): boolean => {
  return date >= moment.tz(timezone as string).startOf('day');
};

export const getDateObject = (date: any): Date => {
  return moment(date).toDate();
};

export const getMidDayDateObject = (date: any): Date => {
  return moment(date).add(12, 'hours').toDate();
};

export const addDaysInDate = (date: Date, days: number): Date => {
  return moment(date).add(days, 'day').toDate();
};

export const subtractSecondsInDate = (date: Date, seconds: number): Date => {
  return moment(date).subtract(seconds, 'seconds').toDate();
};

export const isSameDate = (
  date1: Date | string,
  date2: Date | string
): boolean => {
  return moment(date1).isSame(moment(date2));
};

export const isBetweenDate = (
  date: Date | string,
  fromDate: Date | string,
  toDate: Date | string,
  inclusivity?: '()' | '[)' | '(]' | '[]' // '[' indicates inclusion of a value. A '(' indicates exclusion
): boolean => {
  return moment(date).isBetween(
    moment(fromDate),
    moment(toDate),
    undefined,
    inclusivity
  );
};

export const getCalendarStartDateTime = (): Date => {
  return moment().startOf('hour').add(-3, 'hour').toDate();
};

export const getDateRangeForCalendar = (
  currentView: string,
  currentDate: Date
): any => {
  let startDate = moment().startOf('month');
  let endDate = moment().endOf('month');
  if (currentView === CalendarView.day) {
    startDate = moment(currentDate).startOf('day');
    endDate = moment(currentDate).endOf('day');
    return {
      startDate: startDate,
      endDate: endDate,
    };
  } else if (currentView === CalendarView.week) {
    // adding 1 day as in isoWeek Monday is first day and Sunday is last day, but in calendar we have sunday as first day
    startDate = moment(currentDate).add(1, 'days').startOf('isoWeek');
    endDate = moment(currentDate).add(1, 'days').endOf('isoWeek');
  } else if (currentView === CalendarView.month) {
    startDate = moment(currentDate).startOf('month').subtract(7, 'days');
    endDate = moment(currentDate).endOf('month').add(7, 'days');
  } else if (currentView === CalendarView.agenda) {
    startDate = moment(currentDate).startOf('day');
    endDate = moment(currentDate).endOf('day').add(1, 'month');
  }
  return {
    startDate: startDate.subtract(1, 'days'),
    endDate: endDate.add(1, 'days'),
  };
};

export const getCalendarWeekHeader = (date: any): {
  weekday: string;
  date: string;
} => {
  return {
    weekday: moment(date).format('ddd'),
    date: moment(date).format('DD'),
  };
};

export const getCalendarMonthHeader = (date: any): any => {
  return {
    header: moment(date).format('ddd'),
  };
};

export const getCurrentNextHour = (): Date => {
  return moment().startOf('hour').add(1, 'hour').toDate();
};

export const getEndOfDay = (date?: any): Date => {
  if (date) {
    return moment(date).endOf('day').toDate()
  }
  return moment().endOf('day').toDate();
};

export const getStartOfDay = (date?: any): Date => {
  if (date) {
    return moment(date).startOf('day').toDate()
  }
  return moment().startOf('day').toDate();
};

export const getCareDashboardDateRange = () => {
  return {
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
  };
};

export const isPastTime = (date: string | Date): boolean => {
  const time = moment(date).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);
  return moment(time, DATE_FORMATS.DISPLAY_TIME_FORMAT) < moment();
};

export const getUnixDateFormatted = (date: string) => {
  return moment.unix(Number(date)).format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
};

export const getDisplayDateFormatted = (date: string) => {
  return moment(date).format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
};

export const getDisplayDateMessageSectionHeader = (date: string) => {
  const convertedDate = moment(date).format(DATE_FORMATS.MESSAGE_SECTION_HEADER_FORMAT_WITHOUT_TIME);
  return convertedDate;
};

export const getDisplayDateMonthFormatted = (date: string) => {
  const customFormatDate = moment(date, DATE_FORMATS.GMT_DATE_FORMAT).format(DATE_FORMATS.DISPLAY_DATE_MONTH_FORMAT);
  return customFormatDate;
};

export const getDateDifferenceInDays =(startDateTime:any, futureDateTime:any)=>{
  const startDate = moment(startDateTime);
const futureDate = moment(futureDateTime)
return futureDate.diff(startDate, 'days')
}

export const getDateDifference = (startDateTime: any, futureDateTime: any, unit: moment.unitOfTime.Diff = 'days', isPrecise = false) => {
  const startDate = moment(startDateTime);
  const futureDate = moment(futureDateTime);
  return futureDate.diff(startDate, unit, isPrecise);
}

export const addTimeToDate = (
  currentDate: Date | string,
  count: number,
  unit: string | moment.unitOfTime.Diff
) => {
  let date = new Date();
  switch (unit) {
    case 'HOUR':
      date = moment(currentDate).add(count, 'hour').toDate();
      break;
    case 'DAY':
      date = moment(currentDate).add(count, 'day').toDate();
      break;
    case 'WEEK':
      date = moment(currentDate).add(count, 'week').toDate();
      break;
    case 'MONTH':
      date = moment(currentDate).add(count, 'month').toDate();
      break;
    case 'YEAR':
      date = moment(currentDate).add(count, 'year').toDate();
      break;
    case 'MINUTE':
      date = moment(currentDate).add(count, 'minute').toDate();
      break;
    default:
      date = moment(currentDate).add(count, unit as moment.unitOfTime.Diff).toDate();
      break;
  }
  return date;
};

export const getStartOfDate = (date: Date | string): Date => {
  return moment(date).startOf('day').toDate();
};

export const getEndOfDate = (date: Date | string): Date => {
  return moment(date).endOf('day').toDate();
};

export const getISODateFromDisplayDate = (range: ITopBarData) => {
  return {
    start: moment(range.start, DATE_FORMATS.DISPLAY_DATE_FORMAT)
      .startOf('day')
      .toISOString(),
    end: moment(range.end, DATE_FORMATS.DISPLAY_DATE_FORMAT)
      .endOf('day')
      .toISOString(),
  };
};

export const getDiffInMonths = (startDateTime: any, endDateTime: any) => {
  const duration = moment.duration(getMomentObj(endDateTime).diff(startDateTime));
  const months = duration.asMonths();
  return months;
};

export const getDiffInYears = (startDateTime: any, endDateTime: any) => {
  const duration = moment.duration(endDateTime.diff(startDateTime)).asYears();
  return duration;
};

export const getDisplayMonths = (startDateTime: any, endDateTime: any) => {
  const duration = moment.duration(endDateTime.diff(startDateTime));
  const months = duration.asMonths();
  const weeks = duration.asWeeks();
  const days = duration.asDays();

  const displayDifferenceInDays = Math.floor(
    (days > 0 ? days : -days)
  );
  const displayDifferenceInWeeks = Math.floor(
    (weeks > 0 ? weeks : -weeks)
  );
  const displayDifferenceInMonths = Math.floor(
    (months > 0 ? months : -months) % 12
  );
  const displayDifferenceInYears = Math.floor(
    (months > 0 ? months : -months) / 12
  );

  if (displayDifferenceInYears) {
    return displayDifferenceInYears > 1 ? `${displayDifferenceInYears} years` : `${displayDifferenceInYears} year`;
  } else if (displayDifferenceInMonths) {
    return displayDifferenceInMonths > 1 ? `${displayDifferenceInMonths} months` : `${displayDifferenceInMonths} month`;
  } else if (displayDifferenceInWeeks) {
    return displayDifferenceInWeeks > 1 ? `${displayDifferenceInWeeks} weeks` : `${displayDifferenceInWeeks} week`;
  } else if (displayDifferenceInDays) {
    return displayDifferenceInDays > 1 ? `${displayDifferenceInDays} days` : `${displayDifferenceInDays} day`;
  }
  return '';
};

export const getDiffInDays = (
  startDateTime: any,
  endDateTime: any,
  isFloor?: boolean
) => {
  const duration = moment.duration(
    getMomentObj(endDateTime).diff(getMomentObj(startDateTime))
  );
  const days = isFloor
    ? Math.floor(duration.asDays())
    : Math.ceil(duration.asDays());
  return days;
};

export const getDiffInHours = (startDateTime: any, endDateTime: any) => {
  const duration = moment.duration(moment(endDateTime).diff(startDateTime));
  const hours = duration.asHours();
  return hours;
};

export const getDiffInMinutes = (startDateTime: any, endDateTime: any) => {
  const duration = moment.duration(
    moment(endDateTime).diff(moment(startDateTime))
  );
  const minutes = Math.ceil(duration.asMinutes())
  return minutes;
};

export const getDiffInSec = (startDateTime: any, endDateTime: any) => {
  const duration = moment.duration(
    moment(endDateTime).diff(moment(startDateTime))
  );
  const minutes = duration.asMilliseconds();
  return minutes;
};

export const setHourAndMinutesOfDate = (
  date: Date,
  hours: number,
  minutes?: number,
  seconds?: number
) => {
  const momentDate = moment(date);
  momentDate.set({
    hour: hours,
    minute: minutes || 0,
    second: seconds || 0,
    millisecond: 0,
  });
  return momentDate.toDate();
};

export const getDiffBetweenTwoTime = (
  dateString1: string,
  dateString2: string
): number => {
  let sec = 0;
  sec = moment(dateString2).diff(dateString1);
  const min = sec / 1000 / 60;
  return Math.round(min);
};

export const getDatediff = (first: number, second: number): number => {
  /**
    @remarks Round to nearest whole number to deal with DST.
    @returns difference between the dates and divide by milliseconds per day
  */
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
};

export function getFormattedDate(date: Date | string | Moment, format: string): string {
  return moment(date).format(format);
}

export function getFormattedMomentObj(
  date: Date | string | Moment,
  format: string
): Moment {
  return moment(date, format);
}

export const isFutureDate = (date: moment.Moment): boolean => {
  return date > moment().endOf('day');
};

export const isDateBetweenRange = (
  current: Date | string,
  min: Date | string,
  max: Date | string | any,
  checkWithoutMoment?: boolean
): boolean => {
  if(checkWithoutMoment && typeof current !== 'string') {
    return (
      current?.getTime() >= new Date(min).getTime() &&
      current?.getTime() <= new Date(max as string).getTime()
    );
  }
  return moment(current).isBetween(moment(min), moment(max).add(1, 'days'), undefined, '[]');
};

export const getCustomDateRangeForAppointments = (dateObj?: any) => {
  const startDate = getDateStrFromMomentObj(
    moment(dateObj).startOf('month'),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  const endDate = getDateStrFromMomentObj(
    moment(dateObj).endOf('month'),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  return {
    startDate: startDate,
    endDate: endDate,
  };
};



export const getCurrentTimeZoneAbbr = () => {
  return moment.tz(moment.tz.guess()).zoneAbbr();
};

export const getCurrentTimezoneOffsetString = () => {
  return moment().format('Z');
};

export const convertToTimezone = (date: Date | string, timezone: string) => {
  return moment(date).tz(timezone);
};

export const replaceTimezone = (date: Date, timezone: string) => {
  const displayDateTimeFormat = `YYYY-MM-DD HH:mm:ss`;
  const momentDate = moment(date);
  return moment
    .tz(
      momentDate.format(displayDateTimeFormat),
      displayDateTimeFormat,
      timezone
    )
    .toDate();
};

export const getStartOfDayOfTimezone = (date: Date, timezone: string) => {
  const displayDateTimeFormat = `YYYY-MM-DD HH:mm:ss`;
  return moment(
    moment(date).tz(timezone).format(displayDateTimeFormat),
    displayDateTimeFormat
  ).startOf('day');
};

export const ConvertUnixTimestamp = (date: number, format?: string) => {
  return moment
    .unix(date)
    .format(format ? format : DATE_FORMATS.MESSAGE_DATE_FORMAT);
};

export const convertUnixDate = (date: number) => {
  return moment.unix(date).toDate();
};

export const isBeforeOrSameDate = (
  date1: Date | string,
  date2: Date | string
): boolean => {
  return (
    moment(date1).isBefore(moment(date2), 'day') ||
    moment(date1).isSame(moment(date2), 'day')
  );
};

export const isBeforeDate = (
  date1: Date | string,
  date2: Date | string
): boolean => {
  return (
    moment(date1).isBefore(moment(date2), 'day')
  );
};

export const getFormattedCurrentTimeZone = () =>
  moment().tz(moment.tz.guess()).format('z');

export const getDayNameByNumber = (dayNumber: number) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[dayNumber];
};

export const getYearBack = (year: number) => {
  const dateObj: any = moment().subtract(year, 'years');
  return getDateToMomentISOString(dateObj);
}

export const getDayBack = (days: number) => {
  const dateObj: any = moment().subtract(days, 'day');
  return getDateToMomentISOString(dateObj);
}

export const getNextDay = (days: number) => {
  const dateObj: any = moment().add(days, 'day');
  return getDateToMomentISOString(dateObj);
}

export const getAgeValue = (birthDate: string, deceasedDate: string, isSidecarView?: boolean) => {
  if(!birthDate && !deceasedDate){
    return '';
  }
   // NOTE: DIRTY HACK to fix momentjs issue where JSC doesnot handle date manipulations where the format includes - (hyphen character)
   // so replacing hyphen with / (slash character) to fix the issue
  const deceasedDateWithoutHyphens = deceasedDate.replace(/-/g, '/');
  const deceasedDateISOString = deceasedDateWithoutHyphens
    ? getDateObjectFromStringAndFormat(
        deceasedDateWithoutHyphens,
        DISPLAY_SLASH_DATE_FORMAT
      ).toISOString()
    : '';
  const birthDateISOString = birthDate
    ? getDateObjectFromStringAndFormat(
        birthDate,
        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
      ).toISOString()
    : '';
  let ageInYears: number | undefined = undefined;
  let ageInMonths: number | undefined = undefined;
  let ageInDays: number | undefined = undefined;

  if (!!deceasedDateISOString && !!birthDateISOString) {
    ageInYears = moment(deceasedDateISOString).diff(
      birthDateISOString,
      'years',
      false
    );
    ageInMonths = moment(deceasedDateISOString).diff(
      birthDateISOString,
      'months',
      false
    );
    ageInDays = moment(deceasedDateISOString).diff(
      birthDateISOString,
      'days',
      false
    );
  } else if (birthDateISOString) {
    ageInYears = moment().diff(birthDateISOString, 'years', false);
    ageInMonths = moment().diff(birthDateISOString, 'months', false);
    ageInDays = moment().diff(birthDateISOString, 'days', false);
  }

  if (ageInYears) {
    return (
      ageInYears + (ageInYears > 1 ? (isSidecarView ? 'Y' : ' yrs') : ' yr')
    );
  } else if (ageInMonths) {
    return (
      ageInMonths + (ageInMonths > 1 ? (isSidecarView ? 'M' : ' mos') : ' mo')
    );
  } else if (ageInDays) {
    return (
      ageInDays + (ageInDays > 1 ? (isSidecarView ? 'D' : ' days') : ' day')
    );
  } else if (moment().isSame(birthDateISOString, 'day')) {
    return '0 day';
  }
  else {
    return '';
  }
};

export const getAgeDiffInYears = (birthDate: string | Date) => {
  return moment().diff(moment(birthDate), 'years', true);
};

export const getOverDuesDate = (timezone: string) => {
  const todayDate = moment().tz(timezone);
  return {
    end: todayDate.toISOString(),
  };
};

export const getTodaysTimeSlot = (timezone: string) => {
  const todayDate = moment().tz(timezone);
  return {
    start: todayDate.startOf('day').toISOString(),
    end: todayDate.endOf('day').toISOString(),
  };
};

export const getTomorrowsTimeSlot = (timezone: string) => {
  const tomorrowDate = moment().add(1, 'days').tz(timezone);
  return {
    start: tomorrowDate.startOf('day').toISOString(),
    end: tomorrowDate.endOf('day').toISOString(),
  };
};

export const getDoLaterTimeSlot = (timezone: string) => {
  const doLaterDate = moment().add(2, 'days').tz(timezone);
  return {
    start: doLaterDate.startOf('day').toISOString(),
  };
};

export const getDayBackInDate = (days: number, date?: Date) => {
  if (date) {
    return moment(date).subtract(days, 'day').toDate();
  }
  return moment().subtract(days, 'day').toDate();
}

export const getDayFutureInDate = (days: number, date?: Date) => {
  if (date) {
    return moment(date).add(days, 'day').toDate();
  }
  return moment().add(days, 'day').toDate();
}

export const isSameDay = (date1: Date | string, date2: Date | string) => {
  return moment(date1).isSame(date2, 'day');
};

export const isTodayDate = (dateString: string | Date | Moment) => {
  const todayDate = moment();
  const selectedDate = moment(dateString);
  return todayDate.isSame(selectedDate, 'day');
};

export const isYesterdayDate = (dateString: string) => {
  const yesterdayDate = moment().subtract(1, 'days')
  const selectedDate = moment(dateString);
  return yesterdayDate.isSame(selectedDate, 'day');
};

export const tomorrowDateInDateStringFormat = (dateString: string) => {
  return getDateStrFromMomentObj(
    moment(dateString).add(1, 'days'),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
};
export const isDayAndDateInPast = (dateString1: string, dateString2?: string): boolean => {
  const now = moment();
  const date = moment(dateString1);
  const date2 = dateString2 ? moment(dateString2) : null;
  return date.isBefore(date2 || now);
}

export const isDayAndDateInFuture = (dateString1: string, dateString2?: string): boolean => {
  const now = moment();
  const date = moment(dateString1);
  const date2 = dateString2 ? moment(dateString2) : null;
  return date.isAfter(date2 || now);
}

export const getDateAndTimeOnLocalFormate = (
  dateString: string | Date | number,
  dateFormatStr = DATE_FORMATS.MESSAGE_DATE_FORMAT
): string => {
  return moment(dateString).utc().local().format(dateFormatStr)
};

export const getNearestDateTimeWithMinutes = (date: moment.Moment, minutes: number, method: 'floor' | 'ceil') => {
  const milliseconds = minutes * 60 * 1000;
  return moment(Math[method]((+date) / milliseconds) * milliseconds);
}

export const isTimeBefore = (timeOne: Date | string, timeTwo: Date | string) => {
  return moment(timeOne).isBefore(moment(timeTwo));
}
export const getMinutesFromStartToEndOfTheDay = (start: Date | string) => {
  const end = moment(start).endOf('day');
  return moment.duration(end.diff(start)).asMinutes();
}

export const getStartAndEndOfDayForSearchByBirthDate = (dateString: string) => {
  const dateFormat = ["MM-DD-YYYY", "MM/DD/YYYY"];

  const parsedDate = moment.utc(dateString, dateFormat, true);

  if (!parsedDate.isValid()) {
    return false;
  }
  if (parsedDate.isAfter(moment.utc())) {
    return false;
  }
  const startOfDay = parsedDate.startOf('day').toISOString();
  const endOfDay = parsedDate.endOf('day').toISOString();

  return [startOfDay, endOfDay];
}

export const convertAvailabilityTimeToStringTime = (availabilityTimeWithDate: string) => {
  const momentDate = moment(availabilityTimeWithDate).toDate();
  return `${momentDate.getHours()}:${momentDate.getMinutes()}:${momentDate.getSeconds()}`;
}

export const getTimeDifference = (date: string) => {
  const currentDate = new Date();
  const previousDate = new Date(date);
  const diffInMilliseconds = currentDate.getTime() - previousDate.getTime();

  // Define time intervals in milliseconds
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;

  if (diffInMilliseconds < minute) {
    return 'Just now';
  } else if (diffInMilliseconds < hour) {
    const minutesAgo = Math.floor(diffInMilliseconds / minute);
    return `${minutesAgo} ${minutesAgo === 1 ? 'min' : 'mins'} ago`;
  } else if (diffInMilliseconds < day) {
    const hoursAgo = Math.floor(diffInMilliseconds / hour);
    return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
  } else if (diffInMilliseconds < month) {
    const daysAgo = Math.floor(diffInMilliseconds / day);
    if (daysAgo === 1) {
      return `Yesterday`;
    } else {
      return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    }
  } else if (diffInMilliseconds < year) {
    return moment(date).format(DATE_FORMATS.SAME_YEAR_MESSAGE_DATE_FORMAT);
  } else {
    return moment(date).format(DATE_FORMATS.MESSAGE_DATE_FORMAT);
  }
};

export const getDefaultStartDate = () => {
  return getDateStrFromMomentObj(
    moment().startOf('day'),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
}
export const getDefaultEndDate = () => {
  return getDateStrFromMomentObj(
    moment().endOf('month'),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
}

export const isSameMonth = (startDate: string, otherDate?: string | Date) => {
  return moment(startDate).isSame(moment(otherDate || new Date()), 'month') && moment(startDate).isSame(moment(otherDate || new Date()), 'year');
}

export const currentMonth = () => {
  return moment().month() + 1
}

export const currentYear = () => {
  return moment().year()
}

export const detectMonthYearChange = (selectedMonth: number, selectedYear: number, prevSelectedMonth: number, prevSelectedYear: number) => {
  return selectedMonth !== prevSelectedMonth || selectedYear !== prevSelectedYear
}

export const isDisabledMonth = (date: any) => {
  const test = getDateStrFromMomentObj(
    moment(date).endOf('month'),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  return !moment(test).isAfter(moment(), 'day');
};

export const getTimeRemaining = (utcDateString: any) => {
  const targetDate = moment.utc(utcDateString);
  const now = moment.utc();
  const duration = moment.duration(targetDate.diff(now));
  const totalMinutesRemaining = duration.asMinutes();

  if (totalMinutesRemaining < 60) {
    return `${Math.ceil(totalMinutesRemaining)} minute${totalMinutesRemaining > 1 ? 's' : ''}`;
  } else {
    const hoursRemaining = totalMinutesRemaining / 60;
    const minutesRemaining = totalMinutesRemaining % 60;

    if (minutesRemaining < 30) {
      return `${Math.floor(hoursRemaining)} hour${Math.floor(hoursRemaining) > 1 ? 's' : ''}`;
    } else {
      return `${Math.ceil(hoursRemaining)} hour${Math.ceil(hoursRemaining) > 1 ? 's' : ''}`;
    }
  }
};

export const getTimezone = (date: any) => {
  return moment(date).tz.name;
}
export const getPreviousDateEndOfDay = () => {
  const currentDate = new Date()
  const previousDateTimeEndOfDay = moment(currentDate).subtract(1, 'day').endOf('day').toISOString();
  return previousDateTimeEndOfDay
}

export const getNextDateStartOfDay = () => {
  const currentDate = new Date()
  const nextDateTimeEndOfDay = moment(currentDate).add(1, 'day').startOf('day').toISOString();
  return nextDateTimeEndOfDay
}
export const getStartAndEndDateOfJourney = (startDate: any, endDate: any) => {
  return {
    startDate: moment(startDate).startOf('day').toISOString(),
    endDate: moment(endDate).endOf('day').toISOString(),
  };
};

export const getListOfDatesWithRange = (
  startDate: Date | string | moment.Moment,
  endDate: Date | string | moment.Moment,
  format: string,
): string[] => {
  const start = moment(startDate);
  const end = moment(endDate);

  const dateRange = [];
  while (start.isSameOrBefore(end, 'day')) {
    dateRange.push(start.format(format));
    start.add(1, 'days');
  }
  return dateRange;
};

export const getDefaultRSVPExpiryDateTime = (dateTime:string) => {
  let dateObj: any = dateTime ? moment(dateTime) : undefined;
  if (!dateObj) {
    const currentDate = new Date()
    dateObj = moment(currentDate).add(2, 'hour');
  }
  return dateObj
}

export const getDateToMomentISOStringInUtc = (date?: Date) => {
  const dateObj = date || new Date();
  return moment(dateObj)?.utc()?.toISOString()
}

export const getDateObjectByTimeZone = (date: any, timezone: string) => {
  return moment(date).tz(timezone).toDate();
}


export const isDateInNextMonth = (date:Moment) => {
  const nextMonthStartDate = moment().add(1, 'M').startOf('month');
  return date.isSameOrAfter(nextMonthStartDate);
}

export const isAfter = (
  currentDate: string | Date | Moment,
  referenceDate: string | Date | Moment,
  granularity?: moment.unitOfTime.StartOf) => {
    return moment(currentDate).isAfter(referenceDate, granularity);
}

export const isBefore = (
  currentDate: string | Date | Moment,
  referenceDate: string | Date | Moment,
  granularity?: moment.unitOfTime.StartOf) => {
    return moment(currentDate).isBefore(referenceDate, granularity);
}

const range = (start: number, end: number) => {
  const result = [];
  for (let index = start; index < end; index++) {
    result.push(index);
  }
  return result;
};

export const disabledDateTime = (selectedScheduleDate: any) => ({
  disabledHours: () => {
    const selectedDate = moment(selectedScheduleDate);
    const endOfToday = moment().endOf('day');
    if (selectedDate.valueOf() > endOfToday.valueOf()) {
      return range(0, 0);
    }
    return range(0, 24).splice(0, moment().hours());
  },
  disabledMinutes: () => {
    const selectedDate = moment(selectedScheduleDate);
    const endOfToday = moment().endOf('day')
    const currentHours = moment().hours();
    const selectedHours = moment(selectedScheduleDate).hours();
    if (selectedDate.valueOf() > endOfToday.valueOf() || (selectedHours > currentHours)) {
      return range(0, 0);
    }
    return range(0, moment().minutes() + 3);
  },
});

export const getJourneyStartDateWithOffset = (selectedDate: Date | Moment | string | null) => {
  const currentTime = moment();
  if (!selectedDate) {
    selectedDate = currentTime;
  }
  const date = moment(selectedDate);
  let journeyStartDateTime = date.startOf('day').add(8, 'hours');

  if (journeyStartDateTime.valueOf() < currentTime.valueOf()) {
    journeyStartDateTime = currentTime;
  }

  return journeyStartDateTime;
}

export const getFormTaskDates = () => {
  const currentDate = moment();
  return {
    startDate: currentDate.startOf('day').toISOString(),
    endDate: currentDate.add(30, 'days').endOf('day').toISOString(),
  }
}

export const getNormalTaskDates = (date: string) => {
  // NOTE: DIRTY HACK to fix momentjs issue where JSC doesnot handle date manipulations where the format includes - (hyphen character)
  // so replacing hyphen with / (slash character) to fix the issue
  const dateWithoutHyphens = date.replace(/-/g, '/');
  const momentDate = moment(dateWithoutHyphens);
  return {
    startDate: isPastDay(momentDate) ? momentDate.startOf('day').toISOString() : moment().startOf('day').toISOString(),
    endDate: momentDate.endOf('day').toISOString(),
  }
}

export const DATE_UNIT_CONST: string[] = ['minutes', 'hours', 'days'];

export const isValidDate = (date: Date | string) => {
  const momentDate = momentTz(date);
  return momentDate?.isValid() || false;
};

export const isPastDateTime = (date: string | Date | momentTz.Moment) => {
  const momentDateValue = momentTz(date).valueOf();
  if (momentDateValue < momentTz().valueOf()) {
    return true;
  }
  return false;
}
export const isDateStringIsValid = (dateString: string , fromFormat = 'YYYY-MM-DD' ) => {
  return moment(dateString, fromFormat, true).isValid()
}
export const getDateRangeForAppointmentSlots = (slotStartTime: string | undefined, slotEndTime: string | undefined) => {
  const dateRange = {
    startDate: slotStartTime ? moment(slotStartTime).startOf('day').format(DATE_FORMATS.DISPLAY_DATE_FORMAT) : moment().startOf('day').format(DATE_FORMATS.DISPLAY_DATE_FORMAT),
    endDate: slotEndTime ? moment(slotEndTime).endOf('day').format(DATE_FORMATS.DISPLAY_DATE_FORMAT) : moment().add(MAX_FUTURE_DATE_RANGE, 'days').endOf('day').format(DATE_FORMATS.DISPLAY_DATE_FORMAT)
  }

  return dateRange;
};

export const isValidUnitType = (unit: string) => {
  const validUnitTypes = [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'weeks',
    'seconds',
  ];
  if (validUnitTypes.includes(unit)) {
    return true;
  }
};

export const addDurationInDate = (duration: number, unit: moment.unitOfTime.DurationConstructor, isUtc?: boolean) => {
  if(isUtc){
    return moment().utc().add(duration, unit);
  }
  return moment().add(duration, unit);
}

export const getDefaultStartAndEndDateForNoteTask = () => {
  return {
    startDateTime: moment().toISOString(),
    endDateTime: moment().add(48, 'hours').toISOString()
  }
}
export const isPastDayOfCalendar = (date: moment.Moment, timezone?: string): boolean => {
  const startOfCurrentDay = timezone ? moment.tz(timezone as string).startOf('day') : moment().startOf('day');
  const passDateStartOfTheDay = timezone ? moment(date).tz(timezone as string).startOf('day') : moment(date).startOf('day');
  const isPastDay = passDateStartOfTheDay < startOfCurrentDay;
  return isPastDay;
};

export const getFirstDateByMonthAndYear = (month: number, year: number) => {
  return moment([year, month - 1]).toDate().toString();
}

export const getStartOfMonthFromPastMonths = (monthsToSubtract: number) => {
  return moment()
    .subtract(monthsToSubtract, 'month')
    .startOf('month')
}

export const getCurrentMonthEndDateTime = () => {
  return moment().endOf('month')
}

export const subtractMilliSeconds = (date: Date, number: number) => {
  return moment(date).subtract(number, 'milliseconds');
}

export const getMonthAndYearFromDate = (dateString: string, timezone: string) => {
  return moment.utc(dateString).utcOffset(timezone).format(DATE_FORMATS.NOTE_TIMELINE_MONTH_VIEW_FORMAT);
}

export const getMonthStartAndEndDateTime = (dateString: string, timezone: string) => {
  return {
    monthStartDateTime: moment.utc(dateString).utcOffset(timezone).startOf('month').toISOString(),
    monthEndDateTime: moment.utc(dateString).utcOffset(timezone).endOf('month')?.toISOString()
  }
}

export const getCurrentDateTimeMoment = () => {
  return moment(new Date())
}

export const getCurrentMonthStartDateTime =() => {
  return moment().startOf('month').toISOString()
}

export const getPreviousMonthsStartDateTime =(month: number) => {
  return moment().subtract(month, 'months').startOf('month').toISOString()
}

export const isMomentObject = (date: any) => {
  return date && moment.isMoment(date);
}

export const getEndOfDayOfTimezone = (date: Date, timezone: string) => {
  const displayDateTimeFormat = `YYYY-MM-DD HH:mm:ss`;
  return moment(
    moment(date).tz(timezone).format(displayDateTimeFormat),
    displayDateTimeFormat
  ).endOf('day');
};

export const isDateBetweenLastDays =(dateString: string, dayCount: number) =>{
  const dateToCheck = moment(dateString);
  const currentDate = moment();
  const twelveMonthsAgo = currentDate.clone().subtract(dayCount, 'days');

  return dateToCheck.isBetween(twelveMonthsAgo, currentDate, null, '[]');
}

export const isDateBetweenFutureDays =(dateString: string, dayCount: number) =>{
  const dateToCheck = moment(dateString);
  const currentDate = moment();
  const twelveMonthsAfter = currentDate.clone().add(dayCount, 'days');

  return dateToCheck.isBetween(currentDate, twelveMonthsAfter, null, '[]');
}

export const convertMinsToHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return { hours, minutes };
}

export const convertHoursToMins = (hours: number) => {
  return hours * 60;
}

export const convertSecondsToMins = (seconds: number) => {
  return seconds / 60;
}

export const convertMinsToHours = (mins: number) => {
  return mins / 60;
}

export const getTimeDifferenceInMinutes = (
  start: Date | string,
  end: Date | string,
  returnMinsOnly?: boolean
): string | number => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  const minutes = duration.asMinutes();

  if(returnMinsOnly) {
    return minutes;
  }

  if (minutes > 60) {
    return duration.asHours() + ' Hr';
  }

  return minutes + ' min';
};

export const getDatesBetweenTwoDates = (date1: Date, date2: Date, unit: DurationInputArg2) : Date[] => {
  let startDate =  moment(date1)
  const endDate = moment(date2)

  const days = [];

  while(startDate < endDate){
    days.push(startDate.toDate())
    if(unit === 'days') {
      startDate = moment(startDate).add(1, unit).startOf('day')
    }
    else {
      startDate = moment(startDate).add(1, unit)
    }
  }

  return days
}
export const getEndOfUpcomingSunday = (date: Date | string): Date => {
  return moment(date).endOf('week').add(1, 'day').toDate();
};

export const convertToISODate = (dateStr: string) => {
  return moment(dateStr).toISOString();
};
