import {gql} from '@apollo/client';

// {
//   ids: [uuid]
//   name: String
//   typeCodes: [String]
//   limit: Int
//   offset: Int
//   orderBy: {
//     createdOn: 'asc' | 'desc',
//     name: 'asc' | 'desc'
//   }
// }
export const GetTaskPool = gql`
  query GetUserPools($params: GetUserPoolsInput!) {
    getUserPools(params: $params) {
      userPools {
        id
        name
        userPoolUsers {
          id
          userId
          user
          isDefault
        }
        userPoolLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
      }
      aggregate
    }
  }
`;

export const AddOrUpdateTaskPool = gql`
  mutation AddOrUpdateTaskPool($params: TaskPoolInput!) {
    addOrUpdateTaskPool(params: $params) {
      name
      id
    }
  }
`;

export const CreateTaskPool = gql`
  mutation CreateTask($data: user_pool_insert_input!) {
    createUserPool(object: $data) {
      id
      name
      userPoolUsers {
        id
        userId
        isDefault
      }
    }
  }
`;

export const CreateTaskPoolAccess = gql`
  mutation createUserPoolAccess($data: user_pool_access_insert_input!) {
    createUserPoolAccess(object: $data) {
      id
      userId
      user_pool {
        id
        name
        typeId
        userPoolUsers {
          id
          userId
          isDefault
        }
      }
    }
  }
`;

export const GetUsersTaskPoolAccess = gql`
  query getUserTaskAccess($userId: [uuid!]!, $userPoolTypeId: uuid!) {
      userPoolAccesses(
      where: {
        userId: {_in: $userId}
        isDeleted: {_eq: false}
        user_pool: {typeId: {_eq: $userPoolTypeId}, isDeleted: {_eq: false}}
      }
    ) {
      id
      userId
      user_pool {
        id
        name
        typeId
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          id
          userId
          isDefault
          isDeleted
        }
      }
    }
  }
`;

export const GetUsersTaskPoolAccessWithActiveUsers = gql`
  query getUserTaskAccess($userId: [uuid!]!, $userPoolTypeId: uuid!) {
    userPoolAccesses(where: {userId: {_in: $userId}, isDeleted: {_eq: false}, user_pool: {typeId: {_eq: $userPoolTypeId}, isDeleted: {_eq: false}, userPoolUsers: {isDeleted: {_eq: false}}}}) {
      id
      userId
      user_pool {
        id
        name
        typeId
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          id
          userId
          isDefault
          isDeleted
        }
      }
    }
  }
`;

export const GetUsersTaskAccess = gql`
  query getUserTaskAccess($userId: [uuid!]!, $userPoolTypeId: uuid!) {
    userPoolUsers(
      distinct_on: [userId, userPoolId]
      where: {
        isDeleted: {_eq: false}
        userId: {_in: $userId}
        userPool: {isDeleted: {_eq: false}, typeId: {_eq: $userPoolTypeId}}
      }
    ) {
      id
      userId
      isDefault
      isDeleted
      userPoolId
      userPool {
        name
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          id
          userId
          isDefault
          isDeleted
        }
      }
    }
  }
`;


export const GetAllAccountTaskPools = gql`
  query getUserTaskAccess($userPoolTypeId: uuid!) {
    userPoolUsers(
      distinct_on: [userId, userPoolId]
      where: {
        isDeleted: {_eq: false}
        userPool: {isDeleted: {_eq: false}, typeId: {_eq: $userPoolTypeId}}
      }
    ) {
      id
      userId
      isDefault
      isDeleted
      userPoolId
      userPool {
        name
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          id
          userId
          isDefault
          isDeleted
        }
      }
    }
  }
`;

export const DeleteTaskPool = gql`
  mutation DeleteTaskPool($id: uuid!) {
    updateUserPool(pk_columns: {id: $id}, _set: {isDeleted: true}) {
      id
      name
    }
  }
`;

export const EditTaskPoolName = gql`
  mutation EditTaskPool($id: uuid!, $name: String!) {
    updateUserPool(pk_columns: {id: $id}, _set: {name: $name}) {
      id
      name
    }
  }
`;

export const EditTaskPoolUsers = gql`
  mutation EditTaskPoolUsers($data: [user_pool_users_insert_input!]!) {
    createUserPoolUsers(
      objects: $data
      on_conflict: {
        constraint: user_pool_users_pkey
        update_columns: [isDeleted, isDefault]
      }
    ) {
      affected_rows
      returning {
        id
        userId
        isDeleted
        userPool {
          name
        }
      }
    }
  }
`;


export const SEARCH_TASK_POOL_BY_TEXT = gql`
query getPoolByNameAndType($userPoolTypeId: uuid! $searchString: String) {
  userPools(where: {isDeleted: {_eq: false}, typeId: {_eq: $userPoolTypeId}, name: {_ilike: $searchString}}) {
    id
    name
  }
}
`;

export const SEARCH_TASK_POOL_BY_IDS = gql`
query getPoolByNameAndType($userPoolTypeId: uuid! $poolIds: [uuid]!) {
  userPools(where: {
    isDeleted: {_eq: false},
    typeId: {_eq: $userPoolTypeId},
    id: {_in: $poolIds}
    }) {
      id
      name
  }
}
`;

export const GET_TASK_POOL_BY_IDS = gql`
query getPoolByNameAndType($poolIds: [uuid!]) {
  userPools(where: {
    id: {_in: $poolIds}
    }) {
      id
      name
  }
}
`;

export const GET_USER_POOLS = gql`
  query GetUserPoolsByTypeIdAndUserId($whereCondition: user_pool_bool_exp!) {
    userPools(where: $whereCondition) {
      id
      name
    }
  }
`;

export const SEARCH_TASK_POOL_AND_USER_BY_TEXT = gql`
query getPoolByNameAndType($userPoolTypeId: uuid! $searchString: String) {
  userPools(where: {isDeleted: {_eq: false}, typeId: {_eq: $userPoolTypeId}, name: {_ilike: $searchString}}) {
    id
    name
    userPoolUsers(where: {isDeleted: {_eq: false}}) {
      id
      userId
      isDefault
      isDeleted
    }
  }
}
`;

export const GET_TASK_POOL_BY_AND_POOL_USERS_BY_IDS = gql`
query getPoolByNameAndType($poolIds: [uuid!]) {
  userPools(where: { isDeleted: {_eq: false}, id: {_in: $poolIds} }) {
      id
      name
      userPoolUsers(where: {isDeleted: {_eq: false}}) {
        id
        userId
        isDefault
        isDeleted
      }
  }
}
`;


export default {
  CreateTaskPool,
  GetTaskPool,
  AddOrUpdateTaskPool,
  DeleteTaskPool,
  EditTaskPoolName,
  EditTaskPoolUsers,
  GetUsersTaskPoolAccess,
  CreateTaskPoolAccess,
  GetUsersTaskAccess,
  SEARCH_TASK_POOL_BY_TEXT,
  SEARCH_TASK_POOL_BY_IDS,
  GET_TASK_POOL_BY_IDS,
  GET_USER_POOLS,
  SEARCH_TASK_POOL_AND_USER_BY_TEXT,
  GET_TASK_POOL_BY_AND_POOL_USERS_BY_IDS,
 
};
