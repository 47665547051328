import React from 'react';
import {Svg, Path} from 'react-native-svg';

function SnoozedAlertsSvg() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <Path
        fill="#6F7A90"
        d="M12.961 8.057l-.42.27.42-.27zm.739 1.15l.42-.27-.42.27zm-11.4 0l-.42-.27.42.27zm.739-1.15l.42.27-.42-.27zm9.647 3.958l-.136-.48.136.48zm-9.372 0l.136-.48-.136.48zM6.667 5.5a.5.5 0 100 1v-1zm2.666.5l.354.354a.5.5 0 00-.354-.854V6zM6.667 8.667l-.354-.354a.5.5 0 00.354.854v-.5zm2.666.5a.5.5 0 100-1v1zM5.468 12.49a.5.5 0 00-.936.351l.936-.35zm6 .351a.5.5 0 00-.936-.35l.936.35zM12 6.003v.47h1v-.47h-1zm.542 2.324l.738 1.15.841-.54-.738-1.15-.841.54zm-9.82 1.15l.738-1.15-.841-.54-.738 1.15.84.54zM4 6.473v-.47H3v.47h1zm8.55 5.061c-2.98.843-6.122.843-9.101 0l-.273.962c3.158.894 6.488.894 9.646 0l-.273-.962zM3.458 8.327c.354-.55.542-1.196.542-1.854H3c0 .47-.134.926-.383 1.314l.841.54zM12 6.473c0 .658.188 1.304.542 1.854l.841-.54A2.433 2.433 0 0113 6.473h-1zM1.88 8.936c-.846 1.318-.214 3.132 1.298 3.56l.273-.962c-.833-.236-1.232-1.275-.73-2.057l-.84-.54zm11.4.54c.503.783.104 1.822-.729 2.058l.273.962c1.512-.428 2.144-2.242 1.297-3.56l-.84.54zM8 1.834c2.191 0 4 1.85 4 4.17h1c0-2.837-2.222-5.17-5-5.17v1zm0-1c-2.778 0-5 2.333-5 5.17h1c0-2.32 1.809-4.17 4-4.17v-1zM6.667 6.5h2.666v-1H6.667v1zm2.313-.854L6.313 8.313l.707.707 2.667-2.666-.707-.708zm-2.313 3.52h2.666v-1H6.667v1zm-2.135 3.676C5.044 14.21 6.415 15.167 8 15.167v-1c-1.185 0-2.171-.713-2.532-1.676l-.936.351zM8 15.167c1.585 0 2.956-.957 3.468-2.325l-.936-.35c-.36.962-1.347 1.675-2.532 1.675v1z"
      ></Path>
    </Svg>
  );
}

export default SnoozedAlertsSvg;
