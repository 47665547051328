import {Skeleton, Table} from 'antd';
import {HStack, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions, Pressable} from 'react-native';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {PAGINATION_DIRECTION} from '../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import {getOrdersList} from '../../../../../services/Stripe/StripeServices';
import {Colors} from '../../../../../styles';
import MessagingContactDetailsDrawer from '../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {getMemberShipOrderColumns} from '../../ProductsAndServices/Orders/OrderMainTableView/OrderListView/OrderListViewColumns';
import {getFormattedMembershipOrderList} from '../../ProductsAndServices/Orders/OrdersHelperUtils';
import {styles} from '../CommonStyles';
import {ISubscription} from './interfaces';

const MembershipOrdersList = (props: any) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {searchText} = props;
  const [programsTableState, setProgramsTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedOrder: {} as any,
    searchString: '',
    orderList: [] as any[],
    orderDisplyList: [] as any[],
    ordertMeta: {} as any,
    page: 1,
    sortedInfo: null,
    contactData: {} as any,
    pageSize: 20,
  });
  const [paginationState, setPaginationState] = useState({
    ending_before: '',
    starting_after: '',
    hasMore: true,
    paginationDir: '',
  });
  const starting_after = queryParam.get('starting_after') || '';
  let {sortedInfo}: any = {...programsTableState};
  sortedInfo = sortedInfo || {};
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setProgramsTableState({
      ...programsTableState,
      page: pagination.current,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
    });
  };
  const onRowClicked = (rowData: any) => {
    navigate(`/admin/membership/order/${rowData.id}`, {
      state: {
        orderDetail: rowData.rowData,
      },
      replace: false,
    });
  };
  const getOrderOfMembership = async (dir?: string, startOrEnd?: string) => {
    const orderListResponse = await getOrdersList(
      programsTableState.pageSize,
      // paginationState.paginationDir,
      dir ? dir : '',
      startOrEnd || ''
    ).catch(() => {
      setProgramsTableState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: true,
        };
      });
    });
    if (orderListResponse?.data?.data?.length) {
      const subscriptionList: ISubscription[] =
        orderListResponse?.data?.data || [];
      setPaginationState((prev) => {
        return {
          ...prev,
          ending_before: subscriptionList[0]?.id,
          starting_after: subscriptionList[subscriptionList.length - 1]?.id,
          hasMore: orderListResponse?.data?.has_more,
        };
      });
      const formattedDataList = getFormattedMembershipOrderList(
        orderListResponse?.data.data
      );
      setProgramsTableState((prev) => {
        return {
          ...prev,
          orderList: [],
          orderDisplyList: [...formattedDataList],
          isLoading: false,
        };
      });
    } else {
      setPaginationState((prev) => {
        return {
          ...prev,
          hasMore: false,
        };
      });
      setProgramsTableState((prev) => {
        return {
          ...prev,
          orderList: [],
          orderDisplyList: [],
          isLoading: false,
          hasMore: false,
        };
      });
    }
  };
  const onActionPerformed = (actionCode: string, data: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ON_NAVIGATE_TO_DETAIL:
        setProgramsTableState((prev) => {
          return {
            ...prev,
            contactData: data.contact,
          };
        });
        break;
    }
  };
  useEffect(() => {
    setProgramsTableState((prev) => {
      return {
        ...prev,
        searchString: props.searchText || '',
      };
    });
    setProgramsTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    setTimeout(() => {
      let dir = '';
      let startOrEnd = '';
      if (queryParam.get('ending_before')) {
        dir = PAGINATION_DIRECTION.PREV;
        startOrEnd = queryParam.get('ending_before') || '';
      } else if (queryParam.get('starting_after')) {
        dir = PAGINATION_DIRECTION.NEXT;
        startOrEnd = queryParam.get('starting_after') || '';
      }
      getOrderOfMembership(dir, startOrEnd);
    }, 1000);
  }, [
    programsTableState.page,
    programsTableState.pageSize,
    programsTableState.searchString,
    searchText,
  ]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 310;
  const onPrevClick = () => {
    setPaginationState((prev) => {
      return {
        ...prev,
        paginationDir: PAGINATION_DIRECTION.PREV,
      };
    });
    setSearchParams({['ending_before']: paginationState.ending_before});
    setProgramsTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    getOrderOfMembership(
      PAGINATION_DIRECTION.PREV,
      paginationState.ending_before || ''
    );
  };
  const onNextClick = () => {
    setPaginationState((prev) => {
      return {
        ...prev,
        paginationDir: PAGINATION_DIRECTION.NEXT,
      };
    });
    setSearchParams({['starting_after']: paginationState.starting_after});
    setProgramsTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    getOrderOfMembership(
      PAGINATION_DIRECTION.NEXT,
      paginationState.starting_after || ''
    );
  };
  const getIsPrevDisabled = (dir: string, hasMore: boolean) => {
    return (
      (!dir && !starting_after) ||
      (dir == PAGINATION_DIRECTION.PREV && !hasMore)
    );
  };
  const getIsNextDisabled = (dir: string, hasMore: boolean) => {
    return (!dir && !hasMore) ||
    (dir == PAGINATION_DIRECTION.NEXT && !hasMore)
  };
  return (
    <>
      {programsTableState.isLoading ? (
        <View style={styles.loadingView}>
          <Skeleton active />
        </View>
      ) : (
        <></>
      )}
      {programsTableState.orderList && !programsTableState.isLoading && (
        <>
          <Table
            rowKey={(record) => {
              return record.id;
            }}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            columns={getMemberShipOrderColumns(sortedInfo, onActionPerformed)}
            dataSource={programsTableState.orderDisplyList}
            scroll={{x: 700, y: finalHeight}}
            className="lead-list-table-view strip-payment"
            onChange={onTableChange}
            onRow={(_record: any) => {
              return {
                onClick: () => {
                  onRowClicked(_record);
                },
              };
            }}
            pagination={{
              pageSize: programsTableState.pageSize,
            }}
          />
          <View
            flex={1}
            style={{
              position: 'absolute',
              bottom: 10,
              left: 0,
              zIndex: 101,
              width: '100%',
            }}
          >
            <HStack
              style={{width: '100%', backgroundColor: 'white', height: 40}}
              justifyContent={'center'}
            >
              <View
                cursor={
                  getIsPrevDisabled(
                    paginationState?.paginationDir,
                    paginationState.hasMore
                  )
                    ? 'not-allowed'
                    : ''
                }
              >
                <Pressable
                  disabled={
                    getIsPrevDisabled(
                      paginationState?.paginationDir,
                      paginationState.hasMore
                    )
                      ? true
                      : false
                  }
                  style={{
                    marginHorizontal: 8,
                    width: 80,
                    height: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderRadius: 12,
                    borderColor: Colors.Custom.Gray200,
                    backgroundColor: getIsPrevDisabled(
                      paginationState?.paginationDir,
                      paginationState.hasMore
                    )
                      ? Colors.Custom.Gray25
                      : Colors.Custom.Gray50,
                  }}
                  onPress={() => {
                    onPrevClick();
                  }}
                >
                  <Text
                    size={'smSemibold'}
                    color={
                      getIsPrevDisabled(
                        paginationState?.paginationDir,
                        paginationState.hasMore
                      )
                        ? Colors.Custom.Gray300
                        : Colors.Custom.Gray700
                    }
                  >
                    {' '}
                    {'<- Prev'}
                  </Text>
                </Pressable>
              </View>
              <View
                cursor={
                  getIsNextDisabled(
                    paginationState?.paginationDir,
                    paginationState.hasMore
                  )
                    ? 'not-allowed'
                    : ''
                }
              >
                <Pressable
                  disabled={
                    getIsNextDisabled(
                      paginationState?.paginationDir,
                      paginationState.hasMore
                    )
                      ? true
                      : false
                  }
                  style={{
                    marginHorizontal: 8,
                    width: 80,
                    height: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: Colors.Custom.Gray200,
                    borderRadius: 12,
                    backgroundColor: getIsNextDisabled(
                      paginationState?.paginationDir,
                      paginationState.hasMore
                    )
                      ? Colors.Custom.Gray25
                      : Colors.Custom.Gray50,
                  }}
                  onPress={() => {
                    onNextClick();
                  }}
                >
                  <Text
                    size={'smSemibold'}
                    color={
                      getIsNextDisabled(
                        paginationState?.paginationDir,
                        paginationState.hasMore
                      )
                        ? Colors.Custom.Gray300
                        : Colors.Custom.Gray700
                    }
                  >
                    {'Next->'}
                  </Text>
                </Pressable>
              </View>
            </HStack>
          </View>
        </>
      )}
      {programsTableState?.contactData?.id ? (
        <MessagingContactDetailsDrawer
          contactData={programsTableState?.contactData}
          contactType={
            programsTableState?.contactData?.contactType?.contactType?.code
          }
          isDrawerVisible={programsTableState?.contactData ? true : false}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setProgramsTableState((prev: any) => {
                return {
                  ...prev,
                  contactData: null,
                  contactType: null,
                };
              });
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MembershipOrdersList;
