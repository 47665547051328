import {Drawer, DrawerProps} from 'antd';
import {Skeleton} from 'native-base';
import {useIntl} from 'react-intl';
import {Pressable, View} from 'react-native';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import Divider from './Divider';
import './style.css';
import NewCloseSvg from '../Svg/NewCloseSvg';

const CustomDrawer = (props: {
  onClose: () => void;
  title: string;
  headerButtons?: any[];
  open?: boolean;
  isLoading?: boolean;
  children?: any;
  customization?: DrawerProps;
}) => {
  const intl = useIntl();

  return (
    <>
      <Drawer
        open={props?.open || false}
        onClose={props.onClose}
        className="custom-drawer-sizing"
        title={
          <ModalActionTitle
            title={intl.formatMessage({id: props.title})}
            titleColor={'#000'}
            titleSize={24}
            rightButton={
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                {props?.headerButtons ? (
                  props?.headerButtons?.map((button) => {
                    return (
                      <>
                        {button}
                        <Divider />
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
                <Pressable onPress={props.onClose} disabled={false}>
                  <NewCloseSvg />
                </Pressable>
              </View>
            }
          />
        }
        {...(props?.customization ? props?.customization : {})}
      >
        <View>
          {props?.isLoading ? <Skeleton.Text /> : props?.children || <></>}
        </View>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
