import { Pressable, View, Text } from "react-native";
import {Colors} from "../../../styles";
import ExtensionAddSvg from "../Svg/TelephonySvg/ExtensionAddSvg";
import {IExtensionList} from "../../RightSideContainer/CallConfiguration/interface";


export const ExtensionInfoListItem = (props: {
  extension: IExtensionList
}) => {
    return (
      <>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 6,
        }}>
          <View style={{
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Text>
              {`${props.extension?.extensionName} (${props.extension?.extensionNumber})`}
            </Text>
          </View>
          <View style={{
            width: 10,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Pressable
              onPress={() => {
              }}
            >
              <ExtensionAddSvg/>
            </Pressable>
          </View>
        </View>
        <View style ={{
          borderBottomWidth: 1,
          marginHorizontal: 6,
          borderBottomColor: Colors.FoldPixel.GRAY100
        }}
        >
        </View>
      </>
    );
};
