import { HStack,View,VStack } from 'native-base';
import { useState, useEffect } from 'react';
import ErrorSvg from '../../../assets/svg/ErrorSvg';
import FailedEmailSvg from '../../../assets/svg/FailedEmailSvg';
import FailedSmsSvg from '../../../assets/svg/FailedSMSSvg';
import './Analyticstyles.css';
import { COMMUNICATION_LOG_TAB } from './CommunicationLogChart';
import CommunicationLogChartMemberList from './CommunicationLogChartMemberList';
import { Spinner } from 'native-base';
import DataNotFoundSvg from '../../../assets/svg/DataNotFoundSvg';
import {useIntl} from 'react-intl';

const OtherAnalytics = (props: {
  startDate: string;
  endDate: string;
  emailFailedCount: number;
  smsFailedCount: number;
}) => {
  const [state, setState] = useState({
    isOpen: false,
    emailFailedCount: props.emailFailedCount,
    smsFailedCount: props.smsFailedCount,
    selectedTab: '',
    isLoading: false
  });
  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }, 1000);
    };

    fetchData();
  }, [props.emailFailedCount, props.smsFailedCount]);

  const noDataAvailable =
    !state.emailFailedCount && !state.smsFailedCount;

  return (
    <div style={{ margin: '2px 0' }}>
      {state.isLoading ? (
        <View
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          overflow={'hidden'}
        >
          <Spinner />
        </View>
      ) :
        noDataAvailable ? (
          <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            overflow={'hidden'}
          >
            <DataNotFoundSvg />
            <span
              style={{
                marginTop: '16px',
                fontSize: '14px',
                color: '#8A94A8',
                fontWeight: '400',
              }}
            >
              {intl.formatMessage({id: 'noDataAlert'})}
            </span>
          </View>
        ) : (
          <>
            {state?.isOpen ? (
              <CommunicationLogChartMemberList
                startDate={props.startDate}
                endDate={props.endDate}
                emailCount={state?.emailFailedCount || 0}
                smsCount={state?.smsFailedCount || 0}
                originService={COMMUNICATION_LOG_TAB.OTHER.KEY}
                communicationTypeList={
                  state?.selectedTab === 'EMAIL' ? ['EMAIL'] : ['SMS']
                }
                typeId={''}
                open={state?.isOpen}
                setIsOpen={() => {
                  setState((oldValue: any) => {
                    return {
                      ...oldValue,
                      selectedTab: '',
                      isOpen: false,
                    };
                  });
                }}
              />
            ) : null}

            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                {state.emailFailedCount ? (
                  <tr
                    style={{
                      borderBottom: '1px solid #e0e0e0',
                      width: '100%',
                    }}
                    onClick={() => {
                      setState((oldData) => {
                        return { ...oldData, isOpen: true, selectedTab: 'EMAIL' };
                      });
                    }}
                  >
                    <td style={{ padding: '8px 0', width: '100%' }}>
                      <HStack space={4} alignItems="center">
                        <FailedEmailSvg />
                        <View>
                          <VStack>
                            <span
                              style={{
                                color: '#4A4A4A',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '16.8px',
                              }}
                            >
                              {'Failed Email'}
                            </span>
                            <HStack alignItems="center" style={{ marginTop: '8px' }}>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ErrorSvg />
                              </div>
                              <span
                                style={{
                                  color: '#D92D20',
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '14.4px',
                                  marginLeft: '2px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {props.emailFailedCount + ' Emails Failed to send'}
                              </span>
                            </HStack>
                          </VStack>
                        </View>
                      </HStack>
                    </td>
                  </tr>
                ) : null}

                {state.smsFailedCount ? (
                  <tr
                    style={{
                      borderBottom: '1px solid #e0e0e0',
                      width: '100%',
                    }}
                    onClick={() => {
                      setState((oldData) => {
                        return { ...oldData, isOpen: true, selectedTab: 'SMS' };
                      });
                    }}
                  >
                    <td style={{ padding: '8px 0', width: '100%' }}>
                      <HStack space={2} alignItems="center">
                        <FailedSmsSvg />
                        <View>
                          <VStack>
                            <span
                              style={{
                                color: '#4A4A4A',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '16.8px',
                              }}
                            >
                              {'Failed SMS'}
                            </span>
                            <HStack alignItems="center" style={{ marginTop: '8px' }}>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ErrorSvg />
                              </div>
                              <span
                                style={{
                                  color: '#D92D20',
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '14.4px',
                                  marginLeft: '2px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {props.smsFailedCount + ' SMS Failed to send'}
                              </span>
                            </HStack>
                          </VStack>
                        </View>
                      </HStack>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </>
        )}
    </div>
  );
};

export default OtherAnalytics;