import {Table, Spin} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {TableWrapper} from '../../common/TableWrapper';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {getCallConfigurationColumns} from './Helper';
import {ICallExtensionTable, IExtensionList} from './interface';
import './style.css'


export const CallExtensionTable = (props: ICallExtensionTable) => {
  const { data, onActionPerformed , loading, pageSize, pageNo, total } = props;
  const screenHeight = Dimensions.get('window').height;

  const onCallConfigActionPerformed = (
    actionCode: string,
    rowRecord: IExtensionList
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        return onActionPerformed(actionCode, rowRecord);
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        return onActionPerformed(actionCode, rowRecord);
      case COMMON_ACTION_CODES.DELETE:
        return onActionPerformed(actionCode, rowRecord);
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        return onActionPerformed(actionCode, rowRecord);
      default:
        return;
    }
  };

  return (
    <View flex={1}>
      <TableWrapper
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          onChange(page, pageSize) {
            if (props?.onPagination) {
              props.onPagination(page, pageSize);
            }
          },
          showSizeChanger: true,
        }}
        hideOnSinglePage={false}
        customStyle={{zIndex: 999}}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getCallConfigurationColumns(
            onCallConfigActionPerformed
          )}
          rowClassName={(record, index) => {
            const isDisabled = record?.isDisabled;
            return isDisabled ? 'disabled-row' : ''
          }}
          onRow={(row) => {
            return {
              onClick: () => {
                if (row?.isDisabled) {
                  return;
                }
                onActionPerformed(COMMON_ACTION_CODES.EDIT, row);
              },
            };
          }}
          loading={{
            spinning: loading,
            indicator: (
              <View style={{
                justifyContent: 'center',
                height: '100%'
              }}>
                <Spin/>
              </View>
            ),
          }}
          dataSource={data}
          pagination={false}
          rowKey={(record: any) => record.id}
        />
      </TableWrapper>
    </View>
  );
};
