import { StyleSheet } from 'react-native';
import { Colors } from '../../styles/Colors';

export const styles = StyleSheet.create({
  mainContainer: {
    paddingBottom: 0,
    backgroundColor: Colors.Custom.Gray100,
    minHeight: '100vh',
  },
  baseContainer: {
    overflow: 'scroll',
    maxHeight: '85vh',
    minHeight: '94vh',
  },
  headerContainer: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    maxHeight: '15vh',
    minHeight: 50,
    borderBottomWidth: 0.5,
    borderColor: Colors.Custom.Gray400
  },
  leftContainer: {
    backgroundColor: Colors.Custom.Gray50,
    paddingTop: 2,
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: Colors.Custom.Gray400
  },
  middleContainer: {
    // backgroundColor: Colors.Custom.BackgroundColor,
    // borderRightWidth: 1,
    // borderLeftWidth: 1,
    // borderTopWidth: 1,
    // borderColor: Colors.Custom.Gray200,
    borderTopWidth: 0.5,
    borderColor: Colors.Custom.Gray400
  },
  rightContainer: {
    backgroundColor: 'coolGray.50',
    borderTopWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: Colors.Custom.Gray400
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionBtnContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});
