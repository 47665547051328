import React from 'react';
import { isWeb } from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const ExtensionUserSvg = () => {
  return (
    <>
      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3C7 4.10457 6.10457 5 5 5C3.89543 5 3 4.10457 3 3C3 1.89543 3.89543 1 5 1C6.10457 1 7 1.89543 7 3Z" stroke="#8A94A8"/>
      <path d="M9 8.75C9 9.99264 9 11 5 11C1 11 1 9.99264 1 8.75C1 7.50736 2.79086 6.5 5 6.5C7.20914 6.5 9 7.50736 9 8.75Z" stroke="#8A94A8"/>
      </svg>
    </>
  );
};

export default ExtensionUserSvg;
