import React from 'react';
import {Svg, Path} from 'react-native-svg';

function AssessmentSvg() {
  return (
    <Svg width="16" height="17" fill="none" viewBox="0 0 16 17">
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M12.12 5.86l.309-.31a1.31 1.31 0 111.854 1.854l-.31.31M12.12 5.86s.039.656.618 1.235c.58.58 1.236.618 1.236.618M12.12 5.86L9.28 8.7a4.556 4.556 0 00-.372.395c-.097.125-.18.26-.25.403-.057.122-.1.25-.186.509l-.365 1.094m5.867-3.388l-2.84 2.84a4.561 4.561 0 01-.395.372c-.125.098-.26.181-.404.25-.121.057-.25.1-.509.186l-1.093.365m0 0l-.268.09a.353.353 0 01-.447-.448l.09-.267m.625.625l-.626-.625M5.333 9.167H7M5.333 6.5h4.334m-4.334 5.333h1m6.886-9.219c-.781-.78-2.038-.78-4.552-.78H7.333c-2.514 0-3.77 0-4.552.78C2 3.395 2 4.653 2 7.167v2.666c0 2.514 0 3.772.781 4.553.781.78 2.038.78 4.552.78h1.334c2.514 0 3.77 0 4.552-.78.629-.63.751-1.566.775-3.22"
      ></Path>
    </Svg>
  );
}

export default AssessmentSvg;
