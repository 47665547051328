import React, { useContext } from 'react';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../constants/MlovConst';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { getUserUUID } from '../../../utils/commonUtils';
import { useApolloClient, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { GET_AGGREGATE_TASK_COUNT, GET_TASK_ALERTS, UPDATE_ALERT_PREFERENCE } from '../../../services/Task/TaskQueries';
import { IAlertActionReasons, IAlertsViewActionsState, IAlertsViewComponentState } from '../interfaces';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useToast } from '../../Toast/ToastProvider';
import { useIntl } from 'react-intl';
import { IMlov } from '../../../Interfaces';
import { ITasksAttachments } from '../../common/CareDashboard/CareDashboardInterfaces';

export interface ITaskAlert {
  id: string;
  priorityId: string;
  title: string;
  description: string;
  taskDisplayCategoryId: string;
  taskTypeId: string;
  startDateTime?: string;
  statusId?: string;
  taskActionConfigs: {
    taskTypeActionId?: string;
  }[];
  taskAlertDisplayPreferences: {
    userId: string;
    reasonId: string;
    note: string;
    taskAlertDisplayCategoryId: string;
  }[];
  attachments: ITasksAttachments[];
}

export interface UseFetchAlertsParams {
  statusCode: string;
  page?: number;
  pageSize?: number;
  searchText?: string;
  filters?: IAlertsViewComponentState['filters'];
  prioritySort?: any;
  taskTypeIds?: string[]
}

const useFetchAlerts = (params: { contactId?: string }) => {
  const client = useApolloClient();
  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const userUuid = getUserUUID();
  const taskAlertDisplayCategoryMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
    ) || [];
  const [updateAlertPreference, { loading: alertStatusChangeLoading }] = useMutation(UPDATE_ALERT_PREFERENCE, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onError: (error: any) => {
      showToast(
        toast,
        intl.formatMessage({ id: 'apiError' }),
        ToastType.error,
      );

    },
  });

  const getVariablesForQuery = ({ statusCode, page, pageSize, searchText, filters, prioritySort,taskTypeIds }: UseFetchAlertsParams) => {
    const displayCategoryId = getMlovIdFromCode(
      taskAlertDisplayCategoryMlovs,
      statusCode
    )
    return {
      searchString: searchText,
      assigneeIds: [userUuid],
      taskAlertDisplayCategoryIds: [displayCategoryId],
      ...(pageSize && { limit: pageSize }),
      ...(pageSize && page && { offset: pageSize * (page - 1) }),
      priorityIds: filters?.formData.selectedPriorityList?.map((priority) => priority.id) || [],
      includeOnlyAlerts: true,
      contactIds: [params?.contactId],
      taskTypeIds: filters?.formData?.selectedTaskAlertTypeList?.length
        ? filters?.formData?.selectedTaskAlertTypeList?.map(
            (alertType) => alertType.id
          )
        : taskTypeIds?.length
        ? taskTypeIds
        : [],
        orderBy: {
          priority: (!!prioritySort && prioritySort !== 'default') ? prioritySort : 'desc'
        }
    }
  }

  const fetchData = async (
    searchText: string,
    paramsArray: {
      params: UseFetchAlertsParams;
      stateKeyToUpdate: string;
      loadingKeyToUpdate: string;
      totalCountKeyToUpdate: string;
    }[],
    codesForAggregateQuery?: string[],
    paramsForAggregateQuery?: { filters: IAlertsViewComponentState['filters'] }
  ) => {
    const fetchPromises =
      paramsArray.map(async ({ params }) => {
        return client.query({
          query: GET_TASK_ALERTS,
          variables: {
            params: getVariablesForQuery({ ...params, searchText: searchText, }),
          },
          context: { service: CARESTUDIO_APOLLO_CONTEXT },
          fetchPolicy: 'no-cache',
        });
      }) || [];
    if (!!codesForAggregateQuery && codesForAggregateQuery?.length > 0) {
      codesForAggregateQuery.forEach(async (code) => {
        fetchPromises.push(
          client.query({
            query: GET_AGGREGATE_TASK_COUNT,
            variables: {
              params: getVariablesForQuery({
                ...paramsForAggregateQuery,
                statusCode: code,
                searchText: searchText,
              }),
            },
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
            fetchPolicy: 'no-cache',
          })
        );
      });
    }

    try {
      const results = await Promise.all(fetchPromises);
      const mappedResults = results.map((result, index) => {
        const alertData = result?.data?.getTasks.tasks || [];
        const totalAlertCount = result?.data?.getTasks?.aggregate?.total || 0;
        return { data: alertData, totalCount: totalAlertCount };
      });
      return mappedResults;
    } catch {
      return [];
    }
  };

  const handleAlertStatusChange = async (selectedTaskAlertForAction: IAlertsViewActionsState['selectedTaskAlertForAction'], displayCategoryStatusCode: string, onStatusChangeCompleted: (displayCategoryStatusCode?: string) => void, selectedReason?: IAlertActionReasons, note?: string, snoozeValidTill?: string) => {
    const alertId = selectedTaskAlertForAction?.id
    const displayCategoryId = getMlovIdFromCode(
      taskAlertDisplayCategoryMlovs,
      displayCategoryStatusCode
    )
    await updateAlertPreference({
      variables: {
        params: {
          taskId: alertId,
          taskAlertDisplayPreferenceId: displayCategoryId,
          reasonId: selectedReason?.taskAlertDisplayPreferenceReasonId,
          note: note || '',
          validTillTimestamp: snoozeValidTill,
        },
      }
    });

    onStatusChangeCompleted(displayCategoryStatusCode)
  }

  return { fetchData, handleAlertStatusChange, loaders: { statusChangeloading: alertStatusChangeLoading } };
};

export default useFetchAlerts;
