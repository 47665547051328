import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer, notification, Select as MultiSelect, Select, Tooltip} from 'antd';
import {
  Button,
  Center,
  FormControl,
  HStack,
  Icon,
  Input,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Image,
  Checkbox
} from 'native-base';
import React, {createRef, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {
  BUTTON_TYPE,
  COLLABORATORS_INFO,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  setSmsInboxErr,
  SMALL_WINDOW_1400,
  SMALL_WINDOW_1700,
} from '../../../../../../constants';
import {CRM_URLs} from '../../../../../../constants/BaseUrlConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../../Interfaces';
import {UserQueries} from '../../../../../../services';
import CloudTelephonyQueries from '../../../../../../services/CloudTelephony/CloudTelephonyQueries';
import BaseService from '../../../../../../services/CommonService/BaseService';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {
  IChannelTwilioSms,
  IChannelTwilioSmsList,
  IInbox,
  IInboxMember,
} from '../../../../../../services/Inbox/interfaces';
import {IUserDetailData} from '../../../../../../services/User/interface';
import {Colors} from '../../../../../../styles';
import {
  getAccountId,
  getAccountUUID,
} from '../../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {NoDataFound} from '../../../../../common/NoDataFound';
import PhoneRingSvg from '../../../../../common/Svg/PhoneRingSvg';
import {IVirtualPhoneNumberResp} from '../../../../CloudTelephony/interfaces';
import {IUsersResponse} from '../../../../Contacts/TeamMembers/interfaces';
import {
  IAddUser,
  IDeletedInboxData,
  IInboxMembers,
  ISmsInboxDrawerProps,
  ISmsInboxDrawerState,
  IVirtualPhoneNumberDisplay,
} from './interfaces';
import {SMS_INBOX_ACTION_CODES} from './SmsInboxConst';
import './SmsInboxCreateCss.css';
import {styles} from './SmsInboxStyle';
import {
  checkNumberExistInVirtualPhoneAndSmsList,
  getUpdatedInboxErrorMessages,
  getVirtualNoFormattedList,
} from './SmsInboxUtils';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { ModalActionUserMultiSelect } from '../../../../../common/ModalActionCommonComponent/ModalActionUserMultiSelect';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import {capitalizeText} from '../../../../ContentManagement/CampaignManagement/Helper';
import { CHANNEL_TYPE } from '../../../Conversations/ConversationConst';
import {addOrUpdateInboxMembersAPI, createInboxMembersAPI} from './SmsService';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import { TEAM_MEMBERS_ROLES_CODE } from '../../../../Contacts/TeamMembers/AddEditUser/TeamMemberConst';
import {CustomUserSearch} from '../../../../../common/CustomUserSearch/CustomUserSearch';
import {IInternalUsers} from '../../../../../common/CustomUserSearch/interface';
import {
  getFormattedInboxMembers,
  getUserIdsFromUserData,
} from '../../../Conversations/MessagingWindow/MessagingUtils';
import {getSelectedUsersUUIDs} from '../../../../../common/CustomUserSearch/CustomUserSearchUtils';
import { USER_SEARCH_TYPE } from '../../../../constants';

const {Option} = MultiSelect;
const SmsInboxDrawer = (props: ISmsInboxDrawerProps) => {
  const smsInboxToast = useToast();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const assigneesTypeList = mlovData['VirtualNumberAssignee'] || [];
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const smsFormRef: any = createRef();
  const {
    isDrawerVisible,
    allUserList,
    selectedInboxData,
    onSmsInboxActionPerformed,
  } = props;

  const phoneNumber = props?.selectedPhoneNumber
  ? props?.selectedPhoneNumber
  : '';

  const filteredUsers = allUserList?.filter((item) => {
    const user = item.user
    let roles = '';
    user.userRoles?.forEach((element: any) => {
      roles += element?.userRole?.userRole?.code;
    });
    if (
      !roles.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) &&
      !roles.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS)
    ) {
      return item;
    }
  });

  const [smsInboxState, setSmsInboxState] = useState<ISmsInboxDrawerState>({
    selectedUsersIds: [],
    selectedPhoneNumber: props?.selectedPhoneNumber
      ? props?.selectedPhoneNumber
      : '',
    isLoading: false,
    errors: {} as any,
    allVirtualPhoneNumbers: [],
    selectedUsers: [] as any[],
    isNumberExistInVirtualPhoneAndSmsList: false,
    enableAutoAssignment: false,
    selectedUsersUuids: [] as string[],
    selectedUsersData: [] as IInternalUsers[],
  });
  const enableChatHistoryRead = isAccountConfigEnabled(CONFIG_CODES.ENABLE_CHAT_HISTORY_READ)
  const {width} = Dimensions.get('window');
  const [updateInboxName] = useMutation(InboxQueries.updateSMSInboxName);
  const {loading: userDetailsLoading, data: allUserVirtualNumbers} =
    useQuery<IUserDetailData>(UserQueries.GetUserDetails, {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        accountId: userData.account_id,
      },
    });

  const {loading: twilioSmsLoading, data: channelData} =
    useQuery<IChannelTwilioSms>(InboxQueries.GetTwilioSmsChannelFromId, {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        id: selectedInboxData?.channel_id,
      },
    });
  const {loading: allSmsChannelLoading, data: channelDataList} =
    useQuery<IChannelTwilioSmsList>(InboxQueries.GetChannelTwilioSmsList, {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    });

  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getUsersByUuid] = useLazyQuery(UserQueries.GET_USER_BY_UUID_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      uuidList: smsInboxState.selectedUsersUuids,
    },
  });

  const [getAllNonDeletedInboxesMembers] = useLazyQuery<{
    inboxMembers: IInboxMember[]
  }>(InboxQueries.GetAllNonDeletedInboxesMembers, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [deleteInboxesMembers] = useMutation(InboxQueries.deleteInboxesMembers);
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);
  const [getInboxById] = useLazyQuery(InboxQueries.GetInboxById);
  const [getInboxesDataByPhoneNumber] = useLazyQuery<{inboxes: IInbox[]}>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getRoles = (agentRecord: any) => {
    return (agentRecord?.user?.userRoles || agentRecord?.userRoles || []).map((role: any) => {
      return (
        <Text mr={1} fontSize={11} fontWeight={400} color={'gray.500'}>{role?.userRole?.userRole?.value + ','}</Text>
      );
    });
  };

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });

  const getNumbersList = async () => {
    const responses = await GET_VIRTUAL_PHONE_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        searchString: '%%',
      },
    });

    const userDataList = await getUsersData();
    const formattedAllVirtualPhoneNumbers: IVirtualPhoneNumberDisplay[] =
      getVirtualNoFormattedList(
        responses?.data?.virtualPhoneNumberAssignees || [],
        assigneesTypeList,
        userDataList.data?.users || [],
        channelDataList?.channelTwilioSmsList || []
      );

    const selectedPhoneNumber =
      props?.selectedPhoneNumber ||
      channelData?.channelTwilioSms?.phoneNumber ||
      formattedAllVirtualPhoneNumbers?.[0]?.phoneNumber?.phoneNumber ||
      '';
    const selectedUserIds = getDefaultSelectedUsers();
    const selectedUsers = selectedUserIds?.map((id)=>{
      const user = allUserList?.find((user)=>{
        return user?.userId == id
      })
      return user
    })
    const isNumberExistInVirtualPhoneAndSmsList =
      checkNumberExistInVirtualPhoneAndSmsList(
        selectedPhoneNumber,
        responses?.data?.virtualPhoneNumberAssignees || [],
        channelDataList?.channelTwilioSmsList || []
      );
      const formattedInboxMembers = getFormattedInboxMembers(
        props?.selectedInboxData?.singleInboxData?.inboxMembers
      ) as IInternalUsers[];
    setSmsInboxState((prev) => {
      return {
        ...prev,
        selectedPhoneNumber,
        allVirtualPhoneNumbers: formattedAllVirtualPhoneNumbers,
        selectedUsersIds: selectedUserIds || [],
        isLoading: false,
        selectedUsers: selectedUsers,
        isNumberExistInVirtualPhoneAndSmsList: !props.selectedPhoneNumber
          ? false
          : isNumberExistInVirtualPhoneAndSmsList,
        selectedUsersData: formattedInboxMembers,
        selectedUsersUuids: getSelectedUsersUUIDs(formattedInboxMembers),
      };
    });
  };

  const closeModal = () => {
    onSmsInboxActionPerformed(SMS_INBOX_ACTION_CODES.DRAWER_CLOSE);
  };

  const onSmsInboxCreateOrUpdate = () => {
    const errorData = getUpdatedInboxErrorMessages(smsInboxState, smsFormRef);
    if (errorData?.isErrorPresent) {
      setSmsInboxState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
            isLoading: false
          },
        };
      });
    } else {
      createOrUpdateSmsInbox();
    }
  };
  const onUserSelect = (selectedData: any[]) => {
    const userIds = selectedData
    const selectedUsers = userIds?.map((id)=>{
      const user = filteredUsers?.find((user)=>{
        return ((user?.userId ?? user?.id) == id)
      })
      return user
    })
    setSmsInboxState((prev) => {
      return {
        ...prev,
        selectedUsersIds: userIds,
        selectedUsers: selectedUsers
      };
    });
  };


  const onPhoneNumberSelect = (selectedPhoneNumber: string) => {
    setSmsInboxState((prev) => {
      return {
        ...prev,
        selectedPhoneNumber,
      };
    });
  };

  const isInboxPresent = (): boolean => {
    if (selectedInboxData?.singleInboxData?.id) {
      return true;
    }
    return false;
  };
  const createSmsInbox = () => {
    const baseService = BaseService.getSharedInstance().axios;
    const createSmsInboxUrl = `/cloud-telephony/inbox/twillio`;
    let inboxName = '';
    if (smsFormRef?.current?.value) {
      inboxName = smsFormRef?.current?.value;
    }
    const bodyData = {
      twilio_channel: {
        name: inboxName,
        medium: 'sms',
        phone_number: smsInboxState.selectedPhoneNumber,
      },
      smsCallBackUrl: CRM_URLs.CHATWOOT + '/twilio/callback',
      accountId: userData.account_id,
      enable_auto_assignment: smsInboxState.enableAutoAssignment,
    };
    baseService
      .post(createSmsInboxUrl, JSON.stringify(bodyData))
      .then((resp) => {
        updateInboxUser(resp.data.id, true);
      })
      .catch((error: any) => {

        const errMsg =
          error?.response?.data?.error ||
          intl.formatMessage({id: 'apiErrorMsg'});
        showToast(smsInboxToast, errMsg, ToastType.error);
        setSmsInboxState(prev=> {
          return {
            ...prev,
          isLoading: false
          }
        })
      });
  };

  const updateSmsInbox = async () => {
    setSmsInboxState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    let inboxName = '';
    if (smsFormRef?.current?.value) {
      inboxName = smsFormRef?.current?.value;
    }
    const updatedInboxData = await updateInboxName({
      variables: {
        id: selectedInboxData?.singleInboxData?.id,
        name: inboxName,
        enableAutoAssignment: smsInboxState.enableAutoAssignment,
        inboxType: null,
      },
    });
    updateInboxUser(updatedInboxData?.data?.updateInbox?.id, false);
  };

  const checkInboxIfDeletedSmsInbox = async () => {
    const baseService = BaseService.getSharedInstance().axios;
    const checkExistingInboxInboxUrl = `/crm-nest/inbox/check_existing_inbox`;
    let inboxName = '';
    if (smsFormRef?.current?.value) {
      inboxName = smsFormRef?.current?.value;
    }
    const bodyData = {
      phoneNumber: smsInboxState.selectedPhoneNumber,
    };
    const inboxData = await baseService.post<IDeletedInboxData>(checkExistingInboxInboxUrl, JSON.stringify(bodyData));
    if (inboxData?.data?.inbox?.id) {
      const deletedInboxData = await getInboxById({
        fetchPolicy: 'no-cache',
        variables: {
          accountId: getAccountId,
          id: inboxData?.data?.inbox?.id,
        }
      });
    }
    return {};
  };

  const getInboxUserIds = (inboxMembers: any[]) => {
    const inboxMemberUserIds: any = [];
    inboxMembers.forEach((singleMember) => {
      if (singleMember.userId) {
        inboxMemberUserIds.push(singleMember.userId)
      }
    });
    return inboxMemberUserIds;
  }

  const updateInboxUser = async (inboxId: number, isCreate: boolean) => {
    const smsCreateOrUpdateMsg = isCreate
      ? 'smsInboxCreateSuccess'
      : 'smsInboxUpdatedSuccess';
    const userData = await getUsersByUuid({
      variables: {
        uuidList: smsInboxState.selectedUsersUuids,
      },
    });
    const selectedUserIds = getUserIdsFromUserData(userData?.data?.users) || [];
    const addNewUserList: IAddUser[] = [];
    const inboxMembers =
      props?.selectedInboxData?.singleInboxData?.inboxMembers || [];
    const inboxMemberUserIds = inboxMembers?.map((item) => {
      return item?.userId;
    });
    selectedUserIds.forEach((singleUserId: any) => {
      if (!inboxMemberUserIds?.includes(singleUserId)) {
        addNewUserList.push({
          userId: singleUserId,
          inboxId: props?.selectedInboxData?.singleInboxData?.id,
          isDeleted: false,
        });
      }
    });
    if (inboxMembers?.length) {
      const userObjectList: IInboxMembers[] = [];
      const deleteUserList: any = [];
      inboxMembers?.forEach((singleMember) => {
        if (!selectedUserIds.includes(singleMember.userId)) {
          deleteUserList.push(singleMember.userId);
          userObjectList.push({
            userId: singleMember.userId,
            inboxId: inboxId,
            isDeleted: true,
          });
        }
      });

      const addNewUserList: any = [];
      const inboxMemberUserIds = getInboxUserIds(inboxMembers);
      selectedUserIds.forEach((singleUserId) => {
        if (!inboxMemberUserIds.includes(singleUserId)) {
          addNewUserList.push({
            userId: singleUserId,
            inboxId: inboxId,
            isDeleted: false,
          });
        }
      });
      userObjectList.push(...addNewUserList);

      if (enableChatHistoryRead) {
        const deleteMemberData = await deleteInboxesMembers({
          variables: {
            inboxId,
            userIds: deleteUserList
          }
        });
        const addedMemberData = await createInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
        // const addedMemberData = await addOrUpdateInboxMembers({memberList: userObjectList, inboxId: inboxId});
      } else {
        const addedMemberData = await addOrUpdateInboxMembersAPI({memberList: userObjectList, inboxId: inboxId});
        // const addedMemberData = await createInboxMembers({
        //   variables: {
        //     objects: addNewUserList,
        //   }
        // });
      }
      setSmsInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      const addNewUserList: any = [];
      const inboxMemberUserIds = getInboxUserIds([]);
      selectedUserIds.forEach((singleUserId) => {
        if (!inboxMemberUserIds.includes(singleUserId)) {
          addNewUserList.push({
            userId: singleUserId,
            inboxId: inboxId,
            isDeleted: false,
          });
        }
      });
      let addedMemberData = {} as any
      if (addNewUserList) {
        if (enableChatHistoryRead) {
          addedMemberData = await createInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
         // const addedMemberData = await addOrUpdateInboxMembers({memberList: addNewUserList, inboxId: inboxId});
       } else {
         addedMemberData = await addOrUpdateInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
         //  addedMemberData = await createInboxMembers({
         //   variables: {
         //     objects: addNewUserList,
         //   }
         // });
       }
      }
      if (addedMemberData) {
        showToast(
          smsInboxToast,
          intl.formatMessage({id: smsCreateOrUpdateMsg}),
          ToastType.success
        );
      }
      setSmsInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }

    onSmsInboxActionPerformed(SMS_INBOX_ACTION_CODES.ON_INBOX_SAVE);
  };
  const createOrUpdateSmsInbox = () => {
    setSmsInboxState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    if (selectedInboxData?.singleInboxData?.id) {
      updateSmsInbox();
    } else {
      createSmsInbox();
    }
  };

  const getAvailableVirtualNumbers = () => {
    const virtualNumbersData = smsInboxState?.allVirtualPhoneNumbers || [];
    return (
      <Select
        showSearch={true}
        className="sms-inbox-drawer"
        placeholder="Select Phone Number"
        onChange={onPhoneNumberSelect}
        disabled={isInboxPresent() || props?.selectedPhoneNumber}
        value={smsInboxState.selectedPhoneNumber}
        filterOption={(input: string, option: any) => {
          return (option?.searchString || '')?.toLowerCase?.()?.includes(input?.toLowerCase?.());
        }}
      >
        {virtualNumbersData.map((singleUserDetails) => {
          return (
            <Option
              key={singleUserDetails?.phoneNumber?.phoneNumber}
              searchString={`${singleUserDetails?.phoneNumber?.phoneNumber} ${singleUserDetails?.phoneNumber?.name}`}
              value={singleUserDetails?.phoneNumber?.phoneNumber}
            >
              <View flexDirection={'row'}>
                <View style={{padding: 5}}>
                  <PhoneRingSvg />
                </View>
                <View style={{marginLeft: 12}}>
                  <Text fontSize={'14px'} color={Colors.Custom.Gray900}>
                    {singleUserDetails?.phoneNumber?.phoneNumber}
                  </Text>
                  <Text
                    style={{marginTop: 0}}
                    fontSize={'12px'}
                    color={Colors.Custom.Gray500}
                  >
                    {singleUserDetails?.phoneNumber?.name || ''}
                  </Text>
                </View>
              </View>
            </Option>
          );
        })}
      </Select>
    );
  };

  const getDefaultSelectedUsers = (): number[] => {
    let userIds: number[] = [];
    if (selectedInboxData?.singleInboxData?.inboxMembers?.length) {
      userIds = selectedInboxData?.singleInboxData?.inboxMembers.map(
        (singleInboxMember: IInboxMember) => {
          return singleInboxMember.userId;
        }
      );
    }
    return userIds;
  };

  const getInboxData=async ()=> {
    const phoneInboxData = await getInboxesDataByPhoneNumber({
      variables: {
        whereCondition: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          channelTwilioSms: {
            phoneNumber: {
              _eq: phoneNumber
            }
          },
        }
      }
    });
    const singleInbox = phoneInboxData?.data?.inboxes[0];
    if (singleInbox?.id) {
      setSmsInboxState((prev) => {
        return {
          ...prev,
          loading: false,
          enableAutoAssignment: singleInbox?.enableAutoAssignment
        };
      });
    }
  }

  useEffect(() => {
    getNumbersList();
    getInboxData();
  }, [
    selectedInboxData?.channel_id,
    allUserVirtualNumbers?.userDetails,
    userDetailsLoading,
    twilioSmsLoading,
    allSmsChannelLoading,
  ]);

  const getSmsInboxBodyElem = () => {
    const virtualNumberList = smsInboxState?.allVirtualPhoneNumbers || [];
    if (
      smsInboxState.isLoading ||
      (userDetailsLoading && twilioSmsLoading && allSmsChannelLoading)
    ) {
      return (
        <View
          mx={0}
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      );
    } else if (virtualNumberList?.length && smsInboxState.isNumberExistInVirtualPhoneAndSmsList) {
      return (
        <VStack mx={0} height={'full'}>
          <HStack>
            <FormControl style={styles.formElement}>
              <FormControl.Label isRequired>
                <DisplayText size={'mdNormal'} textLocalId={'fromNumber'} />
              </FormControl.Label>

              <HStack justifyContent={'space-between'}>
                {!userDetailsLoading &&
                  !twilioSmsLoading &&
                  !allSmsChannelLoading && (
                    <VStack flex={0.65}>{getAvailableVirtualNumbers()}</VStack>
                  )}

                {/* WILL ADD LATERR AUTHOR: ABAHY */}
                {!props?.selectedPhoneNumber && (
                  <HStack flex={0.35} justifyContent={'space-between'}>
                    <Text
                      style={{marginHorizontal: 10}}
                      alignSelf={'center'}
                      justifySelf={'center'}
                    >
                      Or
                    </Text>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          onSmsInboxActionPerformed(
                            SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN
                          );
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({id: 'newNumber'}),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </HStack>
                )}
              </HStack>
            </FormControl>
          </HStack>
          <HStack>
            <FormControl
              style={styles.formElement}
              isInvalid={smsInboxState?.errors?.inboxName}
            >
              <FormControl.Label isRequired>
                <DisplayText size={'mdNormal'} textLocalId={'name'} />
              </FormControl.Label>
              <Input
                _focus={{borderColor: '#D0D5DD'}}
                ref={smsFormRef}
                placeholder={'Enter name'}
                defaultValue={selectedInboxData?.singleInboxData?.name}
                onChangeText={(inboxName: string) => {
                  if (inboxName) {
                    setSmsInboxState((prev) => {
                      return {
                        ...prev,
                        errors: {
                          ...prev.errors,
                          inboxName: '',
                        },
                      };
                    });
                  } else {
                    setSmsInboxState((prev) => {
                      return {
                        ...prev,
                        errors: {
                          ...prev.errors,
                          inboxName: 'Please give inbox name',
                        },
                      };
                    });
                  }
                }}
              />
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                  maxWidth: smallWindow1400
                    ? '65%'
                    : width > SMALL_WINDOW_1700
                    ? '85%'
                    : '80%',
                }}
              >
                {smsInboxState.errors.inboxName}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>

          <HStack>
            <FormControl
              style={styles.formElement}
              isInvalid={smsInboxState?.errors?.users}
            >
              <CustomUserSearch
                label={intl.formatMessage({id: 'collaborators'})}
                userSelectRef={undefined}
                isRequired={true}
                disabledUsers={[]}
                selectedUsersData={smsInboxState.selectedUsersData}
                onChange={(selectedUUIDS: string[]) => {
                  setSmsInboxState((prev) => {
                    return {
                      ...prev,
                      selectedUsersUuids: selectedUUIDS,
                    };
                  });
                }}
                userSearchType={
                  isCommunicationLocationHandlingEnabled
                    ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED
                    : USER_SEARCH_TYPE.DEFAULT
                }
              />
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {smsInboxState.errors.users}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>
          <HStack paddingBottom={3}>
            <Checkbox
              defaultIsChecked={smsInboxState?.enableAutoAssignment}
              isChecked={smsInboxState.enableAutoAssignment}
              onChange={(isSelected) => {
                setSmsInboxState((prev) => {
                  return {
                    ...prev,
                    enableAutoAssignment: isSelected,
                  };
                });
              }}
              value={'true'}
            >
              <Text
                size={'smMedium'}
                color={Colors.Custom.Gray700}
              style={{ marginLeft: 4, marginRight: 6 }}
              >
              {intl.formatMessage({ id: 'enableRotationalAssignment' })}
              </Text>
            </Checkbox>
          </HStack>
        </VStack>
      );
    } else {
      return (
        <VStack height={'full'}>
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <NoDataFound displayString="noVirtualNumberAvailable" />
              {!props?.selectedPhoneNumber && (
                <Button
                  mt={3}
                  onPress={() => {
                    onSmsInboxActionPerformed(
                      SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN
                    );
                  }}
                  minWidth={8}
                  variant="solid"
                >
                  {intl.formatMessage({id: 'buyVirtualNumber'})}
                </Button>
              )}
              {/* <ModalActionBtn
                textColor={Colors.secondary[800]}
                btnText={'buyVirtualNumber' || ''}
                btnStype={BUTTON_TYPE.PRIMARY}
                onClickAction={() => {
                  onSmsInboxActionPerformed(
                    SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN
                  );
                }}
              /> */}
            </Center>
          </View>
        </VStack>
      );
    }
  };

  const [isIPadScreen, isIPadMiniScreen, smallWindow1400] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
    {maxWidth: SMALL_WINDOW_1400}
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const finalWidth = isSmallScreen ? '60%' : '33%';

  const showError = () => {
    if (props?.showError) {
      const message = setSmsInboxErr;
      notification.error({
        message,
        duration: 6,
      });
    }
  };

  useEffect(() => {
    showError();
  }, []);

  return (
    <View>
      <Drawer
        visible={isDrawerVisible}
        width={finalWidth}
        onClose={() => closeModal()}
        title={<ModalActionTitle
          title={'smsInbox'}
          buttonList={
            [
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  closeModal();
                },
              },
              smsInboxState?.allVirtualPhoneNumbers?.length &&
                smsInboxState.isNumberExistInVirtualPhoneAndSmsList ?
                {
                  show: true,
                  id: 2,
                  btnText: 'save',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    onSmsInboxCreateOrUpdate();
                  },
                } : {},
            ]
          }
        />}
      >
        {getSmsInboxBodyElem()}
      </Drawer>
    </View>
  );
};

export default SmsInboxDrawer;
