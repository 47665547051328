import {useLazyQuery, useMutation} from '@apollo/client';
import {Drawer, Table} from 'antd';
import {filter} from 'lodash';
import {View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {GET_MANAGER_ROLES} from '../../../../services/User/UserQueries';
import {
  getAccountId,
  getAccountUUID,
  getDrawerWidthByScreens,
  getFeatureFlag,
} from '../../../../utils/commonUtils';
import {getMlovListByCategory} from '../../../../utils/mlovUtils';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../common/TableTopBar';
import { TableWrapper } from '../../../common/TableWrapper';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import '../../Contacts/Leads/LeadView/LeadTableView/LeadListView/tableViewCustomStyle.css';
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import '../DrawerHeaderBorderRemove.css';
import {EmployerCreate} from '../EmployerCreate';
import {EmployerQuickCreate} from '../EmployerQuickCreate';
import {
  getEmployerColumns,
  getFormattedEmployerData,
  openEmployerScreenDrawers,
} from '../Helper';
import {IEmployerData, IEmployerTableColumnsArgs} from '../interfaces';
import {ManageDeal} from '../ManageDeal';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';

const {height, width} = Dimensions.get('window');
export interface EmployerManagementMainViewProps {
  searchString: any;
  employerTableHeaderTabs: any;
  selectedActionCode: string;
  onActionPerformed: (tabCode: any, rawData?: any, extraData?: any) => void;
  updateEmployersCount?: (count: number)=> void;
}

const EmployerManagementMainView = (props: EmployerManagementMainViewProps) => {

  const navigate = useNavigate();
  const commonData = useContext(CommonDataContext);
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, commonData);
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [employerTableState, setEmployerTableState] = React.useState({
    isLoading: true,
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    searchString: props?.searchString?.searchString,
    EmployerTableData: [] as IEmployerData[],
    EmployerTableDataRaw: [] as IEmployerData[],
    sort: 'desc',
    total: null,
    statusString: '',
    sortedInfo: null as any
  });
  const dealStatusList = getMlovListByCategory('DealStatus');

  const [openModal, setOpenModal] = useState({
    type: '',
    data: null as any,
  });
  const accountId = getAccountId();
  const tenantId = getAccountUUID();

  const getSelectedFilter = (): string => {
    return props.employerTableHeaderTabs.find((data: any) => data?.isActive)
      ?.title;
  };

  const [getEmployers] = useLazyQuery(EmployerQueries.getEmployersByExp, {
    fetchPolicy: 'no-cache',
  });

  const [deleteEmployer] = useMutation(EmployerQueries.deleteEmployer, {
    fetchPolicy: 'no-cache',
  });

  const [getManagers] = useLazyQuery(GET_MANAGER_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      tenantId: accountId,
    },
  });

  useEffect(() => {
    loadEmployers();
  }, [
    employerTableState.statusString,
    employerTableState.pageSize,
    employerTableState.page,
    employerTableState.searchString,
    employerTableState.sortedInfo
  ]);

  useEffect(() => {
    // checking for null as if zero count comes then if condition should work
    if (employerTableState.total !== null) {
      props.updateEmployersCount?.(employerTableState.total ?? 0);
    }
  }, [employerTableState.total]);

  const loadEmployers = (): void => {
    setEmployerTableState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    const statusString = employerTableState.statusString;
    const employersBoolExp: any = {
      accountUuid: {_eq: tenantId},
    };
    if (employerTableState.searchString) {
      employersBoolExp.name = {
        _ilike: `%${employerTableState.searchString}%`,
      };
    }
    let order_by:any = {updatedAt: 'desc'}
    if (employerTableState.sortedInfo) {
      if (employerTableState.sortedInfo?.columnKey == 'members') {
        if (employerTableState?.sortedInfo?.order == 'ascend') {
          order_by = {...order_by, employee_aggregate: {count: 'asc'}}
        } else if (employerTableState?.sortedInfo?.order == 'descend') {
          order_by = {...order_by, employee_aggregate: {count: 'desc'}}
        } else {
          delete order_by.employee_aggregate
        }
      } else {
        delete order_by?.employee_aggregate
      }
    }
    getEmployers({
      variables: {
        limit: employerTableState.pageSize,
        offset: employerTableState.pageSize * (employerTableState.page - 1),
        employersBoolExp: employersBoolExp,
        order_by: order_by,
        currentDate: new Date()
      },
    })
      .then(async (resp) => {
        if (resp?.data?.employers?.length) {
          const total = resp?.data?.employerAggregate?.aggregate?.count;
          const managersResp = await getManagers();
          const formattedEmployerData = getFormattedEmployerData(
            resp?.data?.employers,
            dealStatusList,
            managersResp.data?.users,
            statusString
          );
          setEmployerTableState((prev) => ({
            ...prev,
            EmployerTableData: formattedEmployerData,
            EmployerTableDataRaw: formattedEmployerData,
            isLoading: false,
            total: total,
          }));
        } else {
          setEmployerTableState((prev) => ({
            ...prev,
            EmployerTableData: [],
            EmployerTableDataRaw: [],
            isLoading: false,
            total: null,
          }));
        }
      })
      .finally(() => {
        setEmployerTableState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const onRowClicked = (rowData: IEmployerData) => {
      navigate(`/members/employers/${rowData?.id}/members`);
  };

  const onEmployerTableActionPerformed = (
    actionCode: string,
    actionData: IEmployerData,
    status?: boolean
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT_EMPLOYER:
        setOpenModal({
          type: 'employerCreate',
          data: actionData,
        });
        props.onActionPerformed(COMMON_ACTION_CODES.CREATE_EMPLOYER);
        break;
      case COMMON_ACTION_CODES.ROW_CLICKED:
        onRowClicked(actionData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        deleteEmployer({
          variables: {
            employerId: actionData.id,
          },
        })
          .then((resp: any) => {
            loadEmployers();
          })
          .catch((e) => {

          });
        break;
      case COMMON_ACTION_CODES.MANAGE_DEAL:
        setOpenModal({
          type: 'manageDeal',
          data: actionData,
        });
        props.onActionPerformed(COMMON_ACTION_CODES.MANAGE_DEAL);
        break;
      default:
    }
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.QUICK_CREATE:
        setOpenModal({
          type: 'quickCreate',
          data: null,
        });
        break;
      case COMMON_ACTION_CODES.CREATE_EMPLOYER:
        setOpenModal({
          type: 'employerCreate',
          data: null,
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setEmployerTableState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString || '',
            page: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
          };
        });
        break;
    }
  };

  const EmployerTableColumnArgs: IEmployerTableColumnsArgs = {
    actionFn: onEmployerTableActionPerformed,
    isVbcTenant: isVbcTenant || false
  };

  const finalWidth = getDrawerWidthByScreens({
    screenWidth: width,
    divider: 2.5,
  });

  const onDrawerContent = (type?: string): JSX.Element => {
    let element: JSX.Element = <></>;
    switch (type) {
      case COMMON_ACTION_CODES.CREATE_EMPLOYER:
        element = (
          <>
            <EmployerCreate
              isModalOpen={openModal?.type == 'employerCreate'}
              initData={openModal?.data}
              onModalClose={(shouldRefetch?: boolean) => {
                if (shouldRefetch) {
                  loadEmployers();
                }
                props.onActionPerformed(COMMON_ACTION_CODES.RESET);
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              title="quickCreate"
            />
          </>
        );
        break;
      case COMMON_ACTION_CODES.QUICK_CREATE:
        element = (
          <>
            <EmployerQuickCreate
              isModalOpen={openModal?.type == 'quickCreate'}
              onModalClose={(shouldRefetch?: boolean) => {
                if (shouldRefetch) {
                  loadEmployers();
                }
                props.onActionPerformed(COMMON_ACTION_CODES.RESET);
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              title="quickCreate"
            />
          </>
        );
        break;
      case COMMON_ACTION_CODES.MANAGE_DEAL:
        element = (
          <ManageDeal
            isModalOpen={openModal?.type == 'manageDeal'}
            initData={openModal?.data}
            onModalClose={(shouldRefetch?: boolean) => {
              if (shouldRefetch) {
                loadEmployers();
              }
              props.onActionPerformed(COMMON_ACTION_CODES.RESET);
              setOpenModal({
                type: '',
                data: null,
              });
            }}
            title="manageDeal"
            manageDealText={openModal?.data?.name}
          />
        );
        break;
      default:
        break;
    }
    return element;
  };

  useEffect(() => {
    setEmployerTableState((prev) => {
      return {
        ...prev,
        searchString: props?.searchString?.searchString,
        page: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE,
      };
    });
  }, [props.searchString]);

  return (
    <View>
      <Drawer
        className="headerBorder"
        width={finalWidth}
        open={openEmployerScreenDrawers(props.selectedActionCode)}
        closeIcon={null}
        closable
        //title={onDrawerHeader(openModal?.type)}
        maskClosable={true}
        onClose={() => {
          props.onActionPerformed(COMMON_ACTION_CODES.RESET);
          setOpenModal({
            type: '',
            data: null,
          });
        }}
      >
        {onDrawerContent(props.selectedActionCode)}
      </Drawer>

        <TableWrapper
          pagination={{
            current: employerTableState.page,
            pageSize: employerTableState.pageSize,
            total: employerTableState.total || 0,
            onChange: (currentPage, currentPageSize) => {
              setEmployerTableState((prev) => {
                return {
                  ...prev,
                  page: currentPage,
                  pageSize: currentPageSize,
                };
              });
            },
          }}
        >
          <Table
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            style={{minHeight: height - 200}}
            loading={employerTableState.isLoading}
            dataSource={employerTableState?.EmployerTableData || []}
            columns={getEmployerColumns(EmployerTableColumnArgs)}
            onChange={(pagination, filters, sorters) => {
              const statusString = filters?.status?.[0] || '';
              setEmployerTableState((prev) => {
                return {
                  ...prev,
                  statusString: statusString.toString(),
                  sortedInfo: sorters,
                };
              });
            }}
            onRow={(data) => {
              return {
                onClick: (event: any) => {
                  if (event?.target?.nodeName == 'A') {
                    return;
                  }
                  onEmployerTableActionPerformed(
                    COMMON_ACTION_CODES.ROW_CLICKED,
                    data
                  );
                },
              };
            }}
            scroll={{x: 1500, y: height - 236}}
            className="lead-list-table-view patient-list-view"
            rowKey={(row) => row.id as string}
            pagination={false}
          />
        </TableWrapper>
    </View>
  );
};

export default EmployerManagementMainView;
