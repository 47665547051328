import { useLazyQuery, useMutation } from '@apollo/client';
import { Drawer } from 'antd';
import { Observation } from 'fhir/r4';
import { isArray } from 'lodash';
import { Box, useMediaQuery, useToast, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  EVENT_NAMES,
  HTTP_ERROR_CODE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE
} from '../../../constants';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { FHIR_RESOURCE, RESOURCE_BLOCK_MESSAGE_ID } from '../../../constants/FhirConstant';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { AppointmentQueries, ConvertContacts, LeadQueries, PatientQueries } from '../../../services';
import {
  CreatePatient,
  createPatientUsingIntegration
} from '../../../services/CommonService/AidBoxService';
import BaseService from '../../../services/CommonService/BaseService';
import { Colors } from '../../../styles';
import { commonBodyContentContainer } from '../../../styles/CommonBodyContentStyle';
import {
  getBirthSexLabel,
  getGenderIdentityLabel,
  getResourceAbilities
} from '../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountName,
  getAccountUUID,
  getUserFullName,
  getUserId,
  getUserUUID
} from '../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import { getContactTypeId, getContactTypeIdInDefaultContactTypes, getMlovId } from '../../../utils/mlovUtils';
import { PersonOmniView } from '../../PersonOmniView/PersonOmniView';
import { CONTACT_ACTION_CODES } from '../../RightSideContainer/Contacts/ContactsConst';
import {
  checkMandatoryFields,
  getMissingFields
} from '../../RightSideContainer/Contacts/ContactsUtils';
import { ConvertLeadList } from '../../RightSideContainer/Contacts/Leads/ConvertLeadList';
import { AddOrUpdateLead } from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { getKeysForPersonType } from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {
  getFieldConfig,
  getFormDataFromLeadData,
  sendPatientAppInvite
} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { LeadMainTableView } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadMainTableView';
import { ConfirmationModal } from '../../RightSideContainer/Contacts/Visitor/ConvertContactsList/ConfirmationModal';
import AddOrUpdateCampaign from '../../RightSideContainer/ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import { ContentTypes } from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import { getTemplates } from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getFormattedSingleEmailTemplate } from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import { OverrideModal } from '../../RightSideContainer/ContentManagement/OverrideModal';
import { AutomationWorkflowEmployee } from '../../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import { BulkImportEmployee } from '../../RightSideContainer/MembersManagement/BulkImportEmployee';
import { CreateGroupModal } from '../../RightSideContainer/MembersManagement/CreateGroup';
import MemberListDrawer from '../../RightSideContainer/Sales/MembershipAndSubscriptions/MembershipProducts/MemberListDrawer';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import { getShortLink } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { createCampaignSegment } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { AddContactRelationView } from '../AddContactRelationView';
import { AddNoteView } from '../AddNoteView';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import { AppointmentType } from '../CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import BookingWorkflow from '../CalendarWidget/BookingWorkflows/BookingWorkflow';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import InstantChat from '../ChatDrawer/InstantChat';
import ContactSendForm from '../ContactSendForm/ContactSendForm';
import DeactivateContactView from '../DeactivateContactView/DeactivateContactView';
import DeleteContactView from '../DeleteContactView/DeleteContactView';
import EmailDrawerCommon from '../EmailPopupView/EmailDrawerCommon';
import { PrintContactClinicalProfileSectionSelector } from '../PrintContactClinicalProfileDrawer/PrintContactClinicalProfileSectionSelector';
import { SMSDrawer } from '../SMSDrawer';
import { AddTagView } from '../TagViews';
import { IAllMembersView } from './interface';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import { EmailDrawerCommonV2 } from '../EmailDrawerCommonV2';
import InstantChatView from '../ChatDrawer/InstantChatView';
import { SMS_INBOX_ACTION_CODES } from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import { IUserPracticeLocation, IUserPracticeLocationData } from '../../../services/Location/interfaces';
import { GetUserPracticeLocations } from '../../../services/Location/UserPracticeLocationQueries';
import { SendProviderRequestFormDrawer } from '../../RightSideContainer/Contacts/Tpa/SendProviderRequestFormDrawer';
import CareProgramConsent from '../CareProgramConsent/CareProgramConsent';
import { INewConversationResponse } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import { EcmOutreach } from '../EcmOutreach/EcmOutreach';
import {CareProgramStepOutreach} from '../CareProgramStepOutreach/CareProgramStepOutreach';
import {CARE_PROGRAM_STEP_TYPE, PROGRAM_FILTER_CODE} from './constant';
import {IContactCareProgramResponse, IContactCareProgramSteps} from '../../../services/ContactCareProgram/interface';
import { CareProgramSubmitConsentView } from '../CareProgramConsent/CareProgramSubmitConsentView';
import { CareProgramAssessmentResponseView } from '../CareProgramAssessment/CareProgramAssessmentResponseView';
import InternalTaskDrawer from '../PatientListInternalTask/InternalTaskDrawer';
import AppointmentDetail from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import { IAppointmentDetail } from '../CalendarWidget/CalendarWidgetInterfaces';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { FORM_CATEGORY_TYPES } from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { EventBus } from '../../../utils/EventBus';

const {width} = Dimensions.get('window');

const AllMembersView = (props: IAllMembersView) => {
  // const {contactType} = useParams();
  const {selectedActionCode, selectedExtraData, selectedRawData, updateContactCount, tab} = props;
  const {contactType} = props;
  const accountId = getAccountId();
  const tenantId = getAccountUUID();
  const userId = getUserId();
  const mlovData = useContext(CommonDataContext);
  const [content, setContent] = useState<any>();
  const [template, setTemplate] = useState<any>();
  const [membershipData, setMembershipData] = useState({
    plan: '',
    productId: '',
  });
  const [observations, setObservations] = useState<Observation[]>([]);
  const accountName = getAccountName();

  const [selectLeadViewCode, setSelectLeadViewCode] = useState({
    code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW,
    invocationCode: '',
  });
  const [campaignDrawerState, setCampaignDrawerState] = useState({
    isVisible: false,
    segmentId: null,
  });
  const currentContactType = contactType
    ? contactType == 'patient'
      ? 'CUSTOMER'
      : ''
    : 'CUSTOMER';
  const intl = useIntl();
  let contactTypeUuid = ''
  if (props?.contactTypeId) {
    contactTypeUuid = props?.contactTypeId;
  } else if (currentContactType.length > 0) {
    contactTypeUuid = getContactTypeId(
      currentContactType
    );
  }
  const [allMemberState, setAllMemberState] = useState({
    contactTypeUuid: contactTypeUuid,
    contactType: currentContactType,
    addContactType: currentContactType,
    addContactTypeUuid: contactTypeUuid,
    practiceLocations: {},
    customHtml: '',
    contactUpdated: false,
    isLoading: true,
    userPracticeLocation: [] as IUserPracticeLocation[],
  });
  const userUUID = getUserUUID();
  const userFullName = getUserFullName();

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(true);

  const [componentState, setComponentState] = useState({
    refreshData: false,
    appointment: {} as IAppointmentDetail,
    isVisible: false,
  })

  const [GetConversationByConversationId] =
  useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByConversationId,
    {
      fetchPolicy: 'no-cache',
    }
  );

const handleOnCreateNewEmailConversation = async (conversationId: string) => {
  if (!conversationId) {
    return;
  }
  try {
    const response = await GetConversationByConversationId({
      variables: {
        conversationId: Number(conversationId),
        loginUserIntId: userId,
        loginUserUuid: userUUID,
      },
    });
    const conversations = response.data?.conversations || [];
    if (conversations.length > 0) {
      const selectedConversationData = conversations[0];
      setSelectedGroupRowContactData([])
      setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
      setSelectedRowData({...selectedRowData, selectedConversation: selectedConversationData})
      setSelectLeadViewCode((prev)=>{
        return {
          ...prev,
          code: RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
        }
      })
    }
  } catch (error) {
  }
};

  useEffect(() => {
    getInitialData();
  }, [currentContactType, tab, contactTypeUuid]);
  const accountUuid = getAccountUUID();
  const navigate = useNavigate();

  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [selectedCareProgramAndSteps, setSelectedCareProgramAndSteps] = useState<{
    stepId: string;
    careProgramId: string;
    careProgram?: IContactCareProgramResponse;
  }>({
    stepId: '',
    careProgramId: '',
  });
  const [selectedGroupRowContactData, setSelectedGroupRowContactData] =
  useState<any[]>();
  const [getContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [alert, setAlert] = useState('');
  const [convertLeadModal, setConvertLeadModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [sendInvite, setSendInvite] = useState(true);

  const [patientUuid, setPatientUuid] = useState<string | undefined>('');
  const [actionType, setActionType] = useState('');
  const [UpdateContactTypeByPatientUuid] = useMutation(
    ConvertContacts.UpdateContactTypeByPatientUuid
  );
  const [CreatePatientWithContactId] = useMutation(
    PatientQueries.CreatePatientWithContactId
  );
  const baseService = BaseService.getSharedInstance().axios;

  const [openGroupModal, setOpenGroupModal] = useState(false);

  const showAppointmentBookingToast = (message: string) => {
    showToast(
      toast,
      message,
      ToastType.success
    );
  };

  const sendResetPasswordInstructions = async (email: string) => {
    const url = `/crm-nest/auth/resetContactPassword`;
    const data = {
      accountUuid: accountUuid,
      email: email,
    };
    const headers = {
      accountId: accountId,
    };
    await baseService
      .post(url, data, {headers: headers})
      .then((response) => {
        showToast(
          toast,
          intl.formatMessage({id: 'appointmentBookingSuccess'}),
          ToastType.success,
        );
        showToast(
          toast,
          intl.formatMessage({id: 'emailSentSuccess'}),
          ToastType.success,
        )
      })
      .catch((error) => {
        showToast(
          toast,
          intl.formatMessage({id: 'errorMsg'}),
          ToastType.error,
        );

      });
  };

  const shouldCreateInCanvas =
    getResourceAbilities(FHIR_RESOURCE.PATIENT, '', '')
      ?.createPatientUsingIntegration || false;

  const fieldKeys = getKeysForPersonType(currentContactType)
  const isMobileNumberRequired = false;
  //!!getFieldConfig(fieldKeys?.phoneNumber,patientResource)?.isRequired;
  const isPhoneOrEmailRequired = isAccountConfigEnabled(CONFIG_CODES.IS_PHONE_OR_EMAIL_REQUIRED);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const finalWidth = isIPadScreen || isIPadMiniScreen ? width / 1.5 : width / 2.5;

  const toast = useToast();
  const handleConvertToCustomer = (id: any, convertToType: string) => {
    const typeId = getContactTypeIdInDefaultContactTypes(mlovData.defaultContactTypes?.customContactTypes, convertToType);
    UpdateContactTypeByPatientUuid({
      variables: {
        id: id,
        typeId: typeId,
      },
    }).then((response) => {
      onViewChangeActionPerformed(COMMON_ACTION_CODES.ADDED_OR_UPDATED);
    }).catch((error) => {
      const message = error?.response?.data?.message || error?.response?.message;
      showToast(
        toast,
        message || intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    })
  };

  const handlePatientContactMap = (contactData: any, patientUuid: any) => {
    CreatePatientWithContactId({
      variables: {
        accountId: accountId,
        patientUuid: patientUuid,
        contactId: contactData.leadId,
      },
    })
      .then(() => {
        onViewChangeActionPerformed(
          RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT
        );
      })
      .catch((err) => {
        // catch
      });
  };
  const handleOnContactTypeSelctected = (contactTypeData: any) => {
    setAllMemberState((prev) => {
      return {
        ...prev,
        contactType: contactTypeData.contactType,
        contactTypeUuid: contactTypeData.contactTypeUuid,
      };
    });
  };
  const handleAddNewContactTypeSelected = (contactTypeData: any) => {
    setAllMemberState((prev) => {
      return {
        ...prev,
        addContactType: contactTypeData?.contactType,
        addContactTypeUuid: contactTypeData?.contactTypeUuid,
      };
    });
  };

  const setViewCode = (code: string) => {
    setSelectLeadViewCode((prev) => {
      return {
        ...prev,
        code: code,
      };
    });
  };

  const setViewInvocationCode = (type: string) => {
    setSelectLeadViewCode((prev) => {
      return {
        ...prev,
        invocationCode: type,
      };
    });
  };

  const sendPatientAppInvitation = (formattedData: any) => {
    if (formattedData.contactUuid || formattedData.contactUUID) {
      if (sendInvite) {
        sendPatientAppInvite({
          email: formattedData.email,
          accountId,
          accountUuid,
          contactUuid: formattedData.contactUuid || formattedData.contactUUID,
        })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            showToast(
              toast,
              intl.formatMessage({id: 'patientAppInvitationError'}),
              ToastType.error,
            );
            return err;
          });
      }
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'patientAppInvitationError'}),
        ToastType.error,
      );
    }
  };

  const handleCreatePatientInEHR = (formattedData: any) => {
    const typeId = getContactTypeIdInDefaultContactTypes(mlovData.defaultContactTypes?.customContactTypes, PERSON_TYPES.CUSTOMER);
    if (shouldCreateInCanvas) {
      createPatientUsingIntegration({
        patientData: {...formattedData, typeId: typeId},
        sendInvite: sendInvite,
        addTagObjectList: [],
        userId: userId,
        userUuid: userUUID,
        successHandler(response) {
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
        },
        errorHandler(error) {
          if (error.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED){
            showToast(
              toast,
              intl.formatMessage({id: RESOURCE_BLOCK_MESSAGE_ID}),
              ToastType.error,
            );
          } else {
            showToast(
              toast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          }
        },
      });
    } else {
      CreatePatient(
        formattedData,
        (res: any) => {
          const resId = res?.data?.id;
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
          sendPatientAppInvitation(formattedData);
          handlePatientContactMap(formattedData, resId);
        },
        (error: any) => {
        }
      );
    }
  };

  const formattedContactData = getFormDataFromLeadData(
    selectedRowData?.contactData,
    mlovData
  );

  const getContactDataWithConsent = (extraData: any) => {
    const contactRes = extraData?.map((item: any) => {
      return {
          name: item?.contactData?.name,
          email: item?.contactData?.email,
          id: item?.contactData?.id,
          uuid: item?.contactData?.uuid,
          contactConsents: item?.contactData?.contactConsents,
      };
    });
    setSelectedGroupRowContactData(contactRes);
  };

  const [ getAppointmentApi, { loading: isGetAppointmentApiLoading } ] = useLazyQuery<{ appointments?: IAppointmentDetail[] }>(AppointmentQueries.GET_APPOINTMENT_BY_ID_WITH_GROUP, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (response) => {
      if (response?.appointments?.length && response.appointments[0]) {
        setComponentState(prev => ({ ...prev, appointment: (response.appointments as any)[0], isVisible: true }));
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
        );
      }
    },
    onError: (error) => {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    }
  });

  const getAppointmentUserById = (appointmentId: string) => {
    getAppointmentApi({
      variables: {
        appointmentId: appointmentId,
      },
    });
  };

  const onViewChangeActionPerformed = async (
    actionCode: string,
    rowData?: any,
    extraData?: any,
  ) => {
    setViewInvocationCode('');
    switch (actionCode) {
      case COMMON_ACTION_CODES.DEACTIVATE:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_DEACTIVATE_VIEW);
        break;
      case COMMON_ACTION_CODES.DELETE:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_DELETE_VIEW);
        break;
      case COMMON_ACTION_CODES.EDIT:
        setAlert('');
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        break;
      case COMMON_ACTION_CODES.ADD:
        setAlert('');
        setSelectedRowData('');
        handleAddNewContactTypeSelected(rowData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        break;
      case COMMON_ACTION_CODES.SUCCESS:
      setAlert('');
      setSelectedRowData('');
      handleAddNewContactTypeSelected(rowData);
      break;
      case COMMON_ACTION_CODES.ROW_CLICKED:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_OMNI_VIEW);
        break;
      case COMMON_ACTION_CODES.IMPORT:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_IMPORT_VIEW);
        break;
      case COMMON_ACTION_CODES.MAIL:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        if (extraData?.length) {
          getContactDataWithConsent(extraData);
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP);
        break;
      case COMMON_ACTION_CODES.CALL:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        break;
      case COMMON_ACTION_CODES.SMS:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER);
        break;
      case COMMON_ACTION_CODES.CHAT:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CHAT_WITH_PATIENT);
        break;
      case COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.MEMBER_LIST_VIEW);
        break;
      case COMMON_ACTION_CODES.EMAIL_SENT:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.EMAIL_SENT_EVENT);
        break;
      case COMMON_ACTION_CODES.MERGE:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        const patientListRespData = await getContact({
          variables: {
            id: rowData?.contactData?.id,
          },
        });
        let singleLeadData = rowData.singleLeadData;
        const patientLocationUuid =
          patientListRespData?.data?.contact?.contactPracticeLocations?.[0]
            ?.accountLocation?.uuid;
        if (patientListRespData.data?.contact?.id) {
          const contactObj = patientListRespData.data?.contact
          singleLeadData = {...singleLeadData,  contactObj };
          setSelectedRowData({...contactObj, contactData: singleLeadData, singleLeadData: singleLeadData});
        } else if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        const isGenderAtBirthRequired = getResourceAbilities(
          FHIR_RESOURCE.PATIENT,
          '',
          patientLocationUuid
        )?.keyAllowedOperations?.birthSex?.isRequired;
        const genderIdentityLabel = getGenderIdentityLabel(
          '',
          patientLocationUuid
        );

        const isPractitionerRequired =
          getResourceAbilities(FHIR_RESOURCE.PATIENT, '', patientLocationUuid)
            ?.keyAllowedOperations?.generalPractitioner?.isRequired || false;
        const isLocationRequired =
          getResourceAbilities(FHIR_RESOURCE.PATIENT, '', patientLocationUuid)
            ?.keyAllowedOperations?.location?.isRequired || false;
        const patientResource = FHIR_RESOURCE.PATIENT;
        const isAddressLine1Required = !!getFieldConfig(
          fieldKeys?.addressLine1,
          patientLocationUuid,
          patientResource
        )?.isRequired;

        const formattedData = getFormDataFromLeadData(
          singleLeadData,
          mlovData
        );
        if (
          checkMandatoryFields(formattedData, PERSON_TYPES.CUSTOMER, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired: isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired: isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          })
        ) {
          setConvertLeadModal(true);
          setActionType(CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER);
        } else {
          const genderAtBirthLabel = getBirthSexLabel('', patientLocationUuid);
          const alert = getMissingFields(formattedData, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            genderAtBirthLabel,
            genderIdentityLabel,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired:isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired: isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          }).message;
          setAlert(alert);
          setViewInvocationCode(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        }
        break;
      case COMMON_ACTION_CODES.SET_TAG:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_TAG_VIEW);
        break;
      case COMMON_ACTION_CODES.CONVERT:
      case COMMON_ACTION_CODES.ADD_RELATION:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_RELATION_VIEW);
        break;
      case COMMON_ACTION_CODES.CONTACT_TYPE_SELECTED:
        handleOnContactTypeSelctected(rowData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        break;
      case COMMON_ACTION_CODES.ON_USER_PRACTICE_LOCATION_SELECT:
        setAllMemberState((prev) => {
          return {
            ...prev,
            practiceLocations: rowData,
          };
        });
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else if(isArray(rowData)) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW);
        break;
      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        const contactIds = (rowData || []).map((contact: any) => {
          return contact?.id;
        });
        createCampaignSegment({
          name: '',
          tenantId: tenantId,
          metadata: {
            patientFilter: {
              contactIds: contactIds,
            },
          },
        }).then((resp) => {
          if (resp?.id) {
            setCampaignDrawerState({
              isVisible: true,
              segmentId: resp?.id,
            });
          }
        });
        break;
      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
        // setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT);
        if (
          rowData?.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER &&
          rowData?.leadFormData
        ) {
          onViewChangeActionPerformed(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER, {
            singleLeadData: rowData.leadFormData.singleLeadData,
            contactData: rowData.leadFormData.singleLeadData
          });
        } else {
          setSelectedRowData({});
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT);
          setComponentState((prev) => ({
            ...prev,
            refreshData: !componentState.refreshData,
          }))
          setAllMemberState((prev)=>{
            return {
              ...prev,
              contactUpdated: !allMemberState?.contactUpdated
            }
          })
        }
        break;
      case COMMON_ACTION_CODES.CREATE_GROUP:
        setSelectedRowData(rowData);
        setOpenGroupModal(true);
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE);
        break;
      case COMMON_ACTION_CODES.RESET_PASSWORD:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD);
        break;
      case COMMON_ACTION_CODES.SEND_FORMS:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM);
        break;
      case COMMON_ACTION_CODES.CREATE_TASK:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK);
        break;
      case COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP);
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        getAppointmentUserById(rowData?.appointmentData?.id);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_DETAIL_POPUP);
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        props.onActionPerformed?.(COMMON_ACTION_CODES.ON_BULK_ACTION, rowData);
        break;
      case COMMON_ACTION_CODES.SAVE:
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        props.onActionPerformed?.(COMMON_ACTION_CODES.SAVE, rowData);
        break;
      case COMMON_ACTION_CODES.TEXT_MESSAGE:
        setSelectedRowData(rowData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER);
        break;
      case COMMON_ACTION_CODES.RESET:
      case COMMON_ACTION_CODES.CANCEL:
      case SMS_INBOX_ACTION_CODES.DRAWER_CLOSE:
        setTemplate(undefined);
        setSelectedGroupRowContactData([])
        props.onActionPerformed?.(actionCode, rowData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        const data = rowData?.conversation;
        setSelectedRowData({...selectedRowData, selectedConversation: data})
        setSelectLeadViewCode((prev)=>{
          return {
            ...prev,
            code: RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
          }
        })
        break;
        case RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT:
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT)
        break
      case COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE);
        break;
      case COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM:
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER);
        break;
      case COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL:
        setSelectedCareProgramAndSteps(prev => ({
          ...prev,
          stepId: rowData?.extraData?.stepId,
          careProgramId: rowData?.extraData?.careProgramId,
          careProgram: rowData?.extraData?.careProgram,
        }))
        if (rowData?.contactData?.id) {
          setSelectedRowData(rowData);
        } else {
          setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        }
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER);
        break;
      case COMMON_ACTION_CODES.ECM_OUTREACH_VIEW:
        if (rowData?.contactData?.id) {

            setSelectedRowData(rowData);
          } else {
            setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
          }
          setSelectedCareProgramAndSteps(prev => ({
            ...prev,
            stepId: rowData?.extraData?.stepId,
            careProgramId: rowData?.extraData?.careProgramId
          }))
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.ECM_OUTREACH_VIEW);
          break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT:
        if (rowData?.contactData?.id) {

            setSelectedRowData(rowData);
          } else {
            setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
          }
          setSelectedCareProgramAndSteps(prev => ({
            ...prev,
            stepId: rowData?.extraData?.stepId,
            careProgramId: rowData?.extraData?.careProgramId
          }))
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT);
          break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT:
        if (rowData?.contactData?.id) {

            setSelectedRowData(rowData);
          } else {
            setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
          }
          setSelectedCareProgramAndSteps(prev => ({
            ...prev,
            stepId: rowData?.extraData?.stepId,
            careProgramId: rowData?.extraData?.careProgramId
          }))
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_ASSESSMENT);
          break;
      case COMMON_ACTION_CODES.ACTIONABLE_TASK_VIEW:
        setSelectedRowData(rowData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_TASK_VIEW);
        break;
      case COMMON_ACTION_CODES.ACTIONABLE_ALERT_VIEW:
        setSelectedRowData(rowData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_ALERT_VIEW);
        break;
      default:
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        setViewInvocationCode('');
        props.onActionPerformed?.(actionCode, rowData);
        break;
    }
  };

  const getLabelIdsFromTags = (tags: any[]) => {
    return tags?.map((tag) => {
      return tag?.labels?.id;
    });
  };

  const getGroupFilter = () => {
    const metadata = {
      patientFilter: {
        contactIds: (selectedRowData && selectedRowData?.map
          ? selectedRowData
          : []
        ).map((contact: any) => {
          return contact?.id;
        }),
      },
    };
    return metadata;
  };

  // (
  //   selectedRowData?.contactCarePrograms?.[0]
  //     ?.contactCareProgramSteps as IContactCareProgramSteps[]
  // )?.find(
  //   (step: IContactCareProgramSteps) =>
  //     step.careProgramStepType?.code ===
  //     CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH
  // )?.id



  const getLeadViewFromSelectedCode = () => {
    switch (selectLeadViewCode.code) {
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_IMPORT_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_DELETE_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_TAG_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_RELATION_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP:
      case RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT:
      case RIGHT_SIDE_CONTAINER_CODE.EMAIL_SENT_EVENT:
      case RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_DEACTIVATE_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM:
      case RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE:
      case RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK:
      case RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP:
      case RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_DETAIL_POPUP:
      case RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS:
      case RIGHT_SIDE_CONTAINER_CODE.MEMBER_LIST_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.CHAT_WITH_PATIENT:
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.ECM_OUTREACH_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT:
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_ASSESSMENT:
      case RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_TASK_VIEW:
      case RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_ALERT_VIEW:
        let automationInput: any;
        try {
          automationInput = selectedRowData?.id
            ? [selectedRowData]
            : selectedRowData && selectedRowData.length
            ? [...selectedRowData]
            : [];

        } catch (exception) {
          automationInput = [];
        }
        return (
          <View flex={1} key={allMemberState.contactType}>
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.MEMBER_LIST_VIEW && (
              <MemberListDrawer
                rowData={selectedRowData}
                onFormCompleteAction={(
                  actionCode: string,
                  selectedRowData: any,
                  product: any,
                  plan: any
                ) => {
                  if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                    setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                    props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                    setContent(undefined);
                    return;
                  }
                  if (product && product.id) {
                    setMembershipData((prev) => {
                      return {
                        ...prev,
                        plan: plan?.price,
                        productId: product.id,
                      };
                    });
                    onViewChangeActionPerformed(actionCode, selectedRowData);
                    return;
                  }
                  const baseService = BaseService.getSharedInstance().axios;
                  const url = `/crm-nest/membership/api/token`;
                  baseService
                    .post(url, {
                      accountId,
                      accountUuid,
                      contactUuid: selectedRowData.contactData?.uuid,
                    })
                    .then(async (resp) => {
                      const origin = window.location.origin;
                      const dotIndex = origin.indexOf('.');
                      const subString = 'membership';
                      const finalString =
                        origin.slice(0, dotIndex) +
                        '.' +
                        subString +
                        origin.slice(dotIndex) +
                        `/#/?token=${resp.data}&productId=${product.id}&plan=${plan.price}`;
                      const shortLink = await getShortLink(finalString);
                      setContent(shortLink);
                      const customHtml = `<!doctype html>
                     <p>
                       <span style="float: left;display: block;">Hi ${
                         selectedRowData?.name
                       }</span>
                       <br/>
                         <span style="float: left;display: block;">
                         Thank you for your interest in ${accountName}, We are excited to offer you the opportunity to become a member of ${accountName}. As a member, you will have access to our comprehensive primary care services, as mentioned below.
                         </span>
                        <div style="textAlign:left">Plan name: ${
                          product?.name
                        }</div>
                        <div style="textAlign:left">Price: $${
                          product?.prices[plan?.plan].price / 100
                        } ${plan?.plan}</div>
                        <br/>
                        <span >Become a member <a href="${shortLink}}">click here</a></span>
                     </p>
                     </html>`;
                      setAllMemberState((prev) => {
                        return {
                          ...prev,
                          customHtml: customHtml,
                        };
                      });
                      if (actionCode == COMMON_ACTION_CODES.MAIL) {
                        let isDefaultEmailForGeneralCategory = false
                        getTemplates(`${ContentTypes.emails.path}?isDefault=true&category=GENERAL`)
                          .then((data) => {
                            if (!data?.data.length) {
                              showToast(toast, intl.formatMessage({id: 'defaultEmailError'}), ToastType.error, 5000);
                              return;
                            }
                            isDefaultEmailForGeneralCategory = true;
                            const dataObj = data?.data[0]?.id
                              ? {
                                  data: data?.data[0],
                                }
                              : data[0];
                            return getFormattedSingleEmailTemplate(dataObj)
                          })
                          .then((singleTemplate) => {
                            if (isDefaultEmailForGeneralCategory) {
                              setTemplate(singleTemplate);
                              onViewChangeActionPerformed(
                                actionCode,
                                selectedRowData
                              );
                            }
                          })
                          .catch((error) => error);
                        }
                    });
                }}
              />
            )}
            <LeadMainTableView
              selectedActionCode={selectedActionCode}
              selectedRawData={selectedRawData}
              selectedExtraData={selectedExtraData}
              key={allMemberState.contactType}
              personType={allMemberState.contactType}
              personTypeUuid={allMemberState.contactTypeUuid}
              onActionPerformed={onViewChangeActionPerformed}
              addOrUpdateEvent={selectLeadViewCode.code}
              updateContactCount={updateContactCount}
              contactUpdated={allMemberState?.contactUpdated}
              tab={props?.tab}
              userPracticeLocation={allMemberState?.userPracticeLocation}
              hideConvertToPatient={props?.hideConvertToPatient}
              refreshData={componentState.refreshData}
            />
            {campaignDrawerState?.isVisible ? (
              <Drawer
                visible={campaignDrawerState?.isVisible}
                width={Dimensions.get('window').width * 0.7}
                onClose={() => {
                  setCampaignDrawerState({
                    isVisible: false,
                    segmentId: null,
                  });
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                  props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                }}
                headerStyle={{
                  display: 'none',
                }}
              >
                <AddOrUpdateCampaign
                  shouldShowEdit={true}
                  initData={undefined}
                  segmentId={campaignDrawerState?.segmentId}
                  close={(code) => {
                    setSelectedRowData({});
                    setCampaignDrawerState({
                      isVisible: false,
                      segmentId: null,
                    });
                    setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                    props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                    if(code ==='created'){
                      showToast(
                        toast,
                        intl.formatMessage({id: 'campaignCreateSuccess'}),
                        ToastType.success,
                      );
                    }
                  }}
                />
              </Drawer>
            ) : null}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW && (
              <Drawer
                width={finalWidth}
                visible={true}
                closeIcon={null}
                closable
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                  props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                }}
                maskClosable={true}
              >
                <AutomationWorkflowEmployee
                  type="patient"
                  initData={{selectedItems: automationInput}}
                  onModalClose={() => {
                    setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                    props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                  }}
                />
              </Drawer>
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
              <AddOrUpdateLead
                practiceLocationObj={allMemberState.practiceLocations}
                {...selectedRowData}
                invocationCode={selectLeadViewCode.invocationCode}
                onFormCompleteAction={(actionCode: string, actionData: any) => {
                  onViewChangeActionPerformed(actionCode, actionData);
                }}
                personType={
                  selectedRowData.id
                    ? allMemberState.contactType
                    : allMemberState.addContactType
                }
                personTypeUuid={
                  selectedRowData.id
                    ? allMemberState.contactTypeUuid
                    : allMemberState.addContactTypeUuid
                }
                alert={alert}
                isEmployeeView={true}
                showConsentError={selectedRowData?.showConsentError}
                observationsUpdated={(observations: Observation[]) => {
                  setObservations([...observations]);
                }}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_IMPORT_VIEW && (
              <Drawer
                width={finalWidth}
                visible={
                  selectLeadViewCode.code ===
                  RIGHT_SIDE_CONTAINER_CODE.CONTACT_IMPORT_VIEW
                }
                closeIcon={null}
                maskClosable={true}
                closable
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              >
                <>
                  <BulkImportEmployee
                    type={'lead'}
                    onModalClose={() => {
                      setTimeout(() => {
                        setViewCode(
                          RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW
                        );
                      }, 500);
                    }}
                  />
                </>
              </Drawer>
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_TAG_VIEW && (
              <AddTagView
                contactId={selectedRowData.id}
                contactData={selectedRowData.singleLeadData}
                selectedLabelIds={getLabelIdsFromTags(selectedRowData.tags)}
                tagType="Contact"
                onFormCompleteAction={(actionCode: string) => {
                  onViewChangeActionPerformed(actionCode);
                }}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_RELATION_VIEW && (
              <AddContactRelationView
                contactId={selectedRowData.id}
                onFormCompleteAction={(code: string) => {
                  if (code == 'EDIT') {
                    setSelectedRowData({});
                    setSelectLeadViewCode((prev) => {
                      return {
                        ...prev,
                        code: RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS,
                      };
                    });
                    return;
                  }
                  onViewChangeActionPerformed(code);
                }}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS && (
              <AddOrUpdateLead
                isShowAddContactTypeOption={true}
                personTypeUuid={getContactTypeId(
                  'CUSTOMER'
                )}
                personType={'CUSTOMER'}
                singleLeadData={undefined}
                onFormCompleteAction={() => {
                  onViewChangeActionPerformed(COMMON_ACTION_CODES.SUCCESS);
                }}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER && (
              <>
                <ConvertLeadList
                  isOpen={convertLeadModal}
                  onClose={() => {
                    setSelectLeadViewCode((prev) => {
                      return {
                        ...prev,
                        code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW,
                        invocationCode: '',
                      }
                    })
                    setConvertLeadModal(false);
                  }}
                  onSave={(patientUuid) => {
                    setConfirmationModalOpen(true);
                    setPatientUuid(patientUuid);
                    setActionType(CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER);
                  }}
                  personType={PERSON_TYPES.CUSTOMER}
                  contactData={selectedRowData}
                  onCreatePatient={() => {
                    setConfirmationModalOpen(true);
                    setActionType(CONTACT_ACTION_CODES.CREATE_PATIENT);
                  }}
                  isChecked={(checked) => {
                    setSendInvite(checked);
                  }}
                />
                <ConfirmationModal
                  isModalOpen={confirmationModalOpen}
                  onModalClose={() => setConfirmationModalOpen(false)}
                  btnList={[
                    {
                      btnText: 'Confirm',
                      onBtnPress: () => {
                        const singleLeadDataObj =
                        selectedRowData?.contactData?.singleLeadData ||
                        selectedRowData?.contactData ||
                        selectedRowData;
                      let formattedData = getFormDataFromLeadData(
                        singleLeadDataObj,
                        mlovData
                      );
                        formattedData = {...formattedData, vitalObservations: observations || []};
                        if (
                          actionType ===
                          CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER
                        ) {
                          handlePatientContactMap(formattedData, patientUuid);
                          handleConvertToCustomer(
                            formattedData.personTypeId,
                            PERSON_TYPES.CUSTOMER
                          );
                          sendPatientAppInvitation(formattedData);
                        } else if (
                          actionType === CONTACT_ACTION_CODES.CREATE_PATIENT
                        ) {
                          handleCreatePatientInEHR(formattedData);
                        }
                        setConfirmationModalOpen(false);
                        setSelectLeadViewCode((prev) => {
                          return {
                            ...prev,
                            code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW,
                            invocationCode: '',
                          }
                        })
                        setConvertLeadModal(false);
                      },
                    },
                    {
                      btnText: 'Cancel',
                      onBtnPress: () => {
                        setConfirmationModalOpen(false);
                      },
                      btnColorScheme: 'dark',
                      btnTextStyle: {color: 'white'},
                    },
                  ]}
                  actionType={actionType}
                />
              </>
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_DEACTIVATE_VIEW && (
              <DeactivateContactView
                contactId={selectedRowData.contactData?.uuid}
                onDeleteCompleteAction={onViewChangeActionPerformed}
                record={selectedRowData}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_DELETE_VIEW && (
              <DeleteContactView
                contactId={selectedRowData.id}
                onDeleteCompleteAction={onViewChangeActionPerformed}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
              <EmailDrawerCommonV2
                contactIds={
                  selectedGroupRowContactData &&
                  selectedGroupRowContactData?.length > 0
                    ? selectedGroupRowContactData.map((item) => item.id)
                    : selectedRowData?.id
                    ? [selectedRowData.id]
                    : []
                }
                isOpen={
                  selectLeadViewCode.code ===
                  RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
                }
                onClose={() => {
                  onViewChangeActionPerformed(COMMON_ACTION_CODES.RESET);
                }}
                onEmailSent={({msgData}) => {
                  onViewChangeActionPerformed(COMMON_ACTION_CODES.RESET);
                  handleOnCreateNewEmailConversation(msgData?.conversationId);
                }}
                membershipData={membershipData}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
              <CreateSmsConversationDrawer
                isDrawerVisible={true}
                selectedInbox={{} as any}
                onCreateSmsConversationActionPerformed={(
                  actionCode: string,
                  actionData: any
                ) => {
                  const data = actionData?.selectedInboxConversationData?.conversations?.[0]
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                  props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                  setSelectedRowData({...selectedRowData, selectedConversation: data})
                  setSelectLeadViewCode((prev)=>{
                    return {
                      ...prev,
                      code: RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
                    }
                  })
                }}
                selectedPatient={selectedRowData?.contactData}
                content={content}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE && (
              <AddNoteView
                contactId={selectedRowData?.contactData?.id}
                onFormActionPerformed={(actionCode: any) => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD && (
              <OverrideModal
                isOpen={showResetPasswordModal}
                onClose={() => {
                  // setShowResetPasswordModal(false);
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
                onConfirm={() => {
                  const email = selectedRowData?.contactData?.email;
                  sendResetPasswordInstructions(email);
                  setShowResetPasswordModal(false);
                }}
                textContent={{
                  headerText: 'Confirmation',
                  message: 'Do you want to send reset password instructions?',
                }}
              />
            )}
            {selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM && (
              <ContactSendForm
                isVisible={
                  selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM
                }
                assignmentData={{
                  patientId: formattedContactData.patientUuid || '',
                  contactId: formattedContactData.contactUUID || '',
                  assignedById: userUUID,
                  patientEmail: formattedContactData.email || '',
                  patientFirstName: formattedContactData.firstName || '',
                  patientContactUUID: formattedContactData.contactUUID || '',
                  contactTypeCode: formattedContactData?.contactType?.code || '',
                }}
                onActionComplete={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                  setComponentState((prev) => ({
                    ...prev,
                    refreshData: !componentState.refreshData,
                  }))
                }}
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
                categoryCodesToSearchForms={[FORM_CATEGORY_TYPES.CARE_PLAN_ASSESSMENT]}
              />
            )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER && (
              <SendProviderRequestFormDrawer
                isVisible={
                  selectLeadViewCode.code ===
                  RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER
                }
                assignmentData={{
                  contactId: formattedContactData.contactUUID || '',
                  contactEmail: formattedContactData.email
                }}
                onActionComplete={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              />
            )}
            {
              selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE &&
              <PrintContactClinicalProfileSectionSelector
                formattedContactData={formattedContactData}
                isOpen={selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE}
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              />
            }
          { selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK && (
            <AddOrUpdateTask
              // saveButtonText={'Send'}
              // successToastMessage={'taskSendMsg'}
              // showTaskPriority={true}
              // showAssignee={false}
              // showAssignedBy={false}
              saveBtnText={'Save'}
              successMessage={'taskAddedMsg'}
              assignee={{
                value: formattedContactData?.contactUUID,
                label: formattedContactData?.name,
                key: formattedContactData?.contactUUID,
                type: ParticipantType.patient,
                details:formattedContactData
              }}
              isVisible={
                selectLeadViewCode.code ===
                RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK
              }
              onComplete={(data: any) => {
                setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                const eventBus = EventBus.getEventBusInstance();
                const code = `${EVENT_NAMES.REFRESH_TASK}_${data.addOrUpdateTask.contactId}`;
                eventBus.broadcastEvent(code, {});
              }}
              onCancel={() => {
                setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
              }}
            />
          )}
            {selectLeadViewCode.code ===
              RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP && (
              <BookingWorkflow
                user={{
                  uuid: userUUID,
                  name: userFullName,
                }}
                isOpen={true}
                appointmentType={AppointmentType.bookAppointment}
                defaultParticipants={[
                  {
                    label: selectedRowData?.contactData.name,
                    key: selectedRowData?.contactData.uuid,
                    value: selectedRowData?.contactData.uuid,
                    type: ParticipantType.patient,
                  },
                ]}
                onClose={() => {
                  showAppointmentBookingToast(
                    'Appointment booked successfully!'
                  );
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
                onCancel={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              />
            )}

            {selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_DETAIL_POPUP && componentState.isVisible && (
              <AppointmentDetail
                event={{ detail: componentState?.appointment }}
                isLoading={isGetAppointmentApiLoading}
                isVisible={componentState.isVisible && RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_DETAIL_POPUP}
                onStatusChange={() => {
                  setComponentState(prev => ({ ...prev, isVisible: false, appointment: {} as IAppointmentDetail}));
                }}
                onClose={() => {
                  setComponentState(prev => ({ ...prev, isVisible: false, appointment: {} as IAppointmentDetail}));
                }}
              />
            )}
            {selectLeadViewCode.code ==
              RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER && (
              <SMSDrawer
                data={selectedRowData}
                isOpen={
                  selectLeadViewCode.code ==
                  RIGHT_SIDE_CONTAINER_CODE.SEND_SMS_DRAWER
                }
                onCompleteAction={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                  props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
                }}
              />
            )}
            {selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER &&
            !!selectedRowData?.contactData?.uuid && (
              <CareProgramConsent
                isVisible={
                  selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER
                }
                contactId={selectedRowData?.contactData?.uuid || ''}
                contactCareProgramId={selectedCareProgramAndSteps?.careProgramId}
                onClose={(action) => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT);
                  if (action) {
                    setAllMemberState((prev)=>{
                      return {
                        ...prev,
                        contactUpdated: !allMemberState?.contactUpdated
                      }
                    })
                  } else {
                    setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                  }
                }}
                programName={selectedCareProgramAndSteps?.careProgram?.payerCareProgram?.careProgramType?.value}
              />
            )}
            {selectLeadViewCode.code == RIGHT_SIDE_CONTAINER_CODE.CHAT_WITH_PATIENT &&
              selectedRowData?.contactData?.uuid &&
              selectedRowData?.contactData?.patient?.id && (
                <InstantChat
                  contactData={selectedRowData?.contactData}
                  contactUuid={selectedRowData?.contactData?.uuid}
                  isDrawerVisible={
                    selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CHAT_WITH_PATIENT
                  }
                  onActionPerformed={(actionCode) => {
                    if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                      setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                    }
                  }}
                />
              )}
              {
                (selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_TASK_VIEW ||
                 selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_ALERT_VIEW) &&
                !!selectedRowData?.contactData?.uuid && (
                  <InternalTaskDrawer
                    onClose={() => {
                      setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                    }}
                    contactId={selectedRowData?.contactData?.uuid}
                    shouldOpenAlertsTab={selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.ACTIONABLE_ALERT_VIEW}
                  />
                )
              }
             {selectLeadViewCode.code == RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
              && selectedRowData?.selectedConversation
              &&(
                <InstantChatView
                  selectedConversation={selectedRowData.selectedConversation}
                  contactData={{} as any}
                  isLoading={false}
                  isDrawerVisible={true}
                  isInstantChatView={true}
                  instantChatFromNotifications={true}
                  onActionPerformed={(actionCode: any) => {
                    if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                      setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                    }
                  }}
                />
             )}
            {selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.ECM_OUTREACH_VIEW &&
            !!selectedRowData?.contactData?.uuid && (
              <CareProgramStepOutreach
                isVisible={
                    selectLeadViewCode.code ===
                    RIGHT_SIDE_CONTAINER_CODE.ECM_OUTREACH_VIEW
                  }
                  outreachStepId={selectedCareProgramAndSteps.stepId}
                contactId={selectedRowData?.contactData?.uuid}
                contactCareProgramId={selectedCareProgramAndSteps.careProgramId}
                onClose={(action) => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT);
                  if (action) {
                    setAllMemberState((prev) => {
                      return {
                        ...prev,
                        contactUpdated: !allMemberState?.contactUpdated,
                      };
                    });
                  }
                }}
              />
            )}

          {selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT &&
            !!selectedRowData?.contactData?.uuid && (
              <CareProgramSubmitConsentView
                contactId={selectedRowData?.contactData?.uuid}
                contactCareProgramId={selectedCareProgramAndSteps.careProgramId}
                stepId={selectedCareProgramAndSteps.stepId || ''}
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              />
            )}
          {selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_ASSESSMENT &&
            !!selectedRowData?.contactData?.uuid && (
              <CareProgramAssessmentResponseView
                contactCareProgramId={selectedCareProgramAndSteps.careProgramId}
                stepId={selectedCareProgramAndSteps.stepId || ''}
                onClose={() => {
                  setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                }}
              />
            )}

          </View>
        );
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_OMNI_VIEW:
        const singlePersonData = getFormDataFromLeadData(
          selectedRowData.singleLeadData,
          mlovData
        );
        return (
          <PersonOmniView
            personData={singlePersonData}
            personType={PERSON_TYPES.LEAD}
          />
        );
    }
  };

  const getBgColorForView = (): string => {
    return '#fff'
    if (
      selectLeadViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CONTACT_OMNI_VIEW
    ) {
      return Colors.Custom.Gray100;
    }
    return Colors.Custom.Gray100;
  };

  const [getUserPracticeLocation] = useLazyQuery<IUserPracticeLocationData>(
    GetUserPracticeLocations, { fetchPolicy: 'no-cache' }
  );

  const getInitialData = async () => {
    let userPracticeLocations = allMemberState?.userPracticeLocation || [];
    if (!userPracticeLocations?.length) {
      const response = await getUserPracticeLocation();
      userPracticeLocations = response?.data?.accountLocations || [];
    }
    setAllMemberState({
      contactTypeUuid: contactTypeUuid,
      contactType: currentContactType,
      addContactType: currentContactType,
      addContactTypeUuid: contactTypeUuid,
      practiceLocations: {},
      customHtml: '',
      contactUpdated: false,
      isLoading: false,
      userPracticeLocation: userPracticeLocations || []
    });
  };

  return (
    <View backgroundColor={getBgColorForView()} flex={1}>
      <View style={[commonBodyContentContainer.pageContentContainer]}>
        {getLeadViewFromSelectedCode()}
      </View>
      <Outlet />
      <CreateGroupModal
        isOpen={openGroupModal}
        contactTypeUuid={contactTypeUuid}
        metadata={getGroupFilter()}
        onConfirm={() => {
          //createGroup(groupName)
          setOpenGroupModal(false);
          setSelectedRowData('');
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
          props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
        }}
        onClose={() => {
          setOpenGroupModal(false);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
          props.onActionPerformed?.(COMMON_ACTION_CODES.RESET, {});
        }}
      />
    </View>
  );
};

export default AllMembersView;
