import React from 'react';
import {Path, Svg} from 'react-native-svg';

const RedDotSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="4"
      height="4"
      fill="none"
      viewBox="0 0 4 4"
    >
      <circle cx="2" cy="2" r="2" fill="#D92D20"></circle>
    </Svg>
  );
};

export default RedDotSvg;
