import {View} from 'native-base';
import React, {useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {useNavigate, useParams} from 'react-router-dom';
import {MLOV_CATEGORY} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {TELEPHONY_VIEW} from '../../../constants/TelephonyConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {FlowType} from '../../../context/WorkflowContext';
import {Colors} from '../../../styles';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import PlusIcon from '../../common/Svg/PlusSvg';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../common/TableTopBar';
import {CloudTelephonyView} from '../CloudTelephony';
import PurchaseNumberView from '../CloudTelephony/PurchaseNumberView/PurchaseNumberView';
import {getWorkflowListTopBarButtons} from '../Workflow/Workflow/WorkflowTableView/Helper/TopBarButtons';
import WorkflowView from '../Workflow/Workflow/WorkflowView';
import {SMS_INBOX_ACTION_CODES} from './Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {styles} from './styles';
import CommunicationTypesTable from './Conversations/CommunicationTypes/CommunicationTypesTable';
import {ICommunicationType} from './Conversations/CommunicationTypes/interfaces';
import {VideoCallSettings} from './VideoCallSettings';
import {isZoomEnabled} from '../../../utils/commonUtils';
import {ExtensionConfigurationTable} from '../CallConfiguration/CallExtensionConfigurationTable';
import OnCallSchedule from '../OnCallSchedule/OnCallSchedule';
import { ON_CALL_SCHEDULE_VIEW } from '../OnCallSchedule/const';

const AdminCallSettings = () => {
  const [searchString, setSearchString] = React.useState('');
  const commonData = React.useContext(CommonDataContext);
  const tagsMlovs = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const [selectedTab, setSelectedTab] = React.useState({} as ITabsList);
  const [selectedData, setSelectedData] = React.useState({} as any);
  const [state, setState] = useState({
    viewCode: '',
    searchString: '',
    selectedData: {} as ICommunicationType,
  });

  const [mlov, setMlov] = React.useState({value: ''});
  const isZoomEnabledForAccount = isZoomEnabled();

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'number',
        title: 'Phone Number',
        path: 'number',
        tabContainerElem: () => {
          return (
            <CloudTelephonyView
              selectedCode={selectedTab.selectedDrawerCode}
              selectedData={selectedData}
              searchString={searchString}
              onActionPerformed={(actionCode: string, actionData?: any) => {
                const tempSelectedData = actionData || {};
                setSelectedTab((prev) => {
                  return {
                    ...prev,
                    selectedDrawerCode: actionCode,
                    actionData: actionData,
                  };
                });
                setSelectedData((prev: any) => {
                  return {
                    ...prev,
                    selectedCode: actionData.selectedCode,
                    ...tempSelectedData,
                  };
                });
              }}
            />
          );
        },
      },
      {
        key: 'ivr',
        path: 'ivr',
        title: 'Phone Trees',
        tabContainerElem: () => {
          return (
            <View key={FlowType.ivr} bgColor={'white'}>
              <WorkflowView
                searchText={searchString}
                showTableTopBar={false}
                flowType={FlowType.ivr}
              />
            </View>
          );
        },
      },
      {
        key: 'communication_types',
        path: 'communication_types',
        title: 'Communication Types',
        tabContainerElem() {
          return (
            <CommunicationTypesTable
              searchString={state.searchString}
              viewCode={state.viewCode}
              selectedData={state.selectedData}
              onActionPerformed={(code, data) => {
                onCommunicationTypesTableActionPerformed(code, data);
              }}
            />
          );
        },
      },
      {
        key: 'call_extension_configuration',
        path: 'call_extension_configuration',
        title: 'Extension Configuration',
        tabContainerElem() {
          return (
            <ExtensionConfigurationTable
              searchString={searchString}
              selectedCode={selectedTab.selectedDrawerCode}
              onActionPerformed={onExtensionConfigurationTableActionPerformed}
            />
          );
        },
      },
      {
        key: 'on_call_schedule',
        path: 'on_call_schedule',
        title: 'On-Call Schedule',
        tabContainerElem: () => {
          return (
            <OnCallSchedule
              selectedDrawerCode={selectedTab.selectedDrawerCode}
              selectedData={selectedData}
              onActionPerformed={(actionCode: string, actionData?: any) => {
                switch (actionCode) {
                  case COMMON_ACTION_CODES.CLOSE_MODAL:
                    setSelectedTab((prev) => {
                      return {
                        ...prev,
                        selectedDrawerCode: '',
                      };
                    });
                    break;
                  case COMMON_ACTION_CODES.OPEN_MODAL:
                    setSelectedTab((prev) => {
                      return {
                        ...prev,
                        selectedDrawerCode: actionData,
                      };
                    });
                    break;
                }
              }}
            />
          );
        },
      },
      // ...(isZoomEnabledForAccount
      //   ? [
      //       {
      //         key: 'video_call',
      //         path: 'video_call',
      //         title: 'Video Call',
      //         tabContainerElem() {
      //           return <VideoCallSettings />;
      //         },
      //       },
      //     ]
      //   : []),
    ];
    return array;
  };
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setOpenModal(false);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const navigate = useNavigate();
  const getSearch = (actionCode: string, actionData: any) => {
    setSearchString(actionData.searchString);
  };

  const onCommunicationTypesTableActionPerformed = (
    code: string,
    data: ICommunicationType
  ) => {
    switch (code) {
      case COMMON_ACTION_CODES.EDIT:
        setState((prev) => {
          return {
            ...prev,
            viewCode: COMMON_ACTION_CODES.EDIT,
            selectedData: data,
          };
        });
        break;

      case COMMON_ACTION_CODES.DELETE:
        setState((prev) => {
          return {
            ...prev,
            viewCode: COMMON_ACTION_CODES.DELETE,
            selectedData: data,
          };
        });
        break;

      default:
        setState((prev) => {
          return {
            ...prev,
            viewCode: '',
            selectedData: {} as ICommunicationType,
          };
        });
    }
  };

  const onExtensionConfigurationTableActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE:
        setSelectedTab((prev) => {
          return {
            ...prev,
            selectedDrawerCode: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.OPEN:
        onAddExtensionPress();
        break;
      case COMMON_ACTION_CODES.SEARCH:
        setSearchString(actionData.searchString);
        break;
      default:
        return;
    }
  }

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };
  const {type} = useParams();
  const tabType = {
    products: 'products',
    services: 'services',
    journeys: 'journeys',
  };

  const flowTypeIvr = FlowType.ivr || '';
  const workflowUrlIvr = `/admin/call/ivr/create?currentTab=TRIGGER&flowType=IVR`;

  const onPurchaseNumber = (): any => {
    setSelectedTab((prev) => {
      return {
        ...prev,
        selectedDrawerCode: TELEPHONY_VIEW.PURCHASE_NEW_NUMBER,
      };
    });
  };

  const ivrTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getWorkflowListTopBarButtons(
          [
            () => {
              navigate(workflowUrlIvr, {});
            },
          ],
          workflowUrlIvr,
          'New'
        )}
      />
    </>
  );

  const onCallScheduleTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        title={``}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        hideSearchBar={true}
        buttonList={[
          {
            btnText: 'new',
            backgroundColor: Colors.secondary['100'],
            borderColor: Colors.secondary['200'],
            textColor: '',
            variant: 'PRIMARY',
            size: 'sm',
            leftIcon: <PlusIcon />,
            btnClick: ()=> {
              setSelectedTab((prev) => {
                return {
                  ...prev,
                  selectedDrawerCode: ON_CALL_SCHEDULE_VIEW.CREATE_SCHEDULE,
                };
              });
            },
          },
        ]}
      />
    </>
  );


  const NumberTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        title={``}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        buttonList={[
          {
            btnText: 'purchaseNewNumber',
            backgroundColor: Colors.secondary['100'],
            borderColor: Colors.secondary['200'],
            textColor: '',
            variant: 'PRIMARY',
            size: 'sm',
            leftIcon: <PlusIcon />,
            btnClick: onPurchaseNumber,
          },
        ]}
      />
    </>
  );

  const onAddCommunicationTypePress = () => {
    setState((prev) => {
      return {
        ...prev,
        viewCode: COMMON_ACTION_CODES.ADD,
      };
    });
  };

  const onAddExtensionPress = () => {
    setSelectedTab((prev) => {
      return {
        ...prev,
        selectedDrawerCode: TELEPHONY_VIEW.ADD_CONFIGURATION,
      };
    });
  };

  const CommunicationTopBar = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        title={``}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          setState((prev) => {
            return {
              ...prev,
              searchString: actionData.searchString || '',
            };
          });
        }}
        buttonList={
          [
            // {
            //   btnText: 'addCommunicationType',
            //   backgroundColor: Colors.secondary['100'],
            //   borderColor: Colors.secondary['200'],
            //   textColor: '',
            //   variant: 'PRIMARY',
            //   size: 'sm',
            //   leftIcon: <PlusIcon />,
            //   btnClick: onAddCommunicationTypePress,
            // },
          ]
        }
      />
    </>
  );

  const ExtensionConfigurationTopBar = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        title={``}
        alignSearchBarFlexEnd={true}
        onActionPerformed={onExtensionConfigurationTableActionPerformed}
        buttonList={
          [
            {
              btnText: 'newConfiguration',
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              textColor: '',
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <PlusIcon />,
              btnClick: onAddExtensionPress,
            },
          ]
        }
      />
    </>
  );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab.key === 'number') {
      ele = NumberTopBar;
    } else if (selectedTab.key === 'ivr') {
      ele = ivrTopBar;
    } else if (selectedTab.key == 'communication_types') {
      ele = CommunicationTopBar;
    } else if (selectedTab.key == 'on_call_schedule') {
      ele = onCallScheduleTopBar;
    } else if (selectedTab.key === 'video_call') {
      ele = <></>;
    } else if (selectedTab.key === 'call_extension_configuration') {
      ele = ExtensionConfigurationTopBar;
    } else {
      ele = NumberTopBar;
    }
    return ele;
  };

  React.useEffect(() => {
    const filteredResult = tagsMlovs?.filter((item) => {
      return item.code === 'CONVERSATION';
    });
    const mlov = filteredResult[0];
    setMlov(mlov);
    setSelectedTab(tagsTabList()[0]);
  }, []);

  const onSmsInboxActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case SMS_INBOX_ACTION_CODES.DRAWER_CLOSE:
      case COMMON_ACTION_CODES.CANCEL:
        setSelectedTab((prev: any) => {
          return {
            ...prev,
            selectedDrawerCode: '',
          };
        });
        break;
    }
  };

  return (
    <View>
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          contentTopMargin={0}
          heading="communicationSettingTitle"
          extraBigRightView
          showHeading
          isAddMaxTab={true}
          minScreenSupportWidth={ 1800}
          leftOffset={1200}
          basePath={'/admin/call'}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          rightView={(activeTab) => {
            return getSelectedTopBar(activeTab);
          }}
          bottomOffset={150}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
      {selectedTab.selectedDrawerCode === TELEPHONY_VIEW.PURCHASE_NEW_NUMBER &&
        false && (
          <PurchaseNumberView
            onFormCompleteAction={(actionCode: string) => {
              setTimeout(() => {
                onSmsInboxActionPerformed(actionCode);
              }, 500);
            }}
            userList={[]}
          />
        )}
    </View>
  );
};

export default AdminCallSettings;
