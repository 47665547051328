import { HStack,View,VStack } from 'native-base';
import { useEffect,useState } from 'react';
import AutomationAnalyticsSvg from '../../../assets/svg/AutomationAnalyticsSvg';
import ErrorEmailSvg from '../../../assets/svg/ErrorEmailSvg';
import ErrorSMSSvg from '../../../assets/svg/ErrorSMSSvg';
import ErrorSvg from '../../../assets/svg/ErrorSvg';
import RedDotSvg from '../../../assets/svg/RedDotSvg';
import { getAccountUUID } from '../../../utils/commonUtils';
import { getWorkflowNameByMasterIds } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { COMMUNICATION_LOG_TAB } from './CommunicationLogChart';
import CommunicationLogChartMemberList from './CommunicationLogChartMemberList';
import { Spinner } from 'native-base';
import { useLazyQuery } from '@apollo/client';
import UserQueries from '../../../services/User/UserQueries';
import DataNotFoundSvg from '../../../assets/svg/DataNotFoundSvg';
import {useIntl} from 'react-intl';

const AutomationCommunicationAnalytics = (props: {
  startDate: string;
  endDate: string;
  automationList: [
    {workflowMasterId: string; smsFailedCount: number; emailFailedCount: number}
  ];
}) => {
  const tenantId = getAccountUUID();
  const intl = useIntl();
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [state, setState] = useState<any>({
    isLoading: false,
    selectedWorkflowDetails: {
      isOpen: false,
      workflowMasterId: '',
      workflowName: '',
      userId: '',
      emailFailedCount: 0,
      smsFailedCount: 0,
    },
    workflowMasterIdsMap: {},
  });

  const getWorkflowData = async () => {
    const workflowMasterIdList = props.automationList
      .map((automation) => {
        return automation?.workflowMasterId;
      })
      .filter((workflowMasterId) => {
        return !!workflowMasterId;
      });
    if (workflowMasterIdList) {
      setState((oldData: any) => {
        return {...oldData, isLoading: true};
      });
      const workflowList = await getWorkflowNameByMasterIds(
        workflowMasterIdList,
        tenantId
      );
      const workflowMasterIdsMap: any = {};
      workflowList.forEach((workflow: any) => {
        workflowMasterIdsMap[workflow?.workflowMasterId] = workflow;
      });
      setState((oldData: any) => {
        return {...oldData, isLoading: false, workflowMasterIdsMap};
      });
    }
  };
  const [user, setUser] = useState<any>()
  const [getUserById] = useLazyQuery(
    UserQueries.GET_CONTACTS_BY_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    const fetchUserById = async (userId: string) => {
      try {
        const response = await getUserById({ variables: { userId } });
        if (response?.data?.user) {
          setUser(response?.data?.user);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (state.selectedWorkflowDetails.workflowMasterId) {
      const userId = state.workflowMasterIdsMap[state.selectedWorkflowDetails.workflowMasterId]?.userId;
      if (userId) {
        fetchUserById(userId);
      }
    }
  }, [state.selectedWorkflowDetails.workflowMasterId, state.workflowMasterIdsMap]);

  useEffect(() => {
    getWorkflowData();
  }, [props.automationList, props.automationList?.length]);

  useEffect(() => {
    const allDataEmpty = props.automationList.every(
      (item) => item.emailFailedCount === 0 && item.smsFailedCount === 0
    );
    setNoDataAvailable(allDataEmpty);
  }, [props.automationList]);

  return (
    <div style={{ margin: '2px 0' }}>
      {state.isLoading ? (
        <View
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Spinner />
        </View>
      ) :
        noDataAvailable ? (
          <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            overflow={'hidden'}
          >
            <DataNotFoundSvg />
            <span
              style={{
                marginTop: '16px',
                fontSize: '14px',
                color: '#8A94A8',
                fontWeight: '400',
              }}
            >
              {intl.formatMessage({id: 'noDataAlert'})}
            </span>
          </View>
        ) : (
          <>
            {state.selectedWorkflowDetails?.isOpen ? (
              <CommunicationLogChartMemberList
                startDate={props.startDate}
                endDate={props.endDate}
                emailCount={state?.selectedWorkflowDetails?.emailFailedCount || 0}
                smsCount={state?.selectedWorkflowDetails?.smsFailedCount || 0}
                originService={COMMUNICATION_LOG_TAB.AUTOMATION.KEY}
                communicationTypeList={state?.selectedWorkflowDetails?.emailFailedCount ? ['EMAIL', 'SMS'] : ['SMS', 'EMAIL']}
                typeId={state.selectedWorkflowDetails.workflowMasterId}
                errorTitle={state.workflowMasterIdsMap[state.selectedWorkflowDetails.workflowMasterId]?.name}
                userName={user?.name}
                open={state.selectedWorkflowDetails?.isOpen}
                setIsOpen={() => {
                  setState((oldValue: any) => {
                    return {
                      ...oldValue,
                      selectedWorkflowDetails: {
                        isOpen: false,
                        workflowMasterId: '',
                        workflowName: '',
                        userId: '',
                        emailFailedCount: 0,
                        smsFailedCount: 0,
                      },
                    };
                  });
                }}
              />
            ) : (
              <></>
            )}
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                {(props.automationList || []).map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom: '1px solid #e0e0e0',
                      width: '100%',
                      paddingTop: index === 0 ? '0' : '8px',
                    }}
                    onClick={() => {
                      setState((oldValue: any) => {
                        return {
                          ...oldValue,
                          selectedWorkflowDetails: {
                            isOpen: true,
                            workflowMasterId: row?.workflowMasterId,
                            emailFailedCount: row.emailFailedCount,
                            smsFailedCount: row.smsFailedCount,
                          },
                        };
                      });
                    }}
                  >
                    <td style={{ padding: '0px 0', width: '100%' }}>
                      <HStack alignItems="center" space={4}>
                        <View display={'flex'} justifyContent={'center'} style={{ marginLeft: 10 }}>
                          <View style={{ padding: 12 }}>
                            <AutomationAnalyticsSvg />
                          </View>
                        </View>
                        <View style={{ padding: '8px', marginLeft: -10 }}>
                          <VStack>
                            <span
                              style={{
                                color: '#3A485F',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16.8px',
                              }}
                            >
                              {state.workflowMasterIdsMap[row.workflowMasterId]?.name}
                            </span>
                            <HStack alignItems="center" style={{ marginTop: '4px' }}>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ErrorSvg />
                              </div>
                              <span
                                style={{
                                  color: '#D92D20',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  lineHeight: '14.4px',
                                  marginRight: '8px',
                                  verticalAlign: 'middle',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {'Execution Error'}
                              </span>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <RedDotSvg />
                              </div>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ErrorEmailSvg />
                                <span
                                  style={{
                                    color: '#D92D20',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    marginLeft: '8px',
                                  }}
                                >
                                  {row.emailFailedCount || 0}
                                </span>
                              </div>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ErrorSMSSvg />
                                <span
                                  style={{
                                    color: '#D92D20',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    marginLeft: '8px',
                                  }}
                                >
                                  {row.smsFailedCount || 0}
                                </span>
                              </div>
                            </HStack>
                          </VStack>
                        </View>
                      </HStack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
    </div>
  );
};

export default AutomationCommunicationAnalytics;
