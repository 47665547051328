import React from 'react';
import {Svg, Path} from 'react-native-svg';

function PendingAlertsSvg() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 2v2m6 4h-2m-4 6v-2M2 8h2m-.243-4.243l1.414 1.415m7.072-1.415l-1.415 1.415m1.415 7.07l-1.415-1.414m-7.07 1.415l1.414-1.415"
      ></Path>
    </Svg>
  );
}

export default PendingAlertsSvg;
