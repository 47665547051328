import { ILoginUserData, IUserRole } from '../../Interfaces';
import jwtDecode from 'jwt-decode';
import LocalStorage from '../../utils/LocalStorage';
import { getMomentObj } from '../../utils/DateUtils';
import { LOCAL_STORAGE_KEYS } from './constants';
import { SIDE_CAR_MODULE_CODE } from '../../components/SideCar/constants';
import CommonService from '../../services/CommonService/CommonService';
import { getEnvVariable } from '../../constants/BaseUrlConst';
import { EHRName } from '../../components/PersonOmniView/MiddleContainer/PatientNotes/interfaces';

export interface IAccountEhrInfo {
  accountId: string;
  ehrName: string;
  isIntegrated: boolean;
  googleStoreInfo: {
    dataSetId: string;
    fhirStoreId: string;
  },
  name: string;
  connector: string;
  practiceId: number;
  ehrPracticeWebUrl: string;
}

export async function storeLoginData(
  loginData: {
    userData: ILoginUserData;
    userRoles: IUserRole[] | undefined;
    userMainDataObj: any;
    accountSubdomain: any;
  }
) {
  const foldAccessToken = loginData?.userData?.fold_access_token;
  if (!loginData.userData || !loginData.userMainDataObj || !foldAccessToken || isFoldAccessTokenExpired(foldAccessToken) || !loginData.accountSubdomain) {
    throw new Error('Invalid data');
  }

  await LocalStorage.setMultipleItems({
    userRoles: JSON.stringify(loginData.userRoles),
    account: JSON.stringify(loginData.accountSubdomain),
    loginDone: 'true',
    user: JSON.stringify(loginData.userMainDataObj),
  });
}

export const isFoldAccessTokenExpired = (token: string) => {
  const expiry = getFoldAccessTokenExpiry(token);
  const currentTime = new Date().getTime() / 1000;
  if (!expiry || currentTime >= expiry) {
    return true;
  }
  return false;
};

export const getFoldAccessTokenExpiry = (token: string) => {
  if (!token) {
    return undefined;
  }
  const decoded = jwtDecode(token);
  const decodedObj = JSON.parse(JSON.stringify(decoded));

  if (decodedObj.exp) {
    return decodedObj.exp;
  }
  return undefined;
};

export const broadcastEventToSidecarActionsScreen = async (pageId: string, eventCode: string, eventData?: any) => {
  if (!window?.parent?.postMessage) {
    return false;
  }

  const ehrInfoFromStorage = await getCurrentAccountEhrInfo();
  const ehrPracticeWebUrl = ehrInfoFromStorage?.ehrPracticeWebUrl;

  if (!ehrPracticeWebUrl) {
    return false;
  }

  const message = {
    moduleCode: SIDE_CAR_MODULE_CODE.SIDE_CAR_ACTIONS,
    eventCode,
    eventData: {
      pageId: pageId,
      ...(eventData || {}),
    },
  };

  window?.parent?.postMessage(message, ehrPracticeWebUrl);
};

export const broadcastEventToActiveSidecarMainScreen = async (pageId: string, eventCode: string, eventData?: any) => {
  const message = {
    moduleCode: SIDE_CAR_MODULE_CODE.SIDE_CAR_WINDOW_ACTIONS,
    eventCode,
    eventData: {
      pageId: pageId,
      ...(eventData || {}),
    },
  };

  const extensionInfo = await LocalStorage.getItem('extensionInfo');
  const targetDomain = `chrome-extension://${extensionInfo?.id}`;

  const iframe = window.document.getElementById('sidecar-main-window') as HTMLIFrameElement;
  iframe?.contentWindow?.postMessage?.(message, targetDomain);
};

export const broadCastEventToAllTabs = async (eventCode: string, eventData?: any) => {
  const tabs = await chrome.tabs.query({
    url: [
      'https://preview.athenahealth.com/*',
      'https://athenanet.athenahealth.com/*',
      'https://sandbox.elationemr.com/*',
      'https://app.elationemr.com/*',
    ],
  });

  for (const tab of tabs) {
    if (!tab.id) {
      continue;
    }
    try {
      await chrome.tabs.sendMessage(tab.id, {
        moduleCode: 'sidecar',
        eventCode: eventCode,
        eventData: eventData,
      }).catch(error => {

      });
    } catch (error) {

    }
  }
};

export const isValidLoginForSidecar = async () => {
  const [
    userString,
    accountString,
    loginDone,
  ] = await Promise.all([
    LocalStorage.getItem('user'),
    LocalStorage.getItem('account'),
    LocalStorage.getItem('loginDone'),
  ]);

  if (loginDone !== 'true' || !userString || !accountString) {
    return false;
  }

  const user = JSON.parse(userString);

  const foldAccessToken = user?.fold_access_token;
  if (isFoldAccessTokenExpired(foldAccessToken)) {
    return false;
  }
  return true;
};

export const getLoggedInUserInfoForSidecar = async () => {
  const isValidLogin = await isValidLoginForSidecar();
  if (!isValidLogin) {
    return undefined;
  }
  const userString = await LocalStorage.getItem('user');
  const user = JSON.parse(userString || '{}');
  return {
    id: user?.data?.id as number,
    uuid: user?.data?.uuid as string,
    email: user?.data?.email as string,
  };
};

export const getLoggedInAccountInfoForSidecar = async () => {
  const isValidLogin = await isValidLoginForSidecar();
  if (!isValidLogin) {
    return undefined;
  }
  const accountString = await LocalStorage.getItem('account');
  const account = JSON.parse(accountString || '{}');

  return {
    id: account?.accountId,
    uuid: account?.accountUuid ?? account?.accountToSubdomain?.uuid,
    name: account?.accountToSubdomain?.name,
    subdomain: account?.subDomain,
    subDomainUrl: `https://${account?.subDomain}`
  };
};

export const setRequestedLoginUserHash = async (username: string, subdomain: string) => {
  const loginUserHash = getRequestedLoginUserHash(username, subdomain);
  const loginHashValidTill = getMomentObj(new Date()).add(5, 'minutes');

  await LocalStorage.setItem(LOCAL_STORAGE_KEYS.REQUESTED_USER_LOGIN_HASH, loginUserHash);
  await LocalStorage.setItem(LOCAL_STORAGE_KEYS.REQUESTED_USER_LOGIN_HASH_VALID_TILL, loginHashValidTill.valueOf().toString());
};

export const validateRequestedLoginHash = async (username: string, subdomain: string) => {
  if (!username || !subdomain) {
    return false;
  }

  // const extensionInfo = await chrome.management.getSelf();
  // if (extensionInfo?.installType === 'development') {
  //   return true;
  // }

  const loginUserHash =  await LocalStorage.getItem(LOCAL_STORAGE_KEYS.REQUESTED_USER_LOGIN_HASH);
  const loginHashValidTill =  await LocalStorage.getItem(LOCAL_STORAGE_KEYS.REQUESTED_USER_LOGIN_HASH_VALID_TILL);

  if (!loginUserHash || !loginHashValidTill) {
    return false;
  }

  try {
    const loginHashValidTillTime = parseInt(loginHashValidTill);
    if (new Date().getTime() > loginHashValidTillTime) {
      return false;
    }
  } catch (error) {

    return false;
  }

  const newLoginUserHash = getRequestedLoginUserHash(username, subdomain);
  if (loginUserHash !== newLoginUserHash) {
    return false;
  }

  return true;
}

export const getRequestedLoginUserHash = (username: string, subdomain: string) => {
  return btoa(`${username}/${subdomain}`);
};

export async function getCurrentAccountEhrInfo() {
  const ehrInfoFromStorage = JSON.parse(await LocalStorage.getItem('accountEhrInfo') || '{}') as IAccountEhrInfo;
  if (!ehrInfoFromStorage?.ehrName) {
    const locationGroupId = await getSidecarLocationGroupId();
    const commonService = CommonService.getCommonServiceInstance();
    const integrationService = commonService.integrationService;
    const url = `/config/ehr-info?locationGroupId=${locationGroupId}`;
    const response = await integrationService.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = (response.data || {}) as IAccountEhrInfo;
    const ehrPracticeWebUrl = await getEhrPracticeWebUrl(data);
    if (data?.ehrName && ehrPracticeWebUrl) {
      data.ehrPracticeWebUrl = ehrPracticeWebUrl;
      await LocalStorage.setItem('accountEhrInfo', JSON.stringify(data));
      return data;
    }
    return undefined;
  }
  return ehrInfoFromStorage;
}

export async function setSidecarLocationGroupId(locationGroupId: string) {
  await LocalStorage.setItem('sidecarLocationGroupId', JSON.stringify({ locationGroupId }));
}
export async function getSidecarLocationGroupId() {
  return JSON.parse((await LocalStorage.getItem('sidecarLocationGroupId')) || '{}')?.locationGroupId;
}

async function getEhrPracticeWebUrl(accountEhrInfo: IAccountEhrInfo) {
  if (!accountEhrInfo) {
    return;
  }

  if (accountEhrInfo.ehrPracticeWebUrl) {
    return accountEhrInfo.ehrPracticeWebUrl;
  }

  if (accountEhrInfo.accountId === '3e6342f8-36e9-4fbb-a305-097955cd2cbf') {
    return 'https://sandbox.elationemr.com';
  }

  const ehrName = accountEhrInfo.ehrName;
  const envCode = getEnvVariable();

  if (ehrName === EHRName.ATHENA) {
    if (envCode === '') {
      return 'https://athenanet.athenahealth.com';
    } else {
      return 'https://preview.athenahealth.com';
    }
  } else if (ehrName === EHRName.ELATION) {
    if (envCode === '') {
      return 'https://app.elationemr.com';
    } else {
      return 'https://sandbox.elationemr.com';
    }
  }
}
