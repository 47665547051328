import {
  MEMBERSHIP_INTERVAL_CODE,
  STRIPE_INTERVAL_CODE,
} from '../../../../../../../constants';
import {IProductProperty} from '../../ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {
  IBillingProductDisplayData,
  ICareJourneyStatusIDs,
  IImageDisplayData,
  IIncluded,
  IJourneyApiData,
  IProductApiData,
  IProductRelationships,
  IProductTableDisplayData,
} from '../ProductTable/interfaces';
import {IMultiSelectTagObj} from './interfaces';
import {IPrice, IStripProductResponse} from './stripeRespInterface';

export const formateProductListData = (
  productDataList: IProductApiData[],
  dataList: IIncluded[]
): IProductTableDisplayData[] => {
  const productDisplayList: IProductTableDisplayData[] = [];
  productDataList.forEach((productData) => {
    const productAttribute = productData.attributes;
    let status = 'PENDING SALE';
    if (productAttribute.available) {
      status = 'AVAILABLE';
    }
    const taxonStrList = getTaxonStrList(productData, dataList) || [];
    const imageList = getProductImageList(productData, dataList) || [];
    productDisplayList.push({
      id: productData.id,
      name: productAttribute.name,
      status: status,
      available: productAttribute.available,
      mrpData: {
        mrp: productAttribute.display_compare_at_price,
        currency: productAttribute.currency,
      },
      sellingPrice: productAttribute.display_price,
      taxonStrList: taxonStrList,
      imageData: imageList,
      description: productAttribute?.description,
    });
  });
  return productDisplayList;
};

export const formatBillingProductListData = (
  productDataList: IStripProductResponse
) => {
  const productDisplayList: IBillingProductDisplayData[] = [];
  const productList = productDataList.data;
  productList?.forEach((productData) => {
    productDisplayList.push({
      id: productData.id,
      name: productData.name,
      available: productData.active,
      mrpData: {
        mrp: '',
        currency: productData?.prices[0]?.currency
          ? productData.prices[0].currency
          : '',
      },
      sellingPrice: productData.default_price,
      taxonStrList: [''],
      imageData: productData.images,
      description: productData.description,
      active: productData.active,
      prices: {
        monthly: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.MONTHLY,
          productData.prices
        ),
        quarterly: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.QUARTERLY,
          productData.prices
        ),
        yearly: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.YEARLY,
          productData.prices
        ),
        daily: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.DAILY,
          productData.prices
        ),
        oneTime: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.ONE_TIME,
          productData.prices
        ),
        tiers: getTieredPrice(productData.prices),
      },
      rowData: productData,
    });
  });
  return productDisplayList;
};

export const formateStripeProductListData = (
  productDataList: IStripProductResponse,
  isOneTime?: boolean
) => {
  const productDisplayList: IProductTableDisplayData[] = [];
  const productList = [...productDataList.data];
  // let filterProductLists = [] as any[];
  // if (isOneTime) {
  //   filterProductLists = productList?.filter((prod: any) => {
  //     if (prod?.prices?.length === 1) {
  //       if (prod?.prices[0]?.metadata?.category === 'oneTime') {
  //         return prod;
  //       }
  //     }
  //   });
  // } else {
  //   filterProductLists = productList?.filter((prod: any) => {
  //     if (prod?.prices?.length === 1) {
  //       if (prod?.prices[0]?.metadata?.category !== 'oneTime') {
  //         return prod;
  //       }
  //     } else {
  //       return prod;
  //     }
  //   });
  // }

  productList?.forEach((productData) => {
    productDisplayList.push({
      id: productData.id,
      name: productData.name,
      available: productData.active,
      mrpData: {
        mrp: '',
        currency: productData?.prices[0]?.currency
          ? productData.prices[0].currency
          : '',
      },
      sellingPrice: productData.default_price,
      taxonStrList: [''],
      imageData: productData.images,
      description: productData.description,
      active: productData.active,
      prices: {
        monthly: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.MONTHLY,
          productData.prices
        ),
        quarterly: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.QUARTERLY,
          productData.prices
        ),
        yearly: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.YEARLY,
          productData.prices
        ),
        daily: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.DAILY,
          productData.prices
        ),
        oneTime: getIntervalPrice(
          MEMBERSHIP_INTERVAL_CODE.ONE_TIME,
          productData.prices
        ),
      },
      rowData: productData,
      trialInfo:{
        monthly: getTrailInfo(
          MEMBERSHIP_INTERVAL_CODE.MONTHLY,
          productData.prices
        ),
        quarterly: getTrailInfo(
          MEMBERSHIP_INTERVAL_CODE.QUARTERLY,
          productData.prices
        ),
        yearly: getTrailInfo(
          MEMBERSHIP_INTERVAL_CODE.YEARLY,
          productData.prices
        ),
        daily: getTrailInfo(
          MEMBERSHIP_INTERVAL_CODE.DAILY,
          productData.prices
        ),
        oneTime: getTrailInfo(
          MEMBERSHIP_INTERVAL_CODE.ONE_TIME,
          productData.prices
        ),
      }
    });
  });
  return productDisplayList;
};

const getProductImageList = (
  currentProductData: IProductApiData,
  dataList: IIncluded[]
): IImageDisplayData[] => {
  let imageList: any[] = [];
  const imagesObj = currentProductData?.relationships?.images || {};
  if (imagesObj && imagesObj?.data && imagesObj?.data.length) {
    const currentTaxonList = imagesObj.data;
    currentTaxonList.forEach((imageDataObj) => {
      const tempList = getDataListFromRelationIdAndType(
        dataList,
        imageDataObj.id,
        imageDataObj.type
      );
      if (tempList && tempList.length) {
        imageList = [...imageList, ...tempList];
      }
    });
  }
  return imageList;
};

export const getTaxonStrList = (
  currentProductData: IProductApiData,
  dataList: IIncluded[]
): string[] => {
  const taxonStrList: string[] = [];
  const taxonObj = currentProductData?.relationships?.taxons || {};
  if (taxonObj && taxonObj?.data && taxonObj?.data.length) {
    const currentTaxonList = taxonObj.data;
    currentTaxonList.forEach((taxonDataObj) => {
      const tempList = getDataListFromRelationIdAndType(
        dataList,
        taxonDataObj.id,
        taxonDataObj.type
      );
      if (tempList && tempList.length) {
        tempList.forEach((singleObj) => {
          const prettyName = singleObj?.attributes?.pretty_name || '';
          if (prettyName) {
            taxonStrList.push(prettyName);
          }
        });
      }
    });
  }
  return taxonStrList;
};

export const getTaxonObjList = (
  currentProductData: IProductApiData,
  dataList: IIncluded[]
): IMultiSelectTagObj[] => {
  const taxonObjList: IMultiSelectTagObj[] = [];
  const taxonObj = currentProductData?.relationships?.taxons || {};
  if (taxonObj && taxonObj?.data && taxonObj?.data.length) {
    const currentTaxonList = taxonObj.data;
    currentTaxonList.forEach((taxonDataObj) => {
      const tempList = getDataListFromRelationIdAndType(
        dataList,
        taxonDataObj.id,
        taxonDataObj.type
      );
      if (tempList && tempList.length) {
        tempList.forEach((singleObj) => {
          const prettyName = singleObj?.attributes?.pretty_name || '';
          if (prettyName) {
            taxonObjList.push({id: singleObj.id, value: prettyName});
          }
        });
      }
    });
  }
  return taxonObjList;
};

export const getOptionTypesObjList = (
  currentProductData: IProductApiData,
  dataList: IIncluded[]
): IMultiSelectTagObj[] => {
  const optionTypesObjList: IMultiSelectTagObj[] = [];
  const optionTypeObj = currentProductData?.relationships?.option_types || {};
  if (optionTypeObj && optionTypeObj?.data && optionTypeObj?.data.length) {
    const currentTaxonList = optionTypeObj.data;
    currentTaxonList.forEach((optionTypeDataObj) => {
      const tempList = getDataListFromRelationIdAndType(
        dataList,
        optionTypeDataObj.id,
        optionTypeDataObj.type
      );
      if (tempList && tempList.length) {
        tempList.forEach((singleObj) => {
          const presentation = singleObj?.attributes?.presentation || '';
          if (presentation) {
            optionTypesObjList.push({id: singleObj.id, value: presentation});
          }
        });
      }
    });
  }
  return optionTypesObjList;
};

export const getDataListFromRelationIdAndType = (
  dataList: IIncluded[],
  id: string,
  type: string
): IIncluded[] => {
  const finalFilterList =
    dataList.filter((productData) => {
      return productData.id === id && productData.type === type;
    }) || [];
  return finalFilterList;
};

export const getProductPropertyObjList = (
  currentProductData: IProductApiData,
  dataList: IIncluded[]
): IProductProperty[] => {
  const productPropertyObjList: IProductProperty[] = [];
  const productPropertiesObj =
    currentProductData?.relationships?.product_properties || {};
  if (
    productPropertiesObj &&
    productPropertiesObj?.data &&
    productPropertiesObj?.data.length
  ) {
    const currentProductPropertiesList = productPropertiesObj.data;
    currentProductPropertiesList.forEach((productPropertyObj) => {
      const tempList = getDataListFromRelationIdAndType(
        dataList,
        productPropertyObj.id,
        productPropertyObj.type
      );
      if (tempList && tempList.length) {
        tempList.forEach((singleObj) => {
          if (singleObj && singleObj.id) {
            productPropertyObjList.push(singleObj as any);
          }
        });
      }
    });
  }
  return productPropertyObjList;
};

export const getProductRelationDataInMainObj = (
  singleProductData: IProductApiData,
  includedList: IIncluded[]
): IProductApiData => {
  const tempSingleProductData = {...singleProductData};
  const relationships = tempSingleProductData?.relationships;

  if (
    relationships &&
    Object.keys(relationships) &&
    Object.keys(relationships).length
  ) {
    const relationshipKeys = Object.keys(relationships);
    updateProductRelationsData(relationshipKeys, relationships, includedList);
  }
  return tempSingleProductData;
};

const updateProductRelationsData = (
  relationshipKeys: string[],
  relationships: IProductRelationships,
  includedList: IIncluded[]
) => {
  relationshipKeys.forEach((dataKey) => {
    const relationshipsData: any =
      relationships[dataKey as keyof IProductRelationships] || [];
    if (
      relationshipsData &&
      relationshipsData?.data &&
      relationshipsData?.data?.length
    ) {
      const relationshipsDataList = relationshipsData?.data || [];
      updateRelationshipAttributes(relationshipsDataList, includedList);
    } else if (
      relationshipsData &&
      relationshipsData?.data &&
      relationshipsData?.data?.id
    ) {
      const relationshipsDataList = [relationshipsData?.data] || [];
      updateRelationshipAttributes(relationshipsDataList, includedList);
    }
  });
};

const updateRelationshipAttributes = (
  relationshipsDataList: any,
  includedList: IIncluded[]
) => {
  relationshipsDataList.forEach(
    (relationshipElement: {
      id: string;
      type: string;
      includedAttributes: any;
    }) => {
      const tempData: any = getDataListFromRelationIdAndType(
        includedList,
        relationshipElement.id,
        relationshipElement.type
      );
      if (tempData && Object.keys(tempData) && Object.keys(tempData).length) {
        relationshipElement.includedAttributes = tempData[0].attributes;
      }
    }
  );
};

export const getIntervalPriceFromResp = (
  intervalName: string,
  productDetail: IProductApiData
) => {
  const selectIntervalPrice = productDetail?.attributes?.prices?.find(
    (item: any) => {
      return item.interval === intervalName;
    }
  )?.amount;
  return selectIntervalPrice;
};

export const getTieredPrice = (priceList: IPrice[]) => {
  const tiers: any = [];
  const tiersMap = new Map();

  for (const price of priceList) {
    const type = price.metadata?.type;
    if (type && type != 'Base' && price?.tiers) {

      for (const tier of price?.tiers) {
        const getExisting = tiersMap.get(type);
        if (getExisting) {
          getExisting.push(tier);
        } else {
          const tiersObj = [tier];
          tiersMap.set(type, tiersObj);
        }
      }
    }
  }

  const maps = Array.from(tiersMap, ([name, value]) => ({name, value}));
  return maps || [];
};

export const getIntervalPrice = (intervalName: string, priceList: IPrice[]) => {
  let selectIntervalPrice: any = '';
  if (intervalName === STRIPE_INTERVAL_CODE.MONTHLY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.MONTH,
      1,
      priceList
    );
    selectIntervalPrice = {
      price: priceObject?.unit_amount_decimal || '',
      priceId: priceObject?.id || ''
    };
  } else if (intervalName === STRIPE_INTERVAL_CODE.QUARTERLY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.MONTH,
      3,
      priceList
    );
    selectIntervalPrice = {
      price: priceObject?.unit_amount_decimal || '',
      priceId: priceObject?.id || ''
    };
  } else if (intervalName === STRIPE_INTERVAL_CODE.YEARLY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.YEAR,
      1,
      priceList
    );
    selectIntervalPrice = {
      price: priceObject?.unit_amount_decimal || '',
      priceId: priceObject?.id || ''
    };
  } else if (intervalName === STRIPE_INTERVAL_CODE.DAILY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.DAY,
      1,
      priceList
    );
    selectIntervalPrice = {
      price: priceObject?.unit_amount_decimal || '',
      priceId: priceObject?.id || ''
    };
  } else if (intervalName == STRIPE_INTERVAL_CODE.ONE_TIME) {
    const priceObject = getPriceObjectByOneTime(priceList);
    selectIntervalPrice = selectIntervalPrice = {
      price: priceObject?.unit_amount_decimal || '',
      priceId: priceObject?.id || ''
    };
  }
  return selectIntervalPrice || '';
};


export const getTrailInfo = (intervalName: string, priceList: IPrice[]) => {
  let trialInfo : any;
  if (intervalName === STRIPE_INTERVAL_CODE.MONTHLY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.MONTH,
      1,
      priceList
    );
    trialInfo = priceObject?.recurring || {};
  } else if (intervalName === STRIPE_INTERVAL_CODE.QUARTERLY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.MONTH,
      3,
      priceList
    );
    trialInfo = priceObject?.recurring || {};
  } else if (intervalName === STRIPE_INTERVAL_CODE.YEARLY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.YEAR,
      1,
      priceList
    );
    trialInfo = priceObject?.recurring || {};
  } else if (intervalName === STRIPE_INTERVAL_CODE.DAILY) {
    const priceObject = getPriceObjectByIntervalAndCount(
      STRIPE_INTERVAL_CODE.DAY,
      1,
      priceList
    );
    trialInfo = priceObject?.recurring || {};
  } else if (intervalName == STRIPE_INTERVAL_CODE.ONE_TIME) {
    const priceObject = getPriceObjectByOneTime(priceList);
    trialInfo = priceObject?.recurring || {};
  }
  return trialInfo || {};
};

export const getPriceObjectByIntervalAndCount = (
  interval: string,
  count: number,
  priceList: IPrice[]
) => {
  const priceObject = priceList.find((price) => {
    return (
      price?.recurring?.interval === interval &&
      !price.tiers_mode &&
      price?.recurring?.interval_count === count
    );
  });
  return priceObject;
};

export const getPriceObjectByOneTime = (priceList: IPrice[]) => {
  const priceObject = priceList.find((price) => {
    return price?.recurring == null;
  });
  return priceObject;
};

export const getDeleteAlertHeaderAndMessageId = (type?: string) => {
  if (type) {
    switch (type) {
      case 'journeys':
        return {
          headerId: 'deleteCareJourney',
          messageId: 'deleteCareJourneyAlertMessage',
        };
      case 'products':
        return {
          headerId: 'deleteCareJourneyProduct',
          messageId: 'deleteProductAlertMessage',
        };
    }
  }
  return {};
};

export const formatJourneysData = (
  productDataList: IJourneyApiData[],
): IProductTableDisplayData[] => {
  const productDisplayList: IProductTableDisplayData[] = [];
  productDataList.forEach((productData) => {
    productDisplayList.push({
      id: productData.id,
      name: productData.title,
      description: productData.description,
      imageData: [],
      productId: productData.productId,
      status: productData.statusId,
      startType: productData.startType?.value,
      startDateTime: productData.startDateTime,
      careJourneyLocations: productData?.careJourneyLocations
    });
  });
  return productDisplayList;
};


export const getCareJourneyStatusTitleById = (
  statusId: string,
  careJourneyStautsId: ICareJourneyStatusIDs
) => {
  switch (statusId) {
    case careJourneyStautsId.draft:
      return 'Draft';
    case careJourneyStautsId.published:
      return 'Published';
    default:
      return 'N/A';
  }
};
