import {View, Text, Pressable} from "react-native";
import CustomAttributeEmptySvg from "../../common/Svg/CustomAttributeEmptySvg";
import {useIntl} from "react-intl";
import {Colors} from "../../../styles";
import PlusIcon from "../../common/Svg/PlusSvg";

export const CallExtensionNoDataView = (props: any) => {
  const intl = useIntl();
  return (
    <>
      <View>
        <CustomAttributeEmptySvg />
      </View>
      <Text style={{
        marginTop: 20,
        fontWeight: '500',
        fontSize: 16,
      }}>
        {intl.formatMessage({id: props?.title})}
      </Text>
      {props?.message && <View style={{
        marginTop: 6,
        display: 'flex',
        marginBottom: 3,
        maxWidth: 500
      }}>
        <Text style={{
          fontWeight: '400',
          fontSize: 14,
          color: Colors.Custom.Gray400,
          textAlign: 'center'
        }}>
          {intl.formatMessage({id: props?.message})}
        </Text>
      </View>}
      <Pressable
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 8,
          flexDirection: 'row'
        }}
        onPress={() => {
          props?.onCreateFirstCallExtension();
        }}
      >
        <PlusIcon/>
        <Text
          style={{
            fontWeight: '600',
            fontSize: 14,
            color: Colors.Custom.mainPrimaryPurple
          }}
        >
          {intl.formatMessage({id: props?.buttonMessage})}
        </Text>
      </Pressable>
    </>
  )
}