import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // borderBottomColor: Colors.Custom.BorderColor,
    // borderBottomWidth: 1,
    alignItems: 'center',
    paddingLeft: 8,
    // paddingBottom: 16,
  },
  tabContainer: {
    marginLeft: 5,
    paddingHorizontal: 10,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
  },
  tabText: {
    fontSize: 16,
    fontWeight: '500',
  },
});
