import {Text} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {View} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {Colors} from '../../../styles/Colors';
import CustomDrawer from '../../common/CustomDrawer/CustomDrawer';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {IViewOnCallScheduleDrawerProps} from './interface';
import ViewerScheduleBox from './ViewerScheduleBox';
import AlertBanner from '../../common/AlertBanner/AlertBanner';
import {replaceHashValueToString} from '../../../utils/commonUtils';

const ViewOnCallScheduleDrawer = (props: IViewOnCallScheduleDrawerProps) => {
  const intl = useIntl();
  const commonData = React.useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const IvrUsageTypeList = mlovData['IvrUsageType'] || [];

  const IvrUsageType = IvrUsageTypeList.find((value) => {
    return value.id === props?.selectedOnCallScheduleData?.phoneTreeTypeId;
  });
  const scheduleTypeValue = IvrUsageType?.value || '';

  const treesList =
    props?.selectedOnCallScheduleData?.onCallScheduleLinkedPhoneTree?.map(
      (tree, index) => {
        return `${index + 1}. ${tree?.flow?.friendlyName || ''}`;
      }
    );
  const treesListText = treesList?.join(`${'\n'}`) || '';
  return (
    <>
      <CustomDrawer
        title="onCallDetails"
        open={true}
        customization={{
          width: props.width,
        }}
        onClose={() => props.onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL)}
        isLoading={false}
        headerButtons={[
          <FoldButton
            customProps={{
              btnText: intl.formatMessage({id: 'edit'}),
            }}
            nativeProps={{
              onPress() {
                props.onActionPerformed(
                  COMMON_ACTION_CODES.EDIT,
                  props?.selectedOnCallScheduleData
                );
              },
              isDisabled: false,
              isLoading: false,
              backgroundColor: false
                ? Colors.FoldPixel.GRAY50
                : Colors.Custom.Primary300,
              _text: {
                fontSize: 14,
                lineHeight: 16.8,
                fontWeight: 'bold',
                color: false ? Colors.FoldPixel.GRAY150 : '#fff',
              },
            }}
          />,
        ]}
      >
        <View style={{marginBottom: 16}}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '500',
              color: Colors.FoldPixel.GRAY300,
              marginBottom: 4,
            }}
          >
            {intl.formatMessage({
              id: 'scheduleName',
            })}
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '500',
              color: Colors.FoldPixel.GRAY400,
            }}
          >
            {props?.selectedOnCallScheduleData?.scheduleName || ''}
          </Text>
        </View>

        <View>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '500',
              color: Colors.FoldPixel.GRAY300,
              marginBottom: 4,
            }}
          >
            {intl.formatMessage({
              id: 'phoneTreeType',
            })}
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '500',
              color: Colors.FoldPixel.GRAY400,
            }}
          >
            {scheduleTypeValue}
          </Text>
        </View>
        {props?.selectedOnCallScheduleData?.onCallScheduleDurations?.map(
          (scheduleDuration: any, index: number) => {
            return (
              <ViewerScheduleBox
                scheduleDuration={scheduleDuration}
                index={index}
                accountUsers={props?.accountUsers || []}
                listLength={
                  props?.selectedOnCallScheduleData?.onCallScheduleDurations
                    ?.length || 0
                }
              />
            );
          }
        )}
        {treesList?.length ? (
          <AlertBanner
            message={replaceHashValueToString(
              {treesListText: treesListText},
              intl.formatMessage({id: 'scheduleMappedToPhoneTreesListMsg'})
            )}
            visible={true}
            customContainerProps={{marginTop: '24px'}}
            customContainerStyle={{alignItems: 'flex-start', borderRadius: 8}}
            customTextStyle={{lineHeight: 14.4}}
            backgroundColor={Colors.Custom.infoBannerBgColor}
            textColor={Colors.FoldPixel.GRAY400}
            iconColor={Colors.FoldPixel.INFO_COLOR}
          />
        ) : (
          <></>
        )}
      </CustomDrawer>
    </>
  );
};

export default ViewOnCallScheduleDrawer;
