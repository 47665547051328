import React from 'react';
import {Path, Svg} from 'react-native-svg';

function CheckRoundIcon() {
  return (
    <Svg width="16" height="15" viewBox="0 0 16 15" fill="none">
      <Path d="M6.0202 7.47982C5.82494 7.28456 5.50836 7.28456 5.31309 7.47982C5.11783 7.67508 5.11783 7.99167 5.31309 8.18693L6.0202 7.47982ZM6.99998 9.16671L6.64643 9.52026C6.84169 9.71552 7.15827 9.71552 7.35353 9.52026L6.99998 9.16671ZM10.6869 6.18693C10.8821 5.99167 10.8821 5.67508 10.6869 5.47982C10.4916 5.28456 10.175 5.28456 9.97976 5.47982L10.6869 6.18693ZM14.1666 7.50004C14.1666 10.9058 11.4057 13.6667 7.99998 13.6667V14.6667C11.958 14.6667 15.1666 11.4581 15.1666 7.50004H14.1666ZM7.99998 13.6667C4.59422 13.6667 1.83331 10.9058 1.83331 7.50004H0.833313C0.833313 11.4581 4.04194 14.6667 7.99998 14.6667V13.6667ZM1.83331 7.50004C1.83331 4.09428 4.59422 1.33337 7.99998 1.33337V0.333374C4.04194 0.333374 0.833313 3.542 0.833313 7.50004H1.83331ZM7.99998 1.33337C11.4057 1.33337 14.1666 4.09428 14.1666 7.50004H15.1666C15.1666 3.542 11.958 0.333374 7.99998 0.333374V1.33337ZM5.31309 8.18693L6.64643 9.52026L7.35353 8.81315L6.0202 7.47982L5.31309 8.18693ZM7.35353 9.52026L10.6869 6.18693L9.97976 5.47982L6.64643 8.81315L7.35353 9.52026Z" 
      fill="#12B76A"
      />
    </Svg>
  );
}

export default CheckRoundIcon;