import {useLazyQuery, useQuery} from '@apollo/client';
import {Skeleton, Table} from 'antd';
import {cloneDeep} from 'lodash';
import {Box, useToast, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import {GET_USER_FOR_SCHEDULE_ACCESS} from '../../../services/User/UserQueries';
import {GetUsersScheduleAccess} from '../../../services/UserScheduleAccess/UserScheduleAccessQueries';
import {Colors} from '../../../styles';
import {filterWorkflowUser, getAccountUUID, getBooleanFeatureFlag, getUserUUID} from '../../../utils/commonUtils';
import AddEditUserScheduleAccess from './AddEditUserScheduleAccess';
import {
  IScheduleAccessUser,
  IScheduleAccessUserView,
  IScheduleUserResponse,
} from './interfaces';
import {getColumns} from './UserScheduleHelper';
import UserScheduleTopBar from './UserScheduleTopBar';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import {IUserPracticeLocation} from '../../../services/Location/interfaces';
import {usePermissions} from '../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {findArrayIntersection} from '../../../utils/arrUtils';
import FeatureFlags from '../../../constants/FeatureFlags.enums';

export interface IUserScheduleConfigurationView {
  searchString?: string;
  showTableTopBar?: boolean;
}

const UserScheduleConfigurationView = (
  props: IUserScheduleConfigurationView
) => {
  const toast = useToast();
  const accountUUID = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const intl = useIntl();
  const [accountUsers, setAccountUsers] = useState<IScheduleAccessUser[]>([]);
  const userScheduleAccessMap = new Map<string, IScheduleUserResponse[]>();
  const [accountUserQueryLoading, setUserQueryLoading] = useState(true);
  const mlovData = useContext(CommonDataContext);
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.SCHEDULE);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const [userScheduleState, setUserScheduleState] = useState<{
    searchString: string;
    loading: boolean;
    users: IScheduleAccessUserView[];
    originalUserList: IScheduleAccessUserView[];
    selectedItem: IScheduleAccessUserView;
    showDrawer: boolean;
    userScheduleAccessMap: Map<string, IScheduleUserResponse[]>;
    isRefetch: boolean;
  }>({
    searchString: '',
    loading: true,
    users: [],
    originalUserList: [],
    isRefetch: false,
    selectedItem: {} as IScheduleAccessUserView,
    showDrawer: false,
    userScheduleAccessMap: new Map<string, IScheduleUserResponse[]>(),
  });

  useEffect(() => {
    onSearch(props.searchString || '');
  }, [props.searchString]);

  // TODO - remove or used later

  const actionFn = (
    actionCode: string,
    record: IScheduleAccessUserView
  ): any => {
    setUserScheduleState((prev) => {
      return {
        ...prev,
        selectedItem: record,
        showDrawer: true,
      };
    });
  };

  const onAddEditPopClose = (isRefetch: boolean) => {
    setUserScheduleState((prev) => {
      return {
        ...prev,
        selectedItem: {} as IScheduleAccessUserView,
        showDrawer: false,
        isRefetch,
      };
    });
  };

  const formatUserScheduleAccessResponse = (
    userScheduleAccess: IScheduleUserResponse[]
  ): IScheduleAccessUserView[] => {
    const scheduleAccessUserList: IScheduleAccessUserView[] = [];
    (accountUsers || []).find((user: IScheduleAccessUser) => {
      const userId = user?.userId;
      const userScheduleAccessUser: IScheduleAccessUser =
        getUserByUserId(userId);
      const scheduleAccessUsers: IScheduleUserResponse[] =
        getUserScheduleAccessUsers(userScheduleAccess, userId);

      userScheduleAccessMap.set(userId, scheduleAccessUsers);
      if (loggedInUserId === userId) {
        scheduleAccessUserList.unshift(
          formatUserResponseForAccessView(
            scheduleAccessUsers,
            userScheduleAccessUser
          )
        );
      } else {
        scheduleAccessUserList.push(
          formatUserResponseForAccessView(
            scheduleAccessUsers,
            userScheduleAccessUser
          )
        );
      }
    });
    return scheduleAccessUserList;
  };

  const formatUserResponseForAccessView = (
    scheduleAccessUsers: IScheduleUserResponse[],
    userScheduleAccessUser: IScheduleAccessUser
  ) => {
    const userList: IScheduleAccessUser[] = [];
    let isUserExist = false;
    (scheduleAccessUsers || []).forEach((user: IScheduleUserResponse) => {
      const userDetail = getUserByUserId(user.scheduleAccessUserId);
      if (user.scheduleAccessUserId === userScheduleAccessUser.userId) {
        isUserExist = true;
      }
      if (userDetail && !user.isDeleted) {
        userList.push({
          userId: userDetail?.userId,
          email: userDetail?.email,
          userName: userDetail?.userName,
          userRoles: userDetail?.userRoles,
        });
      }
    });
    if (!isUserExist) {
      userList.push(userScheduleAccessUser);
    }
    return {
      userId: userScheduleAccessUser.userId,
      userName: userScheduleAccessUser.userName,
      email: userScheduleAccessUser.email,
      scheduleUsers: userList,
    };
  };

  const getUserScheduleAccessUsers = (
    userScheduleAccess: IScheduleUserResponse[],
    userId: string
  ) => {
    return (userScheduleAccess || []).filter((user: IScheduleUserResponse) => {
      return user.userId === userId;
    });
  };

  const getUserByUserId = (userId: string) => {
    return (
      (accountUsers || []).find((user) => {
        return user.userId === userId;
      }) || ({} as IScheduleAccessUser)
    );
  };

  const fetchScheduleAccessUserForDrawer = () => {
    const selectedUserId = userScheduleState?.selectedItem?.userId;
    return (accountUsers || []).filter((user) => {
      return user.userId !== selectedUserId;
    });
  };

  const fetchUserIdsForDrawer = () => {
    const selectedUserId = userScheduleState?.selectedItem?.userId;
    return (userScheduleState?.selectedItem?.scheduleUsers || [])
      .filter((user) => {
        return user.userId && user.userId !== selectedUserId;
      })
      .map((user) => {
        return user.userId;
      });
  };

  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const accountUsers: IScheduleAccessUser[] = [];
      if (data && data?.users && data?.users.length) {
        data.users = filterWorkflowUser(data.users, loggedInUserId);
        (data.users || []).forEach((user: any) => {
          const userObject = {
            userName: user.name,
            userId: user.uuid,
            email: user.email || '',
            userRoles: user.userRoles?.filter((role: any) => role.userRole) || user.userRoles,
          };
        
          const userLocations = user.userPracticeLocations?.map((location: IUserPracticeLocation) => location.accountLocation?.uuid);
          const hasAccess = !isMultiTenancyEnabled || findArrayIntersection(currentUserAllowedLocations, userLocations)?.length > 0;
        
          if (hasAccess) {
            accountUsers.push(userObject);
          }
        });
      }
      setAccountUsers(accountUsers);
      setUserQueryLoading(false);
    },
    onError: (error) => {

      setUserQueryLoading(false);
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.info,
      );
    },
  });

  const getUserScheduleAccess = useQuery(GetUsersScheduleAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    skip: accountUserQueryLoading,
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const userList: IScheduleAccessUserView[] =
        formatUserScheduleAccessResponse(data?.userScheduleAccesses);
      setUserScheduleState((prev) => {
        return {
          ...prev,
          loading: false,
          users: userList,
          originalUserList: cloneDeep(userList),
          isRefetch: false,
          userScheduleAccessMap,
        };
      });
    },
    onError: (error) => {

      setUserScheduleState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.info,
      );
    },
  });

  const fetchUserList = (userName: string): IScheduleAccessUserView[] => {
    return (userScheduleState.originalUserList || []).filter(
      (user: IScheduleAccessUserView) => {
        return user.userName.toLowerCase().includes(userName.toLowerCase());
      }
    );
  };

  useEffect(() => {
    getAccountUsers({
      variables: {
        accountUUID: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
    if (userScheduleState.isRefetch) {
      setUserScheduleState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
    }
    getUserScheduleAccess.refetch();
  }, [userScheduleState.isRefetch]);

  const onSearch = (text: string) => {
    if (text.trim().length) {
      const userList: IScheduleAccessUserView[] =
        fetchUserList(text.trim()) || [];
      setUserScheduleState((prev) => {
        return {
          ...prev,
          users: userList,
        };
      });
    } else {
      setUserScheduleState((prev) => {
        return {
          ...prev,
          users: userScheduleState.originalUserList,
        };
      });
    }
  };
  const {height} = Dimensions.get('window');
  return (
    <View>
      <Box
        bgColor={'white'}
        overflow={'hidden'}
      >
        {props.showTableTopBar && (
          <UserScheduleTopBar
            onSearch={(text: string) => {
              onSearch(text);
            }}
          />
        )}
        {!getUserScheduleAccess.loading && !userScheduleState.loading && !accountUserQueryLoading && (
          <Table
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            rowKey={(row: any) => row.id || ''}
            columns={getColumns(actionFn)}
            dataSource={userScheduleState.users || []}
            pagination={false}
            scroll={{x: 800,y: height - 184}}
            onRow={(data) => ({
              onClick: () => {
                setUserScheduleState((prev) => {
                  return {
                    ...prev,
                    selectedItem: data,
                    showDrawer: true,
                  };
                });
              },
            })}
          />
        )}
        {(getUserScheduleAccess.loading || accountUserQueryLoading || userScheduleState.loading) && (
          <View margin={4}>
            <Skeleton active />
          </View>
        )}
      </Box>
      {userScheduleState.showDrawer && (
        <AddEditUserScheduleAccess
          userId={userScheduleState.selectedItem?.userId}
          userName={userScheduleState.selectedItem?.userName}
          email={userScheduleState.selectedItem?.email}
          scheduleUsers={fetchScheduleAccessUserForDrawer()}
          userIds={fetchUserIdsForDrawer()}
          showDrawer={userScheduleState.showDrawer}
          onClose={onAddEditPopClose}
          userScheduleAccessList={
            userScheduleState.userScheduleAccessMap.get(
              userScheduleState.selectedItem?.userId
            ) || []
          }
        />
      )}
    </View>
  );
};

export default UserScheduleConfigurationView;
