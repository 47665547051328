import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import useGetBatchedAccountUsers, { IBatchedAccountUsersInterface } from '../../../CustomHooks/useGetBatchedAccountUsers';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { GET_ALL_USERS_WITH_ROLES, GET_ALL_USER_COUNT_FOR_SPECIFIC_ROLES } from '../../../../services/User/UserQueries';
import { useToast } from 'native-base';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { GET_BILLABLE_ACTIVITIES, GET_BILLABLE_ATTRIBUTES_AND_CODES, GET_BILL_ACTIVITIES_SUM, GET_CPT_CODES, PROCESS_BILLING } from '../BillingQueries';
import { IBillingTab, BILLING_HOOK, IChronicCondition, IParamsForBillableActivites, IBillingSelectedFilters, IHookState, IBillingData, IHandleTableActions, IGetBillableActivities, IContactCareProgramAttributesAndCodes } from './BillingHookInterfaces';
import { ALL_CHRONIC_CONDITIONS, ALL_PROGRAM_TYPE, ALL_SELECTED, BILLING_ACTIVITIES_PAGE_LIMIT, BILLING_FILTER_ACTION_CODES, } from './BillingHooksConstants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { IFormCommonData } from '../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import MlovQueries from '../../../../services/Mlov/MlovQueries';
import { BILLABLE_ACTIVITY_STATUS_STATUS_CODES, BILLING_ACTIVITY_STATUS, MLOV_CATEGORY } from '../../../../constants/MlovConst';
import { useIntl } from 'react-intl';
import { IMlov } from '../../../../Interfaces';
import { getEndOfDay, getStartOfMonthFromPastMonths } from '../../../../utils/DateUtils';
import { GET_ALL_CONTACTS_BY_PATIENT_UUIDS } from '../../../../services/Contacts/ContactsQueries';
import { getPatientChronicConditions } from '../../../../services/CommonService/AidBoxService';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { getRecordListFormatted } from '../../Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import LeadQueries from '../../../../services/Lead/LeadQueries';
import { getContactFiltersObject } from '../../Contacts/ContactsUtils';
import { getAccountId, getUserEmployerId } from '../../../../utils/commonUtils';


const UseBillingTab = (params: IBillingTab) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const initialSelectedFilters = () => {
    return {
      programTypes: [ALL_PROGRAM_TYPE],
      cptCodes: [ALL_SELECTED.value],
      providers: [],
      chronicConditions: [ALL_CHRONIC_CONDITIONS],
      dateOfService: {
        startDate: params?.serviceStartDateTime || getStartOfMonthFromPastMonths(3)?.toISOString(),
        endDate: params?.serviceStartDateTime || getEndOfDay()?.toISOString(),
      }
    }
  }
  const intl = useIntl();
  const billableActivityStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];
  const careProgramBillableActivityStatusId = getMlovIdFromCode(
    billableActivityStatusMlov,
    params?.isBilledView ? BILLING_ACTIVITY_STATUS.COMPLETED : BILLING_ACTIVITY_STATUS.PENDING
  )
  const accountId = getAccountId();
  const employerId = getUserEmployerId();
  const [selectedFilters, setSelectedFilters] = useState<IBillingSelectedFilters>(initialSelectedFilters());
  const [hookState, setHookState] = useState<IHookState>({
    cptCodes: [],
    billingData: [],
    totalAmountSum: 0,
  })

  const [paginationState, setPaginationState] = useState({
    paginationLoading: false,
    limit: BILLING_ACTIVITIES_PAGE_LIMIT,
    offset: 0,
    pageNo: 1,
    total: 0,
  });
  const [loadersState, setLoadersState] = useState({
    dataProcessing: true
  })
  const toast = useToast();

  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
  } = useGetBatchedAccountUsers({
    onError: () => {
      showToast(
        toast,
        'Error in fetching account users',
        ToastType.error,
        4000
      );
    },
    usersCountQueryName: GET_ALL_USER_COUNT_FOR_SPECIFIC_ROLES,
    usersQueryName: GET_ALL_USERS_WITH_ROLES,
  });

  const [getContactDetails, { loading: contactDetailsLoading }] = useLazyQuery<{ contacts: IBillingData['contactData'][] }>(
    GET_ALL_CONTACTS_BY_PATIENT_UUIDS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [processBilling, { loading: loadingProcessBilling }] = useMutation(
    PROCESS_BILLING,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      variables: {
        params: {
          contactCareProgramIds: hookState.selectedBills?.map((bill) => bill.id),
        }
      },
    },
  );

  const [getCptCodes, { loading: cptCodesLoading }] = useLazyQuery<{ careProgramCptCodes: { cptCode: string }[] }>(GET_CPT_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setHookState((prev) => {
        return {
          ...prev,
          cptCodes: data.careProgramCptCodes?.map((codes) => codes.cptCode),
        }
      })
    }
  })

  const [getChronicConditions, { loading: chronicConditionsLoading }] = useLazyQuery(GET_CPT_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  })

  const [getBillableActivities, { loading: getBilledActivitiesByMonthLoading }] = useLazyQuery<IGetBillableActivities>(GET_BILLABLE_ACTIVITIES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onError: () => showToast(
      toast,
      intl.formatMessage({ id: 'apiErrorBillableActvities' }),
      ToastType.error,
      4000
    )
  })

  const [getBillActivitiesAmountSum, { loading: getBillActivitiesAmountSumLoading }] = useLazyQuery(GET_BILL_ACTIVITIES_SUM, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onError: () => showToast(
      toast,
      intl.formatMessage({ id: 'apiErrorInFetchingAmountSum' }),
      ToastType.error,
      4000
    )
  })


  const [getBillAttributeAndCodes, { loading: attributeAndCodesLoading }] = useLazyQuery<{ contactCareProgramBillableActivities: IContactCareProgramAttributesAndCodes[] }>(GET_BILLABLE_ATTRIBUTES_AND_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setHookState((prev) => {
        return {
          ...prev,
          attributesAndCodes: data?.contactCareProgramBillableActivities,
        }
      })
    },
    onError: (err: any) => {
      showToast(
        toast,
        intl.formatMessage({ id: 'apiErrorAttributesAndCodes' }),
        ToastType.error,
        4000
      )
    }
  })


  const getBillableActivityStatusIds = () => {
    return [billableActivityStatusMlov.find((mlov) => mlov.code === (params?.isBilledView ? BILLABLE_ACTIVITY_STATUS_STATUS_CODES.COMPLETED : BILLABLE_ACTIVITY_STATUS_STATUS_CODES.PENDING))?.id || '']
  }

  const getParamsForBillableActivitiesData = (args?: {
    limit?: number;
    offset?: number;
    programType?: IMlov[];
    cptCode?: string[];
    provider?: IBatchedAccountUsersInterface[],
    chronicCondition?: IChronicCondition[],
    dateOfService?: { startDate: string; endDate: string };
    members?: {
      value: string | undefined;
      label: string;
      key: string;
      contactData: any;
    }[]
  }): IParamsForBillableActivites => {
    const selectedFilterInState = selectedFilters;
    const programTypeForParams = (args?.programType || selectedFilterInState.programTypes).map((type) => type.id).filter((item) => item !== BILLING_HOOK.ALL);
    const providerForParams = (args?.provider || selectedFilterInState.providers).map((provider) => provider.uuid).filter((item) => item !== BILLING_HOOK.ALL);
    const memberForParams = (args?.members || selectedFilterInState?.members)?.map((member) => member.key) || [];
    const cptCodes = (args?.cptCode || selectedFilterInState.cptCodes).filter((code) => code !== ALL_SELECTED.value);
    return {
      offset: args?.offset ?? paginationState.offset,
      limit: args?.limit ?? paginationState.limit,
      billableActivityStatusIds: getBillableActivityStatusIds(),
      careProgramTypeIds: programTypeForParams || [],
      cptCodes: cptCodes,
      primaryCarePhysicianUserIds: providerForParams,
      serviceStartDateTime: params?.serviceStartDateTime || (args?.dateOfService || selectedFilterInState.dateOfService).startDate,
      serviceEndDateTime: params?.serviceEndDateTime || (args?.dateOfService || selectedFilterInState.dateOfService).endDate,
      contactIds: memberForParams,
    }
  }


  const handleActions = async (actionCode: string, actionData?: any) => {
    let params;
    let updatedActionData: any;
    let resetSelectedBills = true
    switch (actionCode) {
      case BILLING_FILTER_ACTION_CODES.PROGRAM_TYPE:
        updatedActionData = actionData?.length > 0 ? actionData : [ALL_PROGRAM_TYPE];
        setSelectedFilters((prev) => {
          return {
            ...prev,
            programTypes: updatedActionData,
          }
        })
        params = getParamsForBillableActivitiesData({ programType: updatedActionData, offset: 0 });
        break;
      case BILLING_FILTER_ACTION_CODES.CPT_CODE:
        updatedActionData = actionData?.length > 0 ? actionData : [ALL_SELECTED.value];;
        setSelectedFilters((prev) => {
          return {
            ...prev,
            cptCodes: updatedActionData,
          }
        })
        params = getParamsForBillableActivitiesData({ cptCode: updatedActionData, offset: 0 });
        break;
      case BILLING_FILTER_ACTION_CODES.PROVIDER:
        setSelectedFilters((prev) => {
          return {
            ...prev,
            providers: actionData,
          }
        })
        params = getParamsForBillableActivitiesData({ provider: actionData, offset: 0 });
        break;
      case BILLING_FILTER_ACTION_CODES.CHRONIC_CONDITION:
        updatedActionData = actionData?.length > 0 ? actionData : [ALL_CHRONIC_CONDITIONS];
        setSelectedFilters((prev) => {
          return {
            ...prev,
            chronicConditions: updatedActionData,
          }
        })
        params = getParamsForBillableActivitiesData({ chronicCondition: updatedActionData, offset: 0 });
        break;
      case BILLING_FILTER_ACTION_CODES.DATE_FILTER:
        setSelectedFilters((prev) => {
          return {
            ...prev,
            dateOfService: actionData,
          }
        })
        params = getParamsForBillableActivitiesData({ dateOfService: actionData, offset: 0 });
        break;
      case BILLING_FILTER_ACTION_CODES.PAGINATION:
        const pageNumber = actionData.page;
        const pageLimit = actionData.pageSize;
        const offset = pageNumber * pageLimit - pageLimit;
        setPaginationState((prev) => {
          return {
            ...prev,
            offset: offset,
            pageNo: actionData.page,
            limit: pageLimit,
          }
        })
        params = getParamsForBillableActivitiesData({ offset: offset, limit: pageLimit });
        resetSelectedBills = false;
        break;
      case BILLING_FILTER_ACTION_CODES.REFRESH:
        params = getParamsForBillableActivitiesData({ offset: 0 });

        break;
      case BILLING_FILTER_ACTION_CODES.MEMBER:
        setSelectedFilters((prev) => {
          return {
            ...prev,
            members: actionData,
          }
        })
        params = getParamsForBillableActivitiesData({ offset: 0, members: actionData});
    }
    if (resetSelectedBills) {
      setHookState((prev) => {
        return {
          ...prev,
          selectedBills: [],
        }
      })
    }
    if(actionCode !== BILLING_FILTER_ACTION_CODES.PAGINATION) {
      setPaginationState((prev) => ({
        ...prev,
        offset: 0,
        pageNo: 1,
      }));
    }
    getBillableActivitiesData(params)
  }

  const handleTableActions = async (params: IHandleTableActions) => {
    const { actionCode, actionData, args } = params;
    switch (actionCode) {
      case COMMON_ACTION_CODES.ITEM_SELECT:
        setHookState((prev) => {
          const previousSelectedBills = prev.selectedBills || [];
          const index = previousSelectedBills?.findIndex((item) => item.id === actionData?.id)
          if (args?.isSelected) {
            if (index === -1) {
              previousSelectedBills.push(actionData)
            }
          } else {
            previousSelectedBills.splice(index, 1);
          }
          return {
            ...prev,
            selectedBills: previousSelectedBills,
          }
        })
        break;
      case COMMON_ACTION_CODES.SELECT_ALL:
        setHookState((prev) => {
          return {
            ...prev,
            selectedBills: args?.isSelected ? actionData : [],
          }
        })
        break;
      case COMMON_ACTION_CODES.DETAIL_VIEW:
        setHookState((prev) => {
          return {
            ...prev,
            selectedItemForDetailView: actionData,
          }
        })
        await getBillAttributeAndCodes({
          variables: {
            where: {
              statusId: {
                _eq: careProgramBillableActivityStatusId
              },
              contactCareProgramId: {
                _eq: actionData.id
              }
            }
          }
        })
        break;
      case COMMON_ACTION_CODES.CLOSE:
        setHookState((prev) => {
          return {
            ...prev,
            selectedItemForDetailView: undefined,
            selectedBillDetailedViewData: undefined,
          }
        })
    }
  }

  const getAdditionalDetailsForBillingData = async (billableActivitiesResponse: IGetBillableActivities['getBillableActivities']) => {
    setLoadersState((prev) => {
      return {
        ...prev,
        dataProcessing: true,
      }
    })
    const contactIds = billableActivitiesResponse?.records.map((item) => item.contactId);
    const billableActivitiesWithContactData: IBillingData[] = [];
    const contactDataMappings: { [key: string]: IBillingData['contactData'] } = {};
    if (contactIds.length > 0) {
      const response = await getContactDetails({
        variables: {
          contactUuidList: contactIds,
        },
      });
      const patientIdsAndChronicConditionMap: { [key: string]: any } = {};
      const patientIdsAndLocations: { patientId: string, location?: string }[] = []
      response?.data?.contacts?.forEach((contact: IBillingData['contactData']) => {
        contactDataMappings[contact.uuid] = contact;
        patientIdsAndLocations.push({
          patientId: contact?.patient?.patientId?.toString(),
          ...(!!contact.contactPracticeLocations[0]?.accountLocation?.uuid && { location: contact?.contactPracticeLocations[0]?.accountLocation?.uuid })
        })
      });
      const chronicResponse = await getPatientChronicConditions(patientIdsAndLocations, true);
      if (chronicResponse?.data?.resources) {
        chronicResponse?.data?.resources?.forEach((resource: any) => {
          patientIdsAndChronicConditionMap[resource.patientId] =  getRecordListFormatted(resource.conditions?.entry || []);
        })
      }
      billableActivitiesResponse?.records?.forEach((item) => {
        const patientId = contactDataMappings[item.contactId]?.patient?.patientId;

          const chronicConditionWithPatientId = patientIdsAndChronicConditionMap[patientId];
          const performerDetails = accountUserList.find((user) => user.uuid === item.primaryCarePhysicianUserId) as IBatchedAccountUsersInterface;
          billableActivitiesWithContactData.push({
            ...item,
            contactData: contactDataMappings[item.contactId],
            primaryCarePhysicianUserId: item.primaryCarePhysicianUserId,
            providerUserDetails: performerDetails,
            chronicConditionWithPatientId: chronicConditionWithPatientId
          })
      })
    }
    setHookState((prev) => {
      return {
        ...prev,
        billingData: billableActivitiesWithContactData,
      }
    })
    setLoadersState((prev) => {
      return {
        ...prev,
        dataProcessing: false,
      }
    })
  }

  const getBillableActivitiesData = async (params?: IParamsForBillableActivites) => {
    const paramsForQuery = params || getParamsForBillableActivitiesData();
    const { offset, limit, ...paramsWithoutOffsetLimit } = paramsForQuery;
    const promiseList = [
      getBillableActivities({
        variables: {
          params: paramsForQuery
        }
      }),
      getBillActivitiesAmountSum({
        variables: {
          params: paramsWithoutOffsetLimit,
        }
      })
    ]
    const apiResponses = await Promise.all(promiseList);
    setHookState((prev) => {
      return {
        ...prev,
        totalAmountSum: apiResponses?.[1]?.data?.getBillableActivitiesAmountSum.totalAmount
      }
    })
    if (apiResponses?.[0]?.data?.getBillableActivities) {
      setPaginationState((prev) => {
        return {
          ...prev,
          total: apiResponses?.[0]?.data?.getBillableActivities?.totalRecords || 0,
        }
      })
      getAdditionalDetailsForBillingData(apiResponses?.[0]?.data?.getBillableActivities)
    }
  }

  const [getContacts, {loading: contactsLoading}] = useLazyQuery(
    LeadQueries.GetContacts,
    {
      onCompleted: (data: any) => {
        let contactsData: any = [];
        if (data && data.contacts && data.contacts.length > 0) {
          contactsData = data.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
              };
            });
        }
        setHookState((prev) => {
          return {
            ...prev,
            customerData: contactsData
          }
        })
      },
      onError: (error) => {
        setHookState((prev) => {
          return {
            ...prev,
            customerData: []
          }
        })
      },
    }
  );


  const onChangeCustomer = (searchText: string) => {
    if (searchText) onSearchCustomer(searchText);
  };
  const filter: any = {
    _or: [],
    contactTagging: {},
    contactInboxes: {},
  };

  const onSearchCustomer = async (searchText: string) => {
    try {
      await getContacts({
        variables: {
          limit: 10,
          offset: 0,
          searchString: '%' + searchText + '%',
          order_by: {
            updatedAt: 'desc',
          },
          contact_filters: getContactFiltersObject(
            filter,
            searchText,
            accountId,
            [],
            [],
            '',
            [] as any,
            '',
            '',
            employerId
          ),
        },
      });
    } catch (e) {
    }
  };

  const getDataForFilters = () => {
    const promiseList = [
      getCptCodes(),
      getChronicConditions()
    ]
    Promise.all((promiseList)).catch((err) =>
      showToast(
        toast,
        intl.formatMessage({ id: 'apiErrorMsg' }),
        ToastType.error,
        4000
      ))
  }

  useEffect(() => {
    if (accountUserList?.length > 0) {
      getDataForFilters();
      getBillableActivitiesData();
    }
  }, [accountUserList])

  const activitiesDataLoading = loadersState.dataProcessing || accountUserLoading || contactDetailsLoading || cptCodesLoading || chronicConditionsLoading || getBilledActivitiesByMonthLoading || getBillActivitiesAmountSumLoading;

  return {
    selectedFilters: selectedFilters,
    paginationState,
    handleActions,
    handleTableActions,
    hookState,
    accountUserList,
    activitiesDataLoading,
    attributeAndCodesLoading,
    loadingProcessBilling,
    onChangeCustomer,
    contactsLoading,
  }
}

export default UseBillingTab;
