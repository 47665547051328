import {Button, Spinner, Tooltip} from 'native-base';
import {getOnFocusButtonStyles} from '../../../utils/commonUtils';
import {CSVLink} from 'react-csv';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles/Colors';
import {IRuleContact} from '../../../Interfaces/CommonInterfaces';
import {useRef, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GET_CONTACTS_BY_RULE_ID} from '../../../services/Lead/LeadQueries';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useToast } from '../../Toast/ToastProvider';

export const RuleContactsDownload = (props: {
  ruleId: string;
  popGroupName: string;
  contactsCount: number;
}) => {
  const {ruleId, popGroupName, contactsCount} = props;
  const [ruleContactState, setRuleContactState] = useState<{
    contacts: IRuleContact[];
    isLoading: boolean;
    isCsvDataAvailable: boolean;
    csvHeaders: string[];
    csvData: any[];
  }>({
    contacts: [],
    isLoading: false,
    isCsvDataAvailable: false,
    csvHeaders: [],
    csvData: [],
  });
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement>(null);
  const toast = useToast();

  const [fetchContactsByRuleId] = useLazyQuery(GET_CONTACTS_BY_RULE_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      params: {
        ruleId: ruleId,
        limit: contactsCount + 1000, // add 1000 limit, if contact added in background
      }
    }
  });

  const getColumnNames = () => {
    return ['ehrId', 'firstName', 'middleName', 'lastName', 'email', 'phone', 'birthDate', 'gender', 'location', 'address', 'zipcode'];
  };

  const formatContactResponse = (contacts: IRuleContact[]) => {
    return contacts.map((contact: IRuleContact) => {
      let address = '';
      let zipCode = '';
      if (contact?.personAddress?.length > 0) {
        const personAddress = contact?.personAddress[0];
        if (personAddress?.line1) {
          address = personAddress?.line1;
        }
        if (personAddress?.line2) {
          address = address + ', ' + personAddress?.line2;
        }
        if (personAddress?.cities?.name) {
          address = address + ', ' + personAddress?.cities?.name;
        }
        if (personAddress?.zipcodes?.code) {
          address = address + ', ' + personAddress?.zipcodes?.code;
          zipCode = personAddress?.zipcodes?.code;
        }
        if (personAddress?.states?.name) {
          address = address + ', ' + personAddress?.states?.name;
        }
      }
      return {
        ehrId: contact?.patient?.patientId,
        firstName: contact.person?.firstName,
        middleName: contact.person?.middleName,
        lastName: contact.person?.lastName,
        email: contact?.email,
        phone: contact?.phoneNumber,
        birthDate: contact?.person?.birthDate,
        gender: contact?.person?.gender?.value,
        location: contact?.contactPracticeLocations?.[0]?.practiceLocation?.name || '',
        address: address,
        zipcode: zipCode
      };
    });
  };

  const getContactsByRuleId = async () => {
    setRuleContactState(prev => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const contactResponseData = await fetchContactsByRuleId();
      const patientIds: string[] = [];
      const filterContacts: IRuleContact[] = [];
      const contacts: IRuleContact[] = contactResponseData?.data.searchContacts.contacts || [];
      (contacts || []).forEach((contact: IRuleContact) => {
        const patientId = contact?.patient?.patientId;
        // filter duplicate contacts
        if (!patientId || !patientIds.includes(patientId)) {
          patientIds.push(patientId);
          filterContacts.push(contact);
        }
      });
      const formatContacts = formatContactResponse(filterContacts);
      if (formatContacts.length > 0) {
        setRuleContactState((prev: any) => {
          return {
            ...prev,
            contacts: formatContacts,
            isLoading: false,
            csvHeaders: getColumnNames(),
            csvData: formatContacts,
          };
        });
        const timeoutId = setTimeout(() => {
          setRuleContactState((prev: any) => {
            return {
              ...prev,
              isCsvDataAvailable: true,
            };
          });
        }, 500);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        setRuleContactState(prev => ({
          ...prev,
          isLoading: false,
          isCsvDataAvailable: false,
          csvData: [],
          csvHeaders: [],
        }));
        showToast(toast, `No member found for ${props.popGroupName} pop group`, ToastType.info, undefined, true);
      }
    } catch (error) {
      setRuleContactState(prev => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleDownload = () => {
    // programmatically click on the csv link
    if (
      (csvLinkRef?.current as any)?.link &&
      typeof (csvLinkRef?.current as any)?.link.click === 'function'
    ) {
      (csvLinkRef?.current as any)?.link.click();
      setRuleContactState((prev: any) => {
        return {
          ...prev,
          isLoading: false,
          isCsvDataAvailable: false,
        };
      });
    }
  };

  const isAllowToShowDownloadButton =
    !ruleContactState.isLoading && !ruleContactState.isCsvDataAvailable ? true : false;

  return (
    <Tooltip label="Export Member List" placement="top">
      <Button
        style={{
          backgroundColor: 'transparent',
          marginBottom: '5px',
        }}
        _focus={{
          ...getOnFocusButtonStyles(),
        }}
        disabled={ruleContactState.isLoading || contactsCount === 0}
        onPress={() => {
          if (!ruleContactState.csvData?.length && !ruleContactState.isLoading) {
            getContactsByRuleId();
          }
        }}>
        {ruleContactState.isLoading && <Spinner />}
        {isAllowToShowDownloadButton && (
          <FeatherIcon
            color={(ruleContactState.isLoading || contactsCount === 0) ? Colors.Custom.Gray300 : Colors.Custom.Gray600}
            name="download"
            size={17}
          />
        )}
        {ruleContactState.isCsvDataAvailable && handleDownload()}
        <CSVLink
          filename={`${popGroupName}.csv`}
          data={ruleContactState?.csvData}
          headers={ruleContactState?.csvHeaders}
          ref={csvLinkRef}
          style={{display: 'none'}}
        />
      </Button>
    </Tooltip>
  );
};
