import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {Pressable} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {COUNTRY_CODE} from '../../../constants';
// import {ISearchFieldProps} from '../../../Interfaces';
import {
  UserQueries,
} from '../../../services';
import {getAccountId} from '../../../utils/commonUtils';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import UserInfoListItem from './UserInfoListItem';
import {IExtensionList} from '../../RightSideContainer/CallConfiguration/interface';
import {EXTENSION_TYPE} from './PhoneCallingConstant';
import {ExtensionInfoListItem} from './ExtensionInfoListItem';
// import {IVirtualNumberSearch} from './interface';

export interface ISearchFieldProps {
  value?: any;
  userInputFieldList?: any;
  defaultTemplateCategory?: string;
  isHideDefaultInput?: boolean;
  isHideTemplateCategory?: boolean;
  isShowError: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (value: any) => void;
  isReturnCategory?: boolean;
  allowUserMultiSelect?: boolean;
  userList?: any;
  extensionList?: IExtensionList[];
}

const UserSearchSelect = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [componentState, setComponentState] = useState({
    virtualPhoneData: props.userList,
    loading: true,
    newPhoneNumber: ''
  });
  
  const inputSelectRef = useRef<any>(null);

  useEffect(() => {
    // getNumberList();
  }, []);

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });

  // const getNumberList = async () => {
  //   const userDataList = await getUsersData();
  //   let users = userDataList.data?.users || [];
  //   const excludeList = props.excludeList || [];
  //   users = users.filter((user) => {
  //     return excludeList.includes(user.uuid) ? false : true;
  //   })
  //   // const updatedUserList = [];
  //   // users.forEach(user => {
  //   //   if ([].includes('a')) {

  //   //   }
  //   //   updatedUserList.push(user);
  //   // });
  //   setComponentState((prev: any) => {
  //     return {
  //       ...prev,
  //       virtualPhoneData: users,
  //     };
  //   });
  // };

  const getDataFromId = (uuid: any) => {
    const matchedData = componentState.virtualPhoneData.filter((item: any) => {
      return item.uuid === uuid;
    });
    if (matchedData.length > 0) {
      const virtualPhoneObj = matchedData[0];
      return virtualPhoneObj;
    }
  };

  const getExtensionDataFromId = (id: string) => {
    const matchedData = props?.extensionList?.filter((extension: IExtensionList) => {
      return extension?.id === id;
    }) || [];
    if (matchedData?.length > 0) {
      const extensionData = matchedData?.[0];
      return extensionData;
    }
  }

  return (
      <Select
        // dropdownMatchSelectWidth={10}
        ref={inputSelectRef}
        size="large"
        showSearch={true}
        autoClearSearchValue={false}
        filterOption={(input: string, option: any) => {
          const keyMatch = (option.key as unknown as string)
            .split('/')?.[0]
            .toLowerCase()
            .includes(input.toLowerCase());
          const extensionCodeMatch = option.extensionCode
            ? option.extensionCode.toString().toLowerCase().includes(input.toLowerCase())
            : false;
        return keyMatch || extensionCodeMatch;
        }}
        onSearch={(value)=> {
          const numberTyped = value.replace(/[()-\s]/g, '').trim()
          const isValidNumber =  /^\d+$/.test(numberTyped);
          const isValueEqualToTen =  numberTyped?.length === 10 ? true :  false;
          setComponentState(prev=> {
            return {
              ...prev,
              newPhoneNumber:  isValueEqualToTen && isValidNumber ? numberTyped : ''
            }
          })
        }}
        onFocus={()=>{
          props?.onFocus && props?.onFocus();
        }}
        onBlur={()=>{
          props?.onBlur && props?.onBlur();
        }}        
        onChange={(value: any[], data: any) => {
          if (data && data.value) {
            if (data.type === EXTENSION_TYPE) {
              onChange(getExtensionDataFromId(data.value));
            } else {
              onChange(getDataFromId(data.value));
            }
          } else {
            onChange(undefined);
          }
        }}
        onSelect={()=>{
          if(inputSelectRef.current) {
            inputSelectRef.current.blur();
          }
        }}
        value={[]}
        defaultValue={[]}

        placeholder="Search/Type to transfer call"
        // loading={componentState.loading}
      notFoundContent={componentState?.newPhoneNumber?.length ?
      <Pressable  onPress={()=> {
        const realNumber = COUNTRY_CODE.US_COUNTRY_CODE + componentState?.newPhoneNumber
        const data = {
          name: realNumber,
          uuid: realNumber,
          newNumber: realNumber
        }
        onChange(data)
      }}>
      <UserInfoListItem

        user={{name: componentState.newPhoneNumber, uuid: componentState.newPhoneNumber}}
      ></UserInfoListItem> </Pressable> : <></>}
        style={{height: '36px', width: '100%'}}
        className={isShowError && !value ? 'field-error' : ''}
      >
        <Select.OptGroup label="Users">
          {props.userList?.map((virtualNumberObject: any) => {
            return (
              <Select.Option

                key={`${virtualNumberObject.name}/${virtualNumberObject.uuid}`}
                value={virtualNumberObject.uuid}
              >
                <UserInfoListItem
                  user={virtualNumberObject}
                ></UserInfoListItem>
              </Select.Option>
            );
          })}
        </Select.OptGroup>
        <Select.OptGroup label="Extensions">
          {props.extensionList?.map((extension) => (
            <Select.Option 
              key={`${extension.extensionName}/${extension.id}`}
              value={extension.id}
              type={EXTENSION_TYPE}
              extensionCode={extension?.extensionNumber}
            >
              <ExtensionInfoListItem
                extension={extension}
              />
            </Select.Option>
          ))}
        </Select.OptGroup>

      </Select>
  );
};

export default UserSearchSelect;
