import {Content} from 'antd/lib/layout/layout';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../constants/ActionConst';
import {FHIR_RESOURCE} from '../../../constants/FhirConstant';
import {getPatientDetailsFromHint} from '../../../services/CommonService/AidBoxService';
import {getAccountConfigResourceAbilities, getPatientEHRUrl, getResourceAbilities} from '../../../utils/capabilityUtils';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {MessagingContactDetails} from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetails';
import {PersonActionPopover} from '../PersonHeaderBar/PersonAction/PersonActionPopover/PersonActionPopover';
import {IRightContainerProps} from './interfaces';
import { MESSAGING_WINDOW_HEADER_HEIGHT } from '../../../constants/StringConst';
import {useContactLocationUuid} from '../../common/MemebersView/customHook/useContactLocationUuid';

export const PatientInfoView = (props: IRightContainerProps) => {
  const {height} = Dimensions.get('window');
  const {
    contactData,
    onConversationSelect,
    onPersonActionPerformed,
    personData,
  } = props;
  const ehrPatientId = props.contactData?.patient?.patientId || '';
  const contactId: number = contactData?.id || 0;
  const [hintPatientId, setHintPatientId] = useState('');
  const [locationId, setLocationId] = useState(contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid || personData?.accountLocationUuid);
  
  const {fetchContactLocationUuid} = useContactLocationUuid(personData.leadId, true);
  const getContactLocationId = async (contactId: number) => {
    const response = await fetchContactLocationUuid(contactId);
    setLocationId(response);
  };
  const openInEhrUrl =
    getPatientEHRUrl(ehrPatientId, locationId, '') ||
    window.location.host === 'demo.sandbox.foldhealth.io';

  const openInHint =
    getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT)?.openInHint || false;
  useEffect(() => {
    if (personData.leadId && openInHint) {
      if (!locationId) {
        getContactLocationId(personData?.leadId);
      } else {
      getPatientDetailsFromHint(personData.leadId, locationId)
        .then((response) => {
          const id = response.data?.id;
          setHintPatientId(id);
        })
        .catch((error) => {

        });
      }
    }
  }, [locationId]);
  return (
    <Content
      className="common-scroll common-collapse"
      style={{overflow: 'hidden'}}
    >
      <MessagingContactDetails
        onConversationActionPerformed={onPersonActionPerformed as any}
        contactId={contactId}
        borderLessView={false}
        height={height - MESSAGING_WINDOW_HEADER_HEIGHT}
        showEditProfileOnPatientInfoBanner={true}
        showDetailProfile={false}
        hintExtraDetail={props.hintExtraDetail}
        parentCode={PATIENT_QUICK_PROFILE_PARENT_CODES.CONTACT_OMNI_VIEW}
        btnTextVisibility={{
          showEhrBtn: openInEhrUrl,
          // showMeetBtn: true,
          // showChatBtn : true,
          showSmsBtn: true,
          showEmailBtn: true,
          showAppointmentBtn: true
        }}
        extra={
          <View>
            <PersonActionPopover
              personData={props?.contactData  as IContact}
              personType={
                props?.contactData?.contactType?.contactType?.code as any
              }
              onActionPerformed={props.onPersonActionPerformed as any}
              hintPatientData={{
                openInHint: openInHint,
                hintPatientId: hintPatientId,
                showVideoCall: true
              }}
            />
          </View>
        }
        patientHieSyncCall={props.patientHieSyncCall}
      />
      {/* <PatientInfoListView
        refetchPatientAppointment={props.refetchPatientAppointment}
        onConversationSelect={onConversationSelect}
        contactData={contactData}
        contactId={contactId}
        onPersonActionPerformed={onPersonActionPerformed}
      /> */}
    </Content>
  );
};
