import BaseService from '../../../../../services/CommonService/BaseService';
import { IConversationFilterValue } from '../ConversationChannelNew/Interfaces';
import { IHandleBulkArchive } from '../ConversationChannelTabs/interfaces';

export const getFilterValues = (filterQuery?: any, inboxId?: number, conversationUserFilterData?: IConversationFilterValue) => {
  const readFilter = filterQuery?.isRead;
  const assigneeIds = filterQuery?.assigneeIds
  const locationUuids = conversationUserFilterData?.locationUuids
  const selectedInboxId = conversationUserFilterData?.inboxId
  const dateFilter = {
    endDate:
      filterQuery?._and?.[0]?.message?.createdAt?._lte ||
      filterQuery?._and?.[0]?.lastActivityAt?._lte ||
      filterQuery?.dateRange?.[0]?.message?.createdAt?._lte ||
      filterQuery?.dateRange?.[0]?.lastActivityAt?._lte,
    startDate:
      filterQuery?._and?.[1]?.message?.createdAt?._gte ||
      filterQuery?._and?.[1]?.lastActivityAt?._gte ||
      filterQuery?.dateRange?.[1]?.message?.createdAt?._gte ||
      filterQuery?.dateRange?.[1]?.lastActivityAt?._gte,
  };
  const filterData = {
    ...(dateFilter?.startDate &&
      dateFilter?.endDate && {dateRange: dateFilter}),
    ...(readFilter !== undefined && {readFilter: readFilter}),
    ...((selectedInboxId && selectedInboxId > 0) ? {inboxId: selectedInboxId} : inboxId && inboxId > 0 && {inboxId: inboxId}),
    ...(assigneeIds && {assigneeIds: assigneeIds}),
    ...(locationUuids?.length && {locationUuids: locationUuids})
  };
  return filterData;
};

export const getTotalConversationCountApi = async (
  selectedInboxTypeCode: string,
  filterQuery?: any,
  inboxId?: number,
  conversationUserFilterData?: IConversationFilterValue
) => {
  const filterData = getFilterValues(filterQuery, inboxId, conversationUserFilterData);
  const bodyData = {
    selectedInboxTypeCode: selectedInboxTypeCode,
    ...(filterData && {filter: filterData}),
  };
  const baseService = BaseService.getSharedInstance().axios;
  const conversationCountUrl = `crm-nest/conversation/conversations-count`;
  const conversationCount = await baseService.post(
    conversationCountUrl,
    bodyData
  );
  return conversationCount?.data;
};

export const handleMarkAsReadAPi = async (
  selectedInboxTypeCode: string,
  selectAllConversation: boolean,
  selectedConversationUuids?: string[],
  deselectedConversationUuidList?: string[],
  selectedMentionConversationIds?: string[],
  deselectedMentionConversationIds?: string[],
  filterQuery?: any,
  inboxId?: number,
  conversationUserFilterData?: IConversationFilterValue
) => {
  const filterData = getFilterValues(filterQuery, inboxId, conversationUserFilterData);
  const bodyData = {
    selectedInboxTypeCode: selectedInboxTypeCode,
    selectAll: selectAllConversation,
    selectedConversationUuidList: selectedConversationUuids,
    deselectedConversationUuidList: deselectedConversationUuidList,
    selectedMentionConversationIdList: selectedMentionConversationIds,
    deselectedMentionConversationIdList: deselectedMentionConversationIds,
    ...(filterData && {filter: filterData}),
  };
  const baseService = BaseService.getSharedInstance().axios;
  const conversationReadUrl = `crm-nest/conversation/read-conversation-msg`;
  const conversationCount = await baseService.post(
    conversationReadUrl,
    bodyData
  );
  return conversationCount?.data;
};

export const handleBulkArchiveAPI = async (data: IHandleBulkArchive) => {
  const {
    selectedInboxTypeCode,
    selectAllConversation,
    selectedConversationUuids,
    deselectedConversationUuidList,
    inboxId,
    filterQuery,
    conversationUserFilterData
  } = data;
  const filterData = getFilterValues(filterQuery, inboxId, conversationUserFilterData);
  const bodyData = {
    selectedInboxTypeCode: selectedInboxTypeCode,
    selectAll: selectAllConversation,
    selectedConversationUuids: selectedConversationUuids,
    deselectedConversationUuidList: deselectedConversationUuidList,
    ...(filterData && {filter: filterData}),
  };
  const baseService = BaseService.getSharedInstance().axios;
  const conversationReadUrl = `crm-nest/conversation/bulk-archive`;
  const conversationCount = await baseService.post(
    conversationReadUrl,
    bodyData
  );
  return conversationCount?.data;
};
