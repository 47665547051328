import {IInbox, IInboxOptions} from '../../../../../services/Inbox/interfaces';
import {ICreateEmailConversationDrawerState} from './CreateEmailConversationDrawer/interface';
import {ICreateSmsConversationDrawerState} from './CreateSmsConversationDrawer/interface';


export const getUpdatedCreateSMSConversationErrorMessages = (
  createSmsConversationDrawerState: ICreateSmsConversationDrawerState, contactData?: any
): any => {
  let patientErrorMsgStr = '';
  let messageErrorStr = '';
  let isErrorPresent = false;

  if (!createSmsConversationDrawerState?.selectedPatient?.id && !contactData?.id) {
    patientErrorMsgStr = 'Please select member or type number';
    isErrorPresent = true;
  }

  if (!createSmsConversationDrawerState.messageStr) {
    messageErrorStr = 'Message is required';
    isErrorPresent = true;
  }
  return {
    errors: {
      patient: patientErrorMsgStr,
      message: messageErrorStr,
    },
    isErrorPresent,
  };
};

export const getUpdatedCreateEmailConversationErrorMessages = (
  createSmsConversationDrawerState: ICreateEmailConversationDrawerState
): any => {
  let patientErrorMsgStr = '';
  let messageErrorStr = '';
  let subjectErrorStr = '';
  let isErrorPresent = false;

  if (!createSmsConversationDrawerState?.selectedPatient?.id) {
    patientErrorMsgStr = 'Please select patient';
    isErrorPresent = true;
  }

  if (!createSmsConversationDrawerState.messageStr) {
    messageErrorStr = 'Message is required';
    isErrorPresent = true;
  }
  if (!createSmsConversationDrawerState.subject) {
    subjectErrorStr = 'Subject is required';
    isErrorPresent = true;
  }
  return {
    errors: {
      patient: patientErrorMsgStr,
      message: messageErrorStr,
      subject: subjectErrorStr,
    },
    isErrorPresent,
  };
};

export const formatInboxDataForSelectOptions = (
  inboxDataList: IInbox[]
): IInboxOptions[] => {
  const inboxOptionsList: any = [...inboxDataList];
  inboxOptionsList.forEach((singleInbox: IInboxOptions) => {
    singleInbox.label = singleInbox?.name;
    if (singleInbox?.channelTwilioSms?.phoneNumber) {
      singleInbox.label +=
        ' (' + singleInbox?.channelTwilioSms?.phoneNumber + ')' || '';
    }
    singleInbox.key = singleInbox.id + '';
    singleInbox.value = singleInbox.id + '';
  });
  return inboxOptionsList;
};
