import {useLazyQuery} from '@apollo/client';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import QualityMeasuresService, {
  getQualityMeasures,
} from '../../../../../services/Contracts/QualityMeasures.service';
import {
  IMeasureReport,
  IQualityMeasures,
  IQualityMeasuresList,
} from '../../HelperFiles/interface';
import {getFormattedQualityMeasuresList} from '../../HelperFiles/utils';
import QualityMeasuresList from './QualityMeasuresList';
import {getAccountUUID} from '../../../../../utils/commonUtils';

const QualityMeasures = (props: IQualityMeasures) => {
  const {searchString} = props;
  const {contractId} = useParams();
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    qualityMeasuresList: [] as IQualityMeasuresList[],
    currentPage: 1,
    pageSize: 10,
    total: 10,
    offSet: 0,
    loading: false,
  });
  const [qualityMeasures] = useLazyQuery(
    QualityMeasuresService.QualityMeasuresQuery,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [qualityMeasureReport] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReport,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offSet = currentPageSize * (page - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: page,
        offSet: offSet,
        pageSize: currentPageSize,
      };
    });
  };
const updateStateData = (newState: any) => {
  setStateData((prev) => ({ ...prev, ...newState }));
};

const fetchQualityMeasuresData = async (offSet?: number) => {
  try {
    const response = await qualityMeasures({
      variables: {
        contractId,
        name: `%${searchString}%`,
        limit: stateData.pageSize,
        offset: offSet === 0 ? offSet : stateData.offSet,
      },
    });
    return response;
  } catch (error) {
    updateStateData({ qualityMeasuresList: [], loading: false });
    return null;
  }
};

const fetchQualityMeasureReports = async (measureIds: string[]) => {
  const filterObject = {
    isLatest: { _eq: true },
    contractId: { _eq: contractId || '' },
    measureId: { _in: measureIds || '' },
    tenantId: { _eq: accountUuid },
    denominatorFlag: {_eq: true}
  };

  const response = await qualityMeasureReport({
    variables: { where: filterObject },
  });
  return response.data.qmMeasureReports;
};

const processQualityMeasures = (qualityMeasuresList: IQualityMeasuresList[], qmReportList: IMeasureReport[]) => {
  qualityMeasuresList.forEach((measure) => {
    const qmReportsByMeasure = qmReportList.filter(qmReport => qmReport.measureId === measure.measureId);
    const qmReportsByNumeratorTrue = qmReportsByMeasure.filter(qmReport => qmReport.numeratorFlag);
    const qmReportsByDenominatorTrue = qmReportsByMeasure.filter(qmReport => qmReport.denominatorFlag && !qmReport.numeratorFlag );

    const performanceRate = (qmReportsByNumeratorTrue.length / qmReportsByMeasure.length) * 100;
    measure.performanceScore = `${performanceRate.toFixed(2)}%`; // Ensure consistent decimal formatting
    measure.totalPatients = qmReportsByMeasure.length;
    measure.careGap = qmReportsByDenominatorTrue.length;
  });

  return qualityMeasuresList;
};

const fetchQualityMeasures = async (offSet?: number) => {
  updateStateData({ loading: true });

  const qualityMeasuresResp = await fetchQualityMeasuresData(offSet);
  if (!qualityMeasuresResp || !qualityMeasuresResp.data?.qmContractQualityMeasures?.length) {
    updateStateData({ qualityMeasuresList: [], loading: false });
    return;
  }

  const qualityMeasuresList = getFormattedQualityMeasuresList(qualityMeasuresResp.data);
  const measureIds = qualityMeasuresList.map(measure => measure.measureId);

  const qmReportList = await fetchQualityMeasureReports(measureIds);
  const updatedQualityMeasuresList = processQualityMeasures(qualityMeasuresList, qmReportList);

  updateStateData({
    qualityMeasuresList: updatedQualityMeasuresList,
    loading: false,
    total: qualityMeasuresResp.data.qggregateQmContractQualityMeasures?.aggregate?.count,
  });
};

  useEffect(() => {
    fetchQualityMeasures();
  }, [stateData.currentPage, stateData.pageSize]);

  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offSet: 0,
        };
      });
    } else {
      fetchQualityMeasures(0);
    }
  }, [searchString]);
  return (
    <View>
      <QualityMeasuresList
        handlePageChange={handlePageChange}
        stateData={stateData}
        data={stateData.qualityMeasuresList}
      />
    </View>
  );
};
export default QualityMeasures;
