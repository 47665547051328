import { JOURNEY_START_TYPES } from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import {IProductDetailForm} from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {IJourneyDetailForm} from './AddOrUpdateJourney';
import {JOURNEY_TABS} from './JourneyTabs';

export const getCareTeamDataForUpdate = (
  currentCareTeam: string[],
  prevCareTeam: any[],
  careJourneyId: string
) => {
  const finalData: any[] = [];
  let careTeamPendingToAdd = currentCareTeam;

  prevCareTeam.forEach((prevMember: any) => {
    if (careTeamPendingToAdd.includes(prevMember.roleId)) {
      finalData.push({...prevMember, careJourneyId: careJourneyId});
      careTeamPendingToAdd = careTeamPendingToAdd.filter(
        (careTeam) => careTeam !== prevMember.roleId
      );
    } else {
      finalData.push({
        ...prevMember,
        isDeleted: true,
        careJourneyId: careJourneyId,
      });
    }
  });

  if (careTeamPendingToAdd.length > 0) {
    careTeamPendingToAdd.forEach((roleId) => {
      finalData.push({roleId: roleId, careJourneyId: careJourneyId});
    });
  }

  return finalData;
};

export const getUserInputDataFromUserInputFieldList = (
  userInputFieldList: any[]
) => {
  const userInputMap: any = {};
  userInputFieldList.forEach((metaData) => {
    if (metaData && metaData.key) {
      if (metaData.value) {
        userInputMap[metaData.key] = metaData.value;
      } else {
        userInputMap[metaData.key] = metaData.defaultValue;
      }
    }
  });

  return userInputMap;
};

// const checkPriceAndComparePriceVal = (isAssignWorkflow: boolean, details?: IProductDetailForm) => {
//   const isPrescribedTagSelected =
//     details?.triggerEvents?.length === 1 &&
//     details?.triggerEvents?.includes('prescribed');

//   if (isPrescribedTagSelected) {
//     return false;
//   }

//   let offerPrice: any = undefined;
//   let price: any = undefined;

//   try {
//     offerPrice = details?.price;
//     price = details?.compare_at_price;
//     if (offerPrice) {
//       offerPrice = parseFloat(offerPrice);
//     }
//     if (price) {
//       price = parseFloat(price);
//     }
//   } catch (err) {
//   }

//   if (!isAssignWorkflow && offerPrice >= 0 && price >= 0 && offerPrice > price) {
//     return true;
//   }

//   // Price and offer price are mandatory now
//   if (!(offerPrice || offerPrice === 0) || !(price || price === 0)) {
//     return true;
//   }

//   return false;
// };

const isJourneyDesignTabDisabled = (isAssignWorkflow: boolean, details?: IProductDetailForm): boolean => {
  return (
    !details ||
    !details.name ||
    details.triggerEvents.length === 0 ||
    !details.duration ||
    details.duration.value <= 0 ||
    !details.duration.unit ||
    !details.description
    // checkPriceAndComparePriceVal(isAssignWorkflow, details)
  );
};

export const isTabDisabled = (
  tabCode: string,
  isAssignWorkflow: boolean,
  details?: IProductDetailForm,
  noUserConfiguredForSomeRole = false
) => {
  switch (tabCode) {
    case JOURNEY_TABS.JOURNEY_DESIGN:
    case JOURNEY_TABS.CARE_TEAM:
      return isJourneyDesignTabDisabled(isAssignWorkflow, details);

    case JOURNEY_TABS.PREVIEW:
      if (isAssignWorkflow) {
        return (
          !details ||
          isJourneyDesignTabDisabled(isAssignWorkflow, details) ||
          (details?.startDate && details?.careTeam?.length !== 0 && details?.careTeam?.length !== details?.careTeamUsers?.length) ||
          noUserConfiguredForSomeRole
        );
      }
      return (
        !details ||
        isJourneyDesignTabDisabled(isAssignWorkflow, details)
      );
    default:
      break;
  }
  return false;
};

export const isNextButtonDisabled = (
  tabCode: string,
  isAssignWorkflow: boolean,
  details?: IProductDetailForm
) => {
  switch (tabCode) {
    case JOURNEY_TABS.JOURNEY_DESIGN:
    case JOURNEY_TABS.CARE_TEAM:
      return isJourneyDesignTabDisabled(isAssignWorkflow, details);

    case JOURNEY_TABS.PREVIEW:
        return (
          !details ||
          isJourneyDesignTabDisabled(isAssignWorkflow, details)
        );
    default:
      break;
  }
  return false;
}

export const checkIfDetailsFilled = (
  details: IJourneyDetailForm | undefined,
  isAssignWorkflow: boolean,
  isMsoEnabled = false
): boolean => {
  if (
    !details ||
    !details.name ||
    details.triggerEvents.length === 0 ||
    !details.duration ||
    details.duration.value <= 0 ||
    !details.duration.unit ||
    !details.description || (details.startType === JOURNEY_START_TYPES.FIXED && !details.startDateTime) ||
    (!details.careJourneyLocations?.locations && isMsoEnabled)
    // checkPriceAndComparePriceVal(isAssignWorkflow, details)
  ) {
    return false;
  }

  return true;
};
