import {getEnvVariable} from '../../../../../../constants/BaseUrlConst';
import {IChannelEmail} from '../../../../../../services/Inbox/interfaces';
import {
  getCodeFromUrl,
  isValidURL,
  postMessageToParentWindow,
} from '../../../../../../utils/commonUtils';
import {EMAIL_REGEX} from '../../constants';
import {IIntegrationsDataProps} from '../../interfaces';
import {EMAIL_INBOX_ERROR, EMAIL_SETTING} from './EmailInboxConst';
import {
  IEmailInboxCreateParams,
  IEmailInboxDrawerState,
  IEmailInboxErrors,
  IEmailInboxFormKeys,
  IEmailInboxRef,
  ITempBodyData,
  IUpdateEmailChannelImapConfig,
  IUpdateEmailChannelSmtpConfig,
} from './interfaces';

export const getUpdatedEmailInboxErrorMessages = (
  emailInboxState: IEmailInboxDrawerState,
  smsFormRef: React.MutableRefObject<IEmailInboxRef>
): any => {
  let isErrorPresent = false;
  const errorsObj: any = {
    users: '',
  };

  if (smsFormRef?.current && Object.keys(smsFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(smsFormRef?.current);
    formElemKeys.forEach((singleKey: IEmailInboxFormKeys) => {
      const formFieldRef = smsFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        errorsObj[singleKey] = '';
      } else {
        if (singleKey === 'email') {
          if (emailInboxState.emailSetting === EMAIL_SETTING.FOLD_POWERED) {
            isErrorPresent = true;
            errorsObj[singleKey] = EMAIL_INBOX_ERROR[singleKey];
          } else {
            errorsObj[singleKey] = '';
          }
        } else {
          isErrorPresent = true;
          errorsObj[singleKey] = EMAIL_INBOX_ERROR[singleKey];
        }
        if (singleKey === 'imapEmail' || singleKey === 'smtpEmail') {
          errorsObj[singleKey] = !formFieldRef?.value.match(EMAIL_REGEX)
            ? 'Invalid Email'
            : '';
        }
      }
    });
  }
  if (
    !emailInboxState?.selectedUsersIds?.length ||
    !emailInboxState?.selectedUsersUuids?.length
  ) {
    errorsObj.users = 'Please select users';
    isErrorPresent = true;
  }
  return {
    errors: {
      ...errorsObj,
    },
    isErrorPresent,
  };
};

export const getEmailInboxBodyData = (
  emailInboxState: IEmailInboxDrawerState,
  smsFormRef: React.MutableRefObject<IEmailInboxRef>,
  additionalData: {
    subDomain: string;
  }
): IEmailInboxCreateParams => {
  const tempBodyData: ITempBodyData = {
    selectedUsersIds: [],
    inboxName: '',
    email: '',
    imapAddress: '',
    imapPort: '',
    imapEmail: '',
    imapPassword: '',
    smtpAddress: '',
    smtpPort: '',
    smtpEmail: '',
    smtpPassword: '',
  };

  if (smsFormRef?.current && Object.keys(smsFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(smsFormRef?.current);
    formElemKeys.forEach((singleKey: IEmailInboxFormKeys) => {
      const formFieldRef = smsFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        tempBodyData[singleKey] = formFieldRef?.value;
      }
    });
  }
  if (emailInboxState?.selectedUsersIds?.length) {
    tempBodyData.selectedUsersIds = emailInboxState?.selectedUsersIds;
  }

  if (emailInboxState?.emailSetting === EMAIL_SETTING.FOLD_POWERED) {
    tempBodyData.email = tempBodyData.email + '@' + additionalData.subDomain;
  } else {
    tempBodyData.email = tempBodyData.imapEmail;
  }
  const finalBodyData: IEmailInboxCreateParams = {
    name: tempBodyData.inboxName,
    channel: {
      type: 'email',
      email: tempBodyData?.smtpEmail,
      imap_enabled: false,
      imap_address: tempBodyData.imapAddress,
      imap_port: tempBodyData.imapPort,
      imap_email: tempBodyData.imapEmail,
      imap_password: tempBodyData.imapPassword,
      imap_inbox_synced_at: new Date().toISOString(),
      imap_enable_ssl: false,
      smtp_enabled: true,
      smtp_address: tempBodyData?.smtpAddress || 'smtp.mailgun.org',
      smtp_port: tempBodyData?.smtpPort || '587',
      smtp_email:
        tempBodyData?.smtpEmail ||
        tempBodyData.email + '@' + additionalData.subDomain,
      smtp_password: tempBodyData?.smtpPassword || '',
      smtp_domain: additionalData.subDomain,
      smtp_enable_ssl_tls: false,
      smtp_enable_starttls_auto: true,
      smtp_openssl_verify_mode: 'none',
    },
  };
  return finalBodyData;
};

export const getImapEmailBodyData = (
  emailInboxState: IEmailInboxDrawerState,
  smsFormRef: React.MutableRefObject<IEmailInboxRef>,
  additionalData: {
    subDomain: string;
  }
): IUpdateEmailChannelImapConfig => {
  const tempBodyData: any = {
    selectedUsersIds: [],
    inboxName: '',
    email: '',
    imapAddress: '',
    imapPort: '',
    imapEmail: '',
    imapPassword: '',
    smtpAddress: '',
    smtpPort: '',
    smtpEmail: '',
    smtpPassword: '',
  };

  if (smsFormRef?.current && Object.keys(smsFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(smsFormRef?.current);
    formElemKeys.forEach((singleKey: IEmailInboxFormKeys) => {
      const formFieldRef = smsFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        tempBodyData[singleKey] = formFieldRef?.value;
      }
    });
  }
  if (emailInboxState?.selectedUsersIds?.length) {
    tempBodyData.selectedUsersIds = emailInboxState?.selectedUsersIds;
  }

  if (emailInboxState?.emailSetting === EMAIL_SETTING.FOLD_POWERED) {
    tempBodyData.email = tempBodyData.email + '@' + additionalData.subDomain;
  } else {
    tempBodyData.email = tempBodyData.imapEmail;
  }
  if (smsFormRef?.current && Object.keys(smsFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(smsFormRef?.current);
    formElemKeys.forEach((singleKey: IEmailInboxFormKeys) => {
      const formFieldRef = smsFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        tempBodyData[singleKey] = formFieldRef?.value;
      }
    });
  }
  if (emailInboxState?.selectedUsersIds?.length) {
    tempBodyData.selectedUsersIds = emailInboxState?.selectedUsersIds;
  }

  if (emailInboxState?.emailSetting === EMAIL_SETTING.FOLD_POWERED) {
    tempBodyData.email = tempBodyData.email + '@' + additionalData.subDomain;
  } else {
    tempBodyData.email = tempBodyData.imapEmail;
  }
  const finalBodyData: IUpdateEmailChannelImapConfig = {
    channel: {
      imap_enabled: true,
      imap_address: tempBodyData.imapAddress,
      imap_port: tempBodyData.imapPort,
      imap_email: tempBodyData.imapEmail,
      imap_password: tempBodyData.imapPassword,
      imap_inbox_synced_at: new Date().toISOString(),
      imap_enable_ssl: true,
    },
  };
  return finalBodyData;
};

export const getSmtpEmailBodyData = (
  emailInboxState: IEmailInboxDrawerState,
  smsFormRef: React.MutableRefObject<IEmailInboxRef>,
  additionalData: {
    subDomain: string;
  }
): IUpdateEmailChannelSmtpConfig => {
  const tempBodyData: any = {
    selectedUsersIds: [],
    inboxName: '',
    email: '',
    imapAddress: '',
    imapPort: '',
    imapEmail: '',
    imapPassword: '',
    smtpAddress: '',
    smtpPort: '',
    smtpEmail: '',
    smtpPassword: '',
  };

  if (smsFormRef?.current && Object.keys(smsFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(smsFormRef?.current);
    formElemKeys.forEach((singleKey: IEmailInboxFormKeys) => {
      const formFieldRef = smsFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        tempBodyData[singleKey] = formFieldRef?.value;
      }
    });
  }
  if (emailInboxState?.selectedUsersIds?.length) {
    tempBodyData.selectedUsersIds = emailInboxState?.selectedUsersIds;
  }

  if (emailInboxState?.emailSetting === EMAIL_SETTING.FOLD_POWERED) {
    tempBodyData.email = tempBodyData.email + '@' + additionalData.subDomain;
  } else {
    tempBodyData.email = tempBodyData.imapEmail;
  }
  if (smsFormRef?.current && Object.keys(smsFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(smsFormRef?.current);
    formElemKeys.forEach((singleKey: IEmailInboxFormKeys) => {
      const formFieldRef = smsFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        tempBodyData[singleKey] = formFieldRef?.value;
      }
    });
  }
  if (emailInboxState?.selectedUsersIds?.length) {
    tempBodyData.selectedUsersIds = emailInboxState?.selectedUsersIds;
  }

  if (emailInboxState?.emailSetting === EMAIL_SETTING.FOLD_POWERED) {
    tempBodyData.email = tempBodyData.email + '@' + additionalData.subDomain;
  } else {
    tempBodyData.email = tempBodyData.imapEmail;
  }
  const finalBodyData: IUpdateEmailChannelSmtpConfig = {
    channel: {
      smtp_enabled: true,
      smtp_address: tempBodyData?.smtpAddress || 'smtp.mailgun.org',
      smtp_port: tempBodyData?.smtpPort || '587',
      smtp_email:
        tempBodyData?.smtpEmail ||
        tempBodyData.email + '@' + additionalData.subDomain,
      smtp_password: tempBodyData?.smtpPassword || '',
      smtp_domain: additionalData.subDomain,
      smtp_enable_ssl_tls: false,
      smtp_enable_starttls_auto: true,
      smtp_openssl_verify_mode: 'none',
    },
  };
  return finalBodyData;
};

export const isInboxPresent = (
  selectedInboxData: IIntegrationsDataProps
): boolean => {
  if (selectedInboxData?.singleInboxData?.id) {
    return true;
  }
  return false;
};

export const postOutlookCodeToParentWindow = () => {
  // TODO:
  // in case of sso, this function was getting executed and the window was getting closed.
  // this condition is added for partnermd as it is currently the only SSO enabled account
  // this needs to fixed in a proper manner and patched to production
  // ticket ref: https://foldhealth.atlassian.net/browse/FCS-2840
  const href = window.location.href;
  const isExtensionLogin = window.location.href.includes('/extension');
  if (isExtensionLogin) {
    return;
  }
  const code = getCodeFromUrl();
  const url = new URL(window.location.href);
  const error = url.searchParams.get('error');
  const state = url.searchParams.get('state');
  if (!isValidURL(state || '')) {
    return;
  }
  
  if (code) {
    postMessageToParentWindow(code, state || '*');
    window.close();
    return;
  }
  if (error) {
    postMessageToParentWindow(error, state || '*');
    window.close();
  }
};

export const getChildWindowDimensions = () => {
  const width = 900;
  const height = 900;
  const left = screen.width / 2 - 900 / 2;
  const top = screen.height / 2 - 900 / 2;
  return {
    width,
    height,
    top,
    left,
  };
};

export const validateOutlookEmailData = (data: {
  inboxName: string;
  code: string;
  selectedAgents: any[];
  email: string;
  inboxId?: number;
}): {
  isValid: boolean;
  errors: IEmailInboxErrors;
} => {
  const {inboxName, code, selectedAgents, email, inboxId} = data;
  let isValid = true;
  const errors: IEmailInboxErrors = {
    code: '',
    email: '',
    inboxName: '',
    selectedAgents: '',
  };
  if (!inboxName || (inboxName || '').trim().length === 0) {
    isValid = false;
    errors.inboxName = 'inboxNameNotValid';
  }

  if (!email || !EMAIL_REGEX.test(email)) {
    isValid = false;
    errors.email = 'emailNotValid';
  }

  if (!inboxId && !code) {
    isValid = false;
    errors.code = 'authCodeNotReceived';
  }

  if (selectedAgents.length === 0) {
    isValid = false;
    errors.selectedAgents = 'agentsNotSelected';
  }

  return {
    errors: errors,
    isValid: isValid,
  };
};

export const isOutlookEmailInbox = (
  channelEmail: IChannelEmail | undefined
) => {
  return channelEmail?.smtpAddress === 'outlook.com';
};

export const openOutlookLoginWindow = () => {
  const redirectUri = getRedirectUriByEnv();
  const state = window.location.origin;
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=61f11af3-e978-4ef2-978b-1529e02e2a1e&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=offline_access%20User.Read%20Mail.Read%20IMAP.AccessAsUser.All%20Mail.Read%20Mail.Read.Shared%20Mail.ReadBasic%20Mail.ReadBasic.Shared%20Mail.ReadWrite.Shared%20Mail.Send.Shared%20profile%20User.Read%20openid%20email&state=${state}&prompt=login`;
  // const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=61f11af3-e978-4ef2-978b-1529e02e2a1e&response_type=code&redirect_uri=http://localhost:3000&response_mode=query&scope=offline_access%20User.Read%20Mail.Read&state=${state}&prompt=login`;
  const {height, left, top, width} = getChildWindowDimensions();
  window.open(
    url,
    'Login',
    `left=${left},top=${top},width=${width},height=${height}, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no`
  );
};

export const getRedirectUriByEnv = () => {
  const env = getEnvVariable();
  let redirectUri = '';
  switch (env) {
    case 'dev':
      redirectUri = 'https://demo.dev.foldhealth.io';
      break;

    case 'qa':
      redirectUri = 'https://test2.qa.foldhealth.io';
      break;

    case 'sandbox':
      redirectUri = 'https://testing24.sandbox.foldhealth.io';
      break;

    default:
      redirectUri = 'https://test5.foldhealth.io';
      break;
  }
  return redirectUri;
};
