import {useLazyQuery} from '@apollo/client';
import {HStack, Pressable, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import RedirectSvg from '../../../../assets/Icons/RedirectSvg';
import {DATE_FORMATS, MODULE_PAGINATION_COUNT, RIGHT_SIDE_CONTAINER_CODE} from '../../../../constants';
import {COMMON_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../../constants/ActionConst';
import {LeadQueries} from '../../../../services';
import { IUserResp } from '../../../../services/User/interface';
import {Colors} from '../../../../styles';
import {
  getSecondaryValue,
  isActiveContact
} from '../../../../utils/commonUtils';
import {getAgeValue, getDateStrFromFormat} from '../../../../utils/DateUtils';
import {PersonActions} from '../../../common/PersonActions/PersonActions';
import { isUuid } from '../../../common/PhoneCalling/utils';
import {PatientInfoBanner} from '../../../PatientInfoBanner/PatientInfoBanner';
import {AddOrUpdateLead} from '../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {IContact} from '../Conversations/interfaces';
import CallLogDetailItem from './CallLogDetailItem';
import {ICallLogEntry, ISipNumberList} from './interface';
import { withMiniContactViewHOC } from '../../../MiniContactViewHOC';
import { isPatientDetailViewAllowed } from '../../UserAccess/UserAccessPermission';
import { getEhrConfig } from '../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData} from '../../../../services/CommonService/AidBoxService';
import { Tooltip } from 'antd';
import { CALL_DETAILS_FLEX_VALUES } from './CallLogsConst';
import NoCallLogsSvg from '../../../common/Svg/NoCallLogsSvg';
import { useIntl } from 'react-intl';

export interface CallLogDetailsProps {
  callLogDetails: ICallLogEntry[];
  onClickPrev: () => void;
  onClickNext: () => void;
  callLogList: any;
  selectedLog: any;
  callLogDetailsPage: number;
  userList: IUserResp[];
  sipNumberList?: ISipNumberList[];
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;
  openContactInWindowOrOpenContactIdDrawer?:(contactId?:string)=>void;
}

const CallLogDetails = (props: CallLogDetailsProps) => {
  const {height} = Dimensions.get('window');
  const navigate = useNavigate();
  const [contactInfo, setContactInfo] = useState<IContact>();
  const [singleCallLog, setSingleCallLog] = useState({} as any);
  let newContactData: any;
  const [stateData, setStateData] = useState({
    selectedViewCode: '',
    deceasedDate : ''
  });
  const intl = useIntl();
  const finalListHeight = height - 490  ;
  const patientDetailsAllowed = isPatientDetailViewAllowed();

  const [getContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  const getContactTypeValue = (contactData: ICallLogEntry): string => {
    if (contactData?.contact?.contactType == 'PATIENT') {
      return 'Patient';
    } else if (contactData?.contact?.contactType) {
      return 'Prospect';
    }
    return '';
  };

  const getSubtitleText = (contactData: ICallLogEntry) : JSX.Element=> {
    let birthDateCareDashboardFormat = '';
    if (contactData?.contact?.birthDate) {
      birthDateCareDashboardFormat = getDateStrFromFormat(contactData?.contact?.birthDate,DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT)
    }
    const ageValue = getAgeValue(birthDateCareDashboardFormat, stateData.deceasedDate);
    return (
      <HStack>
        {isCallLogValid() && (
          <>
            {singleCallLog?.direction == 'inbound' ? (
              <Text>
                {contactData?.fromName !== contactData?.from
                  ? getUsernameForId(contactData?.from)
                  : ''}
              </Text>
            ) : (
              <Text>
                {contactData?.toName !== contactData?.to
                  ? getUsernameForId(contactData?.to)
                  : ''}
              </Text>
            )}
          </>
        )}
        {
          <Text>
            {!isCallLogValid() &&
              singleCallLog?.contact?.phoneNumber &&
              singleCallLog?.contact?.phoneNumber}
          </Text>
        }
        {contactData?.contact?.birthDate &&
        getAgeValue(birthDateCareDashboardFormat, stateData.deceasedDate) ? (
          <Tooltip
            title={ageValue}
            placement="top"
            color={Colors.Custom.Gray700}
          >
            <Text>
              {` | ` +
                getDateStrFromFormat(
                  contactData?.contact?.birthDate,
                  DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                )}
            </Text>
          </Tooltip>
        ) : (
          <Text>{''}</Text>
        )}
        {
          <Text>
            {getContactTypeValue(contactData).length > 0
              ? ` | ` + getContactTypeValue(contactData)
              : ''}
          </Text>
        }
      </HStack>
    );
  };

  const getUsernameForId = (string: string) => {
    const isTypeUuid = isUuid(string);
    if (isTypeUuid) {
      const user = props.userList.filter((user) => {
        return user.uuid === string;
      });
      return user[0]?.name;
    } else {
      return string
    }
  }

  const getHeaderString = () => {
    if (isCallLogValid()) {
      return singleCallLog?.direction == 'inbound'
        ? singleCallLog?.fromName ?? singleCallLog?.from
        : singleCallLog?.toName ?? singleCallLog?.to;
    } else if (singleCallLog?.contact) {
      return (
        singleCallLog?.contact?.name ??
        singleCallLog?.contact?.phoneNumber ??
        '-'
      );
    }
  };

  const getOverridenHeadersubString = () => {
    const header = getHeaderString() || '';
    if (isUuid(header)) {
      const user = props.userList.filter((user) => {
        return user.uuid === header;
      });
      return user[0]?.name;
    } else {
      return header;
    }
  }

  const getContactNumber = () => {
    if (isCallLogValid()) {
      return singleCallLog?.direction == 'inbound'
        ? singleCallLog?.from
        : singleCallLog?.to;
    } else if (singleCallLog?.contact?.phoneNumber) {
      return singleCallLog?.contact?.phoneNumber;
    }
  };

  const getValidPhoneNumber = () => {
    const callLogPhoneNumber = getContactNumber();
    if (contactInfo?.phoneNumber) {
      return callLogPhoneNumber;
    }
    return '';
  }
  const handleEdit = (actionCode?: string) => {
      if (actionCode === COMMON_ACTION_CODES.CONSENT_WARNING) {
        newContactData = {...contactInfo, showConsentError: true};
      }
      setContactInfo((prev: any)=> {
        return {
          ...prev,
          showConsentError: true
        }
      })
      setStateData(prev=> {
        return {
          ...prev,
          selectedViewCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW
        }
      })

  };
  useEffect(() => {
    getContact({
      variables: {
        id: singleCallLog?.contact?.contactId,
      },
    }).then((resp) => {
      if (resp?.data) {
        setContactInfo(resp?.data?.contact);
      } else {
        setContactInfo( {} as IContact);
      }
    });
  }, [singleCallLog]);

  useEffect(() => {
    setSingleCallLog(props?.selectedLog);
  }, [props?.selectedLog]);

  const isValidContact = () => {
    return singleCallLog?.contact ? true : false;
  };

  const isCallLogValid = () => {
    return singleCallLog?.id;
  };
  const contactActiveStatus = isActiveContact(contactInfo);

  const ehrConfig = getEhrConfig(
    contactInfo?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );

  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData || {});
    setStateData((prev)=> {
      return{
        ...prev,
        deceasedDate: deceasedDatePatient
      }
    });
  };

  useEffect(() => {
    ehrConfig.isAthena &&
    getPatientDeceasedData(contactInfo)
  }, [contactInfo?.patient?.patientId]);
  return (
    <View>
      <VStack>
        <View
          style={{
            backgroundColor: '#ffffff',
          }}
        >
          <View style={{padding: 14}}>
            <PatientInfoBanner
              contactData={contactInfo}
              isLetterAvatarShow
              name={getOverridenHeadersubString()}
              contactActiveStatus={contactInfo?.isActive}
              bannerType={'bigBanner'}
              isHorizontal={true}
              isSelected={false}
              headerString={getOverridenHeadersubString()}
              genderCode={contactInfo?.person?.gender?.code}
              userId={singleCallLog?.contact?.contactUuid}
              userType={singleCallLog?.contact?.contactType}
              subtitleElement={getSubtitleText(singleCallLog) || '-'}
              deceasedDate ={stateData.deceasedDate}
              shouldShowSubtitleHighlighter={true}
              showDetailProfile={false}
            />
          </View>
          <View style={{padding: 14}}>
            <PersonActions
              showPhoneCall={true}
              shouldShowEmail={isValidContact()}
              showVideoCall={isValidContact()}
              showAppointment={isValidContact()}
              showNotes={false}
              showSms={isValidContact()}
              showViewProfile={isValidContact()}
              showCharts={patientDetailsAllowed}
              contactInfo={contactInfo}
              handleEdit={handleEdit}
              email={
                contactInfo?.email ||
                getSecondaryValue(contactInfo?.personContact, 'email') ||
                ''
              }
              phoneNumber={
                getValidPhoneNumber() ||
                getSecondaryValue(contactInfo?.personContact, 'phone') ||
                ''
              }
              marginTop={2}
              parentCode={PATIENT_QUICK_PROFILE_PARENT_CODES.CALL_LOG}
            />
          </View>
          {isValidContact() && (
            <HStack
              style={{position: 'absolute', right: 24, top: 24}}
              space={2}
            >
              <View style={{width: 36, height: 36}}>
                <Tooltip title="View Details">
                  <Pressable
                    onPress={() => {
                      if (
                        singleCallLog?.contact?.contactType == 'CUSTOMER' ||
                        singleCallLog?.contact?.contactType == 'PATIENT'
                      ) {

                        props?.openContactInWindowOrOpenContactIdDrawer?.(singleCallLog?.contact?.contactId);
                      } else {
                        window.open(
                          `/#/members/prospect/${singleCallLog?.contact?.contactId}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <RedirectSvg />
                  </Pressable>
                </Tooltip>
              </View>
            </HStack>
          )}
        </View>
        {isCallLogValid() ? (
          <View
            style={{
              backgroundColor: '#ffffff',
            }}
          >
            <View style={{marginHorizontal: 24, marginVertical: 20}}>
              <Text size={'lgBold'} color={'#000000'}>
                {singleCallLog?.direction == 'inbound'
                  ? `To: ${getUsernameForId(singleCallLog?.to ?? '')}`
                  : `From: ${getUsernameForId(singleCallLog?.from ?? '')}`}
              </Text>
            </View>
            <HStack
              style={{
                paddingVertical: 13,
                backgroundColor: Colors.Custom.Gray50,
                borderColor: Colors.Custom.Gray200,
                borderTopWidth: 1,
                borderBottomWidth: 1,
              }}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: CALL_DETAILS_FLEX_VALUES.CALL_TYPE,
                  marginLeft: 24,
                }}
              >
                <Text size={'xsMedium'} color={Colors.Custom.Gray500}>
                  Call Type
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: CALL_DETAILS_FLEX_VALUES.DATE_TIME,
                }}
              >
                <Text size={'xsMedium'} color={Colors.Custom.Gray500}>
                  Date & Time
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: CALL_DETAILS_FLEX_VALUES.DURATION,
                }}
              >
                <Text size={'xsMedium'} color={Colors.Custom.Gray500}>
                  Duration
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: CALL_DETAILS_FLEX_VALUES.OUT_OF_OFFICE,
                }}
              >
                <Text size={'xsMedium'} color={Colors.Custom.Gray500}>
                  Out of office
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: CALL_DETAILS_FLEX_VALUES.ACTIONS,
                }}
              >
                <Text size={'xsMedium'} color={Colors.Custom.Gray500}>
                  Actions
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  width: 100,
                }}
              ></View>
              {/* <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: 1,
                }}
              >
                <Text size={'xsMedium'} color={Colors.Custom.Gray500}>
                  Out of business hours
                </Text>
              </View> */}
            </HStack>
            <div
              style={{
                width: 'full',
                height: finalListHeight,
                overflow: 'scroll',
                marginTop: 5,
                scrollBehavior: 'smooth',
              }}
            >
              {props.callLogDetails?.map((callLogEntry: ICallLogEntry) => {
                return (
                  <CallLogDetailItem
                    callLogEntry={callLogEntry}
                    contactInfo={contactInfo || {} as IContact}
                    userList={props?.userList}
                    sipNumberList={props?.sipNumberList}
                  />
                );
              })}
            </div>
            {props.callLogDetailsPage !== 1 ||
            props.callLogDetails.length >=
              MODULE_PAGINATION_COUNT.CALL_LOG_SIZE ? (
              <HStack
                style={{
                  borderTopColor: Colors.Custom.Gray200,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderTopWidth: 1,
                  paddingVertical: 12,
                  paddingHorizontal: 15,
                }}
              >
                {props.callLogDetailsPage !== 1 ? (
                  <Pressable
                    style={{
                      borderWidth: 1,
                      padding: 10,
                      borderColor: Colors.Custom.Gray200,
                      borderRadius: 6,
                    }}
                    onPress={props.onClickPrev}
                  >
                    <Text size={'smMedium'} color="#000000">
                      Previous
                    </Text>
                  </Pressable>
                ) : (
                  <View></View>
                )}
                {props.callLogDetails.length >=
                MODULE_PAGINATION_COUNT.CALL_LOG_SIZE ? (
                  <Pressable
                    style={{
                      borderWidth: 1,
                      padding: 10,
                      borderColor: Colors.Custom.Gray200,
                      borderRadius: 6,
                    }}
                    onPress={props.onClickNext}
                  >
                    <Text size={'smMedium'} color="#000000">
                      Next
                    </Text>
                  </Pressable>
                ) : (
                  <View></View>
                )}
              </HStack>
            ) : (
              <></>
            )}
          </View>
        ):(
          <VStack
            justifyContent={'center'}
            alignItems={'center'}
            alignSelf={'center'}
            paddingTop={40}
          >
            <NoCallLogsSvg />
            <Text
              padding={4}
              fontSize={24}
              fontWeight={'700'}
              color={Colors.Custom.Gray500}
            >
              {intl.formatMessage({id: 'noCallLogs'})}
            </Text>
          </VStack>
        )}
      </VStack>
      {stateData.selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
        <AddOrUpdateLead
          singleLeadData={contactInfo}
          onFormCompleteAction={(actionCode: string) => {
            setStateData(prev=> {
              return {
                ...prev,
                selectedViewCode: ''
              }
            });
          }}
          personType={contactInfo?.contactType?.contactType?.code}
          personTypeUuid={contactInfo?.contactType?.contactType?.id}
        />
      )}
    </View>
  );
};

export default withMiniContactViewHOC(CallLogDetails);
