import {Progress} from 'antd';
import {Text, View} from 'native-base';
import {
  GROUP_MEMBER_TYPE,
  QUALITY_MEASURES_PATIENT_TYPE,
} from '../../../../constants';
import {Colors} from '../../../../styles';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import GoalSvg from '../../../common/Svg/GoalSvg';
import GroupSvg from '../../../common/Svg/GroupSvg';
import './styles.css';
import {CARE_TEAM_MEMBER_TYPE} from '../../../../constants/MlovConst';
import {extractNumber} from './utils';

export const getContractsListColumns = (
  onActionPerformed: (code: string, data?: any) => void
) => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="contractName"
        />
      ),
      key: 'name',
      width: '25%',
      render: (contract: any) => {
        return (
          <Text size="smSemibold" color={Colors.FoldPixel.GRAY400}>
            {contract.name || ''}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="livesCovered"
        />
      ),
      key: 'livesCovered',
      width: '12.5%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <GroupSvg
              customStrokeColor={Colors.FoldPixel.GRAY200}
              width={16}
              height={16}
            />
            <Text size="smMedium" ml={1} color={Colors.FoldPixel.GRAY200}>
              {contract.livesCovered || ''}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="projectedRevenue"
        />
      ),
      key: 'projectedRevenue',
      width: '12.5%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text
              size="smMedium"
              ml={0.5}
              color={ contract.projectedRevenue ? Colors.FoldPixel.STATUS_DARK_SUCCESS : Colors.FoldPixel.GRAY400 }
            >
            {contract.projectedRevenue ? `$${contract.projectedRevenue}` : 0}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="performancePeriod"
        />
      ),
      key: 'startDate',
      width: '12%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {contract.startDate} - {contract.endDate}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="riskLevel"
        />
      ),
      key: 'riskLevel',
      width: '6%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {contract.riskLevel}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="contractType"
        />
      ),
      key: 'contractType',
      width: '18%',
      render: (contract: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {contract.contractType?.value || ''}
            </Text>
          </View>
        );
      },
    },
    
  ];
};

export const getQualityMeasuresListColumns = (
  onActionPerformed: (code: string, data?: any) => void
) => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="qualityMeasureName"
        />
      ),
      key: 'name',
      width: '30%',
      render: (qualityMeasures: any) => {
        return (
          <Text size="smSemibold" color={Colors.FoldPixel.GRAY400}>
            {qualityMeasures.name || ''}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="performanceRate"
        />
      ),
      key: 'performanceScore',
      width: '30%',
      render: (qualityMeasures: any) => {
        const goal = extractNumber(qualityMeasures.goal)
        const performanceScore = extractNumber(qualityMeasures.performanceScore)
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{width: '76%'}}>
              <div
                style={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: '#F6F7F8',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  position: 'relative',
                }}
              >
                <div className="fold-progress-container">
                  <div className="fold-progress-bar">
                    <div
                      className="fold-progress-fill"
                      style={{
                        width: `${goal}%`,
                        background: `repeating-linear-gradient(
                      135deg,
                      ${getProgressBarColor(
                        performanceScore || 0
                      )},
                      ${getProgressBarColor(
                        performanceScore || 0
                      )} 2.5px,
                      transparent 2.5px,
                      transparent 3.5px
                    )`,
                      }}
                    >
                      {' '}
                      <div className="fold-progress-overlay"></div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 8,
                    backgroundColor: getProgressBarColor(
                      performanceScore || 0
                    ),
                    width: `${performanceScore || 0}%`,
                    borderRadius: 4,
                    position: 'relative',
                  }}
                ></div>
                <div
                  style={{
                    height: 8,
                    backgroundColor: 'transparent',
                    width: `${100 - (performanceScore || 0)}%`,
                    borderRadius: 4,
                  }}
                ></div>
              </div>
            </View>
            <Text size="smMedium" ml={3} color={Colors.FoldPixel.GRAY200}>
              {performanceScore || 0}%
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="goal"
        />
      ),
      key: 'goal',
      width: '12%',
      render: (qualityMeasures: any) => {
        console.log(qualityMeasures)
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {qualityMeasures.goal}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="measureNotMet"
        />
      ),
      key: 'careGap',
      width: '12%',
      render: (qualityMeasures: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <GroupSvg
              customStrokeColor={Colors.FoldPixel.GRAY200}
              width={16}
              height={16}
            />
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {qualityMeasures.careGap || ''}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="totalMembers"
        />
      ),
      key: 'totalPatients',
      width: '12%',
      render: (qualityMeasures: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <GroupSvg
              customStrokeColor={Colors.FoldPixel.GRAY200}
              width={16}
              height={16}
            />
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {qualityMeasures.totalPatients || ''}
            </Text>
          </View>
        );
      },
    },
  ];
};
export const getQualifiedPatientList = (args: {memberType: string}) => {
  const {memberType} = args;
  const array = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      key: 'name',
      width: '25%',
      render: (data: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{marginRight: 8}}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '12',
                  width: 48,
                  height: 48,
                }}
                isLetterAvatarShow
                userData={{
                  userId: data?.contactData?.id || '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  contactType: data?.contactData?.memberType || '',
                  name: data?.contactData?.name,
                  userName: data?.contactData?.name || '',
                }}
              />
            </View>
            <View>
              <Text size="smSemibold" color={Colors.FoldPixel.GRAY400}>
                {data?.contactData?.name || ''}
              </Text>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
                  {data?.contactData?.memberType
                    ? `${data?.contactData?.memberType} • `
                    : ''}
                </Text>
                <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
                  {data?.contactData?.gender
                    ? `${data?.contactData?.gender} • `
                    : ''}
                </Text>
                <Text size="smMedium" color={Colors.FoldPixel.GRAY200}>
                  {data?.contactData?.dob || ''}
                  {data?.contactData?.age
                    ? ` (${data?.contactData?.age}) `
                    : ''}
                </Text>
              </View>
            </View>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="pcp"
        />
      ),
      key: 'pcp',
      width: '10%',
      render: (data: any) => {
        const pcpPractitioner = (
          data?.pcp?.careTeamMemberType || []
        ).find((careTeamMember: any) => {
          return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP;
        });
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {pcpPractitioner?.userName || ''}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="payer"
        />
      ),
      key: 'payer',
      width: '10%',
      render: (data: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text size="smMedium" ml={0.5} color={Colors.FoldPixel.GRAY200}>
              {data?.payer || ''}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId={ memberType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED ?  "reasonForMeetingCriteria" : 'reasonForNotMeetingCriteria'}
        />
      ),
      key: 'reason',
      width: '20%',
      render: (data: any) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text
              size="smMedium"
              ml={0.5}
              color={
                data?.reason
                  ? Colors.FoldPixel.STATUS_DARK_SUCCESS
                  : Colors.FoldPixel.STATUS_ERROR
              }
            >
              {data?.reason || 'No screening evidence found'}
            </Text>
          </View>
        );
      },
    },
  ];
  return array;
};

export const getProgressBarColor = (value: number) => {
  if (value <= 30) {
    return Colors.FoldPixel.STATUS_ERROR;
  } else if (value <= 75) {
    return Colors.FoldPixel.STATUS_IN_PROGRESS;
  } else if (value <= 100) {
    return Colors.FoldPixel.STATUS_DARK_SUCCESS;
  } else {
    return '#fff';
  }
};
