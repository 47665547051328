import React from 'react';
import {CommonDataContext} from '../../context/CommonDataContext';

export const useLocationGroupIdsByAccountLocationIds = (
  accountLocationIds: string[]
) => {
  const context = React.useContext(CommonDataContext);
  const {accountLocationListWithEHR} = context;
  const allAccountsList = accountLocationListWithEHR || [];

  const locationGroupIds = React.useMemo(
    () =>
      allAccountsList
        .filter((location) => accountLocationIds.includes(location.uuid))
        .map((accountLocation) => accountLocation.locationGroupId),
    [allAccountsList, accountLocationIds]
  );

  return locationGroupIds as string[];
};
