import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';

const SearchIconSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.size ? props?.size : "18"}
      height={props?.size ? props?.size : "18"}
      fill="none"
      viewBox="0 0 18 18"
    >
      <Path
        stroke={props?.customStrokeColor || "#667085"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props?.strokeWidth || "1.5"}
        d="M16.5 16.5l-3.625-3.625m1.958-4.708a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      ></Path>
    </Svg>
  );
};

export default SearchIconSvg;
