import { HStack,View,VStack } from 'native-base';
import { useEffect,useState } from 'react';
import CampaignAnalyticsSvg from '../../../assets/svg/CampaignAnalyticsSvg';
import ErrorEmailSvg from '../../../assets/svg/ErrorEmailSvg';
import ErrorSMSSvg from '../../../assets/svg/ErrorSMSSvg';
import ErrorSvg from '../../../assets/svg/ErrorSvg';
import RedDotSvg from '../../../assets/svg/RedDotSvg';
import { getAccountUUID } from '../../../utils/commonUtils';
import { getCampaignTitleByIds } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { COMMUNICATION_LOG_TAB } from './CommunicationLogChart';
import CommunicationLogChartMemberList from './CommunicationLogChartMemberList';
import { Spinner } from 'native-base';
import DataNotFoundSvg from '../../../assets/svg/DataNotFoundSvg';
import {useIntl} from 'react-intl';

const CampaignCommunicationAnalytics = (props: {
  startDate: string;
  endDate: string;
  campaignList: [
    {campaignId: string; smsFailedCount: number; emailFailedCount: number}
  ];
}) => {
  const tenantId = getAccountUUID();
  const intl = useIntl();
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [state, setState] = useState<any>({
    isLoading: false,
    selectedCampaignDetails: {
      isOpen: false,
      campaignId: '',
      campaignTitle: '',
      emailFailedCount: 0,
      smsFailedCount: 0,
    },
    campaignIdsMap: {},
  });

  const getCampaignData = async () => {
    const campaignIdList = props.campaignList
      .map((automation) => {
        return automation?.campaignId;
      })
      .filter((campaignId) => {
        return !!campaignId;
      });
    if (campaignIdList) {
      setState((oldData: any) => {
        return {...oldData, isLoading: true};
      });
      const campaignList = await getCampaignTitleByIds(
        campaignIdList,
        tenantId
      );
      const campaignIdsMap: any = {};
      campaignList.forEach((campaign: any) => {
        campaignIdsMap[campaign?.id] = campaign;
      });
      setState((oldData: any) => {
        return {...oldData, isLoading: false, campaignIdsMap};
      });
    }
  };

  useEffect(() => {
    getCampaignData();
  }, [props.campaignList, props.campaignList?.length]);

  useEffect(() => {
    const allDataEmpty = props.campaignList.every(
      (item) => item.emailFailedCount === 0 && item.smsFailedCount === 0
    );
    setNoDataAvailable(allDataEmpty);
  }, [props.campaignList]);

  return (
    <div style={{ margin: '2px 0' }}>
      {state.isLoading ? (
        <View
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Spinner />
        </View>
      ) :
        noDataAvailable ? (
          <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            overflow={'hidden'}
          >
            <DataNotFoundSvg />
            <span
              style={{
                marginTop: '16px',
                fontSize: '14px',
                color: '#8A94A8',
                fontWeight: '400',
              }}
            >
              {intl.formatMessage({id: 'noDataAlert'})}
            </span>
          </View>
        ) : (
          <>
            {state.selectedCampaignDetails?.isOpen ? (
              <CommunicationLogChartMemberList
                startDate={props.startDate}
                endDate={props.endDate}
                emailCount={state?.selectedCampaignDetails?.emailFailedCount || 0}
                smsCount={state?.selectedCampaignDetails?.smsFailedCount || 0}
                originService={COMMUNICATION_LOG_TAB.CAMPAIGN.KEY}
                communicationTypeList={state?.selectedCampaignDetails?.emailFailedCount ? ['EMAIL'] : ['SMS']}
                typeId={state.selectedCampaignDetails.campaignId}
                errorTitle={state.campaignIdsMap[state.selectedCampaignDetails.campaignId]?.name || ''}
                open={state.selectedCampaignDetails?.isOpen}
                setIsOpen={() => {
                  setState((oldValue: any) => {
                    return {
                      ...oldValue,
                      selectedCampaignDetails: {
                        isOpen: false,
                        campaignId: '',
                        campaignTitle: '',
                        emailFailedCount: 0,
                        smsFailedCount: 0,
                      },
                    };
                  });
                }}
              />
            ) : (
              <></>
            )}
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                {(props.campaignList || []).map((row, index) => (
                  <tr
                    onClick={() => {
                      setState((oldValue: any) => {
                        return {
                          ...oldValue,
                          selectedCampaignDetails: {
                            isOpen: true,
                            campaignId: row?.campaignId,
                            emailFailedCount: row.emailFailedCount,
                            smsFailedCount: row.smsFailedCount,
                          },
                        };
                      });
                    }}
                    key={index}
                    style={{
                      borderBottom: '1px solid #e0e0e0',
                      width: '100%',
                      paddingTop: index === 0 ? '0' : '8px',
                    }}
                  >
                    <td style={{ padding: '8px 0', width: '100%' }}>
                      <HStack space={4} alignItems="center">
                        <View display={'flex'} justifyContent={'center'} style={{ marginLeft: 10 }}>
                          <View style={{ padding: 8 }}>
                            <CampaignAnalyticsSvg />
                          </View>
                        </View>
                        <View>
                          <VStack>
                            <span
                              style={{
                                color: '#4A4A4A',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16.8px',
                              }}
                            >
                              {state.campaignIdsMap?.[row.campaignId]?.name || '-'}
                            </span>
                            <HStack alignItems="center" style={{ marginTop: '4px' }}>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ErrorSvg />
                              </div>
                              <span
                                style={{
                                  color: '#D92D20',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  lineHeight: '14.4px',
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {'Delivery Incomplete'}
                              </span>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <RedDotSvg />
                              </div>
                              {row.emailFailedCount ? (
                                <div
                                  style={{
                                    marginRight: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <ErrorEmailSvg />
                                  <span
                                    style={{
                                      color: '#D92D20',
                                      fontWeight: '400',
                                      fontSize: '12px',
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {row.emailFailedCount || 0}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                              {row.smsFailedCount ? (
                                <div
                                  style={{
                                    marginRight: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <ErrorSMSSvg />
                                  <span
                                    style={{
                                      color: '#D92D20',
                                      fontWeight: '400',
                                      fontSize: '12px',
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {row.smsFailedCount || 0}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </HStack>
                          </VStack>
                        </View>
                      </HStack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
    </div>
  );
};

export default CampaignCommunicationAnalytics;