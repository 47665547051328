import React from 'react';
import {TASK_PRIORITY_CODES} from '../../constants/MlovConst';
import HighSeveritySvg from '../../components/common/Svg/SideCarSvg/HighSeveritySvg';
import MediumSeveritySvg from '../../components/common/Svg/SideCarSvg/MediumSeveritySvg';
import LowSeveritySvg from '../../components/common/Svg/SideCarSvg/LowSeveritySvg';

const AlertSeverityIcon = (props: {priorityCode: string}) => {
  switch (props.priorityCode) {
    case TASK_PRIORITY_CODES.HIGH:
      return <HighSeveritySvg />;
    case TASK_PRIORITY_CODES.MEDIUM:
      return <MediumSeveritySvg />;
    case TASK_PRIORITY_CODES.LOW:
      return <LowSeveritySvg />;
    default:
      return <></>;
  }
};

export default AlertSeverityIcon;
