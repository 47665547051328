import {Path, Svg} from 'react-native-svg';

const RefreshSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <Path
        fill="#6F7A90"
        d="M2.066 7.444h-.5.5zm0 1.39l-.352.354a.5.5 0 00.705 0l-.353-.355zm1.752-1.035a.5.5 0 10-.704-.71l.704.71zm-2.8-.71a.5.5 0 00-.704.71l.705-.71zM14.62 4.096a.5.5 0 10.852-.524l-.852.524zm1.31 3.072l.35-.356a.5.5 0 00-.702 0l.351.356zM14.17 8.2a.5.5 0 00.703.711l-.703-.711zm2.81.711a.5.5 0 00.704-.711l-.703.711zM3.326 11.904a.5.5 0 10-.85.525l.85-.525zM9.065 0C4.929 0 1.567 3.33 1.567 7.444h1C2.566 3.89 5.472 1 9.066 1V0zM1.567 7.444v1.39h1v-1.39h-1zm.853 1.744l1.4-1.389-.705-.71-1.4 1.39.705.71zm0-.71l-1.4-1.388-.705.71 1.4 1.388.705-.71zM15.47 3.571A7.507 7.507 0 009.066 0v1a6.507 6.507 0 015.553 3.095l.852-.524zM8.902 16c4.151 0 7.526-3.328 7.526-7.444h-1c0 3.553-2.916 6.444-6.526 6.444v1zm7.526-7.444v-1.39h-1v1.39h1zm-.851-1.745L14.17 8.2l.703.711 1.406-1.389-.703-.71zm0 .711l1.405 1.39.703-.712L16.28 6.81l-.703.711zM2.474 12.43A7.54 7.54 0 008.902 16v-1a6.54 6.54 0 01-5.577-3.096l-.85.525z"
      ></Path>
    </Svg>
  );
};

export default RefreshSvg;