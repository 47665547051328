import React from 'react';
import {ICommonSvgProps} from './interfaces';


const AssignedUsersSvg = (props: ICommonSvgProps) => {
    const {customStrokeColor} = props;
    const strokeColor = customStrokeColor ? customStrokeColor : '#6F7A90';
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props?.width || 24}
        height={props?.height || 24}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={strokeColor}
          d="M17.0506 15.6799C16.8738 15.4678 16.5585 15.4391 16.3464 15.6159C16.1343 15.7927 16.1056 16.108 16.2824 16.3201L17.0506 15.6799ZM17.5 17L17.1159 17.3201C17.2067 17.429 17.3394 17.4943 17.4811 17.4996C17.6228 17.505 17.7601 17.45 17.8588 17.3482L17.5 17ZM19.692 15.4593C19.8843 15.2612 19.8796 14.9446 19.6814 14.7523C19.4832 14.56 19.1667 14.5647 18.9744 14.7629L19.692 15.4593ZM15.5 6C15.5 7.933 13.933 9.5 12 9.5V10.5C14.4853 10.5 16.5 8.48528 16.5 6H15.5ZM12 9.5C10.067 9.5 8.5 7.933 8.5 6H7.5C7.5 8.48528 9.51472 10.5 12 10.5V9.5ZM8.5 6C8.5 4.067 10.067 2.5 12 2.5V1.5C9.51472 1.5 7.5 3.51472 7.5 6H8.5ZM12 2.5C13.933 2.5 15.5 4.067 15.5 6H16.5C16.5 3.51472 14.4853 1.5 12 1.5V2.5ZM21.5 16C21.5 17.933 19.933 19.5 18 19.5V20.5C20.4853 20.5 22.5 18.4853 22.5 16H21.5ZM18 19.5C16.067 19.5 14.5 17.933 14.5 16H13.5C13.5 18.4853 15.5147 20.5 18 20.5V19.5ZM14.5 16C14.5 14.067 16.067 12.5 18 12.5V11.5C15.5147 11.5 13.5 13.5147 13.5 16H14.5ZM18 12.5C19.933 12.5 21.5 14.067 21.5 16H22.5C22.5 13.5147 20.4853 11.5 18 11.5V12.5ZM16.2824 16.3201L17.1159 17.3201L17.8841 16.6799L17.0506 15.6799L16.2824 16.3201ZM17.8588 17.3482L19.692 15.4593L18.9744 14.7629L17.1412 16.6518L17.8588 17.3482ZM12 21.5C10.0172 21.5 8.55383 21.3748 7.47419 21.1563C6.39235 20.9373 5.72941 20.6312 5.31573 20.2943C4.52688 19.6519 4.5 18.7861 4.5 17.5H3.5C3.5 18.6992 3.47312 20.0834 4.68427 21.0697C5.27059 21.5471 6.10765 21.9 7.27581 22.1364C8.44617 22.3733 9.98282 22.5 12 22.5V21.5ZM4.5 17.5C4.5 16.5182 5.21273 15.5276 6.58828 14.7538C7.94815 13.9889 9.86047 13.5 12 13.5V12.5C9.72125 12.5 7.63357 13.0185 6.09802 13.8822C4.57813 14.7372 3.5 15.9965 3.5 17.5H4.5ZM12 13.5C13.0248 13.5 14.0002 13.6123 14.889 13.8146L15.111 12.8396C14.1469 12.6201 13.097 12.5 12 12.5V13.5ZM19.3306 19.3473C19.147 19.9198 18.7447 20.442 17.6835 20.8371C16.5877 21.245 14.8275 21.5 12 21.5V22.5C14.86 22.5 16.7655 22.2459 18.0324 21.7742C19.3339 21.2897 19.9913 20.5619 20.2829 19.6527L19.3306 19.3473Z"
        ></path>
      </svg>
    );
  };
  
  export default AssignedUsersSvg;
  