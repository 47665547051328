import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import { IUser } from '../../../../Interfaces';
import { ITimezone, IUserPracticeLocation } from '../../../../services/Location/interfaces';
import { IAddOrUpdateAvailability, IAvailabilitiesByDateRangeKey, IAvailabilitiesByDaysOfWeek, IAvailabilitiesBySingleWeekDay, IAvailabilityCalendarData, IAvailabilityCalendarTableData, IAvailabilityCalendarTablePrimaryData, IPracticeAvailability, IUserAvailability } from './PracticeAvailabilityInterfaces';
import { VIRTUAL_LOCATION_CODE } from './PracticeAvailability';
import { v4 as uuidV4 } from 'uuid';
import { extractAccountLocationsFromUserPracticeLocations } from '../../../../utils/commonUtils';
import { getDateObjectFromStringAndFormat } from '../../../../utils/DateUtils';
import { AVAILABILITY_CONST, DATE_FORMATS } from '../../../../constants';


export const AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
};

export const AVAILABILITY_CALENDAR_WEEK_COLUMN_SEQUENCE = [
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SUNDAY,
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.MONDAY,
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.TUESDAY,
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.WEDNESDAY,
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.THURSDAY,
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.FRIDAY,
  AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SATURDAY,
];

export const AVAILABILITY_CALENDAR_WEEK_COLUMN_VALUES = {
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SUNDAY]: 'Sunday',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.MONDAY]: 'Monday',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.TUESDAY]: 'Tuesday',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.WEDNESDAY]: 'Wednesday',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.THURSDAY]: 'Thursday',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.FRIDAY]: 'Friday',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SATURDAY]: 'Saturday',
};

export const AVAILABILITY_CALENDAR_WEEK_CODE_BY_INDEX = {
  '0': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SUNDAY,
  '1': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.MONDAY,
  '2': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.TUESDAY,
  '3': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.WEDNESDAY,
  '4': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.THURSDAY,
  '5': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.FRIDAY,
  '6': AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SATURDAY,
} as  { [index: string]: string};

export const AVAILABILITY_CALENDAR_WEEK_INDEX_BY_CODE = {
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SUNDAY]: '0',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.MONDAY]: '1',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.TUESDAY]: '2',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.WEDNESDAY]: '3',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.THURSDAY]: '4',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.FRIDAY]: '5',
  [AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES.SATURDAY]: '6',
};

export const getDefaultTimezone = (
  accountTimezone?: ITimezone,
  timezones?: ITimezone[]
) => {
  if (accountTimezone) {
    return accountTimezone;
  }
  if (!timezones?.length) return;

  const defaultTimezoneValue = 'America/New_York';
  let edtTimezone: ITimezone | undefined = undefined;

  const defaultTimezone = timezones.find((timezone: ITimezone) => {
    if (defaultTimezoneValue && defaultTimezoneValue === timezone.timezone)
      return true;
    if (timezone.abbreviations === 'EDT') edtTimezone = timezone;
  });

  return (defaultTimezone || edtTimezone);
};

export function createAvailabilityCalendarGroupData(data: {
  practiceAvailabilities?: IPracticeAvailability[];
  timezones: ITimezone[],
  accountLocations?: IAccountLocation[];
  accountUsers?: IUser[];
  isUserSchedule: boolean;
}): IAvailabilityCalendarData[] {
  if (!data.practiceAvailabilities?.length) {
    return [];
  }
  data.practiceAvailabilities = data.practiceAvailabilities.map((availability) => {
    if (availability.daysOfWeek) {
      availability.daysOfWeek = JSON.parse(JSON.stringify(availability.daysOfWeek));
    }
    if (availability.timezoneId) {
      availability.timezone = data.timezones.find((timezone) => (timezone.uuid === availability.timezoneId));
    }
    return availability;
  });
  if (data.isUserSchedule) {
    return createAvailabilityCalendarGroupDataByUser(data);
  } else {
    return createAvailabilityCalendarGroupDataByLocation(data);
  }
}

function createAvailabilityCalendarGroupDataByUser(data: {
  practiceAvailabilities?: IPracticeAvailability[];
  accountLocations?: IAccountLocation[];
  accountUsers?: IUser[];
}) {
  if (!data.accountUsers?.length) {
    return [];
  }

  const { accountLocationsIds, accountLocationDataById } = getLocationsById(data.accountLocations);

  const practiceAvailabilities: IUserAvailability[] = (data.practiceAvailabilities || []).filter((availability) => {
    if (availability.locationId && !accountLocationsIds.includes(availability.locationId)) {
      return;
    }
    if (availability.locationId) {
      (availability as IUserAvailability).locationData = accountLocationDataById[availability.locationId];
    }
    return !availability.userId && !availability.beginDate && !availability.endDate;
  });

  const userWiseAvailabilities = data.accountUsers.reduce((userWiseAvailabilities, user) => {
    const userId = user.uuid;
    const userAccountLocations = extractAccountLocationsFromUserPracticeLocations(user.userPracticeLocations?.filter((userLocation)=>{
      return userLocation.accountLocation?.practiceLocation !== null
    }) || []);
    const {
      accountLocationsIds: userAccountLocationsIds,
      accountLocationDataById: userAccountLocationDataById
    } = getLocationsById(userAccountLocations);

    if (!userId) {
      return userWiseAvailabilities;
    }

    const currentUserWiseAvailabilities = {
      availabilities: [] as IUserAvailability[],
      overriddenAvailabilities: [] as IUserAvailability[],
      isPracticeScheduleApplied: false,
    };

    (data.practiceAvailabilities || []).forEach((availability) => {
      if (availability.userId !== userId) {
        return;
      }

      const locationId = availability.locationId;
      if (locationId && !userAccountLocationsIds.includes(locationId)) {
        return;
      }

      const isVirtualLocation = !availability.locationId && availability.isVirtualLocation;

      if (!isVirtualLocation && !locationId) {
        return;
      }

      const isOverrideAvailability = availability.beginDate && availability.endDate;

      // ignore invalid availability
      if (!isOverrideAvailability && (availability.beginDate || availability.endDate)) {
        return;
      }

      const userAvailability: IUserAvailability = availability;
      if (locationId) {
        userAvailability.locationData = userAccountLocationDataById[locationId];
      }

      if (isVirtualLocation) {
        userAvailability.locationId = VIRTUAL_LOCATION_CODE;
      }

      if (isOverrideAvailability) {
        currentUserWiseAvailabilities.overriddenAvailabilities.push(userAvailability);
      } else {
        currentUserWiseAvailabilities.availabilities.push(userAvailability);
      }
    });

    if (!currentUserWiseAvailabilities.availabilities.length) {
      currentUserWiseAvailabilities.isPracticeScheduleApplied = true;
      currentUserWiseAvailabilities.availabilities = (practiceAvailabilities || []).filter((availability) => {
        const locationId = availability.locationId;
        if (locationId && !userAccountLocationsIds.includes(locationId)) {
          return false;
        }

        const isVirtualLocation = !availability.locationId && availability.isVirtualLocation;
        if (!isVirtualLocation && !locationId) {
          return false;
        }

        return true;
      });
    }

    userWiseAvailabilities[userId] = currentUserWiseAvailabilities;
    return userWiseAvailabilities;

  }, {} as {
    [index: string]: {
      availabilities: IUserAvailability[],
      overriddenAvailabilities: IUserAvailability[],
      isPracticeScheduleApplied: boolean,
    }
  });

  const availabilityCalendarGroupData: IAvailabilityCalendarData[] = data.accountUsers.map((accountUser) => {
    const userId = accountUser.uuid;
    const isPracticeScheduleApplied = userWiseAvailabilities[userId].isPracticeScheduleApplied;
    const updatedAccountUser = {...accountUser}
    const updatedPracticeLocations = accountUser.userPracticeLocations?.filter((userLocation: IUserPracticeLocation)=>{
      return userLocation.accountLocation?.practiceLocation !== null
    })
    updatedAccountUser.userPracticeLocations = [
      ...(updatedPracticeLocations || []),
    ];
    return {
      isUserSchedule: true,
      userId: userId,
      userData: updatedAccountUser,
      availabilities: userWiseAvailabilities[userId].availabilities,
      isPracticeScheduleApplied: userWiseAvailabilities[userId].isPracticeScheduleApplied,
      availabilitiesBySingleWeekDay: groupAvailabilitiesBySingleWeekDay(userWiseAvailabilities[userId].availabilities),
      availabilitiesByDaysOfWeek: groupAvailabilitiesByDaysOfWeek(userWiseAvailabilities[userId].availabilities, isPracticeScheduleApplied),
      overriddenAvailabilitiesByDateRangeKey: groupAvailabilitiesByDateRangeKey(userWiseAvailabilities[userId].overriddenAvailabilities),
    };
  });

  return availabilityCalendarGroupData;
}

function createAvailabilityCalendarGroupDataByLocation(data: {
  practiceAvailabilities?: IPracticeAvailability[];
  accountLocations?: IAccountLocation[];
}) {
  if (!data.practiceAvailabilities?.length) {
    return [];
  }

  const { accountLocationsIds, accountLocationDataById } = getLocationsById(data.accountLocations);

  const locationWiseAvailabilities = data.practiceAvailabilities.reduce((locationWiseAvailabilities, availability) => {
    let locationId = availability.locationId;

    if (locationId && !accountLocationsIds.includes(locationId)) {
      return locationWiseAvailabilities;
    }

    const isVirtualLocation = !availability.locationId && availability.isVirtualLocation;

    if (!isVirtualLocation && !locationId) {
      return locationWiseAvailabilities;
    }

    if (isVirtualLocation) {
      locationId = VIRTUAL_LOCATION_CODE;
    }

    if (!locationId) {
      return locationWiseAvailabilities;
    }

    if (!locationWiseAvailabilities[locationId]) {
      locationWiseAvailabilities[locationId] = [];
    }

    locationWiseAvailabilities[locationId].push(availability);

    return locationWiseAvailabilities;
  }, {} as { [index: string]: IPracticeAvailability[]; });



  const availabilityCalendarGroupData: IAvailabilityCalendarData[] = [];

  for (const locationId in locationWiseAvailabilities) {
    const data = {
      isUserSchedule: false,
      locationId: locationId,
      locationData: accountLocationDataById[locationId],
      availabilities: locationWiseAvailabilities[locationId],
      availabilitiesBySingleWeekDay: groupAvailabilitiesBySingleWeekDay(locationWiseAvailabilities[locationId]),
      availabilitiesByDaysOfWeek: groupAvailabilitiesByDaysOfWeek(locationWiseAvailabilities[locationId], true),
      // overriddenAvailabilitiesByDateRangeKey: { isAvailabilitiesPresent: false, data: {} },
    };

    if (locationId === VIRTUAL_LOCATION_CODE) {
      data.locationData = {
        uuid: VIRTUAL_LOCATION_CODE,
        locationGroupId: undefined,
        practiceLocation: {
          id: -1,
          uuid: VIRTUAL_LOCATION_CODE,
          name: 'Virtual',
        },
      };
      availabilityCalendarGroupData.unshift(data);
    } else {
      availabilityCalendarGroupData.push(data);
    }
  }

  return availabilityCalendarGroupData;
}

function getLocationsById(accountLocations?: IAccountLocation[]) {
  const { accountLocationsIds, accountLocationDataById } = (accountLocations || []).reduce((accountLocationData, location) => {
    if (location.uuid) {
      accountLocationData.accountLocationDataById[location.uuid] = location;
      accountLocationData.accountLocationsIds.push(location.uuid);
    }
    return accountLocationData;
  }, {
    accountLocationsIds: [] as string[],
    accountLocationDataById: {} as {[index: string]: IAccountLocation}
  });

  return {
    accountLocationsIds,
    accountLocationDataById,
  };
}

function groupAvailabilitiesBySingleWeekDay(availabilities: IPracticeAvailability[]) {
  const availabilitiesBySingleWeekDay: IAvailabilitiesBySingleWeekDay = {
    isAvailabilitiesPresent: false,
    data: {},
  };

  if (!availabilities?.length) {
    return availabilitiesBySingleWeekDay;
  }

  availabilities.forEach((availability) => {
    if (!availability.daysOfWeek) {
      return;
    }

    availabilitiesBySingleWeekDay.isAvailabilitiesPresent = true;
    const availableDays: number[] = JSON.parse(availability.daysOfWeek);

    availableDays.forEach((availableDay) => {
      const weekDayKey = AVAILABILITY_CALENDAR_WEEK_CODE_BY_INDEX[availableDay.toString()];
      if (!availabilitiesBySingleWeekDay.data[weekDayKey]) {
        availabilitiesBySingleWeekDay.data[weekDayKey] = [];
      }
      availabilitiesBySingleWeekDay.data[weekDayKey].push(availability);
    });
  });

  return availabilitiesBySingleWeekDay;
}

export function groupAvailabilitiesByDateRangeKey(availabilities: IPracticeAvailability[]) {
  const availabilitiesByDateRangeKey: IAvailabilitiesByDateRangeKey = {
    isAvailabilitiesPresent: false,
    data: [],
  };

  if (!availabilities?.length) {
    return availabilitiesByDateRangeKey;
  }

  availabilities.forEach((availability) => {
    if (!availability.beginDate || !availability.endDate || !availability.timezoneId) {
      return;
    }

    const beginDate = getDateObjectFromStringAndFormat(availability.beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
    const endDate = getDateObjectFromStringAndFormat(availability.endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
    const timezoneId = availability.timezoneId;
    const key = `${beginDate.toISOString()}-${endDate.toISOString()}-${timezoneId}`;

    let data = availabilitiesByDateRangeKey.data.find((item) => {
      if (item.localId === key) {
        return true;
      }
    });

    if (!data) {
      data = {
        localId: key,
        beginDate,
        endDate,
        timezoneId,
        availabilities: [],
      };
      availabilitiesByDateRangeKey.data.push(data);
    }

    const addOrUpdateAvailability: IAddOrUpdateAvailability = {
      ...availability,
      localId: uuidV4(),
      isOverrideAvailability: true,
    };

    availabilitiesByDateRangeKey.isAvailabilitiesPresent = true;
    data.availabilities.push(addOrUpdateAvailability);
  });

  return availabilitiesByDateRangeKey;
}

export function groupAvailabilitiesByDaysOfWeek(availabilities: IPracticeAvailability[], isPracticeScheduleApplied: boolean) {
  const availabilitiesByDaysOfWeek: IAvailabilitiesByDaysOfWeek = {
    isAvailabilitiesPresent: false,
    isPracticeScheduleApplied: isPracticeScheduleApplied,
    data: [],
  };

  if (!availabilities?.length) {
    return availabilitiesByDaysOfWeek;
  }

  availabilities.forEach((availability) => {
    if (!availability.daysOfWeek || !availability.timezoneId) {
      return;
    }

    availabilitiesByDaysOfWeek.isAvailabilitiesPresent = true;

    const selectedDaysOfWeek = JSON.parse(availability.daysOfWeek);
    const timezoneId = availability.timezoneId;
    const key = `${JSON.stringify(selectedDaysOfWeek)}-${timezoneId}`;

    let data = availabilitiesByDaysOfWeek.data.find((item) => {
      if (item.localId === key) {
        return true;
      }
    })

    if (!data) {
      data = {
        localId: key,
        selectedDaysOfWeek: selectedDaysOfWeek,
        timezoneId: timezoneId,
        availabilities: [],
      };
      availabilitiesByDaysOfWeek.data.push(data);
    }

    const addOrUpdateAvailability: IAddOrUpdateAvailability = {
      ...availability,
      localId: uuidV4(),
    };

    data.availabilities.push(addOrUpdateAvailability);
  });

  return availabilitiesByDaysOfWeek;
}

export function convertAvailabilityCalendarDataIntoTableData(calendarGroupData: IAvailabilityCalendarData[]) {
  const tableData: IAvailabilityCalendarTableData[] =  (calendarGroupData || []).map((data) => {
    const object: any = {
      key: uuidV4(),
      primaryData: {
        isUserSchedule: data.isUserSchedule,
        isPracticeScheduleApplied: data.isPracticeScheduleApplied,
        locationData: data.locationData,
        locationId: data.locationId,
        userData: data.userData,
        userId: data.userId,
        overriddenAvailabilitiesByDateRangeKey: data.overriddenAvailabilitiesByDateRangeKey,
      } as IAvailabilityCalendarTablePrimaryData,
    };

    Object.keys(AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES).forEach((weekDayKey) => {
      object[weekDayKey] = (data.availabilitiesBySingleWeekDay.data[weekDayKey] || []);
    });

    return object;
  });

  return tableData;
}


export function isAvailabilitiesPresent(availabilitiesByDaysOfWeek: IAvailabilitiesByDaysOfWeek): boolean {
  if (!availabilitiesByDaysOfWeek?.data?.length) {
    return false;
  }

  const data = availabilitiesByDaysOfWeek?.data.find((data) => {
    if (!data?.availabilities?.length) {
      return false;
    }

    const availability = data?.availabilities.find((availability) => {
      if  (availability && !availability.isDeleted) {
        return true;
      }
    });

    if (availability) {
      return true;
    }
  });

  if (data) {
    return true;
  }
  return false;
}

export function areAvailabilitiesInvalid(availabilities: IAddOrUpdateAvailability[]): boolean {
  if (!availabilities?.length) {
    return false;
  }

  for (const availability of availabilities) {
    const isInvalid = isAvailabilityInvalid(availability);
    if (isInvalid) {
      return true;
    }
  }

  return false;
}

export function isAvailabilityInvalid(availability: IAddOrUpdateAvailability): boolean {
  if (availability?.isDeleted) {
    return false;
  }
  if (availability.isOverrideAvailability && (!availability.beginDate || !availability.endDate)) {
    return true;
  }
  const isInvalid =
    !availability.startTime ||
    !availability.endTime ||
    (availability.daysOfWeek === '[]' && !availability.isOverrideAvailability) ||
    !availability.timezoneId ||
    (!availability.isVirtualLocation && !availability.locationId);

  return isInvalid;
}


export const filterDeletedAvailabilities = (availabilities: IAddOrUpdateAvailability[]) => {
  return availabilities.filter((availability) => !availability.isDeleted);
};

export const getAccountLocationIds = (accountLocations: IAccountLocation[]) => {
  return accountLocations.map((location) => location.uuid);
};

export const markAllDeleted = (availabilities: IAddOrUpdateAvailability[]) => {
  return availabilities.map((availability) => ({
    ...availability,
    isDeleted: availability.locationId === AVAILABILITY_CONST.VIRTUAL_LOCATION ? false : true,
  }));
};

export const updateAvailabilityStatus = (availabilities: IAddOrUpdateAvailability[], accountLocationIds: string[]) => {
  return availabilities.map((availability) => ({
    ...availability,
    isDeleted: availability.locationId === AVAILABILITY_CONST.VIRTUAL_LOCATION ? false : !accountLocationIds.includes(availability.locationId || ''),
  }));
};

export const processAvailabilities = (availabilities: IAddOrUpdateAvailability[], accountLocations: IAccountLocation[]) => {
  const filteredAvailabilities = filterDeletedAvailabilities(availabilities);
  const accountLocationIds = getAccountLocationIds(accountLocations);
  return accountLocations.length
    ? updateAvailabilityStatus(filteredAvailabilities, accountLocationIds)
    : markAllDeleted(filteredAvailabilities);
};

