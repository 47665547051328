import {
  Button,
  Center,
  Divider,
  FlatList,
  HStack,
  Icon,
  IconButton,
  Pressable,
  ScrollView,
  Skeleton,
  Spacer,
  Spinner,
  Text,
  useToast,
  View,
  VStack,
  Tooltip
} from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Content } from 'antd/lib/layout/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image, Modal, notification, UploadFile } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { IDocument, IDocumentAttachment } from '../../../../services/Document/DocumentInterfaces';
import { IMediaLibraryData } from '../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import { IPatientDocumentSelectedFilters } from '../../LeftContainer/OtherDetails/PatientDocuments/FilterPatientDocumentViewDrawer';
import CareStudioService from '../../../../services/CommonService/CareStudioService';
import { CARESTUDIO_APOLLO_CONTEXT, PATIENT_EDUCATION_URL } from '../../../../constants/Configs';
import { getCareStudioMlovListByCategoryCode } from '../../../../utils/mlovUtils';
import { DATE_FORMATS, RIGHT_SIDE_CONTAINER_CODE, SORT_BY, SORT_ORDER } from '../../../../constants';
import * as DocumentQueries from '../../../../services/Document/DocumentQueries';
import { IContact } from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { Colors } from '../../../../styles';
import { WORD_DOC_MIME_TYPES } from '../../../common/DocumentViewer/DocumentViewerHelper';
import { getBase64, UploadPatientDocument } from '../../LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { NoTagsSvg } from '../../../common/Svg';
import FHActionPopover from '../../../common/FHActionPopover/FHActionPopover';
import { tooltipInnerOverlayStyle, tooltipPlacement } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { DocumentViewer } from '../../../common/DocumentViewer/DocumentViewer';
import { DeleteConfirmationDrawer } from '../../../common/DeleteConfirmationDrawer/DeleteConfirmationDrawer';
import { SearchBar } from '../../../common/SearchBar';
import SortIcon from '../../../common/Svg/SortIcon';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { CARE_PLAN_SUB_CATEGORY, DOCUMENT_CATEGORY_CODES, MAX_DOC_TITLE_CHAR_LIMIT } from './DocumentCategoryConstants';
import { CareJourneyQueries } from '../../../../services';
import FolderIcon from '../../../common/Svg/FolderIcon';
import RightArrowIconForDocuments from '../../../common/Svg/RightArrowIconForDocuments';
import { getDateObjectFromAPIFormatToMyFormat} from '../../../../utils/DateUtils';
import DocumentCategoryIcon from '../../../common/Svg/DocumentCategoryIcon';
import { IJourneyDetails } from './DocumentUtils';
import CreateEFaxConversationDrawer from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawer';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import {EmailDrawerCommonV2} from '../../../common/EmailDrawerCommonV2';
import {IDocumentData} from '../../../common/EmailDrawerCommonV2/interfaces';
import { IPersonData } from '../interfaces';
import { getEhrConfig, getResourceAbilities, isMarkAsReviewEnabled } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { ReportCategory } from '../Orders/OrdersAndReports/OrderUtils';
import { IPendingReportDetails, IReportAndResourceMap } from '../../LeftContainer/RecentActivity/OrdersInterface';
import { DiagnosticReport, RequestGroup } from 'fhir/r4';
import OrderDetailsDrawer from '../Orders/OrdersAndReports/OrderDetailsDrawer/OrderDetailsDrawer';
import { getReportForImaging } from '../../LeftContainer/RecentActivity/helper';
import { getReportResultForDisplay, isAllReportViewedBy } from '../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { ReportResultView } from '../../LeftContainer/RecentActivity/RecentReport/ReportResultView';
import EmailDrawerCommon from '../../../common/EmailPopupView/EmailDrawerCommon';
import CreateSmsConversationDrawer from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import MeetingView from '../../../common/MeetingView/MeetingView';
import InstantChat from '../../../common/ChatDrawer/InstantChat';
import { getFormDataFromLeadData } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import useReports from '../../../TaskModule/CustomHooks/useReports';

type IDocumentCategory = {
  id: string;
  isDefault: boolean;
  code: string;
  value: string;
  description?: string;
}
type IProps = {
  contactUuid: string;
  category: IDocumentCategory;
  documentsCategories: IDocumentCategory[];
  contactName: string;
  contactId: number;
  personData?: IPersonData;
  unFormattedContactData?: any;
};

type SortingState = 'none' | 'asc' | 'desc';



export function DocumentsList(props: IProps) {
  const mlovData = useContext(CommonDataContext);
  const ehrConfig = getEhrConfig(props?.personData?.accountLocationUuid, '');
  const [searchText, setSearchText] = useState('');
  const labOrderAbilitity = getResourceAbilities(
    FHIR_RESOURCE.DIAGNOSTIC_REPORT,
    '',
    props?.personData?.accountLocationUuid
  );
  const canShowMarkAsReview = isMarkAsReviewEnabled(props?.personData?.accountLocationUuid);
  const {
    fetchReports,
    loading,
    error,
    fetchAndHandleDataForImagingReport,
    fetchAndHandleDataForAllDoc,
    paginationState,
    setPaginationState,
    componentState,
    setComponentState,
    currentPage,
    setCurrentPage,
  } = useReports({
    patientId: props?.personData?.patientId,
    contactUuid: props?.personData?.contactUUID || '',
    accountLocationUuid: props?.personData?.accountLocationUuid || '',
    isPagination: labOrderAbilitity?.usePagination,
    canShowMarkAsReview: canShowMarkAsReview,
    type: ReportCategory?.IMAGING
});

  const toast = useToast();
  const [reportDetail, setReportDetail] = useState<IPendingReportDetails>({
    loading: false,
    userAPILoading: false,
    reportLoadingKey: undefined,
    showModal: false,
    showDetail: false,
    showImagingResultTable: false,
    showDraftOrders: false,
    showAddNewOrderModal: false,
    orders: [],
    activeOrders: [],
    draftOrders: [],
    reports: [],
    labReports: [],
    radReports: [],
    medications: [],
    selectedReport: {
      reportName: '',
      observation: [],
      resources: [],
      isReportReviewedByUser: false,
    },
    selectedOrder: {},
    reportResultViewLoading: false,
  });
  const setInitialcomponentState = () => {
    setComponentState({
      documentList: [],
      isAddDocumentModalOpen: false,
      isFilterModalOpen: false,
      isDeleteConfirmationOpen: false,
      isLoading: true,
      deleteDocumentLoading: {},
      isFileLoading: {},
      isPreviewVisible: false,
      previewTitle: undefined,
      searchString: '',
      aggregateDocumentCount: 0,
      nameSort: 'none',
      dateSort: 'none',
      careJourneyIdsHavingDocuments: [],
      careJourneyDetailsHavingDocuments: [],
      filteredCareJourneyDetailsHavingDocuments: [],
      selectedJourney: undefined,
      isEFaxDrawerOpen: false,
      isEmailDrawerOpen: false,
      base64DataUploadedDoc: '',
      allDocMergedSortedList:[],
      imagingAllReports: [],
      isImagingReportDesc: false,
      isImagingReportAscByName: false,
      isAllDocDescByDate: false,
      isAllDocAscByName: false,
    }
    )
    setPaginationState({
      pageSize: 10,
      isLoading: false,
      offset: 0,
    })
  };

  const JOURNEY_DOCUMENT_RENDER = "journey_document_render";

  const sortStateMap: Record<SortingState, SortingState> = {
    none: 'asc',
    asc: 'desc',
    desc: 'none',
  };

  const contactDetails = props?.personData;
    let formattedContactData: any = '';
    if (contactDetails) {
      contactDetails.uuid = props?.personData?.contactUUID
      formattedContactData = getFormDataFromLeadData(contactDetails || {}, mlovData);
    }
  const isAllDocument = props?.category?.id === DOCUMENT_CATEGORY_CODES?.ALL_DOCUMENTS;
  const isImagingReport = props?.category?.code === DOCUMENT_CATEGORY_CODES?.IMAGING_REPORTS;
  const isAthenaAndAllDocumentSection = ehrConfig.isAthena && isAllDocument;
  const isAthenaAndImagingReportSection = ehrConfig.isAthena && isImagingReport;

  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);

  const isAnyFileLoading = (obj: Record<string, boolean>) => {
    const value = Object.keys(obj).find(key => {
      const isLoading = obj[key]
      return isLoading
    })
    return !!value
  }

  const [getContactDocumentsQuery, documentsQuery] = useLazyQuery(
    DocumentQueries.GET_PATIENT_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (response) => {
        const documentList = response?.getDocuments?.documents ? paginationState?.offset > 0 ? [...componentState.documentList, ...response?.getDocuments?.documents] : [...response?.getDocuments?.documents] : []
        let filteredJourneys: undefined | IJourneyDetails[] = undefined;
        if (searchText) {
          if (componentState?.selectedJourney) {
            filteredJourneys = [];
          }
          else filteredJourneys = componentState?.careJourneyDetailsHavingDocuments?.filter((journey: any) => { return journey.title?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) });
        }
        else filteredJourneys = componentState?.careJourneyDetailsHavingDocuments;
        setComponentState((prev) => {
          return ({
            ...prev,
            documentList: documentList,
            aggregateDocumentCount: response?.getDocuments?.aggregate?.total,
            ...(filteredJourneys && { filteredCareJourneyDetailsHavingDocuments: filteredJourneys }),
            isLoading: false,
          })
        });
        setPaginationState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      },
      onError: (error) => {
        setComponentState((prev) => ({ ...prev, isLoading: false }));
        setPaginationState((prev) => ({
          ...prev,
          isLoading: false
        }))
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
      },
    }
  );

  const [getJourneyIdsOfPatientHavingDocumentsInJourney, journeyIdsQuery] = useLazyQuery(
    DocumentQueries.GET_JOURNEYIDS_OF_PATIENT_HAVING_DOCUMENTS_IN_JOURNEY,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: async (response) => {
        if (response?.documents?.length > 0) {
          const careJourneyIds = response?.documents?.map((document: any) => document?.patientCareJourneyId);
          setComponentState((prev) => {
            return ({
              ...prev,
              careJourneyIdsHavingDocuments: careJourneyIds,
            })
          });
          await getPatientJourneyDetails({
            variables: {
              journeyIds: careJourneyIds,
            }
          })
        }
      },
      onError: (error: any) => {
        setComponentState(prev => ({
          ...prev,
          isLoading: false
        }));
        showToast(
          toast,
          'Error in fetching journeys',
          ToastType.error
        );
      },
    }
  )

  const [getPatientJourneyDetails, careJourneyAPI] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_DETAIL_BY_JOURNEY_IDS,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: response => {
        if (
          response &&
          response.patientCareJourneys &&
          response.patientCareJourneys.length > 0
        ) {
          const mappedJourneyData = response.patientCareJourneys?.map((detail?: any) => {
            return {
              title: detail?.title || detail?.careJourney?.title,
              id: detail?.id,
              startDateTime: detail?.startDateTime,
              statusValue: detail?.status?.value
            }
          });
          setComponentState(prev => ({
            ...prev,
            careJourneyDetailsHavingDocuments: mappedJourneyData,
            filteredCareJourneyDetailsHavingDocuments: mappedJourneyData,
            isLoading: false
          }));
        }
      },
      onError: (error: any) => {
        setComponentState(prev => ({
          ...prev,
          isLoading: false
        }));
        showToast(
          toast,
          'Error in fetching journey details',
          ToastType.error
        );
      },
    },
  );


  const [deleteDocumentQuery] = useMutation(
    DocumentQueries.DELETE_CONTACT_DOCUMENT,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onError: () => {
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
      },
    }
  );

  const isSkeletonLoading =
    journeyIdsQuery?.loading ||
    careJourneyAPI?.loading ||
    componentState?.isLoading ||
    (documentsQuery?.loading && paginationState?.offset === 0) ||
    reportDetail.loading || loading;

  const isNoDocumentFound =
    !(
      journeyIdsQuery?.loading ||
      careJourneyAPI?.loading ||
      componentState?.isLoading ||
      documentsQuery?.loading ||
      reportDetail.loading || loading
    ) &&
    componentState?.documentList?.length === 0 &&
    componentState?.filteredCareJourneyDetailsHavingDocuments?.length === 0;

  const isFlatListDataRender =
    !journeyIdsQuery?.loading &&
    !careJourneyAPI?.loading &&
    !componentState?.isLoading &&
    !(documentsQuery?.loading && paginationState?.offset === 0) &&
    !loading &&
    !reportDetail.loading &&
    (componentState?.documentList?.length > 0 ||
      componentState?.filteredCareJourneyDetailsHavingDocuments?.length > 0);

  function getFileIcon(document?: any ) {
    if (document?.documentTypeObject?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <Ionicons name="documents" size={26} color={Colors.Custom.Gray500} />
        </View>
      );
    }
    if (isBelongToPatientEducationContent(document)) {
      return (
        <Ionicons name="documents" size={26} color={Colors.Custom.Gray500} />
      );
    }
    if (!document?.documentAttachments || !document?.documentAttachments.length) {
      return <></>
    }
    if (document?.documentAttachments.length > 1) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <Ionicons
            name="documents"
            size={26}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    }

    const documentType = document?.documentAttachments?.[0]?.attachment?.type;

    if (documentType === 'application/pdf') {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="file-pdf-box"
            size={32}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    } else if (documentType?.startsWith('image/')) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="file-image"
            size={26}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    } else if (documentType && WORD_DOC_MIME_TYPES.includes(documentType)) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="file-word"
            size={26}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    }
    return (
      <Ionicons name="document-text" size={26} color={Colors.Custom.Gray500} />
    );
  }

  function deleteDocument(documentId: string) {
    setComponentState((prev) => {
      prev.deleteDocumentLoading[documentId] = true;
      return {
        ...prev,
        deleteDocumentLoading: prev.deleteDocumentLoading,
      };
    })
    deleteDocumentQuery({
      variables: {
        data: {
          id: documentId,
        },
      },
      onCompleted: (response) => {
        showToast(toast, 'Document deleted successfully', ToastType.success);
        setComponentState((prev) => {
          prev.deleteDocumentLoading[documentId] = false
          return { ...prev, documentList: [], deleteDocumentLoading: prev.deleteDocumentLoading };
        });
        setPaginationState((prev) => {
          return {
            ...prev,
            offset: 0,
          }
        })
        if (isAthenaAndAllDocumentSection) {
          setCurrentPage(1);
          fetchAndHandleDataForAllDoc({
            currentPageOnUI: 1,
            sort: {
              by: SORT_BY.DATE,
              order: SORT_ORDER.DESC,
            },
            searchString: searchText,
          });
        } else {
          fetchDocuments({offset: 0});
        }
      },
      onError: (error) => {
        setComponentState((prev) => {
          prev.deleteDocumentLoading[documentId] = false;
          return { ...prev, deleteDocumentLoading: prev.deleteDocumentLoading };
        });
      },
    });
  }

  const getFetchDocParamsQuery = (args?: { offset?: number, careJourneyId?: string, searchString?: string, nameSort?: string, dateSort?: string }) => {
    const paramsForQuery: any = {
      subjectId: props?.contactUuid,
      ...(props?.category?.id !== DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS && { documentTypeId: props?.category?.id }),
      limit: 10,
      offset: args?.offset ?? paginationState.offset,
      searchString: args?.searchString !== undefined ? `%${args?.searchString}%` : `%${searchText}%`,
      ...(!!args?.nameSort?.length ? args?.nameSort !== 'none' && {
        orderBy: {
          caption: args?.nameSort
        }
      } : componentState?.nameSort !== 'none' && {
        orderBy: {
          caption: componentState.nameSort
        }
      }),
      ...(!!args?.dateSort?.length ? args?.dateSort !== 'none' && {
        orderBy: {
          updatedOn: args?.dateSort
        }
      } : componentState?.dateSort !== 'none' && {
        orderBy: {
          updatedOn: componentState.dateSort
        }
      }),
      ...((args?.careJourneyId !== 'false' && (args?.careJourneyId || componentState?.selectedJourney?.id)) &&
        { patientCareJourneyId: args?.careJourneyId || componentState?.selectedJourney?.id }),

      ...((args?.careJourneyId !== 'false' && (args?.careJourneyId || componentState?.selectedJourney?.id)) ?
        { subCategory: [CARE_PLAN_SUB_CATEGORY.PATIENT_CARE_JOURNEY, CARE_PLAN_SUB_CATEGORY.SUPPORTING_JOURNEY_DOCUMENTS] } :
        props?.category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS &&
        { subCategory: [CARE_PLAN_SUB_CATEGORY.CARE_JOURNEY] })
    }
    return paramsForQuery;

  }



  async function fetchDocuments(args?: { offset?: number, careJourneyId?: string, searchString?: string, nameSort?: string, dateSort?: string }) {
    if (props?.contactUuid) {
      const paramsForQuery: any = getFetchDocParamsQuery({offset: args?.offset, careJourneyId: args?.careJourneyId, searchString: args?.searchString,nameSort: args?.nameSort, dateSort: args?.dateSort})
      if (!paramsForQuery?.orderBy) {
        paramsForQuery.orderBy = {
          updatedOn: 'desc'
        }
      }
      await getContactDocumentsQuery({
        variables: {
          params: paramsForQuery
        }
      }
      );
    }
  }



  const getActions = (document: any) => {
    let actions;
    if(ehrConfig.isAthena && (isImagingReport || (document?.resources?.[0]?.category[0]?.coding?.[0]?.code === 'IMAGING'))){
      actions = [
        {
          code: 'VIEW',
          name: 'View',
        }
      ]
    }
    else{
      actions = [
        {
          code: 'VIEW',
          name: 'View',
        },
        {
          code: 'UPDATE',
          name: 'Update',
        },
        {
          code: 'DELETE',
          name: 'Delete',
        },
        ...(isEFaxMessagingEnabled &&
          document?.documentAttachments &&
          document?.documentAttachments?.length
            ? [
                {
                  code: 'EFAX',
                  name: 'Send eFax',
                },
              ]
            : []),
        ...(document?.documentAttachments && document?.documentAttachments?.length
          ? [
              {
                code: 'EMAIL',
                name: 'Send Email',
              },
            ]
          : []),
        ];
    }
      if (document?.patientCareJourneyId) {
        const actionList = [actions[0]];
        if (
          isEFaxMessagingEnabled &&
          document?.documentAttachments &&
          document?.documentAttachments?.length
        ) {
          actionList.push(actions[3]);
        }
        if (
          document?.documentAttachments &&
          document?.documentAttachments?.length
        ) {
          actionList.push({
            code: 'EMAIL',
            name: 'Send Email',
          });
        }
        return actionList;
      }
      if(isBelongToPatientEducationContent(document)) {
        const actionList = [actions[0]];
        return actionList;
      }
    return actions;
  }

  const getDocumentList = () => {
    const documentList: IDocumentData[] = [];
    if (!componentState.selectedDocument) {
      return [];
    }
    if (
      componentState.selectedDocument?.documentAttachments?.[0]?.attachment
        ?.id &&
      componentState.selectedDocument?.documentAttachments?.[0]?.attachment
        ?.name &&
      componentState.selectedDocument?.documentAttachments?.[0]?.attachment
        ?.type
    ) {
      documentList.push({
        id: componentState.selectedDocument.documentAttachments[0].attachment
          .id,
        name: componentState.selectedDocument.documentAttachments[0].attachment
          .name,
        type: componentState.selectedDocument.documentAttachments[0].attachment
          .type,
      });
    }
    return documentList;
  };

  useEffect(() => {
    setComponentState((prev) => ({...prev, isLoading: true, documentList: []}));

      if (isAthenaAndAllDocumentSection) {
        setCurrentPage(1);
        fetchAndHandleDataForAllDoc({
          currentPageOnUI: 1,
          sort: {
            by: SORT_BY.DATE,
            order: SORT_ORDER.DESC,
          },
          searchString: searchText,
        });
      } else if (isAthenaAndImagingReportSection) {
        setCurrentPage(1);
        fetchAndHandleDataForImagingReport({
          currentPageOnUI: 1,
          sort: {
            by: SORT_BY.DATE,
            order: SORT_ORDER.DESC
          },
          searchString: searchText,
        });
    } else {
      fetchDocuments({searchString: searchText, offset: 0});
    }
  }, [searchText]);

  useEffect(() => {
    setInitialcomponentState();
    if (props?.category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS) {
      getJourneyIdsDocumentsInJourney()
    }
    if (isAthenaAndAllDocumentSection) {
      setSearchText('');
      setCurrentPage(1);
      fetchAndHandleDataForAllDoc({
        currentPageOnUI: 1,
        sort: {
          by: SORT_BY.DATE,
          order: !componentState.isAllDocDescByDate
            ? SORT_ORDER.DESC
            : SORT_ORDER.ASC,
        },
      });
    } else if (isAthenaAndImagingReportSection) {
      setSearchText('');
      setCurrentPage(1);
      fetchAndHandleDataForImagingReport({
        currentPageOnUI: 1,
        sort: {
          by: SORT_BY.DATE,
          order: !componentState.isImagingReportDesc
            ? SORT_ORDER.DESC
            : SORT_ORDER.ASC,
        },
      });
    } else {
      setSearchText('');
      fetchDocuments({
        offset: 0,
        careJourneyId: 'false',
        searchString: '',
        nameSort: 'none',
        dateSort: 'none',
      });
    }
  }, [props?.category?.id,props?.category?.code])

  const getJourneyIdsDocumentsInJourney = async () => {
    setComponentState((prev) => {
      return ({
        ...prev,
        isLoading: true,
      })
    });
    await getJourneyIdsOfPatientHavingDocumentsInJourney({
      variables: {
        contactId: props?.contactUuid,
        subCategory: [CARE_PLAN_SUB_CATEGORY.PATIENT_CARE_JOURNEY, CARE_PLAN_SUB_CATEGORY.SUPPORTING_JOURNEY_DOCUMENTS]
      }
    })
  }

  async function downloadFileAsBlob(patientDocument: IDocument, file: any, downloadFile = false) {
    const attachmentId: string = (file as any).id;
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[patientDocument?.id] = true;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });

    try {
      if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
        const response = await service.get(`/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`, {
          responseType: 'blob',
        });

        file.blob = response.data;
        file.blobUrl = URL.createObjectURL(file.blob);
        file.preview = await getBase64(response.data);
      }

      if (downloadFile) {
        if (isWeb()) {
          const link = document.createElement('a');
          link.href = file.blobUrl;
          link.download = file.name;
          link.click();
        } else {
          showToast(toast, 'Unable to download file', ToastType.error);
        }
      }

      setComponentState((prev) => {
        prev.isFileLoading[patientDocument?.id] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[patientDocument?.id] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    }
  }

  async function handlePreview(document: IDocument, file?: any) {
    if (componentState.isFileLoading[document?.id]) {
      return;
    }
    if (document?.referenceData?.entity?.slug) {
      const slug = document?.referenceData?.entity?.slug;
      const url = PATIENT_EDUCATION_URL + slug;
      window.open(url, '_blank');
    }
    else if (file) {
      let fileUrl = '';

      if ((file as any).id && (!(file as any).blobUrl || WORD_DOC_MIME_TYPES.includes(file.type as string))) {
        await downloadFileAsBlob(document, file);
      }

      const fileMediaData: IMediaLibraryData = {
        ...file,
        lastUpdated: file.lastModified?.toString() || '',
        mime: file.type as string,
        name: file.name,
        size: file.size as number,
      };

      if (file.type) {
        fileUrl = (file as any).blobUrl;
      }

      setComponentState((prev) => ({
        ...prev,
        isPreviewVisible: true,
        previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
        ...(file.type && fileUrl &&
        {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }
        ),
      }));
    }
  }

  function handlePreviewCancel(value = false) {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
      previewImage: undefined,
    }));
  }

  const handleEndReached = () => {
      if (!paginationState.isLoading) {
        setPaginationState((prev) => {
          const newOffset = prev.offset + prev.pageSize;
          if (componentState.aggregateDocumentCount && (componentState.aggregateDocumentCount > newOffset)) {
              if (isAthenaAndAllDocumentSection) {
                setCurrentPage((prevPage) => prevPage + 1);
                fetchAndHandleDataForAllDoc({
                  currentPageOnUI: currentPage + 1,
                  sort: {
                    by: SORT_BY.DATE,
                    order: !componentState.isAllDocDescByDate
                      ? SORT_ORDER.DESC
                      : SORT_ORDER.ASC,
                  },
                });
              } else if (isAthenaAndImagingReportSection) {
                setCurrentPage((prevPage) => prevPage + 1);
                fetchAndHandleDataForImagingReport({
                  currentPageOnUI: currentPage + 1,
                  sort: {
                    by: SORT_BY.DATE,
                    order: !componentState.isImagingReportDesc
                      ? SORT_ORDER.DESC
                      : SORT_ORDER.ASC,
                  },
                });
            } else {
              fetchDocuments({offset: newOffset});
            }
            return {
              ...prev,
              isLoading: true,
              offset: newOffset,
            }

          }
          else {
            return prev
          }
        })
      }
      setComponentState((prev) => ({ ...prev, isLoading: false }))
  };
  const intl = useIntl();
  const isBelongToPatientEducationContent = (document: any) => {
    return !!document?.referenceData?.entity?.slug;
  }
  const renderImagingDocumentItem = (documentObject: any) => {
    const document = documentObject?.item;
    const latestReport = document?.resources?.[0]
    const documentName = document?.display;
    return (
      <View
        backgroundColor={Colors.Custom.BackgroundColor}
        key={latestReport?.id}
        minHeight={70}
      >
        {componentState.deleteDocumentLoading[latestReport?.id] && (
          <HStack
            alignItems={'center'}
            justifyContent={'center'}
            height={70}
            paddingTop={3}
            paddingBottom={3}
            paddingLeft={10}
            paddingRight={10}
            space={2}
          >
            <Skeleton.Text lines={2} />
          </HStack>
        )}
        {
          <HStack
            space={1}
            paddingY={3}
            paddingLeft={3}
            paddingRight={1}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <View width={12} alignItems={'center'}>
              <Pressable
                onPress={() => {
                  getReportDataForImaging(latestReport);
                  setReportDetail((prev) => {
                    return {
                      ...prev,
                      showDetail: true,
                      selectedOrder: {
                        report: latestReport,
                        order: undefined,
                        action: undefined,
                        prescription: undefined,
                        reportType: 'IMAGING',
                        documentTitle: documentName,
                      },
                    };
                  });
                }}
              >
                <DocumentCategoryIcon
                  categoryCode={DOCUMENT_CATEGORY_CODES.IMAGING_REPORTS}
                />
              </Pressable>
            </View>

            <View flex={1}>
              <Pressable
                onPress={() => {
                  getReportDataForImaging(latestReport);
                  setReportDetail((prev) => {
                    return {
                      ...prev,
                      showDetail: true,
                      selectedOrder: {
                        report: latestReport,
                        order: undefined,
                        action: undefined,
                        prescription: undefined,
                        reportType: 'IMAGING',
                        documentTitle: documentName,
                      },
                    };
                  });
                }}
              >
                <VStack flex={2}>
                  <View width={'90%'} flex={1}>
                    <HStack alignItems={'center'}>
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        isTruncated={true}
                        noOfLines={1}
                        marginLeft={1}
                      >
                        {document?.documentType?.value}
                      </Text>
                    </HStack>
                  </View>
                  <View width={'90%'} flex={1}>
                    <Text
                      fontSize={14}
                      fontWeight={500}
                      marginLeft={1}
                      isTruncated={true}
                      noOfLines={1}
                    >
                      {document?.caption || documentName}
                    </Text>
                  </View>
                  {!!(
                    document?.patientCareJourney?.title ||
                    document?.patientCareJourney?.careJourney?.title
                  ) && (
                    <View width={'90%'} flex={1}>
                      <HStack alignItems={'center'} space={1}>
                        <DocumentCategoryIcon
                          categoryCode={JOURNEY_DOCUMENT_RENDER}
                        />
                        <Text
                          fontSize="xs"
                          color="gray.500"
                          isTruncated={true}
                          noOfLines={1}
                        >
                          {document?.patientCareJourney?.title ||
                            document?.patientCareJourney?.careJourney?.title}
                        </Text>
                      </HStack>
                    </View>
                  )}
                </VStack>
              </Pressable>
            </View>
            <View marginRight={2}>
              {document?.effectiveDateTime && (
                <Text
                  fontSize="xs"
                  color="gray.500"
                  isTruncated={true}
                  noOfLines={1}
                  marginLeft={1}
                >
                  {getDateObjectFromAPIFormatToMyFormat(
                    document?.effectiveDateTime,
                    DATE_FORMATS.DOCUMENT_DATE_FORMAT
                  )}
                </Text>
              )}
            </View>
            <View width={6} alignItems={'center'}>
              <FHActionPopover
                actions={getActions(document)}
                onActionClick={(code, event) => {
                  const isAnyDocFileLoading = isAnyFileLoading(
                    componentState.isFileLoading
                  );
                  if (isAnyDocFileLoading) {
                    notification.destroy();
                    notification.info({
                      message: intl.formatMessage({id: 'otherDocLoading'}),
                      duration: 3.0,
                      placement: 'top',
                    });
                  } else {
                    if (code === 'VIEW') {
                      getReportDataForImaging(latestReport);
                      setReportDetail((prev) => {
                        return {
                          ...prev,
                          showDetail: true,
                          selectedOrder: {
                            report: latestReport,
                            order: undefined,
                            action: undefined,
                            prescription: undefined,
                            reportType: 'IMAGING',
                            documentTitle: documentName,
                          },
                        };
                      });
                    }
                  }
                }}
              />
            </View>
          </HStack>
        }
        <Divider />
      </View>
    );
  };
  const renderDocItem = (documentObject: any) => {
    if (isAthenaAndAllDocumentSection) {
      return renderAllDocItem(documentObject);
    } else if (isAthenaAndImagingReportSection) {
      return renderImagingDocumentItem(documentObject);
    } else {
      return renderDocumentItem(documentObject);
    }
  };
  const renderAllDocItem = (documentObject: any) => {
    const document = documentObject.item;
    if (
      document?.resources?.[0]?.category[0]?.coding?.[0]?.code === 'IMAGING'
    ) {
      return renderImagingDocumentItem(documentObject);
    } else {
      return renderDocumentItem(documentObject);
    }
  };

  const renderDocumentItem = (documentObject: any) => {
    const document = documentObject.item
    const documentName =
      document?.notes ||
      document?.documentAttachments
        ?.map((documentAttachment: any) => documentAttachment?.attachment?.name)
        .filter((documentName: any) => !!documentName)
        .join(', ');

    return (
      (documentObject?.item?.id && documentObject?.item?.title) ?
        componentState?.selectedJourney?.id ? <></> :
          <View
            backgroundColor={Colors.Custom.BackgroundColor}
            key={document.id}
            minHeight={70}
          >
            <Pressable onPress={() => {
              setComponentState((prev) => ({
                ...prev,
                selectedJourney: documentObject?.item,
                searchString: ''
              }));
              setSearchText('')
              setPaginationState((prev) => ({ ...prev, offset: 0 }));
              fetchDocuments({ careJourneyId: documentObject?.item?.id, searchString: '', offset: 0 })
            }}>
              <HStack space={1} padding={3} alignItems={'center'} justifyContent={'center'}>
                <View width={12} alignItems={'center'}>
                    <FolderIcon />
                </View>
                <View flex={1}>
                    <VStack flex={2}>
                      <View width={'90%'} flex={1}>
                        <Text style={{
                          color: 'black',
                          fontSize: 14,
                          fontFamily: 'Manrope',
                          fontWeight: '600',
                          lineHeight: 21,
                        }} isTruncated={true} noOfLines={1}>
                          {documentObject?.item?.title}
                        </Text>
                    </View>
                    <View width={'90%'} flex={1}>
                      <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1}>
                        {`Start Date: ${documentObject?.item?.startDateTime
                        ? getDateObjectFromAPIFormatToMyFormat(
                            documentObject?.item?.startDateTime,
                            DATE_FORMATS.DOCUMENT_DATE_FORMAT,
                          )
                        : intl.formatMessage({ id: 'startDateNotAssigned' })
                      }`}
                      </Text>
                    </View>
                    <View width={'90%'} flex={1}>
                      <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1}>
                        {`Status: ${documentObject?.item?.statusValue || ''}`}
                      </Text>
                    </View>
                    </VStack>
                </View>
              </HStack>
            </Pressable>
            <Divider />
          </View>
        :
        <View
          backgroundColor={Colors.Custom.BackgroundColor}
          key={document.id}
          minHeight={70}
        >
          {componentState.deleteDocumentLoading[document.id] && (
            <HStack
              alignItems={'center'}
              justifyContent={'center'}
              height={70}
              paddingTop={3}
              paddingBottom={3}
              paddingLeft={10}
              paddingRight={10}
              space={2}
            >
              <Skeleton.Text lines={2} />
            </HStack>
          )}
          {!componentState.deleteDocumentLoading[document.id] && (
            <HStack space={1} paddingY={3} paddingLeft={3} paddingRight={1} alignItems={'center'} justifyContent={'center'}>
              <View width={12} alignItems={'center'}>
                <Pressable
                  onPress={() => {
                    if (!document?.patientCareJourneyId) {
                      setComponentState((prev) => ({
                        ...prev,
                        isAddDocumentModalOpen: true,
                        selectedDocument: document,
                      }));
                    }
                  }
                  }
                >
                  {getFileIcon(document)}
                </Pressable>
              </View>

              <View flex={1}>
                <Pressable
                  onPress={() => {
                    if (!document?.patientCareJourneyId) {
                      setComponentState((prev) => ({
                        ...prev,
                        isAddDocumentModalOpen: true,
                        selectedDocument: document,
                      }));
                    }
                  }
                  }
                >
                  <VStack flex={2}>
                    <View width={'90%'} flex={1}>
                      <HStack alignItems={'center'}>
                        <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1} marginLeft={1}>{document?.documentType?.value}</Text>
                      </HStack>
                    </View>
                    <View width={'90%'} flex={1}>
                      <Text fontSize={14} fontWeight={500} marginLeft={1} isTruncated={true} noOfLines={1}>
                        {document?.caption || documentName}
                      </Text>
                    </View>
                    {!!(document?.patientCareJourney?.title || document?.patientCareJourney?.careJourney?.title) &&
                      <View width={'90%'} flex={1}>
                        <HStack alignItems={'center'} space={1}>
                          <DocumentCategoryIcon categoryCode={JOURNEY_DOCUMENT_RENDER} />
                          <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1}>
                            {document?.patientCareJourney?.title || document?.patientCareJourney?.careJourney?.title}
                          </Text>
                        </HStack>
                      </View>
                    }
                  </VStack>
                </Pressable>
              </View>
              <View marginRight={2}>
                {document?.updatedOn &&
                  <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1} marginLeft={1}>
                    {getDateObjectFromAPIFormatToMyFormat(document?.updatedOn, DATE_FORMATS.DOCUMENT_DATE_FORMAT)}
                  </Text>
                }
              </View>

              {componentState.isFileLoading[document?.id] && (
                <View width={8}>
                  <Spinner size="sm" />
                </View>
              )}

              <View width={6} alignItems={'center'}>
                <FHActionPopover
                  actions={getActions(document)}
                  onActionClick={(code, event) => {
                    const isAnyDocFileLoading = isAnyFileLoading(componentState.isFileLoading);
                    if (isAnyDocFileLoading) {
                      notification.destroy();
                      notification.info({
                        message: intl.formatMessage({id:'otherDocLoading'}),
                        duration: 3.0,
                        placement: 'top'
                      });
                    }else{
                      if (code === 'DELETE') {
                        setComponentState((prev) => ({
                          ...prev,
                          isDeleteConfirmationOpen: true,
                          selectedDocument: document,
                        }));
                      } else if (code === 'UPDATE') {
                        setComponentState((prev) => ({
                          ...prev,
                          isAddDocumentModalOpen: true,
                          selectedDocument: document,
                        }));
                      } else if (code === 'VIEW') {
                        if (document?.referenceData?.entity?.slug) {
                          handlePreview(document);
                        }
                        else if (document?.documentAttachments?.length === 1 && document.documentAttachments[0]?.attachment) {
                          const file = {
                            id: document?.documentAttachments[0].attachment.id,
                            uid: document?.documentAttachments[0].attachment.id,
                            name: document?.documentAttachments[0].attachment.name,
                            type: document?.documentAttachments[0].attachment.type,
                            status: 'done',
                          } as any;
                          handlePreview(document, file);
                        } else {
                          setComponentState((prev) => ({
                            ...prev,
                            isAddDocumentModalOpen: true,
                            selectedDocument: document,
                          }));
                        }
                      } else if (code === 'EFAX') {
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            isEFaxDrawerOpen: true,
                            selectedDocument: document,
                          }
                        })
                      } else if (code === 'EMAIL') {
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            isEmailDrawerOpen: true,
                            selectedDocument: document,
                          };
                        });
                      }
                    }
                  }}
                />
              </View>
            </HStack>
          )}

          <Divider />
        </View>
    );
  };

  const renderDocumentTitleTextElem = () => {
    return (
      <Text
        style={{
          color: 'black',
          fontSize: 16,
          fontFamily: 'Manrope',
          fontWeight: '700',
          lineHeight: 27,
        }}
        isTruncated={true}
        maxW={'100%'}
      >
        {props?.category?.value}
      </Text>
    );
  }

  const configureReportResult = async (
    selectedReport: IReportAndResourceMap,
    reportType: string,
    key: string,
    isImaging?: boolean
  ) => {
    setReportDetail((prev: any) => {
      return {
        ...prev,
        reportLoadingKey: key,
      };
    });
    const {reportName, observationList} = await getReportResultForDisplay(
      selectedReport,
      reportType,
      reportDetail.orders,
      {
        ehrConfig,
        patientId:
          props?.personData?.patientId || props?.personData?.patientUuid,
        locationId: props?.personData?.accountLocationUuid,
      }
    );
    const isDataAvailable = !!reportName && observationList.length > 0;
    setReportDetail((prev: any) => {
      return {
        ...prev,
        ...(isImaging
          ? {showImagingResultTable: isDataAvailable}
          : {showModal: isDataAvailable}),
        reportLoadingKey: undefined,
        selectedReport: {
          reportName,
          observation: observationList,
          resources: selectedReport?.resources || [],
          isReportReviewedByUser: isAllReportViewedBy(selectedReport),
        },
      };
    });
  };

  const getReportDataForImaging = async (report?: DiagnosticReport) => {
    if (report) {
      setReportDetail((prev: any) => {
        return {
          ...prev,
          reportResultViewLoading: true,
        };
      });
      const supportingParams = {
        patientId:
          props?.personData?.patientId || props?.personData?.patientUuid,
        locationId: props?.personData?.accountLocationUuid || '',
      };
      const reportForImaging = await getReportForImaging(
        report,
        canShowMarkAsReview ?? false,
        supportingParams
      );
        reportForImaging?.length > 0 &&
        !!reportForImaging?.[0]?.type &&
        configureReportResult?.(
          reportForImaging[0],
          reportForImaging[0].type,
          `${report?.code}_${report?.id}`,
          true
        );
      setReportDetail((prev: any) => {
        return {
          ...prev,
          reportResultViewLoading: false,
        };
      });
    }
    return [];
  };

  const fetchOrders = async () => {
    const patientId =
      props?.personData?.patientId || props?.personData?.patientUuid;
    if (!reportDetail.loading && patientId) {
      setReportDetail((prev: any) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const labReport = await fetchReports();
      let radReports = labReport || [];
      const radReportWithDates: IReportAndResourceMap[] = [];
      const radReportWithoutDates: IReportAndResourceMap[] = [];
      radReports?.forEach((report) => {
        if (report.effectiveDateTime) {
          radReportWithDates.push(report);
        } else {
          radReportWithoutDates.push(report);
        }
      });
      radReports = radReportWithDates?.sort((report1, report2) => {
        const date1: any = new Date(report1.effectiveDateTime || new Date());
        const date2: any = new Date(report2.effectiveDateTime || new Date());
        return date2 - date1;
      });
      radReports = radReports?.concat(radReportWithoutDates);
      setReportDetail((prev: any) => {
        return {
          ...prev,
          orders: componentState.documentList,
          radReports: radReports,
          loading: false,
        };
      });
      if (error) {
        setReportDetail((prev: any) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    }
  };

  const getTitleOfDocumentList = () => {
    const isLargeText = props?.category?.value.length > MAX_DOC_TITLE_CHAR_LIMIT;
    return (
      <HStack
        style={{
          borderLeftColor: '#7F56D9',
          borderStyle: 'solid',
          borderTopWidth: 0,
          borderTopColor: '#EEEEEE',
          borderBottomColor: '#EEEEEE',
          paddingHorizontal: 16,
          paddingVertical: 12,
        }}
        maxW="100%"
      >
        <HStack maxWidth={'90%'}>
          <Pressable maxW={'100%'} onPress={() => {
            setComponentState((prev) => ({
              ...prev,
              selectedJourney: undefined,
              nameSort: 'none',
              dateSort: 'none',
              searchString: ''
            }));
            setSearchText('')
            setPaginationState((prev) => ({ ...prev, offset: 0 }));
            fetchDocuments({ careJourneyId: 'false', searchString: '', nameSort: 'none', dateSort: 'none', offset: 0 });
          }
          }
          flexDir={'row'}
          >
            {isLargeText ? (
              <Tooltip label={props?.category?.value}>
                  {renderDocumentTitleTextElem()}
              </Tooltip>
              ) : (
              renderDocumentTitleTextElem()
            )}
            {componentState?.selectedJourney?.title &&
              <RightArrowIconForDocuments />
            }
          </Pressable>
          {componentState?.selectedJourney?.title &&
            <Tooltip label={componentState?.selectedJourney?.title}>
              <Text
                style={{
                  color: Colors?.Custom?.Gray500,
                  fontSize: 16,
                  fontFamily: 'Manrope',
                  fontWeight: '600',
                  lineHeight: 27,
                }}
                isTruncated={true}
              >
                {componentState?.selectedJourney?.title}
              </Text>
            </Tooltip>
          }
        </HStack>
        <Spacer />
        <Pressable onPress={() => {
          setComponentState((prev) => ({
            ...prev,
            isAddDocumentModalOpen: true,
            selectedDocument: undefined,
          }));
        }}>
          <PlusIcon defaultColor={Colors?.Custom?.Gray500} />
        </Pressable>
      </HStack>
    )
  }
  const onCloseOfReportResultView = () => {
    setReportDetail((prev) => {
      return {
        ...prev,
        showModal: false,
        showImagingResultTable: false,
        showDetail: false,
        selectedOrder: {},
        selectedReport: {
          reportName: '',
          observation: [],
          resources: [],
          isReportReviewedByUser: false,
        },
      };
    });
  };

  const reportResultView = (
    <ReportResultView
      unformmatedContactDetails={props?.unFormattedContactData}
      contactData={props?.personData}
      showModal={reportDetail.showModal}
      reportName={reportDetail.selectedReport.reportName}
      observations={reportDetail.selectedReport.observation}
      resources={reportDetail.selectedReport.resources as DiagnosticReport[]}
      onClose={onCloseOfReportResultView}
      onMarkedAsReviewed={fetchOrders}
      documentTitle={reportDetail.selectedOrder.documentTitle}
      isImaging={reportDetail?.selectedOrder?.reportType === ReportCategory.IMAGING}
      locationId={props?.personData?.accountLocationUuid}
    />
  );

  const getDataSetForFlatList = () => {
    if (props?.category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS && !componentState?.selectedJourney) {
      return [...componentState?.filteredCareJourneyDetailsHavingDocuments, ...componentState?.documentList]
    }
    else return componentState?.documentList
  }

  const handleOnChangeOfSearchAction = (searchString: any) => {
    setSearchText(searchString);
  }

  const selectedCategoryIdForUpload = () => {
    // here if user is in ALL documents section then selected category id will be undefined as he will choose the same in upload form
    const selectedCategoryId = componentState?.selectedDocument?.documentTypeId || props?.category?.id;
    if (selectedCategoryId === DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS) {
      return undefined;
    }
    return selectedCategoryId;
  }

  return (
    <>
      <VStack borderRadius={8} marginBottom={4} maxHeight={'100%'}>
        {getTitleOfDocumentList()}
        <HStack alignItems={'center'}>
          <SearchBar
            flex={1}
            searchText={searchText}
            placeholderText={`Search in ${
              componentState?.selectedJourney?.title || props?.category?.value
            }`}
            onChange={debounce(handleOnChangeOfSearchAction, 500)}
            borderRadius={0}
          />
        </HStack>
        {(componentState?.documentList?.length > 0 ||
          componentState?.filteredCareJourneyDetailsHavingDocuments?.length >
            0) &&
          !(
            props?.category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS &&
            !componentState?.selectedJourney?.id
          ) && (
            <View>
              <HStack
                style={{
                  borderLeftColor: Colors.Custom.primary300,
                  borderStyle: 'solid',
                  borderTopWidth: 0,
                  borderTopColor:  Colors.Custom.gray150,
                  borderBottomColor:  Colors.Custom.gray150,
                  paddingLeft: 16,
                  paddingRight: 60,
                  paddingVertical: 12,
                }}
              >
                <Text
                  style={{
                    color: '#667085',
                    fontSize: 14,
                    fontFamily: 'Manrope',
                    fontWeight: '500',
                    lineHeight: 21,
                  }}
                >
                  {'File Name'}
                </Text>
                <Pressable
                  onPress={() => {
                    if (isAthenaAndImagingReportSection) {
                      fetchAndHandleDataForImagingReport({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.NAME,
                          order: componentState.isImagingReportAscByName
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                    }
                    else if (isAthenaAndAllDocumentSection){
                        fetchAndHandleDataForAllDoc({
                          currentPageOnUI: 1,
                          sort: {
                            by: SORT_BY.NAME,
                            order: componentState.isAllDocAscByName
                              ? SORT_ORDER.DESC
                              : SORT_ORDER.ASC,
                          },
                          searchString: searchText,
                        });
                    }
                    else {
                      const nextSortOrder =
                        sortStateMap[componentState?.nameSort];
                      setPaginationState((prev) => {
                        return {
                          ...prev,
                          offset: 0,
                        };
                      });
                      setComponentState((prev) => ({
                        ...prev,
                        nameSort: nextSortOrder,
                        dateSort: 'none',
                      }));
                      fetchDocuments({
                        nameSort: nextSortOrder,
                        dateSort: 'none',
                        offset: 0,
                      });
                    }
                  }}
                  style={{justifyContent: 'center', marginLeft: 2}}
                >
                  <SortIcon sortMode={componentState?.nameSort} />
                </Pressable>
                <Spacer />
                <Text
                  style={{
                    color: '#667085',
                    fontSize: 14,
                    fontFamily: 'Manrope',
                    fontWeight: '500',
                    lineHeight: 21,
                  }}
                >
                  {'Last Updated'}
                </Text>
                <Pressable
                  onPress={() => {
                    if (isAthenaAndImagingReportSection) {
                      fetchAndHandleDataForImagingReport({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.DATE,
                          order: !componentState.isImagingReportDesc
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                    } else if (isAthenaAndAllDocumentSection) {
                      fetchAndHandleDataForAllDoc({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.DATE,
                          order: !componentState.isAllDocDescByDate
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                      setPaginationState((prev) => ({ ...prev, offset: 0, pageSize: 10}));
                    }else {
                      const nextSortOrder =
                        sortStateMap[componentState?.dateSort];
                      setPaginationState((prev) => {
                        return {
                          ...prev,
                          offset: 0,
                        };
                      });
                      setComponentState((prev) => ({
                        ...prev,
                        dateSort: nextSortOrder,
                        nameSort: 'none',
                      }));
                      fetchDocuments({
                        nameSort: 'none',
                        dateSort: nextSortOrder,
                        offset: 0,
                      });
                    }
                  }}
                  style={{justifyContent: 'center', marginLeft: 2}}
                >
                  <SortIcon sortMode={componentState?.dateSort} />
                </Pressable>
              </HStack>
              <Divider />
            </View>
          )}
        {isSkeletonLoading && <Skeleton.Text lines={3} />}
        {isNoDocumentFound && (
            <View backgroundColor={Colors.Custom.BackgroundColor}>
              <NoTagsSvg titleId="patientDocumentsNoDocumentsFound" />
            </View>
          )}

        {isFlatListDataRender && (
            <FlatList
              data={getDataSetForFlatList()}
              keyExtractor={(item: any) => item?.id}
              renderItem={ renderDocItem }
              onEndReached={(data) => {
                if (data?.distanceFromEnd < 70) {
                  handleEndReached();
                }
              }}
              onEndReachedThreshold={0.1}
              ListFooterComponent={
                paginationState.isLoading ? (
                  <Spinner size={'lg'} my={2} />
                ) : null
              }
              maxHeight={'100%'}
            />
          )}
      </VStack>

      {componentState.previewTitle &&
        componentState.previewObj?.type &&
        componentState.isPreviewVisible && (
          <DocumentViewer
            fileName={componentState.previewTitle}
            fileType={componentState.previewObj.type}
            onClose={handlePreviewCancel}
            fileUrl={componentState.previewObj.url}
            isOpen={true}
            base64Data={componentState.previewObj?.fileMediaData?.preview}
            uniqueResourceIdentifier={componentState.previewObj?.fileMediaData?.id}
            patientId = {props?.personData?.patientId}
          />
        )}
      <>
        {props?.contactUuid && componentState.isAddDocumentModalOpen && (
          <UploadPatientDocument
            personData={props?.personData}
            contactId={props?.contactUuid}
            selectedCategoryId={selectedCategoryIdForUpload()}
            document={componentState.selectedDocument}
            isVisible={true}
            onCancel={() => {
              setComponentState((prev) => ({
                ...prev,
                isAddDocumentModalOpen: false,
                selectedDocument: undefined,
              }));
            }}
            documentsCategories={props?.documentsCategories.filter(
              (category) =>
                category.id !== DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS
            )}
            onUpload={(data: any, documentTypeId: any) => {
              setComponentState((prev) => {
                setPaginationState((prev) => {
                  return {...prev, offset: 0};
                });
                if (isAthenaAndAllDocumentSection) {
                  fetchAndHandleDataForAllDoc({
                    currentPageOnUI: 1,
                    sort: {
                      by: SORT_BY.DATE,
                      order: SORT_ORDER.DESC,
                    },
                  });
                } else if (isAthenaAndImagingReportSection) {
                  fetchAndHandleDataForImagingReport({
                    currentPageOnUI: 1,
                    sort: {
                      by: SORT_BY.DATE,
                      order: SORT_ORDER.DESC,
                    },
                  });
                } else {
                  fetchDocuments({offset: 0});
                }
                return {
                  ...prev,
                  isAddDocumentModalOpen: false,
                  selectedDocument: undefined,
                };
              });
              if (
                props?.category?.id !== DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS &&
                (documentTypeId !== props?.category?.id ||
                  !!componentState?.selectedJourney)
              ) {
                notification.destroy();
                notification.info({
                  message:
                    'The document is created but is not visible. View details.',
                  style: {
                    width: 500,
                    cursor: 'pointer',
                  },
                  onClick() {
                    notification.destroy();
                    setComponentState((prev) => ({
                      ...prev,
                      isAddDocumentModalOpen: true,
                      selectedDocument: {
                        ...data,
                        documentTypeId: documentTypeId,
                      },
                    }));
                  },
                  duration: 3000,
                });
              } else {
                showToast(
                  toast,
                  `Document ${
                    !!componentState.selectedDocument?.id
                      ? 'updated'
                      : 'uploaded'
                  } successfully`,
                  ToastType.success
                );
              }
            }}
          />
        )}
      </>
      {props?.contactUuid && componentState.isDeleteConfirmationOpen && (
        <DeleteConfirmationDrawer
          isOpen={true}
          onCancel={() => {
            setComponentState((prev) => ({
              ...prev,
              isDeleteConfirmationOpen: false,
              selectedDocument: undefined,
            }));
          }}
          onConfirm={() => {
            if (componentState.selectedDocument?.id) {
              deleteDocument(componentState.selectedDocument?.id);
            }
            setComponentState((prev) => ({
              ...prev,
              isDeleteConfirmationOpen: false,
              selectedDocument: undefined,
            }));
          }}
          cancelButtonText={'no'}
          confirmButtonText={'yes'}
        />
      )}
      {reportDetail.showDetail && (
        <OrderDetailsDrawer
          isVisible={reportDetail.showDetail}
          report={reportDetail.selectedOrder.report}
          documentTitle={reportDetail.selectedOrder.documentTitle}
          order={reportDetail.selectedOrder.order}
          action={reportDetail.selectedOrder.action}
          prescription={reportDetail.selectedOrder.prescription}
          onClose={() => {
            onCloseOfReportResultView();
          }}
          reportResultViewLoading={reportDetail?.reportResultViewLoading}
          reportType={reportDetail.selectedOrder.reportType}
          showReportResultView={reportDetail.showImagingResultTable}
          reportResultView={reportResultView}
          selectedReportResources={
            reportDetail.selectedReport?.resources as DiagnosticReport[]
          }
          unformmatedContactDetails={props?.unFormattedContactData}
          contactData={props?.personData}
          onCommunicationActionPerformed={(actionCode: string) => {
            setReportDetail((prev) => ({
              ...prev,
              actionCode: actionCode,
              showDetail: false,
            }));
          }}
        />
      )}
      {props?.contactUuid && componentState.isEFaxDrawerOpen && (
        <CreateEFaxConversationDrawer
          visible={componentState.isEFaxDrawerOpen}
          selectedPatient={{
            uuid: props?.contactUuid,
            id: props?.contactId,
            name: props?.contactName,
          }}
          selectedAttachment={
            componentState?.selectedDocument?.documentAttachments
          }
          isEFaxFromPatientProfile={true}
          onActionPerformed={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                isEFaxDrawerOpen: false,
              };
            });
          }}
        />
      )}
      {reportDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommon
          contactData={props?.personData}
          category={{
            code: 'GENERAL',
            name: '',
          }}
          onMailCompleteAction={() => {
            onCloseOfReportResultView();
            setReportDetail((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
        />
      )}

      {reportDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            onCloseOfReportResultView();
            setReportDetail((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
          selectedPatient={props?.unFormattedContactData}
        />
      )}

      {reportDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
        <MeetingView
          personData={props.personData || formattedContactData}
          onPersonActionPerformed={() => {
            onCloseOfReportResultView();
            setReportDetail((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
        />
      )}

      {reportDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CHAT_DRAWER && (
        <InstantChat
          contactUuid={props?.personData?.contactUUID}
          isDrawerVisible={true}
          contactData={props?.personData}
          onActionPerformed={() => {
            onCloseOfReportResultView();
            setReportDetail((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
        />
      )}
      {componentState.isEmailDrawerOpen ? (
        <EmailDrawerCommonV2
          isOpen={componentState.isEmailDrawerOpen}
          onClose={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                isEmailDrawerOpen: false,
              };
            });
          }}
          onEmailSent={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                isEmailDrawerOpen: false,
              };
            });
          }}
          contactIds={[props.contactId]}
          documentList={getDocumentList()}
        />
      ) : (
        <></>
      )}
    </>
  );
}
