import React from 'react';
import { Colors } from '../../../styles';
import Svg, { Path } from 'react-native-svg';
import { ICommonSvgProps } from './interfaces';

const NewCloseSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.width || "20"}
      height={props?.height || "20"}
      viewBox="0 0 20 20"
      fill="none"
    >
      <Path
        d="M15.3536 5.35359C15.5488 5.15833 15.5488 4.84175 15.3536 4.64649C15.1583 4.45122 14.8417 4.45122 14.6464 4.64649L15.3536 5.35359ZM4.64649 14.6464C4.45123 14.8417 4.45123 15.1583 4.64649 15.3536C4.84175 15.5488 5.15833 15.5488 5.3536 15.3536L4.64649 14.6464ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L5.35355 4.64645ZM14.6464 15.3535C14.8417 15.5488 15.1582 15.5488 15.3535 15.3535C15.5488 15.1583 15.5488 14.8417 15.3535 14.6464L14.6464 15.3535ZM14.6464 4.64649L9.64647 9.64647L10.3536 10.3536L15.3536 5.35359L14.6464 4.64649ZM9.64647 9.64647L4.64649 14.6464L5.3536 15.3536L10.3536 10.3536L9.64647 9.64647ZM4.64645 5.35355L9.64647 10.3536L10.3536 9.64647L5.35355 4.64645L4.64645 5.35355ZM9.64647 10.3536L14.6464 15.3535L15.3535 14.6464L10.3536 9.64647L9.64647 10.3536Z"
        fill={props?.customStrokeColor || Colors.FoldPixel.GRAY300} />
    </Svg>
  );
};

export default NewCloseSvg;
