import {useLazyQuery} from '@apollo/client';
import {HStack, Pressable, Text, View, VStack} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useLocation} from 'react-router-dom';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {FHIR_RESOURCE} from '../../../constants/FhirConstant';
import {CommonDataContext, ICommonData} from '../../../context/CommonDataContext';
import {FilterDataContext} from '../../../context/FilterDataContext';
import {FlowType} from '../../../context/WorkflowContext';
import {GET_MY_PATIENT} from '../../../services/CareTeam/CareTeamQueries';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import EmployerQueries from '../../../services/Employer/EmployerQueries';
import {Colors} from '../../../styles';
import {checkAccountConfigAbilityAccess, getAccountConfigResourceAbilities} from '../../../utils/capabilityUtils';
import {getAccountId, getAccountUUID, getFeatureFlag, getUserUUID, isChildAccount, isContractsEnabled, isEnableCareProgram, isMasterAccount} from '../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {getFilterDataByType} from '../../../utils/FilterUtils';
import {getContactTypeId} from '../../../utils/mlovUtils';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import AllMembersView from '../../common/MemebersView/AllMembersView';
import PlusIcon from '../../common/Svg/PlusSvg';
import {
  ITableTopBarProps,
  TABLE_TOP_BAR_ACTION_CODES,
  TableTopBar
} from '../../common/TableTopBar';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import {TAB_KEYS} from '../constants';
import {getContactFiltersObject, getSearchContactsFilterObject} from '../Contacts/ContactsUtils';
import {
  getBulkActionTopBarButton,
  getLeadListTopBarButtons
} from '../Contacts/Leads/LeadView/LeadTableView/Helper/TopBarButtons';
import CampaignManagementSegmentView from '../ContentManagement/CampaignManagement/CampaignManagementSegmentView';
import {POPULATION_FILTER_TYPE} from '../ContentManagement/CampaignManagement/constants';
import Contracts from '../Contracts/Contracts';
import {EmployerManagement} from '../EmployerManagement';
import {isAutomationAllowed} from '../UserAccess/UserAccessPermission';
import {getGroupsList} from '../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {LeadQueries} from '../../../services';
const AllContactsView = (props: any) => {
  const basePath = '/members';
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const filterContext = useContext(FilterDataContext);
  const populationContextData = getFilterDataByType(filterContext, POPULATION_FILTER_TYPE)


  const [stateData, setStateData] = useState({
    showBulkActionTopBar: false,
    selectedViewCode: '',
    searchString: '',
    filtersApplied: [],
    selectedTagsLabels: [],
    personType: '',
    selectedActionCode: '',
    selectedRawData: {} as any,
    selectedExtraData: {} as any,
    buttonList: [],
    myPatientList: [],
    selectedGroups: []
  });

  const [myPatientCount, setMyPatientCount] = useState<number>(0);
  const updateMyPatientTabState = useCallback((count: number) => {
    setMyPatientCount(count)
  }, []);

  const [allPatientCount, setAllPatientCount] = useState<number>(0);
  const updateAllPatientTabState = useCallback((count: number) => {
    setAllPatientCount(count)
  }, []);

  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const mlovData = useContext(CommonDataContext);
  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled;
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, mlovData);
  const disableEmployerFlag = !isAccountConfigEnabled(CONFIG_CODES.DISABLE_EMPLOYER_TAB)
  const isMasterAccountFlag = isMasterAccount()
  const isChildAccountFlag = isChildAccount()
  const contactTypeUuid = getContactTypeId(
    'CUSTOMER'
  );
  const userSettings = mlovData.userSettings;
  const contractEnabled = isContractsEnabled(userSettings);
  const enableCareProgram = isEnableCareProgram(userSettings);
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const [topbarBtnConfig, setTopbarBtnConfig] = useState({
    hideAddButton: false,
  });
  const [getContactCounts] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_COUNT,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      onError: (error: any) => {

      },
    }
  );
  const [getAllContactCount] = useLazyQuery(LeadQueries.contactAggregateOnLocation,{
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  })
  const [getMyPatientCounts] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_COUNT,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      onError: (error: any) => {

      },
    }
  );
  const [getEmployersCount] = useLazyQuery(EmployerQueries.getEmployersCount, {
    fetchPolicy: 'no-cache',
  });
  const [getMyPatient] = useLazyQuery(GET_MY_PATIENT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const userUuid = getUserUUID();

  const [tabsCountData, setTabsCountData] = useState<{
    allContacts: number;
    myPatient: number;
    populationGroups: number;
    employers: number;
    contractCount: number
  }>({
    allContacts: 0,
    populationGroups: 0,
    myPatient: 0,
    employers: 0,
    contractCount: 0
  });


  const getPatientTab = (): ITabsList => {
    return {
      key: 'patient',
      title: 'All Patients',
      path: 'patient',
      count: allPatientCount ?? 0,
      tabContainerElem: () => {
        return (
          <AllMembersView
            contactType="patient"
            selectedActionCode={stateData.selectedActionCode}
            selectedRawData={stateData.selectedRawData}
            selectedExtraData={stateData.selectedExtraData}
            onActionPerformed={onActionPerformed}
            updateContactCount={(contactCount) => {
              updateAllPatientTabState(contactCount);
            }}
            tab={'patient'}
          />
        );
      },
    } as ITabsList;
  };

  const getMyPatientTab = (): ITabsList => {
    return {
      key: 'patient',
      title: 'My Patients',
      path: 'myPatient',
      count: myPatientCount ?? 0,
      tabContainerElem: () => {
        return (
          <AllMembersView
            contactType="patient"
            selectedActionCode={stateData.selectedActionCode}
            selectedRawData={stateData.selectedRawData}
            selectedExtraData={stateData.selectedExtraData}
            onActionPerformed={onActionPerformed}
            updateContactCount={(contactCount) => {
              updateMyPatientTabState(contactCount);
            }}
            tab={'myPatient'}
          />
        );
      },
    } as ITabsList;
  };
  const tagsTabList = (): ITabsList[] => {
    let tabList: ITabsList[] = [];
    const defaultTabCodePath = enableCareProgram ? 'myPatient' : 'patient';
    const patientTab: ITabsList = getPatientTab();
    const myPatientTab: ITabsList = getMyPatientTab();

    if (patientTab?.path === defaultTabCodePath) {
      tabList.unshift(patientTab);
    } else {
      tabList.push(patientTab);
    }
    if (!isMasterAccountFlag) {
      if (myPatientTab?.path === defaultTabCodePath) {
        tabList.unshift(myPatientTab);
      } else {
        tabList.push(myPatientTab);
      }
    }
    const array: ITabsList[] = [
      ...( !isMasterAccountFlag ? [{
        key: 'groups',
        title: 'Population Groups',
        path: 'groups',
        count: tabsCountData.populationGroups ?? 0,
        tabContainerElem: () => {
          return (
            <CampaignManagementSegmentView
              selectedActionCode={stateData.selectedActionCode}
              selectedRawData={stateData.selectedRawData}
              onActionPerformed={onActionPerformed}
              updatePopulationGroupCount={(popGroupCount: number) => {
                setTabsCountData((prev) => {
                  return {
                    ...prev,
                    populationGroups: popGroupCount,
                  }
                })
              }}
            />
          );
        },
      }] : []),
      ...(  disableEmployerFlag && !isChildAccountFlag
        ? [
            {
              key: 'employers',
              title: isVbcTenant ? 'Contracts': 'Employers',
              path: 'employers',
              count: tabsCountData.employers ?? 0,
              tabContainerElem: () => (
                <EmployerManagement
                  showTitleSubtitle={false}
                  selectedActionCode={stateData.selectedActionCode}
                  selectedRawData={stateData.selectedRawData}
                  onActionPerformed={onActionPerformed}
                  updateEmployersCount={(employersCount) => {
                    setTabsCountData((prev) => {
                      return {
                        ...prev,
                        employers: employersCount,
                      }
                    })
                  }}
                />
              ),
            },
          ]
        : [])
    ];

    tabList = tabList.concat(array)
    if(contractEnabled) {
      tabList.push({
        key: 'contracts',
        title: 'Contracts',
        path: 'contracts',
        count: tabsCountData.contractCount ?? 0,
        tabContainerElem: () => (
          <Contracts
            searchString={stateData.selectedRawData?.searchString}
          />
        ),
      })
    }
    return tabList;
  };
  const onActionPerformed = (
    tabCode: any,
    rawData?: any,
    extraData?: any
  ): any => {
    switch (tabCode) {
      case COMMON_ACTION_CODES.RESET:
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: '',
            selectedRawData: '',
            selectedExtraData: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.BULK_EMAIL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.BULK_SMS:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_GROUP:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CLEAR_MAIL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        const showBulkActionTopBar = rawData.length > 0 ? true : false;
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            showBulkActionTopBar: showBulkActionTopBar,
          };
        });
        break;
      case COMMON_ACTION_CODES.ADD:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.IMPORT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        if(selectedTab.key === TAB_KEYS.GROUPS){
          savePopulationGroupFilterData(rawData?.searchString || '')
        }
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.SAVE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: '',
            filtersApplied: rawData,
          };
        });
        break;
      case 'BUTTON_LIST':
        setStateData((prev) => {
          return {
            ...prev,
            buttonList: rawData,
          };
        });
        break;

      case COMMON_ACTION_CODES.QUICK_CREATE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CREATE_EMPLOYER:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.MANAGE_DEAL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      case COMMON_ACTION_CODES.SELECT_GROUP:
        setStateData((prev) => {
          return {
            ...prev,
            selectedGroups: rawData
          };
        });
        break;

      default:
        break;
    }
    return;
  };
  const downloadButtonClick = () => {
    onActionPerformed(COMMON_ACTION_CODES.IMPORT, {});
  };
  const createLeadButtonClick = () => {
    onActionPerformed(COMMON_ACTION_CODES.EDIT, {});
  };

  const handleSearchChange = (actionData: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        searchString: actionData.searchString,
      };
    });
  };

  const savePopulationGroupFilterData = (searchString: string) => {
    const filterData = {
      type: POPULATION_FILTER_TYPE,
      searchString: searchString
    }
    filterContext?.saveFilterData?.(filterData)
  }

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any,
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.SEARCH, actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH, actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW, {
          selectedViewCode: 'FILTER_VIEW',
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: ''
          };
        });
        break;
    }
  };

  const quickActionList = [
    {
      displayString: 'quickCreate',
      actionCode: COMMON_ACTION_CODES.QUICK_CREATE,
    },
    {
      displayString: 'createEmployer',
      actionCode: COMMON_ACTION_CODES.CREATE_EMPLOYER,
    },
  ];

  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {quickActionList.map((action: any, index: any) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              onActionPerformed(action.actionCode);
            }}
          >
            <HStack
              style={[
                {
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 2,
                  // cursor: 'pointer',
                },
                {
                  paddingTop: 10,
                },
              ]}
            >
              <Text style={{marginLeft: 10}}>
                <DisplayText
                  size={'smMedium'}
                  textLocalId={action.displayString}
                />
              </Text>
            </HStack>
          </Pressable>
        );
      })}
    </VStack>
  );

  const getTopBarButtons = () => {
    if (openInHint && topbarBtnConfig.hideAddButton || (isMasterAccountFlag)) {
      return [];
    } else  {
      return [
        {
          btnText: 'newEmployer',
          btnClick() {
            //setIsOpen(true)
          },
          id: 2,
          size: 'smBold',
          textColor: Colors.Custom.mainPrimaryPurple,
          borderColor: Colors.Custom.mainPrimaryPurple,
          backgroundColor: '#ffffff00',
          variant: 'PRIMARY',
          style: {
            paddingHorizontal: 16
          },
          leftIcon: (
            <PlusIcon />
          ),
          content: content,
        },
      ];
    }

  };

  const getHintConfig = async () => {
    setTopbarBtnConfig((prev) => {
      return {
        ...prev,
        hideAddButton: openInHint,
      };
    });
  };

  const showAutomationTab = isAutomationAllowed();

  const getAutomationSettingsButtonVisibility = () => {
      if (
      stateData.showBulkActionTopBar &&
      (selectedTab.key === TAB_KEYS.PATIENT ||
        selectedTab.key === TAB_KEYS.MY_PATIENT ||
        selectedTab.key === TAB_KEYS.PROSPECT)
    ) {
      return false;
    } else if (
      (stateData.selectedGroups?.length && selectedTab.key === 'groups') ||
      selectedTab.key === TAB_KEYS.GROUPS ||
      selectedTab.key === 'employers' ||
      selectedTab.key === 'contracts'
    ) {
      return false
    }
     else {
      return true;
    }
  };

  const getTopBarCode = () => {
    let tableTopbarEle:any = false;
    let placeHolderText = 'Search';
    if (selectedTab.key === 'patient' || selectedTab.key === 'prospect' || selectedTab.key === 'myPatient') {
      placeHolderText = 'Search by name, email, phone or DOB';
    }
    if (stateData.showBulkActionTopBar) {
      tableTopbarEle = (
        <>
          <TableTopBar
            selectedTab={selectedTab.key}
            alignSearchBarFlexEnd={true}
            placeHolderText={placeHolderText}
            isDividerNotVisible={true}
            hStackMinHight={'auto'}
            searchInputStyle={{minWidth: 340}}
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            topContainerStyle={{borderRadius: 0}}
            hideSearchBar={false}
            {...getBulkActionTopBarButton(
              [
                onBulkEmailClicked,
                onBulkEmailSms,
                onCLearAllMail,
                onCreateCampaign,
                onCreateAutomation,
                onCreateGroup,
              ],
              onActionPerformed,
              //mainTableState.topBarTitle
              '',
              showAutomationTab
            )}
          />
        </>
      );
    } else {
      tableTopbarEle = (
        <TableTopBar
          selectedTab={selectedTab.key}
          placeHolderText={placeHolderText}
          isDividerNotVisible={true}
          alignSearchBarFlexEnd={true}
          hStackMinHight={'auto'}
          searchInputStyle={{width: 340}}
          topContainerStyle={{borderRadius: 0}}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          {...(isMasterAccountFlag
            ? {} as ITableTopBarProps
            : {
                ...getLeadListTopBarButtons(
                  [downloadButtonClick, createLeadButtonClick],
                  onActionPerformed,
                  '',
                  stateData.personType || '',
                  stateData.selectedTagsLabels,
                  {},
                  canCreatePatient
                )
              })}
          showFilterIcon={true}
          appliedFilters={stateData.filtersApplied}
        />
      );
    }
    return tableTopbarEle;
  };
  const onBulkEmailClicked = () => {
    onActionPerformed(COMMON_ACTION_CODES.BULK_EMAIL);
  };
  const onBulkEmailSms = () => {
    onActionPerformed(COMMON_ACTION_CODES.BULK_SMS);
  };
  const onCLearAllMail = () => {
    onActionPerformed(COMMON_ACTION_CODES.CLEAR_MAIL);
  };
  const onCreateCampaign = () => {
    onActionPerformed(COMMON_ACTION_CODES.CREATE_CAMPAIGN);
  };
  const onCreateAutomation = () => {
    onActionPerformed(COMMON_ACTION_CODES.CREATE_AUTOMATION);
  };
  const onCreateGroup = () => [
    onActionPerformed(COMMON_ACTION_CODES.CREATE_GROUP),
  ];
  const screenDimensions = Dimensions.get('window');

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab.key === 'patient' || selectedTab.key === 'prospect' || selectedTab.key === 'myPatient') {
      ele = getTopBarCode();
    } else if (selectedTab.key === 'groups') {
      ele = (
        <TableTopBar
          selectedTab={selectedTab.key}
          title={''}
          searchText={populationContextData?.searchString || ''}
          showFilterIcon={stateData.selectedGroups?.length ? false : true}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchBarWidth={stateData.selectedGroups?.length <= 1 ? screenDimensions?.width < 1500 ? 150 : screenDimensions?.width < 1600 ? 200 : '' : ''}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          appliedFilters={populationContextData?.appliedFilters || stateData.filtersApplied}
          buttonList={isMasterAccountFlag ? [] : stateData.buttonList}
        />
      );
    } else if (selectedTab.key == 'employers') {
      ele = (
        <TableTopBar
          selectedTab={selectedTab.key}
          title=""
          alignSearchBarFlexEnd={true}
          hStackMinHight={70}
          isDividerNotVisible={true}
          //headerElement={renderHeaderTabs()}
          // popOverClick={isOpen}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          buttonList={getTopBarButtons()}
        />
      );
    } else if (selectedTab.key == 'contracts') {
      ele = (
        <TableTopBar
          selectedTab={selectedTab.key}
          title=""
          alignSearchBarFlexEnd={true}
          hStackMinHight={70}
          isDividerNotVisible={true}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
        />
      );
    }
    return ele;
  };
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setStateData((prev) => {
      return {
        ...prev,
        personType:
          tab.key == 'patient'
            ? 'CUSTOMER'
            : tab.key == 'prospect'
            ? 'LEAD'
            : '',
        filtersApplied: tab.key == 'patient' ? prev.filtersApplied : [],
      };
    });
  };
  useEffect(() => {
    getTabsCount();
    if (isVbcTenant) {
      setTopbarBtnConfig((prev) => {
        return {
          ...prev,
          hideAddButton: true,
        };
      });
    } else {
      getHintConfig();
    }
  }, []);

  useEffect(() => {
    const tagsList = tagsTabList();
    tagsList.some((tab: any) => {
      if (location?.pathname?.indexOf(tab.path) != -1) {
        setSelectedTab(tab);
        setStateData((prev) => {
          return {
            ...prev,
            personType:
              (tab.key == 'patient' || tab.key == 'myPatient')
                ? 'CUSTOMER'
                : tab.key == 'prospect'
                ? 'LEAD'
                : '',
          };
        });
      }
    });
  }, [location?.pathname]);

  const fetchAllContactCount = async () => {
    const filterobjtec = getSearchContactsFilterObject({
      contactTypeId: contactTypeUuid,
      mlovData
    })
    const response = await getAllContactCount({
      variables: {
        params: filterobjtec
      }
    })
    return response?.data?.searchContacts?.contactAggregate?.aggregate?.count
  }
  const fetchMyPatientCount = async () => {
    const filterobjtec = getSearchContactsFilterObject({
      contactTypeId: contactTypeUuid,
      mlovData,
      tab: 'myPatient'
    })
    const response = await getAllContactCount({
      variables: {
        params: filterobjtec
      }
    })
    return response?.data?.searchContacts?.contactAggregate?.aggregate?.count
  }
  const getTabsCount = async () => {
    
    const allContactCount = await fetchAllContactCount()
    updateAllPatientTabState(allContactCount);
    
    const myPatientCount = await fetchMyPatientCount()
    updateMyPatientTabState(myPatientCount);
    
    if(!populationContextData){
      getGroupsList(accountUuid, 1, '').then((responseData) => {
        const populationGroupCount = responseData?.data?.total;
        if (populationGroupCount) {
          setTabsCountData((prev) => {
            return {
              ...prev,
              populationGroups: populationGroupCount,
            }
          })
        }
      });
    }
    // employer count fetch
    getEmployersCount({
      variables: {
        employersBoolExp: {
          accountUuid: {_eq: accountUuid},
        },
      },
    }).then((responseData) => {
      const employersCount = responseData?.data?.employerAggregate?.aggregate?.count;
      if (employersCount) {
        setTabsCountData((prev) => {
          return {
            ...prev,
            employers: employersCount,
          }
        })
      }
    });
  };

  return (
    <>
      <View flex={1} style={{backgroundColor: '#fff'}}>
        <View style={{backgroundColor: '#fff'}}>
          <TitleSubtitleView />
        </View>
        <CustomTabsView
          contentTopMargin={0}
          showHeading={true}
          heading={selectedTab?.key}
          canNavigate={true}
          basePath={basePath}
          hideAutomationSettingsButton={!getAutomationSettingsButtonVisibility()}
          tabsList={tagsTabList()}
          extraBigRightView
          settingsPath={'/admin/patients/tags'}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            paddingVertical: 16,
            backgroundColor: '#fff',
          }}
          selectedTab={selectedTab}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          onTabClick={(tabItem) => {
            if (tabItem?.key !== selectedTab?.key) {
              const searchObj = {
                searchString: tabItem.key === TAB_KEYS.GROUPS && filterContext?.searchString ? filterContext?.searchString : ''
              }
              onActionPerformed(COMMON_ACTION_CODES.SAVE, [])
              onTableTopBarActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLEAR_SEARCH, searchObj);
            }
            onTabClick(tabItem);
          }}
          flowType={FlowType.patients}
        />
      </View>
    </>
  );
};

export default AllContactsView;
