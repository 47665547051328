import {useLazyQuery} from '@apollo/client';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import ContractsService, {getContracts} from '../../../services/Contracts/ContractsService';
import {getAccountUUID} from '../../../utils/commonUtils';
import ContractsList from './ContractsList';
import {IContractList, IContracts} from './HelperFiles/interface';
import {getFormattedContracts} from './HelperFiles/utils';

const Contracts = (props: IContracts) => {
  const {searchString} = props;
  const [stateData, setStateData] = useState({
    contractsList: [] as IContractList[],
    currentPage: 1,
    pageSize: 10,
    total: 10,
    offSet: 0,
    loading: false
  });
  const accountUuid = getAccountUUID();
  const [getContractQuery] = useLazyQuery(ContractsService.getContractQuery,{
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache'
  })
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offSet = currentPageSize * (page - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: page,
        offSet: offSet,
        pageSize: currentPageSize,
      };
    });
  };

  const fetchContracts = async (offSet?: number) => {
    setStateData(prev=> {
      return {
        ...prev,
        loading: true
      }
    })
    const contractResp = await getContractQuery({
      variables: {
        accountUuid: accountUuid,
        searchString: `%${searchString}%`,
        limit: stateData.pageSize,
        offset: offSet == 0 ? offSet : stateData.offSet
      }
    }).catch(()=> {
      setStateData((prev) => {
        return {
          ...prev,
          contractsList: [],
          loading: false,
          total: 0
        };
      });
    })
    if (contractResp?.data?.selectQmContract?.length) {
      const formattedContracts = getFormattedContracts(contractResp?.data)
      setStateData((prev) => {
        return {
          ...prev,
          contractsList: formattedContracts,
          loading: false,
          total: contractResp?.data?.aggregateQmContracts?.aggregate?.count || 0
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          contractsList: [],
          loading: false,
          total: 0
        };
      });
    }
  };
  useEffect(() => {
    fetchContracts();
  }, [stateData.currentPage, stateData.pageSize]);

  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offSet: 0,
        };
      });
    } else {
      fetchContracts(0);
    }
  }, [searchString]);
  return (
      <ContractsList
        handlePageChange={handlePageChange}
        stateData={stateData}
        data={stateData.contractsList}
      />
  );
};
export default Contracts;
