export interface ITaskPoolTableData {
  newPoolUsers: string[];
  newPoolName: string;
  poolId?: string;
  poolUsers?: string[];
  isDefault?: string;
  poolLocations: any;
}

export interface IAddPoolModalProps {
  title: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  onSave: (newPool: ITaskPoolTableData) => void;
  usersData: any;
  selectedTaskData?: any;
  isEdit: boolean;
}

export const TASK_ACTIONS_TYPE = {
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add',
};

export interface ITaskManagementInterface {
  showTableTopBar?: boolean;
  searchString?: string;
  selectedActionCode?: string;
  selectedRawData?: any;
  onClose?: (actionCode: string, actionData?: any) => void;
}