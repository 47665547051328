import { Button, View } from 'native-base';
import React, {useContext} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import { COMMON_ACTION_CODES } from '../../../../../../../constants/ActionConst';
import { getOnFocusButtonStyles, isCloneJourneyEnabled } from '../../../../../../../utils/commonUtils';
import { styles } from './ActionsViewStyles';
import { Tooltip } from 'antd';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import { Colors } from '../../../../../../../styles';

interface IActionViewProps {
  selectedRecord: any;
  onActionClick: (actionCode: string, actionData: any) => void;
}

const ActionsView = (props: IActionViewProps) => {
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const cloneJourneyEnabled = isCloneJourneyEnabled(userSettings);
  return (
    <View style={styles.iconView}>
      <Tooltip
        title={
          "Journey Overview"
        }
      >
        <Button
          cursor={props?.selectedRecord?.isDisabled ? 'not-allowed' : 'pointer'}
          disabled={props?.selectedRecord?.isDisabled}
          onPress={() => {
            props.onActionClick(COMMON_ACTION_CODES.VIEW_ANALYTICS, props.selectedRecord);
          }}
          leftIcon={<Feather name="bar-chart" size={17} style={[
            styles.icons,
            { color: props?.selectedRecord?.isDisabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray600 },
          ]} />}
          style={styles.btn}
          _focus={{
            ...getOnFocusButtonStyles(),
          }}
        />
      </Tooltip>
      <Tooltip
        title={
          "Clone"
        }
      >
      <Button
        onPress={() => {
          props.onActionClick(COMMON_ACTION_CODES.CLONE_JOURNEY, props.selectedRecord);
        }}
        leftIcon={<Feather name="copy" size={17} style={[styles.icons]} />}
        style={styles.btn}
        _focus={{
          ...getOnFocusButtonStyles(),
        }}
      />
      </Tooltip>
      <Tooltip
        title={
          "Edit"
        }
      >
      <Button
        disabled={props?.selectedRecord?.isDisabled}
        onPress={() => {
          props.onActionClick(COMMON_ACTION_CODES.EDIT, props.selectedRecord);
        }}
        leftIcon={
          <Feather name="edit-2" size={17} 
            style={[
              styles.icons, 
              { color: props?.selectedRecord?.isDisabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray600 },
            ]}
          />
          }
        style={styles.btn}
        _focus={{
          ...getOnFocusButtonStyles(),
        }}
      />
      </Tooltip>

      {/* <IconButton
        colorScheme="gray"
        tintColor="gray.500"
        variant="ghost"
        onPress={() => {
          props.onActionClick(COMMON_ACTION_CODES.CLONE, props.selectedRecord);
        }}
        icon={
          <Icon
            as={AntIcon}
            name="copy1"
            size="5"
            color={Colors.Custom.Gray600}
            {...testID(TestIdentifiers.clone)}
          />
        }
      /> */}
      <Tooltip
        title={
          "Delete"
        }
      >
      <Button
        cursor={props?.selectedRecord?.isDisabled ? 'not-allowed' : 'pointer'}
        disabled={props?.selectedRecord?.isDisabled}
        onPress={() => {
          props.onActionClick(COMMON_ACTION_CODES.DELETE, props.selectedRecord);
        }}
        leftIcon={
          <Icon name="delete" size={17}
            style={[
              styles.icons,
              { color: props?.selectedRecord?.isDisabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray600 },
            ]}
          />
        }
        style={styles.btn}
        _focus={{
          ...getOnFocusButtonStyles(),
        }}
      />
      </Tooltip>
    </View>
  );
};

export default ActionsView;
