import {UploadFile} from 'antd';
import { RcFile } from 'antd/lib/upload';
import CareStudioService from '../../../../services/CommonService/CareStudioService';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { getBase64 } from '../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { IMediaLibraryData } from '../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import { WORD_DOC_MIME_TYPES } from '../../DocumentViewer/DocumentViewerHelper';

export interface AttachmentComponentState {
    previewObj?: { url: string; type: string; fileMediaData: IMediaLibraryData };
    isPreviewVisible: boolean;
    previewTitle?: string;
    previewImage?: any;
    isFileLoading: { [index: string]: boolean };
  }

export const handleAttachmentPreview = async (
  file: UploadFile,
  setComponentState: React.Dispatch<React.SetStateAction<AttachmentComponentState>>,
  toast: any
) => {
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();

  setComponentState((prev) => {
    if (prev.isFileLoading[file.uid]) {
      return prev;
    }
    return {
      ...prev,
      isFileLoading: {
        ...prev.isFileLoading,
        [file.uid]: true,
      },
    };
  });

  let fileUrl = file?.url || '';

  if (
    (file as any).id &&
    (!(file as any).blobUrl ||
      WORD_DOC_MIME_TYPES.includes(file.type as string))
  ) {
    await downloadFileAsBlob(file, setComponentState, toast, careStudioInstance);
  }

  if (
    !(file as any).id &&
    file?.type &&
    WORD_DOC_MIME_TYPES.includes(file?.type)
  ) {
    await convertToPdf(file, setComponentState, toast, careStudioInstance);
  }

  const fileMediaData: IMediaLibraryData = {
    ...file,
    lastUpdated: file.lastModified?.toString() || '',
    mime: file.type as string,
    name: file.name,
    size: file?.size as number,
  };

  if (!(file as any).blobUrl && !file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj as RcFile);
  }

  if (file.type && !fileUrl) {
    fileUrl =
      (file as any).blobUrl ||
      file.preview ||
      URL.createObjectURL(file.originFileObj as any);
  }

  setComponentState((prev) => ({
    ...prev,
    isPreviewVisible: true,
    previewTitle:
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    ...(file.type &&
      fileUrl && {
        previewObj: {
          url: fileUrl || '',
          type: file.type as string,
          fileMediaData: fileMediaData,
        },
      }),
  }));
};

const downloadFileAsBlob = async (
  file: any,
  setComponentState: React.Dispatch<React.SetStateAction<AttachmentComponentState>>,
  toast: any,
  careStudioInstance: any,
  downloadFile = false
) => {
  const attachmentId: string = (file as any).id;
  const service = careStudioInstance.careStudioAxiosService;

  setComponentState((prev) => {
    prev.isFileLoading[file.uid] = true;
    return { ...prev, isFileLoading: prev.isFileLoading };
  });

  try {
    if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
      const response = await service.get(
        `/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`,
        { responseType: 'blob' }
      );
      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);
    }

    if (downloadFile) {
      if (isWeb()) {
        const link = document.createElement('a');
        link.href = file.blobUrl;
        link.download = file.name;
        link.click();
      } else {
        showToast(toast, 'Unable to download file', ToastType.error);
      }
    }

    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = false;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });
  } catch (error) {
    showToast(toast, 'Error while opening file', ToastType.error);
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = false;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });
  }
};

const convertToPdf = async (
  file: any,
  setComponentState: React.Dispatch<React.SetStateAction<AttachmentComponentState>>,
  toast: any,
  careStudioInstance: any
) => {
  const service = careStudioInstance.careStudioAxiosService;

  setComponentState((prev) => {
    prev.isFileLoading[file.uid] = true;
    return { ...prev, isFileLoading: prev.isFileLoading };
  });

  try {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    const response = await service.post(`/attachment/convert-to-pdf`, formData, {
      responseType: 'blob',
    });

    file.blob = response.data;
    file.blobUrl = URL.createObjectURL(file.blob);
    file.preview = await getBase64(response.data);

    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = false;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });
  } catch (error) {
    showToast(toast, 'Error while opening file', ToastType.error);
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = false;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });
  }
};
