import { Divider, HStack, Pressable, View, Text, Spinner } from "native-base";
import { DisplayText } from "../../../common/DisplayText/DisplayText";
import { COMMON_ACTION_CODES } from "../../../../constants/ActionConst";
import { Colors } from "../../../../styles/Colors";
import { LLMTemplateViewCodes, LLM_VIEW_ROLE, ROLE_STRING } from "./llmTemplatesConsts";
import Feather from "react-native-vector-icons/Feather";
import { Popover, Switch } from "antd";
import { updateTemplateStatus } from "./llmTemplateUtils";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_BY_UUID_LIST } from "../../../../services/User/UserQueries";
import { styles } from "./llmTemplateStyles";
import { SingleLLMTemplate } from "./interfaces";
import UserGroupIcon from "../../../../assets/Icons/UserGroupIcon";
import SingleUserIcon from "../../../../assets/Icons/SingleUserIcon";
import LockKeyholeIcon from "../../../../assets/Icons/LockKeyholeIcon";



export const RoleView = (
  props: {
    template?: SingleLLMTemplate;
    roleList: any;
    userList: any;
  }
) => {
  const [userRoleUUIDs, setUserRoleUUIDs] = useState(props?.template?.userRoleIds || [])
  const [userUUIDs, setUserUUIDs] = useState(props?.template?.userIds || [])
  const { template } = props;
  const accessRole = template?.accessRole;

  switch (accessRole) {
    case LLM_VIEW_ROLE.SELECTED_USERS:
      const getFirstUserName = () => {
        const firstUserUuid = userUUIDs.length > 0 ? userUUIDs[0] : '';
        const firstUser = props?.userList?.find((user: any) => user?.uuid === firstUserUuid)
        return firstUser?.name || 'Unknown User';
      };
      const remainingUsers = userUUIDs.slice(1);

      return (
        <View flexDirection={'row'} gap={1}>
          <View style={styles.accessRoleCardStyle} paddingX={1.5} paddingY={1}>
            <SingleUserIcon />
            <Text style={styles.tableContent} numberOfLines={1}>{getFirstUserName()}</Text>
          </View>
          <Popover
            placement= 'bottom'
            content={
              <View>
                {remainingUsers.map((uuid: any) => {
                  const user = props?.userList?.find((user: any) => user.uuid == uuid)
                  return <Text>{user ? user?.name : 'Unknown User'}</Text>
                })}
              </View>
            }
          >
            {remainingUsers.length ? (
              <View borderRadius={4} paddingX={1.5} paddingY={1} backgroundColor={Colors.FoldPixel.GRAY50}>
                <Text style={styles.tableContent}>
                  {`+ ${remainingUsers.length}`}
                </Text>
              </View>
            ) : (
              <></>
            )}
          </Popover>
        </View>
      );

    case LLM_VIEW_ROLE.PRIVATE:
      return (
        <View style={styles.accessRoleCardStyle} paddingX={1.5} paddingY={1}>
          <LockKeyholeIcon />
          <Text style={styles.tableContent}>{ROLE_STRING.PRIVATE}</Text>
        </View>
      )
    case LLM_VIEW_ROLE.ALL_USERS:
      return (
        <View style={styles.accessRoleCardStyle} paddingX={1.5} paddingY={1}>
          <UserGroupIcon />
          <Text style={styles.tableContent}>{ROLE_STRING.ALL_USERS}</Text>
        </View>
      )
    case LLM_VIEW_ROLE.SELECTED_ROLES:
      const getFirstRoleName = () => {
        const firstUUID = userRoleUUIDs.length > 0 ? userRoleUUIDs[0] : '';
        const firstRole = props?.roleList.find((role: any) => role?.roleId === firstUUID);
        return firstRole?.userRole?.value || ''
      }
      const remainingUUIDs = userRoleUUIDs.slice(1);
      return (
        <View flexDirection={'row'} gap={1}>
          <View style={styles.accessRoleCardStyle} paddingX={1.5} paddingY={1}>
            <UserGroupIcon />
            <Text style={styles.tableContent} numberOfLines={1}>{getFirstRoleName()}</Text>
          </View>
          <Popover
          placement='bottom'
            content={
              <View>
                {remainingUUIDs.map((uuid) => {
                  const role = props?.roleList.find((role: any) => role?.roleId === uuid);
                  return <Text key={uuid}>{role ? role?.userRole?.value : 'Unknown Role'}</Text>;
                })}
              </View>
            }
          >
            {remainingUUIDs.length ?
              <View borderRadius={4} paddingX={1.5} paddingY={1} backgroundColor={Colors.FoldPixel.GRAY50}>
                <Text style={styles.tableContent}>
                  {`+ ${remainingUUIDs.length}`}
                </Text>
              </View> : <></>
            }
          </Popover>
        </View>
      )
    default:
      return <></>
  }
}

export const RenderCreatedBy = (
  props: {
    userUuid: string
  }
) => {

  const [userDetail, setUserDetails] = useState<any>();
  const getUserByUuidList = useQuery(GET_USER_BY_UUID_LIST, {
    variables: {
      uuidList: [props.userUuid],
    },
    onCompleted: (data: any) => {
      setUserDetails(data?.users[0])
    },
  });

  return (
    <Text style={styles.tableContent}>
      {userDetail?.name}
    </Text>
  )
}

export const GetCloseAndContinue = (
  props: {
    isPressed: boolean;
    onActionPerformed: any;
    viewCode: string;
    adminAccess: boolean;
    isDefaultTemplate?: boolean | false;
  }) => {

  return (
    <HStack alignItems={'end'} space={2} paddingX={5} >
      {(props?.adminAccess || !props?.isDefaultTemplate) &&
        <>
          <Pressable
            disabled={props.isPressed}
            onPress={() => {
              props.onActionPerformed(COMMON_ACTION_CODES.SAVE, LLMTemplateViewCodes.LIST);
            }}
          >
            <View
              style={{
                backgroundColor: Colors.primary[300],
                height: 32,
                justifyContent: 'center',
                paddingHorizontal: 8,
                borderRadius: 4,
                minWidth: props.viewCode == LLMTemplateViewCodes.EDIT ? 70 : 65
              }}
            >
              {props.isPressed ? <Spinner></Spinner> :
                <DisplayText textLocalId={props.viewCode == LLMTemplateViewCodes.EDIT ? 'Update' : 'Create'} size={'smBold'} extraStyles={{ color: 'white' }} />
              }
            </View>
          </Pressable>

          <Divider orientation='vertical' backgroundColor={Colors.FoldPixel.GRAY200} height={4} alignSelf='center'></Divider>
        </>}

      <Pressable
        onPress={() => {
          props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, LLMTemplateViewCodes.LIST);
        }}
      >
        <View justifyContent={'center'} height={8}>
          <Feather name="x" size={24} color={Colors.FoldPixel.GRAY300} />
        </View>
      </Pressable>
    </HStack>
  );
};


export const RenderStatusSwitch = (props: { data: any , isDisabled: boolean}) => {
  const [localIsActive, setLocalIsActive] = useState(props?.data?.isActive);

  const handleStatusChange = async (checked: boolean, record: any) => {
    try {
      setLocalIsActive(checked);
      await updateTemplateStatus(record?.id, checked, record);
    } catch (error) {
    }
  };

  return (
    <>
      <Switch
        disabled={props?.isDisabled || props?.data?.isDefault}
        size='small'
        style={{
          backgroundColor: localIsActive ? Colors.primary[300] : Colors.muted[500],
        }}
        checked={localIsActive}
        onChange={(value: boolean) => handleStatusChange(value, props?.data)}
      />
    </>
  );
};

