import {CLAIMS_MEMBER_STATUS} from './../../../../../constants/StringConst';
import {LazyQueryExecFunction, OperationVariables} from '@apollo/client';
import {
  ContactObject,
  ContactResponse,
  ContactResponseObject,
  DrillDownMemberListControllerArgs,
  DrillDownMemberListState
} from './interfaces';
import {IContactDrillDownDataList} from '../interface';

export class DrillDownMemberListController {
  private state: DrillDownMemberListState;
  private responseData: any;
  private contactUuidList: string[];
  private contactDrillDownData: IContactDrillDownDataList;
  private accountId: string | number;
  private createCampaignUrl = '/#/campaignManagement/create';
  private accountUuid: string;
  private setState: React.Dispatch<React.SetStateAction<DrillDownMemberListState>>;
  private getContactsByUuid: LazyQueryExecFunction<any, OperationVariables>;
  private onCompleteAction: () => void;

  constructor(args: DrillDownMemberListControllerArgs) {
    this.state = args.state;
    this.contactDrillDownData = args.contactDrillDownData;
    this.contactUuidList = args.contactUuidList;
    this.accountId = args.accountId;
    this.accountUuid = args.accountUuid;
    this.responseData = args.responseData;
    this.setState = args.setState;
    this.getContactsByUuid = args.getContactsByUuid;
    this.onCompleteAction = args.onCompleteAction;

    // binding
    this.getContactList = this.getContactList.bind(this);
    this.onMount = this.onMount.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.getFormattedContactData = this.getFormattedContactData.bind(this);
    this.getSelectedContacts = this.getSelectedContacts.bind(this);
    this.getContactPageMap = this.getContactPageMap.bind(this);
    this.handleSelectedContacts = this.handleSelectedContacts.bind(this);
  }

  onMount(contactUuidList: string[]) {
    this.contactUuidList = contactUuidList;
    this.getContactList();
  }

  handleOnClose() {
    this.onCompleteAction();
  }

  handleVisibleChange(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        popoverOpen: value,
      };
    });
  }

  private async getContactList() {

    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    try {
      if (this.contactUuidList?.length) {
        const response = await this.getContactsByUuid({
          variables: {
            contactUuidList: (this.contactUuidList || []).filter((uuid) => {return !!uuid}),
            accountId: this.accountId,
          },
          fetchPolicy: 'no-cache',
        });
        const contacts = response?.data?.contacts || [];
        const contactList = this.getFormattedContactData(contacts);
        const contactResponse = this.getContactResponseObjects(contacts);
        const contactDrillDownColumnData = this.getContactDrillDownColumnData(contactResponse, this.contactDrillDownData)
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            contactList: contactList,
            contactResponse,
            contactDrillDownColumnData: contactDrillDownColumnData
          };
        });
      } else if (this.responseData?.isNonMemberData && this.responseData?.contactDrillDownDataList?.length) {
        const contactList = this.getFormattedContactData(this.responseData?.contactDrillDownDataList, true);
        const contactResponse = this.getContactResponseObjects(this.responseData?.contactDrillDownDataList, true);
        const contactDrillDownColumnData = this.getContactDrillDownColumnData(contactResponse, this.contactDrillDownData)
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            contactList: contactList,
            contactResponse,
            contactDrillDownColumnData: contactDrillDownColumnData
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            contactList: [],
            contactResponse: [],
            contactDrillDownColumnData: []
          };
        });
      }

    } catch (error) {
      this.setState((prev) => {
        return {
          ...prev,
          loading: false,
          contactList: [],
          contactResponse: [],
        };
      });

    }
  }



  getFormattedContactData(contacts: ContactResponse[], isNonMemberData = false): ContactObject[] {
    const result: ContactObject[] = [];
    const map: any = {};
    if (contacts.length == 0) {
      return result;
    }
    contacts.forEach((item: any) => {
      if (isNonMemberData) {
        result.push({
          contact: {
            id: (item?.id || '')?.toString(),
            uuid: item?.uuid,
            email: item?.email || '',
            isActive: item?.isActive,
            birthDate: item?.person?.birthDate || item?.birthDate || '',
            firstName: item?.person?.firstName || item?.firstName || '',
            lastName: item?.person?.lastName || item?.lastName || '',
            genderCode: item?.person?.gender?.code || item?.genderCode || '',
            genderValue: item?.person?.gender?.value || item?.genderValue || '',
            name: item?.name,
            patientId: item?.patient?.patientId || '',
            patientUuid: item?.patient?.patientUuid || '',
            isSelected: false,
            phoneNumber: item?.phoneNumber,
            claimsMemberStatus: CLAIMS_MEMBER_STATUS.NON_MEMBER,
            tempDummyContactUuid: item?.tempDummyContactUuid,
            contactConsents: item?.contactConsents || [],
          },
        });
      } else {
        if (!map[item.uuid]) {
          map[item.uuid] = item;
          result.push({
            contact: {
              id: (item?.id || '')?.toString(),
              uuid: item?.uuid,
              email: item?.email || '',
              isActive: item?.isActive,
              birthDate: item?.person?.birthDate || item?.birthDate || '',
              firstName: item?.person?.firstName || item?.firstName || '',
              lastName: item?.person?.lastName || item?.lastName || '',
              genderCode: item?.person?.gender?.code || item?.genderCode || '',
              genderValue: item?.person?.gender?.value || item?.genderValue || '',
              name: item?.name,
              patientId: item?.patient?.patientId || '',
              patientUuid: item?.patient?.patientUuid || '',
              isSelected: false,
              phoneNumber: item?.phoneNumber,
              contactConsents: item?.contactConsents || [],
            },
          });
        }
      }

    });
    return result;
  }

  getContactResponseObjects(
    contacts: ContactResponse[],
    isNonMemberData = false
  ): ContactResponseObject[] {
    const result: ContactResponseObject[] = [];
    if (contacts.length == 0) {
      return result;
    }
    const map: any = {};
    contacts.forEach((item) => {
      if (isNonMemberData) {
        result.push({
          contact: {
            id: item?.id,
            name: item?.name,
            isActive: item?.isActive,
            person: item?.person,
            uuid: item?.uuid,
            email: item?.email,
            patient: item?.patient,
            phoneNumber: item?.phoneNumber,
            contactMemberships: item?.contactMemberships || [],
            additionalAttributes: item?.additionalAttributes || {},
            contactType: item?.contactType || {},
            claimsMemberStatus: CLAIMS_MEMBER_STATUS.NON_MEMBER,
            tempDummyContactUuid: item?.tempDummyContactUuid,
            contactConsents: item?.contactConsents || [],
            contactPracticeLocations: item?.contactPracticeLocations || [],
          },
        });
      } else {
        if (!map[item.uuid]) {
          map[item.uuid] = item;
          result.push({
            contact: {
              id: item?.id,
              name: item?.name,
              isActive: item?.isActive,
              person: item?.person,
              uuid: item?.uuid,
              email: item?.email,
              patient: item?.patient,
              phoneNumber: item?.phoneNumber,
              contactMemberships: item?.contactMemberships || [],
              additionalAttributes: item?.additionalAttributes || {},
              contactType: item?.contactType || {},
              contactConsents: item?.contactConsents || [],
              contactPracticeLocations: item?.contactPracticeLocations || [],
            },
          });
        }
      }

    });
    return result;
  }

  getContactDrillDownColumnData(contactResponse: ContactResponseObject[], contactDrillDownData: IContactDrillDownDataList) {
    if (contactDrillDownData?.isOnFirstView) {
      const finalContactColumnList: any[] = [];
      contactResponse.forEach((singleContactData) => {
        const otherColumnData = this.getFirstViewColumnDataForContact(singleContactData?.contact?.uuid, contactDrillDownData);
        const finalSingleContactAllColumnData: any = {...otherColumnData};
        finalSingleContactAllColumnData.contact = singleContactData?.contact;
        finalContactColumnList.push(finalSingleContactAllColumnData);
      });
      return finalContactColumnList;
    }
    return contactResponse as any[];
  }

  getFirstViewColumnDataForContact(contactUuid: string, contactDrillDownData: any) {
    let otherColumnData = {};
    const contactDataList = contactDrillDownData?.contactDrillDownDataList || [];
    for (let contactIndex = 0; contactIndex < contactDataList.length; contactIndex++) {
      const singleContact = contactDataList[contactIndex];
      if (contactUuid === singleContact?.contactUuid) {
        if (singleContact?.contactRowData && Object.keys(singleContact?.contactRowData)?.length) {
          const tempOtherColumnData: any = {};
          singleContact?.contactRowData.forEach((singleDataObj: any) => {
            tempOtherColumnData[singleDataObj?.columnKey] = singleDataObj?.value;
          });
          otherColumnData = {...tempOtherColumnData};
        }
        break;
      }
    }
    return otherColumnData;
  }

  getSelectedContacts(args: {selectedKeys: string[]}) {
    const {selectedKeys} = args;
    const selectedContacts: ContactObject[] = [];
    this.state.contactList.forEach((item) => {
      const check = selectedKeys.some((key) => key == item.contact.uuid);
      if (check) {
        selectedContacts.push(item);
      }
    });
    return selectedContacts;
  }

  handleSelectedContacts(selectedKeys: string[]) {
    const selectedContacts = this.getSelectedContacts({selectedKeys});
    const finalSelectedKeys = [
      ...new Set([...this.state.selectedRowKeys, ...selectedKeys]),
    ];
    const allContacts = [...this.state.selectedContacts, ...selectedContacts];
    const uuids = allContacts.map(function (obj) {
      return obj.contact.uuid;
    });
    const finalContactList = allContacts.filter(function (el, i, arr) {
      return uuids.indexOf(el.contact.uuid) == i;
    });
    return {finalSelectedKeys, finalContactList};
  }

  getContactPageMap() {
    const contactPageMap: {[index in number]: string[]} = {};
    const pageSize = this.state.pageSize;
    let index = 1;
    for (let i = 0; i < this.contactUuidList.length; i += pageSize) {
      const chunk = this.contactUuidList.slice(i, i + pageSize);
      contactPageMap[index] = chunk;
      index += 1;
    }
    return contactPageMap;
  }

}
