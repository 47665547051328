import { Drawer, Spin } from 'antd'
import React, { useContext, useRef, useState } from 'react'
import { View, Text, useWindowDimensions } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Colors } from '../../../styles/Colors';
import { AttachmentComponentState, handleAttachmentPreview } from '../../common/AddTask/AddEditTaskView/AttchmentUtils';
import { ITasksAttachments } from '../../common/CareDashboard/CareDashboardInterfaces';
import { DocumentViewer } from '../../common/DocumentViewer/DocumentViewer';
import Stack from '../../common/LayoutComponents/Stack';
import { ModalActionTitle } from '../../common/ModalActionTitle/ModalActionTitle';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { ISidecarInsightsDrawerState } from '../SidecarInsightsDrawer';
import { SidecarPatientInfoBanner } from '../SidecarPatientInfoBanner';
import { useIntl } from 'react-intl';
import { manageAttachmentsListData } from '../../common/AddTask/AddTaskUtils';
import { Divider, Pressable, useToast, VStack } from 'native-base';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { WORD_DOC_MIME_TYPES } from '../../common/DocumentViewer/DocumentViewerHelper';
import BackArrowSvg from '../../common/Svg/SideCarSvg/BackArrowSvg';
import AttachmentsBackArrowSvg from '../../common/Svg/SideCarSvg/AttachmentsBackArrowSvg';
import { getSidecarDrawerWidth } from '../SidecarUtils';

interface IAttachmentListProps {
    onCancel: () => void;
    contactData?: ISidecarInsightsDrawerState['contactData'];
    attachments?: ITasksAttachments[];
}

const getIconByFileType = (documentType?: string) => {
    if (documentType === 'application/pdf') {
        return (
            <MaterialCommunityIcons
                name="file-pdf-box"
                size={18}
                color={Colors.FoldPixel.PRIMARY300}
            />
        );
    } else if (documentType === 'image/gif') {
        return (
            <MaterialCommunityIcons
                name="file-gif-box"
                size={18}
                color={Colors.FoldPixel.PRIMARY300}
            />
        )
    } else if (documentType?.startsWith('image/')) {
        return (
            <MaterialCommunityIcons
                name="file-image"
                size={18}
                color={Colors.FoldPixel.PRIMARY300}
            />
        );
    } else if (documentType && WORD_DOC_MIME_TYPES.includes(documentType)) {
        return (
            <MaterialCommunityIcons
                name="file-word"
                size={18}
                color={Colors.FoldPixel.PRIMARY300}
            />
        );
    } else if (documentType && documentType.startsWith('audio/')) {
        return (
            <MaterialIcons
                name="audiotrack"
                size={18}
                color={Colors.FoldPixel.PRIMARY300}
            />
        );
    } else if (documentType && documentType.startsWith('video/')) {
        return (
            <MaterialCommunityIcons
                name="video"
                size={18}
                color={Colors.FoldPixel.PRIMARY300}
            />
        );
    }
    return (
        <Ionicons name="document-text" size={16} color={Colors.FoldPixel.PRIMARY300} />
    );
};



const AttachmentsList = (props: IAttachmentListProps) => {
    const { contactData, onCancel, attachments } = props;
    const { height, width } = useWindowDimensions();
    const intl = useIntl();
    const toast = useToast();
    const mlovData = useContext(CommonDataContext);
    const headerContainerRef: any = useRef();
    const isSidecarContext = mlovData.sidecarContext?.isSidecar;
    const [attachmentState, setAttachmentState] = useState<AttachmentComponentState>({
        isPreviewVisible: false,
        previewTitle: '',
        previewImage: '',
        isFileLoading: {},
    });

    const handlePreviewCancel = (value = false) => {
        setAttachmentState((prev) => ({
            ...prev,
            previewObj: undefined,
            isPreviewVisible: value,
            previewTitle: undefined,
        }));
    }

    return (
        <Drawer
            className={isSidecarContext ? 'custom-task-drawer-sidecar' : 'custom-task-drawer-web'}
            destroyOnClose
            placement="right"
            open={true}
            closable
            mask={isSidecarContext ? false : true}
            width={
                isSidecarContext ? '100%' : '50%'
            }
            title={
              <VStack>
                <ModalActionTitle
                    title={'Actionables'}
                    titleSize={24}
                    titleColor={'#000000'}
                    rightButton={
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <Pressable
                                onPress={() => {
                                    onCancel?.();
                                }}
                            >
                                <Icon name="close" size={20} color={Colors.Custom.Gray500} />
                            </Pressable>
                        </View>
                    }
                />

                <View ref={headerContainerRef} style={{ flex: 1, marginTop: '10px' }}>
                  {props?.contactData && (
                      <SidecarPatientInfoBanner
                          contactData={props?.contactData}
                          headerContainerRef={headerContainerRef}
                      />
                  )}
              </View>

              <Divider marginTop={'10px'} marginLeft={'-24px'} marginRight={'-24px'} width={width} backgroundColor={'#d0d6e1'} height={'1px'}/>

              <Stack direction={'row'} space={12} style={{ paddingVertical: 3, alignItems: 'center', marginTop: '7px' }}>
                <Pressable onPress={onCancel}>
                    <AttachmentsBackArrowSvg />
                </Pressable>
                <Text style={{ fontSize: 14, fontWeight: '500', color: Colors.Custom.HeadingColor }}>
                    {intl.formatMessage({ id: 'attachments' })}
                </Text>
            </Stack>
            </VStack>
            }
        >
            <Stack direction={'column'} style={{ borderWidth: 0.5, borderColor: Colors.FoldPixel.GRAY150, borderRadius: 8, backgroundColor: Colors?.Custom?.BackgroundColor }}>
                {
                    attachments?.map((attachment, index) => {
                        const isAttachmentLoading = !!attachment?.attachmentId && attachmentState?.isFileLoading?.[attachment?.attachmentId];
                        const isAnyAttachmentLoading = !!attachment?.attachmentId && Object.values(attachmentState?.isFileLoading).some(value => !!value);
                        return (
                            <>
                                <Stack direction={'row'} space={8} key={index} style={{ padding: 8 }}>
                                    {getIconByFileType(attachment.type)}
                                    <Text
                                        style={{
                                            color: Colors?.FoldPixel?.GRAY400,
                                            fontWeight: '500',
                                            fontSize: 14,
                                        }}
                                    >
                                        {attachment.name}
                                    </Text>
                                    <View style={{ flex: 1 }} />
                                    {
                                        isAttachmentLoading ? <Spin indicator={<LoadingOutlined spin />} size="small" style={{ marginTop: '-8px', color: Colors?.FoldPixel?.PRIMARY300 }} /> :
                                            <Pressable
                                                onPress={() => {
                                                    const file = {
                                                        ...attachment,
                                                        id: attachment.attachmentId,
                                                        uid: attachment.attachmentId,
                                                        name: attachment.name,
                                                        type: attachment.type,
                                                        status: 'done',
                                                    } as any;
                                                    handleAttachmentPreview(file, setAttachmentState, toast)
                                                }}
                                                isDisabled={isAnyAttachmentLoading}
                                                cursor={isAnyAttachmentLoading ? 'not-allowed' : 'pointer'}

                                            >
                                                <Text
                                                    style={{
                                                        color: Colors?.FoldPixel?.PRIMARY300, fontWeight: '400',
                                                        fontSize: 14,
                                                    }}>{intl.formatMessage({ id: 'viewFile' })}</Text>
                                            </Pressable>
                                    }

                                </Stack>
                                {
                                    index !== attachments?.length - 1 && <Divider />
                                }
                            </>
                        )
                    })
                }
            </Stack>
            {attachmentState.previewTitle &&
                attachmentState.previewObj?.type &&
                attachmentState.isPreviewVisible && (
                    <DocumentViewer
                        key={attachmentState.previewObj.fileMediaData.id}
                        fileName={attachmentState.previewTitle}
                        fileType={attachmentState.previewObj.type}
                        onClose={handlePreviewCancel}
                        fileUrl={attachmentState.previewObj.url}
                        isOpen={true}
                        mediaPlayerHeight={'75%'}
                        mediaPlayerWidth={'75%'}
                    />
                )}
        </Drawer>
    )
}

export default AttachmentsList
