const LineChartUpSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3332 22.3333H13.9998C10.0715 22.3333 8.10728 22.3333 6.88689 21.1129C5.6665 19.8925 5.6665 17.9283 5.6665 14V5.66663M19.8333 9.83329L17.235 13.1053C16.8369 13.6065 16.6379 13.8571 16.4095 13.9792C16.0582 14.167 15.6385 14.1762 15.2793 14.0038C15.0458 13.8917 14.8361 13.65 14.4166 13.1666C13.9972 12.6832 13.7875 12.4415 13.5539 12.3294C13.1948 12.1571 12.7751 12.1662 12.4238 12.354C12.1953 12.4761 11.9963 12.7267 11.5983 13.2279L8.99984 16.5"
        stroke="#6F7A90"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default LineChartUpSvg;
