export const CALL_CONFIGURATION_STATUS_CODE = {
  ACTIVE: 'ACTIVE',
  IN_ACTIVE: 'IN_ACTIVE'
}

export const CALL_CONFIGURATION_STATUS = [
  {
    title: 'Active',
    code: CALL_CONFIGURATION_STATUS_CODE.ACTIVE
  },
  {
    title: 'Inactive',
    code: CALL_CONFIGURATION_STATUS_CODE.IN_ACTIVE
  }
]

export const CALL_CONFIGURATION_TRANSFER_TO_CODE = {
  NUMBER: 'CALL_EXTENSION_PHONE_NUMBER',
  USER: 'CALL_EXTENSION_USER',
  DESK_PHONE: 'CALL_EXTENSION_DESK_PHONE'
}

export const CALL_CONFIGURATION_TRANSFER_TO = [
  {
    title: 'Number',
    code: CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER
  },
  {
    title: 'User',
    code: CALL_CONFIGURATION_TRANSFER_TO_CODE.USER
  },
  {
    title: 'Desk Phone',
    code: CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE
  },
]

export const EXCLUDED_EXTENSION_NUMBERS = ['911','988','411','711'];
export const CALL_EXTENSION_NAME_MAX_CHAR_LIMIT = 80;