import {useLazyQuery, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import { VStack, useToast} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {getAccountUUID} from '../../../utils/commonUtils';
import AppTabPermissionsTable from './AppTabPermissionsTable';
import permissionQueries from './permissionQueries';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import {SELECTED_VIEW_TYPE} from '../../../constants';
import AddRoleAndPermission from './AddRoleAndPermission';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import { MLOV_CATEGORY, USER_ROLE_CODES } from '../../../constants/MlovConst';
import { getCurrentSubdomain } from '../../../screens/MainScreen/MainScreenHelper';
import { getPermitRoles } from '../../../services/UserAccess/UserAccessServices';
const AppTabPermissions = (props: {parentTabCode: string, resetCheck?: () => void ,viewType?: string}) => {

  const intl = useIntl();
  const toast = useToast();
  const showToastMessage = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'userAccessUpdatedMsg'}),
      ToastType.info,
    );
  };
  const subdomain = getCurrentSubdomain();
  const [getAccountRoles] = useLazyQuery(
    permissionQueries.getAccountRoles
  );
  const [stateData, setStateData] = useState({
    userRoles: [] as {id: string,code: string, value: string}[] ,
    userRoleLoading: false
  })
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const variables = {
    entityCode: props.parentTabCode,
    accountUUID: getAccountUUID(),
  };
  const [isSetAllData, setIsSetAllData] = useState(false);
  const [getPermissionByEntity] = useLazyQuery(
    permissionQueries.GET_PERMISSION_BY_ENTITY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (response: any) => {
        response.userPermissions = response.userPermissions;
        setCurrentPermissions(response.userPermissions);
        setIsSetAllData(true);
      },
      onError: () => {
        alert('Error');
      },
    }

  );
  
  const permitRoles = async () => {
    const roles = await getPermitRoles(props.parentTabCode, subdomain).catch(
      (error: any) => {
        setCurrentPermissions([]);
      }
    );
    if (roles?.data) {
      setCurrentPermissions(roles?.data?.roles);
      setIsSetAllData(true);
    }
  }


  const onFormActionPerformed = async (code: string, data?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.CLOSE:
        props?.resetCheck?.()
        break;
      case COMMON_ACTION_CODES.SAVE:
        props?.resetCheck?.()
        await getPermissionByEntity({variables: {...variables}});
        await getAccountRolesFunc()
        break;
      default:
        break;
    }
  }
  const getAccountRolesFunc = async () => {
    setStateData(prev=> {
      return {
        ...prev,
        userRoleLoading: true
      }
    })
    const accountRolesResponse = await getAccountRoles({
      variables: {
        category: [MLOV_CATEGORY.USER_ROLES]
      }
    });
    if (accountRolesResponse.data?.accountRoles?.length) {
      const userRoles:{id: string,code: string, value: string}[] = []
      accountRolesResponse.data?.accountRoles.forEach(
        (accountRole: any) => {
          if (accountRole.customRoleCode === USER_ROLE_CODES.EMPLOYER) {
            return
          }
          userRoles.push( {
            id: accountRole.id,
            code: accountRole.customRoleCode,
            value: accountRole.roleName,
          })
        }
      );
      setStateData(prev=> {
        return{
          ...prev,
          userRoles: userRoles
        }
      })
    }
    setStateData(prev=> {
      return {
        ...prev,
        userRoleLoading: false
      }
    })
  };
  useEffect(()=> {
    getAccountRolesFunc()
  },[])

  useEffect(() => {
    getPermissionByEntity({variables: {...variables}});
  }, []);


  return (
    <VStack backgroundColor={'#fff'} height={'full'}>
      {!isSetAllData || stateData.userRoleLoading  ? (
        <Skeleton></Skeleton>
      ) : (
        <AppTabPermissionsTable
          showToast={showToastMessage}
          userRoles={stateData.userRoles}
          parentTabCode={props.parentTabCode}
          currentPermissions={currentPermissions}
          onSave={() => {
            setIsSetAllData(false);
            getPermissionByEntity({variables: {...variables}});
          }}
        ></AppTabPermissionsTable>
      )}
      {SELECTED_VIEW_TYPE.ADD_ROLE_DRAWER === props.viewType && (
      <AddRoleAndPermission onFormActionPerformed={onFormActionPerformed} />
      )}
    </VStack>
  );
};

export default AppTabPermissions;
