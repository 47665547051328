import {IUserRole} from "../../Interfaces"
import {IUserLoginRoles, IUserPracticeLocation} from "./interface"

export const getAccountLocationUuidFromRolesResp = (
  userRoles: IUserRole[], 
  userPracticeLocation?: IUserPracticeLocation[]
): string[] => {
  const accountLocationUuids: string[] = [];
  
  if (userRoles.length) {
    userRoles.forEach((userRole) => {
      const locationId = userRole?.accountLocationId;
      if (locationId && !userRole.isDeleted && !accountLocationUuids.includes(locationId)) {
        accountLocationUuids.push(locationId);
      }
    });
  } else if (userPracticeLocation) {
    userPracticeLocation.forEach((userLocation) => {
      if (userLocation.accountLocations.length) {
        const locationUuid = userLocation.accountLocations[0].uuid;
        if (locationUuid && !accountLocationUuids.includes(locationUuid)) {
          accountLocationUuids.push(locationUuid);
        }
      }
    });
  }

  return accountLocationUuids;
};

export const getNotDeleteRoles = (userRoles: IUserRole[]) => {
  const notDeletedRoles: IUserRole[] = []
  userRoles.forEach((userRole: any)=> {
    if (!userRole.isDeleted ) {
      notDeletedRoles.push(userRole)
    }
  })
  return  notDeletedRoles
}

export const getResorceCode = (args: {
  sectionCode: string;
  actionCode: string;
  accountLocationId: string;
})=> {
  const {sectionCode, accountLocationId, actionCode} = args;
  return `${sectionCode}/${actionCode}:${accountLocationId}`
}